import React from "react";
import { Button } from "react-bootstrap";
import { CodeVerificationProps } from "../../general/CodeVerification/CodeVerification";
import { ResendCode } from "../../general/CodeVerification/ResendCode";

export interface LoginFlowMagicLinkProps {
    providedEmail: string;
    onRequestCode: CodeVerificationProps["request"];
}

export const LoginFlowMagicLink: React.FC<LoginFlowMagicLinkProps> = ({
    providedEmail,
    onRequestCode,
}) => {
    const isGmailAddress = providedEmail.endsWith("@gmail.com");

    return (
        <>
            <h2 className="magic-link__header mb-5">
                We Just Emailed{" "}
                <span className="text-break">{providedEmail}</span>
            </h2>
            <p className="magic-link__resent mb-2">
                <ResendCode request={onRequestCode}>
                    {({ handleResend, resendBlocked }) => (
                        <Button
                            variant="link"
                            size="lg"
                            onClick={handleResend}
                            disabled={resendBlocked}
                            className="p-0 font-weight-bold"
                        >
                            {!resendBlocked ? (
                                <>Resend Email</>
                            ) : (
                                <>Email re-sent</>
                            )}
                        </Button>
                    )}
                </ResendCode>
            </p>

            <p className="magic-link__lead mb-5">
                Click the link in that email to sign in.
            </p>

            {isGmailAddress && (
                <Button
                    variant="primary"
                    href="https://mail.google.com"
                    target="_blank"
                >
                    Go to Gmail <span className="ml-1">→</span>
                </Button>
            )}
        </>
    );
};
