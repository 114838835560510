import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { CashFlowReportDto } from "../../../common/dto/reports/cash-flow-tab-report-response.dto";
import { NonUndefined } from "../../../common/helpers/typescript";
import { ReportFilters } from "../../../common/types/filters/reports";
import { StickyDataTable } from "../../general/DataTable/StickyDataTable";
import {
    CashFlowReportTable,
    CashFlowReportTableProps,
} from "./CashFlowReportTable";

export interface CashFlowReportTableContainerProps {
    report: CashFlowReportDto;
    filters: ReportFilters;
    withBorder?: boolean;
}

export const CashFlowReportTableContainer: React.FC<
    CashFlowReportTableContainerProps
> = ({ report, filters, withBorder }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollbarsRef = useRef<Scrollbars>(null);
    const [isStickingRight, setIsStickingRight] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            scrollbarsRef.current?.scrollToRight();
        }, 0);
    }, [report]);

    const getStickyRightOffset = useCallback<
        NonUndefined<CashFlowReportTableProps["getStickyRightOffset"]>
    >(
        (col) => (isStickingRight && col.id === "percent" ? 120 : undefined),
        [isStickingRight],
    );

    return (
        <div
            className={classNames("data-table", {
                "data-table--with-border": withBorder,
            })}
            ref={containerRef}
        >
            <StickyDataTable
                containerRef={containerRef}
                ref={scrollbarsRef}
                stickyLeft
                stickyRight={2}
                setIsStickingRight={setIsStickingRight}
            >
                <CashFlowReportTable
                    tableData={report.tableData}
                    filters={filters}
                    getStickyRightOffset={getStickyRightOffset}
                />
            </StickyDataTable>
        </div>
    );
};
