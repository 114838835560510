import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { Form, Formik, FormikConfig } from "formik";
import { useDispatch } from "react-redux";
import { isEmail } from "../../../common/helpers/accounts";
import "./CodeVerification.scss";
import { submitHelper } from "../../../helpers/form";
import { ButtonWithLoader } from "../ButtonWithLoader/ButtonWithLoader";
import { addNotification } from "../../../reducers/appState";
import { Loader } from "../Loader";
import { ResendCode } from "./ResendCode";
import { CodeVerificationContent } from "./CodeVerificationContent";
import { VerificationCodeFormSchema } from "./types";

export type CodeVerificationProps = {
    verify?: (code: string) => Promise<any>;
    onSuccess: (result?: any) => void | Promise<void>;
    request: () => Promise<any>;
    requestOnMount?: boolean;
    phoneOrEmail: string;
    submitButtonText?: React.ReactNode;
    lead?: React.ReactNode;
    resend?: React.ReactNode;
    renderSubmit?: (valid: boolean, loading: boolean) => React.ReactNode;
    small?: boolean;
};

export const CodeVerification: React.FC<CodeVerificationProps> = ({
    phoneOrEmail,
    request,
    requestOnMount = true,
    verify,
    onSuccess,
    submitButtonText = "Confirm",
    resend = <ResendCode request={request} />,
    lead = (
        <header className="codeVerification__lead">
            <p>
                We just sent a 6-digit code to your{" "}
                {isEmail(phoneOrEmail) ? "email" : "phone"}. Proceed by typing
                your code below.
            </p>
            {resend}
        </header>
    ),
    renderSubmit,
}) => {
    // assume the code was sent if not requesting code on mount
    const [codeSent, setCodeSent] = useState(!requestOnMount);
    const [verifying, setVerifying] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (requestOnMount) {
            request().then(() => setCodeSent(true));
        }
    }, [request, requestOnMount]);

    const formConfig: FormikConfig<VerificationCodeFormSchema> = {
        initialValues: {
            verificationCode: "",
        },
        validationSchema: object().shape({
            verificationCode: string()
                .required()
                .matches(/^\d{6}$/, "Type 6-digit code")
                .label("Code"),
        }),
        onSubmit: submitHelper({
            loading: verifying,
            setLoading: setVerifying,
            handler: async (data) => {
                const result = verify
                    ? await verify(data.verificationCode)
                    : data.verificationCode;

                if (result) {
                    await onSuccess(result);
                } else {
                    dispatch(
                        addNotification({
                            type: "danger",
                            message: "Invalid code",
                        }),
                    );
                }
            },
        }),
        validateOnMount: true,
    };

    if (!codeSent) {
        return <Loader />;
    }

    return (
        <div className="codeVerification">
            {lead}
            <Formik {...formConfig}>
                {(formik) => (
                    <Form>
                        <div className="mb-4">
                            <CodeVerificationContent small />
                        </div>
                        {typeof renderSubmit === "function" ? (
                            renderSubmit(formik.isValid, verifying)
                        ) : (
                            <ButtonWithLoader
                                disabled={!formik.isValid}
                                loading={verifying}
                                size="lg"
                                type="submit"
                            >
                                {submitButtonText}
                            </ButtonWithLoader>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};
