import React from "react";
import { EmptyState } from "../general/EmptyState/EmptyState";

export const DashboardBalanceEmpty: React.FC = () => {
    return (
        <EmptyState
            header="Can't show balance history"
            body="We need some time to gather enough data"
        />
    );
};
