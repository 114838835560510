import { createContext } from "react";
import { Entity } from "../../../common/types/entity";
import { FinancialAccount } from "../../../common/types/financialAccount";

export interface EntityWithAccounts {
    entity: Entity;
    accounts: FinancialAccount[];
}

interface Context {
    draggedAccount?: FinancialAccount;
    entitiesWithAccounts: EntityWithAccounts[];
}
export const AccountsDndContext = createContext<Context>({
    entitiesWithAccounts: [],
});
