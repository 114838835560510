import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { noop, noopAsync } from "../../../../helpers/general";
import { IntentType, PricingAndUsage } from "../../../../common/billing";
import {
    getPricingAndUsage,
    prepareSubscription,
} from "../../../../lib/billing";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentMethodModal } from "../PaymentMethodModal";
import { StandardModal } from "../../../general/Modal/Modal";
import { useLoadStripe } from "../useLoadStripe";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { useQuery } from "react-query";
import { AppliedDiscount } from "../../../../common/types/billing";

interface AnnualBillingContextValue {
    previousYearSelected: boolean;
    setPreviousYearSelected: (value: boolean) => void;
    handleCheckout(): Promise<void>;
    pricingAndUsage?: PricingAndUsage;
    appliedDiscount?: AppliedDiscount;
    handleApplyDiscount(code: string): Promise<void>;
    handleClearDiscount(): void;
}

interface CheckoutOptions {
    elementsOptions: StripeElementsOptions;
    intent: IntentType;
}

const AnnualBillingContext = createContext<AnnualBillingContextValue>({
    previousYearSelected: true,
    setPreviousYearSelected: noop,
    handleCheckout: noopAsync,
    handleApplyDiscount: noopAsync,
    handleClearDiscount: noop,
});

export const AnnualBillingProvider: React.FC = ({ children }) => {
    const stripePromise = useLoadStripe();
    const [previousYearSelected, setPreviousYearSelected] = useState(false);

    const [appliedDiscountCode, setAppliedDiscountCode] = useState("");
    const { data: pricingAndUsage } = useQuery(
        ["pricing", previousYearSelected, appliedDiscountCode],
        () => getPricingAndUsage(previousYearSelected, appliedDiscountCode),
        {
            keepPreviousData: true,
        },
    );

    const handleApplyDiscount = useCallback(async (code: string) => {
        setAppliedDiscountCode(code);
    }, []);

    const handleClearDiscount = useCallback(() => {
        setAppliedDiscountCode("");
    }, []);

    const [showCheckout, setShowCheckout] = useState(false);
    const [checkoutOptions, setCheckoutOptions] =
        useState<CheckoutOptions | null>(null);

    const handleCheckout = useCallback(async () => {
        const { clientSecret, intent } = await prepareSubscription(
            previousYearSelected,
            appliedDiscountCode,
        );
        setCheckoutOptions({
            elementsOptions: { clientSecret },
            intent,
        });

        setShowCheckout(true);
    }, [appliedDiscountCode, previousYearSelected]);

    const value = useMemo(() => {
        return {
            previousYearSelected,
            setPreviousYearSelected,
            handleCheckout,
            pricingAndUsage,
            handleApplyDiscount,
            handleClearDiscount,
        };
    }, [
        handleApplyDiscount,
        handleCheckout,
        handleClearDiscount,
        previousYearSelected,
        pricingAndUsage,
    ]);

    return (
        <AnnualBillingContext.Provider value={value}>
            {children}

            <StandardModal
                show={showCheckout}
                onHide={() => setShowCheckout(false)}
                size="lg"
            >
                {checkoutOptions && (
                    <Elements
                        stripe={stripePromise}
                        options={checkoutOptions.elementsOptions}
                    >
                        <PaymentMethodModal
                            intent={checkoutOptions.intent}
                            code={appliedDiscountCode}
                        />
                    </Elements>
                )}
            </StandardModal>
        </AnnualBillingContext.Provider>
    );
};

export function useAnnualBillingContext() {
    const ctx = useContext(AnnualBillingContext);

    if (!ctx) {
        throw new Error("AnnualBillingContext not found");
    }

    return ctx;
}
