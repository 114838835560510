import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import styles from "./Plans.module.scss";
import classNames from "classnames";

interface Props {
    span?: number;
    first?: boolean;
    last?: boolean;
    className?: string;
    sticky?: boolean;
}

export const PlansRow: React.FC<Props> = ({
    children,
    first,
    last,
    sticky,
    span,
    className,
}) => {
    const [height, setHeight] = useState(0);
    const vars = useMemo(
        () =>
            ({
                "--row": `span ${span ?? 1}`,
                "--offset": `${height}px`,
            }) as CSSProperties,
        [height, span],
    );

    const cb = useCallback(
        (el: HTMLDivElement | null) => setHeight(el?.offsetTop ?? 0),
        [],
    );

    return (
        <div
            ref={cb}
            className={classNames(styles.row, className, {
                [styles.firstRow]: first,
                [styles.lastRow]: last,
                [styles.sticky]: sticky,
            })}
            style={vars}
        >
            {children}
        </div>
    );
};
