import React from "react";
import { FinancialDocument } from "../../../common/types/financialDocument";
import { useFilenameParts } from "../useFilenameParts";
import { Tag } from "../../general/Tag/Tag";
import { AIIcon, DownloadIcon } from "../../../icons";
import styles from "./FinancialDocumentDetailsHeader.module.scss";
import { FinancialDocumentMetadata } from "./FinancialDocumentMetadata";
import classNames from "classnames";
import { useDownloadFinancialDocumentFile } from "./useDownloadFinancialDocumentFile";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";

interface Props {
    financialDocument: FinancialDocument;
}

export const FinancialDocumentDetailsHeader: React.FC<Props> = ({
    financialDocument,
}) => {
    const { extension, name } = useFilenameParts(financialDocument.fileName);
    const { download, downloading } =
        useDownloadFinancialDocumentFile(financialDocument);

    return (
        <div
            className={classNames(styles.header, {
                [styles.isMatched]:
                    financialDocument.transactionMatches.length > 0,
            })}
            data-testid="financial-document-details-header"
        >
            {financialDocument.transactionMatches.length > 0 && (
                <aside className={styles.tag}>
                    <Tag variant="primary">
                        <AIIcon className="icon-color-current" /> Receipt
                        matched
                    </Tag>
                </aside>
            )}

            <aside className={styles.actions}>
                <ButtonWithLoader
                    variant="secondary"
                    className="btn-icon"
                    size={"xs" as any}
                    onClick={download}
                    loading={downloading}
                    data-testid="download-financial-document-file"
                >
                    <DownloadIcon />
                </ButtonWithLoader>
            </aside>

            <h3 className={styles.heading}>
                {name}
                {extension && (
                    <small className="text-gray-500">.{extension}</small>
                )}
            </h3>

            <section>
                <FinancialDocumentMetadata
                    financialDocument={financialDocument}
                />
            </section>
        </div>
    );
};
