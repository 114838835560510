import auth0 from "auth0-js";

export class Auth0Error extends Error {
    constructor(public auth0Error: auth0.Auth0Error) {
        super(
            auth0Error.errorDescription ??
                auth0Error.error_description ??
                auth0Error.error ??
                auth0Error.description ??
                "Unknown Auth0 error",
        );
    }
}
