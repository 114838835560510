export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const currencyFormatterNoFractions = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const currencyFormatterFractionsIfNeeded = new Intl.NumberFormat(
    "en-US",
    {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
);

export const currencyFormatterShort = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "short",
});

export interface CurrencyFormatterFinancialStatementsOptions {
    addCurrency?: boolean;
    includeFractions?: boolean;
    displaySign?: boolean;
}
export const currencyFormatterFinancialStatements = {
    format: (
        value: number | string,
        {
            addCurrency = false,
            includeFractions = false,
            displaySign = false,
        }: CurrencyFormatterFinancialStatementsOptions = {},
    ) => {
        const fractionDigits = includeFractions ? 2 : 0;
        if (typeof value === "string") {
            value = parseFloat(value);
        }
        value = displaySign ? value : Math.abs(value);
        const options = {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        } as Intl.NumberFormatOptions;
        if (addCurrency) {
            options.style = "currency";
            options.currency = "USD";
        }
        return value.toLocaleString("en-US", options);
    },
};
