import { Transaction } from "../common/types/transaction";

export function getAmountSaved(transaction: Transaction): number | null {
    return (
        transaction.amountSaved ??
        ((transaction.requirementsSnoozed &&
            transaction.potentialAmountSaved) ||
            null)
    );
}
