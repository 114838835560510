import classNames from "classnames";
import React, { MouseEvent, useCallback, useContext } from "react";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { Transaction } from "../../../common/types/transaction";
import { useDesktopView } from "../../../hooks/useMobileView";
import { EntityIcon } from "../../entity/EntityIcon";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { CategorySelectPrompt } from "../CategorySelect/CategorySelectPrompt";
import { TransactionsContext } from "../context/transactions.context";
import { usePotentialSavingsContext } from "../PotentialSavingsContext";
import { TransactionAction } from "../TransactionAction";
import { TransactionAmount } from "../TransactionAmount";
import { TransactionCategoryChange } from "../TransactionCategoryChange";
import { TransactionEntityCompact } from "../TransactionEntityCompact";
import { useBulkActions } from "../TransactionsBulkActions/useBulkActions";
import { TransferMatchLink } from "../TransferMatchLink/TransferMatchLink";
import { useChangeTransactionEntity } from "../useChangeTransactionEntity";
import { useChangeTransactionToPersonal } from "../useChangeTransactionToPersonal";
import { invalidateTransactionsQueries } from "../useTransactionsQuery";
import { TransactionAccountColumnContent } from "./TransactionAccountColumnContent";
import { TransactionDateColumnContent } from "./TransactionDateColumnContent";
import { TransactionDescriptionColumnContent } from "./TransactionDescriptionColumnContent";
import "./TransactionListItem.scss";

interface Props {
    transaction: Transaction;
    onClick: () => void;
    isActive: boolean;
    isFullView: boolean;
    showAccountName: boolean;
    disableBulkActions?: boolean;
    actionButtonComponent?: (transaction: Transaction) => React.ReactNode;
    onTransferMatchClick?: (transaction: Transaction) => void;
    hideTransferMatchLink?: boolean;
    readonly?: boolean;
}

const BLOCK_EVENT_KEY = "blockCategorySelectClick";

export const TransactionListTableItem: React.FC<Props> = ({
    transaction,
    onClick,
    isActive,
    isFullView,
    showAccountName,
    disableBulkActions,
    actionButtonComponent,
    onTransferMatchClick,
    hideTransferMatchLink = false,
    readonly = false,
}) => {
    const { onTransactionUpdated } = usePotentialSavingsContext();
    const { saveTransaction } = useContext(TransactionsContext);
    const bulkActions = useBulkActions();
    const changeToBusiness = useChangeTransactionEntity(transaction);
    const changeToPersonal = useChangeTransactionToPersonal(transaction);

    const handleTransactionUpdate = useCallback(
        async (payload: UpdateTransactionDto) => {
            const updated = await saveTransaction(transaction, payload);
            if (updated) {
                onTransactionUpdated(transaction, updated);
                invalidateTransactionsQueries();
            }
        },
        [onTransactionUpdated, saveTransaction, transaction],
    );

    const handleClick = useCallback(
        (e: MouseEvent) => {
            // we don't want to register click on the row if user clicked on category select,
            // click on category select should be propagated up however, to close other category selects
            if ((e as any)[BLOCK_EVENT_KEY]) {
                return;
            }

            if (bulkActions.enabled && (e.metaKey || e.ctrlKey)) {
                bulkActions.isSelected(transaction)
                    ? bulkActions.deselect(transaction)
                    : bulkActions.select(transaction);
                return;
            }

            onClick();
        },
        [onClick, bulkActions, transaction],
    );

    const isDesktop = useDesktopView();

    return (
        <tr
            className={classNames(
                "transaction-list-item",
                `transaction-list-item--${transaction.status}`,
                `transaction-list-item--${transaction.type}`,
                {
                    "transaction-list-item--credit": transaction.amount > 0,
                    "transaction-list-item--active": isActive,
                    "transaction-list-item--ignored": transaction.isIgnored,
                },
            )}
            data-testid="transaction-list-item"
            onClick={handleClick}
        >
            {!disableBulkActions && bulkActions.enabled ? (
                <td className="transaction-list-item__select">
                    <FormCheckbox
                        value="page"
                        isChecked={
                            bulkActions.hasSelectedAll ||
                            bulkActions.isSelected(transaction)
                        }
                        disabled={bulkActions.hasSelectedAll}
                        handleChange={() =>
                            bulkActions.isSelected(transaction)
                                ? bulkActions.deselect(transaction)
                                : bulkActions.select(transaction)
                        }
                        preventClickPropagation
                        small
                    />
                </td>
            ) : null}
            {isDesktop && (
                <td className="transaction-list-item__date">
                    <TransactionDateColumnContent transaction={transaction} />
                </td>
            )}

            <td className="transaction-list-item__description">
                <TransactionDescriptionColumnContent
                    transaction={transaction}
                />
                <span className="d-block d-md-none transaction-list-item__date transaction-list-item__date--inline">
                    <TransactionDateColumnContent transaction={transaction} />
                </span>
            </td>

            {isFullView && (
                <td
                    className="transaction-list-item__category"
                    data-testid="transaction-list-item-category"
                >
                    {transaction.split?.length ? (
                        <div className="transaction-list-item__category-container">
                            <span
                                onClick={(e: any) =>
                                    (e[BLOCK_EVENT_KEY] = true)
                                }
                            >
                                <CategorySelectPrompt
                                    transaction={transaction}
                                    readonly
                                />
                            </span>
                            {!hideTransferMatchLink &&
                                transaction.pairedTransferId && (
                                    <span
                                        onClick={(e: any) =>
                                            (e[BLOCK_EVENT_KEY] = true)
                                        }
                                    >
                                        <TransferMatchLink
                                            onClick={onTransferMatchClick}
                                            transaction={transaction}
                                        />
                                    </span>
                                )}
                        </div>
                    ) : (
                        <div className="transaction-list-item__category-container">
                            <span
                                onClick={(e: any) =>
                                    (e[BLOCK_EVENT_KEY] = true)
                                }
                            >
                                {readonly ? (
                                    <CategorySelectPrompt
                                        transaction={transaction}
                                        readonly
                                    />
                                ) : (
                                    <TransactionCategoryChange
                                        transaction={transaction}
                                        onUpdate={handleTransactionUpdate}
                                    />
                                )}
                            </span>
                            {!hideTransferMatchLink &&
                                transaction.pairedTransferId && (
                                    <span
                                        onClick={(e: any) =>
                                            (e[BLOCK_EVENT_KEY] = true)
                                        }
                                    >
                                        <TransferMatchLink
                                            onClick={onTransferMatchClick}
                                            transaction={transaction}
                                        />
                                    </span>
                                )}
                        </div>
                    )}
                </td>
            )}
            {showAccountName && (
                <td
                    className="transaction-list-item__account d-none d-md-table-cell"
                    data-testid="transaction-list-item-account"
                >
                    <TransactionAccountColumnContent
                        transaction={transaction}
                    />
                </td>
            )}

            <td className="transaction-list-item__amount">
                <TransactionAmount
                    transaction={transaction}
                    addSign={true}
                    color={true}
                />
            </td>
            {isFullView && (
                <td className="transaction-list-item__is-business">
                    {readonly ? (
                        <EntityIcon entity={transaction.entity} size="xs" />
                    ) : (
                        <TransactionEntityCompact
                            transaction={transaction}
                            onChangeToPersonal={() => changeToPersonal.mutate()}
                            onChangeToBusiness={(entity) =>
                                changeToBusiness.mutate(entity)
                            }
                            loading={
                                changeToPersonal.isLoading ||
                                changeToBusiness.isLoading
                            }
                        >
                            <EntityIcon entity={transaction.entity} size="xs" />
                        </TransactionEntityCompact>
                    )}
                </td>
            )}
            <td
                className={classNames("transaction-list-item__action", {
                    "transaction-list-item__action--extended":
                        actionButtonComponent,
                })}
            >
                {actionButtonComponent ? (
                    actionButtonComponent(transaction)
                ) : (
                    <TransactionAction transaction={transaction} />
                )}
            </td>
        </tr>
    );
};
