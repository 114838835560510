import React from "react";
import { useBulkActions } from "../useBulkActions";
import { CategorySelectCustom } from "../../CategorySelect/CategorySelectCustom";
import { BulkUpdateStatus } from "../lib";
import { NoteDropdown } from "../NoteDropdown/NoteDropdown";
import classNames from "classnames";
import { TransactionTaxQuestions } from "../ActionBarTaxQuestions/ActionBarTaxQuestions";
import { ActionBarQuestionAnswerPicker } from "../ActionBarTaxQuestions/ActionBarQuestionAnswerPicker";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRealEstateEnabled } from "../../../../hooks/useRealEstateEnabled";
import { ActionBar } from "../../../general/ActionBar/ActionBar";
import actionBarStyles from "../../../general/ActionBar/ActionBar.module.scss";
import {
    TransactionDirectionType,
    Taxonomy,
} from "../../../../common/categories";
import { TransactionEntityCompact } from "../../TransactionEntityCompact";
import { PlanManagementProvider } from "../../../billing/PlanManagement/PlanManagementProvider";

export const TransactionsActionBar: React.FC = () => {
    const bulkActions = useBulkActions();
    const realEstateEnabled = useRealEstateEnabled();

    if (!bulkActions) {
        return null;
    }

    const isBusy =
        bulkActions.updateDetails?.status === BulkUpdateStatus.UPDATING;

    const selectedArray = Object.values(bulkActions.selected);
    const firstType = selectedArray[0];
    const firstTransactionDirection = firstType?.transactionDirection;

    const transactionHaveMixedDirections =
        bulkActions.hasSelectedAll ||
        selectedArray.some(
            (value) => value.transactionDirection !== firstTransactionDirection,
        );

    return (
        <ActionBar
            selectedCount={bulkActions.selectedCount}
            totalCount={bulkActions.totalTransactions}
            clearSelection={bulkActions.clearSelection}
            hasSelectedAll={bulkActions.hasSelectedAll}
            selectAll={bulkActions.selectAll}
        >
            <PlanManagementProvider>
                <CategorySelectCustom
                    onSelected={(category) => {
                        bulkActions.updateSelected({
                            update: "category",
                            value:
                                typeof category === "string"
                                    ? category
                                    : (String(category.id) as Taxonomy),
                        });
                    }}
                    dropdownKey="bulk-update"
                    placement="top-end"
                    realEstateEnabled={realEstateEnabled}
                    transactionDirection={
                        transactionHaveMixedDirections
                            ? TransactionDirectionType.mixed
                            : firstTransactionDirection
                    }
                >
                    {(active) => (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="action-bar-category">
                                    Update category
                                </Tooltip>
                            }
                        >
                            <button
                                className={classNames(actionBarStyles.button, {
                                    active,
                                })}
                                disabled={isBusy}
                            >
                                Category
                            </button>
                        </OverlayTrigger>
                    )}
                </CategorySelectCustom>
            </PlanManagementProvider>

            <span className={actionBarStyles.separator} />

            <TransactionEntityCompact
                drop="up"
                onChangeToPersonal={() =>
                    bulkActions.updateSelected({
                        update: "category",
                        value: Taxonomy.personal,
                    })
                }
                onChangeToBusiness={(entity) =>
                    bulkActions.updateSelected({
                        update: "entity",
                        value: entity,
                    })
                }
            >
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="action-bar-entity">Update entity</Tooltip>
                    }
                >
                    <button
                        className={classNames(actionBarStyles.button)}
                        disabled={isBusy}
                    >
                        Entity
                    </button>
                </OverlayTrigger>
            </TransactionEntityCompact>

            <span className={actionBarStyles.separator} />

            <NoteDropdown
                dropdownKey="memo"
                inputPlaceholder="Add note..."
                placement="top-end"
                onSubmit={(value) =>
                    bulkActions.updateSelected({
                        update: "memo",
                        value: value,
                    })
                }
            >
                {(active) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="action-bar-memo">Add note</Tooltip>
                        }
                    >
                        <button
                            className={classNames(actionBarStyles.button, {
                                active,
                            })}
                            disabled={isBusy}
                        >
                            Note
                        </button>
                    </OverlayTrigger>
                )}
            </NoteDropdown>

            {bulkActions.taxRule ? (
                <TransactionTaxQuestions
                    questions={bulkActions.taxQuestions}
                    answers={bulkActions.answers}
                    onAnswer={bulkActions.handleAnswer}
                    renderAnswerComponent={(options) => (
                        <>
                            <span className={actionBarStyles.separator} />
                            <ActionBarQuestionAnswerPicker {...options}>
                                {(active) => (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip
                                                id={`action-bar-${options.question.key}`}
                                            >
                                                {options.question.hint}
                                            </Tooltip>
                                        }
                                    >
                                        <button
                                            className={classNames(
                                                actionBarStyles.button,
                                                {
                                                    active,
                                                },
                                            )}
                                            disabled={
                                                bulkActions.updateDetails
                                                    ?.status ===
                                                BulkUpdateStatus.UPDATING
                                            }
                                        >
                                            {options.question.short}
                                        </button>
                                    </OverlayTrigger>
                                )}
                            </ActionBarQuestionAnswerPicker>
                        </>
                    )}
                />
            ) : null}
        </ActionBar>
    );
};
