import { useWorkspaceContext } from "../state/workspaceContext";
import { useQuery } from "react-query";
import { billingQueryKeys } from "./useBillingStatus";
import { getBillingInfo } from "../lib/billing";

export function useBillingInfo() {
    const { activeWorkspaceKey } = useWorkspaceContext();

    return useQuery(billingQueryKeys.info(activeWorkspaceKey), () =>
        getBillingInfo(activeWorkspaceKey),
    );
}
