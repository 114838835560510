import { ManualJournalEntryNew } from "./manualJournalEntry";

export interface JournalEntrySource {
    type: JournalEntrySourceType;
    id: string | number;
}

export interface JournalEntry {
    id: string;
    accountingLedgerId: string;
    date: Date;
    memo: string | null;
    lines: JournalEntryLine[];
    source: JournalEntrySource;
}

export interface JournalEntryLine {
    debitAmount: number | null;
    creditAmount: number | null;
    accountCode: number;
    description: string;
}

export interface JournalEntryNew {
    debitAmount: number | null;
    creditAmount: number | null;
    date: Date;
    transactionId: number | null;
    manualJournalEntryId: string | null;
    transactionSplitId?: number | null;
    accountCode: number;
    accountingLedgerId: string;
    referenceNumber: string;
    description: string;
    memo: string | null;
    id: string;
    split: number[];
}

export enum JournalEntrySourceType {
    Transaction = "transaction",
    ManualJournalEntry = "manual_journal_entry",
}

export type GetJournalEntryResponseObject = JournalEntry;

export type CreateJournalEntryResponseObject = ManualJournalEntryNew & {
    journalEntries: JournalEntryNew[];
};

export type UpsertJournalEntryResponseObject = CreateJournalEntryResponseObject;
