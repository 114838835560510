import { BillingHistory, Invoice } from "../../types/billing";
import { InvoiceDto } from "./invoice.dto";

export class BillingHistoryDto {
    static deserialize(dto: BillingHistoryDto): BillingHistory {
        return {
            invoices: dto.invoices.map(InvoiceDto.deserialize),
        };
    }

    invoices: InvoiceDto[];

    constructor(invoices: Invoice[]) {
        this.invoices = invoices.map((invoice) => new InvoiceDto(invoice));
    }
}
