import React, { useCallback, useMemo, useState } from "react";
import {
    AggregatedFiltersContext,
    AggregatedFiltersContextValue,
} from "./AggregatedFilters.context";

export interface Props {
    defaultTab: string;
}

export const AggregatedFiltersProvider: React.FC<Props> = ({
    children,
    defaultTab,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState<string>(defaultTab);

    const open: AggregatedFiltersContextValue["open"] = useCallback(
        (tab) => {
            setActiveTab(tab ?? defaultTab);
            setIsOpen(true);
        },
        [defaultTab],
    );

    const close: AggregatedFiltersContextValue["close"] = useCallback(() => {
        setActiveTab(defaultTab);
        setIsOpen(false);
    }, [defaultTab]);

    const value: AggregatedFiltersContextValue = useMemo(
        () => ({
            open,
            close,
            activeTab,
            isOpen,
        }),
        [open, close, activeTab, isOpen],
    );

    return (
        <AggregatedFiltersContext.Provider value={value}>
            {children}
        </AggregatedFiltersContext.Provider>
    );
};
