import { createContext } from "react";
import { TreeContextValue } from "./Tree.types";
import { noop } from "../../../helpers/general";

export const TreeContext = createContext<TreeContextValue<any>>({
    expandNode: noop,
    collapseNode: noop,
    isExpanded: () => false,
    nodes: [],
    hoverNode: noop,
    unhoverNode: noop,
    isHovered: () => false,
});
