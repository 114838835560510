import { Entity } from "../../common/types/entity";
import { createContext } from "react";
import { UserRole } from "../../common/types/userEntityAssignment";

interface Context {
    entity?: Entity;
    role?: UserRole;
}

export const EntityContext = createContext<Context>({});
