import React from "react";
import { FinancialConnection } from "../../common/types/financialConnection";
import { FinancialAccount } from "../../common/types/financialAccount";
import { isPlaidConnection } from "../../common/helpers/financialConnection";
import { PlaidConnectionIcon } from "../plaid/PlaidConnectionIcon";
import { FinancialAccountIcon } from "../financialAccount/FinancialAccountIcon";

interface Props {
    connection?: FinancialConnection;
    account: FinancialAccount;
}

export const FinancialConnectionIcon: React.FC<Props> = ({
    connection,
    account,
}) => {
    if (connection && isPlaidConnection(connection)) {
        return <PlaidConnectionIcon connection={connection} />;
    }

    return <FinancialAccountIcon account={account} />;
};
