import React from "react";
import { UploadingDocumentPlaceholder } from "../documents/lib";
import classNames from "classnames";
import styles from "./ImportedDocuments.module.scss";
import { Loader } from "../general/Loader";
import { DocumentName } from "../documents/DocumentName";
import { TrashIcon, UploadIcon } from "../../icons";
import { Button } from "react-bootstrap";
import { FinancialDocumentsUpload } from "../documents/FinancialDocumentUpload/FinancialDocumentsUpload";

export interface Props {
    items: UploadingDocumentPlaceholder[];
    onRemoveItem: (item: UploadingDocumentPlaceholder) => void;
}

export const ImportedDocuments: React.FC<Props> = ({ items, onRemoveItem }) => {
    return (
        <section className={styles.documentsTable}>
            <header className={classNames(styles.header, styles.row)}>
                <span>Name</span>
                <span className="text-right">Transactions</span>
            </header>

            {items.map((item) => (
                <div key={item.fileName} className={styles.row}>
                    <span>
                        <DocumentName fileName={item.fileName} />
                    </span>
                    <span className="text-right">???</span>
                    <span>
                        {item.financialDocument ? (
                            <Button
                                onClick={() => onRemoveItem(item)}
                                variant="plain"
                                className={styles.action}
                            >
                                <TrashIcon />
                            </Button>
                        ) : (
                            <Loader inline />
                        )}
                    </span>
                </div>
            ))}

            <footer className={classNames(styles.row, styles.footer)}>
                <FinancialDocumentsUpload
                    render={(open) => (
                        <Button variant="plain" onClick={open} size="sm">
                            <UploadIcon className="mr-2" /> Upload another CSV
                        </Button>
                    )}
                />
            </footer>
        </section>
    );
};
