import React from "react";
import NumberFormat from "react-number-format";
import { FormRow } from "../forms/FormRow";

interface Props {
    label?: string;
    fieldName?: string;
}

export const PhoneNumberInput: React.FC<Props> = ({
    label = "Phone number",
    fieldName = "phoneNumber",
}) => {
    return (
        <FormRow fieldName={fieldName} label={label}>
            {({ setValue }, { value }) => (
                <NumberFormat
                    value={value}
                    onValueChange={(v) => setValue(v.value)}
                    format="(###) ###-####"
                    mask="_"
                    className="form-control"
                />
            )}
        </FormRow>
    );
};
