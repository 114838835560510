import React from "react";
import {
    CategorySelectCustom,
    CategorySelectCustomProps,
} from "./CategorySelectCustom";
import { Taxonomy } from "../../../common/categories";
import { InputSize } from "../../../helpers/form";
import styles from "./CategorySelectSimple.module.scss";
import { CategoryIcon } from "../CategoryIcon";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { CustomSelectDefaultTrigger } from "../../forms/CustomSelect/CustomSelectDefaultTrigger";
import { CustomCategory } from "../../../common/types/customCategory";

export interface CategorySelectSimpleProps
    extends Omit<CategorySelectCustomProps, "children"> {
    value?: Taxonomy | CustomCategory;
    size?: InputSize;
    hideCustomCategories?: boolean;
}

export const CategorySelectSimple: React.FC<CategorySelectSimpleProps> = ({
    value,
    placement = "bottom-start",
    size,
    ...props
}) => {
    const getCategoryLabel = useCategoryLabelGetter();

    return (
        <CategorySelectCustom placement={placement} {...props}>
            {() => (
                <CustomSelectDefaultTrigger
                    size={size}
                    value={
                        value ? (
                            <div className={styles.control}>
                                <CategoryIcon
                                    category={
                                        typeof value === "string"
                                            ? value
                                            : value.mainCategoryId
                                    }
                                />
                                {getCategoryLabel(
                                    typeof value === "string"
                                        ? value
                                        : (value.id as unknown as Taxonomy),
                                )}
                            </div>
                        ) : (
                            "Select category"
                        )
                    }
                />
            )}
        </CategorySelectCustom>
    );
};
