import React, {
    MutableRefObject,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    ClickWithKeyboardEventInteraction,
    useInteraction,
} from "../../../hooks/useInteraction";

export interface ToggleableInputProps {
    renderInputState(
        toggleInput: ClickWithKeyboardEventInteraction<() => void>,
        inputRef: MutableRefObject<HTMLInputElement | null>,
    ): ReactElement<any, any> | null;
    renderDefaultState(
        toggleInput: ClickWithKeyboardEventInteraction<() => void>,
    ): ReactElement<any, any> | null;
}

export const ToggleableInput: React.FC<ToggleableInputProps> = ({
    renderInputState,
    renderDefaultState,
}) => {
    const [inputMode, setInputMode] = useState(false);
    const toggleInput = useCallback(() => {
        setInputMode((prev) => !prev);
    }, []);

    const toggleInputInteraction = useInteraction(
        toggleInput,
    ) as ClickWithKeyboardEventInteraction<() => void>;

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (inputMode) {
            inputRef.current?.focus();
        }
    }, [inputMode]);

    if (inputMode) {
        return renderInputState(toggleInputInteraction, inputRef);
    } else {
        return renderDefaultState(toggleInputInteraction);
    }
};
