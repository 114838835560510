import React from "react";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { CodeVerification } from "../../general/CodeVerification/CodeVerification";
import { ResendCode } from "../../general/CodeVerification/ResendCode";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";

interface Props {
    phoneNumber: string;
    onSuccess(): Promise<void>;
    requestVerification(): Promise<void>;
    verifyCode(code: string): Promise<unknown>;
    back(): void;
}

export const VerifyPhoneNumber: React.FC<Props> = ({
    phoneNumber,
    onSuccess,
    requestVerification,
    verifyCode,
    back,
}) => {
    return (
        <OnboardingStep
            title="What’s your phone number?"
            description="We just sent a 6-digit code to your phone. Proceed by typing your code below."
            contentNarrow
        >
            <CodeVerification
                phoneOrEmail={phoneNumber}
                request={requestVerification}
                verify={verifyCode}
                onSuccess={onSuccess}
                lead={null}
                resend={null}
                requestOnMount={false}
                renderSubmit={(valid, loading) => (
                    <>
                        <ResendCode request={requestVerification} />

                        <OnboardingStepActions className="mt-4" onBack={back}>
                            <ButtonWithLoader
                                type="submit"
                                variant="primary"
                                loading={loading}
                                disabled={!valid}
                            >
                                Next
                            </ButtonWithLoader>
                        </OnboardingStepActions>
                    </>
                )}
                small
            />
        </OnboardingStep>
    );
};
