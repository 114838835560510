import {
    endOfISOWeek,
    endOfMonth,
    format,
    isSameDay,
    isSameMonth,
    isSameYear,
    parse,
    parseISO,
    startOfISOWeek,
    startOfMonth,
} from "date-fns";
import { ReportingTabCycle } from "../constants";
import { ReportFilters } from "../types/filters/reports";

export const REPORT_DATA_LABEL_FORMAT_WEEKLY = "yyyy-MM-dd";
export const REPORT_DATA_LABEL_FORMAT_MONTHLY = "yyyy-MM";
const WEEK_OUTPUT_FORMAT = "MMM d";
const MONTH_OUTPUT_FORMAT = "MMM";

export enum ReportFormat {
    JSON = "json",
    XLSX = "xlsx",
}

interface ReportLabelOutputParams {
    labelDate: Date;
    filters: ReportFilters;
    fullRange?: boolean;
    printYear?: boolean;
}

function formatReportLabelOutput({
    labelDate,
    filters,
    fullRange = false,
    printYear = false,
}: ReportLabelOutputParams) {
    const outputStr = format(
        labelDate,
        filters.cycle === ReportingTabCycle.WEEK || fullRange
            ? WEEK_OUTPUT_FORMAT
            : MONTH_OUTPUT_FORMAT,
    );
    if (printYear) {
        return `${outputStr} ${format(labelDate, "YYY")} `;
    } else {
        return outputStr;
    }
}

export function applyReportFiltersToLabel(
    filters: ReportFilters,
    labelDate: Date,
) {
    if (filters.start && labelDate.getTime() < filters.start.getTime()) {
        labelDate = filters.start;
    }
    if (filters.end && labelDate.getTime() > filters.end.getTime()) {
        labelDate = filters.end;
    }

    return labelDate;
}

export function printLabel(
    text: string,
    filters: ReportFilters,
    fullRange: boolean = false,
) {
    if (!text) {
        return text;
    }
    const inputDateFormat =
        filters.cycle === ReportingTabCycle.WEEK
            ? REPORT_DATA_LABEL_FORMAT_WEEKLY
            : REPORT_DATA_LABEL_FORMAT_MONTHLY;
    const labelDateStart = applyReportFiltersToLabel(
        filters,
        parse(text, inputDateFormat, new Date()),
    );
    const printYear = labelDateStart.getFullYear() !== new Date().getFullYear();
    let labelOutput = formatReportLabelOutput({
        labelDate: labelDateStart,
        filters,
        fullRange,
        printYear,
    });
    if (fullRange) {
        const labelDateEnd =
            filters.cycle === ReportingTabCycle.WEEK
                ? applyReportFiltersToLabel(
                      filters,
                      endOfISOWeek(labelDateStart),
                  )
                : applyReportFiltersToLabel(
                      filters,
                      endOfMonth(labelDateStart),
                  );
        if (!isSameDay(labelDateEnd, labelDateStart)) {
            labelOutput = `${labelOutput} - ${formatReportLabelOutput({
                labelDate: labelDateEnd,
                filters,
                fullRange,
                printYear,
            })}`;
        }
    }

    return labelOutput;
}

export function realReportDataStart(
    metaDataStart: string,
    filtersDataStart?: Date,
) {
    let realDataStart = parseISO(metaDataStart);
    if (
        filtersDataStart &&
        filtersDataStart.getTime() > realDataStart.getTime()
    ) {
        realDataStart = filtersDataStart;
    }
    return realDataStart;
}

export function realReportDataEnd(
    metaDataEnd?: string | null,
    filtersDataEnd?: Date,
) {
    let realDataEnd = metaDataEnd ? parseISO(metaDataEnd) : new Date();
    if (filtersDataEnd && filtersDataEnd.getTime() < realDataEnd.getTime()) {
        realDataEnd = filtersDataEnd;
    }
    return realDataEnd;
}

export function getCycleStartForDate(date: Date, cycle: ReportingTabCycle) {
    return cycle === ReportingTabCycle.WEEK
        ? startOfISOWeek(date)
        : startOfMonth(date);
}

export function getCycleEndForDate(date: Date, cycle: ReportingTabCycle) {
    return cycle === ReportingTabCycle.WEEK
        ? endOfISOWeek(date)
        : endOfMonth(date);
}

export function getLabelFormatForCycle(cycle: ReportingTabCycle) {
    return cycle === ReportingTabCycle.WEEK
        ? REPORT_DATA_LABEL_FORMAT_WEEKLY
        : REPORT_DATA_LABEL_FORMAT_MONTHLY;
}

export function getReportDateRangeLabel(start?: Date, end?: Date): string {
    if (!start && end) {
        return `Before ${format(end, "MMMM do YYYY")}`;
    }

    if (start && !end) {
        return `After ${format(start, "MMMM do YYYY")}`;
    }

    if (!start || !end) {
        return "All time";
    }

    if (
        start.getDate() !== startOfMonth(start).getDate() ||
        end.getDate() !== endOfMonth(end).getDate()
    ) {
        return `${format(start, "MMMM do")} ${
            !isSameYear(start, end) ? start.getFullYear() : ""
        }- ${format(end, "MMMM do")} ${end.getFullYear()}`;
    }

    if (isSameMonth(start, end)) {
        return format(start, "MMMM yyyy");
    }

    return `${format(start, "MMMM")} ${
        !isSameYear(start, end) ? start.getFullYear() : ""
    }- ${format(end, "MMMM")} ${end.getFullYear()}`;
}
