import React, { useMemo } from "react";
import { formatDate, UploadingDocumentPlaceholder } from "./lib";
import styles from "./DocumentsPage.module.scss";
import { FormCheckbox } from "../forms/FormCheckbox/FormCheckbox";
import { noop } from "../../helpers/general";
import { FinancialDocumentExtensionIcon } from "./FinancialDocumentExtensionIcon";
import { useFilenameParts } from "./useFilenameParts";
import { Loader } from "../general/Loader";
import { CheckIcon } from "../../icons";
import classNames from "classnames";

interface Props {
    row: UploadingDocumentPlaceholder;
}

export const UploadingPlaceholderRow: React.FC<Props> = ({ row }) => {
    const now = useMemo(() => new Date(), []);
    const { extension, name } = useFilenameParts(row.fileName);

    return (
        <tr className={classNames(styles.row, styles.placeholderRow)}>
            <td>
                <FormCheckbox
                    value="page"
                    isChecked={false}
                    disabled={false}
                    handleChange={noop}
                    preventClickPropagation
                    small
                />
            </td>
            <td className={styles.date}>{formatDate(now)}</td>

            <td colSpan={3}>
                <span className={styles.documentName}>
                    <FinancialDocumentExtensionIcon filename={row.fileName} />

                    <span>
                        {name}
                        {extension ? (
                            <span className="text-gray-500">.{extension}</span>
                        ) : null}
                    </span>
                </span>
            </td>

            <td className={styles.placeholderStatus}>
                {row.isUploading ? <Loader inline /> : <CheckIcon />}
            </td>
        </tr>
    );
};
