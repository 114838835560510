import "./DateRangeFilter.scss";
import React, { useCallback, useMemo, useRef } from "react";
import {
    DateRangePicker,
    DateSetFrom,
} from "../../forms/dates/DateRangePicker/DateRangePicker";
import {
    PopoverButtonProps,
    PopoverContainer,
    PopoverContainerHandle,
} from "../PopoverContainer";
import { DateRange, getDateRangeLabel } from "../../../helpers/date";
import classNames from "classnames";

interface Props extends PopoverButtonProps {
    dateRange: Partial<DateRange>;
    onChange: (value: Partial<DateRange>) => void;
    popoverOffset?: number;
    monthOnly?: boolean;
    requireSelection?: boolean;
}

const VISIBLE_PRESETS = [
    "last_month",
    "this_month",
    "last_4_months",
    "last_quarter",
    "this_quarter",
    "this_year",
    "last_year",
];

export const DateRangeFilter: React.FC<Props> = ({
    dateRange,
    onChange,
    popoverOffset = 4,
    monthOnly,
    requireSelection,
    ...popoverButtonProps
}) => {
    const { start, end } = dateRange;

    const popoverRef = useRef<PopoverContainerHandle>(null);

    const dateLabel = useMemo(
        () => getDateRangeLabel(start, end, monthOnly),
        [start, end, monthOnly],
    );
    const onDateRangeChanged = useCallback(
        (newValue: Partial<DateRange>, setFrom: DateSetFrom | undefined) => {
            if (
                newValue.start?.getTime() !== start?.getTime() ||
                newValue.end?.getTime() !== end?.getTime()
            ) {
                onChange(newValue);
            }

            if (setFrom === "calendar" || setFrom === "preset") {
                popoverRef.current?.close();
            }
        },
        [start, end, onChange],
    );

    return (
        <PopoverContainer
            buttonText={dateLabel || "All dates"}
            buttonClass={classNames("popover-activator", {
                "popover-activator--selected": start ?? end,
            })}
            id="filter_date_range"
            offset={popoverOffset}
            ref={popoverRef}
            popoverClass={"date-range-filter-popover"}
            {...popoverButtonProps}
        >
            <DateRangePicker
                onChange={onDateRangeChanged}
                initialValue={dateRange}
                visiblePresets={VISIBLE_PRESETS}
                monthOnly={monthOnly}
                requireSelection={requireSelection}
            />
        </PopoverContainer>
    );
};
