import { useQueryParam } from "../../../hooks/useQueryParam";
import { useEffect } from "react";
import { PARTNER_KEY_COOKIE, XID_COOKIE } from "../../../common/partnerStack";

const MAX_AGE = 90 * 24 * 60 * 60 * 1000; // 90 days

export function usePartnerStackCookies() {
    const partnerStackXid = useQueryParam("ps_xid") ?? "";
    const partnerStackPartnerKey = useQueryParam("ps_partner_key") ?? "";

    useEffect(() => {
        if (partnerStackXid) {
            document.cookie = `${XID_COOKIE}=${partnerStackXid}; samesite=strict; path=/; max-age=${MAX_AGE}`;
            document.cookie = `${PARTNER_KEY_COOKIE}=${partnerStackPartnerKey}; samesite=strict; path=/; max-age=${MAX_AGE}`;
        }
    }, [partnerStackPartnerKey, partnerStackXid]);
}
