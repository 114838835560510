import React from "react";
import styles from "./HorizontalLine.module.scss";
import classNames from "classnames";

interface HorizontalLineProps {
    className?: string;
}

export const HorizontalLine: React.FC<HorizontalLineProps> = ({
    children,
    className,
}) => {
    return <div className={classNames(styles.line, className)}>{children}</div>;
};
