import React from "react";
import { AutopilotCardSection } from "./AutopilotCardSection";
import { CheckSmallIcon } from "../../icons";

interface Props {
    header: string;
}

export const AutopilotCardCompleted: React.FC<Props> = ({
    header,
    children,
}) => {
    return (
        <AutopilotCardSection header={header} isCompleted>
            {children}
            <CheckSmallIcon className="icon-color-blue-600" />
        </AutopilotCardSection>
    );
};
