import React, { useCallback } from "react";
import { useUser } from "../../hooks/useUser";
import { useEntities } from "../../hooks/useEntities";
import { Entity } from "../../common/types/entity";
import { usePartnerStackCookies } from "../onboarding/Register/usePartnerStackCookies";
import { completeOnboarding } from "../../lib/user";
import { Registration } from "./views/Registration/Registration";
import { PrimaryOnboardingFlow } from "./views/PrimaryFlow/PrimaryOnboardingFlow";

export const OnboardingV2: React.FC = () => {
    usePartnerStackCookies();

    const user = useUser();
    const businessEntity = useEntities({
        onlyBusiness: true,
        excludeEmptyMockEntities: false,
    })[0] as Entity | undefined;

    const handleFinishOnboarding = useCallback(async () => {
        await completeOnboarding();

        window.location.replace("/");
    }, []);

    if (!user) {
        return <Registration />;
    }

    if (businessEntity && !businessEntity.onboardingComplete) {
        return (
            <PrimaryOnboardingFlow
                entity={businessEntity}
                onFinish={handleFinishOnboarding}
            />
        );
    }

    return null;
};
