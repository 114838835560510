import { partition } from "lodash";
import { useQuery } from "react-query";
import { GetTransactionsResponseDto } from "../../common/dto/transactions/getTransactions/get-transactions-response.dto";
import { TransactionFiltersDto } from "../../common/dto/transactions/getTransactions/transaction-filters.dto";
import { WithRequiredProperties } from "../../common/types/base/generics";
import { Category } from "../../common/types/category";
import { Entity } from "../../common/types/entity";
import { GenericCategory } from "../../common/types/genericCategory";
import { getTransactions } from "../../lib/transactions";
import { queryClient } from "../../queryClient";
import { TransactionsFilters } from "./filters/lib";
import { Sort } from "./useSort";
import { Transaction } from "../../common/types/transaction";

export const TRANSACTIONS_PAGE_SIZE = 20;
const TRANSACTIONS_QUERY_KEY = "transactions";

interface UseTransactionsQueryParams {
    filters: WithRequiredProperties<TransactionsFilters, "entitiesAccounts">;
    sort?: Sort;
    page: number;
    onSuccess?: (response: GetTransactionsResponseDto) => void;
    limit?: number;
}

export function useTransactionsQuery({
    filters,
    sort,
    page,
    onSuccess,
    limit,
}: UseTransactionsQueryParams) {
    return useQuery(
        [TRANSACTIONS_QUERY_KEY, filters, sort, page, limit],
        () =>
            getTransactions({
                page: page - 1,
                limit: limit ?? TRANSACTIONS_PAGE_SIZE,
                filters: convertFiltersToDto(filters),
                sort,
            }),
        {
            onSuccess,
        },
    );
}

export function convertFiltersToDto(
    filters: WithRequiredProperties<TransactionsFilters, "entitiesAccounts">,
): TransactionFiltersDto;
export function convertFiltersToDto<T extends TransactionsFilters>(
    filters: T,
    entities: Entity[],
): TransactionFiltersDto;
export function convertFiltersToDto<T extends TransactionsFilters>(
    filters: T,
    entities?: Entity[],
): TransactionFiltersDto {
    const [categories, customCategories] = partition<GenericCategory, Category>(
        filters.category,
        (genericCategory): genericCategory is Category =>
            genericCategory.type === "category",
    );
    return {
        ...filters,
        entitiesAccounts: filters.entitiesAccounts
            ? filters.entitiesAccounts
            : entities?.map((entity) => ({
                  entityId: entity.id,
              })) ?? [],
        category: categories.map((category) => category.id),
        customCategory: customCategories.map(
            (customCategory) => customCategory.id,
        ),
        requiredAction: filters.requiredAction || undefined, // nosonar - false is first converted to string 'false', and then backend interprets it as truthy
    };
}

export async function invalidateTransactionsQueries(): Promise<void> {
    return await queryClient.invalidateQueries(TRANSACTIONS_QUERY_KEY);
}

export function updateTransactionInTransactionsQuery(
    transactionId: number,
    transactionUpdate: Partial<Transaction>,
) {
    queryClient.setQueriesData({ queryKey: TRANSACTIONS_QUERY_KEY }, ((
        oldData: GetTransactionsResponseDto,
    ) => {
        return {
            ...oldData,
            data: oldData.data.map((fetchedTransaction) => {
                const isTargetTransaction =
                    fetchedTransaction.id === transactionId;
                if (isTargetTransaction) {
                    return {
                        ...fetchedTransaction,
                        ...transactionUpdate,
                    };
                }
                return fetchedTransaction;
            }),
        };
    }) as any);
}
