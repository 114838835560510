import React, { Fragment } from "react";
import { ReportFilters } from "../../../common/types/filters/reports";
import { ProfitLossReportDto } from "../../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import {
    PLColumns,
    useProfitLossReportTable,
} from "../ProfitLossReportTable/useProfitLossReportTable";
import { useTableData } from "../ProfitLossReportTable/useTableData";
import { useProfitLossReportTableColumns } from "../ProfitLossReportTable/useProfitLossReportTableColumns";
import { useExpandRows } from "../ProfitLossReportTable/useExpandRows";

import { ProfitLossReportDownloadCell } from "./ProfitLossReportDownloadCell";
import "./ProfitLossReportDownloadTable.scss";
import { format } from "date-fns";
import { useEntities } from "../../../hooks/useEntities";
import { Entity } from "../../../common/types/entity";
import { fullDateFullYearFormat } from "../../../common/helpers/date";
import {
    realReportDataEnd,
    realReportDataStart,
} from "../../../common/helpers/reports";

interface Props {
    report: ProfitLossReportDto;
    filters: ReportFilters;
}

export const PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID = "sheets-js-report";

function shouldExpandRow(canExpand: boolean) {
    return canExpand;
}

const TABLE_PROPS: React.HTMLAttributes<HTMLTableElement> = {
    id: PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID,
    style: { display: "none" },
};

function getEntityNames(filters: ReportFilters, entities: Entity[]) {
    const entitiesIds = filters.entitiesAccounts?.map((e) => e.entityId);
    return entities
        .filter((e) => entitiesIds?.includes(e.id))
        .map((e) => e.name)
        .join(", ");
}

const grouping = [PLColumns.SECTION, PLColumns.PARENT_CATEGORY];

export const ProfitLossReportDownloadTable: React.FC<Props> = ({
    report,
    filters,
}) => {
    const tableData = useTableData(report);
    const columns = useProfitLossReportTableColumns(report, grouping, false);
    const table = useProfitLossReportTable(tableData, columns, grouping);
    useExpandRows(table, report, shouldExpandRow);
    const startDate = format(
        realReportDataStart(report.meta.dataStart, filters.start),
        fullDateFullYearFormat,
    );
    const endDate = format(
        realReportDataEnd(report.meta.dataEnd, filters.end),
        fullDateFullYearFormat,
    );
    const entities = useEntities({ onlyBusiness: true });
    const entityNames = getEntityNames(filters, entities);

    return (
        <table className={"reports-download-table__table"} {...TABLE_PROPS}>
            <tbody>
                <tr>
                    <td
                        colSpan={2}
                        className={
                            "reports-download-table__cell--bold reports-download-table__cell--center"
                        }
                        style={{ fontSize: "24px" }}
                    >
                        {entityNames}
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={2}
                        className={"reports-download-table__cell--center"}
                    >
                        {startDate} - {endDate}
                    </td>
                </tr>
                <tr>
                    <td className={"reports-download-table__cell--bold"}>
                        Profit and Loss
                    </td>
                    <td
                        className={
                            "reports-download-table__cell--bold reports-download-table__cell--right"
                        }
                    >
                        Total
                    </td>
                </tr>
                {table.getRowModel().rows.map((row) => (
                    <Fragment key={row.id}>
                        {row.depth === 0 && (
                            <tr>
                                <td colSpan={2} />
                            </tr>
                        )}
                        <tr>
                            {row.getVisibleCells().map((cell) => (
                                <ProfitLossReportDownloadCell
                                    key={cell.id}
                                    cell={cell}
                                />
                            ))}
                        </tr>
                    </Fragment>
                ))}
                <tr>
                    <td colSpan={2}></td>
                </tr>
                <tr>
                    <td
                        colSpan={2}
                        className={"reports-download-table__cell--center"}
                    >
                        Kick.co | Modified Cash Basis | Unaudited |{" "}
                        {format(new Date(), "MMM d, yyyy HH:mm")}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
