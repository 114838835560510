import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    DEFAULT_ACTIVITY_FEED_FILTERS,
    TransactionsFilters,
} from "./filters/lib";
import { TransactionPageProps, TransactionsPage } from "./TransactionsPage";
import "./TransactionsPage.scss";
import { useSort } from "./useSort";
import { TransactionsContext } from "./context/transactions.context";
import { Pagination, PaginationProps } from "../general/Pagination/Pagination";
import { useQueryParam } from "../../hooks/useQueryParam";
import { PotentialSavingsProvider } from "./PotentialSavingsContext";
import { TransactionsActionBar } from "./TransactionsBulkActions/TransactionsActionBar/TransactionsActionBar";
import { TransactionsBulkActionsContextProvider } from "./TransactionsBulkActions/TransactionsBulkActionsContextProvider";
import { BulkUpdateMode } from "./TransactionsBulkActions/lib";
import { getTransactionsCsv } from "../../lib/transactions";
import fileDownload from "js-file-download";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import {
    convertFiltersToDto,
    invalidateTransactionsQueries,
    TRANSACTIONS_PAGE_SIZE,
    useTransactionsQuery,
} from "./useTransactionsQuery";
import { useEntities } from "../../hooks/useEntities";
import { useFinancialAccountsCache } from "../../hooks/useFinancialAccountsCache";
import { invalidateTransactionDetailsQueries } from "./TransactionDetails/useTransactionDetailsQuery";

export const TransactionsPageContainer: React.FC = () => {
    const shownTransactionId = useQueryParam("transactionId");
    const shownTransactionType = useQueryParam("transactionType");
    const requiredActionsOnly = useQueryParam("actionsRequired");
    // to be removed after demo
    const filtersParam = useQueryParam("filters");

    const initialEntityId = useQueryParam("entityId");
    const { transactions, updateMany, setShownTransactionId, setTransactions } =
        useContext(TransactionsContext);
    const [cachedAccounts, setCachedAccounts] = useFinancialAccountsCache();
    const entities = useEntities();
    const [filters, setFilters] = useState<TransactionsFilters>(() => {
        const initialFilters: TransactionsFilters = {
            ...DEFAULT_ACTIVITY_FEED_FILTERS,
        };

        initialFilters.requiredAction = Boolean(requiredActionsOnly);

        if (cachedAccounts) {
            const accountsFilter = cachedAccounts.filter((ca) =>
                entities.some((e) => e.id === ca.entityId),
            );
            if (accountsFilter.length) {
                initialFilters.entitiesAccounts = accountsFilter;
            }
        }

        if (initialEntityId) {
            const entity = entities.find(
                (e) => e.id === parseInt(initialEntityId),
            );
            if (entity) {
                initialFilters.entitiesAccounts = [{ entityId: entity.id }];
            }
        }

        if (filtersParam) {
            const parsed = JSON.parse(filtersParam);
            Object.assign(initialFilters, parsed);

            if (parsed.start) {
                initialFilters.start = new Date(parsed.start);
            }

            if (parsed.end) {
                initialFilters.end = new Date(parsed.end);
            }
        }

        return initialFilters;
    });
    const [page, setPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const {
        currentSort,
        handleSortChange: setSort,
        sortExpression,
    } = useSort();
    const { invalidate: invalidateCreditsUsed } = useBillingStatus();

    const queryFiltersToPass = {
        ...filters,
        entitiesAccounts:
            filters.entitiesAccounts && filters.entitiesAccounts.length > 0
                ? filters.entitiesAccounts
                : entities.map((e) => ({
                      entityId: e.id,
                  })),
    };

    useTransactionsQuery({
        filters: queryFiltersToPass,
        sort: sortExpression,
        page: page,
        onSuccess: (response) => {
            if (response.pageCount >= page) {
                setTransactions(response.data);
            }

            if (page > response.pageCount) {
                setPage(response.pageCount);
            } else {
                setTotalCount(response.total);
                setPageCount(response.pageCount);
            }

            return response;
        },
    });

    useEffect(() => {
        if (shownTransactionId && shownTransactionType) {
            setTimeout(() => {
                // delay to make sure transaction details are fetched after transaction list
                setShownTransactionId(parseInt(shownTransactionId));
            }, 500);
        }
    }, [shownTransactionId, shownTransactionType, setShownTransactionId]);

    const exportTransactions = useCallback(async () => {
        const response = await getTransactionsCsv(
            convertFiltersToDto(filters, entities),
        );

        fileDownload(response, `transactions.csv`, "text/csv");
    }, [filters, entities]);

    const handleFiltersChange: TransactionPageProps["onFiltersChange"] =
        useCallback(
            (newFilters) => {
                setShownTransactionId(undefined);
                setPage(1);
                setFilters(newFilters ?? {});
                setCachedAccounts(newFilters?.entitiesAccounts);
            },
            [setShownTransactionId, setCachedAccounts],
        );

    const handleSortChange: TransactionPageProps["onSortChange"] = useCallback(
        (sort) => {
            setShownTransactionId(undefined);
            setPage(1);
            setSort(sort);
        },
        [setSort, setShownTransactionId],
    );

    const handlePageChange: PaginationProps["onPageChange"] = useCallback(
        (nextPage) => {
            setPage(nextPage);
            setShownTransactionId(undefined);
        },
        [setShownTransactionId],
    );

    const onBulkUpdated = useCallback(
        (updatedTransactions, { mode }) => {
            if (mode === BulkUpdateMode.SELECTED) {
                updateMany(updatedTransactions);
            } else {
                void invalidateTransactionsQueries();
                setPage(1);
            }

            void invalidateCreditsUsed();
            void invalidateTransactionDetailsQueries();
        },
        [invalidateCreditsUsed, updateMany],
    );

    return (
        <PotentialSavingsProvider filters={queryFiltersToPass}>
            <TransactionsBulkActionsContextProvider
                currentFilters={filters}
                totalTransactions={totalCount}
                onUpdated={onBulkUpdated}
            >
                <TransactionsPage
                    transactions={transactions}
                    exportTransactions={exportTransactions}
                    currentSort={currentSort}
                    currentPage={page}
                    totalPages={pageCount}
                    onSortChange={handleSortChange}
                    onFiltersChange={handleFiltersChange}
                    currentFilters={filters}
                >
                    {totalCount > 0 && (
                        <Pagination
                            pageCount={pageCount}
                            currentPage={page}
                            totalCount={totalCount}
                            onPageChange={handlePageChange}
                            pageSize={TRANSACTIONS_PAGE_SIZE}
                        />
                    )}
                </TransactionsPage>
                <TransactionsActionBar />
            </TransactionsBulkActionsContextProvider>
        </PotentialSavingsProvider>
    );
};
