import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useWidgetOptions } from "./useWidgetOptions";

interface BookCallProps {
    className?: string;
    url: string;
    onBooked?: () => void;
}

export const BookCallWidget: React.FC<BookCallProps> = ({
    url,
    onBooked,
    className,
}) => {
    const { prefill, pageSettings } = useWidgetOptions();

    useCalendlyEventListener({
        onEventScheduled: onBooked,
    });

    return (
        <div className={className}>
            <InlineWidget
                url={url}
                pageSettings={pageSettings}
                prefill={prefill}
            />
        </div>
    );
};
