import React, { forwardRef, useMemo } from "react";
import { ButtonProps, Dropdown } from "react-bootstrap";
import { Logo } from "../general/Logo";
import { useUser } from "../../hooks/useUser";
import { useWorkspaceContext } from "../../state/workspaceContext";
import styles from "./NavigationDropdown.module.scss";
import { useSettings } from "../settings/SettingsProvider";
import { useNavigationSearch } from "./Search/NavigationSearchProvider";
import { ArrowDownIcon } from "../../icons";
import { useInteraction } from "../../hooks/useInteraction";
import { useWorkspaces } from "../../hooks/useWorkspaces";
import classNames from "classnames";
import { ObjectIcon } from "../general/ObjectIcon/ObjectIcon";
import colors from "@styles/colors.module.scss";
import { useKeyboardCommands } from "../../hooks/useKeyboardCommands";
import { useLogout } from "../../hooks/useLogout";

const MAX_WORKSPACE_SHORT_NAME_LENGTH = 13;

const CustomToggle: React.FC<ButtonProps> = forwardRef(({ onClick }, ref) => {
    const toggleClick = useInteraction(onClick);

    const { activeWorkspace } = useWorkspaceContext();

    const shortName = useMemo(() => {
        const regexGroups = activeWorkspace?.name?.match(/(.+)'s workspace/i);
        const extractedName = regexGroups?.[1] ?? "";
        return extractedName.length > MAX_WORKSPACE_SHORT_NAME_LENGTH
            ? `${extractedName.slice(0, MAX_WORKSPACE_SHORT_NAME_LENGTH)}...`
            : extractedName;
    }, [activeWorkspace?.name]);

    return (
        <button
            {...toggleClick}
            className={styles.topLeftBar}
            ref={ref as any}
            data-testid="workspace-button"
        >
            <div className={styles.logoContainer}>
                <Logo className={styles.logo} variant="icon" />
            </div>

            <div
                className={styles.activeWorkspaceName}
                data-testid="active-workspace-name"
            >
                {shortName}
                <ArrowDownIcon className={styles.arrowDownIcon} />
            </div>
        </button>
    );
});

export const NavigationDropdown = () => {
    const user = useUser();

    const { activeWorkspace } = useWorkspaceContext();

    const { open: openSettings } = useSettings();
    const { open: openSearch } = useNavigationSearch();
    const logout = useLogout();

    const userWorkspaces = useWorkspaces();

    const hasMultipleWorkspaces = (userWorkspaces.data?.length ?? 0) > 1;

    const userRoleInWorkspace = useMemo(() => {
        if (!activeWorkspace) return "Member";
        return activeWorkspace.primaryAdminId === user.id ? "Owner" : "Member";
    }, [activeWorkspace, user]);

    useKeyboardCommands({
        commands: [
            {
                key: "s",
                callback: () => openSearch("workspaces"),
            },
        ],
    });

    return (
        <Dropdown className={styles.dropdown}>
            <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu} align="left">
                <div
                    className={classNames(
                        styles.navigationDropdownItemTextOnly,
                        styles.navigationDropdownWorkspaceName,
                    )}
                    data-testid="dropdown-workspace-name"
                >
                    {activeWorkspace?.name ?? ""}
                </div>
                <div
                    className={classNames(
                        styles.navigationDropdownItemTextOnly,
                        styles.navigationDropdownUserEmail,
                    )}
                    data-testid="user-email"
                >
                    {user.email}
                </div>
                <div
                    className={classNames(
                        styles.navigationDropdownItemTextOnly,
                        styles.navigationDropdownUserRoleItem,
                    )}
                >
                    <span
                        className={styles.navigationDropdownUserRole}
                        data-testid="user-role"
                    >
                        {userRoleInWorkspace}
                    </span>
                </div>
                <Dropdown.Divider />

                <Dropdown.Item
                    onClick={() => openSettings()}
                    data-testid="settings-button"
                >
                    Settings
                </Dropdown.Item>
                <Dropdown.Divider />
                {hasMultipleWorkspaces && (
                    <Dropdown.Item
                        onClick={() => openSearch("workspaces")}
                        disabled={userWorkspaces.isLoading}
                        className={styles.dropdownMenuSwitchWorkspaceItem}
                        data-testid="switch-workspace-button"
                    >
                        Switch workspace{" "}
                        <div
                            className={
                                styles.dropdownMenuSwitchWorkspaceIconContainer
                            }
                        >
                            <ObjectIcon
                                name="⌘"
                                defaultColor={colors.miscAlpha100}
                                size="xs"
                                square
                                className={
                                    styles.dropdownMenuSwitchWorkspaceIcon
                                }
                            />
                            <ObjectIcon
                                name="s"
                                defaultColor={colors.miscAlpha100}
                                size="xs"
                                square
                                className={
                                    styles.dropdownMenuSwitchWorkspaceIcon
                                }
                            />
                        </div>
                    </Dropdown.Item>
                )}
                <Dropdown.Item onClick={logout} data-testid="logout-button">
                    Log out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
