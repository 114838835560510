import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { PLDataRow } from "./ProfitLossReportTableContainer";
import { Taxonomy } from "../../../common/categories";
import { values } from "lodash";
import { PLColumns, sectionLabels } from "./useProfitLossReportTable";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { useCategoryMap } from "../../../hooks/useCategoryMap";
import { ProfitLossReport } from "../types";

export function useProfitLossReportTableColumns(
    report: ProfitLossReport,
    grouping: PLColumns[],
    includeDataColumns = true,
) {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();

    return useMemo(() => {
        const columns: ColumnDef<PLDataRow>[] = [
            {
                id: PLColumns.TITLE,
                header: "",
            },
            {
                id: PLColumns.SECTION,
                header: "",
                accessorFn: (row) =>
                    row.category
                        ? getCategoryLabel(row.category as Taxonomy)
                        : sectionLabels[row.section],
                getGroupingValue: (row) => row.section,
            },
            ...(includeDataColumns
                ? report.columnLabels.map((col) => ({
                      header: col,
                      accessorKey: col,
                      accessorFn: (row: PLDataRow) => row.data[col] || 0,
                  }))
                : []),
            {
                id: PLColumns.TOTAL,
                header: "Total",
                accessorFn: (row) => {
                    return values(row.data).reduce(
                        (acc, value) => acc + value,
                        0,
                    );
                },
            },
        ];

        if (grouping.includes(PLColumns.PARENT_CATEGORY)) {
            columns.push({
                id: PLColumns.PARENT_CATEGORY,
                header: "Parent Category",
                accessorFn: (row) =>
                    row.category
                        ? categoryMap[row.category as Taxonomy].parentCategoryId
                        : "",
                getGroupingValue: (row) => {
                    if (!row.category) {
                        return "";
                    }

                    const parentCategory =
                        categoryMap[row.category as Taxonomy].parentCategoryId;

                    return parentCategory ?? row.category;
                },
            });
        }

        return columns;
    }, [
        grouping,
        includeDataColumns,
        report.columnLabels,
        getCategoryLabel,
        categoryMap,
    ]);
}
