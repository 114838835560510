import React from "react";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { AccountsConnectionWidget } from "../../financialAccount/AccountConnectionWidget/AccountsConnectionWidget";
import { PlaidConnectionIcon } from "../PlaidConnectionIcon";
import { DuplicatePlaidAccount } from "../../../common/dto/plaid/plaid-common";
import { getAnonymousAccountNumber } from "../../../common/helpers/bankAccount";

interface Props {
    connection: PlaidConnectionWithAccounts;
    duplicates: DuplicatePlaidAccount[] | null;
}

export const PlaidAccountsConnectedModalBody: React.FC<Props> = ({
    connection,
    duplicates,
}) => {
    const accounts = connection.accounts;

    return (
        <>
            <AccountsConnectionWidget
                heading={
                    <div className="d-flex align-items-center">
                        <PlaidConnectionIcon connection={connection} />

                        <h6 className="ml-3">{connection.bankName}</h6>
                    </div>
                }
                accounts={accounts}
                connection={connection}
            />

            {duplicates && duplicates.length > 0 && (
                <>
                    <p className="mt-3">
                        The following <strong>{connection.bankName}</strong>{" "}
                        accounts are already connected to Kick and were skipped:
                    </p>
                    <ul>
                        {duplicates.map((duplicate) => (
                            <li
                                key={
                                    duplicate.name +
                                    duplicate.accountNumberLast4
                                }
                            >
                                {duplicate.name}{" "}
                                {duplicate.accountNumberLast4
                                    ? getAnonymousAccountNumber(
                                          duplicate.accountNumberLast4,
                                      )
                                    : ""}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};
