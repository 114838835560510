import React from "react";
import { useDataAttributes } from "../../hooks/useDataAttributes";

export function extractDataAttributes<P = {}>(
    WrappedComponent: React.ComponentType<
        P & { dataAttributes: Record<string, string> }
    >,
) {
    const Cmp: React.FC<P> = (props) => {
        const dataAttributes = useDataAttributes(props);

        return <WrappedComponent {...props} dataAttributes={dataAttributes} />;
    };

    return Cmp;
}
