import React from "react";
import { SettingsSection } from "../../SettingsSection";
import { getTierForUsage } from "../../../../common/billing";
import { PricingTier, TierVariant } from "./PricingTier";
import { useCurrentPricingTier } from "../../../../hooks/useCurrentPricingTier";
import "./PricingTiers.scss";
import { CustomPricingCallButton } from "../CustomPricingCallButton";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";

export const PricingTiers: React.FC = () => {
    const { creditsUsed, pricingTiers } = useBillingStatus();

    const currentTier = useCurrentPricingTier(creditsUsed, pricingTiers);

    const currentTierIndex = pricingTiers.indexOf(currentTier); // for now, until we have subscription status here
    const requiredTierIndex = pricingTiers.indexOf(
        getTierForUsage(pricingTiers, creditsUsed),
    );

    return (
        <SettingsSection
            heading={<h4>Pricing Tiers</h4>}
            className="billing__pricing-tiers pricing-tiers"
        >
            <div className="pricing-tiers__container">
                {pricingTiers.map((tier, idx) => (
                    <PricingTier
                        key={tier.price}
                        tier={tier}
                        variant={getTierVariant(
                            idx,
                            currentTierIndex,
                            requiredTierIndex,
                        )}
                    />
                ))}
            </div>

            <footer className="pricing-tiers__footer">
                <CustomPricingCallButton
                    className="btn btn-xs btn-link text-gray-700"
                    text="Book a call"
                />{" "}
                if you expect $150k+ in business expenses for custom pricing
            </footer>
        </SettingsSection>
    );
};

function getTierVariant(
    tierIndex: number,
    currentTierIndex: number,
    nextTierIndex: number,
) {
    if (tierIndex === currentTierIndex) {
        return TierVariant.CURRENT;
    } else if (tierIndex === nextTierIndex) {
        return TierVariant.NEXT;
    } else if (tierIndex > nextTierIndex) {
        return TierVariant.FUTURE;
    } else {
        return null;
    }
}
