import { useMemo } from "react";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import type { OnboardingNavStep } from "./OnboardingNav";
import type { OnboardingStep } from "../../reducers/onboarding";

export function useOnboardingSteps() {
    const { flatRateBilling } = useTypedFlags();

    return useMemo((): OnboardingNavStep<OnboardingStep>[] => {
        if (flatRateBilling) {
            return [
                {
                    keys: [
                        "intro",
                        "register",
                        "personal_info",
                        "business_profile",
                        "business_description",
                    ],
                    label: "Business profile",
                },
                {
                    keys: ["connect_accounts"],
                    label: "Connect accounts",
                },
                {
                    keys: ["receipts_start", "receipts_finish"],
                    label: "Match receipts",
                },
                {
                    keys: ["choose_plan"],
                    label: "Choose plan",
                },
                {
                    keys: [],
                    label: "Start autopilot",
                },
            ];
        } else {
            return [
                {
                    keys: ["intro", "register", "personal_info"],
                    label: "Create account",
                },
                {
                    keys: ["business_profile", "business_description"],
                    label: "Business profile",
                },
                {
                    keys: [
                        "connect_accounts",
                        "receipts_start",
                        "receipts_finish",
                    ],
                    label: "Connect accounts",
                },
                {
                    keys: [],
                    label: "Profit & loss",
                },
            ];
        }
    }, [flatRateBilling]);
}
