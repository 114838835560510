import React, { forwardRef, PropsWithChildren } from "react";
import classNames from "classnames";
import css from "./Card.module.scss";
import { CardSection } from "./CardSection";

interface Props {
    className?: string;
    withTabs?: boolean;
    onClick?: () => void;
    label?: string;
    stacked?: boolean;
}
export const Card = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    ({ children, className, withTabs, label, stacked, ...otherProps }, ref) => {
        return (
            <div
                ref={ref}
                className={classNames(css.card, className, {
                    [css.withTabs]: withTabs,
                    [css.stacked]: stacked || Boolean(label),
                })}
                {...otherProps}
            >
                {label && (
                    <CardSection className={css.label}>{label}</CardSection>
                )}
                {children}
            </div>
        );
    },
);
