import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Transaction } from "../common/types/transaction";

export function useShowTransactionDetails<
    T extends Pick<Transaction, "id" | "type">,
>() {
    const history = useHistory();

    return useCallback(
        (transaction: T) => {
            history.push(
                `/transactions?transactionId=${transaction.id}&transactionType=${transaction.type}`,
            );
        },
        [history],
    );
}
