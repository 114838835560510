import React, { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { Transaction } from "../../../common/types/transaction";
import { isUncategorized } from "../lib";
import { TransactionCategory } from "../TransactionCategory";
import { Clock2Icon, DottedCircleIcon } from "../../../icons";
import "./CategorySelectPrompt.scss";
import { useInteraction } from "../../../hooks/useInteraction";

export type CategorySelectPromptVariant = "default" | "warning";

export interface CategorySelectPromptProps {
    transaction: Transaction;
    variant?: CategorySelectPromptVariant;
    onClick?(): void;
    active?: boolean;
    readonly?: boolean;
}

const tooltipConfig = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, 4],
            },
        },
    ],
};

export const CategorySelectPrompt: React.FC<CategorySelectPromptProps> = ({
    transaction,
    onClick,
    variant = "default",
    active,
    readonly,
}) => {
    const uncategorized =
        transaction.isBusiness &&
        isUncategorized(transaction) &&
        (!transaction.split || transaction.split.length === 0);
    const ref = useRef<HTMLElement>();
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const openSelect = useInteraction(onClick);
    const showTooltip = useInteraction(
        () => setTooltipVisible(true),
        "onMouseEnter",
    );
    const hideTooltip = useInteraction(
        () => setTooltipVisible(false),
        "onMouseLeave",
    );

    return (
        <span
            {...openSelect}
            {...showTooltip}
            {...hideTooltip}
            ref={ref as any}
            className={classNames(
                `category-select-prompt category-select-prompt--${variant}`,
                {
                    "category-select-prompt--uncategorized": uncategorized,
                    "category-select-prompt--active": active,
                    "category-select-prompt--readonly": readonly,
                },
            )}
            data-testid="category-select-prompt"
        >
            {uncategorized ? (
                <>
                    <span className="category-select-prompt__empty">
                        {transaction.inReview ? (
                            <>
                                <Clock2Icon className="icon-color-current" />
                                Categorizing
                            </>
                        ) : (
                            <>
                                <DottedCircleIcon className="icon-color-current" />{" "}
                                Select category
                            </>
                        )}
                    </span>

                    <Overlay
                        target={ref.current!}
                        show={!active && tooltipVisible && transaction.inReview}
                        placement="top"
                        popperConfig={tooltipConfig}
                    >
                        {(props) => (
                            <Tooltip
                                id={`uncategorized_${transaction.id}`}
                                {...props}
                            >
                                This transaction is being reviewed.
                            </Tooltip>
                        )}
                    </Overlay>
                </>
            ) : (
                <TransactionCategory transaction={transaction} />
            )}
        </span>
    );
};
