import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalComponentWithResultProps } from "../../helpers/modal";
import { useIgnoreLoosingAccessToTransaction } from "../../hooks/useIgnoreLoosingAccessToTransaction";
import { FormCheckbox } from "../forms/FormCheckbox/FormCheckbox";

interface Props extends ModalComponentWithResultProps<boolean> {
    ownerName: string;
}

export const LoosingAccessToTransactionModal: React.FC<Props> = ({
    ownerName,
    close,
}) => {
    const firstName = ownerName.split(" ")[0];
    const { setIgnoreLoosingAccess } = useIgnoreLoosingAccessToTransaction();
    const [isChecked, setIsChecked] = useState(false);

    const onContinue = useCallback(() => {
        if (isChecked) {
            setIgnoreLoosingAccess();
        }
        close(true);
    }, [setIgnoreLoosingAccess, close, isChecked]);

    return (
        <>
            <Modal.Header closeButton>
                <h3 className="mb-0">
                    You will lose access to this transaction
                </h3>
            </Modal.Header>
            <Modal.Body>
                <p className="text-prominent">
                    This transaction was initiated by{" "}
                    <strong>{ownerName}</strong>, so if you mark it as Personal,
                    it will be shown on {firstName}'s account only. Are you sure
                    you want to continue?
                </p>

                <FormCheckbox
                    value="dontShow"
                    isChecked={isChecked}
                    label="Don't show this again"
                    handleChange={() => setIsChecked(!isChecked)}
                />

                <footer className="d-flex justify-content-between mt-5">
                    <Button variant="secondary" onClick={() => close(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={onContinue}>
                        Continue
                    </Button>
                </footer>
            </Modal.Body>
        </>
    );
};
