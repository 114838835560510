import { isString } from "lodash";

const ENTITY_PREFIX = "entity-";

export function getEntityKey(entityId: number) {
    return `${ENTITY_PREFIX}${entityId}`;
}

export function getEntityId(itemId: string | number) {
    return isString(itemId) && itemId.startsWith(ENTITY_PREFIX)
        ? parseInt(itemId.replace(ENTITY_PREFIX, ""))
        : null;
}
