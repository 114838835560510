import { Entity } from "../common/types/entity";
import {
    FinancialAccount,
    FinancialAccountSourceType,
} from "../common/types/financialAccount";
import isNumber from "lodash/isNumber";
import { useEntitiesContexts } from "./useEntitiesContexts";
import { useMemo } from "react";

interface UseFinancialAccountsOptions {
    entityId?: number;
    entityIds?: number[];
    excludeNoBalanceAccounts?: boolean;
    onlyBusiness?: boolean;
    excludeMockEntities?: boolean;
    sourceType?: FinancialAccountSourceType;
    connectionId?: number;
    connectionIds?: number[];
}

export function usePlaidFinancialAccounts(
    options: UseFinancialAccountsOptions = {},
) {
    return useFinancialAccounts({
        ...options,
        sourceType: FinancialAccountSourceType.PLAID,
    });
}

export function usePayrollFinancialAccounts(
    options: UseFinancialAccountsOptions = {},
) {
    return useFinancialAccounts({
        ...options,
        sourceType: FinancialAccountSourceType.GUSTO,
    });
}

export function useFinancialAccounts({
    entityId,
    entityIds,
    sourceType,
    connectionId,
    connectionIds,
    excludeNoBalanceAccounts = false,
    onlyBusiness = false,
    excludeMockEntities = false,
}: UseFinancialAccountsOptions = {}) {
    connectionIds = useMemo(
        () => (connectionId ? [connectionId] : connectionIds),
        [connectionId, connectionIds],
    );
    entityIds = useMemo(
        () => (entityId ? [entityId] : entityIds),
        [entityId, entityIds],
    );

    const entitiesContexts = useEntitiesContexts({
        onlyBusiness,
        excludeMockEntities,
    });

    return useMemo(() => {
        let accounts = (entitiesContexts ?? [])
            .filter((ec) => !entityIds || entityIds.includes(ec.entity.id))
            .map((e) => e.financialAccounts)
            .flat();

        if (connectionIds) {
            accounts = accounts.filter((a) =>
                a.integrationAccounts
                    ?.map((ia) => ia.connectionId)
                    .some((cid) => cid && connectionIds.includes(cid)),
            );
        }
        if (excludeNoBalanceAccounts) {
            accounts = accounts.filter((a) => isNumber(a.balance));
        }
        if (sourceType) {
            accounts = accounts.filter((a) => a.sourceType === sourceType);
        }

        return accounts;
    }, [
        entitiesContexts,
        entityIds,
        connectionIds,
        excludeNoBalanceAccounts,
        sourceType,
    ]);
}

export function getTotalBalance(allAccounts: FinancialAccount[]) {
    return allAccounts
        .filter((acc) => isNumber(acc.balance))
        .reduce(($, acc) => $ + (acc.balance ?? 0), 0);
}

export function useTotalBalance(entity?: Entity) {
    const allAccounts = useFinancialAccounts({
        entityId: entity?.id,
    });

    return getTotalBalance(allAccounts);
}
