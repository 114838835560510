import React from "react";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { SubscriptionType } from "../../../common/types/billing";
import { BillingPageSubscribedToMonthly } from "./BillingPageSubscribedToMonthly";
import { SubscribedView as SubscribedToAnnual } from "./AnnualBilling/SubscribedView";

export const BillingPageSubscribed: React.FC = () => {
    const { subscriptionDetails } = useBillingStatus();

    switch (subscriptionDetails?.type) {
        case SubscriptionType.MONTHLY:
            return <BillingPageSubscribedToMonthly />;
        case SubscriptionType.ANNUAL:
            return <SubscribedToAnnual />;
        default:
            // custom subscription page is not implemented yet, future task
            return null;
    }
};
