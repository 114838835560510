import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import { isReducedMotionEnabled } from "../../../constants";

export const StandardModal: React.FC<
    React.ComponentProps<typeof BootstrapModal>
> = (props) => {
    return (
        <BootstrapModal
            {...props}
            animation={!isReducedMotionEnabled}
            data-testid="modal"
        >
            {props.children}
        </BootstrapModal>
    );
};
