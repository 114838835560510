import React from "react";
import { Transaction } from "../../common/types/transaction";
import { Entity } from "../../common/types/entity";
import { EntityMenuItem } from "../general/EntityMenuItem/EntityMenuItem";

interface Props {
    transaction?: Transaction;
    entity: Entity;
    onSelect: () => void;
}

export const TransactionEntityMenuItem: React.FC<Props> = ({
    transaction,
    entity,
    onSelect,
}) => {
    return (
        <EntityMenuItem
            onSelect={onSelect}
            entity={entity}
            isSelected={transaction?.entity.id === entity.id}
        />
    );
};
