import { Category } from "./types/category";

export enum Taxonomy {
    income = "income",
    interest_income = "interest_income",
    other_income = "other_income",
    advertising_marketing = "advertising_marketing",
    auto_vehicle = "auto_vehicle",
    auto_insurance = "auto_insurance",
    auto_payment = "auto_payment",
    parking_tolls = "parking_tolls",
    communications = "communications",
    learning_development = "learning_development",
    entertainment = "entertainment",
    fees = "fees",
    merchant_bank_fees = "merchant_bank_fees",
    licenses_permits = "licenses_permits",
    penalties_fines = "penalties_fines",
    insurance = "insurance",
    health_insurance = "health_insurance",
    life_insurance = "life_insurance",
    legal_professional = "legal_professional",
    meals = "meals",
    payroll = "payroll",
    contractors = "contractors",
    salaries_wages = "salaries_wages",
    payroll_taxes = "payroll_taxes",
    rentals_leases = "rentals_leases",
    repairs_maintenance = "repairs_maintenance",
    software_subscriptions = "software_subscriptions",
    taxes = "taxes",
    federal_taxes = "federal_taxes",
    local_taxes = "local_taxes",
    property_taxes = "property_taxes",
    sales_taxes = "sales_taxes",
    travel = "travel",
    utilities = "utilities",
    supplies_equipment = "supplies_equipment",
    office_supplies = "office_supplies",
    furniture_fixtures = "furniture_fixtures",
    props_production = "props_production",
    credit_card_loans = "credit_card_loans",
    credit_card_payment = "credit_card_payment",
    interest_paid = "interest_paid",
    loan_payment = "loan_payment",
    transfer = "transfer",
    contributions_distributions = "contributions_distributions",
    real_estate = "real_estate",
    improvements = "improvements",
    property_insurance = "property_insurance",
    management_fees = "management_fees",
    health_dental = "health_dental",
    donations = "donations",
    uncategorized = "uncategorized",
    retirement = "retirement",
    personal = "personal",
    home_office = "home_office",
    security_deposit = "security_deposit",
    principal_paid = "principal_paid",
    cogs = "cogs",
    direct_labor = "direct_labor",
    goods_materials = "goods_materials",
    other_cogs = "other_cogs",
    business_office = "business_office",
    mortgage_payment = "mortgage_payment",
    equipment_rentals = "equipment_rentals",
}

export enum TransactionDirectionType {
    incoming = "incoming",
    outgoing = "outgoing",
    mixed = "mixed",
}

interface MapCategoryNameParams {
    category: Category;
    transactionDirection: TransactionDirectionType;
}

export const mapCategoryName: (params: MapCategoryNameParams) => string = ({
    category,
    transactionDirection,
}) => {
    if (category.id === Taxonomy.contributions_distributions) {
        if (transactionDirection === TransactionDirectionType.incoming) {
            return "Contributions";
        }
        if (transactionDirection === TransactionDirectionType.outgoing) {
            return "Distributions";
        }
    }
    return category.label;
};

export const REAL_ESTATE_CATEGORIES: Taxonomy[] = [
    Taxonomy.real_estate,
    Taxonomy.management_fees,
    Taxonomy.improvements,
];

export type TaxonomyMainCategories = Extract<
    Taxonomy,
    | Taxonomy.income
    | Taxonomy.uncategorized
    | Taxonomy.auto_vehicle
    | Taxonomy.repairs_maintenance
    | Taxonomy.donations
    | Taxonomy.insurance
    | Taxonomy.travel
    | Taxonomy.transfer
    | Taxonomy.contributions_distributions
    | Taxonomy.legal_professional
    | Taxonomy.supplies_equipment
    | Taxonomy.real_estate
    | Taxonomy.health_dental
    | Taxonomy.entertainment
    | Taxonomy.software_subscriptions
    | Taxonomy.advertising_marketing
    | Taxonomy.taxes
    | Taxonomy.credit_card_loans
    | Taxonomy.meals
    | Taxonomy.fees
    | Taxonomy.rentals_leases
    | Taxonomy.utilities
    | Taxonomy.communications
    | Taxonomy.retirement
    | Taxonomy.payroll
    | Taxonomy.learning_development
    | Taxonomy.personal
    | Taxonomy.security_deposit
    | Taxonomy.cogs
>;
