import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import { monthlyDateFormat } from "../../../common/helpers/date";
import { Card } from "../../general/Card/Card";

interface Props {
    label: string;
    value: string;
    startDate: Date;
    endDate: Date;
    children: React.ReactNode;
}

export const ProfitLossReportSummaryCard: React.FC<Props> = ({
    label,
    value,
    startDate,
    endDate,
    children,
}) => {
    return (
        <OverlayTrigger
            overlay={
                <Tooltip
                    className={`tooltip--large`}
                    id={`profit-loss-report-summary-revenue`}
                >
                    <div className="pl-summary-card-tooltip">
                        <div className="pl-summary-card-tooltip__label">
                            {label}
                        </div>
                        <div className="pl-summary-card-tooltip__date-range">
                            {format(startDate, monthlyDateFormat)} -{" "}
                            {format(endDate, monthlyDateFormat)}
                        </div>

                        {children}
                    </div>
                </Tooltip>
            }
        >
            <Card className="profit-loss-report-summary__card">
                <div className="profit-loss-report-summary__card__label">
                    {label}
                </div>
                <div className="profit-loss-report-summary__card__value">
                    {value}
                </div>
            </Card>
        </OverlayTrigger>
    );
};
