import { JournalEntryLineProxyObject } from "../journalEntryModal/JournalEntryModal";
import { EditableTableCellSelectionState } from "./EditableTableCell";
import { CellPosition, SelectedArea } from "./EditableTableContext";
import { Box, boxesIntersect } from "../../../lib/dragToSelect";
import { isEqual } from "lodash";

export interface EditableCellItem {
    item: HTMLElement;
    cell: CellPosition;
    topToParent: number;
}

export interface ItemWithSelectionBorders extends EditableCellItem {
    selectionBorders: EditableTableCellSelectionState | undefined;
}

interface TraverseCellsToInsertParams {
    firstActiveCell: [number, number];
    cellsToInsert: string[][];
    lastColumnIndex: number;
    callback: (lineIndex: number, columnIndex: number, value?: string) => void;
}

export const traverseCellsToEdit = ({
    firstActiveCell,
    cellsToInsert,
    lastColumnIndex,
    callback,
}: TraverseCellsToInsertParams) => {
    const lineIndexToChange = firstActiveCell[0];
    const columnToChange = firstActiveCell[1];

    let activeCellLineIndex = 0;
    let activeCellColumnIndex = 0;
    while (activeCellLineIndex < cellsToInsert.length) {
        while (columnToChange + activeCellColumnIndex <= lastColumnIndex) {
            const cellValue =
                cellsToInsert[activeCellLineIndex][activeCellColumnIndex];
            callback(
                lineIndexToChange + activeCellLineIndex,
                columnToChange + activeCellColumnIndex,
                cellValue,
            );
            activeCellColumnIndex++;
        }
        activeCellColumnIndex = 0;
        activeCellLineIndex++;
    }
};

interface ConvertLinesToCellsParams {
    lines: JournalEntryLineProxyObject[];
    cellsIndexesToColumnsMapping: Record<number, string>;
    fromLine?: number;
    toLine?: number;
    fromColumn?: number;
    toColumn?: number;
}

export const convertLinesToCells = ({
    lines,
    cellsIndexesToColumnsMapping,
    fromLine = 0,
    toLine = lines.length - 1,
    fromColumn: fromColumnParam,
    toColumn: toColumnParam,
}: ConvertLinesToCellsParams): string[][] => {
    const cellsToReturn: string[][] = [];
    const fromColumn =
        fromColumnParam ??
        Math.min(...Object.keys(cellsIndexesToColumnsMapping).map(Number));
    const toColumn =
        toColumnParam ??
        Math.max(...Object.keys(cellsIndexesToColumnsMapping).map(Number));

    for (let i = fromLine; i <= toLine; i++) {
        cellsToReturn.push([]);
        for (let j = fromColumn; j <= toColumn; j++) {
            const columnName = cellsIndexesToColumnsMapping[j];
            if (!columnName) {
                continue;
            }
            const cellValue =
                lines[i][columnName as keyof JournalEntryLineProxyObject];
            if (cellValue !== undefined) {
                cellsToReturn[cellsToReturn.length - 1].push(
                    cellValue === null ? "" : String(cellValue),
                );
            }
        }
    }
    return cellsToReturn;
};

export const moveArrayChunk = ({
    array,
    startIndex,
    chunkSize,
    newIndex,
}: {
    array: any[];
    startIndex: number;
    chunkSize: number;
    newIndex: number;
}) => {
    // Ensure startIndex, chunkSize, and newIndex are within bounds
    if (startIndex < 0 || startIndex >= array.length || chunkSize <= 0) {
        throw new Error("Invalid start index or chunk size");
    }
    if (newIndex < 0 || newIndex >= array.length) {
        throw new Error("Invalid new index");
    }

    const newArray = [...array];

    // Extract the chunk
    const chunk = newArray.splice(startIndex, chunkSize);

    // Calculate the index where to insert the chunk
    // If newIndex is after startIndex, adjust for the removed elements
    let insertIndex = newIndex > startIndex ? newIndex - chunkSize : newIndex;

    // Insert the chunk at the new index
    newArray.splice(insertIndex, 0, ...chunk);

    return newArray; // returning the modified array for chaining or further usage
};

export const isCellInSelectedArea = (
    cell: CellPosition,
    selectedArea: SelectedArea,
) => {
    return (
        cell.rowIndex >= selectedArea.topLeftCellPosition.rowIndex &&
        cell.rowIndex <= selectedArea.bottomRightCellPosition.rowIndex &&
        cell.columnIndex >= selectedArea.topLeftCellPosition.columnIndex &&
        cell.columnIndex <= selectedArea.bottomRightCellPosition.columnIndex
    );
};

export type CellGrid<T> = Partial<Array<Array<T>>>;

export const getCellByPosition = (
    cellPosition: CellPosition,
    cellGrid: CellGrid<EditableCellItem>,
) => {
    return cellGrid[cellPosition.rowIndex]?.[cellPosition.columnIndex];
};

export const doesSelectedAreaContainOnlyOneCell = (
    selectedArea: SelectedArea,
) => {
    return (
        selectedArea.topLeftCellPosition.rowIndex ===
            selectedArea.bottomRightCellPosition.rowIndex &&
        selectedArea.topLeftCellPosition.columnIndex ===
            selectedArea.bottomRightCellPosition.columnIndex
    );
};

export const isSamePosition = (a: CellPosition, b: CellPosition) => {
    return a.rowIndex === b.rowIndex && a.columnIndex === b.columnIndex;
};

export const getAbsoluteTop = (element: HTMLElement) => {
    let currentElement: HTMLElement | null = element;
    let totalOffsetTop = 0;
    while (currentElement) {
        totalOffsetTop +=
            currentElement.offsetTop -
            currentElement.scrollTop +
            currentElement.clientTop;
        currentElement = currentElement.offsetParent as HTMLElement;
    }
    return totalOffsetTop;
};

export const getItemBoundingClientRect = (item: HTMLElement): Box => {
    const { left, width, height } = item.getBoundingClientRect();
    return {
        left,
        top: getAbsoluteTop(item),
        width,
        height,
    };
};

export const calculateSelectedArea = (
    box: Box,
    selectableItems: CellGrid<EditableCellItem>,
) => {
    let topLeftCellPosition: CellPosition | null = null;
    let bottomRightCellPosition: CellPosition | null = null;
    for (const row of selectableItems) {
        if (!row) {
            continue;
        }
        for (const item of row) {
            if (!boxesIntersect(box, getItemBoundingClientRect(item.item))) {
                continue;
            }
            if (
                topLeftCellPosition === null ||
                bottomRightCellPosition === null
            ) {
                topLeftCellPosition = { ...item.cell };
                bottomRightCellPosition = { ...item.cell };
            }

            bottomRightCellPosition =
                item.cell.rowIndex >= bottomRightCellPosition.rowIndex &&
                item.cell.columnIndex >= bottomRightCellPosition.columnIndex
                    ? { ...item.cell }
                    : bottomRightCellPosition;

            if (item.cell.columnIndex === 0) {
                bottomRightCellPosition.columnIndex = row.length - 1;
                bottomRightCellPosition.rowIndex = item.cell.rowIndex;
            }
        }
    }
    return { topLeftCellPosition, bottomRightCellPosition };
};

export const isDraggingOrderCell = (
    box: Box,
    selectedArea: SelectedArea,
    selectableItems: CellGrid<EditableCellItem>,
): boolean => {
    if (selectedArea === null) {
        return false;
    }
    for (const row of selectableItems) {
        if (!row) {
            continue;
        }
        for (const cell of row) {
            if (!boxesIntersect(box, getItemBoundingClientRect(cell.item))) {
                continue;
            }
            if (
                cell.cell.columnIndex === 0 &&
                isCellInSelectedArea(cell.cell, selectedArea)
            ) {
                return true;
            }
            break;
        }
    }
    return false;
};

interface KeepPreviousCellSelectionReferenceIfEqualParams {
    prevState: EditableTableCellSelectionState | undefined;
    newState: EditableTableCellSelectionState;
}
export const keepPreviousCellSelectionReferenceIfEqual = ({
    prevState,
    newState,
}: KeepPreviousCellSelectionReferenceIfEqualParams): EditableTableCellSelectionState => {
    if (prevState && isEqual(newState, prevState)) {
        return prevState;
    }
    return newState;
};

interface KeepPreviousLineSelectionReferenceIfEqualParams {
    prevValue: EditableTableCellSelectionState[] | undefined;
    newValue: EditableTableCellSelectionState[];
}
export const keepPreviousLineSelectionReferenceIfEqual = ({
    prevValue,
    newValue,
}: KeepPreviousLineSelectionReferenceIfEqualParams): EditableTableCellSelectionState[] => {
    if (prevValue && prevValue.length === newValue.length) {
        const linesAreEquals = prevValue.every((prevCell, cellIndex) => {
            if (newValue[cellIndex] === undefined) {
                return false;
            }
            // NOTE: using ref check as we keep previous cells if they're equal
            return prevCell === newValue[cellIndex];
        });
        if (linesAreEquals) {
            return prevValue;
        }
    }
    return newValue;
};
