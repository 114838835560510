import React from "react";
import { Button } from "react-bootstrap";
import { Transaction } from "../../../common/types/transaction";
import { RemoveIcon } from "../../../icons";
import { TransactionAmount } from "../TransactionAmount";
import { TransactionStatus } from "../TransactionStatus";

export interface TransactionDetailsHeaderProps {
    transaction: Transaction;
    onClose(): void;
}

export const TransactionDetailsHeader: React.FC<
    TransactionDetailsHeaderProps
> = ({ transaction, onClose }) => {
    return (
        <div>
            <div className="d-flex justify-content-between align-items-start">
                <div className="transaction-details__description-container">
                    <TransactionStatus
                        transaction={transaction}
                        className="mr-2"
                    />
                    <div
                        className="transaction-details__description"
                        data-testid="transaction-details-description"
                    >
                        {transaction.description}
                    </div>
                </div>
                <Button
                    variant="plain"
                    onClick={onClose}
                    data-testid="transaction-details-close"
                >
                    <RemoveIcon />
                </Button>
            </div>

            <TransactionAmount transaction={transaction} color addSign />
        </div>
    );
};
