import React, { useCallback, useMemo, useState } from "react";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";
import GustoLogo from "../../../icons/logos/Gusto.svg?react";
import ADPLogo from "../../../icons/logos/ADP.svg?react";
import QuickbooksLogo from "../../../icons/logos/QuickBooks.svg?react";
import Paychex from "../../../icons/logos/Paychex.svg?react";
import { without } from "lodash";
import { OptionGroupItem } from "../../general/OptionGroup/OptionGroupItem";
import { OptionGroupCustomItem } from "../../general/OptionGroup/OptionGroupCustomItem";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { PlanManagementProvider } from "../../billing/PlanManagement/PlanManagementProvider";
import { UpgradePrompt } from "../components/UpgradePrompt";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";

interface Option {
    value: string;
    label: string;
    icon: React.ReactNode;
}

const payrollProviderOptions: Option[] = [
    {
        value: "gusto",
        label: "Gusto",
        icon: <GustoLogo />,
    },
    {
        value: "adp",
        label: "ADP",
        icon: <ADPLogo />,
    },
    {
        value: "quickbooks",
        label: "Quickbooks",
        icon: <QuickbooksLogo />,
    },
    {
        value: "paychex",
        label: "Paychex",
        icon: <Paychex />,
    },
];

const definedProviders = payrollProviderOptions.map((option) => option.value);

interface Props {
    payrollProviders: string[];
    onSubmit: (providers: string[]) => Promise<void>;
    back(): void;
}

export const Payroll: React.FC<Props> = ({
    onSubmit,
    back,
    payrollProviders,
}) => {
    const { isSubscribed } = useBillingStatus();

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(() => {
        return payrollProviders.filter((provider) =>
            definedProviders.includes(provider),
        );
    });
    const [other, setOther] = useState(() => {
        return payrollProviders
            .filter((provider) => !definedProviders.includes(provider))
            .join(", ");
    });

    const handleToggleProvider = useCallback(
        (item: string) => {
            if (selected.includes(item)) {
                setSelected((prev) => without(prev, item));
            } else {
                setSelected((prev) => [...prev, item]);
            }
        },
        [selected],
    );

    const finalValue = useMemo(() => {
        const providers = [...selected];

        if (other.trim().length) {
            providers.push(other.trim());
        }

        return providers;
    }, [other, selected]);

    let description: React.ReactNode;

    if (isSubscribed) {
        description =
            "We’ll follow up with instructions on how to provide access.";
    } else {
        description = (
            <PlanManagementProvider>
                <UpgradePrompt targetPlan={FLAT_RATE_PLANS.PLUS} />
            </PlanManagementProvider>
        );
    }

    return (
        <OnboardingStep
            title="Select payroll provider"
            description={description}
            narrow
            contentNarrow
        >
            <div className="mb-5">
                {payrollProviderOptions.map((option) => (
                    <OptionGroupItem
                        key={option.value}
                        icon={option.icon}
                        title={option.label}
                        selected={selected.includes(option.value)}
                        onToggle={() => handleToggleProvider(option.value)}
                        disabled={!isSubscribed}
                    />
                ))}
                <OptionGroupCustomItem
                    value={other}
                    empty="Other"
                    onChange={setOther}
                    disabled={!isSubscribed}
                />
            </div>

            <OnboardingStepActions className="mt-4" onBack={back}>
                <ButtonWithLoader
                    loading={loading}
                    setLoading={setLoading}
                    variant="primary"
                    onClick={() => onSubmit(finalValue)}
                >
                    {finalValue.length > 0 ? "Next" : "Skip"}
                </ButtonWithLoader>
            </OnboardingStepActions>
        </OnboardingStep>
    );
};
