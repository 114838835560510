import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store";
import { PlaidAccountsConnectedModal } from "../PlaidAccountsConnectedModal/PlaidAccountsConnectedModal";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import { updateConnectAccountState } from "../../../reducers/appState";
import { useUser } from "../../../hooks/useUser";
import { NewConnectionLoaderModal } from "./NewConnectionLoaderModal";
import { FinancialAccountsConnectedModal } from "../../financialAccount/FinancialAccountsConnectedModal";
import { isPlaidConnection } from "../../../common/helpers/financialConnection";
import { StandardModal } from "../../general/Modal/Modal";

export const NewConnectionHandler: React.FC = () => {
    const { isConnecting, connection, duplicates } = useSelector(
        (state: State) => state.appState.connectAccount,
    );
    const showMainModal = isConnecting || !!connection;
    const [showDismissConfirmation, setShowDismissConfirmation] =
        useState<boolean>(false);

    const dispatch = useDispatch();
    const user = useUser();

    const hideMainModal = useCallback(() => {
        dispatch(
            updateConnectAccountState({
                isConnecting: false,
                connection: null,
                duplicates: null,
            }),
        );
    }, [dispatch]);

    const hideConfirmationModal = useCallback(
        () => setShowDismissConfirmation(false),
        [],
    );

    const handleHideAccountsConnected = useCallback(() => {
        const showConfirmation = connection?.accounts.some(
            (acc) =>
                acc.financialAccount.isBusiness === null ||
                !acc.financialAccount.entityId,
        );

        if (showConfirmation && user.onboardingComplete) {
            setShowDismissConfirmation(true);
        } else {
            hideMainModal();
        }
    }, [connection?.accounts, hideMainModal, user.onboardingComplete]);

    const handleConfirm = useCallback(() => {
        hideMainModal();
        hideConfirmationModal();
    }, [hideConfirmationModal, hideMainModal]);

    if (!connection) return <NewConnectionLoaderModal show={showMainModal} />;

    return (
        <>
            {isPlaidConnection(connection) ? (
                <PlaidAccountsConnectedModal
                    show={showMainModal}
                    connection={connection}
                    duplicates={duplicates}
                    onHide={handleHideAccountsConnected}
                />
            ) : (
                <FinancialAccountsConnectedModal
                    show={showMainModal}
                    connection={connection}
                    onHide={handleHideAccountsConnected}
                />
            )}

            <StandardModal
                show={showMainModal && showDismissConfirmation}
                onHide={hideConfirmationModal}
            >
                <ConfirmationModal
                    title="Are you sure you want to cancel?"
                    question={
                        <>
                            To add your accounts, Kick needs to know whether
                            they are Business or Personal.
                        </>
                    }
                    onConfirm={handleConfirm}
                    onReject={hideConfirmationModal}
                    yes="Yes, don't add accounts"
                    no="Back"
                    yesBtnVariant="secondary"
                    noBtnVariant="primary"
                />
            </StandardModal>
        </>
    );
};
