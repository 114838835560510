import React from "react";
import { NavLink } from "react-router-dom";
import { useDrillDownUrl } from "../useDrillDownUrl";
import { endOfMonth, parse, startOfMonth } from "date-fns";

interface Props {
    accountCodes?: number[];
    startDate?: Date;
    endDate?: Date;
    entityId?: number;
    label?: string;
}

export const ProfitLossDrillDown: React.FC<Props> = ({
    accountCodes,
    startDate,
    endDate,
    label,
    entityId,
    children,
}) => {
    if (label) {
        const date = parse(label, "yyyy-MM", new Date());

        startDate = startOfMonth(date);
        endDate = endOfMonth(date);
    }

    if (!startDate || !endDate) {
        throw new Error("startDate and endDate or label are required");
    }

    const path = useDrillDownUrl({
        startDate,
        endDate,
        entityId,
        accountCodes,
    });

    return (
        <NavLink to={path} className="data-table__drill-down">
            {children}
        </NavLink>
    );
};
