import classNames from "classnames";
import { forwardRef, MouseEvent, useCallback, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { DropdownItemProps } from "react-bootstrap/esm/DropdownItem";
import { Taxonomy } from "../../../common/categories";
import {
    FLAT_RATE_PLANS,
    PREMIUM_FEATURES,
} from "../../../common/flatRateBilling";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { ThunderboltIcon, TriangleIcon } from "../../../icons";
import {
    useCreateCustomCategoryMutation,
    useDeleteCustomCategoryMutation,
    useUpdateCustomCategoryMutation,
} from "../../../mutations/customCategories";
import { usePlanManagement } from "../../billing/PlanManagement/PlanManagementContext";
import { FeatureAccess } from "../../general/FeatureAccess/FeatureAccess";
import {
    ManagementDropdown,
    ManagementDropdownProps,
} from "../../general/ManagementDropdown/ManagementDropdown";
import { TreeNode } from "../../general/Tree/Tree.types";
import { useTreeContext } from "../../general/Tree/useTreeContext";
import { CategoryIcon } from "../../transactions/CategoryIcon";
import type { GenericCategoryItem } from "./Categories";
import {
    CategoryLabelEditForm,
    CategoryLabelEditFormProps,
} from "./CategoryLabelEditForm";
import css from "./CategoryTreeItem.module.scss";
import { NonUndefined } from "../../../common/helpers/typescript";

interface Props {
    node: TreeNode<GenericCategoryItem>;
    onAddSubcategoryDraft: (id: Taxonomy | undefined) => void;
    isEditing: boolean;
    onEditNode: (id: string | undefined) => void;
    onShowManagement: (nodeId: string) => void;
    onHideManagement: (nodeId: string) => void;
}

export const CategoryTreeItem = forwardRef<HTMLInputElement, Props>(
    (
        {
            node,
            onAddSubcategoryDraft,
            onEditNode,
            isEditing,
            onShowManagement,
            onHideManagement,
        },
        ref,
    ) => {
        const { isExpanded, expandNode, collapseNode } = useTreeContext();
        const { nodeId } = node;
        const handleToggle = useCallback(
            (e: MouseEvent) => {
                e.stopPropagation();
                isExpanded(nodeId) ? collapseNode(nodeId) : expandNode(nodeId);
            },
            [collapseNode, expandNode, isExpanded, nodeId],
        );

        const { category, customCategory, isDraft } = node.current;
        const key = node.current.id;

        const parentCategory = node.parent?.category;
        const createCustomCategoryMutation = useCreateCustomCategoryMutation();

        const updateCustomCategoryMutation = useUpdateCustomCategoryMutation(
            customCategory?.id,
        );

        const deleteCustomCategoryMutation = useDeleteCustomCategoryMutation(
            customCategory?.id,
        );

        const onStartEditing = useCallback(
            () => onEditNode(node.nodeId),
            [node.nodeId, onEditNode],
        );
        const onStopEditing = useCallback(
            () => onEditNode(undefined),
            [onEditNode],
        );
        const onDelete = useCallback(
            () => deleteCustomCategoryMutation.mutate(),
            [deleteCustomCategoryMutation],
        );

        const onAddSubcategory = useCallback(() => {
            if (!category) {
                return;
            }

            expandNode(node.nodeId); // expand main category
            onAddSubcategoryDraft(category.id);
        }, [category, expandNode, node.nodeId, onAddSubcategoryDraft]);

        const onLabelSubmit = useCallback<
            CategoryLabelEditFormProps["onSubmit"]
        >(
            (values) => {
                if (customCategory) {
                    updateCustomCategoryMutation.mutate(values);
                } else {
                    if (!parentCategory) {
                        throw new Error(
                            `Expecting parentCategory to be defined`,
                        );
                    }
                    createCustomCategoryMutation.mutate({
                        mainCategoryId: parentCategory.id,
                        ...values,
                    });
                }

                onStopEditing();
                onAddSubcategoryDraft(undefined);
            },
            [
                createCustomCategoryMutation,
                customCategory,
                onAddSubcategoryDraft,
                onStopEditing,
                parentCategory,
                updateCustomCategoryMutation,
            ],
        );

        const { upgrade } = usePlanManagement();
        const { trialAvailable, isTrialing } = useBillingStatus();
        const onUpgrade = useCallback(
            () => upgrade(FLAT_RATE_PLANS.PLUS, trialAvailable || isTrialing),
            [isTrialing, trialAvailable, upgrade],
        );

        const [isMgmtDropdownOpen, setIsMgmtDropdownOpen] = useState(false);

        const onDropdownToggle = useCallback<
            NonUndefined<ManagementDropdownProps["onToggle"]>
        >(
            (isOpen: boolean) => {
                setIsMgmtDropdownOpen(isOpen);
                if (isOpen) {
                    onShowManagement(node.nodeId);
                } else {
                    onHideManagement(node.nodeId);
                }
            },
            [node.nodeId, onHideManagement, onShowManagement],
        );

        const shouldShowSettings =
            node.depth === 0 ||
            node.children.length > 0 ||
            customCategory != null;

        return (
            <div
                key={key}
                className={classNames(css.item, {
                    [css.rootItem]: node.depth === 0,
                    [css.expandedItem]: isExpanded(nodeId),
                    [css.lockedItem]: isMgmtDropdownOpen,
                })}
                data-testid="category-tree-item"
            >
                <div className={css.itemLabel}>
                    {node.depth === 0 && category && (
                        <CategoryIcon category={category} />
                    )}
                    {(customCategory && isEditing) || isDraft ? (
                        <CategoryLabelEditForm
                            customCategory={customCategory}
                            onSubmit={onLabelSubmit}
                            onStopEditing={onStopEditing}
                            ref={ref}
                        />
                    ) : (
                        <span className="ml-2" data-testid="category-item">
                            {category?.label ?? customCategory?.label}
                        </span>
                    )}
                    {node.children.length > 0 && (
                        <Button
                            variant="link"
                            onClick={handleToggle}
                            className={classNames(css.itemExpandBtn, "ml-1")}
                            data-testid="category-expand-btn"
                        >
                            <TriangleIcon />
                        </Button>
                    )}
                    {shouldShowSettings && (
                        <ManagementDropdown
                            id={`category-dropdown__${key}`}
                            className={css.managementDropdown}
                            toggleClassName={css.managementDropdownToggle}
                            drop="up"
                            onToggle={onDropdownToggle}
                        >
                            {node.depth === 0 || node.children.length > 0 ? (
                                <UpgradeDropdownItem
                                    onClick={onAddSubcategory}
                                    onUpgrade={onUpgrade}
                                >
                                    Add subcategory
                                </UpgradeDropdownItem>
                            ) : (
                                <>
                                    {!isEditing && (
                                        <UpgradeDropdownItem
                                            onClick={onStartEditing}
                                            onUpgrade={onUpgrade}
                                        >
                                            Rename subcategory
                                        </UpgradeDropdownItem>
                                    )}
                                    <UpgradeDropdownItem
                                        onClick={onDelete}
                                        className="text-danger"
                                        onUpgrade={onUpgrade}
                                    >
                                        Delete subcategory
                                    </UpgradeDropdownItem>
                                </>
                            )}
                        </ManagementDropdown>
                    )}
                </div>
            </div>
        );
    },
);

interface UpgradeDropdownItemProps extends DropdownItemProps {
    readonly onUpgrade: () => void;
}

function UpgradeDropdownItem({
    onUpgrade,
    children,
    ...rest
}: UpgradeDropdownItemProps) {
    return (
        <FeatureAccess
            feature={PREMIUM_FEATURES.CUSTOM_CATEGORIES}
            renderAllowed={() => (
                <Dropdown.Item {...rest}>{children}</Dropdown.Item>
            )}
            renderBlocked={() => (
                <Dropdown.Item {...rest} onClick={onUpgrade}>
                    {children}
                    <ThunderboltIcon className="ml-auto icon-color-blue-600 icon-size-text" />
                </Dropdown.Item>
            )}
        />
    );
}
