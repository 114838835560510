import React from "react";
import { Button } from "react-bootstrap";
import { intercom } from "../../lib/intercom";

export const Support: React.FC = () => {
    return (
        <>
            <p>Message us for any questions or requests.</p>
            <Button
                variant="secondary"
                size="sm"
                onClick={() => intercom("show")}
            >
                Chat with us <span className="ml-2">💬</span>
            </Button>
        </>
    );
};
