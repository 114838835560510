import React, { useCallback, useEffect, useState } from "react";
import { Entity } from "../../common/types/entity";
import { EntityIcon } from "../entity/EntityIcon";
import { Card } from "../general/Card/Card";
import { PageHeading } from "../general/PageHeading/PageHeading";
import { CashFlowContextProvider } from "./CashFlowContextProvider";
import "./CashFlowPage.scss";
import {
    CashFlowPageFilters,
    CashFlowPageFiltersProps,
} from "./CashFlowPageFilters/CashFlowPageFilters";
import { CashFlowReport } from "./CashFlowReport";
import { useDefaultReportsFilters } from "./useDefaultReportsFilters";

interface CashFlowPageProps {
    entity?: Entity;
}

export const CashFlowPage: React.FC<CashFlowPageProps> = ({ entity }) => {
    const defaultFilters = useDefaultReportsFilters(true /* thisYear */);
    const [filters, setFilters] = useState(defaultFilters);

    const onFiltersChange = useCallback<CashFlowPageFiltersProps["onChange"]>(
        (newFilters) =>
            setFilters((oldFilters) => ({ ...oldFilters, ...newFilters })),
        [],
    );

    useEffect(() => {
        if (!entity?.id) {
            return;
        }

        onFiltersChange({
            entitiesAccounts: [{ entityId: entity.id }],
        });
    }, [entity?.id, onFiltersChange]);

    return (
        <main className="reports">
            <PageHeading className="reports__title">
                {entity && <EntityIcon entity={entity} />}
                Cash Flow
            </PageHeading>
            <CashFlowContextProvider filters={filters}>
                <Card className="reports__header">
                    <CashFlowPageFilters
                        filters={filters}
                        onChange={onFiltersChange}
                        hardcodedEntity={entity}
                    />
                </Card>
                <CashFlowReport
                    resetFilters={() => setFilters(defaultFilters)}
                    onChange={onFiltersChange}
                />
            </CashFlowContextProvider>
        </main>
    );
};
