import React, { useMemo } from "react";
import "./ObjectIcon.scss";
import classNames from "classnames";

export type IconSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
export interface ObjectIconProps {
    name?: string;
    defaultColor: string;
    iconUrl?: string;
    size: IconSize;
    square?: boolean;
    dynamic?: boolean;
    border?: string;
    className?: string;
}

export const ObjectIcon: React.FC<ObjectIconProps> = ({
    name,
    defaultColor,
    iconUrl,
    size,
    square,
    dynamic,
    children,
    border,
    className,
}) => {
    const style = useMemo(
        () => ({
            backgroundColor: defaultColor,
            backgroundImage: iconUrl ? `url(${iconUrl})` : undefined,
            border,
        }),
        [defaultColor, iconUrl, border],
    );

    return (
        <div
            className={classNames(
                `object-icon object-icon--${size}`,
                {
                    "object-icon--square": square,
                    "object-icon--dynamic": dynamic,
                },
                className,
            )}
            style={style}
        >
            {children || (!iconUrl && name?.charAt(0).toUpperCase())}
        </div>
    );
};
