import { useEntities } from "../../hooks/useEntities";
import { useMemo } from "react";

export function useEntitiesWithAccountingAvailable() {
    const entities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
    });

    return useMemo(
        () => entities.filter((e) => Boolean(e.profile?.entityType)),
        [entities],
    );
}
