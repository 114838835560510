import React from "react";
import {
    AccountingReportHeader,
    AccountingReportHeaderProps,
} from "./AccountingReportHeader";
import styles from "./AccountingReportCompact.module.scss";
import { AccountingReportFooter } from "./AccountingReportFooter";
import { Card } from "../general/Card/Card";
import { CardSection } from "../general/Card/CardSection";

interface Props extends AccountingReportHeaderProps {
    generationDate: Date;
}

export const AccountingReportCompact: React.FC<Props> = ({
    generationDate,
    entityName,
    reportType,
    dateLabel,
    children,
}) => {
    return (
        <Card stacked>
            <CardSection>
                <AccountingReportHeader
                    entityName={entityName}
                    reportType={reportType}
                    dateLabel={dateLabel}
                />
            </CardSection>
            <CardSection className={styles.totalRow}>
                <div>Total</div>
            </CardSection>
            <CardSection className={styles.contentWrapper}>
                <div className={styles.content}>{children}</div>
            </CardSection>
            <AccountingReportFooter generationDate={generationDate} />
        </Card>
    );
};
