import { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getEnv } from "../../../lib/environment";

export function useLoadStripe() {
    const [stripePromise, setStripePromise] =
        useState<Promise<Stripe | null> | null>(null);

    useEffect(() => {
        setStripePromise(loadStripe(getEnv("VITE_STRIPE_PUBLISHABLE_KEY")));
    }, []);

    return stripePromise;
}
