import {
    RuleConditionType,
    TransactionCategorizationRuleCondition,
} from "../../types/transactionCategorizationRule";

export class TransactionCategorizationRuleConditionDto {
    conditionType: RuleConditionType;
    value: TransactionCategorizationRuleCondition["value"];

    constructor(condition: TransactionCategorizationRuleCondition) {
        this.conditionType = condition.conditionType;
        this.value = condition.value;
    }

    static deserialize(
        dto: TransactionCategorizationRuleConditionDto,
    ): TransactionCategorizationRuleCondition {
        return {
            conditionType: dto.conditionType,
            value: dto.value,
        };
    }
}
