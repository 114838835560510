import React from "react";
import { PlaidConnection } from "../../common/types/plaidConnection";

interface Props {
    connection: PlaidConnection;
}

export const PlaidConnectionIcon: React.FC<Props> = ({ connection }) => {
    return connection.bankLogoUrl ? (
        <img src={connection.bankLogoUrl} alt={connection.bankName} />
    ) : null;
};
