import { FinancialDocument } from "../../../common/types/financialDocument";
import { useQuery } from "react-query";
import { getFinancialDocumentFile } from "../../../lib/financialDocument";
import { financialDocumentFileQueryKeys } from "../lib";

export function useFinancialDocumentFileQuery(
    financialDocument: FinancialDocument,
) {
    return useQuery(
        financialDocumentFileQueryKeys.file(financialDocument.id),
        () => getFinancialDocumentFile(financialDocument.id),
        { refetchOnMount: false, refetchOnWindowFocus: false },
    );
}
