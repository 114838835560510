import React from "react";
import {
    TransactionAuditLog,
    TransactionAuditLogRuleMetadata,
} from "../../../../common/types/transactionAuditLog";

interface Props {
    log: TransactionAuditLog;
    categoryLabel: string;
}

export const TransactionAuditLogCategorizationRuleContent: React.FC<Props> = ({
    log,
    categoryLabel,
}) => {
    const metadata = log.metadata as TransactionAuditLogRuleMetadata;

    return (
        <>
            Category set to <strong>{categoryLabel}</strong> because transaction
            was matched by rule <strong>{metadata.ruleName}</strong>.
        </>
    );
};
