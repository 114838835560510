import { Taxonomy } from "../../categories";
import {
    ProfitLossReportMetaDto,
    ProfitLossTableReportSectionsDto,
} from "./reports-common.dto";

export interface ProfitLossReportDto {
    columnLabels: string[];
    sections: ProfitLossTableReportSectionsDto;
    chart: ProfitLossChartReportDto;
    meta: ProfitLossReportMetaDto;
}

export interface TransactionItemEntry {
    description: string;
    value: number;
    categoryOutput: Taxonomy;
    id: string;
}

export interface ProfitLossTopTransactionsDto {
    top: TransactionItemEntry[];
    other: number;
}

export enum ProfitLossChartDataSeriesCalculatedIdentifier {
    OTHER = "other",
    INCOME_REST = "income_rest",
    DUMMY = "dummy",
}

export type ProfitLossChartReportSeriesIdentifier =
    | Taxonomy
    | ProfitLossChartDataSeriesCalculatedIdentifier;
export interface ProfitLossChartReportDto {
    data: ProfitLossChartReportSeriesData[];
    series: ProfitLossChartReportSeriesIdentifier[];
}

export type ProfitLossChartReportSeriesData = {
    label: string;
} & Record<Taxonomy | ProfitLossChartDataSeriesCalculatedIdentifier, number>;

export type SpendingReportDataDto = {
    label: string;
} & Record<Taxonomy, number>;

export interface SpendingReportDto {
    data: SpendingReportDataDto[];
    series: Taxonomy[];
}
