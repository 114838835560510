import { PlaidConnection } from "../../common/types/plaidConnection";
import React, { useCallback, useState } from "react";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { simulateRevokeConnectionPermissions } from "../../lib/simulations";

export interface ExternalConnectionRowProps {
    connection: PlaidConnection;
}

export const ExternalConnectionRow: React.FC<ExternalConnectionRowProps> = ({
    connection,
}) => {
    const [revoking, setRevoking] = useState(false);

    const revoke = useCallback(async () => {
        await simulateRevokeConnectionPermissions(connection.id);
    }, [connection.id]);

    return (
        <tr>
            <td>{connection.id}</td>
            <td>{connection.user?.email ?? "Deleted"}</td>
            <td>{connection.bankName}</td>
            <td>
                <ButtonWithLoader
                    loading={revoking}
                    setLoading={setRevoking}
                    onClick={revoke}
                    className="mr-2"
                >
                    Revoke permissions
                </ButtonWithLoader>
            </td>
        </tr>
    );
};
