import { User } from "./user";

export enum TransactionAuditLogAuthorType {
    USER = "USER",
    SYSTEM = "SYSTEM",
    OPS = "OPS",
    SIMILARITY = "SIMILARITY",
    TRANSFER_MATCHING = "TRANSFER_MATCHING",
    CATEGORIZATION_RULE = "CATEGORIZATION_RULE",
}

export interface TransactionAuditLogAuthorMetadata {
    author: string;
    email?: string;
}

export interface TransactionAuditLogSimilarityMetadata {
    description: string;
    date: string;
    amount: number;
}

export interface TransactionAuditLogTransferMatchingMetadata {
    description: string;
    date: string;
    amount: number;
}

export interface TransactionAuditLogRuleMetadata {
    ruleId: string;
    ruleName: string;
}

export type TransactionAuditLogMetadata =
    | TransactionAuditLogAuthorMetadata
    | TransactionAuditLogSimilarityMetadata
    | TransactionAuditLogTransferMatchingMetadata
    | TransactionAuditLogRuleMetadata;

export enum TransactionAuditLogProperty {
    CATEGORY = "CATEGORY",
    ENTITY = "ENTITY",
}

export interface CategoryAuditLogValue {
    category: string;
}

export interface EntityAuditLogValue {
    entityId: number;
    entityName?: string;
    isBusiness: boolean;
    classificationChanged: boolean;
    category?: string;
}

export type TransactionAuditLogValue =
    | CategoryAuditLogValue
    | EntityAuditLogValue;

export interface TransactionAuditLog {
    id: number;
    date: Date;
    property: TransactionAuditLogProperty;
    authorType: TransactionAuditLogAuthorType;
    metadata: TransactionAuditLogMetadata;
    newValue: TransactionAuditLogValue;
    user: User | null;
    userId: number | null;
}
