import { IntentType } from "../../billing";

export class PrepareSubscriptionResponseDto {
    static deserialize(dto: PrepareSubscriptionResponseDto) {
        return {
            ...dto,
        };
    }

    constructor(
        public clientSecret: string,
        public intent: IntentType,
        public couponId?: string,
    ) {}
}
