import { useCallback, useEffect, useMemo, useState } from "react";
import {
    addYears,
    endOfDay,
    isSameDay,
    min,
    startOfYear,
    subYears,
} from "date-fns";
import {
    DATE_RANGE_PRESETS,
    DateRange,
    DateRangePreset,
} from "../../../../helpers/date";
import { OnChangeDateRangeCallback } from "react-calendar";
import { DateSetFrom } from "./DateRangePicker";

export interface DateRangePickerOptions {
    initialValue?: Partial<DateRange>;
    onChange: (
        value: Partial<DateRange>,
        dateSetFrom: DateSetFrom | undefined,
    ) => void;
    requireSelection?: boolean;
    visiblePresets?: string[];
}

const latestValidActiveStart = startOfYear(subYears(new Date(), 1));

export function useDateRangePicker({
    initialValue = {},
    requireSelection = false,
    onChange,
    visiblePresets,
}: DateRangePickerOptions) {
    const today = useMemo(() => new Date(), []);
    const previousYear = useMemo(() => subYears(today, 1), [today]);
    const [activeStart, setActiveStart] = useState(() =>
        initialValue.start &&
        initialValue.start.getTime() < previousYear.getTime()
            ? initialValue.start
            : previousYear,
    );
    const [start, setStart] = useState(initialValue.start);
    const [end, setEnd] = useState(initialValue.end);
    const [dateSetFrom, setDateSetFrom] = useState<DateSetFrom>();

    const presets = useMemo(
        () =>
            visiblePresets
                ? DATE_RANGE_PRESETS.filter((preset) =>
                      visiblePresets.includes(preset.id),
                  )
                : DATE_RANGE_PRESETS,
        [visiblePresets],
    );

    useEffect(() => {
        if (!requireSelection || (start && end)) {
            onChange({ start, end }, dateSetFrom);
        }
    }, [start, end, onChange, dateSetFrom, requireSelection]);

    const selectFromInput = useCallback(
        (
            value: Date | undefined,
            setter: (date: Date | undefined) => void,
            isEnd: boolean = false,
        ) => {
            const newValue = isEnd && value ? endOfDay(value) : value;

            setter(newValue);
            setDateSetFrom("input");

            const dateBounds = [
                ...(isEnd ? [start, newValue] : [newValue, end]),
                latestValidActiveStart,
            ].filter(Boolean) as Date[];

            setActiveStart(min(dateBounds));
        },
        [end, start],
    );

    const changeDateRange: OnChangeDateRangeCallback = useCallback(
        ([newStart, newEnd]: [Date] | [Date, Date]) => {
            setStart(newStart);
            setEnd(newEnd);
            setDateSetFrom("calendar");
        },
        [],
    );

    const clearValue = useCallback(() => {
        setStart(undefined);
        setEnd(undefined);
        setDateSetFrom("preset");
    }, []);

    const selectPreset = useCallback(
        (preset?: DateRangePreset) => {
            if (preset) {
                const presetStart = preset.getStart(today);

                setStart(presetStart);
                setEnd(preset.getEnd(today));
                setDateSetFrom("preset");

                setActiveStart(min([presetStart, latestValidActiveStart]));
            } else {
                clearValue();
            }
        },
        [clearValue, today],
    );

    const calendarValue: Date | [Date, Date] | undefined = useMemo(() => {
        if (start && end) {
            return [start, end];
        } else {
            return start;
        }
    }, [start, end]);

    const activePreset = useMemo(() => {
        if (start && end) {
            return DATE_RANGE_PRESETS.find(
                (p) =>
                    isSameDay(p.getStart(today), start) &&
                    isSameDay(p.getEnd(today), end),
            )?.id;
        }
    }, [start, end, today]);

    const onActiveStartDateChange = useCallback(({ action }) => {
        if (action === "next") {
            setActiveStart((prev) => addYears(prev, 1));
        }
        if (action === "prev") {
            setActiveStart((prev) => subYears(prev, 1));
        }
    }, []);

    return {
        start,
        setStart,
        end,
        setEnd,
        activePreset,
        activeStart,
        calendarValue,
        changeDateRange,
        clearValue,
        onActiveStartDateChange,
        presets,
        selectFromInput,
        selectPreset,
        maxDate: today,
    };
}
