import React, { useState } from "react";
import { Entity, EntityProfileType } from "../../../common/types/entity";
import { object, string } from "yup";
import { Field, Form, Formik, FormikConfig } from "formik";
import { submitHelper } from "../../../helpers/form";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { FormRow } from "../../forms/FormRow";
import classNames from "classnames";
import styles from "./Waitlist.module.scss";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";

interface Props {
    entity: Entity;
    onSubmit(comment: string): Promise<void>;
    back(): void;
}

interface FormSchema {
    comment: string;
}

export const Waitlist: React.FC<Props> = ({ entity, onSubmit, back }) => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            comment: entity?.profile?.waitlistComment ?? "",
        },
        validationSchema: object().shape({
            comment: string().required("Please provide a comment"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                await onSubmit(values.comment);
            },
        }),
    };

    let title: React.ReactNode;
    if (!entity.profile?.industry || !entity.profile?.type) {
        return null;
    } else if (entity.profile.type === EntityProfileType.NO_LEGAL_ENTITY) {
        title = `Kick only currently supports U.S. based businesses`;
    } else {
        title = `Kick doesn’t yet support ${entity.profile.industry}`;
    }

    return (
        <OnboardingStep
            title={title}
            description="If you'd like to be an industry or country design partner, let us know the number one thing that keeps you up at night about your business finances. "
            narrow
        >
            <Formik {...form}>
                {({ isValid, values }) => (
                    <Form>
                        <FormRow fieldName="comment">
                            <Field
                                name="comment"
                                as="textarea"
                                className={classNames(
                                    "form-control",
                                    styles.textarea,
                                )}
                                placeholder="Making sure our books are accurate and up-to-date. We've tried a number of bookkeepers and softwares, but no one can keep the books as accurate as we do since they don't know our business as well as we do."
                            />
                        </FormRow>

                        <OnboardingStepActions className="mt-4" onBack={back}>
                            <ButtonWithLoader
                                type="submit"
                                variant="primary"
                                loading={loading}
                                disabled={!isValid}
                            >
                                Next
                            </ButtonWithLoader>
                        </OnboardingStepActions>

                        <p className="mt-5">
                            If you think we made a mistake, email us at{" "}
                            <a href="mailto:support@kick.co">support@kick.co</a>
                            .
                        </p>
                    </Form>
                )}
            </Formik>
        </OnboardingStep>
    );
};
