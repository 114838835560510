import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { CalendarIcon, FolderIcon } from "../../../icons";
import { AggregatedFiltersOverlay } from "../../general/AggregatedFilters/AggregatedFiltersOverlay";
import { AggregatedDateFilter } from "../../general/AggregatedFilters/DateFilter/AggregatedDateFilter";
import { NavigationItem } from "../../navigation/NavigationItem";
import { AggregatedCategoryFilter } from "../../transactions/filters/AggregatedFilters/CategoryFilter/AggregatedCategoryFilter";
import { CashFlowPageFiltersProps } from "./CashFlowPageFilters";
import { CashFlowPageFilterTabs } from "./lib";

export interface CashFlowPageFiltersOverlayProps
    extends CashFlowPageFiltersProps {
    hideDateFilter?: boolean;
}

export const CashFlowPageFiltersOverlay: React.FC<
    CashFlowPageFiltersOverlayProps
> = ({ filters, onChange, hideDateFilter = false }) => {
    return (
        <AggregatedFiltersOverlay
            navigation={
                <>
                    {!hideDateFilter && (
                        <Nav.Link eventKey={CashFlowPageFilterTabs.DATE}>
                            <NavigationItem
                                icon={<CalendarIcon />}
                                children="Date"
                                activeIndicator
                            />
                        </Nav.Link>
                    )}

                    <Nav.Link eventKey={CashFlowPageFilterTabs.CATEGORY}>
                        <NavigationItem
                            icon={<FolderIcon />}
                            children="Category"
                            activeIndicator
                        />
                    </Nav.Link>
                </>
            }
            content={
                <>
                    {!hideDateFilter && (
                        <Tab.Pane eventKey={CashFlowPageFilterTabs.DATE}>
                            <AggregatedDateFilter
                                filters={filters}
                                onChange={onChange}
                            />
                        </Tab.Pane>
                    )}

                    <Tab.Pane eventKey={CashFlowPageFilterTabs.CATEGORY}>
                        <AggregatedCategoryFilter
                            filters={filters}
                            onChange={onChange}
                        />
                    </Tab.Pane>
                </>
            }
        />
    );
};
