import React, { useCallback } from "react";
import { PageCoverLoader } from "../general/PageCoverLoader/PageCoverLoader";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useUser } from "../../hooks/useUser";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { useCheckoutMutation } from "../../mutations/flatRateBilling";
import { useQueryParam } from "../../hooks/useQueryParam";

interface CheckoutRouteParams {
    setupId: string;
}

export const Checkout: React.FC = () => {
    const history = useHistory();
    const { setupId } = useParams<CheckoutRouteParams>();
    const afterCheckoutUrl = useQueryParam("after") ?? undefined;
    const { onboardingComplete } = useUser();
    const { query } = useBillingStatus();
    const afterCheckout = useCallback(async () => {
        await query.refetch();
        if (onboardingComplete) {
            history.push(afterCheckoutUrl ?? "/settings/billing");
        } else {
            history.push("/onboarding");
        }
    }, [afterCheckoutUrl, history, onboardingComplete, query]);
    const checkoutMutation = useCheckoutMutation(setupId, afterCheckout);

    useEffectOnce(() => {
        checkoutMutation.mutate();
    });

    return <PageCoverLoader>Creating your dashboard</PageCoverLoader>;
};
