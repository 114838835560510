import { AccountingReportFilters } from "./types";
import { useMemo, useState } from "react";
import { formatISO } from "date-fns";
import { useQuery } from "react-query";
import { Entity } from "../../common/types/entity";

export interface BasicAccountingReportQuery {
    startDate?: string;
    endDate?: string;
    entityId: number;
}

export interface UseAccountingReportParams<
    T,
    Q extends BasicAccountingReportQuery,
> {
    filters: AccountingReportFilters;
    entity?: Entity;
    queryKey: string;
    reportGetter: (query: Q) => Promise<T>;
    prepareQuery?: (query: BasicAccountingReportQuery) => Q;
}

export function useAccountingReport<T, Q extends BasicAccountingReportQuery>({
    filters,
    entity,
    queryKey,
    reportGetter,
    prepareQuery = (query) => query as Q,
}: UseAccountingReportParams<T, Q>) {
    const [generationDate, setGenerationDate] = useState(new Date());

    const query = useMemo(
        () =>
            entity
                ? prepareQuery({
                      startDate: filters.startDate
                          ? formatISO(filters.startDate, {
                                representation: "date",
                            })
                          : undefined,
                      endDate: filters.endDate
                          ? formatISO(filters.endDate, {
                                representation: "date",
                            })
                          : undefined,
                      entityId: entity.id,
                  })
                : null,
        [entity, filters.startDate, filters.endDate, prepareQuery],
    );

    const report = useQuery(
        [queryKey, query],
        () => (query ? reportGetter(query) : null),
        {
            onSuccess: () => {
                setGenerationDate(new Date());
            },
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
        },
    );

    return {
        report,
        generationDate,
    };
}
