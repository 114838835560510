import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { AddAccountModal } from "./AddAccountModal";
import { Entity } from "../../common/types/entity";
import { StandardModal } from "../general/Modal/Modal";

interface Props {
    btnText: string;
    btnVariant?: string;
    btnSize?: "sm" | "lg";
    btnClassName?: string;
    defaultEntity?: Entity;
}

export const AddAccount: React.FC<Props> = ({
    btnClassName,
    btnSize,
    btnText,
    btnVariant,
    defaultEntity,
}) => {
    const [addAccount, setAddAccount] = useState(false);
    const handleAccountCreated = useCallback(() => {
        setAddAccount(false);
    }, []);
    return (
        <>
            <Button
                onClick={() => setAddAccount(true)}
                variant={btnVariant}
                className={btnClassName}
                size={btnSize}
                data-testid="add-account-btn"
            >
                {btnText}
            </Button>
            <StandardModal
                show={addAccount}
                onHide={() => setAddAccount(false)}
            >
                <AddAccountModal
                    onCreated={handleAccountCreated}
                    defaultEntity={defaultEntity}
                />
            </StandardModal>
        </>
    );
};
