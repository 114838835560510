import React from "react";
import { Transaction } from "../../../common/types/transaction";
import classNames from "classnames";
import { EagerLoaded } from "../../../common/types/base/orm";
import { TransactionMatches } from "./TransactionMatches/TransactionMatches";

interface Props {
    transaction: EagerLoaded<Transaction, "transactionMatches">;
    required: boolean;
}

export const TransactionDetailsReceipt: React.FC<Props> = ({
    transaction,
    required,
}) => {
    return (
        <div
            data-testid="transaction-details-receipt"
            className={classNames("transaction-details__receipt", {
                "transaction-details__receipt--required":
                    required && !transaction.transactionMatches.length,
            })}
        >
            <TransactionMatches financialTransaction={transaction} />
        </div>
    );
};
