import type { ReactNode } from "react";

export enum ToastMessageType {
    FINANCIAL_TRANSACTIONS_BULK_UPDATE = "FINANCIAL_TRANSACTIONS_BULK_UPDATE",
    CATEGORIZATION = "CATEGORIZATION",
    CUSTOM = "CUSTOM",
}

export interface BaseToastMessage {
    type: ToastMessageType;
    startTimer: boolean;
}

export interface CustomToastData {
    message: ReactNode;
}

export interface CustomToastMessage extends BaseToastMessage {
    type: ToastMessageType.CUSTOM;
    data: CustomToastData;
}

export interface BaseToastMessageData {
    hide(): void;
}
