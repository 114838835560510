import { useUser } from "./useUser";
import { useSettings } from "../components/settings/SettingsProvider";
import { useCallback } from "react";
import { useBillingStatus } from "./useBillingStatus";
import { useIsPrimaryAdminInAnyEntity } from "./useRoles";
import { useIsDemoUser } from "./useIsDemoUser";

export function useBillingManagement() {
    const isDemoUser = useIsDemoUser();
    const { totalUsage } = useBillingStatus();
    const user = useUser();
    const isPrimaryAdminInAnyCompany = useIsPrimaryAdminInAnyEntity();
    const { open } = useSettings();
    const canManageBilling = isPrimaryAdminInAnyCompany;

    const canShowCta = canManageBilling && !user.isSubscribed && !isDemoUser;

    const showExceededUsageCta =
        canShowCta && totalUsage >= user.freeUsageLimit;
    const showLimitReachedCta =
        canShowCta &&
        !!user.bookkeepingEndDate &&
        user.bookkeepingEndDate <= new Date();

    const onUpgrade = useCallback(() => {
        if (canManageBilling) {
            open(["billing"]);
        }
    }, [canManageBilling, open]);

    return {
        canManageBilling,
        showExceededUsageCta,
        showLimitReachedCta,
        showNotSubscribedCta: canShowCta,
        bookkeepingEndDate: user.bookkeepingEndDate,
        bookkeepingStartDate: user.bookkeepingStartDate,
        onUpgrade,
    };
}
