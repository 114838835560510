import React from "react";
import "./SelectableItemOption.scss";
import { useInteraction } from "../../../hooks/useInteraction";

interface Props {
    icon: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
    onClick: () => void;
}

export const SelectableItemOption: React.FC<Props> = ({
    icon,
    title,
    description,
    onClick,
}) => {
    const handleSelected = useInteraction(onClick);

    return (
        <div className="selectable-item-option" {...handleSelected}>
            <div className="selectable-item-option__icon">{icon}</div>
            <div className="selectable-item-option__content">
                <div className="selectable-item-option__title">{title}</div>
                <div className="selectable-item-option__description">
                    {description}
                </div>
            </div>
        </div>
    );
};
