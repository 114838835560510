import React from "react";
import styles from "./DowngradeModal.module.scss";
import { DowngradeFeature } from "./lib";

interface Props {
    feature: DowngradeFeature;
}

export const PlanFeature: React.FC<Props> = ({
    children,
    feature: { icon, label },
}) => {
    return (
        <article className={styles.planFeature}>
            <aside className={styles.icon}>{icon}</aside>
            {label}
        </article>
    );
};
