import React, { useMemo, useState } from "react";
import { ReportWithNestedGroupsRow } from "../../../common/types/reports/types";
import { last } from "lodash";
import { RenderBalanceSheetRowProps } from "./types";

interface Props {
    section: ReportWithNestedGroupsRow;
    depth: number;
    renderRow: (props: RenderBalanceSheetRowProps) => React.ReactElement;
}

function getNestedAccountCodes(section: ReportWithNestedGroupsRow): string[] {
    if ("rows" in section) {
        return section.rows.flatMap((row) => getNestedAccountCodes(row));
    } else {
        return [section.key];
    }
}

export const BalanceSheetTableSection: React.FC<Props> = ({
    section,
    depth,
    renderRow,
}) => {
    const [expanded, setExpanded] = useState(true);

    const accountCodes = useMemo(
        () => getNestedAccountCodes(section),
        [section],
    );

    if ("rows" in section) {
        const value = last(section.totals)!;

        return (
            <>
                {renderRow({
                    label: section.label,
                    value,
                    depth,
                    canExpand: true,
                    isExpanded: expanded,
                    onExpandToggle: () => setExpanded(!expanded),
                    accountCodes,
                })}
                {expanded && (
                    <>
                        {section.rows.map((row) => (
                            <BalanceSheetTableSection
                                key={row.label}
                                section={row}
                                depth={depth + 1}
                                renderRow={renderRow}
                            />
                        ))}
                        {renderRow({
                            label: `Total ${section.label}`,
                            value,
                            depth: depth + 1,
                            canExpand: false,
                            isTotal: true,
                            accountCodes,
                        })}
                    </>
                )}
            </>
        );
    } else {
        return renderRow({
            label: section.label,
            value: last(section.values)!,
            depth,
            canExpand: false,
            accountCodes,
        });
    }
};
