import React from "react";
import { Button } from "react-bootstrap";
import { ButtonProps } from "react-bootstrap/Button";

import styles from "./AddButton.module.scss";

interface Props extends ButtonProps {
    text?: string;
    iconFontWeight?: number;
}

export const AddButton: React.FC<Props> = ({
    text,
    iconFontWeight,
    ...props
}) => {
    return (
        <Button variant="primary" className="btn-add" {...props}>
            <span
                className="fas fa-plus"
                style={{ fontWeight: iconFontWeight }}
            />{" "}
            {text && <span className={styles.marginLeft}>{text}</span>}
        </Button>
    );
};
