import { useMemo } from "react";
import { noop } from "../helpers/general";

type PromiseControls<T> = {
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
};
export function usePromise<T = unknown>(): [Promise<T>, PromiseControls<T>] {
    return useMemo(() => {
        let controls: PromiseControls<T> = {
            resolve: noop,
            reject: noop,
        };

        const promise = new Promise<T>((resolve, reject) => {
            controls = { resolve, reject };
        });

        return [promise, controls];
    }, []);
}
