import React from "react";
import { Transaction } from "../../../../common/types/transaction";
import { EagerLoaded } from "../../../../common/types/base/orm";
import styles from "./TransactionMatches.module.scss";
import { TransactionMatchFinancialDocument } from "./TransactionMatchFinancialDocument";
import { TransactionMatch } from "../../../../common/types/transactionMatch";
import { TransactionMatchesAddReceipt } from "./TransactionMatchesAddReceipt";

interface Props {
    financialTransaction: EagerLoaded<Transaction, "transactionMatches">;
}

export const TransactionMatches: React.FC<Props> = ({
    financialTransaction,
}) => {
    return (
        <div className={styles.matches}>
            <TransactionMatchesAddReceipt />

            {financialTransaction.transactionMatches.length > 0 && (
                <div className={styles.list}>
                    {financialTransaction.transactionMatches.map((match) => (
                        <TransactionMatchFinancialDocument
                            key={match.id}
                            financialTransaction={financialTransaction}
                            transactionMatch={
                                match as EagerLoaded<
                                    TransactionMatch,
                                    "financialDocument"
                                >
                            }
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
