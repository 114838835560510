import React from "react";
import classNames from "classnames";
import styles from "./PageHeading.module.scss";

interface Props {
    className?: string;
}

export const PageHeading: React.FC<Props> = ({ children, className }) => {
    return <h2 className={classNames(styles.header, className)}>{children}</h2>;
};
