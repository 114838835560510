import React from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";
import { DotsHorizontalIcon } from "../../../icons";
import styles from "./ManagementDropdown.module.scss";
import classNames from "classnames";

export interface ManagementDropdownProps extends DropdownProps {
    id: string;
    className?: string;
    toggleClassName?: string;
    toggleDisabled?: boolean;
    trigger?: React.ReactNode;
}

export const ManagementDropdown: React.FC<ManagementDropdownProps> = ({
    id,
    className,
    toggleClassName,
    toggleDisabled,
    trigger = <DotsHorizontalIcon />,
    children,
    ...rest
}) => {
    return (
        <Dropdown
            {...rest}
            className={classNames(
                "management-dropdown",
                styles.dropdown,
                className,
            )}
            data-testid="management-dropdown"
        >
            <Dropdown.Toggle
                id={id}
                variant="plain"
                size="sm"
                className={classNames(styles.toggle, toggleClassName)}
                disabled={toggleDisabled}
            >
                {trigger}
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.menu} data-testid="dropdown-menu">
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
};
