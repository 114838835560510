import React, { createContext, useContext, useMemo } from "react";

export interface FinancialAccountsConnectionContextValue {
    showAccountTypeWarning: boolean;
}

export interface FinancialAccountsConnectionProviderProps {
    showAccountTypeWarning: boolean;
}

const FinancialAccountsConnectionContext =
    createContext<FinancialAccountsConnectionContextValue>({
        showAccountTypeWarning: false,
    });

export function useFinancialAccountsConnectionContext() {
    const ctx = useContext(FinancialAccountsConnectionContext);

    if (!ctx) {
        throw new Error("Show only be used inside AccountsConnectionProvider ");
    }

    return ctx;
}

export const FinancialAccountsConnectionProvider: React.FC<
    FinancialAccountsConnectionProviderProps
> = ({ children, showAccountTypeWarning }) => {
    const value = useMemo(
        () => ({
            showAccountTypeWarning,
        }),
        [showAccountTypeWarning],
    );

    return (
        <FinancialAccountsConnectionContext.Provider value={value}>
            {children}
        </FinancialAccountsConnectionContext.Provider>
    );
};
