import React from "react";
import FullLogo from "../../static/images/logo_full.svg?react";
import IconLogo from "../../static/images/logo_icon.svg?react";
import CapitalWhiteLogo from "../../static/images/logo_capital_white.svg?react";
import classnames from "classnames";
import "./Logo.scss";

export type LogoProps = {
    variant?: "full" | "icon" | "capital-white" | false;
    className?: string;
    alt?: string;
};

export const Logo: React.FC<LogoProps> = ({
    variant = "full",
    className,
    ...rest
}) => {
    const commonProps = {
        alt: "Kick",
        className: classnames(`logo logo--${variant}`, className),
        ...rest,
    };

    switch (variant) {
        case "full":
            return <FullLogo {...commonProps} />;
        case "icon":
            return <IconLogo {...commonProps} />;
        case "capital-white":
            return <CapitalWhiteLogo {...commonProps} />;

        default:
            return null;
    }
};
