import { STATE_CODES } from "./states";
import { FinancialAccountType } from "./types/financialAccount";
import { InvitedUserRole } from "./types/inviteCode";
import { TransactionClassification } from "./types/transaction";
import { UserRole } from "./types/userEntityAssignment";
import { ConnectionProviderType } from "./types/financialConnection";

export const TRANSACTION_STATUS_LABELS = {
    pending: "Pending",
    completed: "Success",
    failed: "Failed",
};

export const KICK_TRANSACTION_DESCRIPTION = "Kick";

export const PHONE_NUMBER_PREFIX = "+1";

export const FINANCIAL_ACCOUNT_TYPE_LABEL: Record<
    FinancialAccountType,
    string
> = {
    [FinancialAccountType.CHECKING]: "Checking",
    [FinancialAccountType.CREDIT_CARD]: "Credit card",
    [FinancialAccountType.SAVINGS]: "Savings",
    [FinancialAccountType.LOAN]: "Loan",
    [FinancialAccountType.INVESTMENT]: "Investment",
    [FinancialAccountType.PAYMENT_PROCESSOR]: "Payment processor",
    [FinancialAccountType.OTHER]: "Other",
};

export enum OAuthServiceType {
    QUICKBOOKS = "quickbooks",
    STRIPE = "stripe",
    GUSTO = "gusto",
}

export const SENTRY_SERVICE_TAG = "service";
export const TWILIO_CLIENT = Symbol("TWILIO_CLIENT");

export const MAX_RECEIPT_SIZE_BYTES = 20 * 1024 * 1024;

export const SPLIT_TRANSACTION_CATEGORY = "Split";

export const TRANSACTION_CLASSIFICATION_LABELS: Record<
    TransactionClassification,
    string
> = {
    [TransactionClassification.BUSINESS]: "Business",
    [TransactionClassification.PERSONAL]: "Personal",
};

export enum CustomLocationOption {
    INTERNATIONAL = "international",
    UNKNOWN = "unknown",
}

export const TAX_LOCATIONS = [
    ...Object.values(CustomLocationOption),
    ...STATE_CODES,
];

export enum FinancialAccountClassification {
    BUSINESS = "business",
    MIXED = "mixed",
}

export enum PlaidConnectionErrors {
    DISCONNECTED = "disconnected",
    MFA_REQUIRED = "mfa_required",
}

export const ROLES_LABELS: Record<UserRole, string> = {
    [UserRole.PRIMARY_ADMIN]: "Primary Admin",
    [UserRole.ADMIN]: "Admin",
    [UserRole.MEMBER]: "Member",
    [UserRole.SUPPORT]: "Support",
    [UserRole.ACCOUNTANT]: "Accountant",
};

export const PRIMARY_ADMIN_ROLES = [UserRole.PRIMARY_ADMIN];
export const ADMIN_ROLES = [
    UserRole.ADMIN,
    UserRole.PRIMARY_ADMIN,
    UserRole.SUPPORT,
];

export const INVITE_ROLE_TO_USER_ROLE: Record<InvitedUserRole, UserRole> = {
    [InvitedUserRole.ADMIN]: UserRole.ADMIN,
    [InvitedUserRole.MEMBER]: UserRole.MEMBER,
};

export const VERSION_HEADER = "x-app-version" as const;

export const ENTITY_ICON_COLORS = [
    "#FADEC4",
    "#BDF0D5",
    "#D9CCFF",
    "#FED0D4",
    "#E8EAED",

    "#E6E5CA",
    "#C7E4E4",
    "#E6CEF1",
    "#F7D9DD",
    "#D1EBD0",

    "#D0D8F2",
    "#F2CFE3",
    "#F0E2E5",
];

export const USER_ICON_COLORS = [
    "#BDF0D5",
    "#D9CCFF",
    "#FED0D4",
    "#E8EAED",
    "#E6E5CA",

    "#C7E4E4",
    "#E6CEF1",
    "#F7D9DD",
    "#D1EBD0",
    "#D0D8F2",

    "#F2CFE3",
    "#F0E2E5",
    "#FADEC4",
];

// icon color for personal entity doesn't matter as user icon is used as personal entity icon
export const PERSONAL_ENTITY_ICON_COLOR = ENTITY_ICON_COLORS[1];
export const OTHER_ENTITY_COLOR = ENTITY_ICON_COLORS[2];

export const EMPTY_STATE = "—";

export const UNKNOWN_AUDIT_LOG_AUTHOR = "Unknown";
export enum AUDIT_LOG_SYSTEM_COMPONENT {
    PLAID_IMPORT = "plaid_import",
    CATEGORIZATION_API = "categorization_api",
    DEFAULT_VALUE_FOR_TYPE = "default_value_for_type",
    RELATED_CARD_TRANSACTION = "related_card_transaction",
    TRANSFER_MATCHING = "transfer_matching",
    SYSTEM = "system", // ie. report generating lambda
    FILE_IMPORT = "file_import",
}

export enum MoneyDirection {
    MONEY_IN = "MONEY_IN",
    MONEY_OUT = "MONEY_OUT",
}

export enum SortOrder {
    ASC = "ASC",
    DESC = "DESC",
}

export const DatesComparisonOperands = {
    since: ">=",
    until: "<",
};

export const institutionsWithManuallyProvidedData = [
    "ins_128026", // Capital One
];
export const REPORTS_CONTROLLER_DATE_FORMAT = "yyyy-MM-dd";

export enum ReportingTabCycle {
    WEEK = "week",
    MONTH = "month",
}

export const DEFAULT_NUMBER_OF_MONTHS_AVAILABLE = 3;

export const TRANSACTION_MATERIALITY_THRESHOLD = 0.02;

export enum UpdateEmailsFrequency {
    WEEK = "week",
    MONTH = "month",
}

export enum SERVICE_PREFIX {
    MAIN_APP = "api",
    ADMIN = "admin2",
}

export const FinancialConnectionProviderLabel: Record<
    ConnectionProviderType,
    string
> = {
    [ConnectionProviderType.PLAID]: "Plaid",
    [ConnectionProviderType.STRIPE]: "Stripe",
    [ConnectionProviderType.GUSTO]: "Gusto",
};

export enum FinancialDocumentStatus {
    MATCHED = "MATCHED",
    NOT_MATCHED = "NOT_MATCHED",
}

export enum FinancialDocumentBulkActionTypes {
    REMOVE = "REMOVE",
    ASSIGN_ENTITY = "ASSIGN_ENTITY",
}

export const GOOGLE_AUTH_REDIRECT_PATH = "/google";
