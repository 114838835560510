import React from "react";
import { FilterProps } from "./lib";
import styles from "./TransactionActionsToggleFilter.module.scss";
import { Switch } from "../../general/Switch/Switch";
import { usePotentialSavingsContext } from "../PotentialSavingsContext";
import classNames from "classnames";
import { noop } from "../../../helpers/general";
import { currencyFormatter } from "../../../common/helpers/currency";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useInteraction } from "../../../hooks/useInteraction";

export const TransactionActionsToggleFilter: React.FC<FilterProps> = ({
    filters,
    onChange,
}) => {
    const { savings } = usePotentialSavingsContext();
    const hasSavings = savings > 0;

    const toggle = useInteraction(() => {
        if (hasSavings) {
            onChange({
                requiredAction: !filters.requiredAction,
            });
        }
    });

    const isActive = hasSavings && !!filters.requiredAction;

    let tooltipText: React.ReactNode;

    if (!hasSavings) {
        tooltipText = (
            <>
                You're all set. We'll let you know here when you have any
                transactions with actions.
            </>
        );
    } else if (isActive) {
        tooltipText = <>Turn Off to view all your transactions.</>;
    } else {
        tooltipText = <>Turn on to only view transactions with actions.</>;
    }

    return (
        <OverlayTrigger
            placement="bottom-end"
            overlay={
                <Tooltip id="required-actions" className="tooltip--large">
                    {tooltipText}
                </Tooltip>
            }
        >
            <div
                className={classNames(styles.filter, {
                    [styles.disabled]: !hasSavings,
                })}
                {...toggle}
            >
                <span className={styles.toggleButton}>
                    <Switch
                        disabled={!hasSavings}
                        active={hasSavings && !!filters.requiredAction}
                        onChanged={noop}
                        label="Actions"
                        size="sm"
                    />
                </span>

                <span className={styles.savings}>
                    {currencyFormatter.format(savings)}
                </span>
            </div>
        </OverlayTrigger>
    );
};
