import React from "react";
import classNames from "classnames";

import "./Tag.scss";

export type TagVariant = "primary" | "danger" | "warning" | "secondary" | "cta";
export interface TagProps {
    variant?: TagVariant;
    disabled?: boolean;
    outline?: boolean;
    round?: boolean;
    size?: "sm" | "lg";

    className?: string;
}

export const Tag: React.FC<TagProps> = ({
    children,
    outline,
    disabled,
    round,
    variant = "primary",
    className,
    size,
}) => {
    return (
        <span
            className={classNames(
                "tag",
                {
                    [`tag--${variant}`]: variant,
                    [`tag--${size}`]: size,
                    "tag--outline": outline,
                    "tag--disabled": disabled,
                    "tag--round": round,
                },
                className,
            )}
        >
            {children}
        </span>
    );
};
