import React, { useCallback, useMemo } from "react";
import { Card } from "../../../general/Card/Card";
import { SearchFilter } from "../../../general/SearchFilter/SearchFilter";
import { AggregatedFilters } from "../../../general/AggregatedFilters/AggregatedFilters";
import { ActiveFilters } from "../../../general/ActiveFilters/ActiveFilters";
import { AggregatedFiltersProvider } from "../../../general/AggregatedFilters/AggregatedFiltersProvider";
import { useFiltersActive } from "../../useFiltersActive";
import { useActiveFilters } from "../../filters/AggregatedFilters/ActiveFilters/ActivityFilter.hooks";
import {
    DEFAULT_TAB,
    FiltersChangeFn,
    TransactionsFilters,
} from "../../filters/lib";
import css from "../../../general/AggregatedFilters/AggregatedFilters.module.scss";
import { AggregatedTransactionFiltersOverlay } from "../../filters/AggregatedFilters/AggregatedTransactionFiltersOverlay";
import { TransactionsActiveFilterButton } from "../../filters/AggregatedFilters/ActiveFilters/TransactionsActiveFilterButton";
import { AggregatedAccountFilter } from "../../filters/AggregatedFilters/AccountFilter/AggregatedAccountFilter";
import classNames from "classnames";
import { isEqual, omitBy } from "lodash";
import { CustomSelect } from "../../../forms/CustomSelect/CustomSelect";
import { Button } from "react-bootstrap";
import { ArrowDownIcon, ArrowUpIcon } from "../../../../icons";
import styles from "./MatchingView.module.scss";
import { MatchingFiltersPreferenceOption } from "../useTransactionsForTransferMatching";

interface Props {
    filters: TransactionsFilters;
    onChange: (filters: TransactionsFilters) => void;
    resetFilters: () => void;
    preference: MatchingFiltersPreferenceOption;
    setPreference: (preference: MatchingFiltersPreferenceOption) => void;
}

const preferenceLabels: Record<MatchingFiltersPreferenceOption, string> = {
    [MatchingFiltersPreferenceOption.Suggested]: "Suggested",
    [MatchingFiltersPreferenceOption.All]: "All transactions",
};

const preferenceOptions = [
    {
        label: "Suggested",
        value: MatchingFiltersPreferenceOption.Suggested,
    },
    {
        label: "All transactions",
        value: MatchingFiltersPreferenceOption.All,
    },
];

export const TransferMatchingViewFilters: React.FC<Props> = ({
    filters,
    onChange,
    resetFilters,
    preference,
    setPreference,
}) => {
    const filtersActive = useFiltersActive(filters);
    const activeFilters = useActiveFilters(filters);

    const activeFiltersWithoutTransferMatch = useMemo(
        () => activeFilters.filter((filter) => filter !== "direction"),
        [activeFilters],
    );
    const updateDescriptionFilter = useCallback(
        (description?: string) => {
            onChange({
                ...filters,
                description,
            });
        },
        [onChange, filters],
    );

    const handleFiltersChange: FiltersChangeFn = useCallback(
        (value: Partial<TransactionsFilters>) => {
            const newFilters = omitBy(
                { ...filters, ...value },
                (v) => v === null || v === undefined,
            );

            if (!isEqual(newFilters, filters)) {
                onChange(newFilters);
            }
        },
        [filters, onChange],
    );

    return (
        <AggregatedFiltersProvider defaultTab={DEFAULT_TAB}>
            <Card className={css.filters}>
                <section className={css.filters}>
                    <CustomSelect
                        onSelected={(
                            newValue: MatchingFiltersPreferenceOption,
                        ) => setPreference(newValue)}
                        value={preference}
                        dropdownKey="transfer-matching-preference-select"
                        options={preferenceOptions}
                        className="reports-filter-form__cycle-select"
                        popoverClassName={styles.filtersPreferenceSelectPopover}
                        empty="NO"
                    >
                        {(opened) => (
                            <Button
                                variant="secondary"
                                className={classNames({
                                    active: opened,
                                })}
                                size="sm"
                            >
                                {preferenceLabels[preference]}
                                {opened ? (
                                    <ArrowUpIcon className="ml-2" />
                                ) : (
                                    <ArrowDownIcon className="ml-2" />
                                )}
                            </Button>
                        )}
                    </CustomSelect>
                    <SearchFilter
                        filter={filters.description}
                        onChange={updateDescriptionFilter}
                        placeholder="Description..."
                    />

                    <AggregatedFilters>
                        <AggregatedTransactionFiltersOverlay
                            filters={filters}
                            onChange={handleFiltersChange}
                            directionSwitchDisabled
                        />
                    </AggregatedFilters>
                </section>

                {filtersActive && (
                    <ActiveFilters
                        activeFilters={activeFiltersWithoutTransferMatch}
                        renderVisibleFilters={(visibleFilters) =>
                            visibleFilters.map((filterId) => (
                                <TransactionsActiveFilterButton
                                    key={filterId}
                                    filterId={filterId}
                                    filters={filters}
                                    onChange={handleFiltersChange}
                                />
                            ))
                        }
                        clearFilters={resetFilters}
                    />
                )}

                <section className={classNames(css.filters, "ml-auto")}>
                    <AggregatedAccountFilter
                        filters={filters}
                        onChange={handleFiltersChange}
                    />
                </section>
            </Card>
        </AggregatedFiltersProvider>
    );
};
