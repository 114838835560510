import React, { Children, useCallback } from "react";
import classNames from "classnames";
import styles from "./OptionGroup.module.scss";
import { CheckIcon, LockIcon } from "../../../icons";
import { useInteraction } from "../../../hooks/useInteraction";

export interface OptionGroupItemProps {
    icon?: React.ReactNode;
    title: React.ReactNode;
    onToggle: () => void;
    selected?: boolean;
    disabled?: boolean;
    className?: string;
}

export const OptionGroupItem: React.FC<OptionGroupItemProps> = ({
    icon,
    title,
    onToggle,
    selected,
    disabled,
    className,
    children,
}) => {
    const handler = useCallback(() => {
        if (!disabled) {
            onToggle();
        }
    }, [disabled, onToggle]);
    const toggleInteraction = useInteraction(handler);
    let status: React.ReactNode = null;

    if (disabled) {
        status = <LockIcon className={styles.statusIndicator} />;
    } else if (selected) {
        status = <CheckIcon className={styles.statusIndicator} />;
    }

    return (
        <div
            className={classNames(styles.item, className, {
                [styles.selected]: selected,
                [styles.disabled]: disabled,
            })}
            {...toggleInteraction}
        >
            {Children.count(children) > 0 ? (
                children
            ) : (
                <>
                    {icon && <aside className={styles.icon}>{icon}</aside>}
                    <div className={styles.title}>{title}</div>
                    {status}
                </>
            )}
        </div>
    );
};
