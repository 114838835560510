import { useContext } from "react";
import { EntityContext } from "./entity.context";

export function useEntity() {
    const context = useContext(EntityContext);

    if (!context.entity) {
        throw new Error("useEntity must be used within an EntityContext");
    }

    return context.entity;
}
