import React from "react";
import { useEntities } from "../../hooks/useEntities";
import { EntityProvider } from "../entity/EntityProvider";
import { DashboardEntityAccounts } from "./DashboardEntityAccounts";
import { usePlaidFinancialAccounts } from "../../hooks/useFinancialAccounts";
import { useLatestBalanceSyncTime } from "../../hooks/useLatestSyncTime";
import { ClockIcon } from "../../icons";
import { timeAgo } from "../../helpers/date";
import { NavLink } from "react-router-dom";

export const DashboardAccounts: React.FC = () => {
    const entities = useEntities();
    const accounts = usePlaidFinancialAccounts();
    const lastSyncedConnectionAt = useLatestBalanceSyncTime(accounts);

    return (
        <>
            {lastSyncedConnectionAt && (
                <header>
                    <ClockIcon className="fill-gray-500" />
                    {timeAgo(lastSyncedConnectionAt)}
                </header>
            )}
            {entities.map((e) => (
                <EntityProvider entity={e} key={e.id}>
                    <DashboardEntityAccounts />
                </EntityProvider>
            ))}
            <footer className="mt-4">
                <NavLink to="/accounts" className="btn btn-secondary btn-sm">
                    View accounts
                </NavLink>
            </footer>
        </>
    );
};
