import React from "react";
import styles from "./AutopilotSavingsEmptyState.module.scss";

export const AutopilotSavingsEmptyState: React.FC = () => {
    return (
        <main className={styles.main}>
            <header>All set 😎</header>
            <p>You're done with Savings actions</p>
        </main>
    );
};
