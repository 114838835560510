import React from "react";
import { Card } from "../../general/Card/Card";
import styles from "./YearCard.module.scss";
import classNames from "classnames";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";

export interface YearCardProps {
    year: number;
    priceFormatted: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
    selected: boolean;
    onChange?(): void;
}

export const YearCard: React.FC<YearCardProps> = ({
    selected,
    year,
    onChange,
    title,
    description,
    priceFormatted,
}) => {
    return (
        <Card
            className={classNames(styles.card, {
                [styles.selected]: selected,
            })}
        >
            <FormCheckbox
                value={year}
                isChecked={selected}
                handleChange={onChange}
                small
                label={
                    <section
                        className={styles.body}
                        data-testid={`${year}-year-card`}
                    >
                        <main>
                            <header
                                className={styles.title}
                                data-testid={`${year}-title`}
                            >
                                {title}
                            </header>
                            <p
                                className={styles.description}
                                data-testid={`${year}-description`}
                            >
                                {description}
                            </p>
                        </main>

                        <aside
                            className={styles.price}
                            data-testid={`${year}-price`}
                        >
                            {priceFormatted}
                        </aside>
                    </section>
                }
            />
        </Card>
    );
};
