import React from "react";
import {
    ActiveFinancialDocumentFilters,
    FinancialDocumentFiltersProps,
    FinancialDocumentFilterTabs,
} from "./lib";
import { ActiveFilterButton } from "../../general/ActiveFilters/ActiveFilterButton";
import { useResetFinancialDocumentsFilter } from "./useResetFinancialDocumentsFilter";
import { useAggregatedFiltersContext } from "../../general/AggregatedFilters/AggregatedFilters.context";
import { DateFilterLabel } from "../../general/ActiveFilters/DateFilterLabel";
import { StatusFilterLabel } from "./StatusFilterLabel";

export interface FinancialDocumentsActiveFilterButtonProps
    extends FinancialDocumentFiltersProps {
    filterId: ActiveFinancialDocumentFilters;
}

const filterIdToTab: Record<
    ActiveFinancialDocumentFilters,
    FinancialDocumentFilterTabs
> = {
    date: FinancialDocumentFilterTabs.DATE,
    status: FinancialDocumentFilterTabs.STATUS,
};

export const FinancialDocumentsActiveFilterButton: React.FC<
    FinancialDocumentsActiveFilterButtonProps
> = ({ filterId, filters, onChange, children }) => {
    const resetFilter = useResetFinancialDocumentsFilter(onChange, filterId);
    const { open: openFilter } = useAggregatedFiltersContext();

    let label;

    switch (filterId) {
        case "date":
            label = <DateFilterLabel filters={filters} />;
            break;

        case "status":
            label = <StatusFilterLabel filters={filters} />;
            break;
    }

    return (
        <ActiveFilterButton
            openFilter={() => openFilter(filterIdToTab[filterId])}
            resetFilter={resetFilter}
            label={label}
        />
    );
};
