import { useParams } from "react-router-dom";

interface DocumentRouteParams {
    documentId: string;
    view?: string;
}

export function useDocumentParams() {
    return useParams<DocumentRouteParams>();
}
