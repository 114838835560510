import React, { useState } from "react";
import { OptionGroupItem } from "./OptionGroupItem";
import { CaptureInteractions } from "../CaptureInteractions";
import classNames from "classnames";
import styles from "./OptionGroup.module.scss";

interface OptionGroupCustomItemProps {
    value: string;
    empty: string;
    onChange(value: string): void;
    className?: string;
    disabled?: boolean;
}

export const OptionGroupCustomItem: React.FC<OptionGroupCustomItemProps> = ({
    value,
    empty,
    onChange,
    disabled,
    className,
}) => {
    const [editing, setEditing] = useState(false);

    return (
        <OptionGroupItem
            className={classNames(styles.customItem, className, {
                [styles.editing]: editing,
                [styles.disabled]: disabled,
            })}
            title={value || empty}
            onToggle={() => !disabled && setEditing(true)}
        >
            {!disabled && editing ? (
                <CaptureInteractions>
                    <input
                        type="text"
                        className="form-control"
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                        onBlur={() => setEditing(false)}
                        ref={(el) => el?.focus()}
                    />
                </CaptureInteractions>
            ) : null}
        </OptionGroupItem>
    );
};
