import React, { CSSProperties, useMemo, useState } from "react";
import styles from "./Plans.module.scss";
import { PlanHeader } from "./PlanHeader";
import { PlansRow } from "./PlansRow";
import { PlansColumn } from "./PlansColumn";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ArrowDownIcon, ArrowUpIcon } from "../../../icons";
import {
    detailedFeaturesByPlan,
    detailedFeaturesSections,
    featureDescriptions,
    highlightsPerPlan,
} from "./lib";
import { DetailedFeatureSection } from "./DetailedFeatureSection";
import classNames from "classnames";
import { FLAT_RATE_PLANS, FlatRatePlan } from "../../../common/flatRateBilling";
import { Tag } from "../../general/Tag/Tag";

interface Props {
    recommended: FLAT_RATE_PLANS | null;
    plans: FlatRatePlan[];
    renderAction: (plan: FLAT_RATE_PLANS) => React.ReactNode;
    stickyHeaders?: boolean;
}

export const Plans: React.FC<Props> = ({
    plans,
    stickyHeaders,
    renderAction,
    recommended,
}) => {
    const [expanded, setExpanded] = useState(false);

    const variables = useMemo(() => {
        return {
            "--plans-count": plans.length,
        } as CSSProperties;
    }, [plans.length]);

    return (
        <section
            className={classNames(styles.plans, {
                [styles.expanded]: expanded,
                [styles.stickyHeaders]: stickyHeaders,
            })}
        >
            <div className={styles.grid} style={variables}>
                <PlansColumn
                    open={expanded}
                    aboveTheFold={
                        <>
                            <PlansRow span={4} first sticky />
                            <PlansRow className={styles.sectionTitle}>
                                Annual business expenses
                            </PlansRow>
                            <PlansRow
                                className={`${styles.sectionTitle} align-content-start`}
                            >
                                Key features
                            </PlansRow>
                        </>
                    }
                    belowTheFold={detailedFeaturesSections.map((section) => (
                        <React.Fragment key={section.title}>
                            <PlansRow
                                className={`${styles.sectionTitle} ${styles.spaceBefore}`}
                            >
                                {section.title}
                            </PlansRow>

                            {section.features.map((feature) => {
                                const description =
                                    featureDescriptions[feature];

                                return (
                                    <PlansRow
                                        key={feature}
                                        className={styles.featureTitle}
                                    >
                                        <OverlayTrigger
                                            trigger={
                                                description
                                                    ? ["hover", "focus"]
                                                    : []
                                            }
                                            overlay={
                                                <Tooltip
                                                    id={`plans-tooltip-for-${feature}`}
                                                >
                                                    {description}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{feature}</span>
                                        </OverlayTrigger>
                                    </PlansRow>
                                );
                            })}
                        </React.Fragment>
                    ))}
                />

                {plans.map((plan) => (
                    <PlansColumn
                        key={plan.plan}
                        open={expanded}
                        recommended={plan.plan === recommended}
                        aboveTheFold={
                            <PlanHeader
                                plan={plan}
                                highlights={highlightsPerPlan[plan.plan]}
                                action={renderAction(plan.plan)}
                                label={
                                    plan.plan === recommended ? (
                                        <Tag variant="primary" size="sm">
                                            Recommended
                                        </Tag>
                                    ) : null
                                }
                            />
                        }
                        belowTheFold={detailedFeaturesSections.map(
                            (section, index) => (
                                <DetailedFeatureSection
                                    key={section.title}
                                    last={
                                        index ===
                                        detailedFeaturesSections.length - 1
                                    }
                                    features={section.features}
                                    featuresForPlan={
                                        detailedFeaturesByPlan[plan.plan]
                                    }
                                />
                            ),
                        )}
                    />
                ))}
            </div>

            <Button
                variant="plain"
                className={styles.expandButton}
                onClick={() => setExpanded(!expanded)}
                data-testid="toggle-plans-details-button"
            >
                {expanded ? (
                    <>
                        Show less details <ArrowUpIcon />
                    </>
                ) : (
                    <>
                        Compare all features <ArrowDownIcon />
                    </>
                )}
            </Button>
        </section>
    );
};
