import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import classNames from "classnames";
import "./SelectDropdown.scss";

interface Props {
    className?: string;
    prepend?: React.ReactNode;
}

export const SelectDropdown: React.FC<Props> = ({
    prepend,
    className,
    children,
}) => {
    return (
        <div className={classNames("select-dropdown", className)}>
            {prepend}
            <Scrollbars
                style={{ width: "100%" }}
                autoHeight
                autoHeightMin={48}
                autoHeightMax={260}
            >
                {children}
            </Scrollbars>
        </div>
    );
};
