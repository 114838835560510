import React, { useMemo } from "react";
import classNames from "classnames";
import "./Skeleton.scss";

interface Props extends React.HTMLAttributes<HTMLTableCellElement> {
    skeleton: number | string | React.ReactElement | null;
}

export const SkeletonCell: React.FC<Props> = ({
    skeleton,
    className,
    ...cellProps
}) => {
    const contentElement = useMemo(() => {
        if (skeleton === null || React.isValidElement(skeleton)) {
            return skeleton;
        } else {
            return (
                <span
                    className="skeleton__placeholder"
                    style={{
                        width: `${skeleton}${
                            typeof skeleton === "string" ? "" : "px"
                        }`,
                    }}
                />
            );
        }
    }, [skeleton]);

    return (
        <td {...cellProps} className={classNames("skeleton__cell", className)}>
            {contentElement}
        </td>
    );
};
