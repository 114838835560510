import React, { useMemo } from "react";
import {
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../../../common/helpers/currency";
import { getTierForUsage } from "../../../../../common/billing";
import { numberFormatterNoFractions } from "../../../../../common/helpers/number";
import styles from "./FAQ.module.scss";
import { useBillingStatus } from "../../../../../hooks/useBillingStatus";

export const PricingExampleTable: React.FC = () => {
    const { pricingTiers } = useBillingStatus();

    const rows = useMemo(() => {
        const expenses = pricingTiers
            .slice(0, pricingTiers.length - 1)
            .map((tier) => tier.end + 1);

        return expenses.map((expense) => {
            const tier = getTierForUsage(pricingTiers, expense);
            const annualPrice = tier.price * 12;

            return {
                expense,
                price: tier.price,
                percentage: Math.round((annualPrice / expense) * 100),
            };
        });
    }, [pricingTiers]);

    return (
        <table className={`table table-sm ${styles.examplePricingTable}`}>
            <thead>
                <tr>
                    <th>Annual Expenses</th>
                    <th>Kick Monthly Cost</th>
                    <th>% of Expenses</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        {"<"}
                        {currencyFormatterShort
                            .format(pricingTiers[0].end)
                            .toLowerCase()}
                    </td>
                    <td>{currencyFormatterNoFractions.format(0)}</td>
                    <td>0%</td>
                </tr>

                {rows.map(({ price, percentage, expense }) => (
                    <tr key={expense}>
                        <td>
                            {currencyFormatterShort
                                .format(expense)
                                .toLowerCase()}
                        </td>
                        <td>{currencyFormatterNoFractions.format(price)}</td>
                        <td>
                            {numberFormatterNoFractions.format(percentage)}%
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
