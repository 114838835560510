import React from "react";
import { CurrentPlan } from "./CurrentPlan";
import { useUser } from "../../../../hooks/useUser";
import { PaymentDetails } from "./PaymentDetails";
import { BillingHistory } from "./BillingHistory";
import { PreviousYearBookkeeping } from "./PreviousYearBookkeeping";

export const FlatRateBilling: React.FC = () => {
    const { isSubscribed } = useUser();

    return (
        <>
            <CurrentPlan />
            {isSubscribed && <PreviousYearBookkeeping />}
            {isSubscribed && <PaymentDetails />}
            <BillingHistory />
        </>
    );
};
