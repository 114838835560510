import React from "react";
import { InviteCode } from "../../../common/types/inviteCode";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RevertIcon, TrashIcon } from "../../../icons";
import { resendInvite } from "../../../lib/user";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useMutation } from "react-query";
import { useRemoveInviteMutation } from "../../../mutations/user";
import { ROLES_LABELS } from "../../../common/constants";
import { useEntity } from "../../entity/useEntity";
import { ObjectIcon } from "../../general/ObjectIcon/ObjectIcon";

interface Props {
    invite: InviteCode;
}

export const InviteRow: React.FC<Props> = ({ invite }) => {
    const entity = useEntity();
    const resend = useMutation(() => resendInvite(invite));
    const remove = useRemoveInviteMutation(invite, entity);

    return (
        <tr>
            <td className="members__name">
                <div className="d-flex align-items-center">
                    <ObjectIcon
                        name={invite.email!}
                        defaultColor={invite.iconColor}
                        size="lg"
                    />
                    <div className="ml-2">
                        <small className="text-grey">{invite.email!}</small>
                    </div>
                </div>
            </td>
            <td className="members__role">{ROLES_LABELS[invite.role!]}</td>
            <td className="members__activity">
                {resend.isSuccess ? (
                    <>Invite resent</>
                ) : (
                    <>
                        Invite sent
                        <OverlayTrigger
                            overlay={
                                <Tooltip id={`resend_${invite.id}`}>
                                    Resend invite
                                </Tooltip>
                            }
                        >
                            <ButtonWithLoader variant="link" mutation={resend}>
                                <RevertIcon />
                            </ButtonWithLoader>
                        </OverlayTrigger>
                    </>
                )}
            </td>
            <td className="members__remove">
                <ButtonWithLoader variant="link" mutation={remove}>
                    <TrashIcon />
                </ButtonWithLoader>
            </td>
        </tr>
    );
};
