import React from "react";
import styles from "./PhoneMockup.module.scss";

export const PhoneMockup: React.FC = ({ children }) => {
    return (
        <div className={styles.phone}>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
