export function getAnonymousAccountNumber(
    accountNumber: string,
    maskLength: number = 4,
    divider: string = " ",
) {
    const last4Digits = accountNumber.substring(accountNumber.length - 4);

    if (maskLength === 0) {
        return last4Digits;
    } else {
        return `${"•".repeat(maskLength)}${divider}${last4Digits}`;
    }
}
