import React, { useMemo } from "react";
import { DataTableHeaderCell } from "../../general/DataTable/DataTableHeaderCell";
import { format, parse } from "date-fns";

interface Props {
    label: string;
}

export const ProfitLossTableHeaderCell: React.FC<Props> = ({ label }) => {
    const formattedLabel = useMemo(() => {
        return format(parse(label, "yyyy-MM", new Date()), "MMM yyyy");
    }, [label]);

    return <DataTableHeaderCell>{formattedLabel}</DataTableHeaderCell>;
};
