import { ReorderAccountsDto } from "../common/dto/entity/reorder-accounts.dto";
import { backendClient, unwrapResponse } from "./backendClient";
import { Entity } from "../common/types/entity";
import { SaveTaxLocationsDto } from "../common/dto/entity/save-tax-locations.dto";
import { UpdateEntityAddressDto } from "../common/dto/entity/update-entity-address.dto";
import { Address } from "../common/types/embeddable/address";
import { CreateEntityDto } from "../common/dto/entity/create-entity.dto";
import { TransactionFiltersDto } from "../common/dto/transactions/getTransactions/transaction-filters.dto";

export async function createEntity(data: CreateEntityDto): Promise<Entity> {
    return unwrapResponse(await backendClient.post("/entity", data));
}

export async function reorderEntityAccounts(data: ReorderAccountsDto) {
    await backendClient.post("/entity/reorder-accounts", data);
}

export async function getPotentialSavings(
    filters: TransactionFiltersDto,
): Promise<number> {
    return unwrapResponse(
        await backendClient.get(`/entity/potential-savings`, {
            params: { filters },
            paramsSerializer: { indexes: true },
        }),
    );
}

export async function getAddress(entity: Entity): Promise<Address | null> {
    return unwrapResponse(
        await backendClient.get(`/entity/${entity.id}/address`),
    );
}

export async function updateAddress(
    data: UpdateEntityAddressDto,
): Promise<Address> {
    return unwrapResponse(await backendClient.put("/entity/address", data));
}

export async function saveTaxLocations(
    data: SaveTaxLocationsDto,
): Promise<Entity> {
    return unwrapResponse(
        await backendClient.post(`/entity/tax-location`, data),
    );
}
