import React from "react";
import { currencyFormatterNoFractions } from "../../../../../common/helpers/currency";
import styles from "./Quote.module.scss";

interface Props {
    value: number;
}
export const SubQuoteItem: React.FC<Props> = ({ children, value }) => {
    return (
        <li className={value === 0 ? styles.freeTier : ""}>
            <span>{children}</span>
            <aside>{currencyFormatterNoFractions.format(value)}</aside>
        </li>
    );
};
