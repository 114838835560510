import React from "react";
import { SettingsSection } from "../../SettingsSection";
import { Card } from "../../../general/Card/Card";
import styles from "./FlatRateBilling.module.scss";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { FLAT_RATE_PLANS } from "../../../../common/flatRateBilling";
import {
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../../common/helpers/monetary";
import { format } from "date-fns";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import { useSettings } from "../../SettingsProvider";
import { Button } from "react-bootstrap";
import { ThunderboltIcon } from "../../../../icons";
import { CustomProgressBar } from "../../../general/CustomProgressBar/CustomProgressBar";
import { clamp } from "lodash";
import classNames from "classnames";
import { CurrentPlanFooter } from "./CurrentPlanFooter";
import { CurrentPlanHeader } from "./CurrentPlanHeader";

export const CurrentPlan: React.FC = () => {
    const { open } = useSettings();
    const {
        currentPlan,
        plans,
        trialAvailable,
        totalUsage,
        isFetched,
        periodEnd,
        cancelsAt,
        isTrialing,
    } = useBillingStatus();

    if (!isFetched) {
        return null;
    }

    const currentPlanData = plans.find((plan) => plan.plan === currentPlan)!;
    const maxUsage = currentPlanData.usageLimit;

    const isAtOrOverLimit = totalUsage >= maxUsage;
    const isOnFreePlan = currentPlan === FLAT_RATE_PLANS.FREE;
    const clampedUsage = clamp(totalUsage, 0, maxUsage);

    let periodEndAction: string;

    if (isTrialing) {
        periodEndAction = "Trial ends";
    } else if (cancelsAt) {
        periodEndAction = "Ends";
    } else {
        periodEndAction = "Renews";
    }

    return (
        <SettingsSection heading="Current Plan">
            <Card className={styles.card}>
                <section className={styles.section}>
                    <CurrentPlanHeader />
                    <main>
                        {currencyFormatterNoFractions.format(
                            fromIntToMonetary(
                                currentPlanData.monthlyAmountCents,
                            ),
                        )}
                        /m
                        {periodEnd && (
                            <>
                                {" "}
                                - {periodEndAction} on{" "}
                                {format(
                                    cancelsAt ?? periodEnd,
                                    fullDateFullYearFormat,
                                )}
                            </>
                        )}
                        <aside>
                            {isOnFreePlan ? (
                                <Button
                                    size="sm"
                                    variant="cta"
                                    onClick={() => open(["plans"])}
                                    data-testid={`${trialAvailable ? "start-trial-button" : "upgrade-button"}`}
                                >
                                    <ThunderboltIcon className="mr-2" />{" "}
                                    {trialAvailable ? "Start trial" : "Upgrade"}
                                </Button>
                            ) : (
                                <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => open(["plans"])}
                                    data-testid="update-plan-button"
                                >
                                    Update plan
                                </Button>
                            )}
                        </aside>
                    </main>
                </section>

                {currentPlan !== FLAT_RATE_PLANS.ENTERPRISE && (
                    <section
                        className={classNames(
                            styles.section,
                            styles.progressBarSection,
                        )}
                    >
                        <header className={styles.title}>Usage</header>

                        <main>
                            <span className="text-left">
                                {currencyFormatterNoFractions.format(0)}
                            </span>
                            <span className="text-right">
                                {currencyFormatterNoFractions.format(maxUsage)}
                            </span>
                            <CustomProgressBar
                                className={styles.progressBar}
                                value={clampedUsage}
                                max={maxUsage}
                                renderValue={(value) => (
                                    <>
                                        {currencyFormatterShort
                                            .format(value)
                                            .toLowerCase()}{" "}
                                        used
                                    </>
                                )}
                            />
                        </main>
                    </section>
                )}

                <CurrentPlanFooter
                    isOnFreePlan={isOnFreePlan}
                    isAtOrOverLimit={isAtOrOverLimit}
                    maxUsage={maxUsage}
                />
            </Card>
        </SettingsSection>
    );
};
