import React from "react";
import { SettingsSection } from "../../SettingsSection";
import { currencyFormatterNoFractions } from "../../../../common/helpers/currency";
import { format } from "date-fns";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import { NewWindowIcon } from "../../../../icons";
import { getEnv } from "../../../../lib/environment";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { SubscriptionType } from "../../../../common/types/billing";
import { fromIntToMonetary } from "../../../../common/helpers/monetary";
import styles from "./CurrentTier.module.scss";

export const CurrentTier: React.FC = () => {
    const { subscriptionDetails } = useBillingStatus();

    if (!subscriptionDetails) {
        return null;
    }

    let tierPrice: React.ReactNode;

    if (subscriptionDetails.price === 0) {
        tierPrice = "Free";
    } else if (subscriptionDetails.type === SubscriptionType.CUSTOM) {
        tierPrice = currencyFormatterNoFractions.format(
            fromIntToMonetary(subscriptionDetails.price),
        );
    } else if (subscriptionDetails.type === SubscriptionType.MONTHLY) {
        tierPrice = (
            <>
                {currencyFormatterNoFractions.format(
                    fromIntToMonetary(subscriptionDetails.price),
                )}
                <span>/m</span>
            </>
        );
    } else if (subscriptionDetails.type === SubscriptionType.ANNUAL) {
        tierPrice = (
            <>
                {currencyFormatterNoFractions.format(
                    fromIntToMonetary(subscriptionDetails.price / 12),
                )}
                <span>/m</span>
            </>
        );
    }

    return (
        <SettingsSection heading="Current Tier">
            <div className={styles.tierInfo}>
                {tierPrice}{" "}
                {subscriptionDetails.renewsOn && (
                    <>
                        - Renews on{" "}
                        {format(
                            subscriptionDetails.renewsOn,
                            fullDateFullYearFormat,
                        )}
                    </>
                )}
            </div>

            <div className={styles.manage}>
                To view billing history, please visit your{" "}
                <a
                    target="_blank"
                    href={getEnv("VITE_STRIPE_PORTAL_URL")}
                    rel="noreferrer"
                >
                    Stripe account
                    <NewWindowIcon className="ml-1" />
                </a>
            </div>
        </SettingsSection>
    );
};
