import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Entity } from "../../common/types/entity";
import { useUser } from "../../hooks/useUser";
import { useUpdatePersonalInfoMutation } from "../../mutations/user";
import { useEditEntityMutation } from "../../mutations/entity";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../store";
import { usePlaidFinancialAccounts } from "../../hooks/useFinancialAccounts";
import { useMobileView } from "../../hooks/useMobileView";
import { usePromise } from "../../hooks/usePromise";
import { OnboardingStep, setForcedStep } from "../../reducers/onboarding";
import memoize from "lodash/memoize";
import { UpdateEntityDto } from "../../common/dto/entity/update-entity.dto";
import { PersonalInfo, PersonalInfoProps } from "./PersonalInfo/PersonalInfo";
import { AxiosError } from "axios";
import { addNotification } from "../../reducers/appState";
import {
    BusinessProfile,
    BusinessProfileProps,
} from "./BusinessProfile/BusinessProfile";
import {
    BusinessDescription,
    BusinessDescriptionProps,
} from "./BusinessDescription/BusinessDescription";
import { sendReceiptsIntro } from "../../lib/user";
import { ConnectAccounts } from "./ConnectAccounts/ConnectAccounts";
import { ReceiptsStart } from "./Receipts/ReceiptsStart";
import { ReceiptsFinish } from "./Receipts/ReceiptsFinish";
import { Outro } from "./Outro/Outro";
import { Loader } from "../general/Loader";
import { Button, Modal } from "react-bootstrap";
import {
    ExpandedSidebar,
    ExpandedSidebarProps,
} from "../layout/ExpandedSidebar/ExpandedSidebar";
import classNames from "classnames";
import { OnboardingNav } from "./OnboardingNav";
import { EntityProvider } from "../entity/EntityProvider";
import { ConfirmationModal } from "../general/ConfirmationModal";
import { ChoosePlan } from "./ChoosePlan/ChoosePlan";
import { PlanManagementProvider } from "../billing/PlanManagement/PlanManagementProvider";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { useOnboardingSteps } from "./useOnboardingSteps";
import { useLogout } from "../../hooks/useLogout";
import { trackEvent } from "../../lib/analytics";
import { FLAT_RATE_PLANS } from "../../common/flatRateBilling";

interface Props {
    businessEntity: Entity;
    showSidebar: boolean;
    onFinish: () => Promise<void>;
    sidebarSize: ExpandedSidebarProps["sidebarSize"];
}

export const OnboardingEntity: React.FC<Props> = ({
    businessEntity: entity,
    showSidebar,
    onFinish,
    sidebarSize,
}) => {
    const onboardingSteps = useOnboardingSteps();
    const { flatRateBilling } = useTypedFlags();
    const user = useUser();
    const { isSubscribed } = useBillingStatus();
    const updatePersonalInfo = useUpdatePersonalInfoMutation();
    const editEntity = useEditEntityMutation(entity);

    const { forcedStep } = useSelector((state: State) => state.onboarding);

    const plaidAccounts = usePlaidFinancialAccounts();

    const dispatch = useDispatch();
    const isMobile = useMobileView();

    const [showConnectAllAccountsReminder, setShowConnectAllAccountsReminder] =
        useState(false);

    const [reminderPromise, { resolve: confirmReminder }] = usePromise<void>();

    const beforeFirstConnection = useCallback(() => {
        if (plaidAccounts.length > 0) {
            confirmReminder();
        } else {
            setShowConnectAllAccountsReminder(true);
            reminderPromise.then(() =>
                setShowConnectAllAccountsReminder(false),
            );
        }

        return reminderPromise;
    }, [confirmReminder, plaidAccounts.length, reminderPromise]);

    const handleReminderConfirmed = useCallback(() => {
        setShowConnectAllAccountsReminder(false);
        confirmReminder();
    }, [confirmReminder]);

    const step: OnboardingStep = (() => {
        if (forcedStep) {
            return forcedStep;
        } else if (!user.name) {
            return "personal_info";
        } else if (!entity.name) {
            return "business_profile";
        } else if (!entity.profile?.description) {
            return "business_description";
        } else if (
            !plaidAccounts?.length ||
            plaidAccounts.some((acc) => acc.isBusiness === null)
        ) {
            return "connect_accounts";
        } else if (!isSubscribed) {
            return "choose_plan";
        } else {
            return "outro";
        }
    })();

    useEffect(() => {
        // prevents going to next step as soon as
        dispatch(setForcedStep(step));
    }, [dispatch, step]);

    const doLogout = useLogout();

    const goToStep = useMemo(
        () =>
            memoize((nextStep: OnboardingStep) => {
                return () => {
                    dispatch(setForcedStep(nextStep));
                };
            }),
        [dispatch],
    );

    const updateEntityAndGo = useCallback(
        async (payload: UpdateEntityDto, nextStep?: OnboardingStep) => {
            await editEntity.mutateAsync(payload);

            if (nextStep) {
                dispatch(setForcedStep(nextStep));
            }
        },
        [dispatch, editEntity],
    );

    const handlePersonalInfo: PersonalInfoProps["onSubmit"] = useCallback(
        async (payload) => {
            try {
                await updatePersonalInfo.mutateAsync({
                    name: `${payload.firstName} ${payload.lastName}`,
                    email: payload.email,
                });

                dispatch(setForcedStep("business_profile"));
            } catch (e) {
                if (!(e as AxiosError).isAxiosError) {
                    dispatch(
                        addNotification({
                            type: "danger",
                            message: "Oops, something went wrong",
                        }),
                    );
                }
            }
        },
        [dispatch, updatePersonalInfo],
    );

    const handleBusinessProfile: BusinessProfileProps["onSubmit"] = useCallback(
        async (name, industry, profile) => {
            const updatedProfile = {
                ...entity.profile,
                ...profile,
            };

            await updateEntityAndGo(
                {
                    name,
                    industry,
                    profile: updatedProfile,
                },
                "business_description",
            );

            await trackEvent("onboarding_business_profile_created", {
                entity_name: name,
                entity_type: profile.entityType,
                ownership: profile.partnershipPercentage ?? 100,
                industry,
            });
        },
        [entity, updateEntityAndGo],
    );

    const saveDescription = useCallback(
        async (description: string) => {
            await editEntity.mutateAsync({
                profile: {
                    ...entity.profile,
                    description,
                },
            });

            await trackEvent("onboarding_business_description_added", {
                description,
            });
        },
        [editEntity, entity],
    );
    const handleBusinessDescription: BusinessDescriptionProps["onSubmit"] =
        useCallback(
            async ({ description }) => {
                await saveDescription(description);
                dispatch(setForcedStep("connect_accounts"));
            },
            [dispatch, saveDescription],
        );

    const sendReceiptIntro = useCallback(async () => {
        try {
            await sendReceiptsIntro();
        } finally {
            // if the request fails, we just go forward anyway
            dispatch(setForcedStep("receipts_finish"));
        }
    }, [dispatch]);

    const handleConnectAccountsFinished = useCallback(() => {
        dispatch(setForcedStep(user.phoneNumber ? "receipts_start" : "outro"));
        void trackEvent("onboarding_step_receipts");
    }, [dispatch, user.phoneNumber]);

    const handleReceiptsFinished = useCallback(() => {
        dispatch(setForcedStep(flatRateBilling ? "choose_plan" : "outro"));
        void trackEvent("onboarding_receipts_matched");
    }, [dispatch, flatRateBilling]);

    const handleOnboardingPaymentInitiated = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            void trackEvent("onboarding_payment_initiated", {
                plan,
            });
        },
        [],
    );

    const allowNoAccounts =
        localStorage.getItem("kickTest:allowNoAccounts") === "true";

    let onboardingView: React.ReactNode;

    switch (step) {
        case "personal_info":
            onboardingView = <PersonalInfo onSubmit={handlePersonalInfo} />;
            break;

        case "business_profile":
            onboardingView = (
                <BusinessProfile
                    entity={entity}
                    onSubmit={handleBusinessProfile}
                    onBack={goToStep("personal_info")}
                />
            );
            break;

        case "business_description":
            onboardingView = (
                <BusinessDescription
                    entity={entity}
                    saveDescription={saveDescription}
                    onSubmit={handleBusinessDescription}
                    onBack={goToStep("business_profile")}
                />
            );
            break;

        case "connect_accounts":
            onboardingView = (
                <ConnectAccounts
                    onBack={goToStep("business_description")}
                    onFinished={handleConnectAccountsFinished}
                    beforeConnect={beforeFirstConnection}
                    allowNoAccounts={allowNoAccounts}
                />
            );
            break;

        case "receipts_start":
            onboardingView = (
                <ReceiptsStart
                    onBack={goToStep("connect_accounts")}
                    onNext={sendReceiptIntro}
                />
            );
            break;

        case "receipts_finish":
            onboardingView = (
                <ReceiptsFinish
                    onBack={goToStep("receipts_start")}
                    onNext={handleReceiptsFinished}
                />
            );
            break;

        case "choose_plan":
            onboardingView = (
                <PlanManagementProvider
                    onBeforeUpgrade={handleOnboardingPaymentInitiated}
                >
                    <ChoosePlan onContinue={goToStep("outro")} />
                </PlanManagementProvider>
            );
            break;

        case "outro":
            onboardingView = <Outro onFinished={onFinish} />;
            break;
    }

    if (step === "intro") {
        showSidebar = false;
    }

    if (!plaidAccounts) {
        onboardingView = <Loader />;
    }

    let header;
    if (isMobile || !showSidebar) {
        header = (
            <Button
                variant="secondary"
                size={isMobile ? "sm" : "lg"}
                className="mr-2"
                onClick={doLogout}
            >
                Log out
            </Button>
        );
    } else {
        header = null;
    }

    return (
        <ExpandedSidebar
            className={classNames("onboarding", {
                "onboarding--no-sidebar": !showSidebar,
            })}
            header={header}
            sidebarSize={sidebarSize}
        >
            {!isMobile && showSidebar ? (
                <OnboardingNav
                    step={step}
                    steps={onboardingSteps}
                    logout={doLogout}
                />
            ) : null}
            <EntityProvider entity={entity}>
                {onboardingView}

                <Modal
                    show={showConnectAllAccountsReminder}
                    onHide={handleReminderConfirmed}
                >
                    <ConfirmationModal
                        closeButton
                        title="Connect all accounts"
                        question={
                            flatRateBilling ? (
                                <>
                                    To fully put your books on autopilot, we
                                    recommend you add all business and personal
                                    accounts to Kick.
                                </>
                            ) : (
                                <>
                                    <p>
                                        When prompted in Plaid, we recommend you
                                        connect all business and personal
                                        accounts available.
                                    </p>
                                    <p>
                                        Doing so will make Kick more
                                        self-driving, while maximizing the
                                        savings Kick can identify, while not
                                        costing you extra.
                                    </p>
                                </>
                            )
                        }
                        onConfirm={handleReminderConfirmed}
                        yes="Got it!"
                    />
                </Modal>
            </EntityProvider>
        </ExpandedSidebar>
    );
};
