import React from "react";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { ConfirmationModal } from "../../general/ConfirmationModal";

interface Props {
    connections: PlaidConnection[];
    onConnectNew: () => void;
    onUseExisting: (connection?: PlaidConnection) => void;
}

export const PossibleConnectionDuplicatesModal: React.FC<Props> = ({
    connections,
    onConnectNew,
    onUseExisting,
}) => {
    const bankName = connections[0].bankName;
    const oneConnection = connections.length === 1 ? connections[0] : undefined;

    return (
        <ConfirmationModal
            title={`You've already connected ${bankName}`}
            question={
                <>
                    Since you're starting another connection with {bankName}, we
                    need to make sure the new accounts <strong>DO NOT</strong>{" "}
                    belong to the same login that is already connected.
                    <br />
                    <br />
                    {oneConnection
                        ? "If they belong to the same login, we may need to ask you to authorize again."
                        : "If they belong to the same login, please go back and add them by clicking on ”Add accounts”, otherwise the connected accounts might be lost."}
                </>
            }
            onConfirm={onConnectNew}
            onReject={() => onUseExisting(oneConnection)}
            yes="This is a new login"
            no="This is the same login"
        />
    );
};
