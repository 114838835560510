import React, { useMemo } from "react";
import { Transaction } from "../../common/types/transaction";
import "./TransactionCategory.scss";
import { CategoryIcon } from "./CategoryIcon";
import { useCategoryLabelGetter } from "../../hooks/useCategoryLabelGetter";
import { SplitIcon } from "../../icons";
import { maxBy } from "lodash";
import { TransactionDirectionType } from "../../common/categories";

interface Props {
    transaction: Transaction;
}

export const TransactionCategory: React.FC<Props> = ({ transaction }) => {
    const getCategoryLabel = useCategoryLabelGetter();

    const primarySplit = useMemo(() => {
        if (!transaction.split || transaction.split.length === 0) {
            return null;
        }

        return maxBy(transaction.split, (split) => Math.abs(split.amount));
    }, [transaction.split]);

    const category = primarySplit?.category ?? transaction.category;
    const transactionDirection =
        transaction.amount > 0
            ? TransactionDirectionType.incoming
            : TransactionDirectionType.outgoing;

    return (
        <span
            className="transaction-category"
            data-testid="transaction-category"
        >
            <CategoryIcon
                category={category}
                transactionDirection={transactionDirection}
            />
            {primarySplit?.customCategory?.label ??
                transaction.customCategory?.label ??
                getCategoryLabel(category, transactionDirection)}
            {primarySplit && (
                <SplitIcon
                    width={12}
                    height={12}
                    className="icon-color-gray-700"
                />
            )}
        </span>
    );
};
