import React from "react";
import { GustoConnection } from "../../../common/types/gustoConnection";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useQuery } from "react-query";
import { getGustoConnectionForEntity } from "../../../lib/gusto";
import { useSyncGustoConnectionMutation } from "../../../mutations/gustoConnection";
import { Entity } from "../../../common/types/entity";
import { ButtonProps } from "react-bootstrap";

interface Props extends Omit<ButtonProps, "onClick"> {
    entity: Entity;
}
export const SyncGustoConnection: React.FC<Props> = ({
    entity,
    children,
    ...btnProps
}) => {
    const { data: connection } = useQuery(["entityConnection", entity.id], () =>
        getGustoConnectionForEntity(entity),
    );

    const syncPayrolls = useSyncGustoConnectionMutation(entity);

    if (!connection) return null;
    return (
        <ButtonWithLoader
            onClick={() => syncPayrolls.mutate(connection as GustoConnection)}
            loading={syncPayrolls.isLoading}
            {...btnProps}
        >
            {children}
        </ButtonWithLoader>
    );
};
