import React, { useMemo } from "react";
import { Pricing } from "../../../../../common/billing";
import { countBy, keys } from "lodash";
import { pluralize } from "../../../../../common/helpers/string";
import { currencyFormatterNoFractions } from "../../../../../common/helpers/currency";
import { SubQuote } from "./SubQuote";
import { SubQuoteItem } from "./SubQuoteItem";

interface Props {
    year: number;
    pricing: Pricing;
}
export const ItemizedSubQuote: React.FC<Props> = ({
    year,
    pricing,
    children,
}) => {
    const tiers = useMemo(() => {
        const monthsPerPrice = countBy(pricing.priceByMonth);

        return keys(monthsPerPrice).map((price) => ({
            price: Number(price),
            months: monthsPerPrice[price],
        }));
    }, [pricing.priceByMonth]);

    let items: React.ReactNode[];

    if (pricing.totalPrice === 0) {
        items = [
            <SubQuoteItem value={0} key={0}>
                Free
            </SubQuoteItem>,
        ];
    } else {
        items = tiers.map((tier) => (
            <SubQuoteItem value={tier.price} key={tier.price}>
                {tier.months} {pluralize("month", tier.months)} *{" "}
                {currencyFormatterNoFractions.format(tier.price)}/m{" "}
                {tier.price === 0 ? "(Free)" : ""}
            </SubQuoteItem>
        ));
    }

    return (
        <SubQuote
            items={items}
            comment={children}
            total={pricing.totalPrice}
            heading={`${year} Bookkeeping`}
        />
    );
};
