import React from "react";
import { TransactionAuditLog } from "../../../../common/types/transactionAuditLog";
import { timeAgo } from "../../../../helpers/date";
import { TransactionDetailsHistoryLogContent } from "./TransactionDetailsHistoryLogContent";

interface Props {
    log: TransactionAuditLog;
}

export const TransactionDetailsHistoryLog: React.FC<Props> = ({ log }) => {
    return (
        <div
            className="transaction-details-history__log"
            data-testid="transaction-details-history-log"
        >
            <main>
                <TransactionDetailsHistoryLogContent log={log} />
            </main>{" "}
            <aside>{timeAgo(log.date)}</aside>
        </div>
    );
};
