import { omit } from "lodash";
import React from "react";
import { Entity } from "../../../common/types/entity";
import { ReportFilters } from "../../../common/types/filters/reports";
import { ActiveFilters } from "../../general/ActiveFilters/ActiveFilters";
import { AggregatedFilters } from "../../general/AggregatedFilters/AggregatedFilters";
import { AggregatedFiltersProvider } from "../../general/AggregatedFilters/AggregatedFiltersProvider";
import { ReportsPageFilters } from "../filters/ReportsPageFilters";
import { CashFlowPageActiveFilterButton } from "./CashFlowPageActiveFilterButton";
import { CashFlowPageFiltersOverlay } from "./CashFlowPageFiltersOverlay";
import { CashFlowPageFilterTabs } from "./lib";
import { useCashFlowPageDocumentsFilters } from "./useCashFlowPageDocumentsFilters";
import { useResetCashFlowPageFilter } from "./useResetCashFlowPageFilter";

export interface CashFlowPageFiltersProps {
    onChange(filters: Partial<ReportFilters>): void;
    filters: ReportFilters;
    hardcodedEntity?: Entity;
}

export const CashFlowPageFilters: React.FC<CashFlowPageFiltersProps> = (
    props,
) => {
    const { onChange, filters, hardcodedEntity } = props;
    const filtersWithoutDate = omit(filters, "start", "end");
    const activeFiltersWithoutDate =
        useCashFlowPageDocumentsFilters(filtersWithoutDate);
    const clearActiveFilters = useResetCashFlowPageFilter(
        onChange,
        activeFiltersWithoutDate,
    );

    const hideDateFilter = true;

    return (
        <>
            <AggregatedFiltersProvider
                defaultTab={
                    hideDateFilter
                        ? CashFlowPageFilterTabs.CATEGORY
                        : CashFlowPageFilterTabs.DATE
                }
            >
                <AggregatedFilters>
                    <CashFlowPageFiltersOverlay
                        {...props}
                        filters={filtersWithoutDate}
                        hideDateFilter={hideDateFilter}
                    />
                </AggregatedFilters>
                <ActiveFilters
                    activeFilters={activeFiltersWithoutDate}
                    renderVisibleFilters={(visibleFilters) =>
                        visibleFilters.map((filterId) => (
                            <CashFlowPageActiveFilterButton
                                key={filterId}
                                {...props}
                                filters={filtersWithoutDate}
                                filterId={filterId}
                            />
                        ))
                    }
                    clearFilters={clearActiveFilters}
                />
            </AggregatedFiltersProvider>
            <ReportsPageFilters
                filters={filters}
                onChange={onChange}
                hardcodedEntity={hardcodedEntity}
            />
        </>
    );
};
