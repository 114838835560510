import React, { RefObject } from "react";
import { Tab } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars-2";
import { Entity } from "../../common/types/entity";
import { EntityProvider } from "../entity/EntityProvider";
import { AccountsSettings } from "./Accounts/AccountsSettings";
import { EntitySettings } from "./EntitySettings/EntitySettings";
import { Members } from "./Members/Members";
import { Payroll } from "./Payroll/Payroll";
import { EntitySettingsPages } from "./SettingsProvider";
import { getEntityPaneKey } from "./lib";

interface Props {
    entity: Entity;
    scrollbars: RefObject<Scrollbars>;
}

export const EntityPanes: React.FC<Props> = ({ entity, scrollbars }) => {
    return (
        <EntityProvider entity={entity}>
            <Tab.Pane
                eventKey={getEntityPaneKey(
                    EntitySettingsPages.ACCOUNTS,
                    entity,
                )}
                title="Accounts"
            >
                <AccountsSettings scrollbars={scrollbars} />
            </Tab.Pane>
            <Tab.Pane
                eventKey={getEntityPaneKey(EntitySettingsPages.MEMBERS, entity)}
                title="Members"
            >
                <Members />
            </Tab.Pane>
            <Tab.Pane
                eventKey={getEntityPaneKey(EntitySettingsPages.PAYROLL, entity)}
                title="Payrolls"
            >
                <Payroll />
            </Tab.Pane>
            <Tab.Pane
                eventKey={getEntityPaneKey(
                    EntitySettingsPages.SETTINGS,
                    entity,
                )}
                title="Settings"
            >
                <EntitySettings />
            </Tab.Pane>
        </EntityProvider>
    );
};
