import { ReportFilters } from "../../../common/types/filters/reports";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useMemo } from "react";
import { formatColHeaderLabel, validDateLabel } from "./cellLabels.helper";
import { Header } from "@tanstack/react-table";
import { PLDataRow } from "./ProfitLossReportTableContainer";
import { useIsWithinBounds } from "./useIsWithinBounds";
import { ThunderboltIcon } from "../../../icons";
import classNames from "classnames";
import { PLColumns } from "./useProfitLossReportTable";

export interface Props {
    header: Header<PLDataRow, any>;
    filters: ReportFilters;
}

export const ProfitLossReportHeaderCell: React.FC<Props> = ({
    header,
    filters,
}) => {
    const value = header.column.columnDef.header as string;
    const isWithinBounds = useIsWithinBounds(header);

    const shortLabel = useMemo(
        () => formatColHeaderLabel(filters, value),
        [value, filters],
    );
    const fullLabel = useMemo(
        () => formatColHeaderLabel(filters, value, true),
        [value, filters],
    );

    const prefix = useMemo(
        () =>
            isWithinBounds ? null : (
                <ThunderboltIcon className="mr-1 icon-color-blue-500" />
            ),
        [isWithinBounds],
    );

    const validLabel = useMemo(() => validDateLabel(value), [value]);

    return (
        <th
            className={classNames("data-table__header-cell", {
                "data-table__header-cell--title": header.id === PLColumns.TITLE,
            })}
        >
            {prefix}
            {shortLabel}
            {validLabel ? (
                <OverlayTrigger
                    overlay={
                        <Tooltip
                            id={`data-table__cover-cell__tooltip-${value}`}
                        >
                            {fullLabel}
                        </Tooltip>
                    }
                >
                    <span className="data-table__cover-cell">
                        {prefix}
                        {shortLabel}
                    </span>
                </OverlayTrigger>
            ) : (
                <span className="data-table__cover-cell">
                    {prefix}
                    {shortLabel}
                </span>
            )}
        </th>
    );
};
