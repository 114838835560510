import React from "react";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
    TRIAL_LENGTH_DAYS,
} from "../../../common/flatRateBilling";
import { usePlanManagement } from "../../billing/PlanManagement/PlanManagementContext";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { ThunderboltIcon } from "../../../icons";
import { Button } from "react-bootstrap";
import styles from "./UpgradePrompt.module.scss";

interface UpgradePromptProps {
    targetPlan: FLAT_RATE_PLANS;
}

export const UpgradePrompt: React.FC<UpgradePromptProps> = ({ targetPlan }) => {
    const { upgrade } = usePlanManagement();
    const { trialAvailable, isTrialing } = useBillingStatus();

    return (
        <span className={styles.prompt}>
            Available on {flatRatePlanNames[targetPlan]}. Try free for{" "}
            {TRIAL_LENGTH_DAYS} days.
            <Button
                variant="cta"
                size="sm"
                onClick={() =>
                    upgrade(targetPlan, trialAvailable || isTrialing)
                }
            >
                <ThunderboltIcon className="mr-1" /> Start trial
            </Button>
        </span>
    );
};
