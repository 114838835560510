import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Category } from "../../common/types/category";
import { Transaction } from "../../common/types/transaction";
import { noop } from "../../helpers/general";
import { useEntities } from "../../hooks/useEntities";
import { getPotentialSavings } from "../../lib/entity";
import { TransactionsFilters } from "./filters/lib";

export interface PotentialSavingsContextValue {
    savings: number;
    onTransactionUpdated(
        oldTransaction: Transaction,
        updatedTransaction: Transaction,
    ): void;
    fetchSavings(): Promise<void>;
}

export const PotentialSavingsContext =
    createContext<PotentialSavingsContextValue>({
        savings: 0,
        onTransactionUpdated: noop,
        fetchSavings: () => Promise.resolve(),
    });

export function usePotentialSavingsContext() {
    const ctx = useContext(PotentialSavingsContext);

    if (!ctx) {
        throw new Error("Should only be used inside SavingsProvider");
    }

    return useContext(PotentialSavingsContext);
}

export interface SavingsProviderProps {
    filters: TransactionsFilters;
    children:
        | React.ReactNode
        | ((value: PotentialSavingsContextValue) => React.ReactNode);
}

export const PotentialSavingsProvider: React.FC<SavingsProviderProps> = ({
    filters,
    children,
}) => {
    const [savings, setSavings] = useState<number>(0);
    const entities = useEntities();

    const onTransactionUpdated: PotentialSavingsContextValue["onTransactionUpdated"] =
        (oldTransaction, newTransaction) => {
            const potentialSavingsDiff =
                (newTransaction.potentialAmountSaved ?? 0) -
                (oldTransaction.potentialAmountSaved ?? 0);

            setSavings((prev) => prev + potentialSavingsDiff);
        };

    const fetchSavings = useCallback(async () => {
        await getPotentialSavings({
            ...filters,
            category: filters.category
                ?.filter(
                    (genericCategory): genericCategory is Category =>
                        genericCategory.type === "category",
                )
                .map((category) => category.id),
            entitiesAccounts:
                filters.entitiesAccounts && filters.entitiesAccounts.length > 0
                    ? filters.entitiesAccounts
                    : entities.map((entity) => ({
                          entityId: entity.id,
                      })),
        }).then(setSavings);
    }, [filters, entities]);

    useEffect(() => {
        fetchSavings();
    }, [fetchSavings]);

    const value = useMemo(
        () => ({
            savings,
            onTransactionUpdated,
            fetchSavings,
        }),
        [fetchSavings, savings],
    );

    return (
        <PotentialSavingsContext.Provider value={value}>
            {children}
        </PotentialSavingsContext.Provider>
    );
};
