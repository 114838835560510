import {
    ConnectionProviderType,
    FinancialConnection,
    FinancialConnectionWithAccounts,
} from "../../types/financialConnection";
import { UserDto } from "../user/user.dto";
import { IntegrationAccountDto } from "./integration-account.dto";

export class FinancialConnectionDto {
    static deserialize(
        dto: FinancialConnectionDto,
    ): FinancialConnectionWithAccounts {
        return {
            ...dto,
            createdAt: new Date(dto.createdAt),
            user: UserDto.deserialize(dto.user),
            accounts: dto.accounts
                ? dto.accounts.map(IntegrationAccountDto.deserialize)
                : [],
        };
    }

    id: number;
    connectionExternalId: string;
    connectionProvider: ConnectionProviderType;
    createdAt: string;
    user: UserDto;
    userId: number;
    accounts: IntegrationAccountDto[];
    error: string | null;

    constructor(connection: FinancialConnection) {
        this.id = connection.id;
        this.connectionExternalId = connection.connectionExternalId;
        this.createdAt = connection.createdAt.toISOString();
        this.user = new UserDto(connection.user);
        this.userId = connection.userId;
        this.connectionProvider = connection.connectionProvider;
        this.accounts = connection.accounts
            ? connection.accounts.map((fa) => new IntegrationAccountDto(fa))
            : [];
        this.error = connection.error;
    }
}
