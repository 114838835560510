export function toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
            resolve((reader.result as string).split("base64,").pop()!);
        reader.onerror = (error) => reject(error);
    });
}

export function download(blob: Blob, fileName: string) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
}
