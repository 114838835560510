import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { UpdateProfileDto } from "../../../../common/dto/user/update-profile.dto";
import { updateProfile } from "../../../../lib/user";
import { addNotification, updateUser } from "../../../../reducers/appState";
import { EditableProfileFields } from "./types";
import { UpdateProfileFlow } from "./UpdateProfileFlow";
import { StandardModal } from "../../../general/Modal/Modal";

interface Props {
    update: EditableProfileFields | null;
    onClose(): void;
}

export const UpdateProfileModal: React.FC<Props> = ({ update, onClose }) => {
    const dispatch = useDispatch();

    const handleSuccess = useCallback(
        async (payload: UpdateProfileDto) => {
            try {
                dispatch(updateUser(await updateProfile(payload)));

                dispatch(
                    addNotification({
                        message: "Profile updated",
                        type: "success",
                    }),
                );

                onClose();
            } catch (e) {
                dispatch(
                    addNotification({
                        message: `Error when updating profile`,
                        type: "danger",
                    }),
                );
            }
        },
        [dispatch, onClose],
    );

    if (!update) {
        return null;
    }

    return (
        <StandardModal show onHide={onClose}>
            <UpdateProfileFlow
                update={update}
                onSuccess={handleSuccess}
                onCancel={onClose}
            />
        </StandardModal>
    );
};
