import {
    ActiveFinancialDocumentFilters,
    FinancialDocumentFilters,
    financialDocumentFiltersOrder,
} from "./lib";
import { useMemo } from "react";

type PredicateFn = (filters: FinancialDocumentFilters) => boolean;

const isActive: Record<ActiveFinancialDocumentFilters, PredicateFn> = {
    date: ({ start, end }) => start !== undefined || end !== undefined,
    status: ({ status }) => status !== undefined,
} as const;

export function useActiveFinancialDocumentsFilters(
    filters: FinancialDocumentFilters,
): ActiveFinancialDocumentFilters[] {
    return useMemo(() => {
        return financialDocumentFiltersOrder.filter((filterId) =>
            isActive[filterId](filters),
        );
    }, [filters]);
}
