import React, { Fragment } from "react";
import {
    PLColumns,
    useProfitLossReportTable,
} from "../../../reports/ProfitLossReportTable/useProfitLossReportTable";
import { useTableData } from "../../../reports/ProfitLossReportTable/useTableData";
import { useProfitLossReportTableColumns } from "../../../reports/ProfitLossReportTable/useProfitLossReportTableColumns";
import { useExpandRows } from "../../../reports/ProfitLossReportTable/useExpandRows";

import { LedgerBasedProfitLossDownloadCell } from "./LedgerBasedProfitLossDownloadCell";
import { format } from "date-fns";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import {
    realReportDataEnd,
    realReportDataStart,
} from "../../../../common/helpers/reports";
import { AccountingReportFilters } from "../../types";
import { LedgerBasedProfitLossResponseDto } from "../../../../common/dto/reports/ledger-based-profit-loss-response.dto";
import { AccountingReportFooter } from "../../AccountingReportFooter";
import { LedgerBasedProfitLossDownloadColumnHeader } from "./LedgerBasedProfitLossDownloadColumnHeader";
import { Entity } from "../../../../common/types/entity";

interface Props {
    report: LedgerBasedProfitLossResponseDto;
    filters: AccountingReportFilters;
    generationDate: Date;
    entity: Entity;
}

export const PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID =
    "profit-loss-download-table";

function shouldExpandRow(canExpand: boolean) {
    return canExpand;
}

const TABLE_PROPS: React.HTMLAttributes<HTMLTableElement> = {
    id: PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID,
};

const grouping = [PLColumns.SECTION];

export const LedgerBasedProfitLossDownloadTable: React.FC<Props> = ({
    report,
    filters,
    generationDate,
    entity,
}) => {
    const tableData = useTableData(report);
    const columns = useProfitLossReportTableColumns(report, grouping, true);
    const table = useProfitLossReportTable(tableData, columns, grouping);
    useExpandRows(table, report, shouldExpandRow);
    const startDate = format(
        realReportDataStart(report.meta.dataStart, filters.startDate),
        fullDateFullYearFormat,
    );
    const endDate = format(
        realReportDataEnd(report.meta.dataEnd, filters.endDate),
        fullDateFullYearFormat,
    );
    const entityName = entity.name;
    const columnsNumber = report.columnLabels.length + 3;

    const emptyRow = (
        <tr>
            <td colSpan={columnsNumber}>&nbsp;</td>
        </tr>
    );

    return (
        <table className="download-table" {...TABLE_PROPS}>
            <tbody>
                {emptyRow}
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className={
                            "download-table__cell--bold download-table__cell--center"
                        }
                        style={{ fontSize: "22px" }}
                    >
                        {entityName}
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className={
                            "download-table__cell--bold download-table__cell--center"
                        }
                        style={{ fontSize: "22px" }}
                    >
                        Profit & Loss
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className={"download-table__cell--center"}
                    >
                        {startDate} - {endDate}
                    </td>
                </tr>
                {emptyRow}
                <tr>
                    <td />
                    <td />
                    {report.columnLabels.map((label) => (
                        <LedgerBasedProfitLossDownloadColumnHeader
                            label={label}
                            key={label}
                        />
                    ))}
                    <td
                        className={
                            "download-table__cell--bold download-table__cell--border-bottom download-table__cell--center"
                        }
                    >
                        Total
                    </td>
                </tr>
                {table.getRowModel().rows.map((row) => (
                    <Fragment key={row.id}>
                        <tr>
                            <td className="download-table__spacing_col" />
                            {row.getVisibleCells().map((cell) => (
                                <LedgerBasedProfitLossDownloadCell
                                    key={cell.id}
                                    cell={cell}
                                />
                            ))}
                        </tr>
                    </Fragment>
                ))}
                {emptyRow}
                {emptyRow}
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className={
                            "download-table__cell--center download-table__font-9"
                        }
                    >
                        <AccountingReportFooter
                            generationDate={generationDate}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
