import { format, isSameYear } from "date-fns";
import { fullDateFormat, shortDateFormatter } from "../../common/helpers/date";
import { FinancialDocument } from "../../common/types/financialDocument";
import { FetchFinancialDocumentsDto } from "../../common/dto/financialDocument/fetch-financial-documents.dto";
import { TransactionFiltersDto } from "../../common/dto/transactions/getTransactions/transaction-filters.dto";
import { FinancialTransactionForTransactionMatch } from "../../common/types/transactionMatch";
import { TransactionsFilters } from "../transactions/filters/lib";

export const financialDocumentQueryKeys = {
    all: () => ["financialDocument"] as const,
    lists: () => [...financialDocumentQueryKeys.all(), "lists"] as const,
    list: (queryParams: FetchFinancialDocumentsDto) =>
        [...financialDocumentQueryKeys.lists(), queryParams] as const,
    details: () => [...financialDocumentQueryKeys.all(), "details"] as const,
    detail: (id: number) =>
        [...financialDocumentQueryKeys.details(), id] as const,
} as const;

export const financialDocumentFileQueryKeys = {
    all: () => ["financialDocumentFile"] as const,
    file: (id: number) =>
        [...financialDocumentFileQueryKeys.all(), id] as const,
} as const;

export const transactionMatchingQueryKeys = {
    all: () => ["transactionMatching"] as const,
    list: (filtersDto: TransactionFiltersDto) =>
        [...transactionMatchingQueryKeys.all(), filtersDto] as const,
    page: (filtersDto: TransactionFiltersDto, page: number) => [
        ...transactionMatchingQueryKeys.list(filtersDto),
        page,
    ],
} as const;

export const transferMatchingQueryKeys = {
    all: () => ["transferMatching"] as const,
    list: (filtersDto: TransactionsFilters) =>
        [...transferMatchingQueryKeys.all(), filtersDto] as const,
    page: (filtersDto: TransactionsFilters, page: number) => [
        ...transferMatchingQueryKeys.list(filtersDto),
        page,
    ],
} as const;

export function formatDate(date: Date): string {
    return isSameYear(date, new Date())
        ? shortDateFormatter.format(date)
        : format(date, fullDateFormat);
}

export interface UploadingDocumentPlaceholder {
    fileName: string;
    isUploading: boolean;
    financialDocument?: FinancialDocument;
}

export function getMatchedTransaction(
    financialDocument: FinancialDocument,
): FinancialTransactionForTransactionMatch | null {
    return (
        financialDocument.transactionMatches[0]?.financialTransaction ?? null
    );
}

export const RECEIPT_TYPES = [
    "image/png",
    "image/jpeg",
    "application/pdf",
] as const;
export const SPREADSHEET_TYPES = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
] as const;
export const WORD_TYPES = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
] as const;
