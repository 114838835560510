import { useQuery } from "react-query";

export function useFetchAsset(url: string) {
    return useQuery(
        ["fetchAsset", url],
        async () =>
            fetch(url)
                .then((res) => res.blob())
                .then((res) => URL.createObjectURL(res)),
        { refetchOnMount: false, refetchOnWindowFocus: false },
    );
}
