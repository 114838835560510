import React, { useCallback, useState } from "react";
import { Entity } from "../../../common/types/entity";
import { Field, Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { OnboardingStepActions } from "../OnboardingStepActions";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { OnboardingStep } from "../OnboardingStep";
import { AIIcon } from "../../../icons";
import { FormRow } from "../../forms/FormRow";
import styles from "./BusinessDescription.module.scss";
import classNames from "classnames";

interface FormSchema {
    description: string;
}

export interface BusinessDescriptionProps {
    entity?: Entity;
    saveDescription?(description: string): Promise<void>;
    onSubmit(data: FormSchema): Promise<void>;
    onBack(): void;
}

export const BusinessDescription: React.FC<BusinessDescriptionProps> = ({
    entity,
    saveDescription,
    onSubmit,
    onBack,
}) => {
    const [loading, setLoading] = useState(false);

    const handleBack = useCallback(
        (description: string) => {
            saveDescription?.(description);
            onBack();
        },
        [onBack, saveDescription],
    );

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            description: entity?.profile?.description ?? "",
        },
        validationSchema: object().shape({
            description: string().required("Please provide a description"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                await onSubmit(values);
            },
        }),
    };

    return (
        <OnboardingStep
            title="Tell us about your business"
            description={
                <>
                    Share 2-3 sentences about your business with{" "}
                    <span className="font-weight-medium d-inline-flex align-items-center">
                        Kick AI <AIIcon className="ml-1 icon-color-blue-600" />
                    </span>
                </>
            }
            narrow
        >
            <Formik {...form}>
                {({ isValid, values }) => (
                    <Form>
                        <FormRow fieldName="description">
                            <Field
                                name="description"
                                as="textarea"
                                placeholder="I host a podcast called Internet Money that’s gives interviews and case studies on creating successful online businesses. My revenue streams include consulting, brand deals, ads and YouTube."
                                className={classNames(
                                    "form-control",
                                    styles.textarea,
                                )}
                            />
                        </FormRow>

                        <OnboardingStepActions
                            className="mt-4"
                            btnSize="xl"
                            onBack={() => handleBack(values.description)}
                        >
                            <ButtonWithLoader
                                type="submit"
                                variant="primary"
                                size={"xl" as any}
                                loading={loading}
                                disabled={!isValid}
                            >
                                Next
                            </ButtonWithLoader>
                        </OnboardingStepActions>
                    </Form>
                )}
            </Formik>
        </OnboardingStep>
    );
};
