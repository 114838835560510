import { FinancialDocument } from "../../types/financialDocument";
import { EntityDto } from "../entity/entity.dto";
import { valueOrNull } from "../dto-helpers";
import { TransactionMatchDto } from "../transactionMatch/transaction-match.dto";
import { EagerLoaded } from "../../types/base/orm";
import { TransactionMatch } from "../../types/transactionMatch";
import { FinancialDocumentBaseDto } from "./financial-document-base.dto";

export class FinancialDocumentDto extends FinancialDocumentBaseDto {
    static deserialize(dto: FinancialDocumentDto): FinancialDocument {
        return {
            ...FinancialDocumentBaseDto.deserialize(dto),
            entity: valueOrNull(dto.entity, EntityDto.deserialize),
            transactionMatches: dto.transactionMatches.map(
                TransactionMatchDto.deserialize,
            ) as Array<EagerLoaded<TransactionMatch, "financialTransaction">>,
        };
    }

    entity: EntityDto | null;
    transactionMatches: TransactionMatchDto[];

    constructor(financialDocument: FinancialDocument) {
        super(financialDocument);
        this.entity = financialDocument.entity
            ? new EntityDto(financialDocument.entity)
            : null;

        this.transactionMatches = financialDocument.transactionMatches.map(
            (tm) => new TransactionMatchDto(tm),
        );
    }
}
