import React, { memo, useMemo } from "react";
import { AccountTransactionsLine } from "../../../common/types/domains/accounting/accountTransactions";
import { dateFormatter } from "../../../common/helpers/date";
import { accountingNumberFormatter } from "../../../common/helpers/number";
import {
    getShortJournalEntryReferenceNumber,
    JournalEntrySourceTypeLabels,
} from "../../../common/helpers/accounting";
import { JournalEntrySourceType } from "../../../common/types/domains/accounting/journalEntry";
import classNames from "classnames";

interface Props {
    transaction: AccountTransactionsLine;
    isAccountIncreasingByDebit: boolean;
    onSourceClick: (transaction: AccountTransactionsLine) => void;
    onReferenceClick: (transaction: AccountTransactionsLine) => void;
}

export const GeneralLedgerTableTransaction: React.FC<Props> = memo(
    ({
        transaction,
        isAccountIncreasingByDebit,
        onSourceClick,
        onReferenceClick,
    }) => {
        const displayDate = useMemo(
            () => dateFormatter.format(new Date(transaction.date)),
            [transaction.date],
        );

        let amount = transaction.creditAmount || transaction.debitAmount;
        if (
            (isAccountIncreasingByDebit && transaction.creditAmount) ||
            (!isAccountIncreasingByDebit && transaction.debitAmount)
        ) {
            amount = -amount;
        }

        const reference = getShortJournalEntryReferenceNumber(
            transaction.referenceNumber,
        );

        return (
            <tr className="data-table__row--depth-1">
                <td className="data-table__cell data-table__cell--string-col">
                    {displayDate}
                </td>
                <td
                    className={classNames(
                        "data-table__cell data-table__cell--string-col",
                        {
                            "data-table__cell--clickable":
                                transaction.source.type ===
                                JournalEntrySourceType.Transaction,
                        },
                    )}
                    onClick={() => onSourceClick(transaction)}
                >
                    {JournalEntrySourceTypeLabels[transaction.source.type]}
                </td>
                <td
                    className="data-table__cell data-table__cell--string-col data-table__cell--clickable"
                    onClick={() => onReferenceClick(transaction)}
                >
                    {reference}
                </td>
                <td className="data-table__cell data-table__cell--string-col">
                    {transaction.description}
                </td>
                <td className="data-table__cell data-table__cell--string-col">
                    {transaction.memo}
                </td>
                <td className="data-table__cell data-table__cell--string-col">
                    {transaction.splitLabel}
                </td>
                <td className="data-table__cell">
                    {accountingNumberFormatter(amount)}
                </td>
                <td className="data-table__cell">
                    {accountingNumberFormatter(transaction.balance)}
                </td>
            </tr>
        );
    },
);
