import React from "react";
import { Transaction } from "../../common/types/transaction";
import { Amount } from "../general/Amount/Amount";
import classNames from "classnames";
import "./TransactionAmount.scss";

interface Props {
    transaction: Transaction;
    addSign?: boolean;
    color?: boolean;
}

export const TransactionAmount: React.FC<Props> = ({
    transaction,
    addSign = false,
    color = false,
}) => {
    return (
        <div
            className={classNames(
                "transaction-amount",
                `transaction-amount--${transaction.status}`,
                {
                    "transaction-amount--color": color,
                    "transaction-amount--credit": transaction.amount > 0,
                    "transaction-amount--debit": transaction.amount < 0,
                },
            )}
            data-testid="transaction-amount"
        >
            <Amount
                amount={transaction.amount}
                separateCurrencySign
                showNegative={addSign}
            />
        </div>
    );
};
