import { Field, FieldProps, Form, Formik } from "formik";
import { noop } from "lodash";
import { forwardRef, KeyboardEventHandler, useCallback, useRef } from "react";
import { CustomCategory } from "../../../common/types/customCategory";
import { getInputClasses, InputSize } from "../../../helpers/form";
import { useCallbackWithFocus } from "../../../hooks/useCallbackWithFocus";
import { FormRow } from "../../forms/FormRow";

export interface CategoryLabelEditFormProps {
    customCategory?: CustomCategory;
    onSubmit: (values: { label: string }) => void;
    onStopEditing: () => void;
    size?: InputSize;
}

export const CategoryLabelEditForm = forwardRef<
    HTMLInputElement,
    CategoryLabelEditFormProps
>(({ customCategory, onSubmit, onStopEditing, size = "sm" }, ref) => {
    const inputRef = useRef<HTMLInputElement | undefined>(undefined);
    useCallbackWithFocus(noop, inputRef);

    const onKeyDown = useCallback<KeyboardEventHandler<HTMLFormElement>>(
        (e) => {
            if (e.key === "Escape") {
                e.stopPropagation();
                onStopEditing();
            }
        },
        [onStopEditing],
    );

    return (
        <Formik
            initialValues={{
                label: customCategory?.label ?? "",
            }}
            onSubmit={onSubmit}
        >
            <Form onKeyDown={onKeyDown}>
                <FormRow fieldName="memo" showErrorMessage={false}>
                    <Field name="label">
                        {({ field }: FieldProps) => (
                            <input
                                {...field}
                                className={getInputClasses(size)}
                                placeholder="New subcategory"
                                ref={ref}
                                style={{ marginBottom: 0 }}
                                data-testid="new-subcategory-input"
                            />
                        )}
                    </Field>
                </FormRow>
            </Form>
        </Formik>
    );
});
