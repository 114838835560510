import React from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { DragHandleIcon } from "../../icons";
import { useSortable } from "@dnd-kit/sortable";

interface Props {
    sortableId: UniqueIdentifier;
}

export const EntityAccountDragHandle: React.FC<Props> = ({ sortableId }) => {
    const { attributes, listeners } = useSortable({ id: sortableId });

    return (
        <span {...attributes} {...listeners}>
            <DragHandleIcon />
        </span>
    );
};
