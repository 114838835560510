import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoIcon } from "../../../icons";

export const BusinessProfileHint: React.FC = ({ children }) => {
    return (
        <OverlayTrigger
            overlay={<Tooltip id="partnership-hint">{children}</Tooltip>}
        >
            <InfoIcon className="ml-1 icon-color-gray-700" />
        </OverlayTrigger>
    );
};
