import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { emulateTab } from "emulate-tab";

import { SelectDropdown } from "../../general/SelectDropdown/SelectDropdown";
import { SelectDropdownOption } from "../../general/SelectDropdown/SelectDropdownOption";
import { SelectDropdownDivider } from "../../general/SelectDropdown/SelectDropdownDivider";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import {
    EditableTableCell,
    EditableTableCellProps,
    calculateCellBorders,
} from "./EditableTableCell";
import styles from "./EditableTable.module.scss";

interface EditableCellSearchSelectableOption {
    value: string | number | null;
    label: string;
    isGroupLabel?: false;
}
interface EditableCellSearchOptionGroupLabel {
    isGroupLabel: true;
    label: string;
}
export type EditableCellSearchOption =
    | EditableCellSearchSelectableOption
    | EditableCellSearchOptionGroupLabel;

interface EditableTableCellSearchProps
    extends Omit<EditableTableCellProps, "children"> {
    options: EditableCellSearchOption[];
    onChange: undefined | ((value: string | number | null) => void);
    value: string;
    isDisabled: boolean;
}

const popperConfig = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, 0],
            },
        },
    ],
};

export const EditableTableCellSearch = React.memo(
    ({
        id,
        isFirstRow,
        options,
        onChange,
        value,
        error,
        selectionState,
        onClick,
        isDisabled,
    }: EditableTableCellSearchProps) => {
        const [isDropdownOpened, setIsDropdownOpened] = useState(false);
        const [search, setSearch] = useState(() => {
            const optionToFind = options.find(
                (option) =>
                    !option.isGroupLabel && String(option.value) === value,
            );
            return optionToFind?.label ?? value;
        });
        const [localValue, setLocalValue] = useState(value);
        const searchRef = useRef<HTMLInputElement | null>(null);
        const popoverRef = useRef<HTMLDivElement | null>(null);

        const { width, height } = useWindowDimensions();

        const popoverStyle = useMemo(() => {
            return {
                maxWidth: searchRef.current?.getBoundingClientRect().width,
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchRef.current, width, height]);

        useEffect(() => {
            if (value !== localValue) {
                const optionToFind = options.find(
                    (option) =>
                        !option.isGroupLabel && String(option.value) === value,
                );
                if (optionToFind) {
                    setSearch(optionToFind.label);
                } else {
                    setSearch(value);
                }
                setLocalValue(value);
            }
        }, [value, localValue, setLocalValue, setSearch, options, id]);

        const cellBorders = calculateCellBorders(selectionState);

        return (
            <OverlayTrigger
                show={isDropdownOpened}
                placement="bottom-start"
                flip={true}
                popperConfig={popperConfig}
                overlay={
                    <Popover
                        id={`${id}-popover`}
                        className={classNames(
                            "popover-container",
                            styles.popoverBody,
                        )}
                        style={popoverStyle}
                    >
                        <Popover.Content ref={popoverRef}>
                            <SelectDropdown
                                className={classNames(
                                    styles.accountDropdown,
                                    "dropdown-menu",
                                )}
                            >
                                {options
                                    .filter(
                                        (option) =>
                                            search === "" ||
                                            option.label
                                                .toLowerCase()
                                                .includes(search.toLowerCase()),
                                    )
                                    .map((option, index) => (
                                        <>
                                            {option.isGroupLabel &&
                                                index !== 0 && (
                                                    <SelectDropdownDivider
                                                        key={`${option.label}-divider-${index}`}
                                                    />
                                                )}
                                            <SelectDropdownOption
                                                className={classNames(
                                                    styles.dropdownOption,
                                                    option.isGroupLabel &&
                                                        styles.dropdownOptionGroupLabel,
                                                )}
                                                key={
                                                    option.isGroupLabel
                                                        ? option.label
                                                        : option.value
                                                }
                                                onClick={() => {
                                                    if (option.isGroupLabel) {
                                                        return;
                                                    }
                                                    setSearch(
                                                        String(option.label),
                                                    );
                                                    onChange?.(
                                                        String(option.value),
                                                    );
                                                    setLocalValue(
                                                        String(option.value),
                                                    );
                                                    setIsDropdownOpened(false);
                                                    emulateTab();
                                                }}
                                            >
                                                {option.label}
                                            </SelectDropdownOption>
                                        </>
                                    ))}
                            </SelectDropdown>
                        </Popover.Content>
                    </Popover>
                }
            >
                {({ ref }) => (
                    <EditableTableCell
                        id={id}
                        isFirstRow={isFirstRow}
                        ref={ref}
                        error={error}
                        selectionState={selectionState}
                    >
                        <input
                            value={search}
                            style={cellBorders}
                            className={classNames(
                                "form-control form-control-sm",
                                styles.editableTableInput,
                            )}
                            ref={searchRef as any}
                            onFocus={() => {
                                if (isDisabled) {
                                    return;
                                }
                                setIsDropdownOpened(true);
                                onClick?.();
                            }}
                            onBlur={(e) => {
                                if (
                                    !popoverRef.current?.contains(
                                        e.relatedTarget,
                                    )
                                ) {
                                    setIsDropdownOpened(false);
                                } else {
                                    searchRef.current?.focus();
                                }
                            }}
                            onChange={(e) => {
                                if (isDisabled) {
                                    return;
                                }
                                setSearch(e.target.value);
                                onChange?.(e.target.value);
                            }}
                        />
                    </EditableTableCell>
                )}
            </OverlayTrigger>
        );
    },
);
