import { useCallback, useMemo } from "react";

import { JournalEntrySourceType } from "../../../common/types/domains/accounting/journalEntry";
import {
    JournalEntryLineProxyObject,
    JournalEntryModalFormikConfig,
} from "./JournalEntryModal";
import { AccountTypeWithAccounts } from "../../../hooks/useAccounts";
import { EditableCellSearchOption } from "../editableTable/EditableTableCellSearch";
import { calculateDebitAndCreditTotals } from "./JournalEntryModalUtils";

export function useIsJournalEntryEditable(
    values: JournalEntryModalFormikConfig,
) {
    return useMemo(() => {
        return (
            values.journalEntryId === undefined ||
            values.journalEntry.source?.type ===
                JournalEntrySourceType.ManualJournalEntry
        );
    }, [values.journalEntry.source, values.journalEntryId]);
}

export function useAccountsSearchOptions(
    accountTypesWithAccounts: AccountTypeWithAccounts[],
) {
    return useMemo(() => {
        return accountTypesWithAccounts.reduce((acc, accountType) => {
            const accounts = accountType.accounts;
            const accountsOptions = accounts.map((account) => {
                return {
                    value: account.code,
                    label: `${account.code} - ${account.name}`,
                };
            });
            acc.push(
                {
                    label: accountType.type,
                    isGroupLabel: true,
                },
                ...accountsOptions,
            );
            return acc;
        }, [] as EditableCellSearchOption[]);
    }, [accountTypesWithAccounts]);
}

export interface UpdateAndReturnNewLineParams<T> {
    line: T;
    field: keyof T;
    value: string | number | null;
    fillEmptyFieldsAutomatically?: boolean;
}

interface UseUpdateAndReturnNewLineCallbackParams {
    isEditable: boolean;
    accountsSearchOptions: EditableCellSearchOption[];
    memo: string;
    linesRef: React.MutableRefObject<JournalEntryLineProxyObject[]>;
}

interface FillEmptyFieldsParams {
    newLine: JournalEntryLineProxyObject;
    accountsSearchOptions: EditableCellSearchOption[];
    memo: string;
    linesRef: React.MutableRefObject<JournalEntryLineProxyObject[]>;
    field: keyof JournalEntryLineProxyObject;
    value: string | number | null;
}

function fillEmptyFields({
    newLine,
    accountsSearchOptions,
    memo,
    linesRef,
    field,
    value,
}: FillEmptyFieldsParams) {
    if (
        field === "accountCode" &&
        typeof newLine.creditAmount !== "number" &&
        typeof newLine.debitAmount !== "number" &&
        accountsSearchOptions.find(
            (option) => !option.isGroupLabel && option.value === Number(value),
        )
    ) {
        const linesSum = calculateDebitAndCreditTotals(linesRef.current);
        const difference = linesSum.debitAmount - linesSum.creditAmount;
        if (difference > 0) {
            newLine["creditAmount"] = difference;
        } else if (difference !== 0) {
            newLine["debitAmount"] = Math.abs(difference);
        }
        const uniqueDescriptionsInOtherLines = new Set(
            linesRef.current
                .map((currentLine) => currentLine.description)
                .filter((description) => Boolean(description)),
        );
        if (uniqueDescriptionsInOtherLines.size === 0) {
            if (memo.length > 0) {
                newLine.description = memo;
            }
        }
        if (uniqueDescriptionsInOtherLines.size === 1) {
            newLine.description = uniqueDescriptionsInOtherLines
                .values()
                .next().value;
        }
    }
}

export function useUpdateAndReturnNewLineCallback({
    isEditable,
    accountsSearchOptions,
    memo,
    linesRef,
}: UseUpdateAndReturnNewLineCallbackParams) {
    return useCallback(
        ({
            line,
            field,
            value,
            fillEmptyFieldsAutomatically,
        }: UpdateAndReturnNewLineParams<JournalEntryLineProxyObject>) => {
            if (!isEditable) {
                return line;
            }
            const newLine: JournalEntryLineProxyObject = {
                ...line,
            };
            (newLine as any)[field] = value;
            if (value !== null) {
                if (field === "debitAmount") {
                    newLine["creditAmount"] = null;
                } else if (field === "creditAmount") {
                    newLine["debitAmount"] = null;
                }
            }
            if (fillEmptyFieldsAutomatically) {
                fillEmptyFields({
                    newLine,
                    accountsSearchOptions,
                    memo,
                    linesRef,
                    field,
                    value,
                });
            }
            return newLine;
        },
        [isEditable, accountsSearchOptions, memo, linesRef],
    );
}
