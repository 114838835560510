import React from "react";
import { Button } from "react-bootstrap";
import { RequestCodeFn } from "./types";
import { UseResend, useResend } from "./useResend";

type Props = {
    request: RequestCodeFn;
    children?: (params: UseResend) => React.ReactElement | null;
    btnSize?: "sm" | "lg";
};

export const ResendCode: React.FC<Props> = ({
    request,
    children,
    btnSize = "sm",
}) => {
    const resend = useResend(request);

    if (children) {
        return children(resend);
    }

    return (
        <Button
            variant="link"
            size={btnSize}
            onClick={resend.handleResend}
            disabled={resend.resendBlocked}
            className="p-0"
        >
            {!resend.resendBlocked ? <>Resend Code</> : <>Code re-sent</>}
        </Button>
    );
};
