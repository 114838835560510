import { EntityContext, EntityContextDto } from "./entity-context.dto";

export interface UserEntitiesResponse {
    entities: EntityContext[];
}

export class UserEntitiesResponseDto {
    static deserialize(status: UserEntitiesResponseDto): UserEntitiesResponse {
        return {
            entities: status.entities.map(EntityContextDto.deserialize),
        };
    }
    entities: EntityContextDto[];
    constructor(status: UserEntitiesResponse) {
        this.entities = status.entities.map(
            (entityContext: EntityContext) =>
                new EntityContextDto(entityContext),
        );
    }
}
