import React, { useCallback, useState } from "react";
import { ClientAddress, ClientAddressProps } from "./ClientAddress";
import { Button, Modal } from "react-bootstrap";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { useEntity } from "../../../entity/useEntity";
import { Address } from "../../../../common/types/embeddable/address";
import { useEntityAddressMutation } from "../../../../mutations/entity";
import { USAddress } from "../../../../common/types/embeddable/usAddress";

interface Props {
    address?: Address;
}

export const AddressSettings: React.FC<Props> = ({ address }) => {
    const entity = useEntity();
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const mutation = useEntityAddressMutation(entity);

    const handleSubmit: ClientAddressProps["onSubmit"] = useCallback(
        async (newAddress) => {
            await mutation.mutateAsync(newAddress);
            setShowUpdateModal(false);
        },
        [mutation],
    );

    return (
        <div className="settings__address">
            <ClientAddress
                address={address as USAddress}
                business={!entity.isPersonal}
                readOnly
            />

            <footer className="m-0">
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => setShowUpdateModal(true)}
                >
                    Change address
                </Button>
            </footer>

            <Modal
                show={showUpdateModal}
                onHide={() => setShowUpdateModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientAddress
                        address={address as USAddress}
                        business={!entity.isPersonal}
                        onSubmit={handleSubmit}
                    >
                        <footer className="d-flex justify-content-between">
                            <Button
                                variant="secondary"
                                type="button"
                                size="lg"
                                onClick={() => setShowUpdateModal(false)}
                            >
                                Cancel
                            </Button>
                            <ButtonWithLoader
                                loading={mutation.isLoading}
                                variant="primary"
                                type="submit"
                                size="lg"
                            >
                                Save
                            </ButtonWithLoader>
                        </footer>
                    </ClientAddress>
                </Modal.Body>
            </Modal>
        </div>
    );
};
