import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { Card } from "../general/Card/Card";
import { AutopilotSavings } from "./savings/AutopilotSavings";

export const AutopilotContent: React.FC = () => {
    return (
        <Tab.Container activeKey="savings">
            <Card withTabs>
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link active={true}>Savings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link active={false}>Audit risk</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Card>

            <AutopilotSavings />
        </Tab.Container>
    );
};
