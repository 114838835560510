import { SpendingReportDto } from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import React, { useEffect } from "react";
import {
    CategoryLabelGetter,
    useCategoryLabelGetter,
} from "../../hooks/useCategoryLabelGetter";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { numberFormatter } from "../../common/helpers/number";
import { Taxonomy } from "../../common/categories";
import { Category } from "../../common/types/category";
import { useCategoryMap } from "../../hooks/useCategoryMap";
import { aggregateDataCategories, PieChartItem } from "./helpers/spending";
import {
    categoryColorsMap,
    isValidCategoryWithAssignedColor,
} from "./helpers/colors";
import { CreateChartParams } from "./types";

function createChartLabel(
    pieSeries: am4charts.PieSeries,
    labelTotal: { cents: string; dollars: any },
) {
    let label = pieSeries.createChild(am4core.Label);
    label.html = `
        <div class="spending-pie-chart__label">
            <span class="spending-pie-chart__label-title">Total spend</span>
            <span class="spending-pie-chart-amount">
                <span class="spending-pie-chart-amount__sign"></span>
                <span class="spending-pie-chart-amount__currency">$</span>
                <span class="spending-pie-chart-amount__dollars">${labelTotal.dollars}</span>
                <span class="spending-pie-chart-amount__cents">.${labelTotal.cents}</span>
            </span>
        </div>`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontWeight = "700";
}

function createChart({
    id,
    report,
    getCategoryLabel,
    categoryMap,
}: CreateChartParams<SpendingReportDto>) {
    const chartData = parseChartData(report, getCategoryLabel, categoryMap);
    const labelTotal = calculateTotalPieChartValue(chartData);

    let chart = am4core.create(id, am4charts.PieChart);
    chart.data = chartData;
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "spend";
    pieSeries.dataFields.category = "categoryLabel";
    pieSeries.slices.template.propertyFields.fill = "color";

    chart.innerRadius = am4core.percent(60);
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 0.6;
    pieSeries.slices.template.fillOpacity = 0.6;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    if (pieSeries.tooltip) {
        pieSeries.tooltip.disabled = true;
    }
    let as = pieSeries.slices.template.states.getKey("active");
    if (as) {
        as.properties.shiftRadius = 0;
    }
    createChartLabel(pieSeries, labelTotal);

    return chart;
}

function parseChartData(
    report: SpendingReportDto,
    getCategoryLabel: CategoryLabelGetter,
    categoryMap: Record<Taxonomy, Category>,
) {
    return addDisplayElements(
        aggregateDataCategories(report, categoryMap),
        getCategoryLabel,
    );
}

function addDisplayElements(
    chartData: PieChartItem[],
    getCategoryLabel: CategoryLabelGetter,
) {
    for (const item of chartData) {
        item.categoryLabel = getCategoryLabel(item.category);
        if (isValidCategoryWithAssignedColor(item.category)) {
            item.color = categoryColorsMap[item.category];
        }
    }
    return chartData;
}

function calculateTotalPieChartValue(chartData: PieChartItem[]) {
    const total = chartData.reduce((acc, item) => acc + item.spend, 0);
    return {
        dollars: numberFormatter.format(Math.floor(Math.abs(total))),
        cents: (Math.round(Math.abs(total) * 100) % 100)
            .toString()
            .padStart(2, "0"),
    };
}

interface Props {
    chart: SpendingReportDto;
}

export const SpendingPieChart: React.FC<Props> = ({ chart }) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();

    useEffect(() => {
        const pieChart = createChart({
            id: "spending-pie-chart",
            report: chart,
            getCategoryLabel: getCategoryLabel,
            categoryMap: categoryMap,
        });
        return () => pieChart.dispose();
    }, [chart, getCategoryLabel, categoryMap]);
    return (
        <div className="spending-pie-chart__left">
            <h4>Categories</h4>
            <div
                className="spending-pie-chart__left__chart"
                id="spending-pie-chart"
            ></div>
        </div>
    );
};
