import React, { useState } from "react";
import { Field, Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { submitHelper } from "../../../../helpers/form";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../reducers/appState";
import { financialAccountsRest } from "../../../../lib/restClient";
import { Entity } from "../../../../common/types/entity";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { updateFinancialAccountInQueryData } from "../../../../queries/entitiesAccounts";
import { useQueryClient } from "react-query";

export interface AccountNameProps {
    account: FinancialAccount;
    entity: Entity;
}

export interface FormSchema {
    name: string;
}

export const AccountName: React.FC<AccountNameProps> = ({ account }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState<boolean>();

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            name: account.name ?? "",
        },
        validationSchema: object().shape({
            name: string()
                .required("Please provide account name")
                .min(4, "4 characters minimum"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ name }) => {
                try {
                    const updatedAccount = {
                        ...account,
                        name: name.trim(),
                    };

                    if (updatedAccount.name === account.name) {
                        return;
                    }

                    updateFinancialAccountInQueryData(
                        queryClient,
                        updatedAccount,
                    );

                    await financialAccountsRest.update(account.id, { name });
                } catch (e) {
                    dispatch(
                        addNotification({
                            message: "Failed to change account name",
                            type: "danger",
                        }),
                    );
                }
            },
        }),
        validateOnChange: true,
    };

    return (
        <Formik {...form}>
            {({ submitForm }) => (
                <Form>
                    <Field
                        name="name"
                        className="form-control form-control-sm"
                        onBlur={() => {
                            submitForm();
                        }}
                    />
                </Form>
            )}
        </Formik>
    );
};
