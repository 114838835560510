import { useMutation, useQueryClient } from "react-query";
import { deleteJournalEntry } from "../lib/accounting";
import { useDispatch } from "react-redux";
import { addNotification } from "../reducers/appState";
interface UseDeleteJournalEntryParams {
    journalEntryId: string | undefined;
    entityId: number;
    onSuccess: () => void;
}

export function useDeleteJournalEntry({
    journalEntryId,
    entityId,
    onSuccess,
}: UseDeleteJournalEntryParams) {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(async () => {
        if (!journalEntryId) {
            return;
        }

        await deleteJournalEntry(entityId, journalEntryId);

        queryClient.invalidateQueries("generalLedger");
        dispatch(
            addNotification({
                message: `Journal entry successfully deleted`,
                type: "success",
                confetti: true,
            }),
        );

        onSuccess();
    });
}
