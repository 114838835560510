import React from "react";
import classNames from "classnames";

interface Props {
    isTitle?: boolean;
    isExpandable?: boolean;
    isExpanded?: boolean;
    onExpand?: () => void;
}

export const DataTableCell: React.FC<Props> = ({
    isExpandable,
    isTitle,
    isExpanded,
    onExpand,
    children,
}) => {
    return (
        <td
            className={classNames("data-table__cell", {
                "data-table__cell--title": isTitle,
                "data-table__cell--expandable": isExpandable,
                "data-table__cell--expanded": isExpanded,
            })}
            onClick={() => onExpand?.()}
        >
            {children}
        </td>
    );
};
