import React from "react";
import { ProfitLossReport } from "./types";
import { ReportFilters } from "../../common/types/filters/reports";
import { PLColumns } from "./ProfitLossReportTable/useProfitLossReportTable";
import { ProfitLossReportTableContainer } from "./ProfitLossReportTable/ProfitLossReportTableContainer";

interface Props {
    report: ProfitLossReport;
    filters: ReportFilters;
}

const grouping = [PLColumns.SECTION, PLColumns.PARENT_CATEGORY];

export const CashBasedProfitLossTableContainer: React.FC<Props> = ({
    report,
    filters,
}) => {
    return (
        <ProfitLossReportTableContainer
            report={report}
            filters={filters}
            grouping={grouping}
            highlightLastRow
            withBorder
        />
    );
};
