import React, { useMemo } from "react";
import { DisplayPlaidConnection } from "../../plaid/DisplayPlaidConnection/DisplayPlaidConnection";
import { UserFinancialConnection } from "../../../common/dto/user/user-integrations-response.dto";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { groupBy } from "lodash";
import { ConnectionProviderType } from "../../../common/types/financialConnection";
import { StripeConnectionsStatus } from "../../stripe/StripeConnectionsStatus";
import { GustoConnectionsStatus } from "../../gusto/GustoConnectionsStatus";

interface Props {
    connections: UserFinancialConnection[];
}

export const FinancialConnectionsSettings: React.FC<Props> = ({
    connections,
}) => {
    const connectionsByType = useMemo(
        () =>
            groupBy(
                connections,
                (connection) => connection.connection.connectionProvider,
            ),
        [connections],
    );

    return (
        <section>
            {connections.length > 0 ? (
                <>
                    {connectionsByType[ConnectionProviderType.PLAID].map(
                        (userConnection) => (
                            <DisplayPlaidConnection
                                key={userConnection.connection.id}
                                connection={
                                    userConnection.connection as PlaidConnectionWithAccounts
                                }
                                numberOfNotVisibleAccounts={
                                    userConnection.numberOfNotVisibleAccounts
                                }
                            />
                        ),
                    )}
                    {connectionsByType[ConnectionProviderType.STRIPE] && (
                        <StripeConnectionsStatus
                            stripeConnections={connectionsByType[
                                ConnectionProviderType.STRIPE
                            ].map((uc) => uc.connection)}
                        />
                    )}
                    {connectionsByType[ConnectionProviderType.GUSTO] && (
                        <GustoConnectionsStatus
                            gustoConnections={connectionsByType[
                                ConnectionProviderType.GUSTO
                            ].map((uc) => uc.connection)}
                        />
                    )}
                </>
            ) : (
                <p className="settings__empty-state">
                    No account connected yet
                </p>
            )}
        </section>
    );
};
