import { createContext, ReactNode } from "react";
import { noop } from "lodash";
import { BulkActionsToastMessage } from "./lib/bulkActions";
import { CategorizationToastMessage } from "./lib/categorization";
import { CustomToastMessage } from "./lib/common";

export type ToastMessage =
    | BulkActionsToastMessage
    | CategorizationToastMessage
    | CustomToastMessage;

export interface ToastMessageItem {
    key: string;
    message: ToastMessage;
}

interface Context {
    registerToast(key: string, message: ToastMessage): void;
    hideToast(key: string): void;
    toast(message: ReactNode): string;
    items: ToastMessageItem[];
}

export const ToastMessagesContext = createContext<Context>({
    registerToast: noop,
    hideToast: noop,
    toast: () => "",
    items: [],
});
