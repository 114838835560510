import React from "react";
import { Entity } from "../../../common/types/entity";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { EntityIcon } from "../../entity/EntityIcon";
import { Switch } from "../Switch/Switch";
import "./AccountsFilter.scss";
import { AccountsFilterAccount } from "./AccountsFilterAccount";
import { EntityFilterSharedProps } from "./lib";

interface Props extends EntityFilterSharedProps {
    entity: Entity;
    excludeNoBalanceAccounts?: boolean;
}

export const AccountsFilterEntity: React.FC<Props> = ({
    entity,
    excludeNoBalanceAccounts,
    isEntitySelected,
    toggleEntity,
    showEntitiesWithoutAccounts,
    disabled,
    ...accountFilterProps
}) => {
    const accounts = useFinancialAccounts({
        entityId: entity.id,
        excludeNoBalanceAccounts,
    });

    return accounts.length || showEntitiesWithoutAccounts ? (
        <section className="accounts-filter__entity">
            <Switch
                active={isEntitySelected(entity.id)}
                onChanged={() => toggleEntity(entity.id)}
                size="sm"
                direction="rtl"
                disabled={disabled}
                label={
                    <>
                        <EntityIcon entity={entity} size="sm" />
                        <span className="accounts-filter__entity__name">
                            {entity.name}
                        </span>
                    </>
                }
            />
            {accounts.map((account) => (
                <AccountsFilterAccount
                    key={account.id}
                    account={account}
                    entity={entity}
                    disabled={disabled}
                    showEntitiesWithoutAccounts={showEntitiesWithoutAccounts}
                    {...accountFilterProps}
                />
            ))}
        </section>
    ) : null;
};
