import {
    DiscountType,
    InvoiceStatus,
    PricingTier,
    SubscriptionStatus,
} from "../billing";
import { FLAT_RATE_PLANS, FlatRatePlan } from "../flatRateBilling";
import { Address } from "./embeddable/address";

export interface CardDetails {
    brand: string;
    last4: string;
}
export interface SubscriptionDetails {
    type: SubscriptionType;
    status: SubscriptionStatus;
    renewsOn: Date | null;
    price: number;
    plan: FLAT_RATE_PLANS;
    cancelsAt: Date | null;
    cancelOnTrialEnd: boolean;
    periodEndsAt: Date | null; // either subscription is renewed or canceled at this date
    nextPeriodPlan: FLAT_RATE_PLANS; // FREE if cancelled (or not subscribed)
    redeemedFreePreviousYearOffer: boolean;
    includePreviousYear: boolean;
}

export interface BillingStatus {
    creditsUsed: number; // for monthly users, will be removed soon
    totalUsage: number; // for annual users
    pricingTiers: PricingTier[];
    subscriptionDetails: SubscriptionDetails | null;
    trialAvailable: boolean;
    plans: FlatRatePlan[];
}

export interface AppliedDiscount {
    type: DiscountType;
    amount: number;
    value: number;
}

export enum SubscriptionType {
    CUSTOM = "CUSTOM",
    MONTHLY = "MONTHLY",
    ANNUAL = "ANNUAL",
    FLAT_RATE = "FLAT_RATE",
}

export interface BillingInfo {
    paymentMethod: CardDetails | null;
    name: string | null;
    email: string | null;
    address: Address | null;
}

export interface Invoice {
    status: InvoiceStatus;
    date: Date;
    url: string | null;
    totalInCents: number;
}

export interface BillingHistory {
    invoices: Invoice[];
}
