import React, { useState } from "react";
import { Transaction } from "../../../../common/types/transaction";
import styles from "./TransactionMatching.module.scss";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { SimplifiedTransactionDetails } from "./SimplifiedTransactionDetails";
import { MatchingView } from "./MatchingView/MatchingView";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import { LeftArrowIcon } from "../../../../icons";
import { Button } from "react-bootstrap";
import { FinancialDocumentPreview } from "../../../documents/FinancialDocumentDetails/FinancialDocumentPreview";

interface Props {
    transaction: EagerLoaded<Transaction, "transactionMatches">;
}

export const TransactionMatchingContainer: React.FC<Props> = ({
    transaction,
}) => {
    const [previewDocument, setPreviewDocument] =
        useState<FinancialDocument | null>(null);

    let header = null,
        content = null;

    if (previewDocument) {
        header = (
            <div className={styles.header}>
                <Button
                    size="sm"
                    variant="secondary-icon"
                    onClick={() => setPreviewDocument(null)}
                >
                    <LeftArrowIcon />
                </Button>{" "}
                Document details
            </div>
        );

        content = (
            <FinancialDocumentPreview financialDocument={previewDocument} />
        );
    } else {
        header = <div className={styles.header}>Documents</div>;
        content = (
            <MatchingView
                transaction={transaction}
                onFinancialDocumentSelected={setPreviewDocument}
            />
        );
    }

    return (
        <div className={styles.layout}>
            <header className={styles.headerArea}>{header}</header>
            <main className={styles.contentArea}>{content}</main>
            <aside className={styles.sidebarArea}>
                <SimplifiedTransactionDetails transaction={transaction} />
            </aside>
        </div>
    );
};
