import axios, { CancelTokenSource } from "axios";
import { useEffect } from "react";

export function useBackendRequestEffect(
    effect: (cancelToken: CancelTokenSource) => (() => void) | undefined | void,
    deps: any[],
): void {
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const cleanup = effect(cancelToken);

        return () => {
            cancelToken.cancel();
            if (cleanup) {
                cleanup();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
