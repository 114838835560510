import { Form, Formik, FormikConfig } from "formik";
import React from "react";
import { object, string } from "yup";
import { PhoneNumberInput } from "../../../phoneNumber/PhoneNumberInput";
import { UpdateProfileFormProps } from "./types";

interface Value {
    phoneNumber: string;
}

export const UpdatePhoneForm: React.FC<UpdateProfileFormProps<Value>> = ({
    phoneNumber,
    onSubmit,
    children,
}) => {
    const form: FormikConfig<Value> = {
        initialValues: {
            phoneNumber,
        },
        validationSchema: object().shape({
            phoneNumber: string()
                .required("Phone number is required")
                .matches(/\d{10}/, "Provide valid US phone number"),
        }),
        onSubmit,
    };

    return (
        <Formik {...form}>
            {({ isValid }) => (
                <Form>
                    <PhoneNumberInput />
                    {children(isValid)}
                </Form>
            )}
        </Formik>
    );
};
