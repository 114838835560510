import React, { forwardRef } from "react";
import {
    Transaction,
    TransactionClassification,
} from "../../common/types/transaction";
import { Dropdown, DropdownProps } from "react-bootstrap";
import { EntityIcon } from "../entity/EntityIcon";
import { useEntities } from "../../hooks/useEntities";
import { TransactionEntityMenuItem } from "./TransactionEntityMenuItem";
import "./TransactionEntityMenu.scss";
import { ButtonProps } from "react-bootstrap/Button";
import classNames from "classnames";
import "./TransactionEntityCompact.scss";
import { CheckIcon } from "../../icons";
import { TRANSACTION_CLASSIFICATION_LABELS } from "../../common/constants";
import { ObjectIcon } from "../general/ObjectIcon/ObjectIcon";
import { DEFAULT_ICON_COLOR } from "../../constants";
import { useInteraction } from "../../hooks/useInteraction";
import { Entity } from "../../common/types/entity";

interface Props {
    transaction?: Transaction;
    drop?: DropdownProps["drop"];
    loading?: boolean;
    onChangeToPersonal(): void;
    onChangeToBusiness(entity: Entity): void;
}

export const TransactionEntityCompact: React.FC<Props> = ({
    transaction,
    onChangeToPersonal,
    onChangeToBusiness,
    loading,
    drop,
    children,
}) => {
    const entities = useEntities({ onlyBusiness: true });

    return (
        <Dropdown
            drop={drop}
            className={classNames("transaction-entity-compact", {
                "transaction-entity-compact--loading": loading,
            })}
        >
            <Dropdown.Toggle as={CustomToggle} disabled={loading}>
                {children}
            </Dropdown.Toggle>

            <Dropdown.Menu className="transaction-entity-compact__menu">
                <Dropdown.Item
                    onClick={(e) => {
                        e.stopPropagation();
                        onChangeToPersonal();
                    }}
                    active={transaction && !transaction.isBusiness}
                >
                    {transaction && !transaction.isBusiness ? (
                        <EntityIcon entity={transaction.entity} size="xs" />
                    ) : (
                        <ObjectIcon
                            name={
                                TRANSACTION_CLASSIFICATION_LABELS[
                                    TransactionClassification.PERSONAL
                                ]
                            }
                            defaultColor={DEFAULT_ICON_COLOR}
                            size="xs"
                            square
                        />
                    )}
                    <span className="transaction-entity-menu__name">
                        {
                            TRANSACTION_CLASSIFICATION_LABELS[
                                TransactionClassification.PERSONAL
                            ]
                        }
                    </span>
                    {transaction && !transaction.isBusiness && (
                        <span className="icon-color-blue-500">
                            <CheckIcon />
                        </span>
                    )}
                </Dropdown.Item>
                <Dropdown.Divider />
                {entities.map((entity) => (
                    <TransactionEntityMenuItem
                        key={entity.id}
                        transaction={transaction}
                        entity={entity}
                        onSelect={() => onChangeToBusiness(entity)}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const CustomToggle: React.FC<ButtonProps> = forwardRef(
    ({ children, onClick, disabled }, ref) => {
        const handleClick = useInteraction((e) => {
            e.stopPropagation();
            if (!disabled) {
                onClick?.(e);
            }
        });

        return (
            <div ref={ref as any} {...handleClick}>
                {children}
            </div>
        );
    },
);
