import React, { useState } from "react";
import { FormCheckbox } from "../forms/FormCheckbox/FormCheckbox";
import { EntityIcon } from "../entity/EntityIcon";
import { FinancialDocument } from "../../common/types/financialDocument";
import styles from "./DocumentsPage.module.scss";
import { CheckIcon, DotsVerticalIcon } from "../../icons";
import { useEffectiveEntity } from "./useEffectiveEntity";
import { CaptureClicks } from "../general/CaptureClicks";
import { formatDate, getMatchedTransaction } from "./lib";
import { ManagementDropdown } from "../general/ManagementDropdown/ManagementDropdown";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { EmptyObjectIcon } from "../general/ObjectIcon/EmptyObjectIcon";
import { useEntities } from "../../hooks/useEntities";
import { EntityMenuItem } from "../general/EntityMenuItem/EntityMenuItem";
import { Entity } from "../../common/types/entity";
import { useFinancialDocumentBulkSelection } from "./FinancialDocumentBulkSelection/useFinancialDocumentBulkSelection";
import { CustomToggle } from "../general/CustomToggle/CustomToggle";
import { DocumentName } from "./DocumentName";

export interface DocumentTableRowProps {
    financialDocument: FinancialDocument;
    onOpenDetails(): void;
    onRemoveDocument(): void;
    onChangeEntity(newEntity: Entity): void;
}

export const DocumentTableRow: React.FC<DocumentTableRowProps> = ({
    financialDocument,
    onOpenDetails,
    onRemoveDocument,
    onChangeEntity,
}) => {
    const bulkSelection = useFinancialDocumentBulkSelection();
    const entities = useEntities();

    const matchedFinancialTransaction =
        getMatchedTransaction(financialDocument);
    const entity = useEffectiveEntity(financialDocument);

    const [dropdownOpen, setDropdownOpen] = useState<boolean>();

    return (
        <tr
            className={classNames(styles.row, {
                [styles.dropdownOpen]: dropdownOpen,
            })}
            onClick={onOpenDetails}
            data-testid="financial-document-table-row"
        >
            <td data-testid="financial-document-table-td-checkbox">
                <FormCheckbox
                    value="page"
                    isChecked={
                        bulkSelection.hasSelectedByCurrentFilters ||
                        bulkSelection.isSelected(financialDocument)
                    }
                    disabled={bulkSelection.hasSelectedByCurrentFilters}
                    handleChange={() =>
                        bulkSelection.isSelected(financialDocument)
                            ? bulkSelection.deselect(financialDocument)
                            : bulkSelection.select(financialDocument)
                    }
                    preventClickPropagation
                    small
                />
            </td>
            <td
                className={styles.date}
                data-testid="financial-document-table-td-date"
            >
                {formatDate(financialDocument.createdAt)}
            </td>
            <td data-testid="financial-document-table-td-document-name">
                <DocumentName fileName={financialDocument.fileName} icon />
            </td>
            <td
                className={styles.entity}
                data-testid="financial-document-table-td-entity"
            >
                <CaptureClicks>
                    <Dropdown
                        show={matchedFinancialTransaction ? false : undefined}
                    >
                        <Dropdown.Toggle as={CustomToggle}>
                            {entity ? (
                                <EntityIcon entity={entity} size="xs" />
                            ) : (
                                <EmptyObjectIcon size="xs" square />
                            )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {entities.map((menuEntity) => (
                                <EntityMenuItem
                                    key={menuEntity.id}
                                    entity={menuEntity}
                                    onSelect={() => onChangeEntity(menuEntity)}
                                    isSelected={menuEntity.id === entity?.id}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </CaptureClicks>
            </td>
            <td
                className={styles.status}
                data-testid="financial-document-table-td-matched"
            >
                {matchedFinancialTransaction ? (
                    <CheckIcon className="icon-color-blue-600" />
                ) : (
                    "•"
                )}
            </td>
            <td
                className={styles.actions}
                data-testid="financial-document-table-td-actions"
            >
                <CaptureClicks>
                    <ManagementDropdown
                        onToggle={setDropdownOpen}
                        trigger={
                            <DotsVerticalIcon className="icon-color-gray-700" />
                        }
                        id={`financial_document_dropdown_${financialDocument.id}`}
                    >
                        <Dropdown.Item
                            as="button"
                            className="text-danger"
                            onClick={onRemoveDocument}
                        >
                            Remove document
                        </Dropdown.Item>
                    </ManagementDropdown>
                </CaptureClicks>
            </td>
        </tr>
    );
};
