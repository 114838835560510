import { InviteMemberDto } from "../common/dto/user/invite-member.dto";
import { useMutation } from "react-query";
import {
    addMember,
    editMemberRole,
    removeMember,
    updatePersonalInfo,
} from "../lib/user";
import {
    allMembersQueryKey,
    getEntityInvitesQueryKey,
    getEntityUsersQueryKey,
    queryClient,
} from "../queryClient";
import { inviteCodeRest, userRest } from "../lib/restClient";
import { InviteCode } from "../common/types/inviteCode";
import { User } from "../common/types/user";
import {
    UserEntityAssignment,
    UserRole,
} from "../common/types/userEntityAssignment";
import { Entity } from "../common/types/entity";
import { UpdateUserDto } from "../common/dto/user/update-user.dto";
import { useDispatch } from "react-redux";
import { updateUser } from "../reducers/appState";
import { useUser } from "../hooks/useUser";
import { OnboardingPersonalInfoDto } from "../common/dto/user/onboarding-personal-info.dto";

export function useAddMemberMutation(entity: Entity) {
    return useMutation((data: InviteMemberDto) => addMember(data), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(
                getEntityInvitesQueryKey(entity.id),
            );
            await queryClient.invalidateQueries(allMembersQueryKey);
            await queryClient.invalidateQueries(
                getEntityUsersQueryKey(entity.id),
            );
        },
    });
}

export function useRemoveInviteMutation(invite: InviteCode, entity: Entity) {
    return useMutation(() => inviteCodeRest.delete(invite.id), {
        onSuccess: () => {
            const key = getEntityInvitesQueryKey(entity.id);
            queryClient.setQueryData(
                key,
                queryClient
                    .getQueryData<InviteCode[]>(key)
                    ?.filter((code) => code.id !== invite.id),
            );
        },
    });
}

export function useRemoveMemberMutation(
    user: User,
    entity: Entity,
    onComplete?: () => void,
) {
    return useMutation(() => removeMember(user, entity), {
        onSuccess: async () => {
            const key = getEntityUsersQueryKey(entity.id);
            queryClient.setQueryData(
                key,
                queryClient
                    .getQueryData<UserEntityAssignment[]>(key)
                    ?.filter((a) => a.user.id !== user.id),
            );
            onComplete?.();
            await queryClient.invalidateQueries(allMembersQueryKey);
        },
    });
}

export function useEditMemberRoleMutation(user: User, entity: Entity) {
    const key = getEntityUsersQueryKey(entity.id);

    return useMutation<void, unknown, UserRole, UserEntityAssignment[]>(
        (role: UserRole) => editMemberRole(user, { role, entityId: entity.id }),
        {
            onMutate: (role) => {
                const previousAssignments =
                    queryClient.getQueryData<UserEntityAssignment[]>(key);

                queryClient.setQueryData(
                    key,
                    previousAssignments?.map((a) =>
                        a.user.id === user.id ? { ...a, role } : a,
                    ),
                );

                return previousAssignments;
            },
            onError: (_1, _2, previousAssignments) => {
                queryClient.setQueryData(key, previousAssignments);
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries(allMembersQueryKey);
            },
        },
    );
}

export function useUpdateUserMutation() {
    const dispatch = useDispatch();
    const user = useUser();

    return useMutation<User, unknown, UpdateUserDto, User>(
        (payload: UpdateUserDto) => userRest.update(user.id, payload),
        {
            onMutate: (payload) => {
                dispatch(
                    updateUser({
                        ...user,
                        ...payload,
                    }),
                );

                return user;
            },
            onError: (_1, _2, previous) => {
                if (previous) {
                    dispatch(updateUser(previous));
                }
            },
            onSuccess: (updatedUser) => {
                dispatch(updateUser(updatedUser));
            },
        },
    );
}

export function useUpdatePersonalInfoMutation() {
    const dispatch = useDispatch();
    const user = useUser();

    return useMutation<User, unknown, OnboardingPersonalInfoDto, User>(
        (payload: OnboardingPersonalInfoDto) => updatePersonalInfo(payload),
        {
            onMutate: (payload) => {
                dispatch(
                    updateUser({
                        ...user,
                        ...payload,
                    }),
                );

                return user;
            },
            onError: (_1, _2, previous) => {
                if (previous) {
                    dispatch(updateUser(previous));
                }
            },
            onSuccess: (updatedUser) => {
                dispatch(updateUser(updatedUser));
            },
        },
    );
}
