import { createContext, useContext } from "react";
import { CashFlowReportDto } from "../../common/dto/reports/cash-flow-tab-report-response.dto";
import { ReportFilters } from "../../common/types/filters/reports";

export interface CashFlowContextValue {
    cashFlowReport?: CashFlowReportDto;
    isCashFlowReportLoading: boolean;
    cashFlowFiltersUsed?: ReportFilters;
    reportCreatedAt?: number;
}

export const CashFlowContext = createContext<CashFlowContextValue>({
    isCashFlowReportLoading: true,
});

export function useCashFlowContext(): CashFlowContextValue {
    const ctx = useContext(CashFlowContext);
    if (!ctx) {
        throw new Error(
            "Can only be used inside a CashFlowContextContextProvider",
        );
    }

    return ctx;
}
