import React from "react";
import "./Dashboard.scss";
import { Col, Row } from "react-bootstrap";
import { Widget } from "../general/Widget";
import { CashFlow } from "./CashFlow";
import { DashboardTransactions } from "./DashboardTransactions";
import { TransactionsContextProvider } from "../transactions/context/TransactionsContextProvider";
import { useUser } from "../../hooks/useUser";
import { DashboardAccounts } from "./DashboardAccounts";
import { Support } from "./Support";

interface Props {}

export const Dashboard: React.FC<Props> = () => {
    const user = useUser();

    return (
        <div className="dashboard">
            <header className="dashboard__header">
                <h2>Hello, {user.firstName}</h2>
            </header>

            <Row>
                <Col md={8}>
                    <Widget title="Cash balance">
                        <CashFlow />
                    </Widget>

                    <Widget title="Transactions">
                        <TransactionsContextProvider>
                            <DashboardTransactions />
                        </TransactionsContextProvider>
                    </Widget>
                </Col>

                <Col md={4}>
                    <Widget title="Accounts" className="dashboard-accounts">
                        <DashboardAccounts />
                    </Widget>

                    <Widget title="Support">
                        <Support />
                    </Widget>
                </Col>
            </Row>
        </div>
    );
};
