import React from "react";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import { Loader } from "../../general/Loader";
import { StandardModal } from "../../general/Modal/Modal";
interface Props {
    show: boolean;
}

export const NewConnectionLoaderModal: React.FC<Props> = ({ show }) => {
    return (
        <StandardModal
            show={show}
            size="lg"
            backdrop="static"
            keyboard={false}
            className={classNames("accounts-connected-modal")}
        >
            <>
                <Modal.Header>
                    <Modal.Title>Loading accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This might take a few seconds...</p>

                    <div className="py-5">
                        <Loader />
                    </div>
                </Modal.Body>
            </>
        </StandardModal>
    );
};
