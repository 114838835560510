import React from "react";
import classNames from "classnames";

interface Props {
    depth?: number;
    isTotal?: boolean;
    isSummary?: boolean;
}

export const DataTableRow: React.FC<Props> = ({
    depth = 0,
    isTotal,
    isSummary,
    children,
}) => {
    return (
        <tr
            className={classNames(
                `data-table__row data-table__row--depth-${depth}`,
                {
                    "data-table__row--total": isTotal,
                    "data-table__row--summary-section": isSummary,
                },
            )}
        >
            {children}
        </tr>
    );
};
