import React, { useCallback, useEffect, useRef, useState } from "react";
import { currencyFormatterShort } from "../../common/helpers/currency";
import { AnimatedSavings } from "./AnimatedSavings";

interface Props {
    value: number;
}

export const UsageValue: React.FC<Props> = ({ value }) => {
    const oldValue = useRef<number>();
    const [displayedValue, setDisplayedValue] = useState(value);
    const [animatedValue, setAnimatedValue] = useState<number>();

    useEffect(() => {
        if (oldValue.current && oldValue.current < value) {
            setAnimatedValue(value - oldValue.current);
        } else {
            setDisplayedValue(value);
        }

        oldValue.current = value;
    }, [value]);

    const onAnimationAboutToEnd = useCallback(() => {
        setDisplayedValue(value);
    }, [value]);

    const onAnimationEnd = useCallback(() => {
        setAnimatedValue(undefined);
    }, []);

    return (
        <div className="usage-widget__value">
            {currencyFormatterShort.format(Math.round(displayedValue))} savings
            {animatedValue && (
                <AnimatedSavings
                    value={animatedValue}
                    onAboutToEnd={onAnimationAboutToEnd}
                    onEnd={onAnimationEnd}
                />
            )}
        </div>
    );
};
