import React, { useState } from "react";
import { useAddMemberMutation } from "../../../mutations/user";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { FormRow } from "../../forms/FormRow";
import { Button, Col, Row } from "react-bootstrap";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../reducers/appState";
import { FormRowRadios } from "../../forms/FormRowRadios";
import { ROLES_LABELS } from "../../../common/constants";
import { useEntity } from "../../entity/useEntity";
import { InvitedUserRole } from "../../../common/types/inviteCode";

const roles = Object.values(InvitedUserRole);
const captions: Record<InvitedUserRole, string> = {
    [InvitedUserRole.ADMIN]: "Can manage integrations, members, and settings",
    [InvitedUserRole.MEMBER]: "Can manage transactions and accounts",
};

interface Props {
    onCancel: () => void;
}

interface FormSchema {
    email: string;
    role: InvitedUserRole | "";
}

export const AddMember: React.FC<Props> = ({ onCancel }) => {
    const entity = useEntity();
    const addMemberMutation = useAddMemberMutation(entity);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            email: "",
            role: "",
        },
        validationSchema: object().shape({
            email: string()
                .email("Must be a valid email address")
                .required("Email is required"),
            role: string().required("Select role for the new user"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ email, role }) => {
                if (role) {
                    await addMemberMutation.mutateAsync({
                        email,
                        role,
                        entityId: entity.id,
                    });
                    dispatch(
                        addNotification({
                            message: "Invite sent",
                            type: "success",
                        }),
                    );
                    onCancel();
                }
            },
        }),
    };

    return (
        <Row>
            <Col md={6}>
                <Formik {...form}>
                    <Form>
                        <FormRow fieldName={"email"} label={"Email address"} />

                        <h4 className="font-weight-bold">Role</h4>
                        <FormRowRadios
                            options={roles}
                            labels={ROLES_LABELS}
                            captions={captions}
                            idPrefix="role"
                            fieldName="role"
                        />

                        <footer>
                            <Button
                                variant="secondary"
                                onClick={onCancel}
                                type="button"
                                size="lg"
                                className="mr-3"
                            >
                                Cancel
                            </Button>
                            <ButtonWithLoader
                                loading={loading}
                                variant="primary"
                                type="submit"
                                size="lg"
                            >
                                Add member
                            </ButtonWithLoader>
                        </footer>
                    </Form>
                </Formik>
            </Col>
        </Row>
    );
};
