import { SpendingReportDto } from "../../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { Taxonomy } from "../../../common/categories";
import { Category } from "../../../common/types/category";

export interface PieChartItem {
    category: Taxonomy;
    spend: number;
    categoryLabel?: string;
    color?: string;
}

interface ProcessRowParams {
    chartData: PieChartItem[];
    categoryId: Taxonomy;
    report: SpendingReportDto;
    category: Taxonomy;
}

function processRow({
    chartData,
    categoryId,
    report,
    category,
}: ProcessRowParams) {
    let categoryObj = chartData.find((item) => item.category === categoryId);

    if (!categoryObj) {
        categoryObj = {
            category: categoryId,
            spend: 0,
        };
        chartData.push(categoryObj);
    }
    categoryObj.spend = report.data.reduce(
        (acc, item) => acc + item[category],
        categoryObj.spend,
    );
}

export function aggregateDataCategories(
    report: SpendingReportDto,
    categoryMap: Record<Taxonomy, Category>,
) {
    const chartData: PieChartItem[] = [];
    for (const category of report.series) {
        const categoryObj = categoryMap[category];
        processRow({
            chartData: chartData,
            categoryId: categoryObj.parentCategoryId
                ? categoryObj.parentCategoryId
                : category,
            report: report,
            category: category,
        });
    }
    return chartData;
}
