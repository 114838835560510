import React from "react";
import { BalanceSheetReport } from "./types";
import { BalanceSheetTableSection } from "./BalanceSheetTableSection";
import { BalanceSheetTableRow } from "./BalanceSheetTableRow";

interface Props {
    report: BalanceSheetReport;
}

export const BalanceSheetTable: React.FC<Props> = ({ report }) => {
    return (
        <table className="data-table--bold-totals data-table__table">
            <tbody>
                {report.rows.map((row) => (
                    <BalanceSheetTableSection
                        section={row}
                        depth={0}
                        key={row.label}
                        renderRow={(props) => (
                            <BalanceSheetTableRow {...props} />
                        )}
                    />
                ))}
            </tbody>
        </table>
    );
};
