import { useHistory } from "react-router-dom";
import { useDocumentParams } from "./useDocumentParams";

export function useDocumentDetails() {
    const history = useHistory();
    const { documentId } = useDocumentParams();
    return {
        shownDocumentId: documentId ? Number(documentId) : null,
        showDocumentDetails: (financialDocumentId: number) => {
            history.push(`/documents/${financialDocumentId}`);
        },
        closeDetails: () => {
            history.push("/documents");
        },
    };
}
