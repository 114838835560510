import React, { useContext, useMemo } from "react";
import { TransactionDetailsSection } from "./TransactionDetailsSection";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    HideIcon,
    IgnoreRequirementsIcon,
    RequirementsIcon,
    ShowIcon,
    SplitIcon,
    SupportIcon,
} from "../../../icons";
import { Transaction } from "../../../common/types/transaction";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { difference } from "lodash";
import { CATEGORY_RELATED_ACTIONS } from "../../../common/requiredActions";

interface Props {
    transaction: Transaction;
}

export const TransactionDetailsFooter: React.FC<Props> = ({ transaction }) => {
    const { onSplit, onUpdate } = useContext(TransactionDetailsContext);
    const showRequirementsSnoozing = useMemo(
        () =>
            transaction.requirementsSnoozed ||
            difference(transaction.requiredActions, CATEGORY_RELATED_ACTIONS)
                .length > 0,
        [transaction.requirementsSnoozed, transaction.requiredActions],
    );

    return (
        <TransactionDetailsSection forceOpened>
            <OverlayTrigger
                overlay={
                    <Tooltip id="split-transaction">Split transaction</Tooltip>
                }
            >
                <Button
                    variant="plain"
                    onClick={onSplit}
                    className="mr-4"
                    data-testid="transaction-details-split"
                >
                    <SplitIcon />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={
                    <Tooltip id="ignore-transaction">
                        {transaction.isIgnored
                            ? "Show in Reports"
                            : "Hide from Reports"}
                    </Tooltip>
                }
            >
                <Button
                    variant="plain"
                    onClick={() =>
                        onUpdate({ isIgnored: !transaction.isIgnored })
                    }
                    className="mr-4"
                    data-testid="transaction-details-ignore"
                >
                    {transaction.isIgnored ? <ShowIcon /> : <HideIcon />}
                </Button>
            </OverlayTrigger>
            {showRequirementsSnoozing && (
                <OverlayTrigger
                    overlay={
                        <Tooltip id="snooze-requirements">
                            {transaction.requirementsSnoozed
                                ? "Enable Requirements"
                                : "Snooze Requirements"}
                        </Tooltip>
                    }
                >
                    <Button
                        variant="plain"
                        onClick={() =>
                            onUpdate({
                                requirementsSnoozed:
                                    !transaction.requirementsSnoozed,
                            })
                        }
                        className="mr-4"
                        data-testid="transaction-details-snooze-requirements"
                    >
                        {transaction.requirementsSnoozed ? (
                            <RequirementsIcon />
                        ) : (
                            <IgnoreRequirementsIcon />
                        )}
                    </Button>
                </OverlayTrigger>
            )}
            <OverlayTrigger overlay={<Tooltip id="get-help">Get help</Tooltip>}>
                <a
                    href="mailto:support@kick.co"
                    data-testid="transaction-details-help"
                >
                    <SupportIcon />
                </a>
            </OverlayTrigger>
        </TransactionDetailsSection>
    );
};
