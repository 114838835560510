import React, { useContext } from "react";
import classNames from "classnames";
import { noop } from "../../../helpers/general";
import { accountingNumberFormatter } from "../../../common/helpers/number";
import { RenderBalanceSheetRowProps } from "./types";
import { NavLink } from "react-router-dom";
import { AccountingReportContext } from "../AccountingReport.context";
import { startOfYear } from "date-fns";
import { useDrillDownUrl } from "../useDrillDownUrl";

export const BalanceSheetTableRow: React.FC<RenderBalanceSheetRowProps> = ({
    label,
    value,
    isExpanded,
    canExpand,
    depth,
    isTotal,
    onExpandToggle,
    accountCodes,
}) => {
    const cellCommonClasses = {
        "data-table__cell": true,
        "data-table__cell--expanded": isExpanded,
    };

    const { filters } = useContext(AccountingReportContext);

    const drillDownUrl = useDrillDownUrl({
        startDate: filters.endDate
            ? startOfYear(filters.endDate)
            : filters.endDate,
        endDate: filters.endDate,
        entityId: filters.entityId,
        accountCodes,
    });

    return (
        <tr
            className={classNames(
                "data-table__row",
                `data-table__row--depth-${depth}`,
                {
                    "data-table__row--total": isTotal,
                },
            )}
        >
            <td
                className={classNames("data-table__cell--title", {
                    ...cellCommonClasses,
                    "data-table__cell--expandable": canExpand,
                })}
                onClick={canExpand ? onExpandToggle : noop}
            >
                {label}
            </td>
            <td
                className={classNames(cellCommonClasses)}
                onClick={canExpand ? onExpandToggle : noop}
            >
                {!isExpanded ? (
                    <NavLink
                        to={drillDownUrl}
                        className="data-table__drill-down"
                    >
                        {accountingNumberFormatter(value)}
                    </NavLink>
                ) : null}
            </td>
        </tr>
    );
};
