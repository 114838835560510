import React from "react";
import classNames from "classnames";
import styles from "./CollapsibleInfoBanner.module.scss";

interface Props {
    className?: string;
}

export const CollapsibleInfoBanner: React.FC<Props> = ({
    className,
    children,
}) => {
    return (
        <section className={classNames(styles.banner, className)}>
            {children}
        </section>
    );
};
