import React, { useMemo } from "react";
import { Card } from "../general/Card/Card";
import { AccountingReportLink } from "./AccountingReportLink";
import { useHistory } from "react-router-dom";
import { endOfYear, formatISO, startOfYear, subYears } from "date-fns";
import { useEntitiesWithAccountingAvailable } from "./useEntitiesWithAccountingAvailable";
import { useAccountingTabContext } from "./useAccountingTab";
import { FeatureAccess } from "../general/FeatureAccess/FeatureAccess";
import { PREMIUM_FEATURES } from "../../common/flatRateBilling";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ThunderboltIcon } from "../../icons";
import { CaptureClicks } from "../general/CaptureClicks";

const defaultStart = startOfYear(subYears(new Date(), 1));
const defaultEnd = endOfYear(subYears(new Date(), 1));

export const AccountingPage: React.FC = () => {
    const history = useHistory();

    const { filters } = useAccountingTabContext();
    const availableEntities = useEntitiesWithAccountingAvailable();
    const entityId = filters.entityId ?? availableEntities[0]?.id;

    const dateStart = useMemo(
        () =>
            formatISO(filters.startDate ?? defaultStart, {
                representation: "date",
            }),
        [filters.startDate],
    );
    const dateEnd = useMemo(
        () =>
            formatISO(filters.endDate ?? defaultEnd, {
                representation: "date",
            }),
        [filters.endDate],
    );

    return (
        <Card label="Accountant Reports">
            <AccountingReportLink
                title={
                    <>
                        Profit & Loss <small>(Modified Cash Basis)</small>
                    </>
                }
                description="Reports your income and expenses over a specified period of time"
                onClick={() =>
                    history.push(
                        `/accounting/profit-loss?startDate=${dateStart}&endDate=${dateEnd}&entityId=${entityId}`,
                    )
                }
                active
            />
            <AccountingReportLink
                title={
                    <FeatureAccess
                        feature={PREMIUM_FEATURES.BALANCE_SHEET}
                        renderAllowed={() => <>Balance Sheet</>}
                        renderBlocked={({ openPlans }) => (
                            <CaptureClicks>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`blocked-balance-sheet`}>
                                            Upgrade to access your Balance Sheet
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="plain"
                                        size="sm"
                                        onClick={openPlans}
                                    >
                                        Balance Sheet
                                        <ThunderboltIcon className="ml-2 icon-color-blue-600 icon-size-text" />
                                    </Button>
                                </OverlayTrigger>
                            </CaptureClicks>
                        )}
                    />
                }
                description="Snapshot of your financial position at a given point of time, broken down into assets, liabilities, and equity"
                onClick={() =>
                    history.push(
                        `/accounting/balance-sheet?startDate=${dateStart}&endDate=${dateEnd}&entityId=${entityId}`,
                    )
                }
                active
            />
            <AccountingReportLink
                title={
                    <FeatureAccess
                        feature={PREMIUM_FEATURES.GENERAL_LEDGER}
                        renderAllowed={() => <>General Ledger</>}
                        renderBlocked={({ openPlans }) => (
                            <CaptureClicks>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`blocked-general-ledger`}>
                                            Upgrade to access your General
                                            Ledger
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="plain"
                                        size="sm"
                                        onClick={openPlans}
                                    >
                                        General Ledger
                                        <ThunderboltIcon className="ml-2 icon-color-blue-600 icon-size-text" />
                                    </Button>
                                </OverlayTrigger>
                            </CaptureClicks>
                        )}
                    />
                }
                description="A record of all transactions in your business that helps in preparing financial statements"
                onClick={() =>
                    history.push(
                        `/accounting/general-ledger?startDate=${dateStart}&endDate=${dateEnd}&entityId=${entityId}`,
                    )
                }
                active
            />
            <AccountingReportLink
                title={<>Chart of Accounts</>}
                description="Complete list of financial accounts that are used to categorize your transactions correctly"
            />
        </Card>
    );
};
