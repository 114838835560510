import React, { useCallback } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AccountDetailsField } from "./AccountDetailsField";
import { AlertIcon } from "../../../../icons";
import "./AccountDetails.scss";
import { getAnonymousAccountNumber } from "../../../../common/helpers/bankAccount";
import { addNotification } from "../../../../reducers/appState";
import { useDispatch } from "react-redux";
import { FinancialAccountRoutingNumbers } from "../../../financialAccount/FinancialAccountRoutingNumbers";
import { AccountName } from "./AccountName";
import { useEntity } from "../../../entity/useEntity";
import { useUser } from "../../../../hooks/useUser";
import { isConnectionOwner } from "../../../../hooks/useConnectionOwnership";
import { EMPTY_STATE } from "../../../../common/constants";
import { UpdateFinancialAccountEntity } from "../../../financialAccount/UpdateFinancialAccountEntity";
import { AccountTypeChange } from "./AccountTypeChange";
import { format } from "date-fns";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import { useIsAdmin } from "../../../../hooks/useRoles";
import { RemoveFinancialAccount } from "../../../financialAccount/RemoveFinancialAccount";
import { ExtendedFinancialAccount } from "../../../../lib/financialAccount";
import { FinancialAccountSourceType } from "../../../../common/types/financialAccount";
import { getConnectionLabel } from "../../../../common/helpers/financialConnection";
import {
    ACCOUNTS_POSSIBLY_PERSONAL,
    getLeadingAccountConnection,
    isCardAccount,
} from "../../../../common/helpers/financialAccount";
import { AccountConnectionStatus } from "./AccountConnectionStatus";
import { Switch } from "../../../general/Switch/Switch";
import {
    useAccountDisablingMutation,
    useAccountEnablingMutation,
} from "../../../../mutations/financialAccount";
import { GustoAccountDetails } from "./GustoAccountDetails";
import { FinancialAccountInfo } from "./FinancialAccountInfo";
import { SyncGustoConnection } from "../../Payroll/SyncGustoConnection";

export interface AccountDetailsProps {
    account: ExtendedFinancialAccount;
}

function getAccountNumber(account: ExtendedFinancialAccount) {
    if (account.accountNumberMask) {
        return getAnonymousAccountNumber(account.accountNumberMask);
    } else {
        return EMPTY_STATE;
    }
}

export const AccountDetails: React.FC<AccountDetailsProps> = ({ account }) => {
    const dispatch = useDispatch();
    const entity = useEntity();
    const isAdmin = useIsAdmin();
    const connection = getLeadingAccountConnection(account);
    const user = useUser();
    const canEditEntity =
        isAdmin || (connection && isConnectionOwner(connection, user));

    const enableAccount = useAccountEnablingMutation(account);
    const disableAccount = useAccountDisablingMutation(account);

    const handleChangeAccountEnabled = useCallback(
        (enabled) => {
            if (enabled) {
                enableAccount.mutate();
            } else {
                disableAccount.mutate();
            }
        },
        [disableAccount, enableAccount],
    );

    const missingClassification = account.isBusiness === null;
    const missingEntity = account.entity === null;

    const onEntityUpdated = useCallback(() => {
        dispatch(
            addNotification({
                type: "success",
                message: "Account successfully moved to a different entity",
            }),
        );
    }, [dispatch]);

    const accountNumber = getAccountNumber(account);

    return (
        <div className="account-details">
            <section className="account-details__section">
                <header className="account-details__section__header">
                    <h4>Details</h4>
                </header>

                <main className="account-details__section__content">
                    <AccountDetailsField title="Account name">
                        <AccountName account={account} entity={entity} />
                    </AccountDetailsField>

                    <AccountDetailsField
                        title={
                            <>
                                Account type
                                {missingClassification && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip
                                                id={`missing-classification-${account.id}`}
                                            >
                                                Required info missing
                                            </Tooltip>
                                        }
                                    >
                                        <AlertIcon className="icon-color-red-600 ml-2" />
                                    </OverlayTrigger>
                                )}
                            </>
                        }
                        className="account_details__type"
                    >
                        {ACCOUNTS_POSSIBLY_PERSONAL.includes(
                            account.sourceType,
                        ) ? (
                            <AccountTypeChange
                                account={account}
                                connection={connection ?? undefined}
                                onChanged={onEntityUpdated}
                            />
                        ) : (
                            "Business"
                        )}
                    </AccountDetailsField>

                    <AccountDetailsField
                        title={
                            <>
                                Entity
                                {missingEntity && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip
                                                id={`missing-entity-${account.id}`}
                                            >
                                                Required info missing
                                            </Tooltip>
                                        }
                                    >
                                        <AlertIcon className="icon-color-red-600 ml-2" />
                                    </OverlayTrigger>
                                )}
                            </>
                        }
                        className="account_details__entity"
                    >
                        <UpdateFinancialAccountEntity
                            account={account}
                            isBusiness={account.entity?.isBusiness ?? undefined}
                            connection={connection ?? undefined}
                            selectedEntity={account.entity ?? undefined}
                            dropdownKey={`accountEntity_${account.id}`}
                            disabled={!canEditEntity}
                            size="sm"
                            onUpdated={onEntityUpdated}
                        />
                    </AccountDetailsField>
                </main>
            </section>

            {account.sourceType !== FinancialAccountSourceType.FILE_IMPORT && (
                <section className="account-details__section">
                    <header className="account-details__section__header">
                        <h4>Info</h4>
                    </header>

                    <main className="account-details__section__content">
                        {connection && (
                            <AccountDetailsField title="Account">
                                {getConnectionLabel(connection)}
                            </AccountDetailsField>
                        )}

                        {account.routingNumber && (
                            <AccountDetailsField title="Routing number">
                                <FinancialAccountRoutingNumbers
                                    account={account}
                                />
                            </AccountDetailsField>
                        )}

                        {account.sourceType ===
                        FinancialAccountSourceType.PLAID ? (
                            <AccountDetailsField
                                title={
                                    isCardAccount(account)
                                        ? "Card number"
                                        : "Account number"
                                }
                            >
                                {accountNumber}
                            </AccountDetailsField>
                        ) : (
                            <FinancialAccountInfo account={account} />
                        )}

                        {account.sourceType ===
                        FinancialAccountSourceType.GUSTO ? (
                            <GustoAccountDetails account={account} />
                        ) : null}
                    </main>
                </section>
            )}

            {connection && (
                <section className="account-details__section">
                    <header className="account-details__section__header">
                        <h4>Connection</h4>
                    </header>

                    <main className="account-details__section__content">
                        <AccountDetailsField
                            title={`Connected to ${connection.connectionProvider}`}
                        >
                            <div className="account-details__connection__content">
                                <AccountConnectionStatus
                                    connection={connection}
                                    account={account}
                                />

                                <Switch
                                    active={
                                        !account.disabled && !connection?.error
                                    }
                                    loading={
                                        enableAccount.isLoading ||
                                        disableAccount.isLoading
                                    }
                                    onChanged={handleChangeAccountEnabled}
                                    size="sm"
                                />
                            </div>
                        </AccountDetailsField>

                        {connection?.user && (
                            <AccountDetailsField title="Integration created by">
                                {isConnectionOwner(connection, user)
                                    ? "You"
                                    : connection.user.name}
                                {connection.createdAt && (
                                    <>
                                        {" "}
                                        on{" "}
                                        {format(
                                            connection.createdAt,
                                            fullDateFullYearFormat,
                                        )}
                                    </>
                                )}
                            </AccountDetailsField>
                        )}
                    </main>
                </section>
            )}
            {isAdmin && (
                <AccountDetailsField>
                    <RemoveFinancialAccount
                        account={account}
                        variant="link"
                        className="account-details__remove-account danger pl-0"
                        size="sm"
                    >
                        Remove account
                    </RemoveFinancialAccount>

                    {account.sourceType === FinancialAccountSourceType.GUSTO ? (
                        <SyncGustoConnection
                            entity={entity}
                            variant="link"
                            size="sm"
                            className="account-details__resync-account pl-0"
                        >
                            Sync account
                        </SyncGustoConnection>
                    ) : null}
                </AccountDetailsField>
            )}
        </div>
    );
};
