import React from "react";
import styles from "./AutopilotCardSection.module.scss";
import { animated } from "@react-spring/web";
import classNames from "classnames";

interface Props {
    header: string;
    inOpened?: boolean;
    isCompleted?: boolean;
    style?: React.CSSProperties;
}

export const AutopilotCardSection: React.FC<Props> = ({
    header,
    style,
    inOpened,
    isCompleted,
    children,
}) => {
    return (
        <animated.section
            className={classNames(styles.section, {
                [styles.inOpened]: inOpened,
                [styles.isCompleted]: isCompleted,
            })}
            style={style}
        >
            <header>{header}</header>
            <main>{children}</main>
        </animated.section>
    );
};
