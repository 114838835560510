import React from "react";
import { AnnualBillingProvider } from "./AnnualBilling.context";
import styles from "./AnnualBilling.module.scss";
import { UsageSection } from "./UsageSection/UsageSection";
import { FAQSection } from "./FAQSection/FAQSection";
import { CurrentTier } from "../CurrentTier/CurrentTier";
import { PaymentDetails } from "./PaymentDetails/PaymentDetails";

export const SubscribedView: React.FC = () => {
    return (
        <AnnualBillingProvider>
            <div className={styles.annualBilling}>
                <UsageSection />
                <CurrentTier />
                <PaymentDetails />
                <FAQSection />
            </div>
        </AnnualBillingProvider>
    );
};
