import { useMutation } from "react-query";
import { queryClient, stripeConnectionsQueryKey } from "../queryClient";
import { createStripeConnection, fixStripeConnection } from "../lib/stripe";
import { StripeConnection } from "../common/types/stripeConnection";
import { useDispatch } from "react-redux";
import {
    addNotification,
    updateConnectAccountState,
} from "../reducers/appState";
import { FinancialConnection } from "../common/types/financialConnection";
import { Entity } from "../common/types/entity";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
} from "../queries/entitiesAccounts";

interface StripeConnectionCreationPayload {
    code: string;
    defaultEntity?: Entity;
}
export function useStripeConnectionCreationMutation(onSuccess?: () => void) {
    const dispatch = useDispatch();
    return useMutation(
        ({ code, defaultEntity }: StripeConnectionCreationPayload) =>
            createStripeConnection({
                code,
                defaultEntityId: defaultEntity?.id,
            }),
        {
            onSuccess: (connection) => {
                queryClient.setQueryData(stripeConnectionsQueryKey, [
                    ...(queryClient.getQueryData<StripeConnection[]>(
                        stripeConnectionsQueryKey,
                    ) ?? []),
                    connection,
                ]);
                addFinancialConnectionInQueryData(queryClient, connection);
                if (!connection.accounts[0].financialAccount.entity) {
                    dispatch(
                        updateConnectAccountState({
                            isConnecting: false,
                            connection: connection,
                            duplicates: [],
                        }),
                    );
                } else {
                    dispatch(
                        addNotification({
                            message: `Stripe account connected`,
                            type: "success",
                        }),
                    );
                }
                onSuccess?.();
            },
        },
    );
}
interface FixStripeConnectionPayload {
    connection: FinancialConnection;
    code: string;
}
export function useFixStripeConnectionsMutation(onSuccess?: () => unknown) {
    return useMutation(
        (payload: FixStripeConnectionPayload) =>
            fixStripeConnection(payload.connection.id, { code: payload.code }),
        {
            onSuccess: (connected) => {
                if (!connected) {
                    return;
                }
                clearConnectionErrorInQueryData(queryClient, connected.id);
                onSuccess?.();
                queryClient.setQueryData<FinancialConnection[] | undefined>(
                    stripeConnectionsQueryKey,
                    (connections) =>
                        connections?.map((c) =>
                            c.id === connected.id
                                ? {
                                      ...c,
                                      accounts: connected.accounts,
                                      error: null,
                                  }
                                : c,
                        ) ?? [],
                );
            },
        },
    );
}
