import { Form, Formik, FormikConfig } from "formik";
import React, { useState } from "react";
import { object, string } from "yup";
import { submitHelper } from "../../helpers/form";
import { simulatePlaidError } from "../../lib/simulations";
import { FormRow } from "../forms/FormRow";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";

interface FormSchema {
    ownerEmail: string;
}

export const SimulatePlaidAccountError: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            ownerEmail: "",
        },
        validationSchema: object().shape({
            ownerEmail: string().required().email().label("E-mail"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (body, { resetForm }) => {
                await simulatePlaidError(body);
                resetForm();
            },
        }),
    };

    return (
        <section>
            <h3>Plaid Account Error</h3>

            <Formik {...form}>
                <Form>
                    <FormRow fieldName="ownerEmail" label="Owner E-mail" />
                    <ButtonWithLoader loading={loading} type="submit">
                        Submit
                    </ButtonWithLoader>
                </Form>
            </Formik>
        </section>
    );
};
