import React from "react";
import { OnboardingStepActions } from "../OnboardingStepActions";
import { Button } from "react-bootstrap";
import styles from "./Receipts.module.scss";
import { PhoneMockup } from "./PhoneMockup";
import { Conversation } from "./Conversation";
import { CheckIcon } from "../../../icons";
import classNames from "classnames";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { useFetchAsset } from "../../../hooks/useFetchAsset";

interface Props {
    onBack(): void;
    onNext(): void;
}

export const ReceiptsFinish: React.FC<Props> = ({ onBack, onNext }) => {
    const { newOnboarding } = useTypedFlags();
    const { data: receiptsFinishVideo } = useFetchAsset(
        "/images/receipts/receiptsFinish.webm",
    );

    return (
        <section className={styles.container}>
            <main className={styles.content}>
                <h1 className="onboarding__title mb-5">Text sent!</h1>

                <p className="onboarding__headline">
                    Check your phone to add Kick to your Contacts and text Kick
                    photos of your receipts.
                </p>
                <p className="onboarding__headline">
                    You can also forward receipts anytime to{" "}
                    <a href="mailto:receipts@kick.co">receipts@kick.co</a> to
                    get them all handled.
                </p>
            </main>

            <OnboardingStepActions
                onBack={onBack}
                className={classNames(styles.buttons, "mt-0")}
                btnSize={newOnboarding ? undefined : "xl"}
            >
                <Button
                    variant="primary"
                    size={newOnboarding ? null : ("xl" as any)}
                    onClick={onNext}
                >
                    Next
                </Button>
            </OnboardingStepActions>

            <aside className={styles.figure}>
                {newOnboarding ? (
                    <video
                        autoPlay
                        muted
                        loop={false}
                        className={styles.video}
                        src={receiptsFinishVideo}
                    />
                ) : (
                    <PhoneMockup>
                        <Conversation />

                        <aside className={styles.conversationOverlay}>
                            <span
                                className={styles.conversationOverlayBackdrop}
                            />
                            <span className={styles.conversationOverlayContent}>
                                <CheckIcon />
                            </span>
                        </aside>
                    </PhoneMockup>
                )}
            </aside>
        </section>
    );
};
