import React from "react";
import { AccountsContext } from "./Accounts.context";
import { useAccounts } from "../../../hooks/useAccounts";

interface Props {
    entityId: number;
}

export const AccountsProvider: React.FC<Props> = ({ entityId, children }) => {
    const value = useAccounts(entityId);

    return (
        <AccountsContext.Provider value={value}>
            {children}
        </AccountsContext.Provider>
    );
};
