import classNames from "classnames";
import { isString } from "lodash";
import React, {
    Children,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Button } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import { TriangleIcon } from "../../../icons";
import { FilterSearch } from "../../general/FilterSearch/FilterSearch";
import {
    PopoverContainer,
    PopoverContainerHandle,
} from "../../general/PopoverContainer";
import { SelectDropdown } from "../../general/SelectDropdown/SelectDropdown";
import { SelectDropdownDivider } from "../../general/SelectDropdown/SelectDropdownDivider";
import { SelectDropdownOption } from "../../general/SelectDropdown/SelectDropdownOption";
import "./CustomSelect.scss";
import {
    CustomSelectDefaultTrigger,
    CustomSelectDefaultTriggerProps,
} from "./CustomSelectDefaultTrigger";

function getSearchableOption<TValue extends string | number = string>(
    option: CustomSelectOption<TValue>,
): string {
    if (option.searchableLabel) {
        return option.searchableLabel;
    } else if (isString(option.label)) {
        return option.label;
    } else {
        return String(option.value);
    }
}

export interface CustomSelectOption<TValue extends string | number = string> {
    value: TValue;
    label?: React.ReactNode;
    searchableLabel?: string;
    className?: string;
    disabled?: boolean;
    suboptions?: Array<CustomSelectOption<TValue>>;
    suboptionsSelectProps?: Partial<CustomSelectProps<TValue>>;
    onSelected?(value: TValue): void;
    renderAddon?: (option: CustomSelectOption<TValue>) => React.ReactNode;
}

export interface CustomSelectProps<TValue extends string | number = string> {
    onSelected(value: TValue): void;
    onSearch?(value: string): void;
    dropdownKey: string | number;
    options: CustomSelectOption<TValue>[];
    customOptions?: CustomSelectOption<TValue>[];
    shouldShowCustomOptionsWhileSearching?: boolean;
    customOptionsPlacement?: "top" | "bottom";
    value?: TValue;
    searchable?: boolean;
    placement?: Placement;
    label?: string;
    insideLabel?: boolean;
    empty?: React.ReactNode;
    className?: string;
    popoverClassName?: string;
    dropdownClassName?: string;
    children?: React.ReactNode | ((open: boolean) => React.ReactNode);
    size?: CustomSelectDefaultTriggerProps["size"];
    placeholder?: string;
    disabled?: boolean;
    fullWidthDropdown?: boolean;
    appendTo?: HTMLElement;
    onShowPopover?: () => void;
    onHidePopover?: () => void;
    canShowPopover?: boolean;
}

export const CustomSelect = <T extends string | number>({
    onSelected,
    onSearch = () => {},
    dropdownKey,
    options,
    customOptions,
    shouldShowCustomOptionsWhileSearching = false,
    customOptionsPlacement = "top",
    value,
    searchable,
    placement,
    label,
    insideLabel,
    empty = "No matching items",
    className,
    popoverClassName,
    dropdownClassName,
    size,
    placeholder,
    disabled,
    fullWidthDropdown,
    children,
    appendTo,
    onShowPopover: parentOnShow = () => {},
    onHidePopover: parentOnHide = () => {},
    canShowPopover = true,
}: CustomSelectProps<T>) => {
    const [search, setSearch] = useState("");
    const [opened, setOpened] = useState(false);
    const searchRef = useRef<HTMLInputElement>();
    const popoverRef = useRef<PopoverContainerHandle>(null);
    const containerRef = useRef<HTMLDivElement>();

    const onShow = useCallback(() => {
        setOpened(true);
        parentOnShow();
    }, [parentOnShow]);
    const onHide = useCallback(() => {
        setOpened(false);
        parentOnHide();
    }, [parentOnHide]);

    const displayedOptions = useMemo(() => {
        return options.filter((option) =>
            getSearchableOption(option)
                .toLowerCase()
                .includes(search.toLowerCase()),
        );
    }, [search, options]);

    const closePopover = useCallback(() => {
        popoverRef.current?.close();
    }, []);

    useEffect(() => {
        if (canShowPopover === false) {
            closePopover();
        }
    }, [closePopover, canShowPopover]);

    const handleSelected = useCallback(
        (newValue) => {
            closePopover();
            setSearch("");
            onSelected(newValue);
        },
        [closePopover, onSelected],
    );

    const handleSearch = useCallback(
        (newValue) => {
            setSearch(newValue);
            onSearch(newValue);
        },
        [onSearch],
    );

    const selectedOption = useMemo(
        () => options.find((option) => option.value === value),
        [value, options],
    );
    const providedCustomTrigger =
        children instanceof Function || Children.count(children) > 0;

    let trigger: React.ReactNode;

    if (!providedCustomTrigger) {
        trigger = (
            <CustomSelectDefaultTrigger
                value={
                    selectedOption?.label ??
                    selectedOption?.value ??
                    placeholder
                }
                label={label}
                insideLabel={insideLabel}
                size={size}
                disabled={disabled}
            />
        );
    } else if (children instanceof Function) {
        trigger = children(opened);
    } else {
        trigger = children;
    }

    const hasResults = displayedOptions.length > 0;
    const hasCustomOptions = !!customOptions && customOptions.length > 0;
    const isSearching = !!search;
    const shouldShowCustomOptions =
        hasCustomOptions &&
        (!isSearching || shouldShowCustomOptionsWhileSearching);

    const content = useMemo(() => {
        if (!hasResults && !shouldShowCustomOptions) {
            return <div className="custom-dropdown__empty">{empty}</div>;
        }

        const results = displayedOptions.map((option) => (
            <CustomSelectDropdownOption
                key={option.value}
                option={option}
                handleSelected={handleSelected}
            />
        ));

        if (!shouldShowCustomOptions) {
            return results;
        }

        const customSelectOptions = customOptions.map((option) => (
            <CustomSelectDropdownOption
                key={option.value}
                option={option}
                handleSelected={handleSelected}
            />
        ));

        if (customOptionsPlacement === "bottom") {
            return (
                <>
                    {results}
                    <SelectDropdownDivider />
                    {customSelectOptions}
                </>
            );
        }

        return (
            <>
                {customSelectOptions}
                <SelectDropdownDivider />
                {results}
            </>
        );
    }, [
        customOptions,
        customOptionsPlacement,
        displayedOptions,
        empty,
        handleSelected,
        hasResults,
        shouldShowCustomOptions,
    ]);

    return (
        <div
            ref={containerRef as any}
            className={classNames("custom-dropdown", className, {
                "custom-dropdown--opened": opened,
            })}
        >
            <PopoverContainer
                ref={popoverRef as any}
                container={appendTo}
                trigger={trigger}
                id={`custom_select_${dropdownKey}`}
                offset={providedCustomTrigger ? 4 : 0}
                placement={placement}
                onShow={onShow}
                onHide={onHide}
                disabled={disabled}
                popoverClass={popoverClassName}
                maxDropdownWidth={
                    fullWidthDropdown && containerRef.current
                        ? containerRef?.current?.clientWidth
                        : undefined
                }
            >
                <SelectDropdown
                    prepend={
                        searchable ? (
                            <FilterSearch
                                value={search}
                                onChange={handleSearch}
                                inputRef={searchRef}
                                focus
                            />
                        ) : undefined
                    }
                    className={dropdownClassName}
                >
                    {content}
                </SelectDropdown>
            </PopoverContainer>
        </div>
    );
};

function CustomSelectDropdownOption<TValue extends string | number = string>({
    option,
    handleSelected,
}: CustomSelectDropdownOptionProps<TValue>) {
    const {
        value,
        label,
        className,
        disabled,
        suboptions = [],
        suboptionsSelectProps,
        renderAddon,
    } = option;

    const onClick = useCallback(() => {
        if (suboptions.length) {
            return;
        }
        handleSelected(value);
    }, [handleSelected, suboptions.length, value]);

    const renderedOption = (
        <SelectDropdownOption
            key={value}
            onClick={onClick}
            className={`select-dropdown-option ${className}`}
            disabled={disabled}
        >
            <div className="select-dropdown-content">
                <span>{label ?? value}</span>
                {suboptions.length > 0 && (
                    <Button
                        variant="link"
                        onClick={onClick}
                        className="ml-1 suboptions-btn"
                    >
                        <TriangleIcon />
                    </Button>
                )}
                {renderAddon?.({ value, onSelected: handleSelected })}
            </div>
        </SelectDropdownOption>
    );

    if (suboptions.length > 0 || renderAddon) {
        return (
            <CustomSelect
                placement="right-start"
                dropdownKey={value}
                {...suboptionsSelectProps}
                onSelected={handleSelected}
                options={suboptions}
            >
                {renderedOption}
            </CustomSelect>
        );
    }

    return renderedOption;
}

interface CustomSelectDropdownOptionProps<
    TValue extends string | number = string,
> {
    readonly handleSelected: (newValue: TValue) => void;
    readonly option: CustomSelectOption<TValue>;
}
