import { EntityScopedRequestDto } from "../common/dto/common/entity-scoped-request.dto";
import { CreateManualJournalEntryDto } from "../common/dto/manualJournalEntry/create-manual-journal-entry.dto";
import { Account } from "../common/types/domains/accounting/account";
import { backendClient, unwrapResponse } from "./backendClient";
import {
    GetJournalEntryResponseObject,
    UpsertJournalEntryResponseObject,
    CreateJournalEntryResponseObject,
} from "../common/types/domains/accounting/journalEntry";

export async function getAccounts(
    query: EntityScopedRequestDto,
): Promise<Account[]> {
    return unwrapResponse(
        await backendClient.get("/accounting/accounts", {
            params: query,
        }),
    );
}

export async function createManualJournalEntry(
    entityId: number,
    data: CreateManualJournalEntryDto,
): Promise<CreateJournalEntryResponseObject> {
    return unwrapResponse(
        await backendClient.post(
            `/accounting/journal-entries/manual/${entityId}`,
            data,
        ),
    );
}

export async function getJournalEntry(
    id: string,
    entityId: number,
): Promise<GetJournalEntryResponseObject> {
    return unwrapResponse(
        await backendClient.get(
            `/accounting/journal-entries/${entityId}/${id}`,
        ),
    );
}

export async function upsertJournalEntry(
    entityId: number,
    journalEntryId: string,
    data: CreateManualJournalEntryDto,
): Promise<UpsertJournalEntryResponseObject> {
    return unwrapResponse(
        await backendClient.put(
            `/accounting/journal-entries/manual/${entityId}/${journalEntryId}`,
            data,
        ),
    );
}

export async function deleteJournalEntry(
    entityId: number,
    journalEntryId: string,
): Promise<void> {
    return unwrapResponse(
        await backendClient.delete(
            `/accounting/journal-entries/manual/${entityId}/${journalEntryId}`,
        ),
    );
}
