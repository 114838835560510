import { useMemo } from "react";
import { TAX_SAVING_RULES, TaxRule } from "../../../common/taxSavingRules";
import { Category } from "../../../common/types/category";
import { TransactionsFilters } from "../filters/lib";
import { SelectedTransaction } from "./transactionsBulkActionsContext";

export function useTaxSavingRulesForBulkActions(
    selection: Record<number, SelectedTransaction>,
    filters: TransactionsFilters | null,
): TaxRule | null {
    return useMemo(() => {
        if (filters) {
            return getTaxSavingRuleForFilters(filters);
        } else {
            return getTaxSavingRuleForSelection(selection);
        }
    }, [filters, selection]);
}

export function getTaxSavingRuleForSelection(
    selection: Record<number, SelectedTransaction>,
): TaxRule | null {
    const selected = Object.values(selection);

    if (selected.length === 0) {
        return null;
    }

    if (selected.some((t) => t.isIgnored)) {
        return null;
    }

    const allBusiness = selected.every((t) => t.isBusiness);
    const sameCategory = selected.every(
        (t) => t.category === selected[0].category,
    );

    if (allBusiness && sameCategory) {
        return TAX_SAVING_RULES[2023][selected[0].category];
    } else {
        return null;
    }
}

export function getTaxSavingRuleForFilters(
    filters: TransactionsFilters,
): TaxRule | null {
    if (filters.category?.length !== 1) {
        return null;
    }

    return TAX_SAVING_RULES[2023][(filters.category[0] as Category).id];
}
