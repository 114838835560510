import React from "react";
import { FormRow, FormRowProps } from "./FormRow";
import { FormCheckbox } from "./FormCheckbox/FormCheckbox";

interface Props extends FormRowProps {
    inline?: boolean;
    options: string[];
    labels?: Record<string, string>;
    captions?: Record<string, string>;
    idPrefix: string;
    children?: never;
}

export const FormRowRadios: React.FC<Props> = ({
    inline = false,
    options,
    labels,
    fieldName,
    idPrefix,
    captions,
    ...props
}) => {
    return (
        <FormRow fieldName={fieldName} {...props}>
            {({ setValue }, { value }) => (
                <>
                    {options.map((option) => (
                        <FormCheckbox
                            type="radio"
                            name={fieldName}
                            label={labels?.[option] ?? option}
                            caption={captions?.[option]}
                            isChecked={value === option}
                            value={value}
                            key={option}
                            handleChange={() => setValue(option)}
                        />
                    ))}
                </>
            )}
        </FormRow>
    );
};
