import { ReportFilters } from "../../../common/types/filters/reports";
import { useResetFilters } from "../../general/ActiveFilters/useResetFilters";
import { ActiveCashFlowFilter } from "./lib";

const resetPayload: Record<ActiveCashFlowFilter, Partial<ReportFilters>> = {
    category: { category: undefined },
    date: { start: undefined, end: undefined },
};

export function useResetCashFlowPageFilter(
    onChange: (filters: Partial<ReportFilters>) => void,
    filterIds: ActiveCashFlowFilter | ActiveCashFlowFilter[],
) {
    return useResetFilters(resetPayload, onChange, filterIds);
}
