import React from "react";
import { useEntity } from "../../entity/useEntity";
import { useQuery } from "react-query";
import { getEntityPayrolls } from "../../../lib/payroll";
import { format } from "date-fns";
import { Payroll as PayrollType } from "../../../common/types/payroll";
import { currencyFormatter } from "../../../common/helpers/currency";
import { Collapsible } from "../../general/Collapsible/Collapsible";
import css from "./Payroll.module.scss";
import { PayrollDetails } from "./PayrollDetails";
import { fullDateFormat } from "../../../common/helpers/date";
import { getPayrollQueryKey } from "../../../queryClient";
import classNames from "classnames";
import { SyncGustoConnection } from "./SyncGustoConnection";

function printTotalPayroll(payroll: PayrollType) {
    if (!payroll.totals) return null;
    return currencyFormatter.format(
        payroll.totals.companyDebit +
            payroll.totals.benefits +
            payroll.totals.employeeBenefitsDeductions,
    );
}
function printPayrollPeriod(payroll: PayrollType) {
    return (
        format(payroll.payPeriodStartDate, fullDateFormat) +
        " - " +
        format(payroll.payPeriodEndDate, fullDateFormat)
    );
}

export const Payroll: React.FC = () => {
    const entity = useEntity();
    const { data: payrolls } = useQuery(getPayrollQueryKey(entity.id), () =>
        getEntityPayrolls(entity),
    );

    return (
        <section className="entity-payrolls">
            <header className="d-flex justify-content-between">
                <div>
                    <small className="mb-3 d-block">{entity.name}</small>
                    <h3 className="settings__heading">Payrolls</h3>
                </div>
                <SyncGustoConnection
                    entity={entity}
                    className={classNames(
                        "btn btn-secondary btn-sm",
                        css.syncButton,
                    )}
                >
                    Sync Payrolls
                </SyncGustoConnection>
            </header>

            <main className={css.payrollContainer}>
                {payrolls
                    ? payrolls.map((payroll) => (
                          <Collapsible
                              key={payroll.id}
                              className={css.payrollItem}
                              header={
                                  <div className="d-flex justify-content-between align-items-center">
                                      <span className="period">
                                          {printPayrollPeriod(payroll)}
                                      </span>
                                      <span className={css.payrollTotal}>
                                          {printTotalPayroll(payroll)}
                                      </span>
                                  </div>
                              }
                              stacked
                          >
                              <PayrollDetails payroll={payroll} />
                          </Collapsible>
                      ))
                    : null}
            </main>
        </section>
    );
};
