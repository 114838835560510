import { backendClient } from "./backendClient";

export async function trackPage(path: string) {
    await backendClient.post("/t/p", { p: path });
}

export async function trackEvent(
    name: string,
    properties: Record<string, unknown> = {},
) {
    await backendClient.post("/t/e", { name, properties });
}
