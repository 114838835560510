import React, { useMemo } from "react";
import {
    TransactionsDrillDownContext,
    transactionsDrillDownModalDefaultParams,
    TransactionsDrillDownModalParams,
} from "./transactionsDrillDown.context";
import { Taxonomy } from "../../common/categories";

interface Props {}

export const TransactionsDrillDownContextProvider: React.FC<Props> = ({
    children,
}) => {
    const [params, setParams] =
        React.useState<TransactionsDrillDownModalParams>(
            transactionsDrillDownModalDefaultParams,
        );

    const value = useMemo(() => {
        return {
            params,
            open: (label: string, category: Taxonomy) => {
                setParams({
                    label,
                    category,
                });
            },
            close: () => {
                setParams(transactionsDrillDownModalDefaultParams);
            },
        };
    }, [params]);
    return (
        <TransactionsDrillDownContext.Provider value={value}>
            {children}
        </TransactionsDrillDownContext.Provider>
    );
};
