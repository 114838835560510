import isNumber from "lodash/isNumber";
import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { Taxonomy, TransactionDirectionType } from "../../../common/categories";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { useCustomCategories } from "../../../hooks/useCustomCategories";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { AIIcon } from "../../../icons";
import { disableCategorizationRule } from "../../../lib/categorizationRules";
import {
    bulkUpdateTransactions,
    getSimilarTransactions,
} from "../../../lib/transactions";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { CategorizationToastData } from "../../general/ToastMessages/lib/categorization";
import { BaseToastMessageData } from "../../general/ToastMessages/lib/common";
import { CategoryIcon } from "../CategoryIcon";
import { invalidateTransactionDetailsQueries } from "../TransactionDetails/useTransactionDetailsQuery";
import { invalidateTransactionsQueries } from "../useTransactionsQuery";
import "./TransactionCategorizationToast.scss";

type Props = CategorizationToastData & BaseToastMessageData;

export const TransactionCategorizationToast: React.FC<Props> = ({
    transaction,
    newCategory,
    hide,
}) => {
    const labelGetter = useCategoryLabelGetter();
    const { retroactiveSimilarity } = useTypedFlags();
    const customCategories = useCustomCategories();
    const customCategory = useMemo(
        () =>
            isNumber(newCategory)
                ? customCategories.find(
                      (category) => category.id === newCategory,
                  )
                : undefined,
        [newCategory, customCategories],
    );

    const dontLearn = useMutation(
        () => disableCategorizationRule(transaction),
        {
            onSuccess: () => hide(),
        },
    );

    const similarQuery = useQuery(
        ["similarTransactions", transaction.id, retroactiveSimilarity],
        () =>
            retroactiveSimilarity
                ? getSimilarTransactions(transaction)
                : { ids: [] },
    );
    const similarTransactionsIds = similarQuery.data?.ids;

    const updateSimilar = useMutation(
        () =>
            bulkUpdateTransactions({
                transactions: similarTransactionsIds?.map((transactionId) => ({
                    transactionId,
                    category: customCategory
                        ? undefined
                        : (newCategory as Taxonomy),
                    customCategoryId: customCategory?.id,
                })),
                similarTransactionId: transaction.id,
            }),
        {
            onSuccess: () => {
                void invalidateTransactionsQueries();
                void invalidateTransactionDetailsQueries();
            },
        },
    );

    const foundSimilarTransactions =
        similarTransactionsIds && similarTransactionsIds.length > 0;

    const categoryIcon = (
        <CategoryIcon
            category={
                customCategory?.mainCategoryId ?? (newCategory as Taxonomy)
            }
            transactionDirection={
                transaction.amount > 0
                    ? TransactionDirectionType.incoming
                    : TransactionDirectionType.outgoing
            }
        />
    );
    const categoryLabel =
        customCategory?.label ?? labelGetter(newCategory as Taxonomy);

    return (
        <div className="categorization-toast-message">
            <p>
                Kick AI{" "}
                <AIIcon className="icon-color-blue-600 categorization-toast-message__ai-icon" />{" "}
                will learn to categorize{" "}
                <strong>{transaction.description}</strong> as{" "}
                <span className="categorization-toast-message__category">
                    {categoryIcon} <strong>{categoryLabel}</strong>
                </span>{" "}
                going forward.
            </p>

            {updateSimilar.isSuccess && (
                <p>
                    <strong>{similarTransactionsIds?.length}</strong> similar
                    transactions were categorized.
                </p>
            )}
            {!updateSimilar.isSuccess && foundSimilarTransactions && (
                <p>
                    We've found <strong>{similarTransactionsIds.length}</strong>{" "}
                    similar transactions. Click <strong>Categorize</strong> to
                    update them to{" "}
                    <span className="categorization-toast-message__category">
                        {categoryIcon} <strong>{categoryLabel}</strong>
                    </span>
                </p>
            )}

            <div className="d-flex justify-content-between">
                <ButtonWithLoader
                    variant="secondary"
                    size="sm"
                    mutation={dontLearn}
                >
                    Don't Learn
                </ButtonWithLoader>

                {!updateSimilar.isSuccess && foundSimilarTransactions && (
                    <ButtonWithLoader
                        variant="secondary"
                        size="sm"
                        mutation={updateSimilar}
                    >
                        Categorize
                    </ButtonWithLoader>
                )}
            </div>
        </div>
    );
};
