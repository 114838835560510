import { Form, Formik, FormikConfig } from "formik";
import React, { useState } from "react";
import { object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { FormRow } from "../../forms/FormRow";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { LoginWithGoogleButton } from "../GoogleAuth/LoginWithGoogleButton";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { HorizontalLine } from "../../general/HorizontalLine/HorizontalLine";

interface FormSchema {
    phoneNumberOrEmail: string;
}

export interface LoginFlowStartProps {
    onRequestCode(phoneNumberOrEmail: string): Promise<any>;
    onSuccess(phoneNumberOrEmail: string): void;
}

export const LoginFlowStart: React.FC<LoginFlowStartProps> = ({
    onRequestCode,
    onSuccess,
}) => {
    const [loading, setLoading] = useState(false);
    const { googleLogin } = useTypedFlags();

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            phoneNumberOrEmail: "",
        },
        validationSchema: object().shape({
            phoneNumberOrEmail: string()
                .required()
                .test({
                    test: (value) =>
                        string().trim().email().isValidSync(value) ||
                        string()
                            .trim()
                            .matches(/^\d{10}$/)
                            .isValidSync(normalizeValue(value)),
                }),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ phoneNumberOrEmail }) => {
                const normalized = normalizeValue(phoneNumberOrEmail);

                const requestSuccessful = await onRequestCode(normalized);
                if (requestSuccessful) {
                    onSuccess(normalized);
                }
            },
        }),
    };

    return (
        <>
            <h2 className="login__heading">Log in to Kick</h2>
            <section className="login__form">
                {googleLogin && (
                    <>
                        <LoginWithGoogleButton
                            variant="secondary"
                            block
                            size={"xl" as any}
                        >
                            Sign in with Google
                        </LoginWithGoogleButton>

                        <HorizontalLine className="my-4">or</HorizontalLine>
                    </>
                )}

                <Formik {...form}>
                    {({ isValid, dirty }) => (
                        <Form>
                            <FormRow
                                fieldName="phoneNumberOrEmail"
                                placeholder="Phone number or email"
                                className=""
                                showErrorMessage={false}
                            />
                            <footer className="d-flex justify-content-center">
                                <ButtonWithLoader
                                    loading={loading}
                                    type="submit"
                                    disabled={!isValid || !dirty}
                                    size={"xl" as any}
                                >
                                    Log in
                                </ButtonWithLoader>
                            </footer>
                        </Form>
                    )}
                </Formik>
            </section>
        </>
    );
};

function normalizeValue(value: string) {
    return value.includes("@")
        ? value.trim()
        : value.trim().replace(/\D/g, "").slice(-10);
}
