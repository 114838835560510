import React, { CSSProperties } from "react";
import classNames from "classnames";
import "./CustomProgressBar.scss";

interface Props {
    value: number;
    max: number;
    className?: string;
    size?: "lg" | "sm";
    renderValue?: (value: number, max: number) => React.ReactNode;
    renderMax?: (max: number, value: number) => React.ReactNode;
}

export const CustomProgressBar: React.FC<Props> = ({
    value,
    max,
    className,
    size = "lg",
    renderValue,
    renderMax,
}) => {
    const percentage = (value / max) * 100;

    return (
        <div
            className={classNames(
                "custom-progress-bar",
                `custom-progress-bar--${size}`,
                className,
                {
                    "custom-progress-bar--max": value > max,
                },
            )}
            style={
                {
                    "--progress-bar-percentage": `${percentage}%`,
                } as CSSProperties
            }
        >
            {renderValue && (
                <span data-testid="business-expenses-used">
                    {renderValue(value, max)}
                </span>
            )}
            {renderMax && (
                <span data-testid="business-expenses-used-max">
                    {renderMax(max, value)}
                </span>
            )}
        </div>
    );
};
