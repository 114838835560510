import { FinancialAccount } from "../common/types/financialAccount";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { Entity } from "../common/types/entity";
import {
    plaidConnectionsQueryKey,
    queryClient,
    financialConnectionsQueryKey,
} from "../queryClient";
import { addNotification, updateFinancialAccount } from "../reducers/appState";
import { financialAccountsRest } from "../lib/restClient";
import { PlaidConnection } from "../common/types/plaidConnection";
import {
    disableFinancialAccount,
    enableFinancialAccount,
} from "../lib/financialAccount";
import {
    removeFinancialAccountFromQueryData,
    updateFinancialAccountInQueryData,
} from "../queries/entitiesAccounts";
import {
    addUnassignedAccountInQueryData,
    removeUnassignedAccountFromQueryData,
} from "../queries/unassignedAccounts";
import { Dispatch } from "redux";

function doFinancialAccountUpdate(
    account: FinancialAccount,
    dispatch: Dispatch,
) {
    updateFinancialAccountInQueryData(queryClient, account);
    queryClient.setQueryData<PlaidConnection[] | undefined>(
        plaidConnectionsQueryKey,
        (connections) =>
            connections?.map((connection) => ({
                ...connection,
                accounts: connection.accounts?.map((a) => ({
                    ...a,
                    financialAccount:
                        a.financialAccount.id === account.id
                            ? account
                            : a.financialAccount,
                })),
            })) ?? [],
    );
    if (account.entityId) {
        removeUnassignedAccountFromQueryData(queryClient, account);
    } else {
        addUnassignedAccountInQueryData(queryClient, account);
    }
    dispatch(updateFinancialAccount(account));
}

export function useChangeFinancialAccountEntityMutation(
    account: FinancialAccount,
    onSuccess?: () => void,
) {
    const dispatch = useDispatch();

    return useMutation<FinancialAccount, unknown, Entity, Entity | null>(
        (entity) =>
            financialAccountsRest.update(account.id, {
                entityId: entity.id,
            }),
        {
            onMutate: async (entity) => {
                const previousValue = account.entity;
                doFinancialAccountUpdate(
                    {
                        ...account,
                        entity,
                        entityId: entity.id,
                        isBusiness: entity.isBusiness,
                    },
                    dispatch,
                );

                return previousValue;
            },
            onSuccess: async (updatedAccount) => {
                doFinancialAccountUpdate(updatedAccount, dispatch);
                onSuccess?.();
                await queryClient.invalidateQueries(
                    financialConnectionsQueryKey,
                );
            },
            onError: (_1, _2, oldEntity) => {
                if (oldEntity !== undefined) {
                    doFinancialAccountUpdate(
                        {
                            ...account,
                            entity: oldEntity,
                            entityId: oldEntity?.id ?? null,
                            isBusiness: oldEntity?.isBusiness ?? null,
                        },
                        dispatch,
                    );
                }

                dispatch(
                    addNotification({
                        message: "Failed to change account company",
                        type: "danger",
                    }),
                );
            },
        },
    );
}

export function useFinancialAccountRemovalMutation(
    account: FinancialAccount,
    onRemoved?: () => void,
) {
    return useMutation(() => financialAccountsRest.delete(account.id), {
        onSuccess: async () => {
            removeFinancialAccountFromQueryData(queryClient, account);
            removeUnassignedAccountFromQueryData(queryClient, account);
            onRemoved?.();
            await queryClient.invalidateQueries(financialConnectionsQueryKey);
        },
    });
}

export function useAccountEnablingMutation(account: FinancialAccount) {
    const dispatch = useDispatch();

    return useMutation(() => enableFinancialAccount(account.id), {
        onSuccess: (updatedAccount) => {
            doFinancialAccountUpdate(
                {
                    ...updatedAccount,
                    integrationAccounts: account.integrationAccounts,
                },
                dispatch,
            );
        },
    });
}

export function useAccountDisablingMutation(
    account: FinancialAccount,
    onDisabled?: () => void,
) {
    const dispatch = useDispatch();

    return useMutation(() => disableFinancialAccount(account.id), {
        onSuccess: (updatedAccount) => {
            doFinancialAccountUpdate(
                {
                    ...updatedAccount,
                    integrationAccounts: account.integrationAccounts,
                },
                dispatch,
            );
            onDisabled?.();
        },
    });
}
