import { createContext } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { UploadingDocumentPlaceholder } from "../lib";
import { noop } from "../../../helpers/general";

export interface FinancialDocumentUploadContextValue {
    isDragActive: boolean;
    inputProps: DropzoneInputProps;
    rootProps: DropzoneRootProps;
    uploadingDocuments: UploadingDocumentPlaceholder[];
    updateUploads(
        cb: (
            prev: UploadingDocumentPlaceholder[],
        ) => UploadingDocumentPlaceholder[],
    ): void;
    clearUploads(): void;
    open(): void;
}

export const financialDocumentUploadContext =
    createContext<FinancialDocumentUploadContextValue>({
        isDragActive: false,
        inputProps: {},
        rootProps: {},
        open: noop,
        clearUploads: noop,
        updateUploads: noop,
        uploadingDocuments: [],
    });
