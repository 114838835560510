import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Modal } from "react-bootstrap";
import Scrollbars, { positionValues } from "react-custom-scrollbars-2";
import { useFormikContext } from "formik";
import classNames from "classnames";

import styles from "./JournalEntryModal.module.scss";
import { EditableTableBody } from "../editableTable/EditableTableBody";
import { AccountsProvider } from "../accountsContext/AccountsProvider";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { JournalEntryModalFormikConfig } from "./JournalEntryModal";
import { JournalEntryModalFooter } from "./JournalEntryModalFooter";
import { JournalEntryModalHeader } from "./JournalEntryModalHeader";
import { EditableTable } from "../editableTable/EditableTableContext";
import { calculateDebitAndCreditTotals } from "./JournalEntryModalUtils";
import { AddButton } from "../../general/AddButton/AddButton";
import {
    useAccountsSearchOptions,
    useIsJournalEntryEditable,
    useUpdateAndReturnNewLineCallback,
} from "./JournalEntryModalHooks";
import { useAccounts } from "../../../hooks/useAccounts";

interface Props {
    setFormEntityId: (entityId: number) => void;
    formEntityId: number;
    loading: boolean;
    onDelete: () => void;
}

export const JournalEntryModalContent: React.FC<Props> = ({
    setFormEntityId,
    formEntityId,
    loading,
    onDelete,
}) => {
    const tableFooterRef = useRef<HTMLDivElement>(null);
    const scrollbarsRef = useRef<Scrollbars>(null);
    const tableRef = useRef<HTMLDivElement>(null);

    const [tableTotalTranslateY, setTableTotalTranslateY] = useState(0);

    const [footerFulyVisible, setFooterFulyVisible] = useState(false);

    const { height, width } = useWindowDimensions();

    const footerHeight = useMemo(() => {
        if (tableFooterRef.current) {
            return tableFooterRef.current.getBoundingClientRect().height;
        }
        return 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableFooterRef.current, height, width]);

    const tableHeight = useMemo(() => {
        if (tableRef.current) {
            return tableRef.current.getBoundingClientRect().height;
        }
        return 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRef.current, height, width]);

    const onScroll = useCallback(
        (values: positionValues) => {
            if (scrollbarsRef.current) {
                if (scrollbarsRef.current.getValues().clientHeight <= 400) {
                    setFooterFulyVisible(true);
                    return;
                }
                const maxScroll = values.scrollHeight - values.clientHeight;

                const footerVisibilityScrollThreshold =
                    maxScroll - (values.scrollHeight - tableHeight);

                const footerVisibility =
                    values.scrollTop >=
                    footerVisibilityScrollThreshold + footerHeight;

                setTableTotalTranslateY(
                    -Math.max(
                        0,
                        values.scrollTop -
                            footerVisibilityScrollThreshold -
                            footerHeight,
                    ),
                );
                if (footerFulyVisible !== footerVisibility) {
                    setFooterFulyVisible(footerVisibility);
                }
            }
        },
        [scrollbarsRef, footerFulyVisible, footerHeight, tableHeight],
    );

    useEffect(() => {
        if (scrollbarsRef.current) {
            onScroll(scrollbarsRef.current.getValues());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollbarsRef.current, onScroll, height, width]);

    const formikContext = useFormikContext<JournalEntryModalFormikConfig>();
    const { values, setFieldValue } = formikContext;

    const lines = values.journalEntry.lines;
    const memo = values.journalEntry.memo;

    const debitAndCreditTotals = useMemo(() => {
        return calculateDebitAndCreditTotals(lines);
    }, [lines]);

    const linesRef = React.useRef(lines);

    React.useEffect(() => {
        linesRef.current = lines;
    }, [lines]);

    const addLines = useCallback(() => {
        const currentLines = linesRef.current;
        const newLines = [
            ...currentLines,
            ...new Array(20).fill(null).map(() => {
                return {
                    id: Math.random().toString(),
                    accountCode: "",
                    debitAmount: null,
                    creditAmount: null,
                    description: "",
                };
            }),
        ];
        setFieldValue(`journalEntry.lines`, newLines);
    }, [linesRef, setFieldValue]);

    const isEditable = useIsJournalEntryEditable(values);

    const { accountTypesWithAccounts } = useAccounts(formEntityId);

    const accountsSearchOptions = useAccountsSearchOptions(
        accountTypesWithAccounts,
    );

    const updateAndReturnNewLine = useUpdateAndReturnNewLineCallback({
        isEditable,
        accountsSearchOptions,
        memo,
        linesRef,
    });

    return (
        <AccountsProvider entityId={formEntityId}>
            <EditableTable
                updateAndReturnNewLine={updateAndReturnNewLine}
                targetLines={lines}
            >
                <>
                    <JournalEntryModalHeader
                        setFormEntityId={setFormEntityId}
                    />
                    <Modal.Body className={styles.modalBody}>
                        <Scrollbars
                            onScrollFrame={onScroll}
                            ref={scrollbarsRef}
                        >
                            <EditableTableBody
                                setFieldValue={setFieldValue}
                                tableRef={tableRef}
                                entityId={formEntityId}
                                footerFulyVisible={footerFulyVisible}
                                ref={tableFooterRef}
                                totals={debitAndCreditTotals}
                                scrollbarsRef={scrollbarsRef}
                                isEditable={isEditable}
                                searchOptions={accountsSearchOptions}
                            />

                            <div className={styles.contentButtonsContainer}>
                                <AddButton
                                    iconFontWeight={400}
                                    className={classNames(
                                        "btn btn-secondary btn-sm",
                                    )}
                                    size="sm"
                                    text="Add lines"
                                    onClick={addLines}
                                />
                            </div>
                        </Scrollbars>
                    </Modal.Body>

                    <JournalEntryModalFooter
                        tableTotalTranslateY={tableTotalTranslateY}
                        footerFulyVisible={footerFulyVisible}
                        loading={loading}
                        onDelete={onDelete}
                    />
                </>
            </EditableTable>
        </AccountsProvider>
    );
};
