import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { NavLink, useHistory } from "react-router-dom";
import { OnboardingNav, OnboardingNavStep } from "../onboarding/OnboardingNav";
import { ExpandedSidebar } from "../layout/ExpandedSidebar/ExpandedSidebar";
import { CloseIcon } from "../../icons";
import {
    useEditEntityMutation,
    useEntityCreationMutation,
} from "../../mutations/entity";
import { Entity } from "../../common/types/entity";
import { EntityProvider } from "../entity/EntityProvider";
import { ConnectAccounts } from "../onboarding/ConnectAccounts/ConnectAccounts";
import { PlaidConnection } from "../../common/types/plaidConnection";
import {
    BusinessProfile,
    BusinessProfileProps,
} from "../onboarding/BusinessProfile/BusinessProfile";
import {
    BusinessDescription,
    BusinessDescriptionProps,
} from "../onboarding/BusinessDescription/BusinessDescription";

type NewEntityStep =
    | "business_profile"
    | "business_description"
    | "connect_accounts";

const steps: OnboardingNavStep<NewEntityStep>[] = [
    {
        keys: ["business_profile", "business_description"],
        label: "Business profile",
    },
    {
        keys: ["connect_accounts"],
        label: "Connect accounts",
    },
];

export const NewEntity: React.FC = () => {
    const createEntity = useEntityCreationMutation();
    const history = useHistory();
    const [entityToCreate, setEntityToCreate] = useState<Partial<Entity>>({
        bookkeepingYears: [],
        taxLocations: [],
    });
    const editEntity = useEditEntityMutation(entityToCreate as Entity);
    const [step, setStep] = useState<NewEntityStep>("business_profile");
    const [createdConnections, setCreatedConnections] = useState<
        PlaidConnection[]
    >([]);

    const handleBusinessProfile: BusinessProfileProps["onSubmit"] = useCallback(
        async (name, industry, profile) => {
            if (entityToCreate.id) {
                setEntityToCreate(
                    await editEntity.mutateAsync({
                        name,
                        industry,
                        profile: {
                            ...profile,
                            description: entityToCreate.profile?.description,
                        },
                    }),
                );
            } else {
                setEntityToCreate(
                    await createEntity.mutateAsync({
                        name,
                        profile,
                        industry,
                    }),
                );
            }
            setStep("business_description");
        },
        [createEntity, editEntity, entityToCreate],
    );

    const saveDescription = useCallback(
        async (description: string) => {
            setEntityToCreate(
                await editEntity.mutateAsync({
                    profile: {
                        ...entityToCreate.profile,
                        description,
                    },
                }),
            );
        },
        [entityToCreate, editEntity],
    );

    const handleBusinessDescription: BusinessDescriptionProps["onSubmit"] =
        useCallback(
            async ({ description }) => {
                await saveDescription(description);
                setStep("connect_accounts");
            },
            [saveDescription],
        );

    const onFinished = useCallback(() => {
        history.push("/accounts");
    }, [history]);
    const onConnected = useCallback((connection: PlaidConnection) => {
        setCreatedConnections((connections) => [...connections, connection]);
    }, []);

    let content: React.ReactNode | null = null;

    if (step === "business_profile") {
        content = (
            <BusinessProfile
                onSubmit={handleBusinessProfile}
                entity={entityToCreate as Entity}
            />
        );
    } else if (step === "business_description") {
        content = (
            <BusinessDescription
                onSubmit={handleBusinessDescription}
                saveDescription={saveDescription}
                entity={entityToCreate as Entity}
                onBack={() => setStep("business_profile")}
            />
        );
    } else if (step === "connect_accounts") {
        content = (
            <ConnectAccounts
                onBack={() => setStep("business_description")}
                onFinished={onFinished}
                defaultEntity={entityToCreate as Entity}
                onConnected={onConnected}
                connectionsToShow={createdConnections}
                allowNoAccounts
            />
        );
    }

    return (
        <ExpandedSidebar
            className={classNames("onboarding")}
            header={
                <NavLink to="/accounts" className="btn btn-secondary btn-icon">
                    <CloseIcon />
                </NavLink>
            }
            sidebarSize={25}
        >
            <OnboardingNav step={step} steps={steps} />
            <EntityProvider entity={entityToCreate as Entity}>
                {content}
            </EntityProvider>
        </ExpandedSidebar>
    );
};
