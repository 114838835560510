import React, { useCallback, useState } from "react";
import { useWindowMessage } from "../../hooks/useWindowMessage";
import {
    OAuthAuthorizationResponse,
    triggerAuthorization,
} from "../../lib/oauth";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { OAuthServiceType } from "../../common/constants";
import { useStripeConnectionCreationMutation } from "../../mutations/stripeConnection";
import { useInteraction } from "../../hooks/useInteraction";
import { Entity } from "../../common/types/entity";
import { ButtonProps } from "react-bootstrap";
import { useEntities } from "../../hooks/useEntities";
import { isFunction } from "lodash";

interface Props {
    onCreated?: () => void;
    children?:
        | React.ReactNode
        | ((props: {
              connecting: boolean;
              initializeConnection: () => void;
          }) => React.ReactNode);
    defaultEntity?: Entity;
    btnVariant?: ButtonProps["variant"];
}
export const CreateStripeConnection: React.FC<Props> = ({
    children = <>Connect</>,
    onCreated,
    defaultEntity,
    btnVariant = "link",
}) => {
    const mutation = useStripeConnectionCreationMutation(onCreated);
    const businessEntities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
    });
    if (!defaultEntity && businessEntities.length === 1) {
        defaultEntity = businessEntities[0];
    }
    const [creating, setCreating] = useState(false);
    const handler = ({ code }: OAuthAuthorizationResponse) => {
        if (creating) {
            mutation.mutate({ code, defaultEntity });
        }
        setCreating(false);
    };

    useWindowMessage<OAuthAuthorizationResponse>({
        scope: OAuthServiceType.STRIPE,
        handler,
    });

    const initializeConnection = useCallback(() => {
        triggerAuthorization(OAuthServiceType.STRIPE);
        setCreating(true);
    }, []);

    const authorize = useInteraction(initializeConnection);

    if (isFunction(children)) {
        return children({ connecting: creating, initializeConnection });
    }

    return (
        <span {...authorize}>
            <ButtonWithLoader
                loading={mutation.isLoading}
                variant={btnVariant}
                size="sm"
            >
                {children}
            </ButtonWithLoader>
        </span>
    );
};
