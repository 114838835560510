import { Cell, Row } from "@tanstack/react-table";
import { PLDataRow } from "./ProfitLossReportTableContainer";
import { PLColumns } from "./useProfitLossReportTable";
import { Taxonomy } from "../../../common/categories";
import { Category } from "../../../common/types/category";
import isNumber from "lodash/isNumber";

export function rowExpansionHelper(
    cellOrRow: Cell<PLDataRow, any> | Row<PLDataRow>,
    categoryMap: Record<Taxonomy, Category>,
) {
    const isCell = checkIsCell(cellOrRow);
    const row = isCell ? cellOrRow.getContext().row : cellOrRow;
    const isTitle = !isCell || cellOrRow.column.id === PLColumns.TITLE;
    const isTotal = row.original.isTotal;
    const isExpanded = row.getIsExpanded();
    const canExpand =
        !isTotal &&
        isTitle &&
        (isGroupedSectionRow(row) || isGroupedCategoryRow(row, categoryMap));

    return {
        expandToggle: row.getToggleExpandedHandler(),
        isExpanded,
        canExpand,
    };
}

function isGroupedSectionRow(row: Row<PLDataRow>) {
    return Boolean(row.depth === 0 && row.original.category);
}

function isGroupedCategoryRow(
    row: Row<PLDataRow>,
    categoryMap: Record<Taxonomy, Category>,
) {
    const isSubcategory =
        categoryMap[row.original.category as Taxonomy]?.parentCategoryId;
    const isAccount = isNumber(row.original.category);

    return Boolean(
        row.depth === 1 &&
            !isAccount &&
            (isSubcategory || hasChildrenDifferentThanItself(row)),
    );
}

function hasChildrenDifferentThanItself(row: Row<PLDataRow>) {
    return row.subRows.some(
        (child) =>
            !child.original.isTotal && child.original.id !== row.original.id,
    );
}

function checkIsCell(
    cellOrRow: Cell<PLDataRow, any> | Row<PLDataRow>,
): cellOrRow is Cell<PLDataRow, any> {
    return (cellOrRow as Cell<PLDataRow, any>).getContext !== undefined;
}
