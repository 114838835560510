import React from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { SettingsSection } from "../../../SettingsSection";
import { currentYear, previousYear } from "../../Pricing/constants";
import { useBillingStatus } from "../../../../../hooks/useBillingStatus";
import { getTierForUsage } from "../../../../../common/billing";
import { YearForQuote } from "./YearForQuote";
import {
    currencyFormatter,
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../../../common/helpers/currency";
import { useAnnualBillingContext } from "../AnnualBilling.context";
import { Loader } from "../../../../general/Loader";
import { Card } from "../../../../general/Card/Card";
import { ItemizedSubQuote } from "./ItemizedSubQuote";
import styles from "./Quote.module.scss";
import billingStyles from "../AnnualBilling.module.scss";
import { DiscountCode } from "./DiscountCode";
import { SubQuoteItem } from "./SubQuoteItem";
import { SubQuote } from "./SubQuote";

const observer = new IntersectionObserver(
    ([entry]) => {
        entry.target.classList.toggle(
            styles.isStuck,
            entry.intersectionRatio < 1,
        );
    },
    { threshold: 1 },
);

const connectFooterObserver = (el: HTMLElement | null) => {
    observer.disconnect();
    if (el) {
        observer.observe(el);
    }
};

export const QuoteSection: React.FC = () => {
    const {
        previousYearSelected,
        setPreviousYearSelected,
        handleCheckout,
        pricingAndUsage,
        handleApplyDiscount,
        handleClearDiscount,
    } = useAnnualBillingContext();

    const { creditsUsed, pricingTiers } = useBillingStatus();
    const requiredTier = getTierForUsage(pricingTiers, creditsUsed);

    if (!pricingAndUsage) {
        return <Loader />;
    }

    let currentYearQuoteHint: React.ReactNode;

    if (creditsUsed < pricingTiers[0].end) {
        currentYearQuoteHint = (
            <>
                Once you’ve used{" "}
                {currencyFormatterShort
                    .format(pricingTiers[0].end)
                    .toLowerCase()}{" "}
                business expenses, you’ll be upgraded to the{" "}
                {currencyFormatterNoFractions.format(pricingTiers[1].price)}/m
                tier.
            </>
        );
    } else {
        currentYearQuoteHint = (
            <>
                Includes{" "}
                {currencyFormatterShort.format(requiredTier.end).toLowerCase()}{" "}
                business expenses for {currentYear}. Once redeemed, must upgrade
                to next tier.
            </>
        );
    }

    return (
        <SettingsSection heading="Quote">
            <YearForQuote
                year={currentYear}
                formattedPrice={
                    <>
                        {currencyFormatterNoFractions.format(
                            pricingAndUsage?.currentYearPricing
                                .predictedNextPayment,
                        )}
                        <small>/m</small>
                    </>
                }
                selected
                description={currentYearQuoteHint}
                priceInfo="Billed annually"
            />
            <YearForQuote
                year={previousYear}
                formattedPrice={currencyFormatterNoFractions.format(
                    pricingAndUsage?.lastYearPricing.totalPrice,
                )}
                selected={previousYearSelected}
                onChange={() => setPreviousYearSelected(!previousYearSelected)}
                description={
                    <>Includes all 12 months of the {previousYear} tax year.</>
                }
                priceInfo="One-time"
            />

            <Card className={classNames(billingStyles.card, styles.quoteCard)}>
                <ItemizedSubQuote
                    year={currentYear}
                    pricing={pricingAndUsage.currentYearPricing}
                >
                    {pricingAndUsage.currentYearPricing.totalPrice === 0 && (
                        <>
                            Add your credit card to get access to this year
                            bookkeeping.
                            <br />
                            You won't be billed until you've reached{" "}
                            {currencyFormatterShort
                                .format(pricingTiers[0].end)
                                .toLowerCase()}{" "}
                            business expenses.
                        </>
                    )}
                </ItemizedSubQuote>

                {previousYearSelected && (
                    <SubQuote
                        items={[
                            <SubQuoteItem
                                key="previous-year"
                                value={
                                    pricingAndUsage.lastYearPricing.totalPrice
                                }
                            >
                                {pricingAndUsage.lastYearPricing.totalPrice ===
                                0
                                    ? "Free"
                                    : "One-time fee"}
                            </SubQuoteItem>,
                        ]}
                        total={pricingAndUsage.lastYearPricing.totalPrice}
                        heading={`${previousYear} Bookkeeping`}
                    />
                )}

                <DiscountCode
                    codeInvalid={pricingAndUsage.invalidDiscountCode}
                    appliedDiscount={pricingAndUsage.discount}
                    onApplyDiscount={handleApplyDiscount}
                    onClearDiscount={handleClearDiscount}
                />

                <section className={styles.total}>
                    <span className={styles.heading}>Total</span>
                    <aside className={styles.totalPrice}>
                        {currencyFormatter.format(pricingAndUsage.total)}
                    </aside>
                </section>

                <footer
                    ref={connectFooterObserver}
                    className={classNames(
                        styles.footer,
                        styles.stickyFooter,
                        styles.isStuck,
                    )}
                >
                    <section className={styles.total}>
                        <span className={styles.heading}>Total</span>
                        <aside className={styles.totalPrice}>
                            {currencyFormatter.format(pricingAndUsage.total)}
                        </aside>
                    </section>

                    <Button variant="cta" onClick={handleCheckout}>
                        {pricingAndUsage.total === 0 ? (
                            <>Add credit card</>
                        ) : (
                            <>Make Payment</>
                        )}
                    </Button>
                </footer>
            </Card>
        </SettingsSection>
    );
};
