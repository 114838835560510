import { Store } from "./store";
import {
    getUserEntities,
    getUserStatus,
    getUserWorkspaces,
    TeamNotFoundError,
} from "./lib/user";
import { initializeApp } from "./reducers/_commonActions";
import { getAppConfig } from "./lib/config";
import { EntityContext } from "./common/dto/onboarding/entity-context.dto";
import {
    activeWorkspaceProxy,
    getActiveWorkspaceKeyForUserId,
} from "./state/workspaceContext";
import { UserStatusResponse } from "./common/dto/onboarding/user-status-response.dto";
import { AppConfigResponseDto } from "./common/dto/config/app-config-response.dto";
import { intercom } from "./lib/intercom";
import { getEnv } from "./lib/environment";
import { queryClient } from "./queryClient";
import { UserEntitiesResponse } from "./common/dto/onboarding/user-entities-response.dto";
import { getEntitiesAccountsQueryKey } from "./queries/entitiesAccounts";

export async function initApp() {
    const { activeWorkspaceId } = activeWorkspaceProxy;
    const [userStatus, appConfig] = await Promise.all([
        getUserStatus(),
        getAppConfig(),
    ]);

    const teamKey =
        activeWorkspaceId ??
        (userStatus
            ? localStorage.getItem(
                  getActiveWorkspaceKeyForUserId(userStatus.user.id),
              )
            : null);

    const entitiesAndWorkspaceId = await getUserEntitiesFromTeam(teamKey);

    if (entitiesAndWorkspaceId) {
        queryClient.setQueryData(
            getEntitiesAccountsQueryKey(
                entitiesAndWorkspaceId.activeWorkspaceId,
            ),
            (current: UserEntitiesResponse | undefined) => ({
                ...(current ?? {}),
                entities: entitiesAndWorkspaceId.entities,
            }),
        );
    }
    handleResult({
        userStatus,
        appConfig,
    });
}

interface InitialEntitiesContextAndWorkspaceId {
    entities: EntityContext[];
    activeWorkspaceId: string;
}

async function getUserWorkspacesAndExtractEntitiesFromFirstOne(): Promise<
    InitialEntitiesContextAndWorkspaceId | undefined
> {
    const userWorkspaces = await getUserWorkspaces(false);
    if (!userWorkspaces?.workspaces[0]) {
        activeWorkspaceProxy.changeActiveWorkspace(null);
        return;
    }
    activeWorkspaceProxy.changeActiveWorkspace(
        userWorkspaces.workspaces[0],
        false,
    );

    const activeWorkspaceId = userWorkspaces.workspaces[0].id;
    const entitiesResponse = await getUserEntities(activeWorkspaceId);

    if (entitiesResponse) {
        return {
            entities: entitiesResponse.entities,
            activeWorkspaceId,
        };
    }
}

async function getUserEntitiesFromTeam(
    activeWorkspaceId: string | null,
): Promise<InitialEntitiesContextAndWorkspaceId | undefined> {
    if (!activeWorkspaceId) {
        return getUserWorkspacesAndExtractEntitiesFromFirstOne();
    }
    try {
        const [entities, userWorkspaces] = await Promise.all([
            getUserEntities(activeWorkspaceId).then(
                (res) => res?.entities ?? undefined,
            ),
            getUserWorkspaces(false),
        ]);

        const selectedTeam = userWorkspaces?.workspaces.find(
            (t) => t.id === activeWorkspaceId,
        );
        activeWorkspaceProxy.changeActiveWorkspace(
            selectedTeam ?? userWorkspaces?.workspaces[0] ?? null,
            false,
        );

        if (entities) {
            return {
                entities,
                activeWorkspaceId,
            };
        }
    } catch (e) {
        if (e instanceof TeamNotFoundError) {
            return getUserWorkspacesAndExtractEntitiesFromFirstOne();
        }
    }
}

interface HandleResultParams {
    userStatus: UserStatusResponse | void;
    appConfig: AppConfigResponseDto;
}

function handleResult({ userStatus, appConfig }: HandleResultParams) {
    if (userStatus) {
        const user = userStatus.user;

        Store.dispatch(
            initializeApp({
                ...userStatus,
                ...appConfig,
            }),
        );

        if (getEnv("VITE_INTERCOM_APP_ID") && userStatus.intercomHash) {
            intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: getEnv("VITE_INTERCOM_APP_ID"),
                name: user.name,
                email: user.email, // Email address
                user_hash: userStatus.intercomHash, // HMAC using SHA-256
            });
        }
    } else {
        Store.dispatch(
            initializeApp({
                user: null,
                isDemoUser: false,
                ...appConfig,
            }),
        );
    }
}
