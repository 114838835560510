import { useMemo } from "react";
import { ReportFilters } from "../../../common/types/filters/reports";
import { ActiveCashFlowFilter, financialDocumentFiltersOrder } from "./lib";

type PredicateFn = (filters: ReportFilters) => boolean;

const isActive: Record<ActiveCashFlowFilter, PredicateFn> = {
    date: ({ start, end }) => start !== undefined || end !== undefined,
    category: ({ category }) => !!category && category.length > 0,
} as const;

export function useCashFlowPageDocumentsFilters(
    filters: ReportFilters,
): ActiveCashFlowFilter[] {
    return useMemo(() => {
        return financialDocumentFiltersOrder.filter((filterId) =>
            isActive[filterId](filters),
        );
    }, [filters]);
}
