import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../store";
import "./Notifications.scss";
import Confetti from "react-dom-confetti";
import { last } from "lodash";

const confettiConfig = {
    angle: 270,
    spread: 180,
    startVelocity: 25,
    elementCount: 167,
    dragFriction: 0.12,
    duration: 2000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: 500,
    colors: ["#4e00ff", "#00feab", "#1b7a5b", "#fd0058", "#dcccff"],
};

export const Notifications: React.FC = () => {
    const notifications = useSelector(
        (state: State) => state.appState.notifications,
    );

    const [confetti, setConfetti] = useState(false);

    useLayoutEffect(() => {
        const lastNotification = last(notifications);
        let timeout: any;

        if (lastNotification?.confetti) {
            setConfetti(true);

            timeout = setTimeout(() => {
                setConfetti(false);
            }, 1000);
        }

        return () => timeout && clearTimeout(timeout);
    }, [notifications]);

    return (
        <div className="notifications">
            {notifications.map((n) => (
                <section key={n.id} className={"alert alert-" + n.type}>
                    <p className="font-weight-bold">{n.message}</p>
                    {n.additionalMessage ? <p>{n.additionalMessage}</p> : null}
                </section>
            ))}
            <div className="notifications__confetti">
                <Confetti active={confetti} config={confettiConfig} />
            </div>
        </div>
    );
};
