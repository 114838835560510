import React from "react";
import { Button } from "react-bootstrap";
import styles from "./Intro.module.scss";
import { useTypedFlags } from "../../../hooks/useTypedFlags";

interface Props {
    next(): void;
}

export const Intro: React.FC<Props> = ({ next }) => {
    const { flatRateBilling } = useTypedFlags();
    return (
        <section className={styles.container}>
            <main className={styles.heroContainer}>
                <h1 className="onboarding__title">Welcome to Kick</h1>
                <p className="onboarding__headline mb-0">
                    {flatRateBilling
                        ? "Put your books on autopilot in 5 minutes."
                        : "Continue to access your Profit & Loss, typically available  within 24h."}
                </p>

                <footer className="mt-5">
                    <Button size={"xl" as any} onClick={next}>
                        {flatRateBilling ? "Get started" : "Create account"}
                    </Button>
                </footer>
            </main>

            <aside className={styles.imageContainer}>
                <img src="/images/intro.png" alt="Kick Profit & Loss" />
            </aside>
        </section>
    );
};
