import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
    AccountsIcon,
    ActivityIcon,
    AutopilotIcon,
    BankIcon,
    DocumentIcon,
    GraphIcon,
    HomeIcon,
    RemoveIcon,
} from "../../icons";
import "./Navigation.scss";
import { useNavigationContext } from "./NavigationContext";
import { NavigationItem } from "./NavigationItem";
import { NavigationMenu } from "./NavigationMenu";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { Tag } from "../general/Tag/Tag";
import { EntitiesNavigation } from "./EntitiesNavigation";
import { useEntities } from "../../hooks/useEntities";
import { EntitySideNavMenu } from "./EntitySideNavMenu";
import { EntityManagementDropdown } from "../entity/EntityManagementDropdown";
import { NavigationDropdown } from "./NavigationDropdown";
import { BillingState } from "../billing/BillingState/BillingState";

export const Navigation: React.FC = () => {
    const { close } = useNavigationContext();

    const {
        reportingTab,
        documentsTab,
        autopilotTab,
        accountingTab,
        cashFlowTab,
        flatRateBilling,
    } = useTypedFlags();
    const entities = useEntities();

    const [openedEntity, setOpenedEntity] = useState<number | undefined>(
        entities[0]?.isBusiness ? entities[0]?.id : undefined,
    );

    const businessEntities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
    });
    const hasBusinessEntities = businessEntities.length > 0;

    return (
        <nav className="navigation">
            <header className="navigation__header">
                <NavigationDropdown />

                <aside className="ml-auto">
                    <Button
                        size="sm"
                        variant="plain"
                        onClick={close}
                        className="navigation__header__close"
                    >
                        <RemoveIcon />
                    </Button>
                </aside>
            </header>

            <div className="navigation__content">
                <NavigationMenu>
                    <NavLink to="/" activeClassName="active" exact={true}>
                        <NavigationItem icon={<HomeIcon />} children="Home" />
                    </NavLink>
                    {autopilotTab && hasBusinessEntities && (
                        <NavLink
                            to="/autopilot"
                            activeClassName="active"
                            exact={true}
                        >
                            <NavigationItem
                                icon={<AutopilotIcon />}
                                children="Autopilot"
                            />
                        </NavLink>
                    )}
                    <NavLink
                        to="/transactions"
                        activeClassName="active"
                        exact={true}
                    >
                        <NavigationItem
                            icon={<ActivityIcon />}
                            children="Transactions"
                        />
                    </NavLink>
                    <NavLink to="/accounts" activeClassName="active">
                        <NavigationItem
                            icon={<AccountsIcon />}
                            children="Accounts"
                        />
                    </NavLink>
                    {cashFlowTab && (
                        <NavLink to="/cashflow" activeClassName="active" exact>
                            <NavigationItem
                                icon={<GraphIcon />}
                                children="Cash Flow"
                            />
                        </NavLink>
                    )}
                    {reportingTab && hasBusinessEntities && !cashFlowTab && (
                        <NavLink to="/reports" activeClassName="active" exact>
                            <NavigationItem
                                icon={<GraphIcon />}
                                children={
                                    accountingTab ? "Insights" : "Reports"
                                }
                            />
                        </NavLink>
                    )}

                    {documentsTab ? (
                        <NavLink to={"/documents"} activeClassName="active">
                            <NavigationItem
                                icon={<DocumentIcon />}
                                children="Documents"
                            />
                        </NavLink>
                    ) : (
                        <NavLink
                            to=""
                            activeClassName="active"
                            isActive={() => false}
                        >
                            <NavigationItem icon={<DocumentIcon />} disabled>
                                Documents
                                <Tag variant="secondary" size="sm">
                                    Soon
                                </Tag>
                            </NavigationItem>
                        </NavLink>
                    )}

                    {hasBusinessEntities && (
                        <>
                            {accountingTab ? (
                                <NavLink
                                    to="/accounting"
                                    activeClassName="active"
                                >
                                    <NavigationItem icon={<BankIcon />}>
                                        Accounting
                                    </NavigationItem>
                                </NavLink>
                            ) : (
                                <NavLink
                                    to=""
                                    activeClassName="active"
                                    isActive={() => false}
                                >
                                    <NavigationItem
                                        icon={<BankIcon />}
                                        disabled
                                    >
                                        Accounting
                                        <Tag variant="secondary" size="sm">
                                            Soon
                                        </Tag>
                                    </NavigationItem>
                                </NavLink>
                            )}
                        </>
                    )}
                </NavigationMenu>

                {reportingTab && (
                    <EntitiesNavigation
                        isEntityActive={() => false}
                        isEntityDisabled={(e) => e.isPersonal}
                        renderNavigation={(e) => (
                            <EntitySideNavMenu entity={e} />
                        )}
                        renderAddon={(e) => (
                            <EntityManagementDropdown
                                entity={e}
                                showAccounts
                                showMembers
                            />
                        )}
                        openedEntity={openedEntity}
                        setOpenedEntity={setOpenedEntity}
                    />
                )}
            </div>

            {flatRateBilling && (
                <footer className="navigation__footer">
                    <BillingState />
                </footer>
            )}
        </nav>
    );
};
