import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { boolean, object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { Col, Row } from "react-bootstrap";
import { FormRow } from "../../forms/FormRow";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { TextMessageConsent } from "../../onboarding/Register/TextMessageConsent";
import NumberFormat from "react-number-format";
import styles from "./PhoneNumber.module.scss";

interface Props {
    initialPhoneNumber?: string;
    onSubmit(phoneNumber: string): Promise<void>;
    back?(): void;
}

interface FormSchema {
    phoneNumber: string;
    textMessageConsent: boolean;
}

export const PhoneNumber: React.FC<Props> = ({
    onSubmit,
    initialPhoneNumber,
    back,
}) => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            phoneNumber: initialPhoneNumber ?? "",
            textMessageConsent: false,
        },
        validationSchema: object().shape({
            phoneNumber: string()
                .required("Phone number is required")
                .matches(/\d{10}/, "Provide valid US phone number"),
            textMessageConsent: boolean().oneOf(
                [true],
                "You must consent to receive text messages",
            ),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                await onSubmit(values.phoneNumber);
            },
        }),
    };

    return (
        <OnboardingStep title="What’s your phone number?" narrow>
            <Formik {...form}>
                {({ isValid }) => (
                    <Form>
                        <Row>
                            <Col sm={8}>
                                <FormRow
                                    fieldName="phoneNumber"
                                    label="Phone number"
                                >
                                    {({ setValue }, { value }) => (
                                        <NumberFormat
                                            value={value}
                                            onValueChange={(v) =>
                                                setValue(v.value)
                                            }
                                            format="(###) ###-####"
                                            allowEmptyFormatting
                                            className="form-control form-control-sm"
                                        />
                                    )}
                                </FormRow>
                            </Col>
                        </Row>

                        <TextMessageConsent
                            fieldName="textMessageConsent"
                            className={styles.consent}
                        />

                        <OnboardingStepActions className="mt-4" onBack={back}>
                            <ButtonWithLoader
                                type="submit"
                                variant="primary"
                                loading={loading}
                                disabled={!isValid}
                            >
                                Next
                            </ButtonWithLoader>
                        </OnboardingStepActions>
                    </Form>
                )}
            </Formik>
        </OnboardingStep>
    );
};
