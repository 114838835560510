import React, { useMemo } from "react";
import { SpendingReportDto } from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { useCategoryLabelGetter } from "../../hooks/useCategoryLabelGetter";
import { currencyFormatterFinancialStatements } from "../../common/helpers/currency";
import { Taxonomy } from "../../common/categories";
import { CategoryIcon } from "../transactions/CategoryIcon";
import { useCategoryMap } from "../../hooks/useCategoryMap";
import { Category } from "../../common/types/category";
import { aggregateDataCategories } from "./helpers/spending";

interface Props {
    chart: SpendingReportDto;
}

interface LegendItem {
    category: Taxonomy;
    spend: number;
    spendPercent: number;
}

function parseChartData(
    report: SpendingReportDto,
    categoryMap: Record<Taxonomy, Category>,
) {
    const chartData = aggregateDataCategories(report, categoryMap);
    const total = chartData.reduce((acc, item) => acc + item.spend, 0);

    return chartData
        .map((item) => ({
            ...item,
            spendPercent: (item.spend / total) * 100,
        }))
        .sort((a, b) => b.spend - a.spend);
}

function printSpendPercent(value: number) {
    if (value < 1) return "< 1%";
    return `${value.toFixed()}%`;
}

export const SpendingLegend: React.FC<Props> = ({ chart }) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();

    const data = useMemo<LegendItem[]>(() => {
        return parseChartData(chart, categoryMap);
    }, [chart, categoryMap]);
    return (
        <table className="table spending-legend-table">
            <thead>
                <tr>
                    <th>Category</th>
                    <th>% Spend</th>
                    <th>Spend</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={item.category}>
                        <td>
                            <span>
                                <CategoryIcon category={item.category} />{" "}
                                {getCategoryLabel(item.category)}
                            </span>
                        </td>
                        <td>{printSpendPercent(item.spendPercent)}</td>
                        <td>
                            {currencyFormatterFinancialStatements.format(
                                -item.spend,
                                {
                                    addCurrency: true,
                                    includeFractions: true,
                                },
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
