import { TransactionBaseDto } from "../transactions/transaction-base.dto";
import { EntityDto } from "../entity/entity.dto";
import { FinancialTransactionForTransactionMatch } from "../../types/transactionMatch";

export class TransactionMatchFinancialTransactionDto extends TransactionBaseDto {
    static deserialize(
        dto: TransactionMatchFinancialTransactionDto,
    ): FinancialTransactionForTransactionMatch {
        return {
            ...dto,
            ...TransactionBaseDto.deserialize(dto),
            entity: EntityDto.deserialize(dto.entity),
        };
    }

    entity: EntityDto;

    constructor(transaction: FinancialTransactionForTransactionMatch) {
        super(transaction);
        this.entity = new EntityDto(transaction.entity);
    }
}
