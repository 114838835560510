import { useWorkspaceContext } from "../state/workspaceContext";
import { useQuery } from "react-query";
import { billingQueryKeys } from "./useBillingStatus";
import { getBillingHistory } from "../lib/billing";

export function useBillingHistory() {
    const { activeWorkspaceKey } = useWorkspaceContext();

    return useQuery(billingQueryKeys.history(activeWorkspaceKey), () =>
        getBillingHistory(activeWorkspaceKey),
    );
}
