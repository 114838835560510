import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { TriangleIcon } from "../../../icons";
import { useInteraction } from "../../../hooks/useInteraction";

interface Props {
    title?: React.ReactNode;
    addon?: React.ReactNode;
    className?: string;
    forceOpened?: boolean;
}

export const TransactionDetailsSection: React.FC<Props> = ({
    title,
    addon,
    className,
    forceOpened = false,
    children,
}) => {
    const [opened, setOpened] = useState(forceOpened);
    const toggleSection = useInteraction(
        () => children && setOpened(!forceOpened ? !opened : true),
    );

    useEffect(() => {
        if (forceOpened) {
            setOpened(true);
        }
    }, [forceOpened]);

    return (
        <section
            data-testid={`transaction-details-section`}
            className={classNames("transaction-details__section", className, {
                "transaction-details__section--with-title": Boolean(
                    title ?? addon,
                ),
                "transaction-details__section--empty": !children,
                "transaction-details__section--opened": opened,
            })}
        >
            <header {...toggleSection}>
                {title && <h5>{title}</h5>}
                {!forceOpened && children ? (
                    <span
                        className={classNames(
                            "transaction-details__section__toggle",
                            {
                                "transaction-details__section__toggle--opened":
                                    opened,
                            },
                        )}
                    >
                        <TriangleIcon />
                    </span>
                ) : null}
                {addon && (
                    <div
                        className="transaction-details__section__addon"
                        data-testid="transaction-details-section-addon"
                    >
                        {addon}
                    </div>
                )}
            </header>
            {children && opened && <main>{children}</main>}
        </section>
    );
};
