import React from "react";
import { useSettings } from "./SettingsProvider";
import { Nav } from "react-bootstrap";

interface Props {
    eventKey: string;
    children: React.ReactNode;
}

export const SettingsNavLink: React.FC<Props> = ({ eventKey, children }) => {
    const { path } = useSettings();

    return (
        <Nav.Link eventKey={eventKey} active={eventKey === path[0]}>
            {children}
        </Nav.Link>
    );
};
