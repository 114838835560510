import React from "react";
import { Button } from "react-bootstrap";
import { BulkActionsToastData } from "../../../general/ToastMessages/lib/bulkActions";
import { BaseToastMessageData } from "../../../general/ToastMessages/lib/common";
import { pluralize } from "../../../../common/helpers/string";
import { useCategoryLabelGetter } from "../../../../hooks/useCategoryLabelGetter";
import { Taxonomy } from "../../../../common/categories";

type Props = BulkActionsToastData & BaseToastMessageData;
export const ToastSuccess: React.FC<Props> = ({
    updateDetails,
    previousTransactions,
    undo,
}) => {
    const getCategoryLabel = useCategoryLabelGetter();

    let message;

    if (updateDetails.isUndo) {
        message = "Changes reverted";
    } else {
        switch (updateDetails.update) {
            case "category":
                if (updateDetails.value === Taxonomy.personal) {
                    message = (
                        <div className="transactions-update-toast__content">
                            {pluralize(
                                "Transaction",
                                updateDetails.transactionsCount,
                            )}{" "}
                            marked as personal
                        </div>
                    );
                } else {
                    message = (
                        <div className="transactions-update-toast__content">
                            Category changed to{" "}
                            {getCategoryLabel(updateDetails.value)}
                        </div>
                    );
                }
                break;

            case "taxQuestionAnswers":
                message = (
                    <div className="transactions-update-toast__content">
                        Answer saved
                    </div>
                );
                break;

            case "memo":
                message = (
                    <div className="transactions-update-toast__content">
                        Note updated
                    </div>
                );
                break;

            case "entity":
                message = (
                    <div className="transactions-update-toast__content">
                        {pluralize(
                            "Transaction",
                            updateDetails.transactionsCount,
                        )}{" "}
                        moved to {updateDetails.value.name}
                    </div>
                );
                break;
        }
    }

    return (
        <>
            <div className="transactions-update-toast__content">
                <p className="transactions-update-toast__label">
                    {updateDetails.transactionsCount}{" "}
                    {pluralize("transaction", updateDetails.transactionsCount)}{" "}
                    updated
                </p>

                <p className="transactions-update-toast__info">{message}</p>
            </div>

            {previousTransactions && undo && (
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => undo(previousTransactions, updateDetails)}
                >
                    Undo
                </Button>
            )}
        </>
    );
};
