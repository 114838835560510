import React from "react";
import "./TransactionActionsMessage.scss";

interface Props {
    header: React.ReactNode;
}

export const TransactionActionsMessage: React.FC<Props> = ({
    header,
    children,
}) => {
    return (
        <section
            className="transaction-actions-message"
            data-testid="transaction-actions-message"
        >
            <header>{header}</header>
            {children}
        </section>
    );
};
