import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    finishPasswordlessLogin,
    handleLogin,
    passwordlessLogin,
} from "../../../lib/auth0";
import { addNotification } from "../../../reducers/appState";
import { initApp } from "../../../lib";
import { useHistory, useLocation } from "react-router-dom";
import { CodeVerificationProps } from "../../general/CodeVerification/CodeVerification";
import { isEmail } from "../../../common/helpers/accounts";
import { PageCoverLoader } from "../../general/PageCoverLoader/PageCoverLoader";
import { LoginFlowMagicLink } from "./LoginFlowMagicLink";
import { LoginFlowPhoneAuth } from "./LoginFlowPhoneAuth";
import { LoginFlowStart } from "./LoginFlowStart";
import { getRedirectPath } from "../../../lib/redirectAfterLogin";
import { Auth0Error } from "../../../lib/errors/auth0.error";
import { useWorkspaceContext } from "../../../state/workspaceContext";

export const LoginFlow: React.FC = () => {
    const [finishingLogin, setFinishingLogin] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [providedPhoneOrEmail, setProvidedPhoneOrEmail] = useState<string>();

    const handleTooManyAttemptsError = useCallback(() => {
        dispatch(
            addNotification({
                message: `Too many attempts to login, check your email for instructions or contact support@kick.co`,
                type: "danger",
            }),
        );
    }, [dispatch]);

    const requestCode = useCallback(
        async (phoneNumberOrEmail = providedPhoneOrEmail) => {
            if (phoneNumberOrEmail) {
                try {
                    await passwordlessLogin(phoneNumberOrEmail);
                    return true;
                } catch (e) {
                    const { error, code } = (e as Auth0Error).auth0Error;

                    if (error === "too_many_attempts") {
                        handleTooManyAttemptsError();
                    } else if (code === "sms_provider_error") {
                        // when provided with invalid phone number
                        dispatch(
                            addNotification({
                                message: `Error when sending text message with verification code`,
                                type: "danger",
                            }),
                        );
                    } else if (code === "bad.connection") {
                        // when provided with phone number / email not in the db
                        dispatch(
                            addNotification({
                                message: `Unrecognized ${
                                    isEmail(phoneNumberOrEmail)
                                        ? "email"
                                        : "phone number"
                                }`,
                                type: "danger",
                            }),
                        );
                    }

                    return false;
                }
            }
        },
        [dispatch, handleTooManyAttemptsError, providedPhoneOrEmail],
    );

    const verifyLogin: CodeVerificationProps["verify"] = useCallback(
        async (verificationCode) => {
            if (providedPhoneOrEmail) {
                try {
                    return await finishPasswordlessLogin(
                        providedPhoneOrEmail,
                        verificationCode,
                    );
                } catch (e) {
                    if (
                        (e as Auth0Error).auth0Error.error ===
                        "too_many_attempts"
                    ) {
                        handleTooManyAttemptsError();
                    } else {
                        throw e;
                    }
                }
            } else {
                return false;
            }
        },
        [handleTooManyAttemptsError, providedPhoneOrEmail],
    );

    const { changeActiveWorkspace } = useWorkspaceContext();

    useEffect(() => {
        if (location.hash.length > 2) {
            setFinishingLogin(true);
            handleLogin()
                .then(() => history.push("/"))
                .then(() => initApp())
                .then(() => history.push(getRedirectPath() ?? "/"))
                .catch((e) => {
                    dispatch(
                        addNotification({
                            message: e.errorDescription ?? "Unknown error",
                            type: "danger",
                        }),
                    );
                    setFinishingLogin(false);
                    history.push("/");
                });
        }
    }, [location.hash, history, dispatch, changeActiveWorkspace]);

    if (providedPhoneOrEmail) {
        if (isEmail(providedPhoneOrEmail)) {
            return (
                <LoginFlowMagicLink
                    providedEmail={providedPhoneOrEmail}
                    onRequestCode={requestCode}
                />
            );
        } else {
            return (
                <LoginFlowPhoneAuth
                    onBack={() => setProvidedPhoneOrEmail(undefined)}
                    providedPhone={providedPhoneOrEmail}
                    onRequestCode={requestCode}
                    onVerify={verifyLogin}
                />
            );
        }
    } else if (finishingLogin) {
        return <PageCoverLoader>Logging in to Kick…</PageCoverLoader>;
    } else {
        return (
            <LoginFlowStart
                onRequestCode={requestCode}
                onSuccess={(val) => setProvidedPhoneOrEmail(val)}
            />
        );
    }
};
