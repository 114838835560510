import React from "react";
import { Entity } from "../../../common/types/entity";
import { EntityIcon } from "../../entity/EntityIcon";
import { CheckIcon } from "../../../icons";
import { Dropdown } from "react-bootstrap";
import styles from "./EntityMenuItem.module.scss";

export interface EntityMenuItemProps {
    isSelected?: boolean;
    entity: Entity;
    onSelect(): void;
}

export const EntityMenuItem: React.FC<EntityMenuItemProps> = ({
    entity,
    isSelected,
    onSelect,
}) => {
    return (
        <Dropdown.Item
            onClick={(e) => {
                e.stopPropagation();
                onSelect();
            }}
            active={isSelected}
            className={styles.dropdownItem}
            data-testid="transaction-entity-menu-item"
        >
            <EntityIcon entity={entity} size="xs" />
            <span
                className={styles.name}
                data-testid="transaction-entity-menu-name"
            >
                {entity.name}
            </span>
            {isSelected && (
                <span className="icon-color-blue-500">
                    <CheckIcon />
                </span>
            )}
        </Dropdown.Item>
    );
};
