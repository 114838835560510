import { useWorkspaceContext } from "../../../state/workspaceContext";
import { Workspace } from "../../../common/types/workspace";

import styles from "./NavigationSearch.module.scss";
import { FormRowRaw } from "../../forms/FormRowRaw";
import { useEffect, useMemo, useState } from "react";
import { EnterIcon, SearchIcon } from "../../../icons";
import { useWorkspaces } from "../../../hooks/useWorkspaces";
import Scrollbars from "react-custom-scrollbars-2";
import { EntityIcon } from "../../entity/EntityIcon";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { entityTypeLabels } from "../../entity/constants";
import { ObjectIcon } from "../../general/ObjectIcon/ObjectIcon";
import colors from "@styles/colors.module.scss";
import { emulateTab } from "emulate-tab";
import { useNavigationSearch } from "./NavigationSearchProvider";

interface Props {
    onClose: () => void;
}

const FIRST_CLICKABLE_ELEMENT_ID = "navigation-search-input";
const LAST_CLICKABLE_ELEMENT_ID = "last-navigation-search-item";

export const NavigationSearch = ({ onClose }: Props) => {
    const { state } = useNavigationSearch();
    const userWorkspaces = useWorkspaces({ withEntities: true });

    const { changeActiveWorkspace } = useWorkspaceContext();

    const onSwitchWorkspace = (team: Workspace) => {
        changeActiveWorkspace(team);
        onClose();
    };

    const [search, setSearch] = useState("");

    const filteredWorkspaces = useMemo(() => {
        if (search.length === 0) return userWorkspaces.data;
        return userWorkspaces.data?.filter((team) => {
            return (
                team.name.toLowerCase().includes(search.toLowerCase()) ||
                team.entities?.some((entity) =>
                    entity.name?.toLowerCase().includes(search.toLowerCase()),
                )
            );
        });
    }, [search, userWorkspaces.data]);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === "ArrowUp") {
                e.preventDefault();
                e.stopPropagation();
                if (document.activeElement?.id === FIRST_CLICKABLE_ELEMENT_ID) {
                    return;
                }
                emulateTab.backwards();
            }
            if (e.key === "ArrowDown") {
                e.preventDefault();
                e.stopPropagation();
                if (document.activeElement?.id === LAST_CLICKABLE_ELEMENT_ID) {
                    return;
                }
                emulateTab();
            }
        };

        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, []);

    useEffect(() => {
        if (state !== null) {
            document.getElementById(FIRST_CLICKABLE_ELEMENT_ID)?.focus();
            setSearch("");
        }
    }, [state]);

    return (
        <div className={styles.navigationSearch}>
            <div
                className={styles.navigationSearchInputContainer}
                data-testid="workspaces-search"
            >
                <FormRowRaw
                    inputId={FIRST_CLICKABLE_ELEMENT_ID}
                    value={search}
                    onChange={(value) => {
                        setSearch(value);
                    }}
                    className={styles.navigationSearchInput}
                    placeholder="Search client or entity..."
                    icon={<SearchIcon />}
                />
            </div>
            <div className={styles.navigationSearchHighlights}></div>
            {(filteredWorkspaces?.length ?? 0) > 0 && (
                <Scrollbars
                    style={{ width: "100%" }}
                    autoHeight
                    autoHeightMax={"60vh"}
                >
                    <div
                        className={styles.navigationSearchResults}
                        data-testid="workspaces-search-results"
                    >
                        {filteredWorkspaces?.map((workspace, index) => (
                            <button
                                id={
                                    index === filteredWorkspaces.length - 1
                                        ? LAST_CLICKABLE_ELEMENT_ID
                                        : undefined
                                }
                                key={workspace.id}
                                onClick={() => onSwitchWorkspace(workspace)}
                                className={styles.navigationSearchResultsItem}
                                data-testid="workspaces-search-result-item"
                            >
                                {workspace.name}

                                <div
                                    className={classNames(
                                        styles.navigationSearchResultsItemRightIcons,
                                        styles.entities,
                                    )}
                                >
                                    {workspace.entities?.map((entity) => (
                                        <OverlayTrigger
                                            key={entity.id}
                                            overlay={
                                                <Tooltip
                                                    className="navigation-search-entity-tooltip"
                                                    id={`navigation-search-entity-${entity.id}`}
                                                >
                                                    <div className="font-weight-medium mb-1">
                                                        {entity.name}
                                                    </div>
                                                    {entity.profile?.entityType
                                                        ? entityTypeLabels[
                                                              entity.profile
                                                                  .entityType
                                                          ]
                                                        : ""}
                                                </Tooltip>
                                            }
                                        >
                                            <div>
                                                <EntityIcon
                                                    withBorders
                                                    size="sm"
                                                    entity={entity}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    ))}
                                </div>
                                <div
                                    className={
                                        styles.navigationSearchResultsItemEnterIconContainer
                                    }
                                >
                                    <ObjectIcon
                                        defaultColor={colors.miscAlpha100}
                                        size="sm"
                                        square
                                    >
                                        <EnterIcon />
                                    </ObjectIcon>
                                </div>
                            </button>
                        ))}
                    </div>
                </Scrollbars>
            )}
        </div>
    );
};
