import React, { useMemo } from "react";
import { Transaction } from "../../common/types/transaction";
import classNames from "classnames";
import { Amount } from "../general/Amount/Amount";

export interface TransactionSavingsProps {
    transactions: Transaction[];
}

export const TransactionsTotals: React.FC<TransactionSavingsProps> = ({
    transactions,
}) => {
    const total = useMemo(() => {
        if (!transactions?.length) {
            return 0;
        }

        return transactions.reduce(
            ($, transaction) => $ + transaction.amount,
            0,
        );
    }, [transactions]);

    return (
        <div className="d-flex justify-content-end transactions__totals">
            <span className="d-flex align-items-center mr-3">
                <span className="d-block mr-2 small text-gray-700">Total</span>
                <strong
                    className={classNames("transactions__totals__value", {
                        "transactions__totals__value--positive": total > 0,
                    })}
                    data-testid="transactions-totals"
                >
                    <Amount amount={total} showPositive />
                </strong>
            </span>
        </div>
    );
};
