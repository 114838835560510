import React from "react";
import { useEntity } from "../../entity/useEntity";
import { EntityGeneralSettings } from "./EntityGeneralSettings";
import { EntityAddressSettings } from "./EntityAddressSettings";
import { Col, Row } from "react-bootstrap";

export const EntitySettings: React.FC = () => {
    const entity = useEntity();

    return (
        <>
            <header>
                <small className="mb-3 d-block">{entity.name}</small>
                <h3 className="settings__heading">Settings</h3>
            </header>
            {entity.isBusiness && <EntityGeneralSettings />}
            <Row>
                <Col md={6}>
                    <EntityAddressSettings />
                </Col>
            </Row>
        </>
    );
};
