import React from "react";
import classNames from "classnames";
import {
    currencyFormatterFinancialStatements,
    CurrencyFormatterFinancialStatementsOptions,
} from "../../../common/helpers/currency";
import { noop } from "../../../helpers/general";
import { PLColumns, sectionLabels } from "./useProfitLossReportTable";
import { PLDataRow } from "./ProfitLossReportTableContainer";
import { Cell } from "@tanstack/react-table";
import { Taxonomy } from "../../../common/categories";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { useCategoryMap } from "../../../hooks/useCategoryMap";
import { rowExpansionHelper } from "./rowExpansionHelper";
import { useIsWithinBounds } from "./useIsWithinBounds";
import { SkeletonCell } from "../../general/Skeleton/SkeletonCell";
import { useTransactionsDrillDownContext } from "../transactionsDrillDown.context";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import {
    ProfitLossReportSummarySections,
    ProfitLossTableReportSection,
} from "../../../common/dto/reports/reports-common.dto";

const PLACEHOLDERS = [68, 75, 72, 54, 68, 80, 65] as const;

function getNumberFnOptions(
    cell: Cell<PLDataRow, any>,
): CurrencyFormatterFinancialStatementsOptions | undefined {
    const { original } = cell.getContext().row;
    if (
        ProfitLossReportSummarySections.includes(
            original.section as ProfitLossTableReportSection,
        )
    ) {
        return {
            displaySign: true,
        };
    }
}

interface RenderTitleCellProps {
    cell: Cell<PLDataRow, any>;
}

const Title: React.FC<RenderTitleCellProps> = ({ cell }) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();
    const { original, depth } = cell.getContext().row;
    const { canExpand } = rowExpansionHelper(cell, categoryMap);

    const effectiveDepth = original.isTotal ? depth - 1 : depth;
    const prefix = original.isTotal ? "Total" : "";

    switch (effectiveDepth) {
        case 0:
            return (
                <>
                    {prefix} {sectionLabels[original.section]}
                </>
            );

        case 1: {
            const categoryDef = categoryMap[original.category as Taxonomy];
            if (!categoryDef) {
                return <>{original.category}</>;
            }

            if (categoryDef.parentCategoryId) {
                return (
                    <>
                        {prefix}{" "}
                        {getCategoryLabel(categoryDef.parentCategoryId)}
                    </>
                );
            }

            const customCategoryName: string | null = canExpand
                ? null
                : original.customCategory;

            return (
                <>
                    {prefix}{" "}
                    {(original.isTotal ? undefined : customCategoryName) ??
                        getCategoryLabel(original.category as Taxonomy)}
                </>
            );
        }

        case 2:
            return (
                <>
                    {prefix}{" "}
                    {original.customCategory ??
                        getCategoryLabel(original.category as Taxonomy)}
                </>
            );

        default:
            return null;
    }
};

interface RenderCellProps extends RenderTitleCellProps {
    numberFormatter?: (value: number) => string;
}

export const ProfitLossReportCell: React.FC<RenderCellProps> = ({
    cell,
    numberFormatter,
}) => {
    const categoryMap = useCategoryMap();
    const isWithinBounds = useIsWithinBounds(cell);
    const isTitle = cell.column.id === PLColumns.TITLE;
    const isTotal = cell.row.original.isTotal;
    const parentRow = cell.row.getParentRow();
    const value = isTotal
        ? parentRow?.getValue(cell.column.id)
        : cell.getValue();

    const { expandToggle, isExpanded, canExpand } = rowExpansionHelper(
        cell,
        categoryMap,
    );
    const { transactionsModalOnReportingTab } = useTypedFlags();
    const notTotalColumn = cell.column.id !== PLColumns.TOTAL;
    const isLeaf = cell.row.getLeafRows().length <= 1;
    const shouldShowModal =
        !isTotal &&
        value !== 0 &&
        isLeaf &&
        notTotalColumn &&
        transactionsModalOnReportingTab;

    const classes = classNames("data-table__cell", {
        "data-table__cell--title": isTitle,
        "data-table__cell--expandable": canExpand,
        "data-table__cell--expanded": isExpanded,
    });

    const displayValue =
        typeof value === "number"
            ? numberFormatter?.(value) ??
              currencyFormatterFinancialStatements.format(
                  value,
                  getNumberFnOptions(cell),
              )
            : value;
    const { open: openTransactionsModal } = useTransactionsDrillDownContext();
    const handleCellClick = () => {
        if (shouldShowModal) {
            openTransactionsModal(
                cell.column.id,
                cell.row.original.category as Taxonomy,
            );
        }
    };

    if (!isWithinBounds) {
        return (
            <SkeletonCell
                skeleton={
                    PLACEHOLDERS[
                        (cell.row.index +
                            cell.row.original.id +
                            cell.row.depth) %
                            PLACEHOLDERS.length
                    ]
                }
                className={classes}
            />
        );
    }

    if (isTitle) {
        return (
            <td className={classes} onClick={canExpand ? expandToggle : noop}>
                <Title cell={cell} />
            </td>
        );
    }

    if (isExpanded && cell.getIsAggregated()) {
        return <td className={classes} />;
    }

    return (
        <td
            className={classNames(classes, {
                "data-table__cell--clickable": shouldShowModal,
            })}
            onClick={handleCellClick}
        >
            {displayValue}
        </td>
    );
};
