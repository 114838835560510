import React, { useState } from "react";
import {
    Entity,
    EntityIndustry,
    EntityProfile,
    EntityType,
} from "../../../common/types/entity";
import { Form, Formik, FormikConfig } from "formik";
import { boolean, number, object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { OnboardingStep } from "../OnboardingStep";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { OnboardingStepActions } from "../OnboardingStepActions";
import { Col, Row } from "react-bootstrap";
import { FormRow } from "../../forms/FormRow";
import { entityTypeLabels } from "../../entity/constants";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import NumberFormat from "react-number-format";
import { pick } from "lodash";
import { useUser } from "../../../hooks/useUser";
import { BusinessProfileHint } from "./BusinessProfileHint";

export interface BusinessProfileProps {
    entity?: Entity;
    onSubmit(
        name: string,
        industry: EntityIndustry,
        profile: EntityProfile,
    ): Promise<void>;
    onBack?(): void;
}

interface FormSchema {
    name: string;
    entityType?: EntityType;
    isPartnership: boolean;
    partnershipPercentage?: number;
    isRealEstate: boolean;
    annualRevenue: number;
    annualExpenses: number;
}

const entityTypeOptions: CustomSelectOption[] = Object.values(EntityType).map(
    (value) => ({
        value,
        label: entityTypeLabels[value],
    }),
);

export const BusinessProfile: React.FC<BusinessProfileProps> = ({
    entity,
    onSubmit,
    onBack,
}) => {
    const [loading, setLoading] = useState(false);
    const user = useUser();

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            name: entity?.name ?? "",
            entityType: entity?.profile?.entityType ?? undefined,
            isPartnership: entity?.profile?.isPartnership ?? false,
            partnershipPercentage:
                entity?.profile?.partnershipPercentage ?? undefined,
            isRealEstate: entity?.industry === EntityIndustry.REAL_ESTATE,
            annualRevenue: entity?.profile?.annualRevenue ?? NaN,
            annualExpenses: entity?.profile?.annualExpenses ?? NaN,
        },
        validationSchema: object().shape({
            name: string().required("Business name is required"),
            annualRevenue: number()
                .typeError("Annual revenue must be a number")
                .required("Annual revenue is required")
                .moreThan(0, "Annual revenue must be greater than $0"),
            annualExpenses: number()
                .typeError("Annual expenses must be a number")
                .required("Annual expenses are required")
                .moreThan(0, "Annual expenses must be greater than $0"),
            entityType: string()
                .oneOf(Object.values(EntityType))
                .required("Select an entity type"),
            isPartnership: boolean(),
            partnershipPercentage: number().when("isPartnership", {
                is: true,
                then: () =>
                    number()
                        .integer()
                        .required("Required field")
                        .moreThan(
                            0,
                            "The percentage of ownership must be greater than 0",
                        )
                        .lessThan(
                            100,
                            "The percentage of ownership must be less than 100",
                        ),
            }),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                const fieldsToPick: Array<keyof FormSchema> = [
                    "entityType",
                    "isPartnership",
                    "annualRevenue",
                    "annualExpenses",
                ];

                if (values.isPartnership) {
                    fieldsToPick.push("partnershipPercentage");
                }

                const profile = pick(values, fieldsToPick);
                const industry = values.isRealEstate
                    ? EntityIndustry.REAL_ESTATE
                    : EntityIndustry.OTHER;

                await onSubmit(values.name, industry, {
                    ...profile,
                    annualExpenses: Number(profile.annualExpenses),
                    annualRevenue: Number(profile.annualRevenue),
                    partnershipPercentage: profile.partnershipPercentage
                        ? Number(profile.partnershipPercentage)
                        : undefined,
                });
            },
        }),
    };

    return (
        <OnboardingStep
            title="Create business profile"
            description={
                user.onboardingComplete
                    ? "Add your business to Kick."
                    : "Add your first business to Kick."
            }
            narrow
        >
            <Formik {...form}>
                {({ values }) => (
                    <Form>
                        <Row>
                            <Col>
                                <FormRow
                                    fieldName="name"
                                    label="Business name"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormRow fieldName="entityType">
                                    {(helpers, field) => (
                                        <CustomSelect
                                            label="Entity type"
                                            value={field.value}
                                            onSelected={(value) =>
                                                helpers.setValue(value)
                                            }
                                            dropdownKey={field.name}
                                            options={entityTypeOptions}
                                            fullWidthDropdown
                                        />
                                    )}
                                </FormRow>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormRow
                                    fieldName="annualRevenue"
                                    label="Annual revenue"
                                >
                                    {({ setValue }, { value }) => (
                                        <NumberFormat
                                            value={value}
                                            onValueChange={(v) =>
                                                setValue(v.value)
                                            }
                                            prefix="$"
                                            decimalScale={0}
                                            allowEmptyFormatting
                                            thousandSeparator
                                            className="form-control"
                                        />
                                    )}
                                </FormRow>
                            </Col>

                            <Col>
                                <FormRow
                                    fieldName="annualExpenses"
                                    label="Annual expenses"
                                >
                                    {({ setValue }, { value }) => (
                                        <NumberFormat
                                            value={value}
                                            onValueChange={(v) =>
                                                setValue(v.value)
                                            }
                                            prefix="$"
                                            decimalScale={0}
                                            allowEmptyFormatting
                                            thousandSeparator
                                            className="form-control"
                                        />
                                    )}
                                </FormRow>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormRow
                                    fieldName="isPartnership"
                                    className="d-inline-flex"
                                    showErrorMessage={false}
                                >
                                    {(helpers, field) => (
                                        <FormCheckbox
                                            value="isPartnership"
                                            handleChange={() =>
                                                helpers.setValue(!field.value)
                                            }
                                            isChecked={field.value}
                                            label={
                                                <>
                                                    This is a Partnership{" "}
                                                    <BusinessProfileHint>
                                                        Select if this business
                                                        has multiple owners
                                                    </BusinessProfileHint>
                                                </>
                                            }
                                        />
                                    )}
                                </FormRow>
                            </Col>
                        </Row>

                        {values.isPartnership && (
                            <Row>
                                <Col sm={3}>
                                    <FormRow
                                        fieldName="partnershipPercentage"
                                        label="Your ownership %"
                                    >
                                        {({ setValue }, { value }) => (
                                            <NumberFormat
                                                allowNegative={false}
                                                value={value}
                                                onValueChange={(v) =>
                                                    setValue(v.value)
                                                }
                                                suffix="%"
                                                className="form-control"
                                            />
                                        )}
                                    </FormRow>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col>
                                <FormRow
                                    fieldName="isRealEstate"
                                    className="d-inline-flex"
                                    showErrorMessage={false}
                                >
                                    {(helpers, field) => (
                                        <FormCheckbox
                                            value="isRealEstate"
                                            handleChange={() =>
                                                helpers.setValue(!field.value)
                                            }
                                            isChecked={field.value}
                                            label={
                                                <>
                                                    This is a Real Estate
                                                    business{" "}
                                                    <BusinessProfileHint>
                                                        Select if this business
                                                        holds, manages, or
                                                        invests in real estate
                                                    </BusinessProfileHint>
                                                </>
                                            }
                                        />
                                    )}
                                </FormRow>
                            </Col>
                        </Row>

                        <OnboardingStepActions
                            btnSize="xl"
                            className="mt-4"
                            onBack={onBack}
                        >
                            <ButtonWithLoader
                                type="submit"
                                variant="primary"
                                size={"xl" as any}
                                loading={loading}
                            >
                                Next
                            </ButtonWithLoader>
                        </OnboardingStepActions>
                    </Form>
                )}
            </Formik>
        </OnboardingStep>
    );
};
