import React from "react";
import {
    TransactionAuditLog,
    TransactionAuditLogTransferMatchingMetadata,
} from "../../../../common/types/transactionAuditLog";
import { currencyFormatter } from "../../../../common/helpers/currency";

interface Props {
    log: TransactionAuditLog;
    categoryLabel: string;
}

export const TransactionAuditLogTransferMatchContent: React.FC<Props> = ({
    log,
    categoryLabel,
}) => {
    const metadata =
        log.metadata as TransactionAuditLogTransferMatchingMetadata;
    const match =
        metadata.amount && metadata.description ? (
            <>
                {metadata.amount < 0
                    ? "outgoing transfer"
                    : "incoming transfer"}{" "}
                <strong>{metadata.description}</strong> for{" "}
                <strong>
                    {currencyFormatter.format(Math.abs(metadata.amount))}
                </strong>
            </>
        ) : (
            "other transaction"
        );

    return (
        <>
            Category set to <strong>{categoryLabel}</strong> because transaction
            was matched to {match}.
        </>
    );
};
