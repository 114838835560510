import React, { useCallback } from "react";
import { GeneralLedgerTableSection } from "./GeneralLedgerTableSection";
import { AccountWithTransactions } from "../../../common/dto/reports/get-account-transactions-report-response.dto";
import { GeneralLedgerTableHeaderCell } from "./GeneralLedgerTableHeaderCell";
import { useHistory } from "react-router-dom";
import { AccountTransactionsLine } from "../../../common/types/domains/accounting/accountTransactions";
import { JournalEntrySourceType } from "../../../common/types/domains/accounting/journalEntry";
import { useAccountingTabContext } from "../useAccountingTab";

interface Props {
    data: AccountWithTransactions[];
}

export const GeneralLedgerTable: React.FC<Props> = ({ data }) => {
    const { push } = useHistory();

    const { setFilters } = useAccountingTabContext();

    const onSourceClick = useCallback(
        (transaction: AccountTransactionsLine) => {
            if (
                transaction.source.type === JournalEntrySourceType.Transaction
            ) {
                push(
                    `/transactions?transactionId=${transaction.source.id}&transactionType=external`,
                );
            }
        },
        [push],
    );

    const onReferenceClick = useCallback(
        (transaction: AccountTransactionsLine) => {
            setFilters((current) => ({
                ...current,
                openJournalEntryModal: true,
                openedJournalEntryId: transaction.referenceNumber,
            }));
        },
        [setFilters],
    );

    return (
        <table className="data-table__table data-table--bold-totals">
            <thead>
                <tr>
                    <GeneralLedgerTableHeaderCell title="Date" isString />
                    <GeneralLedgerTableHeaderCell title="Source" isString />
                    <GeneralLedgerTableHeaderCell title="Reference" isString />
                    <GeneralLedgerTableHeaderCell
                        title="Description"
                        isString
                    />
                    <GeneralLedgerTableHeaderCell title="Memo" isString />
                    <GeneralLedgerTableHeaderCell title="Split" isString />
                    <GeneralLedgerTableHeaderCell title="Amount" />
                    <GeneralLedgerTableHeaderCell title="Balance" />
                </tr>
            </thead>
            <tbody>
                {data.map(
                    ({ account, transactions, isAccountIncreasingByDebit }) => (
                        <GeneralLedgerTableSection
                            account={account}
                            isAccountIncreasingByDebit={
                                isAccountIncreasingByDebit
                            }
                            transactions={transactions}
                            key={account.code}
                            onSourceClick={onSourceClick}
                            onReferenceClick={onReferenceClick}
                        />
                    ),
                )}
            </tbody>
        </table>
    );
};
