import { QueryClient } from "react-query";

export const queryClient = new QueryClient();

export function getEntityUsersQueryKey(entityId: number) {
    return ["entityUsers", entityId];
}
export function getEntityInvitesQueryKey(entityId: number) {
    return ["entityInvites", entityId];
}
export function getAddressQueryKey(entityId: number) {
    return ["address", entityId];
}
export function getPayrollQueryKey(entityId: number) {
    return ["entityPayrolls", entityId];
}

export const financialConnectionsQueryKey = "financialConnections";
export const plaidConnectionsQueryKey = [
    financialConnectionsQueryKey,
    "plaidConnections",
];
export const stripeConnectionsQueryKey = [
    financialConnectionsQueryKey,
    "stripeConnections",
];
export const gustoConnectionsQueryKey = [
    financialConnectionsQueryKey,
    "gustoConnections",
];

export const allMembersQueryKey = "allMembers";
export const materialUnconfirmedTransactionsQueryKey =
    "materialUnconfirmedTransactions";

export const EMPTY_COLLECTION_QUERY_KEY = "emptyCollection";
export const EMPTY_COLLECTION = [];

export const CUSTOM_CATEGORIES_QUERY_KEY = "custom-categories";
