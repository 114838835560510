import { TransactionsFilters } from "../../lib";
import { useMemo } from "react";

export function useExpandedEntities(
    value: TransactionsFilters["entitiesAccounts"],
) {
    return useMemo(() => {
        if (!value) {
            return [];
        }

        return value
            .filter(
                (v) => !v.excludedAccounts || v.excludedAccounts.length !== 0,
            )
            .map((v) => String(v.entityId));
    }, [value]);
}
