import { Taxonomy } from "../categories";
import { TaxQuestionKey } from "../taxSavingRules";
import { Entity } from "./entity";
import { FinancialAccount } from "./financialAccount";
import { TaxQuestionAnswer } from "./taxQuestionAnswer";
import { TransactionAuditLog } from "./transactionAuditLog";
import { TransactionSplit } from "./transactionSplit";
import { TransactionMatch } from "./transactionMatch";
import { CustomCategory } from "./customCategory";

export enum TransactionType {
    EXTERNAL = "external",
    STRIPE = "stripe",
    IMPORTED = "imported",
}

export enum TransactionStatus {
    PENDING = "pending",
    COMPLETED = "completed",
    FAILED = "failed",
}

export enum TransactionClassification {
    BUSINESS = "business",
    PERSONAL = "personal",
}

export enum BasisType {
    CONTRIBUTION = "contribution",
    DISTRIBUTION = "distribution",
}

export const KICK_DESCRIPTION_STOP_WORDS = ["Unknown"];

export enum CategoryBackupKeys {
    PERSONAL = "personal",
    BUSINESS = "business",
    USER = "user",
    OPS = "ops",
    CATEGORIZATION_MODEL = "categorizationModel",
    SIMILARITY = "similarity",
    CATEGORIZATION_RULES = "categorizationRules",
}

export type CategoryBackup = Partial<Record<CategoryBackupKeys, Taxonomy>>;

export interface TransactionBase {
    id: number;
    amount: number;
    absoluteAmount: number;
    date: Date;
    type: TransactionType;
    status: TransactionStatus;
    description: string;
    customDescription: string | null;
    kickDescription: string | null;
    plaidDescription: string | null;
    bankDescription: string;
    category: Taxonomy;
    categoryBackup: CategoryBackup | null;
    counterpartyId: string | null;
    memo: string | null;
    amountSaved: number | null;
    potentialAmountSaved: number | null;
    requiredActions: string[];

    taxQuestions: TaxQuestionKey[];
    deductionRate: number;
    requirementsSnoozed: boolean;
    isIgnored: boolean;
    isBusiness: boolean;

    inReview: boolean;

    isEligibleForTaxSavings: boolean;
    deductionRateAdjustable: boolean;
    pairedTransferId?: number | null;
}

export interface Transaction extends TransactionBase {
    financialAccount: FinancialAccount;
    entity: Entity;
    split?: TransactionSplit[];
    auditLogs: TransactionAuditLog[];
    taxQuestionAnswers: TaxQuestionAnswer[];
    transactionMatches?: TransactionMatch[];
    customCategory?: CustomCategory | null;
    pairedTransfer?: Transaction | null;
}

export interface MatchTransferResponse {
    originTransaction: Transaction;
    targetTransaction: Transaction;
}
export interface UnmatchTransferResponse {
    transaction: Transaction;
}
