const STORAGE_KEY = "redirectAfterLogin";

export function saveCurrentPathForRedirect() {
    window.localStorage.setItem(STORAGE_KEY, window.location.pathname);
}

export function clearRedirectPath() {
    window.localStorage.removeItem(STORAGE_KEY);
}

export function getRedirectPath() {
    return window.localStorage.getItem(STORAGE_KEY);
}
