import React from "react";
import {
    FinancialDocumentDetailsView,
    useFinancialDocumentDetailsView,
} from "../useFinancialDocumentDetailsView";
import { Button } from "react-bootstrap";
import styles from "./FinancialDocumentMatches.module.scss";

export const FinancialDocumentNoMatches: React.FC = () => {
    const { go } = useFinancialDocumentDetailsView();

    return (
        <section
            className={styles.section}
            data-testid="financial-document-no-matches-section"
        >
            <p>If this is a receipt, find a transaction.</p>
            <Button
                variant="primary"
                size="sm"
                onClick={() => go(FinancialDocumentDetailsView.MATCHING)}
                data-testid="financial-document-no-matches-find-transaction-button"
            >
                Find a transaction
            </Button>
        </section>
    );
};
