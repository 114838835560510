import React, { useCallback, useMemo, useRef, useState } from "react";
import { useEntities } from "../../../hooks/useEntities";
import { FilterSearch } from "../FilterSearch/FilterSearch";
import { partition } from "lodash";
import { useCallbackWithFocus } from "../../../hooks/useCallbackWithFocus";
import { ClearFilter } from "../../transactions/ClearFilter/ClearFilter";
import Scrollbars from "react-custom-scrollbars-2";
import styles from "./EntityFilter.module.scss";
import { EntityFilterItem } from "./EntityFilterItem";
import { EntityFilterProps } from "./types";

export const EntityFilterOverlay: React.FC<EntityFilterProps> = ({
    filters,
    onChange,
}) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();
    const entities = useEntities();

    const [personalEntities, businessEntities] = useMemo(() => {
        let filteredEntities = entities;
        if (search) {
            filteredEntities = entities.filter((entity) =>
                entity.name?.toLowerCase().includes(search.toLowerCase()),
            );
        }

        return partition(filteredEntities, (entity) => entity.isPersonal);
    }, [entities, search]);

    const isEntitySelected = useCallback(
        (entityId: number) => {
            return !!filters.entities?.includes(entityId);
        },
        [filters.entities],
    );
    const toggleEntity = useCallback(
        (entityId: number) => {
            if (isEntitySelected(entityId)) {
                onChange({
                    entities: filters.entities?.filter((id) => id !== entityId),
                });
            } else {
                onChange({
                    entities: [...(filters.entities || []), entityId],
                });
            }
        },
        [filters.entities, isEntitySelected, onChange],
    );

    const resetFilter = useCallback(() => {
        onChange({ entities: undefined });
    }, [onChange]);

    const displayAnyEntities =
        personalEntities.length > 0 || businessEntities.length > 0;
    const isFilterActive = filters.entities && filters.entities?.length > 0;

    const handleToggleEntity = useCallbackWithFocus(toggleEntity, searchRef);
    const handleReset = useCallbackWithFocus(resetFilter, searchRef);

    return (
        <div>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
                focus
            />

            <Scrollbars
                style={{ width: "100%" }}
                autoHeight
                autoHeightMax={400}
                autoHeightMin={80}
            >
                {displayAnyEntities ? (
                    <>
                        {personalEntities.length > 0 && (
                            <section className={styles.section}>
                                <header>Personal</header>

                                <div className={styles.list}>
                                    {personalEntities.map((entity) => (
                                        <EntityFilterItem
                                            key={entity.id}
                                            entity={entity}
                                            isSelected={isEntitySelected(
                                                entity.id,
                                            )}
                                            onToggle={handleToggleEntity}
                                        />
                                    ))}
                                </div>
                            </section>
                        )}

                        {businessEntities.length > 0 && (
                            <section className={styles.section}>
                                <header>Business</header>

                                <div className={styles.list}>
                                    {businessEntities.map((entity) => (
                                        <EntityFilterItem
                                            key={entity.id}
                                            entity={entity}
                                            isSelected={isEntitySelected(
                                                entity.id,
                                            )}
                                            onToggle={handleToggleEntity}
                                        />
                                    ))}
                                </div>
                            </section>
                        )}
                    </>
                ) : (
                    <p className={styles.empty}>No matching entities</p>
                )}
            </Scrollbars>

            {isFilterActive ? (
                <ClearFilter onClick={handleReset}>Reset</ClearFilter>
            ) : null}
        </div>
    );
};
