import { FinancialDocumentStatus } from "../../../common/constants";
import { WithRequiredProperties } from "../../../common/types/base/generics";

export interface FinancialDocumentFilters {
    search?: string;
    start?: Date;
    end?: Date;
    status?: FinancialDocumentStatus;
    entities?: number[];
}

export type FinancialDocumentFiltersToUseInRequest = WithRequiredProperties<
    FinancialDocumentFilters,
    "entities"
>;

export interface FinancialDocumentFiltersProps {
    onChange(filters: Partial<FinancialDocumentFilters>): void;
    filters: FinancialDocumentFilters;
}

export enum FinancialDocumentFilterTabs {
    DATE = "DATE",
    STATUS = "STATUS",
}

export const financialDocumentFiltersOrder = ["date", "status"] as const;
export type ActiveFinancialDocumentFilters =
    (typeof financialDocumentFiltersOrder)[number];
