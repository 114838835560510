import { Entity } from "./entity";

export enum InvitedUserRole {
    ADMIN = "admin",
    MEMBER = "member",
}

export interface InviteCode {
    id: number;
    email: string | null;
    used: boolean;
    entity: Entity | null;
    role: InvitedUserRole | null;
    iconColor: string;
}
