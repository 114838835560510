export enum EventType {
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAILED = "LOGIN_FAILED",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
    LOGOUT_FAILED = "LOGOUT_FAILED",
}

export enum EventConnection {
    SMS = "SMS",
    EMAIL = "EMAIL",
}

export interface LogStreamEvent {
    logId: string;
    eventType: EventType;
    connection: EventConnection;
    ip: string;
    ipLocation?: string;
    userAgent: string;
    date: Date;
}
