import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useQuery } from "react-query";
import { getPricing, setupSubscription } from "../../../lib/flatRateBilling";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
    TRIAL_LENGTH_DAYS,
} from "../../../common/flatRateBilling";
import styles from "./PlanManagement.module.scss";
import { PreviousYearPriceCard } from "./PreviousYearPriceCard";
import { Summary } from "./Summary";
import { ModalWithPaymentSetupContent } from "./ModalWithPaymentSetupContent";
import { ConfirmPaymentData, StripeError } from "@stripe/stripe-js";
import { CurrentYearPriceCard } from "./CurrentYearPriceCard";
import { useOnboardingStatus } from "../../../hooks/useOnboardingStatus";
import { format } from "date-fns";
import { fullDateFormat } from "../../../common/helpers/date";
import { SelectedPaymentMethod } from "./lib";

interface Props {
    plan: FLAT_RATE_PLANS;
    isTrial: boolean;
    intentId?: string;
}

export const UpgradeSubscriptionModal: React.FC<Props> = ({
    plan,
    isTrial,
    intentId,
}) => {
    const [paymentBusy, setPaymentBusy] = useState(false);
    const [error, setError] = useState<StripeError | null>(null);
    const [includePreviousYear, setIncludePreviousYear] = useState(false);
    const [appliedDiscountCode, setAppliedDiscountCode] = useState<
        string | null
    >(null);
    const {
        plans,
        subscriptionDetails,
        isSubscribed,
        currentPlan: currentPlanId,
    } = useBillingStatus();
    const stripe = useStripe();
    const elements = useElements();
    const isOnboarded = useOnboardingStatus();

    const isUpgrade = isSubscribed;
    const canManagePreviousYearBookkeeping = !isUpgrade || isTrial;

    useEffect(() => {
        if (isTrial && subscriptionDetails?.redeemedFreePreviousYearOffer) {
            setIncludePreviousYear(true);
        }
    }, [isTrial, subscriptionDetails?.redeemedFreePreviousYearOffer]);

    const { data: pricing, isLoading } = useQuery(
        ["pricing", plan, includePreviousYear, appliedDiscountCode],
        () =>
            getPricing({
                plan,
                includePreviousYear,
                discountCode: appliedDiscountCode ?? undefined,
            }),
        {
            keepPreviousData: true,
        },
    );

    const selectedPlan = plans.find((p) => p.plan === plan)!;
    const currentPlan = plans.find((p) => p.plan === currentPlanId)!;

    const couponDiscount = pricing?.discount?.amount ?? 0;

    const handleConfirm = useCallback(
        async (selectedMethod: SelectedPaymentMethod) => {
            if (!stripe || !elements || !intentId) {
                return;
            }

            setPaymentBusy(true);
            setError(null);

            const { setupId } = await setupSubscription({
                plan: selectedPlan.plan,
                intentId:
                    selectedMethod === SelectedPaymentMethod.NEW
                        ? intentId
                        : undefined,
                discountCode: appliedDiscountCode ?? undefined,
                includePreviousYear:
                    canManagePreviousYearBookkeeping && includePreviousYear,
                trial: isTrial,
                upgrade: isUpgrade,
            });

            const afterCheckout = `${window.location.pathname}${window.location.search}`;

            const returnUrl = new URL(
                `${window.location.origin}/checkout/${setupId}?after=${encodeURIComponent(afterCheckout)}`,
            );

            if (selectedMethod === SelectedPaymentMethod.EXISTING) {
                window.location.replace(returnUrl); // if using existing method, just checkout
                return;
            }

            const options: ConfirmPaymentData = {
                return_url: returnUrl.toString(),
            };

            const response = await stripe.confirmSetup({
                elements,
                confirmParams: options,
            });

            if (response.error) {
                setError(response.error);
            }

            setPaymentBusy(false);
        },
        [
            appliedDiscountCode,
            canManagePreviousYearBookkeeping,
            elements,
            includePreviousYear,
            intentId,
            isTrial,
            isUpgrade,
            selectedPlan.plan,
            stripe,
        ],
    );

    const submitText = useMemo(() => {
        if (!isTrial) {
            return "Confirm Payment";
        } else if (isUpgrade) {
            return "Upgrade";
        } else {
            return "Start Trial";
        }
    }, [isTrial, isUpgrade]);

    const header = useMemo(() => {
        const planName = (
            <span className={styles.planName}>{flatRatePlanNames[plan]}</span>
        );

        if (isTrial) {
            const trialEndText =
                isUpgrade && subscriptionDetails?.periodEndsAt
                    ? `until your trial ends on ${format(subscriptionDetails.periodEndsAt, fullDateFormat)}`
                    : `Free for ${TRIAL_LENGTH_DAYS} days`;

            return (
                <>
                    <h3>
                        Try {planName} {trialEndText}
                    </h3>
                    <p>
                        Cancel anytime. We'll remind you before the trial ends.
                    </p>
                </>
            );
        } else {
            return (
                <h3>
                    {isUpgrade ? "Upgrade to" : "Subscribe to"} {planName}
                </h3>
            );
        }
    }, [isTrial, isUpgrade, plan, subscriptionDetails?.periodEndsAt]);

    return (
        <ModalWithPaymentSetupContent
            isLoading={isLoading}
            busy={paymentBusy}
            onConfirm={handleConfirm}
            stripeError={error}
            header={header}
            submitText={submitText}
        >
            {pricing ? (
                <CurrentYearPriceCard
                    pricing={pricing}
                    isTrial={isTrial}
                    isUpgrade={isUpgrade}
                    selectedPlan={selectedPlan}
                    currentPlan={currentPlan}
                />
            ) : null}

            {canManagePreviousYearBookkeeping ? (
                <PreviousYearPriceCard
                    selected={includePreviousYear}
                    freePreviousYearBookkeepingOffer={Boolean(
                        !isOnboarded ||
                            (isTrial &&
                                subscriptionDetails?.redeemedFreePreviousYearOffer),
                    )}
                    isTrial={isTrial}
                    selectedPlan={selectedPlan}
                    onChange={() =>
                        setIncludePreviousYear(!includePreviousYear)
                    }
                />
            ) : null}

            {pricing ? (
                <Summary
                    total={pricing.total}
                    isTrial={isTrial}
                    discountCode={appliedDiscountCode}
                    couponDiscount={couponDiscount}
                    onDiscountCodeChange={setAppliedDiscountCode}
                    codeInvalid={pricing.invalidDiscountCode}
                />
            ) : null}
        </ModalWithPaymentSetupContent>
    );
};
