import React from "react";
import { PricingTier as PricingTierType } from "../../../../common/billing";
import {
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../../common/helpers/currency";
import "./PricingTier.scss";
import classnames from "classnames";
import { Tag } from "../../../general/Tag/Tag";
import { numberFormatterShort } from "../../../../common/helpers/number";

export enum TierVariant {
    CURRENT = "current",
    NEXT = "next",
    FUTURE = "future",
}

interface Props {
    tier: PricingTierType;
    variant: TierVariant | null;
}

export const PricingTier: React.FC<Props> = ({ tier, variant }) => {
    let tag;
    switch (variant) {
        case TierVariant.CURRENT:
            tag = <Tag className="pricing-tier__tag">Current</Tag>;
            break;
        case TierVariant.NEXT:
            tag = <Tag className="pricing-tier__tag">Next tier</Tag>;
            break;
        default:
            tag = null;
    }

    return (
        <article
            className={classnames(
                "pricing-tier",
                variant && `pricing-tier--${variant}`,
            )}
        >
            <header className="pricing-tier__header">
                <div className="pricing-tier__label">Savings Credits</div>
                <div className="pricing-tier__range">
                    {currencyFormatterShort.formatToParts(tier.start)[0].value}
                    {
                        currencyFormatterShort.formatToParts(tier.start)[1]
                            .value
                    }{" "}
                    - {numberFormatterShort.format(tier.end).toLowerCase()}
                </div>
            </header>

            <main className="pricing-tier__body">
                <div className="pricing-tier__price">
                    {tier.price === 0 ? (
                        "Free"
                    ) : (
                        <>
                            {currencyFormatterNoFractions.format(tier.price)}
                            <span className="pricing-tier__price__unit">
                                /m
                            </span>
                        </>
                    )}
                </div>
                {tag}
            </main>

            <footer className="pricing-tier__footer">
                ~ {currencyFormatterShort.format(tier.end * 3).toLowerCase()}{" "}
                expenses
            </footer>
        </article>
    );
};
