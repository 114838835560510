import React, { useCallback } from "react";
import classNames from "classnames";
import { TransactionSortValue } from "./useSort";
import "./SortableHeader.scss";

export interface SortableHeaderProps {
    sort?: TransactionSortValue;
    onChange?: (sort: TransactionSortValue) => void;
    asc: TransactionSortValue;
    desc: TransactionSortValue;
}

export const SortableHeader: React.FC<SortableHeaderProps> = ({
    sort,
    asc,
    desc,
    onChange,
    children,
}) => {
    const handleClick = useCallback(() => {
        onChange?.(sort === asc ? desc : asc);
    }, [asc, desc, onChange, sort]);

    if (!onChange) {
        return <>{children}</>;
    }

    return (
        <div
            className={classNames("sortable-header", {
                "sortable-header--asc": sort === asc,
                "sortable-header--desc": sort === desc,
            })}
            onClick={handleClick}
            data-testid={`${sort === asc ? "sortable-header--asc" : ""}${sort === desc ? "sortable-header--desc" : ""}`}
        >
            {children}
        </div>
    );
};
