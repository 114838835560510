import { configureStore } from "@reduxjs/toolkit";
import { AppState, appState } from "./reducers/appState";
import * as Sentry from "@sentry/react";
import { onboarding, OnboardingState } from "./reducers/onboarding";
import { sentryUserContextMiddleware } from "./middleware/sentryUserContext";
import { AppConfig, appConfig } from "./reducers/appConfig";

export interface State {
    appState: AppState;
    onboarding: OnboardingState;
    appConfig: AppConfig;
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const Store = configureStore({
    reducer: {
        appState,
        onboarding,
        appConfig,
    },
    enhancers: [sentryReduxEnhancer],
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([sentryUserContextMiddleware]),
});
