import {
    EntityProfileIndustry,
    EntityProfileType,
    EntityType,
    FirmSize,
    ProfessionalType,
} from "../../common/types/entity";

export const entityTypeLabels: Record<EntityType, string> = {
    [EntityType.NOT_REGISTERED]: "Not registered (Sole proprietorship)",
    [EntityType.LLC]: "LLC",
    [EntityType.LLC_WITH_S_CORP]: "LLC with S Corp Election",
    [EntityType.C_CORP_WITH_S_CORP]: "C Corporation with S Corp Election",
    [EntityType.C_CORP]: "C Corporation",
} as const;

export const entityTypeV2Labels = {
    [EntityProfileType.C_CORP]: "C Corporation",
    [EntityProfileType.LLC]: "LLC",
    [EntityProfileType.NO_LEGAL_ENTITY]: "I don't have a US legal entity",
    [EntityProfileType.PARTNERSHIP]: "Partnership (including LLPs)",
    [EntityProfileType.S_CORP]: "S Corporation",
    [EntityProfileType.SOLE_PROPRIETORSHIP]: "Sole Proprietorship",
} as const satisfies Record<EntityProfileType, string>;

export const industryLabels = {
    [EntityProfileIndustry.RETAIL]: "Retail",
    [EntityProfileIndustry.ADVERTISING]: "Advertising",
    [EntityProfileIndustry.BOOKKEEPER]: "Bookkeeper",
    [EntityProfileIndustry.CONTENT_CREATION]: "Content Creation",
    [EntityProfileIndustry.CONSUMER_GOODS]: "Consumer Goods",
    [EntityProfileIndustry.ACCOUNTANT]: "Accountant",
    [EntityProfileIndustry.OTHER]: "Other",
    [EntityProfileIndustry.ECOMMERCE]: "E-Commerce",
    [EntityProfileIndustry.CONSULTING_SERVICES]: "Consulting/Services",
    [EntityProfileIndustry.REAL_ESTATE]: "Real Estate",
    [EntityProfileIndustry.TECHNOLOGY]: "Technology",
    [EntityProfileIndustry.MARKETPLACE]: "Marketplace",
    [EntityProfileIndustry.HEALTHCARE]: "Healthcare",
    [EntityProfileIndustry.MANUFACTURING]: "Manufacturing",
    [EntityProfileIndustry.VIRTUAL_GOODS]: "Virtual Goods",
    [EntityProfileIndustry.VENTURE_CAPITAL]: "Venture Capital",
    [EntityProfileIndustry.NOT_SURE]: "Not sure",
} as const satisfies Record<EntityProfileIndustry, string>;

export const professionalTypeLabels = {
    [ProfessionalType.BOOKKEEPER]: "Bookkeeper",
    [ProfessionalType.DESIGNATED_ACCOUNTANT]: "Designated Accountant",
    [ProfessionalType.NON_DESIGNATED_ACCOUNTANT]: "Non-designated Accountant",
    [ProfessionalType.TAX_ADVISOR]: "Tax Advisor",
} as const satisfies Record<ProfessionalType, string>;

export const firmSizeLabels = {
    [FirmSize["0_5"]]: "0 - 5",
    [FirmSize["6_9"]]: "6 - 9",
    [FirmSize["10_20"]]: "10 - 20",
    [FirmSize["21_49"]]: "21 - 49",
    [FirmSize["50_PLUS"]]: "50+",
} as const satisfies Record<FirmSize, string>;
