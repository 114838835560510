import React from "react";
import { ButtonProps } from "react-bootstrap";
import {
    ConnectionProviderType,
    FinancialConnection,
} from "../../common/types/financialConnection";
import { FixStripeConnection } from "../stripe/FixStripeConnection";
import css from "./FixFinnancialConnection.module.scss";
import { FixGustoConnection } from "../gusto/FixGustoConnection";

interface Props extends Omit<ButtonProps, "onClick"> {
    connection: FinancialConnection;
    onFixed?: () => void;
}

export const FixFinancialConnection: React.FC<Props> = ({
    connection,
    onFixed,
    children,
    ...btnProps
}) => {
    if (connection.connectionProvider === ConnectionProviderType.STRIPE) {
        return (
            <FixStripeConnection
                onFixed={onFixed}
                connection={connection}
                {...btnProps}
            >
                <span className={css.fixConnection}>{children}</span>
            </FixStripeConnection>
        );
    }
    if (connection.connectionProvider === ConnectionProviderType.GUSTO) {
        return (
            <FixGustoConnection
                onFixed={onFixed}
                connection={connection}
                {...btnProps}
            >
                <span className={css.fixConnection}>{children}</span>
            </FixGustoConnection>
        );
    }

    return null;
};
