import React from "react";
import { useAccountingTabContext } from "../useAccountingTab";
import { useAccountingReport } from "../useAccountingReport";
import { getBalanceSheet } from "../../../lib/accountingReports";
import { AccountingReportFiltersForm } from "../AccountingReportFiltersForm";
import { BalanceSheetContent } from "./BalanceSheetContent";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useBalanceSheetDownload } from "./balanceSheetDownload/useBalanceSheetDownload";
import { ReportingTabCycle } from "../../../common/constants";
import { LoaderOnInitialLoading } from "../../general/LoaderOnInitialLoading";

export const BalanceSheetPreviewPage: React.FC = () => {
    const { filters, setFilters, selectedEntity } = useAccountingTabContext();

    const { report: balanceSheet, generationDate } = useAccountingReport({
        filters,
        entity: selectedEntity,
        queryKey: "balanceSheet",
        reportGetter: getBalanceSheet,
        prepareQuery: (query) => {
            if (!query.startDate || !query.endDate) {
                throw new Error(
                    "Start date and end date are required for Balance Sheet report",
                );
            }

            return {
                ...query,
                startDate: query.startDate,
                endDate: query.endDate,
                cycle: ReportingTabCycle.MONTH,
            };
        },
    });

    const { downloading, download } = useBalanceSheetDownload();

    return (
        <>
            <AccountingReportFiltersForm
                filters={filters}
                setFilters={setFilters}
                additionalButtons={
                    <>
                        <ButtonWithLoader
                            size="sm"
                            onClick={download}
                            loading={downloading}
                            disabled={
                                balanceSheet.isLoading ||
                                !balanceSheet.isSuccess
                            }
                        >
                            Download (.xlsx)
                        </ButtonWithLoader>
                    </>
                }
                requireDateSelection
            />
            <LoaderOnInitialLoading queryResult={balanceSheet}>
                {balanceSheet.data && selectedEntity && (
                    <BalanceSheetContent
                        report={balanceSheet.data}
                        filters={filters}
                        generationDate={generationDate}
                        entity={selectedEntity}
                    />
                )}
            </LoaderOnInitialLoading>
        </>
    );
};
