import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { IntegrationAccount } from "../../../common/types/integrationAccount";

export function isValidAccountType(account: FinancialAccount) {
    return account.isBusiness !== null;
}

export function isValidAccountEntity(account: FinancialAccount) {
    return !!account.entityId;
}
export function isValidAccount(account: IntegrationAccount) {
    return (
        isValidAccountType(account.financialAccount) &&
        isValidAccountEntity(account.financialAccount)
    );
}

export function isValidConnection(connection: PlaidConnectionWithAccounts) {
    return connection.accounts.every(isValidAccount);
}
