import React from "react";
import { Button } from "react-bootstrap";
import { SettingsSection } from "../SettingsSection";
import { CustomProgressBar } from "../../general/CustomProgressBar/CustomProgressBar";
import { currencyFormatterShort } from "../../../common/helpers/currency";
import { ThunderboltIcon } from "../../../icons";
import "./YourSavingsCredits.scss";
import { useCurrentPricingTier } from "../../../hooks/useCurrentPricingTier";

interface Props {
    creditsUsed?: number;
    onButtonClick?(): void;
}

export const YourSavingsCredits: React.FC<Props> = ({
    creditsUsed,
    onButtonClick,
}) => {
    const currentTier = useCurrentPricingTier(creditsUsed);

    return (
        <SettingsSection
            heading="Your Savings Credits"
            className="billing__savings-credits"
        >
            <div className="billing__savings-credits__container">
                <CustomProgressBar
                    value={creditsUsed ?? 0}
                    max={currentTier.end}
                    renderValue={(value) => (
                        <>
                            {currencyFormatterShort.format(value).toLowerCase()}{" "}
                            used
                        </>
                    )}
                    renderMax={(max) => (
                        <>
                            out of{" "}
                            {currencyFormatterShort.format(max).toLowerCase()}{" "}
                            total
                        </>
                    )}
                />

                {onButtonClick && (
                    <Button variant="cta" onClick={onButtonClick}>
                        Subscribe <ThunderboltIcon className="ml-1" />
                    </Button>
                )}
            </div>
        </SettingsSection>
    );
};
