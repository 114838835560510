import React from "react";
import { Payroll, PayrollTotals } from "../../../common/types/payroll";
import css from "./PayrollDetails.module.scss";
import { PayrollDetailsTotalItem } from "./PayrollDetailsTotalItem";
import { chunk } from "lodash";
interface Props {
    payroll: Payroll;
}

const totalsOrder: (keyof PayrollTotals)[] = [
    "companyDebit",
    "netPayDebit",
    "taxDebit",
    "reimbursementDebit",
    "childSupportDebit",
    "reimbursements",
    "grossPay",
    "netPay",
    "employeeBonuses",
    "employeeCommissions",
    "employeeCashTips",
    "employeePaycheckTips",
    "additionalEarnings",
    "ownersDraw",
    "employerTaxes",
    "benefits",
    "employeeTaxes",
    "employeeBenefitsDeductions",
    "checkAmount",
    "deferredPayrollTaxes",
];
export const PayrollDetails: React.FC<Props> = ({ payroll }) => {
    return (
        <div className={css.payrollDetails}>
            <table className="table table-sm table-borderless">
                <tbody>
                    {chunk(totalsOrder, 2).map(
                        ([itemLeftCol, itemRightCol]) => {
                            return (
                                <tr key={itemLeftCol + itemRightCol}>
                                    <PayrollDetailsTotalItem
                                        payroll={payroll}
                                        total={itemLeftCol}
                                    />
                                    <PayrollDetailsTotalItem
                                        payroll={payroll}
                                        total={itemRightCol}
                                    />
                                </tr>
                            );
                        },
                    )}
                </tbody>
            </table>
        </div>
    );
};
