import React from "react";

type Props = {
    buttonText: React.ReactNode;
};

export const RegisterConsent: React.FC<Props> = ({ buttonText }) => {
    return (
        <small className="font-weight-bold text-gray-700">
            By selecting "{buttonText}" you agree to the Kick{" "}
            <a
                href="https://kick.co/legal#terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
            >
                Terms
            </a>{" "}
            and{" "}
            <a
                href="https://kick.co/legal#privacy"
                target="_blank"
                rel="noopener noreferrer"
            >
                Privacy Policy
            </a>
            .
        </small>
    );
};
