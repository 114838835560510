import React, { useEffect } from "react";
import { getUserProfile, handleLogin } from "../../../lib/auth0";
import { handleGoogleAuth } from "../../../lib/user";
import { PageCoverLoader } from "../../general/PageCoverLoader/PageCoverLoader";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { useHistory } from "react-router-dom";
import { addNotification } from "../../../reducers/appState";
import { useDispatch } from "react-redux";

export const GoogleAuth: React.FC = () => {
    const inviteCode = useQueryParam("inviteCode") ?? undefined;
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        handleLogin()
            .then((token) => getUserProfile(token))
            .then((user) =>
                handleGoogleAuth({
                    auth0UserId: user.sub,
                    name: user.name,
                    avatar: user.picture,
                    email: user.email!,
                    inviteCode,
                }),
            )
            .catch((e) => {
                dispatch(
                    addNotification({
                        message:
                            e.errorDescription ?? e.message ?? "Unknown error",
                        type: "danger",
                    }),
                );
            })
            .then(() => {
                history.push("/");
            });
    }, [dispatch, history, inviteCode]);

    return <PageCoverLoader />;
};
