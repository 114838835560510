import { isString } from "lodash";
import { FinancialAccountSourceType } from "../types/financialAccount";
import {
    ConnectionProviderType,
    FinancialConnection,
} from "../types/financialConnection";
import { PlaidConnection } from "../types/plaidConnection";

interface FinancialConnectionSettings {
    multipleAccountsPerConnection: boolean;
    label: string | ((connection: FinancialConnection) => string);
    financialAccountSourceType: FinancialAccountSourceType;
}

export const FINANCIAL_CONNECTIONS_SETTINGS: Record<
    ConnectionProviderType,
    FinancialConnectionSettings
> = {
    [ConnectionProviderType.PLAID]: {
        multipleAccountsPerConnection: true,
        label: (connection) => (connection as PlaidConnection).bankName,
        financialAccountSourceType: FinancialAccountSourceType.PLAID,
    },
    [ConnectionProviderType.STRIPE]: {
        multipleAccountsPerConnection: false,
        label: "Stripe",
        financialAccountSourceType: FinancialAccountSourceType.STRIPE,
    },
    [ConnectionProviderType.GUSTO]: {
        multipleAccountsPerConnection: false,
        label: "Gusto",
        financialAccountSourceType: FinancialAccountSourceType.GUSTO,
    },
};

export function isPlaidConnection(
    connection: FinancialConnection,
): connection is PlaidConnection {
    return connection.connectionProvider === ConnectionProviderType.PLAID;
}

export function getConnectionLabel(connection: FinancialConnection): string {
    const labelProvider =
        FINANCIAL_CONNECTIONS_SETTINGS[connection.connectionProvider].label;

    return isString(labelProvider) ? labelProvider : labelProvider(connection);
}
