import { ReportFilters } from "../../common/types/filters/reports";
import { DATE_RANGE_PRESETS_BY_ID } from "../../helpers/date";
import { ReportingTabCycle } from "../../common/constants";
import { useEffect, useMemo } from "react";
import { useEntitiesContexts } from "../../hooks/useEntitiesContexts";
import { addNotification } from "../../reducers/appState";
import { useDispatch } from "react-redux";
import { useReportParams } from "./useReportParams";

const defaultFilters: Omit<ReportFilters, "entitiesAccounts"> = {
    start: DATE_RANGE_PRESETS_BY_ID.last_4_months.getStart(new Date()),
    end: DATE_RANGE_PRESETS_BY_ID.last_4_months.getEnd(new Date()),
    cycle: ReportingTabCycle.MONTH,
};

const defaultFiltersNotSubscribedUsers: Omit<
    ReportFilters,
    "entitiesAccounts"
> = {
    start: DATE_RANGE_PRESETS_BY_ID.last_4_months.getStart(new Date()),
    end: DATE_RANGE_PRESETS_BY_ID.last_4_months.getEnd(new Date()),
    cycle: ReportingTabCycle.MONTH,
};

const defaultFiltersThisYear: Omit<ReportFilters, "entitiesAccounts"> = {
    start: DATE_RANGE_PRESETS_BY_ID.this_year.getStart(new Date()),
    end: DATE_RANGE_PRESETS_BY_ID.this_year.getEnd(new Date()),
    cycle: ReportingTabCycle.MONTH,
};

const defaultFiltersThisYearNotSubscribedUsers: Omit<
    ReportFilters,
    "entitiesAccounts"
> = {
    start: DATE_RANGE_PRESETS_BY_ID.this_year.getStart(new Date()),
    end: DATE_RANGE_PRESETS_BY_ID.this_year.getEnd(new Date()),
    cycle: ReportingTabCycle.MONTH,
};

export function useDefaultReportsFilters(thisYear?: boolean): ReportFilters {
    const { entityId, cycle, start, end } = useReportParams();

    const entitiesContexts = useEntitiesContexts({
        onlyBusiness: true,
    });
    const dispatch = useDispatch();
    const firstEntity = entitiesContexts?.[0];
    const selectedEntity = entityId
        ? entitiesContexts?.find((ctx) => ctx.entity.id === entityId)
        : firstEntity;

    useEffect(() => {
        if (!selectedEntity) {
            dispatch(
                addNotification({
                    message: "Entity not found",
                    type: "danger",
                }),
            );
        }
    }, [dispatch, selectedEntity]);

    const entityToUse = selectedEntity ?? firstEntity;

    return useMemo(() => {
        const [defaultFiltersSubscribed, defaultFiltersNotSubscribed] = thisYear
            ? [defaultFiltersThisYear, defaultFiltersThisYearNotSubscribedUsers]
            : [defaultFilters, defaultFiltersNotSubscribedUsers];
        const withoutParams = {
            ...(entityToUse?.isPaying
                ? defaultFiltersSubscribed
                : defaultFiltersNotSubscribed),
            entitiesAccounts: entityToUse
                ? [{ entityId: entityToUse.entity.id }]
                : [],
        };

        return {
            ...withoutParams,
            cycle: cycle ?? withoutParams.cycle,
            start: start ?? withoutParams.start,
            end: end ?? withoutParams.end,
        };
    }, [cycle, end, entityToUse, start, thisYear]);
}
