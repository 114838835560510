import React from "react";
import classNames from "classnames";
import styles from "./FlatRateBilling.module.scss";
import { currencyFormatterNoFractions } from "../../../../common/helpers/currency";
import { CustomPricingCallButton } from "../CustomPricingCallButton";

interface Props {
    isOnFreePlan: boolean;
    isAtOrOverLimit: boolean;
    maxUsage: number;
}

export const CurrentPlanFooter: React.FC<Props> = ({
    isAtOrOverLimit,
    maxUsage,
    isOnFreePlan,
}) => {
    if (isOnFreePlan) {
        if (isAtOrOverLimit) {
            return (
                <footer
                    className={classNames(
                        styles.section,
                        styles.footer,
                        styles.warning,
                    )}
                >
                    You've hit the{" "}
                    <strong>
                        {currencyFormatterNoFractions.format(maxUsage)}
                    </strong>{" "}
                    free business expense limit. <br />
                    Upgrade now to continue using Kick.
                </footer>
            );
        } else {
            return (
                <footer className={classNames(styles.section, styles.footer)}>
                    Once you hit the limit above, you will be required to
                    upgrade to a paid tier, or have limited access to Kick.
                </footer>
            );
        }
    } else if (isAtOrOverLimit) {
        return (
            <footer
                className={classNames(
                    styles.section,
                    styles.footer,
                    styles.inverted,
                )}
            >
                <header className={styles.title}>
                    You’re eligible for Kick Enterprise!
                </header>

                <main>
                    <p>
                        Customers with{" "}
                        <strong>
                            {currencyFormatterNoFractions.format(maxUsage)}
                        </strong>{" "}
                        or more in business expenses are eligible for our
                        Enterprise plan, which includes CPA support, 1099
                        processing and accrual flexibility.
                    </p>

                    <aside>
                        <CustomPricingCallButton
                            className="btn btn-sm btn-secondary"
                            text="Book a call"
                        />
                    </aside>
                </main>
            </footer>
        );
    }

    return null;
};
