import React from "react";
import { SettingsSection } from "../SettingsSection";
import { UserInfo } from "./UserInfo";
import { Notifications } from "./Notifications";

export const GeneralSettings: React.FC = () => {
    return (
        <div className="settings__general">
            <header>
                <h3 className="settings__heading">General</h3>
            </header>

            <SettingsSection heading="Your Info">
                <UserInfo />
            </SettingsSection>

            <SettingsSection heading="Email Notifications">
                <Notifications />
            </SettingsSection>
        </div>
    );
};
