import React, { useState } from "react";
import { EntityRow } from "./EntityRow";
import { EntityAccountDragHandle } from "./EntityAccountDragHandle";
import { UniqueIdentifier } from "@dnd-kit/core";
import { ClockIcon, Offline } from "../../icons";
import { AccountCard } from "../general/AccountCard/AccountCard";
import { accountHasError } from "../../helpers/account";
import { timeAgo } from "../../helpers/date";
import classNames from "classnames";
import { FinancialAccountManagementDropdown } from "../financialAccount/FinancialAccountManagementDropdown";
import { FinancialAccount } from "../../common/types/financialAccount";
import { useFinancialAccountSyncedAt } from "../../hooks/useFinancialAccountSyncedAt";
import { BrokenConnectionLink } from "../financialConnection/BrokenConnectionLink";
import { FinancialAccountBalance } from "../financialAccount/FinancialAccountBalance";
import {
    getLeadingAccountConnection,
    MANUALLY_SYNCED_ACCOUNTS,
} from "../../common/helpers/financialAccount";

interface Props {
    account: FinancialAccount;
    sortableId?: UniqueIdentifier;
}

export const EntityAccount: React.FC<Props> = ({ account, sortableId }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const syncedAt = useFinancialAccountSyncedAt(account);
    let lastSync = null;
    const isErrored = account.integrationAccounts?.every((ia) =>
        accountHasError(ia, ia.connection ?? undefined),
    );
    const connection = getLeadingAccountConnection(account);
    const integrationAccountOfLeadingConnection =
        account.integrationAccounts?.find(
            (ia) => ia.connectionId && ia.connectionId === connection?.id,
        );

    if (isErrored && connection && integrationAccountOfLeadingConnection) {
        lastSync = (
            <BrokenConnectionLink
                connection={connection}
                integrationAccount={integrationAccountOfLeadingConnection}
                balanceSyncedAt={syncedAt}
            />
        );
    } else if (syncedAt) {
        const syncIconProps = {
            height: 12,
            width: 12,
            className: "icon-color-current mr-2",
        };
        const isManuallySynced = MANUALLY_SYNCED_ACCOUNTS.includes(
            account.sourceType,
        );
        lastSync = (
            <>
                {isManuallySynced ? (
                    <Offline {...syncIconProps} />
                ) : (
                    <ClockIcon {...syncIconProps} />
                )}
                {timeAgo(syncedAt)}
            </>
        );
    }

    return (
        <EntityRow
            className={classNames("entity__account", {
                "entity__account--disabled": account.disabled,
                "entity__account--active": dropdownOpen,
            })}
            entityOrAccount={
                <AccountCard
                    account={account}
                    connection={connection ?? undefined}
                    numberInName
                    showType={false}
                />
            }
            lastSync={lastSync}
            balanceAndActions={
                <div className="d-flex justify-content-between">
                    <div
                        className="entity__account__balance"
                        data-testid="account-balance"
                    >
                        <FinancialAccountBalance account={account} />
                    </div>
                    <div className="entity__account__actions">
                        {sortableId && (
                            <EntityAccountDragHandle sortableId={sortableId} />
                        )}

                        <FinancialAccountManagementDropdown
                            account={account}
                            connection={connection ?? undefined}
                            onToggle={setDropdownOpen}
                        />
                    </div>
                </div>
            }
        />
    );
};
