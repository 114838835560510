import React from "react";
import { Button } from "react-bootstrap";
import { MenuIcon } from "../../icons";
import { useNavigationContext } from "../navigation/NavigationContext";
import { WhenPrimaryAdmin } from "../general/WhenRoles/WhenRoles";
import { UsageWidget } from "./UsageWidget";
import styles from "./MainInterface.module.scss";
import { HEADER_HEIGHT } from "../../constants";

export const MainInterfaceHeader: React.FC = () => {
    const { open } = useNavigationContext();

    return (
        <header className={styles.header}>
            <div
                className={`${styles.container} ${styles.headerContainer}`}
                style={{ "--height": `${HEADER_HEIGHT}px` } as any}
            >
                <aside className={styles.headerContent}>
                    <WhenPrimaryAdmin>{() => <UsageWidget />}</WhenPrimaryAdmin>

                    <Button
                        size="sm"
                        variant="plain"
                        onClick={open}
                        className="d-md-none"
                    >
                        <MenuIcon />
                    </Button>
                </aside>
            </div>
        </header>
    );
};
