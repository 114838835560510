import { backendClient, unwrapResponse } from "./backendClient";
import { BalanceChartResponseDto } from "../common/dto/balance/balance-chart-response.dto";
import { formatISO } from "date-fns";
import { GetBalanceChartParams } from "../common/dto/balance/get-balance-chart.params";
import { CancelTokenSource } from "axios";
import { ExternalTransferDto } from "../common/dto/balance/external-transfer.dto";

interface BalanceChartOptions {
    startDate: Date;
    endDate: Date;
}

export async function topUpBalance(data: ExternalTransferDto): Promise<void> {
    await backendClient.post("/transfer/topup", data);
}

export async function payOutBalance(data: ExternalTransferDto): Promise<void> {
    await backendClient.post("/transfer/payout", data);
}

export async function getBalanceChart(
    financialAccountIds: number[],
    options: BalanceChartOptions,
    cancelToken?: CancelTokenSource,
): Promise<BalanceChartResponseDto> {
    const params: GetBalanceChartParams = {
        ...options,
        financialAccountIds,
        startDate: formatISO(options.startDate),
        endDate: formatISO(options.endDate),
    };

    return unwrapResponse(
        await backendClient.get(`/balance-stats/chart`, {
            params,
            cancelToken: cancelToken?.token,
        }),
    );
}
