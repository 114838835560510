import { AggregatedFiltersTabs, FilterProps } from "../../lib";
import { ActiveFilter } from "./lib";
import { useAggregatedFiltersContext } from "../../../../general/AggregatedFilters/AggregatedFilters.context";
import React from "react";
import { useResetFilter } from "./ActivityFilter.hooks";
import { AmountFilterLabel } from "./AmountFilterLabel";
import { CategoryFilterLabel } from "./CategoryFilterLabel";
import { DateFilterLabel } from "../../../../general/ActiveFilters/DateFilterLabel";
import { DirectionFilterLabel } from "./DirectionFilterLabel";
import { ActiveFilterButton } from "../../../../general/ActiveFilters/ActiveFilterButton";

interface Props extends FilterProps {
    filterId: ActiveFilter;
}

const filterIdToTab: Record<ActiveFilter, AggregatedFiltersTabs> = {
    category: AggregatedFiltersTabs.CATEGORY,
    amount: AggregatedFiltersTabs.AMOUNT,
    direction: AggregatedFiltersTabs.AMOUNT,
    date: AggregatedFiltersTabs.DATE,
};

export const TransactionsActiveFilterButton: React.FC<Props> = ({
    filterId,
    filters,
    onChange,
}) => {
    const { open: openFilter } = useAggregatedFiltersContext();
    const resetFilter = useResetFilter(onChange, filterId);

    let label;

    switch (filterId) {
        case "amount":
            label = <AmountFilterLabel filters={filters} />;
            break;

        case "category":
            label = <CategoryFilterLabel filters={filters} />;
            break;

        case "date":
            label = <DateFilterLabel filters={filters} />;
            break;

        case "direction":
            label = <DirectionFilterLabel filters={filters} />;
            break;
    }

    return (
        <ActiveFilterButton
            openFilter={() => openFilter(filterIdToTab[filterId])}
            resetFilter={resetFilter}
            label={label}
        />
    );
};
