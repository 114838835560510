import React, { useCallback, useMemo, useState } from "react";
import { OnboardingStep } from "../OnboardingStep";
import { OnboardingStepActions } from "../OnboardingStepActions";
import { usePlaidFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { AccountsConnectionWidget } from "../../financialAccount/AccountConnectionWidget/AccountsConnectionWidget";
import { Button } from "react-bootstrap";
import { ConnectCreditCardsModal } from "./ConnectCreditCardsModal";
import { ConnectMoreAccountsModal } from "./ConnectMoreAccounts";
import PlaidLogo from "../../../static/images/logos/plaid.svg?react";
import { partition, sortBy } from "lodash";
import { AccountsConnectionProvider } from "../../financialAccount/AccountConnectionWidget/AccountsConnectionContext";
import { ConnectPlaidAccountProps } from "../../plaid/ConnectPlaid/ConnectPlaid";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { FullAccountNumber } from "../../../common/dto/financialAccount/get-account-numbers-response.dto";
import {
    FinancialAccount,
    FinancialAccountType,
} from "../../../common/types/financialAccount";
import { useQuery } from "react-query";
import { useUser } from "../../../hooks/useUser";
import { getUnassignedFinancialAccounts } from "../../../lib/financialAccount";
import { unassignedAccountsQueryKey } from "../../../queries/unassignedAccounts";
import { IntegrationAccount } from "../../../common/types/integrationAccount";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { trackEvent } from "../../../lib/analytics";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { PlaidAlert } from "./PlaidAlert";
import { PlusIcon } from "../../../icons";

export interface ConnectAccountsProps
    extends Pick<
        ConnectPlaidAccountProps,
        "onConnected" | "defaultEntity" | "beforeConnect"
    > {
    onBack?(): void;
    onFinished(): void;
    connectionsToShow?: PlaidConnection[];
    allowNoAccounts?: boolean;
}

enum Modals {
    CONNECT_CREDIT_CARDS,
    CONNECT_MORE_ACCOUNTS,
}

type IntegrationAccountWithFinancialAccount = IntegrationAccount & {
    financialAccount: FinancialAccount;
};

function splitAccountsByType(
    allAccounts: IntegrationAccountWithFinancialAccount[],
) {
    const [checkingAccounts, creditCards] = partition(
        allAccounts,
        (account) =>
            account.financialAccount.financialType !==
            FinancialAccountType.CREDIT_CARD,
    );

    return { checkingAccounts, creditCards };
}

export const ConnectAccounts: React.FC<ConnectAccountsProps> = ({
    onBack,
    onFinished,
    connectionsToShow,
    allowNoAccounts,
    onConnected,
    ...connectAccountProps
}) => {
    const { newOnboarding } = useTypedFlags();
    const user = useUser();
    // when onboarding additional entity, show only accounts from connections created during the flow
    // (entity is automatically assigned to new accounts created during additional entity onboarding flow)
    const allPlaidAccounts = usePlaidFinancialAccounts({
        connectionIds: connectionsToShow?.map((c) => c.id),
    });
    // when performing initial onboarding, entity is not assigned by default to account, so need to fetch all unassigned accounts
    const unassignedAccounts = useQuery(unassignedAccountsQueryKey, () =>
        user.onboardingComplete ? [] : getUnassignedFinancialAccounts(),
    );

    const allPlaidAccountsOrdered = useMemo(
        () =>
            sortBy(
                [...allPlaidAccounts, ...(unassignedAccounts.data ?? [])],
                "id",
            ).flatMap((a) =>
                (a.integrationAccounts ?? []).map((ia) => ({
                    ...ia,
                    financialAccount: a,
                })),
            ),
        [allPlaidAccounts, unassignedAccounts],
    );
    const [modal, setModal] = useState<Modals | null>(null);
    const [busy, setBusy] = useState<boolean>(false);

    const hasAccountWithNoType = allPlaidAccounts.some(
        (acc) => acc.isBusiness === null,
    );

    const { checkingAccounts, creditCards } = useMemo(
        () => splitAccountsByType(allPlaidAccountsOrdered),
        [allPlaidAccountsOrdered],
    );

    useEffectOnce(() => {
        void trackEvent("onboarding_step_connect_accounts", {
            bankAccountsConnected: checkingAccounts.length,
            creditCardsConnected: creditCards.length,
        });
    });

    const hideAllModals = useCallback(() => {
        setModal(null);
    }, []);

    const handleAccountConnecting = useCallback(() => {
        setBusy(true);
    }, []);

    const handleAccountConnected = useCallback(
        (connection: PlaidConnection, numbers: FullAccountNumber[]) => {
            setBusy(false);
            hideAllModals();
            onConnected?.(connection, numbers);
        },
        [hideAllModals, onConnected],
    );

    const finish = useCallback(() => {
        hideAllModals();
        onFinished();
    }, [hideAllModals, onFinished]);

    const handleNext = useCallback(() => {
        if (allowNoAccounts) {
            finish();
        } else if (!creditCards.length) {
            setModal(Modals.CONNECT_CREDIT_CARDS);
        } else {
            setModal(Modals.CONNECT_MORE_ACCOUNTS);
        }
    }, [creditCards.length, finish, allowNoAccounts]);

    return (
        <>
            <OnboardingStep
                title={
                    newOnboarding
                        ? "Connect bank accounts and credit cards"
                        : "Add business accounts"
                }
                description={
                    newOnboarding ? null : (
                        <>Connect any accounts with business expenses</>
                    )
                }
            >
                {newOnboarding && <PlaidAlert />}
                <AccountsConnectionProvider showAccountTypeWarning>
                    <section>
                        <AccountsConnectionWidget
                            heading={<h5>Bank accounts</h5>}
                            emptyConnectPrompt={
                                <>
                                    <PlusIcon className="mr-2" /> Add bank
                                    accounts
                                </>
                            }
                            connectPrompt="Add another account"
                            accounts={checkingAccounts}
                            onSavingConnection={handleAccountConnecting}
                            onConnected={handleAccountConnected}
                            isConnecting={busy}
                            allowConnect
                            {...connectAccountProps}
                        />
                    </section>

                    <section className="mt-5">
                        <AccountsConnectionWidget
                            heading={<h5>Credit cards</h5>}
                            emptyConnectPrompt={
                                <>
                                    <PlusIcon className="mr-2" /> Add credit
                                    cards
                                </>
                            }
                            connectPrompt="Add another card"
                            onSavingConnection={handleAccountConnecting}
                            onConnected={handleAccountConnected}
                            accounts={creditCards}
                            isConnecting={busy}
                            allowConnect
                            {...connectAccountProps}
                        />
                    </section>
                </AccountsConnectionProvider>

                <OnboardingStepActions
                    onBack={onBack}
                    onBackDisabled={busy}
                    btnSize={newOnboarding ? undefined : "xl"}
                >
                    <Button
                        variant="primary"
                        size={newOnboarding ? null : ("xl" as any)}
                        disabled={
                            busy ||
                            (!allPlaidAccounts.length && !allowNoAccounts) ||
                            hasAccountWithNoType
                        }
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                    <div className="flex-break" />
                    {!newOnboarding && (
                        <div className="text-gray-700">
                            <small>Connection secured by </small>
                            <PlaidLogo
                                className="icon-color-current"
                                style={{ height: 20, width: 52 }}
                            />
                        </div>
                    )}
                </OnboardingStepActions>
            </OnboardingStep>

            <ConnectCreditCardsModal
                show={modal === Modals.CONNECT_CREDIT_CARDS}
                onConnected={handleAccountConnected}
                onContinue={finish}
                onHide={() => setModal(null)}
            />

            <ConnectMoreAccountsModal
                show={modal === Modals.CONNECT_MORE_ACCOUNTS}
                onConnected={handleAccountConnected}
                onContinue={finish}
                onHide={() => setModal(null)}
            />
        </>
    );
};
