import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useCategorizationRuleUpdateMutation } from "../../../mutations/categorizationRules";
import { ModalComponentProps } from "../../../helpers/modal";
import {
    CategorizationRuleForm,
    CategorizationRuleSubmitData,
} from "./CategorizationRuleForm";
import { TransactionCategorizationRule } from "../../../common/types/transactionCategorizationRule";

interface Props extends ModalComponentProps {
    rule: TransactionCategorizationRule;
}

export const EditRuleModal: React.FC<Props> = ({ rule, close }) => {
    const update = useCategorizationRuleUpdateMutation(rule, close);

    const onEdit = useCallback(
        async (data: CategorizationRuleSubmitData) => {
            await update.mutateAsync(data);
        },
        [update],
    );

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Update Rule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CategorizationRuleForm
                    rule={rule}
                    onSubmit={onEdit}
                    isSubmitting={update.isLoading}
                    submitText="Save"
                />
            </Modal.Body>
        </>
    );
};
