import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSettings } from "./SettingsProvider";

export const SettingsRoute: React.FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { open } = useSettings();

    useEffect(() => {
        const settingsPath = pathname
            .toLowerCase()
            .replace("/settings", "")
            .split("/")
            .filter(Boolean);

        if (settingsPath.length) {
            open(settingsPath);
        } else {
            open();
        }

        history.push("/");
    }, [history, open, pathname]);

    return null;
};
