import React from "react";
import { BulkUpdateStatus } from "../lib";
import "./TransactionsUpdateToast.scss";
import { BulkActionsToastData } from "../../../general/ToastMessages/lib/bulkActions";
import { BaseToastMessageData } from "../../../general/ToastMessages/lib/common";
import { ToastUpdating } from "./ToastUpdating";
import { ToastSuccess } from "./ToastSuccess";

type Props = BulkActionsToastData & BaseToastMessageData;

export const TransactionsUpdateToast: React.FC<Props> = (props) => {
    let content = null;

    switch (props.updateDetails.status) {
        case BulkUpdateStatus.UPDATING: {
            content = <ToastUpdating {...props} />;
            break;
        }
        case BulkUpdateStatus.SUCCESS: {
            content = <ToastSuccess {...props} />;
            break;
        }
    }

    return (
        content && <div className="transactions-update-toast">{content}</div>
    );
};
