import React from "react";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { useDispatch } from "react-redux";
import { addNotification } from "../../reducers/appState";
import { ButtonProps } from "react-bootstrap";
import { IntegrationAccount } from "../../common/types/integrationAccount";
import { useIntegrationAccountRemovalMutation } from "../../mutations/integrationAccount";

interface Props extends Omit<ButtonProps, "onClick"> {
    account: IntegrationAccount;
}
export const RemoveIntegrationAccount: React.FC<Props> = ({
    account,
    children,
    ...btnProps
}) => {
    const dispatch = useDispatch();
    const removal = useIntegrationAccountRemovalMutation(account, () => {
        dispatch(
            addNotification({
                message: "Account removed",
                type: "success",
            }),
        );
    });

    return (
        <ButtonWithLoader mutation={removal} {...btnProps}>
            {children}
        </ButtonWithLoader>
    );
};
