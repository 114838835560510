import React, { useCallback, useRef, useState } from "react";
import { format, formatISO, parse } from "date-fns";
import { InputMask } from "@react-input/mask";

import { DatePicker } from "../DatePicker/DatePicker";
import "./DatePickerInput.scss";
import {
    checkDateFormat,
    fullDateShortFormat,
    monthlyDateFormat,
} from "../../../../common/helpers/date";
import { useMobileView } from "../../../../hooks/useMobileView";
import { CommonDatepickerProps } from "../types";
import { addTimeZoneOffset } from "../../../../helpers/date";
import classNames from "classnames";
import {
    PopoverContainer,
    PopoverContainerHandle,
} from "../../../general/PopoverContainer";
import { InputSize, getInputClasses } from "../../../../helpers/form";

export interface DatePickerInputProps extends CommonDatepickerProps {
    value: string;
    onChange(value: string | undefined): void;
    onBlur?(): void;
    name: string;
    placeholder?: string;
    dateFormat?: string;
    emptyValue?: string;
    showYearSelect?: boolean;
    monthSelect?: boolean;
    inputClassName?: string;
    children?(close: () => void): React.ReactNode;
    inputSize?: InputSize;
    disabled?: boolean;
}

interface InputComponentProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    finalDateFormat: string;
}

const InputComponent = ({ finalDateFormat, ...props }: InputComponentProps) => {
    if (fullDateShortFormat === finalDateFormat) {
        return (
            <InputMask
                mask={finalDateFormat}
                replacement={{
                    d: /\d/,
                    M: /\d/,
                    y: /\d/,
                    h: /\d/,
                    m: /\d/,
                }}
                showMask={false}
                {...props}
            />
        );
    }
    return <input {...props} />;
};

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
    name,
    value,
    onChange,
    onBlur: onBlurProp,
    dateFormat,
    showYearSelect,
    placeholder,
    monthSelect,
    emptyValue = "",
    inputClassName,
    children,
    inputSize,
    disabled,
    ...commonProps
}) => {
    const isMobile = useMobileView();
    const finalDateFormat =
        dateFormat ?? (monthSelect ? monthlyDateFormat : fullDateShortFormat);
    const ref = useRef<PopoverContainerHandle>();

    const handleChange = useCallback(
        (newValue) => {
            if (!newValue) {
                return;
            }

            const formatted = formatISO(newValue, { representation: "date" });
            if (value !== formatted) {
                onChange(formatted);
                setLocalValue(format(newValue, finalDateFormat));
            }

            ref.current?.close();
        },
        [onChange, finalDateFormat, value],
    );

    const currentValueAsDate = value
        ? addTimeZoneOffset(new Date(value))
        : undefined;

    const classes = getInputClasses(inputSize);

    const [localValue, setLocalValue] = useState(
        currentValueAsDate
            ? format(currentValueAsDate, finalDateFormat)
            : emptyValue,
    );

    const handleLocalValueChange = useCallback(
        (newLocalValue: string) => {
            setLocalValue(newLocalValue);
            if (checkDateFormat(newLocalValue, finalDateFormat)) {
                const date = parse(newLocalValue, finalDateFormat, new Date());
                onChange(formatISO(date, { representation: "date" }));
            }
        },
        [onChange, finalDateFormat],
    );

    const onBlur = useCallback(() => {
        // NOTE: reset local value if proper date wasn't input
        setLocalValue(
            currentValueAsDate
                ? format(currentValueAsDate, finalDateFormat)
                : emptyValue,
        );
        if (onBlurProp) {
            onBlurProp();
        }
    }, [onBlurProp, emptyValue, finalDateFormat, currentValueAsDate]);

    return (
        <div className="date-picker-input form-group__input">
            <PopoverContainer
                ref={ref as any}
                offset={isMobile ? 0 : 8}
                id={`date-picker-input-${name}`}
                popoverClass="popover-container date-picker-input__popover"
                placement={isMobile ? "auto" : "bottom-start"}
                trigger={
                    <div>
                        <InputComponent
                            finalDateFormat={finalDateFormat}
                            className={classNames(
                                classes,
                                inputClassName,
                                finalDateFormat === fullDateShortFormat
                                    ? "date-picker-input-default-format-width"
                                    : undefined,
                                disabled && "form-control-disabled",
                            )}
                            onBlur={onBlur}
                            value={localValue}
                            onChange={(e) => {
                                handleLocalValueChange(e.target.value);
                            }}
                            placeholder={placeholder}
                            disabled={disabled}
                        />
                    </div>
                }
            >
                <DatePicker
                    initialValue={currentValueAsDate}
                    onChange={handleChange}
                    showYearSelect={showYearSelect}
                    maxDetail={monthSelect ? "year" : "month"}
                    value={currentValueAsDate}
                    {...commonProps}
                />
                {children ? (
                    <footer>{children(() => ref.current?.close())}</footer>
                ) : null}
            </PopoverContainer>
        </div>
    );
};
