import React from "react";
import { numberFormatter } from "../../../common/helpers/number";
import styles from "./ActionBar.module.scss";
import classNames from "classnames";

export interface ActionBarProps {
    className?: string;
    selectedCount: number;
    totalCount: number;
    hasSelectedAll: boolean;
    clearSelection(): void;
    selectAll(): void;
}

export const ActionBar: React.FC<ActionBarProps> = ({
    children,
    selectedCount,
    hasSelectedAll,
    totalCount,
    selectAll,
    clearSelection,
    className,
}) => {
    return (
        <section className={classNames(styles.actionBar, className)}>
            {selectedCount > 0 && (
                <main className={styles.wrapper}>
                    <section
                        className={classNames(
                            styles.section,
                            styles.selectionSection,
                        )}
                    >
                        <button
                            type="button"
                            className={classNames(
                                styles.button,
                                styles.selectedItemsButton,
                            )}
                            onClick={clearSelection}
                            data-testid="bulk-deselect-button"
                        >
                            {selectedCount} Selected
                            <span className={styles.buttonOverlay}>
                                Deselect
                            </span>
                        </button>
                        {!hasSelectedAll ? (
                            <>
                                <span className={styles.separator} />

                                <button
                                    type="button"
                                    className={styles.button}
                                    onClick={selectAll}
                                    data-testid="bulk-select-all-button"
                                >
                                    Select all{" "}
                                    {numberFormatter.format(totalCount)}
                                </button>
                            </>
                        ) : null}
                    </section>

                    <section
                        className={classNames(
                            styles.section,
                            styles.updatesSection,
                        )}
                    >
                        {children}
                    </section>
                </main>
            )}
        </section>
    );
};
