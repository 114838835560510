import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { CodeInput } from "./components/CodeInput";
import { VerificationCodeFormSchema } from "./types";

interface Props {
    small?: boolean;
}

export const CodeVerificationContent: React.FC<Props> = ({ small }) => {
    const { setFieldValue, submitForm } =
        useFormikContext<VerificationCodeFormSchema>();

    const onComplete = useCallback(() => {
        setTimeout(() => {
            submitForm();
        }, 0);
    }, [submitForm]);

    return (
        <CodeInput
            onChange={(value) => setFieldValue("verificationCode", value)}
            onComplete={onComplete}
            small={small}
        />
    );
};
