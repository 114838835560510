import React, { useContext } from "react";
import { Transaction } from "../../../common/types/transaction";
import { TransactionDetailsSection } from "./TransactionDetailsSection";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { CategorySelectPrompt } from "../CategorySelect/CategorySelectPrompt";
import { TransactionCategoryChange } from "../TransactionCategoryChange";

interface TransactionDetailsCategoryProps {
    transaction: Transaction;
}

export const TransactionDetailsCategory: React.FC<
    TransactionDetailsCategoryProps
> = ({ transaction }) => {
    const { onUpdate, onSplit } = useContext(TransactionDetailsContext);

    return (
        <TransactionDetailsSection
            className="transaction-details__section--category"
            addon={
                transaction.split?.length ? (
                    <CategorySelectPrompt
                        transaction={transaction}
                        onClick={onSplit}
                    />
                ) : (
                    <TransactionCategoryChange
                        transaction={transaction}
                        onUpdate={onUpdate}
                        enableWarning
                        placement="left-start"
                    />
                )
            }
        />
    );
};
