import React, { useCallback, useContext, useMemo, useState } from "react";
import { Transaction } from "../../common/types/transaction";
import { Loader } from "../general/Loader";
import { FiltersChangeFn, TransactionsFilters } from "./filters/lib";
import { NoMatchingTransactions } from "./NoMatchingTransactions";
import { NoTransactions } from "./NoTransactions";
import { TransactionDetailsContainer } from "./TransactionDetails/TransactionDetailsContainer";
import { TransactionSortValue } from "./useSort";
import { TransactionsTable } from "./TransactionsTable";
import { TransactionsTotals } from "./TransactionsTotals";
import { TransactionsContext } from "./context/transactions.context";
import { useActivityFeedCta } from "./useActivityFeedCta";
import { useFiltersActive } from "./useFiltersActive";
import { TransactionsHeader } from "./TransactionsHeader/TransactionsHeader";
import { isEqual, omitBy } from "lodash";
import { AggregatedTransactionFilters } from "./filters/AggregatedFilters/AggregatedTransactionFilters";
import { useClearFilters } from "./filters/hooks";
import { useBulkActions } from "./TransactionsBulkActions/useBulkActions";
import { useKeyboardCommands } from "../../hooks/useKeyboardCommands";
import { useSettings } from "../settings/SettingsProvider";
import { StandardModal } from "../general/Modal/Modal";
import { TransferMatchingContainer } from "./TransferMatching/TransferMatchingContainer";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

export interface TransactionPageProps {
    transactions?: Transaction[];
    currentFilters: TransactionsFilters;
    currentSort: TransactionSortValue;
    currentPage: number;
    totalPages: number;
    onFiltersChange: (filters: TransactionsFilters | null) => void;
    onSortChange: (value: TransactionSortValue) => void;
    exportTransactions: (withFilters: boolean) => Promise<void>;
}

export const TransactionsPage: React.FC<TransactionPageProps> = ({
    transactions,
    currentSort,
    currentFilters,
    onFiltersChange,
    onSortChange,
    exportTransactions,
    currentPage,
    totalPages,
    children,
}) => {
    const { shownTransactionId, setShownTransactionId } =
        useContext(TransactionsContext);

    const filtersActive = useFiltersActive(currentFilters);
    const clearFilters = useClearFilters(onFiltersChange);
    const bulkActions = useBulkActions();

    let empty: React.ReactNode;

    const onTransactionSelected = useCallback(
        (transaction?: Transaction) => {
            setShownTransactionId(transaction ? transaction.id : undefined);
        },
        [setShownTransactionId],
    );

    const handleFiltersChange: FiltersChangeFn = useCallback(
        (value: Partial<TransactionsFilters>) => {
            const newFilters = omitBy(
                { ...currentFilters, ...value },
                (v) => v === null || v === undefined,
            );

            if (!isEqual(newFilters, currentFilters)) {
                onFiltersChange(newFilters);
            }
        },
        [currentFilters, onFiltersChange],
    );

    if (!transactions) {
        empty = <Loader />;
    } else if (transactions.length) {
        empty = null;
    } else if (filtersActive) {
        empty = <NoMatchingTransactions onResetFilters={clearFilters} />;
    } else {
        empty = <NoTransactions />;
    }

    const [prependContent, appendContent] = useActivityFeedCta({
        filters: currentFilters,
        currentSort: currentSort,
        currentPage: currentPage,
        totalPages: totalPages,
        transactionSelected: !!shownTransactionId,
    });

    const { isOpened: settingsOpened } = useSettings();

    const selectAll = useCallback(
        (e: KeyboardEvent) => {
            if (
                e.target instanceof HTMLInputElement ||
                e.target instanceof HTMLTextAreaElement
            ) {
                return;
            }

            if (transactions) {
                bulkActions.select(transactions);
            }

            return {
                preventDefault: true,
                stopPropagation: true,
            };
        },
        [bulkActions, transactions],
    );
    const commands = useMemo(
        () => ({
            commands: settingsOpened
                ? []
                : [
                      {
                          key: "a",
                          callback: selectAll,
                          requiresCtrlOrMeta: true,
                          preventDefault: false,
                          stopPropagation: false,
                      },
                  ],
        }),
        [selectAll, settingsOpened],
    );
    useKeyboardCommands(commands);
    const [
        openedTransactionForTransferMatching,
        setOpenedTransactionForTransferMatching,
    ] = useState<Transaction | null>(null);
    const onTransferMatchClick = useCallback((transaction: Transaction) => {
        setOpenedTransactionForTransferMatching(transaction);
    }, []);

    return (
        <section className="transactions">
            <TransactionsHeader
                currentFilters={currentFilters}
                exportTransactions={exportTransactions}
            />

            <section className="mb-4">
                <AggregatedTransactionFilters
                    filters={currentFilters}
                    onChange={handleFiltersChange}
                />
            </section>

            <section className="transactions__body">
                <main>
                    <TransactionsTable
                        sort={currentSort}
                        onSortChange={onSortChange}
                        transactions={transactions}
                        shownTransactionId={shownTransactionId}
                        onTransactionSelected={onTransactionSelected}
                        prependContent={prependContent}
                        appendContent={appendContent}
                        footer={
                            <tfoot>
                                <tr>
                                    <td colSpan={shownTransactionId ? 7 : 8}>
                                        {transactions && (
                                            <TransactionsTotals
                                                transactions={transactions}
                                            />
                                        )}
                                    </td>
                                </tr>
                            </tfoot>
                        }
                        emptyState={empty}
                        onTransferMatchClick={onTransferMatchClick}
                    />
                    {children}
                </main>

                {shownTransactionId && (
                    <TransactionDetailsContainer
                        id={shownTransactionId}
                        onClose={() => setShownTransactionId()}
                    />
                )}
            </section>
            <StandardModal
                size="xl"
                className={classNames(
                    "fullscreen-modal",
                    "fullscreen-modal--expanded",
                )}
                show={Boolean(openedTransactionForTransferMatching)}
                onHide={() => {
                    setOpenedTransactionForTransferMatching(null);
                }}
            >
                <Modal.Header closeButton />
                {openedTransactionForTransferMatching && (
                    <TransferMatchingContainer
                        key={openedTransactionForTransferMatching.id}
                        transactionId={openedTransactionForTransferMatching.id}
                        transaction={openedTransactionForTransferMatching}
                    />
                )}
            </StandardModal>
        </section>
    );
};
