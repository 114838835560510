import React, { useMemo } from "react";
import "./AccountCard.scss";
import classNames from "classnames";
import { getAnonymousAccountNumber } from "../../../common/helpers/bankAccount";
import { isBoolean } from "lodash";
import { EMPTY_STATE } from "../../../common/constants";
import {
    getAccountNameWithLast4,
    getLeadingAccountConnection,
} from "../../../common/helpers/financialAccount";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { FinancialConnection } from "../../../common/types/financialConnection";
import { FinancialConnectionIcon } from "../../financialConnection/FinancialConnectionIcon";

export interface AccountCardProps {
    account: FinancialAccount;
    connection?: FinancialConnection;
    showNumber?: boolean;
    onlyNumber?: boolean;
    numberInName?: boolean;
    showType?: boolean;
    className?: string;
    iconSize?: "sm" | "md";
    maxNameLength?: number;
}

function getAccountClassificationLabel(
    account: FinancialAccount,
): "Business" | "Personal" | typeof EMPTY_STATE {
    if (!isBoolean(account.isBusiness)) {
        return EMPTY_STATE;
    }

    return account.isBusiness ? "Business" : "Personal";
}

export const AccountCard: React.FC<AccountCardProps> = ({
    account,
    connection,
    showNumber,
    numberInName,
    className,
    showType = true,
    onlyNumber = false,
    iconSize = "md",
    maxNameLength,
}) => {
    connection =
        connection ?? getLeadingAccountConnection(account) ?? undefined;

    let label: string | null = null;

    if (showNumber && account.accountNumberMask) {
        label = getAnonymousAccountNumber(account.accountNumberMask);
    } else if (showType) {
        label = getAccountClassificationLabel(account);
    }

    let displayedName: string = useMemo(() => {
        if (!account.accountNumberMask) {
            return account.name ?? "";
        }
        if (onlyNumber) {
            return getAnonymousAccountNumber(account.accountNumberMask, 0);
        }
        if (numberInName) {
            return getAccountNameWithLast4(account, 2);
        }

        return account.name ?? "";
    }, [account, onlyNumber, numberInName]);

    if (maxNameLength && displayedName.length > maxNameLength) {
        displayedName = displayedName.slice(0, maxNameLength) + "...";
    }

    return (
        <div
            className={classNames("account-card", className, {
                "account-card--only-number":
                    onlyNumber && account.accountNumberMask,
            })}
        >
            <div
                className={`account-card__icon account-card__icon--${iconSize}`}
            >
                <FinancialConnectionIcon
                    connection={connection}
                    account={account}
                />
            </div>
            <div className="account-card__name-type">
                <div className="account-card__name" data-testid="account-name">
                    {displayedName}
                </div>
                {!onlyNumber && (
                    <div className="account-card__type">{label}</div>
                )}
            </div>
        </div>
    );
};
