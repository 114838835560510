import { format } from "date-fns";
import { cloneDeep } from "lodash";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { REPORTS_CONTROLLER_DATE_FORMAT } from "../../common/constants";
import { WithRequiredProperties } from "../../common/types/base/generics";
import { ReportFilters } from "../../common/types/filters/reports";
import { useEntities } from "../../hooks/useEntities";
import { getCashFlowTabReport } from "../../lib/ownerReports";
import { CashFlowContext } from "./cash-flow.context";
import { Taxonomy } from "../../common/categories";

interface Props {
    filters: ReportFilters;
}

interface ReportsQueryOptions {
    filters: WithRequiredProperties<ReportFilters, "entitiesAccounts">;
}

function useCashFlowQuery({ filters }: ReportsQueryOptions) {
    return useQuery(
        ["cashFlowTabReport", filters],
        async () => {
            const filtersUsed = cloneDeep(filters);
            return {
                report: await getCashFlowTabReport({
                    startDate: filters.start
                        ? format(filters.start, REPORTS_CONTROLLER_DATE_FORMAT)
                        : undefined,
                    endDate: filters.end
                        ? format(filters.end, REPORTS_CONTROLLER_DATE_FORMAT)
                        : undefined,
                    cycle: filters.cycle,
                    entitiesAccounts: filters.entitiesAccounts,
                    category: filters.category
                        ?.map(
                            (category) =>
                                category.type === "category" && category.id,
                        )
                        .filter(
                            (category): category is Taxonomy =>
                                category !== false,
                        ),
                    customCategory: filters.category
                        ?.map(
                            (category) =>
                                category.type === "customCategory" &&
                                category.id,
                        )
                        .filter((category) => category !== false),
                }),
                filtersUsed,
                reportCreatedAt: new Date().getTime(),
            };
        },
        {
            refetchOnWindowFocus: false,
        },
    );
}

export const CashFlowContextProvider: React.FC<Props> = ({
    filters,
    children,
}) => {
    const entities = useEntities();
    const filtersToPass = {
        ...filters,
        entitiesAccounts:
            filters.entitiesAccounts && filters.entitiesAccounts.length > 0
                ? filters.entitiesAccounts
                : entities.map((e) => ({
                      entityId: e.id,
                  })),
    };
    const { data, isLoading: isCashFlowReportLoading } = useCashFlowQuery({
        filters: filtersToPass,
    });

    const value = useMemo(() => {
        return {
            cashFlowReport: data?.report,
            cashFlowFiltersUsed: data?.filtersUsed,
            reportCreatedAt: data?.reportCreatedAt,
            isCashFlowReportLoading,
        };
    }, [data, isCashFlowReportLoading]);

    return (
        <CashFlowContext.Provider value={value}>
            {children}
        </CashFlowContext.Provider>
    );
};
