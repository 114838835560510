import { uniq } from "lodash";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { REAL_ESTATE_CATEGORIES } from "../../../../../common/categories";
import { Category } from "../../../../../common/types/category";
import { GenericCategory } from "../../../../../common/types/genericCategory";
import { useCategories } from "../../../../../hooks/useCategories";
import { useCustomCategories } from "../../../../../hooks/useCustomCategories";
import { useEntities } from "../../../../../hooks/useEntities";
import { useRealEstateEnabled } from "../../../../../hooks/useRealEstateEnabled";
import { useUser } from "../../../../../hooks/useUser";
import { FilterSearch } from "../../../../general/FilterSearch/FilterSearch";
import { Tree } from "../../../../general/Tree/Tree";
import { TreeNode } from "../../../../general/Tree/Tree.types";
import { TreeProvider } from "../../../../general/Tree/TreeProvider";
import { ClearFilter } from "../../../ClearFilter/ClearFilter";
import { FilterProps } from "../../lib";
import css from "./AggregatedCategoryFilter.module.scss";
import { CategoryTreeItem } from "./CategoryTreeItem";
import { useExpandParentCategory } from "./useExpandParentCategory";

interface AggregatedCategorySearchProps extends FilterProps {}

export const AggregatedCategoryFilter: React.FC<
    AggregatedCategorySearchProps
> = ({ filters, onChange }) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();
    const realEstateEnabled = useRealEstateEnabled();
    const categories = useCategories();
    const selectedEntities = useEntities().filter((entity) => {
        const { entitiesAccounts } = filters;
        if (!entitiesAccounts) {
            return true;
        }
        return entitiesAccounts.some(({ entityId }) => entityId === entity.id);
    });
    const user = useUser();
    const customCategories = uniq([
        ...selectedEntities.map((entity): number | null => {
            return null; // should be the primary admin of this entity
        }),
        user.id,
    ])
        .filter((id): id is number => id != null)
        .flatMap(useCustomCategories);

    const forceExpanded = useExpandParentCategory(filters.category);

    const isChecked = useCallback(
        (category: GenericCategory): boolean =>
            filters.category?.find(
                (categoryFilter) =>
                    categoryFilter.type === category.type &&
                    categoryFilter.id === category.id,
            ) != null,
        [filters.category],
    );

    const handleChange = useCallback(
        (category: GenericCategory): void => {
            const categoryWithSubcategories: GenericCategory[] = [
                category,
                ...(category.type === "category"
                    ? [
                          ...(category.subcategories ?? []),
                          ...customCategories.filter(
                              ({ mainCategoryId }) =>
                                  mainCategoryId === category.id,
                          ),
                      ]
                    : []),
            ];

            if (isChecked(category)) {
                const newValue =
                    filters.category?.filter(
                        (categoryFilter) =>
                            !categoryWithSubcategories.some(
                                (c) =>
                                    c.id === categoryFilter.id &&
                                    c.type === categoryFilter.type,
                            ),
                    ) ?? [];

                onChange({
                    category: newValue.length > 0 ? newValue : undefined,
                });
            } else {
                onChange({
                    category: [
                        ...(filters.category ?? []),
                        ...categoryWithSubcategories,
                    ],
                });
            }

            searchRef.current?.focus();
        },
        [customCategories, isChecked, filters.category, onChange],
    );

    const categoryMatchesSearch = useCallback(
        (category: GenericCategory) =>
            category.label.toLowerCase().includes(search.toLowerCase()),
        [search],
    );

    const categoriesToDisplay = useMemo(() => {
        return categories
            .filter(
                (category) =>
                    realEstateEnabled ||
                    !REAL_ESTATE_CATEGORIES.includes(category.id),
            )
            .filter((category) =>
                [category, ...getSubcategories(category)].some((cat) =>
                    categoryMatchesSearch(cat),
                ),
            )
            .map((category) => ({
                ...category,
                children: getSubcategories(category).filter(
                    (subcategory) =>
                        categoryMatchesSearch(subcategory) ||
                        categoryMatchesSearch(category),
                ),
            }));

        function getSubcategories(category: Category): GenericCategory[] {
            return [
                ...category.subcategories,
                ...customCategories.filter(
                    ({ mainCategoryId }) => mainCategoryId === category.id,
                ),
            ];
        }
    }, [
        categories,
        realEstateEnabled,
        customCategories,
        categoryMatchesSearch,
    ]);

    useEffect(() => {
        searchRef.current?.focus();

        return () => {
            setSearch("");
        };
    }, []);

    return (
        <div className={css.filter}>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
            />
            <div className={css.categories}>
                <Scrollbars style={{ width: "100%", height: "100%" }}>
                    {categoriesToDisplay.length > 0 ? (
                        <TreeProvider
                            items={categoriesToDisplay}
                            expandAll={!!search}
                            forceExpanded={forceExpanded}
                        >
                            <Tree
                                renderNode={(
                                    node: TreeNode<GenericCategory>,
                                ) => (
                                    <CategoryTreeItem
                                        node={node}
                                        handleChange={handleChange}
                                        isChecked={isChecked}
                                    />
                                )}
                            />
                        </TreeProvider>
                    ) : (
                        <p className={css.empty}>No matching categories</p>
                    )}
                </Scrollbars>
            </div>

            {!!filters.category?.length && (
                <ClearFilter onClick={() => onChange({ category: undefined })}>
                    Deselect {filters.category.length}
                </ClearFilter>
            )}
        </div>
    );
};
