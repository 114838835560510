import React, { useCallback } from "react";
import { Loader } from "../general/Loader";
import { Link, useHistory } from "react-router-dom";
import { TransactionsTable } from "../transactions/TransactionsTable";
import { NoTransactions } from "../transactions/NoTransactions";
import {
    Transaction,
    TransactionClassification,
} from "../../common/types/transaction";
import { SortOrder } from "../../common/constants";
import { TransactionsTableCta } from "../transactions/TransactionsTableCta/TransactionsTableCta";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import styles from "./Activity.module.scss";
import { useTransactionsQuery } from "../transactions/useTransactionsQuery";
import { useEntities } from "../../hooks/useEntities";

const TRANSACTIONS_COUNT = 10;

export const DashboardTransactions: React.FC = () => {
    const { showLimitReachedCta } = useBillingManagement();
    const history = useHistory();
    const entities = useEntities();

    const { data } = useTransactionsQuery({
        filters: {
            classification: TransactionClassification.BUSINESS,
            entitiesAccounts: entities.map((entity) => ({
                entityId: entity.id,
            })),
        },
        limit: TRANSACTIONS_COUNT,
        sort: {
            field: "date",
            order: SortOrder.DESC,
        },
        page: 1,
    });
    const transactions = data?.data;

    const emptyState =
        transactions && transactions.length === 0 ? <NoTransactions /> : null;

    const onSelected = useCallback(
        (transaction: Transaction) => {
            history.push(
                `/transactions?transactionId=${transaction.id}&transactionType=${transaction.type}`,
            );
        },
        [history],
    );

    return transactions ? (
        <>
            <section className={styles.transactions}>
                <TransactionsTable
                    transactions={transactions}
                    emptyState={emptyState}
                    onTransactionSelected={onSelected}
                    showAccountNames={false}
                    prependContent={
                        showLimitReachedCta ? (
                            <TransactionsTableCta rows={4} />
                        ) : null
                    }
                />
            </section>

            <footer>
                <Link to="/transactions" className="btn btn-secondary btn-sm">
                    All Transactions
                </Link>
            </footer>
        </>
    ) : (
        <Loader />
    );
};
