import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { AppliedDiscount } from "../../../../../common/types/billing";
import quoteStyles from "./Quote.module.scss";
import styles from "./DiscountCode.module.scss";
import classNames from "classnames";
import { currencyFormatter } from "../../../../../common/helpers/currency";
import { ButtonWithLoader } from "../../../../general/ButtonWithLoader/ButtonWithLoader";
import { Field, Form, Formik, FormikConfig } from "formik";
import { submitHelper } from "../../../../../helpers/form";
import { FormRowRaw } from "../../../../forms/FormRowRaw";

interface DiscountCodeInputProps {
    codeInvalid?: boolean;
    appliedDiscount: AppliedDiscount | null;
    onApplyDiscount(code: string): Promise<void>;
    onClearDiscount(): void;
}

export const DiscountCode: React.FC<DiscountCodeInputProps> = ({
    appliedDiscount,
    onApplyDiscount,
    onClearDiscount,
    codeInvalid,
}) => {
    const [isEditing, setIsEditing] = useState(!!appliedDiscount);
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<{ code: string }> = {
        initialValues: { code: "" },
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ code }) => {
                if (code) {
                    await onApplyDiscount(code);
                }
            },
        }),
        onReset() {
            onClearDiscount();
        },
    };

    if (!isEditing) {
        return (
            <section>
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => setIsEditing(true)}
                >
                    Add coupon
                </Button>
            </section>
        );
    }

    return (
        <section>
            <header className={quoteStyles.heading}>Coupon</header>

            <main className={classNames(quoteStyles.details)}>
                <Formik {...form}>
                    {({ values }) => (
                        <Form className={styles.form}>
                            <FormRowRaw
                                value={values.code}
                                field={
                                    <Field
                                        name="code"
                                        className="form-control form-control-sm"
                                    />
                                }
                                error={
                                    codeInvalid
                                        ? "Invalid or expired code"
                                        : undefined
                                }
                                showErrorMessage={codeInvalid}
                            />

                            {appliedDiscount ? (
                                <Button
                                    type="reset"
                                    variant="secondary"
                                    size="sm"
                                >
                                    Remove
                                </Button>
                            ) : (
                                <ButtonWithLoader
                                    loading={loading}
                                    variant="secondary"
                                    type="submit"
                                    size="sm"
                                >
                                    Add
                                </ButtonWithLoader>
                            )}
                        </Form>
                    )}
                </Formik>
            </main>

            {appliedDiscount && (
                <ul className={quoteStyles.details}>
                    <li>
                        <span>
                            Discount{" "}
                            {appliedDiscount.type === "percent" && (
                                <>(${appliedDiscount.value}%)</>
                            )}
                        </span>

                        <aside>
                            -{currencyFormatter.format(appliedDiscount.amount)}
                        </aside>
                    </li>
                </ul>
            )}
        </section>
    );
};
