import { createContext, useContext } from "react";
import {
    ProfitLossReportDto,
    SpendingReportDto,
} from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { ReportFilters } from "../../common/types/filters/reports";

export interface ReportsContextValue {
    profitLossReport?: ProfitLossReportDto;
    isProfitLossReportLoading: boolean;
    profitLossFiltersUsed?: ReportFilters;
    reportCreatedAt?: number;
    spendingFiltersUsed?: ReportFilters;
    spendingReport?: SpendingReportDto;
    isSpendingReportLoading: boolean;
}

export const ReportsContext = createContext<ReportsContextValue>({
    isProfitLossReportLoading: true,
    isSpendingReportLoading: true,
});

export function useReportsContext(): ReportsContextValue {
    const ctx = useContext(ReportsContext);
    if (!ctx) {
        throw new Error("Can only be used inside a ReportsContextProvider");
    }

    return ctx;
}
