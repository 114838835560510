import React, { useCallback, useEffect, useState } from "react";
import { ManualImportDropzone } from "./ManualImportDropzone";
import { useFinancialDocumentUpload } from "../documents/FinancialDocumentUpload/useFinancialDocumentUpload";
import { ImportedDocuments } from "./ImportedDocuments";
import { UploadingDocumentPlaceholder } from "../documents/lib";
import { without } from "lodash";
import { useRemoveFinancialDocumentMutation } from "../../mutations/financialDocument";
import { FinancialAccountType } from "../../common/types/financialAccount";
import { Field, Form, Formik, FormikConfig } from "formik";
import { submitHelper } from "../../helpers/form";
import { number, object, string } from "yup";
import { useEntities } from "../../hooks/useEntities";
import { FormRow } from "../forms/FormRow";
import styles from "./ManualImportAccountModal.module.scss";
import { FINANCIAL_ACCOUNT_TYPE_LABEL } from "../../common/constants";
import { EntitySelect } from "../entity/EntitySelect";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";

interface ManualImportAccountFormSchema {
    nickname?: string;
    bankName: string;
    accountNumber: string;
    accountType: FinancialAccountType;
    entityId: number;
}

export const ManualImport: React.FC = () => {
    const [creatingAccount, setCreatingAccount] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const { uploadingDocuments, updateUploads } = useFinancialDocumentUpload();
    const removeFinancialDocumentMutation =
        useRemoveFinancialDocumentMutation();
    const handleRemoveItem = useCallback(
        (item: UploadingDocumentPlaceholder) => {
            if (!item.financialDocument) {
                return;
            }

            removeFinancialDocumentMutation.mutate(item.financialDocument);
            updateUploads((prev) => without(prev, item));
        },
        [removeFinancialDocumentMutation, updateUploads],
    );

    const allowedEntities = useEntities({ excludeAllMockEntities: true });

    useEffect(() => {
        if (uploadingDocuments.length) {
            setShowDetails(true);
        }
    }, [uploadingDocuments.length]);

    const form: FormikConfig<ManualImportAccountFormSchema> = {
        initialValues: {
            nickname: "",
            bankName: "",
            accountNumber: "",
            accountType: null as any,
            entityId: null as any,
        },
        validationSchema: object().shape({
            nickname: string().optional(),
            bankName: string().required("Provide bank account"),
            accountNumber: string()
                .required("Provide account number")
                .matches(/^\d+$/, "Only numbers allowed"),
            accountType: string()
                .required("Select account type")
                .oneOf(Object.values(FinancialAccountType)),
            entityId: number()
                .required("Select entity")
                .oneOf(
                    allowedEntities.map((e) => e.id),
                    "Invalid entity",
                ),
        }),
        onSubmit: submitHelper({
            loading: creatingAccount,
            setLoading: setCreatingAccount,
            async handler(
                values: ManualImportAccountFormSchema,
            ): Promise<void> {
                // temporary until the backend is ready
                // eslint-disable-next-line no-console
                console.log(values);
            },
        }),
    };

    return (
        <Formik {...form}>
            <Form>
                <FormRow
                    fieldName="nickname"
                    size="sm"
                    label="Account nickname (optional)"
                />

                <section className="mb-4">
                    <ManualImportDropzone>
                        <ImportedDocuments
                            items={uploadingDocuments}
                            onRemoveItem={handleRemoveItem}
                        />
                    </ManualImportDropzone>
                </section>

                {showDetails && (
                    <section className={styles.accountDetails}>
                        <FormRow fieldName="accountType" label="Account type">
                            <Field
                                name="accountType"
                                as="select"
                                className="form-control form-control-sm"
                            >
                                <option value="">Select</option>
                                {Object.values(FinancialAccountType).map(
                                    (type) => (
                                        <option key={type} value={type}>
                                            {FINANCIAL_ACCOUNT_TYPE_LABEL[type]}
                                        </option>
                                    ),
                                )}
                            </Field>
                        </FormRow>

                        <FormRow fieldName="entityId">
                            {({ setValue }, { value }) => (
                                <EntitySelect
                                    label="Entity"
                                    entities={allowedEntities}
                                    selectedEntity={allowedEntities.find(
                                        (e) => e.id === value,
                                    )}
                                    onChange={(e) => setValue(e?.id)}
                                    dropdownKey="importer-entity-select"
                                    size="sm"
                                />
                            )}
                        </FormRow>

                        <FormRow
                            fieldName="accountNumber"
                            size="sm"
                            label="Account number"
                        />

                        <FormRow
                            fieldName="bankName"
                            size="sm"
                            label="Bank name"
                        />
                    </section>
                )}

                {showDetails && (
                    <footer className="text-right">
                        <ButtonWithLoader
                            loading={creatingAccount}
                            type="submit"
                        >
                            Create Account
                        </ButtonWithLoader>
                    </footer>
                )}
            </Form>
        </Formik>
    );
};
