import {
    addMinutes,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfYear,
    format,
    formatISO,
    isSameDay,
    isSameYear,
    parseISO,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subDays,
    subMinutes,
    subMonths,
    subQuarters,
    subYears,
} from "date-fns";

import {
    fullDateFormat,
    fullDateShortFormat,
    monthlyDateFormatShort,
    monthOnlyFormat,
    shortDateFormatter,
} from "../common/helpers/date";
import { pluralize } from "../common/helpers/string";
import { keyBy } from "lodash";

export function getDateFromInput(stringValue: string) {
    return stringValue ? parseISO(stringValue) : undefined;
}

export function setDateToInput(value: Date | undefined) {
    return value ? formatISO(value, { representation: "date" }) : "";
}

export interface DateRangePreset {
    id: string;
    label: string;
    getStart: (today: Date) => Date;
    getEnd: (today: Date) => Date;
}

export interface DateRange {
    start: Date;
    end: Date;
}

export const DATE_RANGE_PRESETS: DateRangePreset[] = [
    {
        id: "last_month",
        label: "Last month",
        getStart: (today) => startOfMonth(subMonths(today, 1)),
        getEnd: (today) => startOfDay(endOfMonth(subMonths(today, 1))),
    },
    {
        id: "this_month",
        label: "This month",
        getStart: (today) => startOfMonth(today),
        getEnd: (today) => startOfDay(today),
    },
    {
        id: "last_4_months",
        label: "Last 4 months",
        getStart: (today) => startOfMonth(subMonths(today, 3)),
        getEnd: (today) => startOfDay(today),
    },
    {
        id: "last_30_days",
        label: "Last 30 days",
        getStart: (today) => startOfDay(subDays(today, 29)),
        getEnd: (today) => endOfDay(today),
    },
    {
        id: "this_quarter",
        label: "This quarter",
        getStart: (today) => startOfQuarter(today),
        getEnd: (today) => startOfDay(today),
    },
    {
        id: "last_quarter",
        label: "Last quarter",
        getStart: (today) => startOfQuarter(subQuarters(today, 1)),
        getEnd: (today) => startOfDay(endOfQuarter(subQuarters(today, 1))),
    },
    {
        id: "this_year",
        label: "This year",
        getStart: (today) => startOfYear(today),
        getEnd: (today) => startOfDay(today),
    },
    {
        id: "last_year",
        label: "Last year",
        getStart: (today) => startOfYear(subYears(today, 1)),
        getEnd: (today) => startOfDay(endOfYear(subYears(today, 1))),
    },
];

export const DATE_RANGE_PRESETS_BY_ID: Record<string, DateRangePreset> = keyBy(
    DATE_RANGE_PRESETS,
    (p) => p.id,
);

export function getDateRangeLabel(
    start: Date | undefined,
    end: Date | undefined,
    monthOnly: boolean = false,
): string {
    let label = "";
    const today = startOfDay(new Date());

    if (start && end) {
        const matchingPreset = Object.values(DATE_RANGE_PRESETS_BY_ID).find(
            (p) =>
                isSameDay(p.getStart(today), start) &&
                isSameDay(p.getEnd(today), end),
        );

        if (matchingPreset) {
            label = matchingPreset.label;
        } else if (
            isSameDay(start, startOfMonth(end)) &&
            isSameDay(end, endOfMonth(start))
        ) {
            label = format(start, "MMMM");

            if (!isSameYear(start, today)) {
                label += ` '${start.getFullYear() % 100}`;
            }
        } else {
            label = `${format(
                start,
                monthOnly ? monthlyDateFormatShort : fullDateShortFormat,
            )} - ${format(
                end,
                monthOnly ? monthlyDateFormatShort : fullDateShortFormat,
            )}`;
        }
    } else if (start) {
        const sameYearFrom = monthOnly
            ? format(start, monthOnlyFormat)
            : shortDateFormatter.format(start);
        const differentYearFrom = format(
            start,
            monthOnly ? monthlyDateFormatShort : fullDateFormat,
        );

        label = `from ${
            isSameYear(start, today) ? sameYearFrom : differentYearFrom
        }`;
    } else if (end) {
        const sameYearTo = monthOnly
            ? format(end, monthOnlyFormat)
            : shortDateFormatter.format(end);
        const differentYearTo = format(
            end,
            monthOnly ? monthlyDateFormatShort : fullDateFormat,
        );

        label = `to ${isSameYear(end, today) ? sameYearTo : differentYearTo}`;
    }

    return label;
}

export function addTimeZoneOffset(date: Date): Date {
    return addMinutes(date, date.getTimezoneOffset());
}

export function removeTimeZoneOffset(date: Date): Date {
    return subMinutes(date, date.getTimezoneOffset());
}

export function timeAgo(date: Date | string) {
    date = typeof date === "string" ? new Date(date) : date;
    const now = new Date();

    const days = differenceInDays(now, date);

    if (days) {
        return `${days} ${pluralize("day", days)} ago`;
    }

    const hours = differenceInHours(now, date);

    if (hours) {
        return `${hours}h ago`;
    }

    const minutes = differenceInMinutes(now, date);

    if (minutes) {
        return `${minutes} min ago`;
    }

    return "just now";
}

const US_DATE_FORMAT = /1?\d\/[1-3]?\d\/\d{2,4}/;

export function formatDate(date: Date) {
    return formatISO(date, { representation: "date" });
}

export function normalizeDateString(date: string) {
    if (US_DATE_FORMAT.test(date)) {
        return formatDate(new Date(date));
    } else {
        return date;
    }
}

export function formatDateForTransaction(date: Date) {
    return isSameYear(date, new Date())
        ? shortDateFormatter.format(date)
        : format(date, fullDateFormat);
}
