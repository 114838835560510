import React from "react";
import { Button } from "react-bootstrap";
import "./EmptyState.scss";

export type EmptyStateProps = {
    header: string;
    body: React.ReactNode;
    ctaText?: React.ReactNode;
    action?: () => void;
    buttonVariant?: string;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
    header,
    body,
    ctaText,
    action,
    buttonVariant = "option",
}) => {
    return (
        <section className="transactions-empty-state">
            <span
                className="transactions-empty-state__icon"
                role="img"
                aria-label={header}
            >
                📈
            </span>

            <header className="transactions-empty-state__header">
                {header}
            </header>
            <p className="transactions-empty-state__body">{body}</p>
            {ctaText && action ? (
                <footer className="transactions-empty-state__cta">
                    <Button variant={buttonVariant} block onClick={action}>
                        {ctaText}
                    </Button>
                </footer>
            ) : null}
        </section>
    );
};
