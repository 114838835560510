import { FINANCIAL_ACCOUNT_TYPE_LABEL } from "../constants";
import {
    FinancialAccount,
    FinancialAccountSourceType,
    FinancialAccountType,
} from "../types/financialAccount";
import { FinancialConnection } from "../types/financialConnection";
import { getAnonymousAccountNumber } from "./bankAccount";

export const MANUALLY_SYNCED_ACCOUNTS: FinancialAccountSourceType[] = [
    FinancialAccountSourceType.FILE_IMPORT,
] as const;
export const ACCOUNTS_POSSIBLY_PERSONAL: FinancialAccountSourceType[] = [
    FinancialAccountSourceType.FILE_IMPORT,
    FinancialAccountSourceType.PLAID,
] as const;

export function getAccountNameWithLast4(
    account: FinancialAccount,
    accountNumberMaskLength = 4,
) {
    const name = account.name ?? "";

    if (/\d{4}$/.test(name) || !account.accountNumberMask) {
        return name;
    } else {
        return `${name} ${getAnonymousAccountNumber(
            account.accountNumberMask,
            accountNumberMaskLength,
        )}`;
    }
}

export function getAccountShortName(account: FinancialAccount) {
    switch (account.sourceType) {
        case FinancialAccountSourceType.PLAID:
            return FINANCIAL_ACCOUNT_TYPE_LABEL[account.financialType];
        case FinancialAccountSourceType.STRIPE:
            return "Stripe";
        case FinancialAccountSourceType.GUSTO:
            return "Gusto";
        default:
            return account.name;
    }
}

export function isCardAccount(account: FinancialAccount) {
    return account.financialType === FinancialAccountType.CREDIT_CARD;
}

export function getLeadingAccountConnection(
    account: FinancialAccount,
): FinancialConnection | null {
    return account.integrationAccounts?.[0]?.connection ?? null;
}
