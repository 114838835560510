import { Entity } from "../../../common/types/entity";
import { pluralize } from "../../../common/helpers/string";
import styles from "./EntityIconList.module.scss";
import { IconWithTooltip } from "./IconWithTooltip";
import { EntityIcon } from "../../entity/EntityIcon";
import React from "react";
import { ObjectIcon } from "../ObjectIcon/ObjectIcon";

export interface EntityIconListProps {
    entities: Entity[];
    maxEntitiesToDisplay?: number;
}

export const EntityIconList: React.FC<EntityIconListProps> = ({
    entities,
    maxEntitiesToDisplay = 4,
}) => {
    const shouldAggregate = entities.length > maxEntitiesToDisplay;

    const label = (
        <span className="ml-2">
            {entities.length} {pluralize("Entity", entities.length)}
        </span>
    );

    if (shouldAggregate) {
        const individualEntities = entities.slice(0, maxEntitiesToDisplay - 1);
        const otherEntities = entities.slice(maxEntitiesToDisplay - 1);

        return (
            <span className={styles.list}>
                {individualEntities.map((entity) => (
                    <IconWithTooltip
                        key={entity.id}
                        tooltipId={`filter-entity-${entity.id}`}
                        tooltip={entity.name}
                    >
                        <EntityIcon entity={entity} size="xs" />
                    </IconWithTooltip>
                ))}

                <IconWithTooltip
                    tooltipId={`filter-entity-more`}
                    tooltip={otherEntities.map((entity) => (
                        <React.Fragment key={entity.id}>
                            {entity.name} <br />
                        </React.Fragment>
                    ))}
                >
                    <ObjectIcon defaultColor="#D9DDE2" size="xs" square dynamic>
                        +{entities.length - individualEntities.length}
                    </ObjectIcon>
                </IconWithTooltip>

                {label}
            </span>
        );
    } else {
        return (
            <span className={styles.list}>
                {entities.map((entity) => (
                    <IconWithTooltip
                        key={entity.id}
                        tooltipId={`filter-entity-${entity.id}`}
                        tooltip={entity.name}
                    >
                        <EntityIcon entity={entity} size="xs" />
                    </IconWithTooltip>
                ))}
                {label}
            </span>
        );
    }
};
