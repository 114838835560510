import React, { useMemo } from "react";
import { TaxQuestionAnswerComponentProps } from "../../TaxQuestions/types";
import { getAnswerText, OTHER_ANSWER } from "../../../../common/taxSavingRules";
import { CustomSelect } from "../../../forms/CustomSelect/CustomSelect";

interface Props extends TaxQuestionAnswerComponentProps {
    children?: React.ReactNode | ((open: boolean) => React.ReactNode);
}
export const ActionBarQuestionAnswerPicker: React.FC<Props> = ({
    question,
    answer,
    onAnswer,
    children,
}) => {
    const options = useMemo(
        () => [
            ...question.options.map((option) => ({
                value: getAnswerText(option),
            })),
            { value: OTHER_ANSWER },
        ],
        [question.options],
    );

    return (
        <CustomSelect
            onSelected={(newAnswer) => onAnswer(question.key, newAnswer)}
            dropdownKey={`dropdown_${question.key}`}
            options={options}
            empty="No matching answer"
            placement="top-end"
            searchable={options.length > 10}
        >
            {(open) =>
                children instanceof Function ? children(open) : children
            }
        </CustomSelect>
    );
};
