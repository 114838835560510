import React, {
    ChangeEvent,
    FocusEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import "./CodeInput.scss";
import { VERIFICATION_CODE_LENGTH } from "../../../../constants";

export type CodeInputProps = {
    value?: string;
    onChange?: (value: string) => void;
    onComplete?: (value: string) => void;
    small?: boolean;
};

const BACKSPACE_CODE = 8;
const LEFT_ARROW_CODE = 37;
const RIGHT_ARROW_CODE = 39;

function valueToCode(value: string = ""): string[] {
    return Array.from(
        { length: VERIFICATION_CODE_LENGTH },
        (_, i) => value[i] || "",
    );
}

export const CodeInput: React.FC<CodeInputProps> = ({
    value,
    onChange,
    onComplete,
    small,
}) => {
    const [code, setCode] = useState<Array<string>>(valueToCode(value));
    const refs = useRef<Array<HTMLInputElement | null>>([]);

    const focusInputAt = useCallback((i) => {
        const normalized = Math.max(
            Math.min(i, VERIFICATION_CODE_LENGTH - 1),
            0,
        );
        const input: HTMLInputElement | null = refs.current[normalized];

        if (input) {
            input.focus();
            setTimeout(() => {
                input.select();
            }, 0);
        }
    }, []);

    const blurAll = useCallback(() => {
        refs.current.forEach((input) => {
            input?.blur();
        });
    }, []);

    const setCodeAt = useCallback((i: number, val: string) => {
        setCode((prev) => {
            const newCode = [...prev];
            newCode[i] = val;
            return newCode;
        });
    }, []);

    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            const i = Number(ev.target.dataset.i);

            const fullInput = String(ev.target.value).replace(/\D+/g, "");
            let rawCode = [...code];
            let codeFilled = false;

            fullInput.split("").forEach((val, overflow) => {
                rawCode[i + overflow] = val;

                if (overflow === VERIFICATION_CODE_LENGTH - 1) {
                    codeFilled = true;
                }
            });

            rawCode = rawCode.slice(0, VERIFICATION_CODE_LENGTH);

            setCode(rawCode);
            if (i < VERIFICATION_CODE_LENGTH - 1) {
                if (!codeFilled) {
                    focusInputAt(i + fullInput.split("").length);
                }
            } else {
                codeFilled = true;
            }

            if (onChange) {
                onChange(rawCode.join(""));
            }

            if (codeFilled && rawCode.every((el) => el !== "")) {
                blurAll();
                onComplete?.(rawCode.join(""));
            }
        },
        [code, focusInputAt, onChange, onComplete, blurAll],
    );

    const handleInput = useCallback(
        (ev: KeyboardEvent<HTMLInputElement>) => {
            const i = Number((ev.target as HTMLInputElement).dataset.i);

            switch (
                ev.keyCode // nosonar
            ) {
                case BACKSPACE_CODE:
                    setCodeAt(i, "");
                    focusInputAt(i - 1);
                    break;

                case LEFT_ARROW_CODE:
                    focusInputAt(i - 1);
                    break;

                case RIGHT_ARROW_CODE:
                    focusInputAt(i + 1);
                    break;
            }
        },
        [focusInputAt, setCodeAt],
    );

    const handleClick = useCallback(() => {
        if (code.every((el) => el === "")) {
            focusInputAt(0);
        }
    }, [code, focusInputAt]);

    const handleFocus = useCallback((ev: FocusEvent<HTMLInputElement>) => {
        ev.target.select();
    }, []);

    useEffect(() => {
        focusInputAt(0);
    }, [focusInputAt]);

    useEffect(() => {
        setCode(valueToCode(value));
    }, [value]);

    return (
        <div
            className={classNames("codeInput", {
                "codeInput--small": small,
            })}
        >
            {code.map((codeValue, i) => (
                <input
                    value={codeValue}
                    data-i={i}
                    onChange={handleChange}
                    onKeyDown={handleInput}
                    onClick={handleClick}
                    onFocus={handleFocus}
                    type="number"
                    inputMode="numeric"
                    min={0}
                    max={9}
                    maxLength={VERIFICATION_CODE_LENGTH - i}
                    key={i} // nosonar
                    ref={(ref) => refs.current.push(ref)}
                    className={classNames("form-control", {
                        "codeInput__input--empty": !codeValue,
                    })}
                />
            ))}
        </div>
    );
};
