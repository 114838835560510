import React from "react";
import { format } from "date-fns";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { useManualDataUpload } from "./useManualDataUpload";
import { fullDateFullYearFormat } from "../../../common/helpers/date";
import "./ManualDataUploadPrompt.scss";
import { CollapsibleInfoBanner } from "../../general/Collapsible/CollapsibleInfoBanner";

interface Props {
    connection: PlaidConnection;
}
export const ManualDataUploadPrompt: React.FC<Props> = ({ connection }) => {
    const { missingDataStart, missingDataEnd } =
        useManualDataUpload(connection);

    return (
        <CollapsibleInfoBanner className="manual-data-upload-prompt">
            {connection.bankName} does not provide transaction data before{" "}
            <strong>{format(missingDataEnd, fullDateFullYearFormat)}</strong>.
            To complete your bookkeeping, please download a CSV of transactions
            from{" "}
            <strong>{format(missingDataStart, fullDateFullYearFormat)}</strong>{" "}
            - <strong>{format(missingDataEnd, fullDateFullYearFormat)}</strong>{" "}
            and email it to <a href="mailto:support@kick.co">support@kick.co</a>
        </CollapsibleInfoBanner>
    );
};
