import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ButtonWithLoader } from "./ButtonWithLoader/ButtonWithLoader";
import { ButtonProps } from "react-bootstrap/Button";
import { UseMutationResult } from "react-query";
import classNames from "classnames";

interface CommonProps {
    title?: React.ReactNode;
    question?: React.ReactNode;
    yes?: string;
    yesBtnVariant?: ButtonProps["variant"];
    no?: string;
    noBtnVariant?: ButtonProps["variant"];
    onReject?: () => void;
    closeButton?: boolean;
}

interface PromiseProps extends CommonProps {
    onConfirm: () => Promise<void> | void;
    onConfirmMutation?: never;
}

interface MutationProps extends CommonProps {
    onConfirm?: never;
    onConfirmMutation: UseMutationResult<unknown, unknown, void>;
}

export const ConfirmationModal: React.FC<PromiseProps | MutationProps> = ({
    title,
    question = "Are you sure?",
    yes = "Yes",
    no = "No",
    yesBtnVariant = "primary",
    noBtnVariant = "secondary",
    onConfirm,
    onConfirmMutation,
    onReject,
    closeButton,
}) => {
    const [loading, setLoading] = useState(false);

    return (
        <>
            {title && (
                <Modal.Header closeButton={closeButton}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>
                {question && (
                    <p
                        className="mb-5"
                        data-testid="confirmation-modal-question"
                    >
                        {question}
                    </p>
                )}
                <footer
                    className={classNames("d-flex", {
                        "justify-content-end": !onReject,
                        "justify-content-between": !!onReject,
                    })}
                >
                    {onReject && (
                        <Button
                            variant={noBtnVariant}
                            onClick={onReject}
                            className="mr-2"
                            size="lg"
                            data-testid="confirmation-modal-no-button"
                        >
                            {no}
                        </Button>
                    )}
                    {onConfirmMutation ? (
                        <ButtonWithLoader
                            variant={yesBtnVariant}
                            mutation={onConfirmMutation}
                            size="lg"
                            data-testid="confirmation-modal-yes-button"
                        >
                            {yes}
                        </ButtonWithLoader>
                    ) : (
                        <ButtonWithLoader
                            variant={yesBtnVariant}
                            loading={loading}
                            setLoading={setLoading}
                            onClick={onConfirm}
                            size="lg"
                            data-testid="confirmation-modal-yes-button"
                        >
                            {yes}
                        </ButtonWithLoader>
                    )}
                </footer>
            </Modal.Body>
        </>
    );
};
