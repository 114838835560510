import { UploadReceiptDto } from "../common/dto/transactions/upload-receipt.dto";
import {
    MatchTransferResponse,
    Transaction,
    UnmatchTransferResponse,
} from "../common/types/transaction";
import { backendClient, unwrapResponse } from "./backendClient";
import { SaveTransactionSplitDto } from "../common/dto/transactions/save-transaction-split.dto";
import { SaveTransactionSplitResponseDto } from "../common/dto/transactions/save-transaction-split-response.dto";
import { BulkUpdateTransactionsDto } from "../common/dto/transactions/bulk/bulk-update-transactions.dto";
import { UpdateTransactionDto } from "../common/dto/transactions/update-transaction.dto";
import { UpdateTransactionResponseDto } from "../common/dto/transactions/update-transaction-response.dto";
import { GetTransactionsDto } from "../common/dto/transactions/getTransactions/get-transactions.dto";
import { CancelTokenSource } from "axios";
import { GetTransactionsResponseDto } from "../common/dto/transactions/getTransactions/get-transactions-response.dto";
import { TransactionFiltersDto } from "../common/dto/transactions/getTransactions/transaction-filters.dto";
import { TransactionDetailsResponseDto } from "../common/dto/transactions/transaction-details-response.dto";
import { GetMaterialUnconfirmedTransactionsDto } from "../common/dto/transactions/get-material-unconfirmed-transactions.dto";
import { GetSimilarTransactionsResponseDto } from "../common/dto/transactions/get-similar-transactions-response.dto";
import { GetTransactionsActivityResponseDto } from "../common/dto/transactions/get-transactions-activity-response.dto";
import { GetPossibleSavingsResponseDto } from "../common/dto/transactions/get-possible-savings-response.dto";
import { EagerLoaded } from "../common/types/base/orm";
import { MatchTransferDto } from "../common/dto/transactions/match-transfer.dto";

export function transformTransaction(transaction: Transaction): Transaction {
    return {
        ...transaction,
        date: new Date(transaction.date),
        pairedTransfer: transaction.pairedTransfer
            ? transformTransaction(transaction.pairedTransfer)
            : transaction.pairedTransfer,
    };
}

export async function getTransactionDetails(
    id: number,
): Promise<TransactionDetailsResponseDto> {
    const data = unwrapResponse(
        await backendClient.get<TransactionDetailsResponseDto>(
            `/transactions/${id}`,
        ),
    );

    return {
        ...data,
        transaction: transformTransaction(data.transaction) as EagerLoaded<
            Transaction,
            "transactionMatches" | "pairedTransfer"
        >,
    };
}

export async function uploadTransactionReceipt(
    id: Transaction["id"],
    data: UploadReceiptDto,
): Promise<void> {
    await backendClient.post(`/transactions/${id}/receipt`, data);
}

export async function saveTransactionSplit(
    transactionId: Transaction["id"],
    data: SaveTransactionSplitDto,
): Promise<SaveTransactionSplitResponseDto> {
    return unwrapResponse(
        await backendClient.post(`/transactions/${transactionId}/split`, data),
    );
}

export async function bulkUpdateTransactions(
    payload: BulkUpdateTransactionsDto,
): Promise<Transaction[]> {
    const response: Transaction[] = unwrapResponse(
        await backendClient.post(`/transactions/bulk-update`, payload),
    );

    return response.map(transformTransaction);
}

export async function updateTransaction(
    transaction: Transaction,
    data: UpdateTransactionDto,
): Promise<UpdateTransactionResponseDto> {
    return unwrapResponse(
        await backendClient.patch(`/transactions/${transaction.id}`, data),
    );
}

export async function getTransactions(
    data: GetTransactionsDto,
    cancel?: CancelTokenSource,
): Promise<GetTransactionsResponseDto> {
    const result: GetTransactionsResponseDto = await unwrapResponse(
        await backendClient.get("/transactions", {
            params: data,
            paramsSerializer: { indexes: true },
            cancelToken: cancel?.token,
        }),
    );

    result.data.forEach((t) => {
        t.date = new Date(t.date);
    });

    return result;
}

export async function getSuggestedMatchesForTransaction(
    transactionId: number,
    data: GetTransactionsDto,
    cancel?: CancelTokenSource,
): Promise<GetTransactionsResponseDto> {
    const result: GetTransactionsResponseDto = await unwrapResponse(
        await backendClient.get(
            `/transactions/${transactionId}/suggested-matches`,
            {
                params: data,
                paramsSerializer: { indexes: true },
                cancelToken: cancel?.token,
            },
        ),
    );

    result.data.forEach((t) => {
        t.date = new Date(t.date);
    });

    return result;
}

export async function getTransactionsCsv(
    filters: TransactionFiltersDto,
): Promise<ArrayBuffer> {
    return unwrapResponse(
        await backendClient.get(`/transactions/csv`, {
            responseType: "arraybuffer",
            params: filters,
            paramsSerializer: { indexes: true },
        }),
    );
}

export async function getMaterialUnconfirmedTransactions(
    params: GetMaterialUnconfirmedTransactionsDto,
): Promise<Transaction[]> {
    return unwrapResponse(
        await backendClient.get<Transaction[]>(
            "/transactions/material-unconfirmed",
            {
                params,
                paramsSerializer: { indexes: true },
            },
        ),
    ).map(transformTransaction);
}

export async function getSimilarTransactions(
    transaction: Transaction,
): Promise<GetSimilarTransactionsResponseDto> {
    return unwrapResponse(
        await backendClient.get(`/transactions/${transaction.id}/similar`),
    );
}

export async function getTransactionsActivity(): Promise<GetTransactionsActivityResponseDto> {
    return unwrapResponse(await backendClient.get("/transactions-activity"));
}

export async function getPossibleSavings() {
    return GetPossibleSavingsResponseDto.deserialize(
        unwrapResponse(
            await backendClient.get("/transactions/possible-savings"),
        ),
    );
}

export async function detachFinancialTransactionMatch(
    financialTransactionId: number,
    matchId: number,
) {
    await backendClient.post(
        `/transactions/${financialTransactionId}/detach/${matchId}`,
    );
}

export async function unmatchTransfer(financialTransactionId: number) {
    const data: UnmatchTransferResponse = unwrapResponse(
        await backendClient.delete(
            `/transactions/${financialTransactionId}/match-transfer`,
        ),
    );
    return transformTransaction(data.transaction);
}

export async function matchTransfer(
    originTransactionId: number,
    targetTransactionId: number,
) {
    const data = unwrapResponse(
        await backendClient.post(
            `/transactions/${originTransactionId}/match-transfer`,
            {
                targetTransactionId,
            } as MatchTransferDto,
        ),
    ) as MatchTransferResponse;
    return {
        ...data,
        originTransaction: transformTransaction(data.originTransaction),
        targetTransaction: transformTransaction(data.targetTransaction),
    };
}
