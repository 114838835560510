import React, { useCallback, useEffect, useRef, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { DynamicTooltip } from "./DynamicTooltip";
import { useInteraction } from "../../hooks/useInteraction";

type CopyOnClickProps = {
    value?: string;
    tooltipText?: React.ReactNode;
    className?: string;
};

const CONFIRMATION_TOOLTIP_TEXT = "Copied!";

export const CopyOnClick: React.FC<CopyOnClickProps> = ({
    children,
    value,
    tooltipText = "Click to copy",
    className,
}) => {
    const [currentTooltipText, setCurrentTooltipText] =
        useState<React.ReactNode>(tooltipText);
    const ref = useRef<HTMLSpanElement | null>(null);
    const handleClick = useCallback(() => {
        const textToCopy = value ?? ref.current?.innerText ?? "";
        navigator.clipboard.writeText(textToCopy);

        setCurrentTooltipText("Copied!");
        setTimeout(() => setCurrentTooltipText(tooltipText), 1500);
    }, [tooltipText, value]);

    const handleCopy = useInteraction(handleClick);

    useEffect(() => {
        if (currentTooltipText !== CONFIRMATION_TOOLTIP_TEXT) {
            setCurrentTooltipText(tooltipText);
        }
    }, [currentTooltipText, tooltipText]);

    return (
        <OverlayTrigger
            overlay={
                <DynamicTooltip id="click-to-copy">
                    {currentTooltipText}
                </DynamicTooltip>
            }
        >
            <span ref={ref} {...handleCopy} className={className}>
                {children}
            </span>
        </OverlayTrigger>
    );
};
