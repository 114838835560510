import React from "react";
import { TriangleIcon } from "../../icons";
import classNames from "classnames";

interface Props {
    icon: React.ReactNode;
    children: React.ReactNode;
    activeIndicator?: boolean;
    disabled?: boolean;
}

export const NavigationItem: React.FC<Props> = ({
    icon,
    children,
    activeIndicator,
    disabled,
}) => {
    return (
        <span
            data-testid={`navigation-item-${typeof children === "string" ? children.toLowerCase() : ""}`}
            className={classNames("navigation__item", {
                "navigation__item--disabled": disabled,
            })}
        >
            <span className="navigation__icon">{icon}</span>
            {children}

            {activeIndicator && (
                <TriangleIcon className="navigation__active-indicator" />
            )}
        </span>
    );
};
