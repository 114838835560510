import { createContext, useContext } from "react";
import { noop } from "../../../helpers/general";

export interface AggregatedFiltersContextValue {
    open(tab?: string): void;
    close(): void;
    activeTab: string;
    isOpen: boolean;
}

export const AggregatedFiltersContext =
    createContext<AggregatedFiltersContextValue>({
        isOpen: false,
        activeTab: "",
        open: noop,
        close: noop,
    });

export function useAggregatedFiltersContext() {
    const ctx = useContext(AggregatedFiltersContext);

    if (!ctx) {
        throw new Error("AggregatedFiltersContext is not provided");
    }

    return ctx;
}
