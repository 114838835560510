import React, { useCallback, useMemo, useState } from "react";
import { BalanceChart } from "./BalanceChart";
import { Loader } from "../general/Loader";
import { getBalanceChart } from "../../lib/balance";
import { BalanceChartResponseDto } from "../../common/dto/balance/balance-chart-response.dto";
import {
    addTimeZoneOffset,
    DATE_RANGE_PRESETS_BY_ID,
} from "../../helpers/date";
import { DashboardBalanceEmpty } from "./DashboardBalanceEmpty";
import { useBackendRequestEffect } from "../../hooks/useBackendRequestEffect";
import { Amount } from "../general/Amount/Amount";
import { AccountsFilter } from "../general/AccountsFilter/AccountsFilter";
import "./CashFlow.scss";
import { useFinancialAccounts } from "../../hooks/useFinancialAccounts";
import { isArray } from "lodash";
import { useUserCache } from "../../hooks/useUserCache";
import isNumber from "lodash/isNumber";
import { useWorkspaceContext } from "../../state/workspaceContext";

const SELECT_ACCOUNTS_KEY = "cash-flow-selected-accounts";

export const CashFlow: React.FC = () => {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const [cachedAccounts, setCachedAccounts] = useUserCache(
        `${activeWorkspaceKey}/${SELECT_ACCOUNTS_KEY}`,
    );
    const [stats, setStats] = useState<BalanceChartResponseDto>();
    const [loading, setLoading] = useState(true);
    const allAccounts = useFinancialAccounts({ excludeMockEntities: true });
    const [selectedAccountsIds, setSelectedAccountsIds] = useState<number[]>(
        () => {
            return allAccounts
                .filter((a) =>
                    cachedAccounts && isArray(cachedAccounts)
                        ? cachedAccounts.includes(a.id)
                        : a.isBusiness && isNumber(a.balance),
                )
                .map((a) => a.id);
        },
    );

    const changeAccountSelection = useCallback(
        (accounts: number[]) => {
            setCachedAccounts(accounts);
            setSelectedAccountsIds(accounts);
        },
        [setCachedAccounts],
    );

    const { start, end } = useMemo(
        () => ({
            start: DATE_RANGE_PRESETS_BY_ID.last_30_days.getStart(new Date()),
            end: DATE_RANGE_PRESETS_BY_ID.last_30_days.getEnd(new Date()),
        }),
        [],
    );

    const chartNotEmpty = useMemo(() => {
        if (stats) {
            return stats.balanceHistory.length > 2;
        } else {
            return true;
        }
    }, [stats]);

    useBackendRequestEffect(
        (cancelToken) => {
            setLoading(true);

            getBalanceChart(
                selectedAccountsIds,
                {
                    startDate: start,
                    endDate: end,
                },
                cancelToken,
            ).then((response) => {
                setStats({
                    ...response,
                    balanceHistory: response.balanceHistory.map((b) => ({
                        ...b,
                        date: addTimeZoneOffset(new Date(b.date)),
                    })),
                });
                setLoading(false);
            });
        },
        [start, end, selectedAccountsIds],
    );

    let statsSection: React.ReactNode;

    if (!stats) {
        statsSection = null;
    } else if (stats.hasAnyTransactions && chartNotEmpty) {
        statsSection = (
            <BalanceChart
                endDate={end}
                startDate={start}
                balanceHistory={stats.balanceHistory}
            />
        );
    } else {
        statsSection = <DashboardBalanceEmpty />;
    }

    return (
        <section className={`cash-flow cash-flow--chart-balance`}>
            <header className="d-flex align-items-center">
                <section className="balance-transactions__balance">
                    {stats && (
                        <>
                            <small>Today</small>
                            <Amount
                                amount={stats.currentBalance}
                                showNegative
                                separateCurrencySign
                            />
                        </>
                    )}
                </section>

                <section className="cash-flow__filters ml-auto">
                    <AccountsFilter
                        value={selectedAccountsIds}
                        onChange={changeAccountSelection}
                        buttonSize="sm"
                        excludeNoBalanceAccounts
                    />
                </section>
            </header>

            {statsSection}

            {loading ? <Loader /> : null}
        </section>
    );
};
