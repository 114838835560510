import React, { useMemo } from "react";
import { format, parse } from "date-fns";

interface Props {
    label: string;
}

export const LedgerBasedProfitLossDownloadColumnHeader: React.FC<Props> = ({
    label,
}) => {
    const dateLabel = useMemo(
        () => format(parse(label, "yyyy-MM", new Date()), "MMM yyyy"),
        [label],
    );

    return (
        <td
            className={
                "download-table__cell--bold download-table__cell--border-bottom download-table__cell--center"
            }
        >
            {dateLabel}
        </td>
    );
};
