import React from "react";
import { Modal } from "react-bootstrap";
import { SelectableItem } from "../general/SelectableItem/SelectableItem";
import plaidLogo from "../../static/images/icons/plaid.svg";
import { ConnectPlaid } from "../plaid/ConnectPlaid/ConnectPlaid";
import "./AddAccountModal.scss";
import billLogo from "../../static/images/icons/bill.svg";
import gustoLogo from "../../static/images/icons/gusto.svg";
import rampLogo from "../../static/images/icons/ramp.svg";
import stripeLogo from "../../static/images/icons/stripe.svg";
import { Entity } from "../../common/types/entity";
import { CreateStripeConnection } from "../stripe/CreateStripeConnection";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { CreateGustoConnection } from "../gusto/CreateGustoConnection";
import { UploadIcon } from "../../icons";
import { ManualImportTrigger } from "../manualImport/ManualImportTrigger";

interface Props {
    onCreated: () => void;
    defaultEntity?: Entity;
}

export const AddAccountModal: React.FC<Props> = ({
    onCreated,
    defaultEntity,
}) => {
    const { gustoIntegration, importerUi } = useTypedFlags();

    const gustoItem = gustoIntegration ? (
        <SelectableItem
            icon={<img src={gustoLogo} alt="Gusto" />}
            title={
                <>
                    Gusto <span className="tag tag--primary">Beta</span>
                </>
            }
            description={"Get details of your payroll expenses"}
        >
            <CreateGustoConnection
                onCreated={onCreated}
                defaultEntity={defaultEntity}
            >
                <span className="fas fa-chevron-right" />
            </CreateGustoConnection>
        </SelectableItem>
    ) : (
        <SelectableItem
            icon={<img src={gustoLogo} alt="Gusto" />}
            title="Gusto"
            description={"Get details of your payroll expenses"}
            disabled={true}
        >
            <span className="tag tag--disabled add-account-modal__coming-soon">
                Coming soon
            </span>
        </SelectableItem>
    );

    return (
        <>
            <Modal.Header closeButton>
                <h3 className="mb-0">
                    What type of account would you like to add?
                </h3>
            </Modal.Header>
            <Modal.Body className="add-account-modal">
                <SelectableItem
                    icon={<img src={plaidLogo} alt="Plaid" />}
                    title="Connected account"
                    description="Connect already existing account from a different bank or service"
                >
                    <ConnectPlaid
                        btnVariant="plain"
                        btnSize="sm"
                        btnText={<span className="fas fa-chevron-right" />}
                        onConnected={() => onCreated()}
                        defaultEntity={defaultEntity}
                    />
                </SelectableItem>
                <SelectableItem
                    icon={<img src={stripeLogo} alt="Stripe" />}
                    title="Stripe"
                    description={
                        "Automatically split Stripe Payouts to individual items"
                    }
                >
                    <CreateStripeConnection
                        onCreated={onCreated}
                        defaultEntity={defaultEntity}
                        btnVariant="plain"
                    >
                        <span className="fas fa-chevron-right" />
                    </CreateStripeConnection>
                </SelectableItem>
                {gustoItem}
                {importerUi && (
                    <SelectableItem
                        icon={<UploadIcon />}
                        title="Manual Import"
                        description={"Apple, Capital One and other CSVs"}
                    >
                        <ManualImportTrigger />
                    </SelectableItem>
                )}

                <SelectableItem
                    icon={<img src={rampLogo} alt="Ramp" />}
                    title="Ramp"
                    description={"Connect your Ramp account"}
                    disabled={true}
                >
                    <span className="tag tag--disabled add-account-modal__coming-soon">
                        Coming soon
                    </span>
                </SelectableItem>
                <SelectableItem
                    icon={<img src={billLogo} alt="Bill" />}
                    title="Bill"
                    description={
                        "Enhance your Kick reports with data from Bill"
                    }
                    disabled={true}
                >
                    <span className="tag tag--disabled add-account-modal__coming-soon">
                        Coming soon
                    </span>
                </SelectableItem>
            </Modal.Body>
        </>
    );
};
