import React from "react";
import styles from "./MatchingViewHeader.module.scss";
import { Button } from "react-bootstrap";
import { LeftArrowIcon } from "../../../../icons";

interface Props {
    onBack(): void;
}

export const MatchingViewHeader: React.FC<Props> = ({ onBack }) => {
    return (
        <div className={styles.header}>
            <Button
                size="sm"
                variant="secondary-icon"
                onClick={onBack}
                data-testid="financial-document-matching-view-back-button"
            >
                <LeftArrowIcon />
            </Button>
            Find transaction
        </div>
    );
};
