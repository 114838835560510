export function isoStringOrNull(date: Date | null): string | null {
    if (date === null) {
        return null;
    }
    return date.toISOString();
}

export function valueOrNull<T, R>(
    value: T | null,
    cb: (value: T) => R,
): R | null {
    return value === null ? null : cb(value);
}

export function valueOrUndefined<T, R>(
    value: T | undefined,
    cb: (value: T) => R,
): R | undefined {
    return value === undefined ? undefined : cb(value);
}

export const unpackDateOrNull = (value: string | null) =>
    valueOrNull(value, (date) => new Date(date));
