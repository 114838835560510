import React from "react";
import classNames from "classnames";
import { ClockIcon } from "../../../../icons";
import { timeAgo } from "../../../../helpers/date";
import { useFinancialAccountSyncedAt } from "../../../../hooks/useFinancialAccountSyncedAt";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { FinancialConnection } from "../../../../common/types/financialConnection";
import { BrokenConnectionLink } from "../../../financialConnection/BrokenConnectionLink";
import { accountHasError } from "../../../../helpers/account";

export interface AccountConnectionStatusProps {
    connection: FinancialConnection;
    account: FinancialAccount;
}

export const AccountConnectionStatus: React.FC<
    AccountConnectionStatusProps
> = ({ connection, account }) => {
    const balanceSyncedAt = useFinancialAccountSyncedAt(account);
    const integrationAccount = account.integrationAccounts?.find(
        (ia) => ia.connectionId === connection.id,
    );

    return connection.error || balanceSyncedAt ? (
        <div
            className={classNames("account-details__connection", {
                "account-details__connection--error": connection.error,
            })}
        >
            {balanceSyncedAt && !connection.error && (
                <span className="icon-color-gray-700">
                    <ClockIcon height="12" /> Last synced{" "}
                    {timeAgo(balanceSyncedAt)}
                </span>
            )}
            {integrationAccount &&
                accountHasError(integrationAccount, connection) && (
                    <BrokenConnectionLink
                        connection={connection}
                        integrationAccount={integrationAccount}
                        balanceSyncedAt={balanceSyncedAt}
                    />
                )}
        </div>
    ) : null;
};
