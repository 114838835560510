import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loader.scss";
import classNames from "classnames";

interface Props {
    inline?: boolean;
}

export const Loader: React.FC<Props> = ({ inline = false }) => (
    <div className={classNames("loader", { "loader--inline": inline })}>
        <Spinner animation="border" size={inline ? "sm" : undefined} />
    </div>
);
