import React from "react";
import { EmptyState } from "../general/EmptyState/EmptyState";

export const NoTransactions: React.FC = () => {
    return (
        <EmptyState
            header="No transactions"
            body="It may take a few hours till first transactions are visible."
        />
    );
};
