import { useQuery } from "react-query";
import { getUserWorkspaces } from "../lib/user";

interface UseWorkspacesProps {
    withEntities?: boolean;
}

export const useWorkspaces = ({
    withEntities = false,
}: UseWorkspacesProps = {}) => {
    const userWorkspaces = useQuery(["workspaces", withEntities], async () => {
        const response = await getUserWorkspaces(withEntities);
        return response?.workspaces ?? [];
    });

    return userWorkspaces;
};
