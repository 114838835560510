import React, { useCallback } from "react";
import { useEntities } from "../../hooks/useEntities";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { EntityIcon } from "../entity/EntityIcon";
import { Entity } from "../../common/types/entity";
import "./EntitiesNavigation.scss";
import { TriangleIcon } from "../../icons";

interface Props {
    isEntityActive: (entity: Entity) => boolean;
    isEntityDisabled?: (entity: Entity) => boolean;
    onSelectEntity?: (entity: Entity) => void;
    renderNavigation: (entity: Entity) => React.ReactNode;
    renderAddon?: (entity: Entity) => React.ReactNode;
    openedEntity?: number;
    setOpenedEntity: (entityId?: number) => void;
}

export const EntitiesNavigation: React.FC<Props> = ({
    isEntityActive,
    onSelectEntity,
    renderNavigation,
    renderAddon,
    openedEntity,
    setOpenedEntity,
    isEntityDisabled,
}) => {
    const entities = useEntities();

    const toggleEntityMenu = useCallback(
        (entity: Entity) => {
            if (openedEntity !== entity.id) {
                setOpenedEntity(entity.id);
                onSelectEntity?.(entity);
            } else {
                setOpenedEntity(undefined);
            }
        },
        [onSelectEntity, openedEntity, setOpenedEntity],
    );

    return (
        <section
            className="entities-navigation"
            data-testid="entities-navigation"
        >
            <span className="label entities-navigation__header">Entities</span>
            {entities.map((entity) => (
                <div
                    data-testid="entities-navigation-entity"
                    key={entity.id}
                    className={classNames("entities-navigation__entity-menu", {
                        "entities-navigation__entity-menu--opened":
                            openedEntity === entity.id,
                    })}
                >
                    <Button
                        data-testid="entities-navigation-entity-button"
                        variant="plain"
                        onClick={() => toggleEntityMenu(entity)}
                        className={classNames(
                            "entities-navigation__name",
                            "nav-link",
                            {
                                active: isEntityActive(entity),
                            },
                        )}
                        disabled={isEntityDisabled?.(entity)}
                    >
                        <EntityIcon entity={entity} size="xs" />
                        <span>{entity.name}</span>
                        {!isEntityDisabled?.(entity) && (
                            <TriangleIcon className="entities-navigation__toggle" />
                        )}
                    </Button>
                    {renderAddon?.(entity)}
                    <div
                        className="entities-navigation__content"
                        data-testid="entities-navigation-entity-submenu"
                    >
                        {renderNavigation(entity)}
                    </div>
                </div>
            ))}
        </section>
    );
};
