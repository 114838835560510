import React, { useCallback } from "react";
import { useUser } from "../../hooks/useUser";
import classNames from "classnames";
import {
    ExpandedSidebar,
    ExpandedSidebarProps,
} from "../layout/ExpandedSidebar/ExpandedSidebar";
import { Button } from "react-bootstrap";
import { useMobileView } from "../../hooks/useMobileView";
import { PersonalInfo, PersonalInfoProps } from "./PersonalInfo/PersonalInfo";
import { Outro } from "./Outro/Outro";
import { useUpdatePersonalInfoMutation } from "../../mutations/user";
import { useLogout } from "../../hooks/useLogout";

interface Props {
    onFinish: () => Promise<void>;
    sidebarSize: ExpandedSidebarProps["sidebarSize"];
}

export const OnboardingUser: React.FC<Props> = ({ onFinish, sidebarSize }) => {
    const user = useUser();
    const isMobile = useMobileView();
    const updatePersonalInfo = useUpdatePersonalInfoMutation();
    const logout = useLogout();

    const handlePersonalInfo: PersonalInfoProps["onSubmit"] = useCallback(
        async (payload) => {
            await updatePersonalInfo.mutateAsync({
                name: `${payload.firstName} ${payload.lastName}`,
                email: payload.email,
            });
        },
        [updatePersonalInfo],
    );

    return (
        <ExpandedSidebar
            className={classNames("onboarding", "onboarding--no-sidebar")}
            header={
                <Button
                    variant="secondary"
                    size={isMobile ? "sm" : "lg"}
                    className="mr-2"
                    onClick={logout}
                >
                    Log out
                </Button>
            }
            sidebarSize={sidebarSize}
        >
            {null}
            {!user.name ? (
                <PersonalInfo onSubmit={handlePersonalInfo} />
            ) : (
                <Outro onFinished={onFinish} />
            )}
        </ExpandedSidebar>
    );
};
