import React from "react";
import { ActiveFilterLabelProps } from "./lib";
import { currencyFormatterShort } from "../../../../../common/helpers/currency";

export const AmountFilterLabel: React.FC<ActiveFilterLabelProps> = ({
    filters: { minAmount, maxAmount },
}) => {
    if (minAmount !== undefined && maxAmount !== undefined) {
        return (
            <>
                {currencyFormatterShort.format(minAmount).toLowerCase()} -{" "}
                {currencyFormatterShort.format(maxAmount).toLowerCase()}
            </>
        );
    }

    if (minAmount !== undefined) {
        return <>≥ {currencyFormatterShort.format(minAmount).toLowerCase()}</>;
    }

    if (maxAmount !== undefined) {
        return <>≤ {currencyFormatterShort.format(maxAmount).toLowerCase()}</>;
    }

    return null;
};
