import React from "react";
import styles from "./CardSection.module.scss";
import classNames from "classnames";

interface Props {
    className?: string;
}

export const CardSection: React.FC<Props> = ({ className, children }) => {
    return (
        <div className={classNames(styles.section, className)}>{children}</div>
    );
};
