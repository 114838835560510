import React from "react";
import { RenderBalanceSheetRowProps } from "../types";
import classNames from "classnames";
import { SHEET_ACCOUNTING_FORMAT_NO_CURRENCY } from "../../../../common/helpers/sheet";

interface Props extends RenderBalanceSheetRowProps {
    emptyRow: React.ReactNode;
}

export const BalanceSheetDownloadTableRow: React.FC<Props> = ({
    emptyRow,
    depth,
    isTotal,
    label,
    value,
    isExpanded,
}) => {
    const depth0 = depth === 0 || (depth === 1 && isTotal);

    let effectiveDepth = isTotal ? depth - 2 : depth - 1;
    if (effectiveDepth < 0) {
        effectiveDepth = 0;
    }

    const firstRow = label === "Assets" && depth === 0;

    return (
        <>
            <tr>
                <td />
                <td
                    className={classNames({
                        "download-table__cell--bold": depth0,
                    })}
                    data-indent={effectiveDepth}
                >
                    {label}
                </td>
                <td />
                <td
                    className={classNames({
                        "download-table__cell--center": firstRow,
                        "download-table__cell--right": !firstRow,
                        "download-table__cell--bold":
                            (depth0 && isTotal) || firstRow,
                        "download-table__cell--border-top": isTotal,
                        "download-table__cell--border-bottom": firstRow,
                    })}
                    data-z={firstRow ? "" : SHEET_ACCOUNTING_FORMAT_NO_CURRENCY}
                >
                    {firstRow ? "Total" : null}
                    {!isExpanded && value}
                </td>
            </tr>
            {isTotal && emptyRow}
        </>
    );
};
