import React, { useMemo } from "react";
import { useEntities } from "../../../hooks/useEntities";
import { EntityIconList } from "../EntityIconList/EntityIconList";
import { PopoverContainer } from "../PopoverContainer";
import { Entity } from "../../../common/types/entity";
import styles from "./EntityFilter.module.scss";
import { EntityFilterOverlay } from "./EntityFilterOverlay";
import { EntityFilterProps } from "./types";

export const EntityFilter: React.FC<EntityFilterProps> = ({
    filters,
    onChange,
}) => {
    const entities = useEntities();
    const selectedEntities = useMemo(() => {
        return filters.entities && filters.entities.length > 0
            ? (filters.entities.map((entityId) =>
                  entities.find((entity) => entity.id === entityId),
              ) as Entity[])
            : entities;
    }, [entities, filters.entities]);

    return (
        <PopoverContainer
            id="entities-filter"
            buttonSize="sm"
            popoverClass={styles.popover}
            buttonActiveClass=""
            buttonText={
                <EntityIconList
                    entities={selectedEntities}
                    maxEntitiesToDisplay={4}
                />
            }
            offset={4}
        >
            <EntityFilterOverlay filters={filters} onChange={onChange} />
        </PopoverContainer>
    );
};
