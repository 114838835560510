import React, { useCallback, useState } from "react";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { Button, Modal } from "react-bootstrap";
import { PlaidAccountsConnectedModalBody } from "./PlaidAccountsConnectedModalBody";
import { useFinancialAccountEntitySelection } from "../../../hooks/useFinancialAccountEntitySelection";
import { AccountsConnectionProvider } from "../../financialAccount/AccountConnectionWidget/AccountsConnectionContext";
import { isValidConnection } from "../../financialAccount/AccountConnectionWidget/helpers";
import { useUser } from "../../../hooks/useUser";
import "./PlaidAccountsConnectedModal.scss";
import classNames from "classnames";
import { DuplicatePlaidAccount } from "../../../common/dto/plaid/plaid-common";
import { pluralize } from "../../../common/helpers/string";

export interface PlaidAccountsConnectedModalProps {
    show: boolean;
    connection: PlaidConnectionWithAccounts;
    duplicates: DuplicatePlaidAccount[] | null;
    onHide(): void;
}

export const PlaidAccountsConnectedModal: React.FC<
    PlaidAccountsConnectedModalProps
> = ({ show, connection, duplicates, onHide }) => {
    const { showPersonalEntitySelection, showBusinessEntitySelection } =
        useFinancialAccountEntitySelection();
    const showEntitySelection =
        showPersonalEntitySelection || showBusinessEntitySelection;
    const [submitted, setSubmitted] = useState<boolean>(false);
    const user = useUser();

    const handleSubmit = useCallback(() => {
        if (
            (connection && isValidConnection(connection)) ||
            submitted ||
            !user.onboardingComplete
        ) {
            setSubmitted(false);
            onHide();
        } else {
            setSubmitted(true);
        }
    }, [connection, onHide, submitted, user.onboardingComplete]);

    return (
        <AccountsConnectionProvider
            showAccountTypeWarning={submitted && user.onboardingComplete}
        >
            <Modal
                show={show}
                onHide={onHide}
                size={connection && showEntitySelection ? "xl" : "lg"}
                backdrop={connection ? true : "static"}
                keyboard={!!connection}
                className={classNames("accounts-connected-modal", {
                    "accounts-connected-modal--with-entities":
                        showEntitySelection,
                })}
            >
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Confirm whether your{" "}
                            {pluralize("account", connection.accounts.length)}{" "}
                            {connection.accounts.length > 1 ? "are" : "is"}{" "}
                            Personal or Business
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PlaidAccountsConnectedModalBody
                            connection={connection}
                            duplicates={duplicates}
                        />

                        <footer className="d-flex align-items-center justify-content-end">
                            <Button onClick={handleSubmit} size="lg">
                                Continue
                            </Button>
                        </footer>
                    </Modal.Body>
                </>
            </Modal>
        </AccountsConnectionProvider>
    );
};
