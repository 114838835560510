import { createContext } from "react";
import { Account } from "../../../common/types/domains/accounting/account";

interface Context {
    accounts: Account[];
    accountsByCode: Record<string, Account>;
    isLoading: boolean;
    isFetching: boolean;
}

export const AccountsContext = createContext<Context>({
    accounts: [],
    accountsByCode: {},
    isLoading: false,
    isFetching: false,
});
