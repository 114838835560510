import { useCallback, useMemo, useState } from "react";

export type RequestFn<TPayload, TResult> = (
    payloads: TPayload,
) => Promise<TResult>;

export interface UseSequentialRequests<TPayload, TResult> {
    progress: number;
    total: number;
    start(payloads: TPayload[]): Promise<TResult[]>;
}

export function useSequentialRequest<TPayload, TResult>(
    request: RequestFn<TPayload, TResult>,
): UseSequentialRequests<TPayload, TResult> {
    const [progress, setProgress] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    const start = useCallback(
        async (payloads: TPayload[]) => {
            setTotal(payloads.length);
            try {
                const results = [];

                for (const payload of payloads) {
                    results.push(await request(payload));

                    setProgress((prev) => prev + 1);
                }

                return results;
            } finally {
                setProgress(0);
                setTotal(0);
            }
        },
        [request],
    );

    return useMemo(
        () => ({
            progress,
            start,
            total,
        }),
        [progress, start, total],
    );
}
