import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { addNotification } from "../../../reducers/appState";
import { ReceiptUploadContextProvider } from "./receiptUpload.context";
import "./ReceiptUpload.scss";

const MAX_RECEIPT_SIZE_MB = 10;
const MAX_RECEIPT_SIZE_BYTES = MAX_RECEIPT_SIZE_MB * 1024 * 1024;

const ERROR_CODES: Record<string, string> = {
    "file-too-large": `Max allowed size is ${MAX_RECEIPT_SIZE_MB}MB`,
    "file-invalid-type": "Only PDF, PNG, JPEG files allowed",
};

export interface ReceiptUploadDropzoneProps {
    onAddReceipt(file: File): Promise<void>;
}

export const ReceiptUploadDropzone: React.FC<ReceiptUploadDropzoneProps> = ({
    onAddReceipt,
    children,
}) => {
    const [uploading, setUploading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const onError = useCallback(
        (error: string) => {
            dispatch(
                addNotification({
                    type: "danger",
                    message:
                        ERROR_CODES[error] ??
                        "Unknown error when uploading file",
                }),
            );
        },
        [dispatch],
    );

    const handleAddReceipt = useCallback(
        async (file: File) => {
            try {
                setUploading(true);
                await onAddReceipt(file);
            } finally {
                setUploading(false);
            }
        },
        [onAddReceipt],
    );

    const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
        onDropRejected: ([rejection]) => onError(rejection.errors[0].code),
        onDropAccepted: async ([file]) => {
            await handleAddReceipt(file);
        },
        accept: ["image/png", "image/jpeg", "application/pdf"],
        maxSize: MAX_RECEIPT_SIZE_BYTES,
        multiple: false,
        noClick: true,
    });

    return (
        <ReceiptUploadContextProvider
            value={{
                isUploading: uploading,
                inputProps: getInputProps(),
                isDragActive: isDragAccept,
                open,
            }}
        >
            <div
                {...getRootProps({
                    className: classNames("receipt-upload__dropzone", {
                        "receipt-upload__dropzone--active": isDragAccept,
                    }),
                })}
            >
                {children}
            </div>
        </ReceiptUploadContextProvider>
    );
};
