import { format } from "date-fns";
import { Formik, FormikConfig } from "formik";
import React, { useCallback, useState } from "react";
import { array, number, object, string } from "yup";
import { SaveTransactionSplitResponseDto } from "../../../common/dto/transactions/save-transaction-split-response.dto";
import { currencyFormatter } from "../../../common/helpers/currency";
import { shortDateWithOrdinalsFormat } from "../../../common/helpers/date";
import { Transaction } from "../../../common/types/transaction";
import { TransactionSplit } from "../../../common/types/transactionSplit";
import { submitHelper } from "../../../helpers/form";
import { saveTransactionSplit } from "../../../lib/transactions";
import styles from "./TransactionSplit.module.scss";
import { TransactionSplitForm } from "./TransactionSplitForm";
import { TransactionSplitFormSchema } from "./types";
import { pick } from "lodash";

export interface TransactionSplitContainerProps {
    transaction: Transaction;
    onComplete(split: SaveTransactionSplitResponseDto): Promise<void>;
}

export const TransactionSplitContainer: React.FC<
    TransactionSplitContainerProps
> = ({ transaction, onComplete }) => {
    const handleSplit = useCallback(
        async (split: TransactionSplit[]) => {
            const response = await saveTransactionSplit(transaction.id, {
                split,
            });
            void onComplete(response);
        },
        [onComplete, transaction.id],
    );

    const [loading, setLoading] = useState(false);

    const form: FormikConfig<TransactionSplitFormSchema> = {
        initialValues: {
            split: transaction.split?.length
                ? transaction.split.map((item) => ({
                      ...item,
                      amount: item.amount,
                  }))
                : [
                      pick(transaction, [
                          "description",
                          "category",
                          "customCategory",
                          "amount",
                      ]),
                      {
                          description: "",
                          category: transaction.category,
                          customCategory: transaction.customCategory,
                          amount: "",
                      },
                  ],
        },
        validationSchema: object().shape({
            split: array().of(
                object().shape({
                    description: string().required("Description is required"),
                    category: string().required("Category is required"),
                    amount: number()
                        .notOneOf([0], "Amount is required")
                        .required("Amount is required"),
                }),
            ),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ split: newSplit }) => {
                await handleSplit(
                    newSplit.map((item) => {
                        const { category, customCategory } = item;
                        return {
                            description: item.description,
                            ...(customCategory != null
                                ? {
                                      category: customCategory.mainCategoryId,
                                      customCategoryId: customCategory.id,
                                  }
                                : { category }),
                            amount: item.amount || 0,
                        };
                    }),
                );
            },
        }),
    };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                Split your{" "}
                <strong>
                    {currencyFormatter.format(transaction.absoluteAmount)}
                </strong>{" "}
                transaction{" "}
                {transaction.description && (
                    <>
                        made at <strong>{transaction.description}</strong>
                    </>
                )}{" "}
                on{" "}
                <strong>
                    {format(
                        new Date(transaction.date),
                        shortDateWithOrdinalsFormat,
                    )}
                </strong>
            </header>

            <section className={styles.body}>
                <Formik {...form}>
                    <TransactionSplitForm
                        busy={loading}
                        transaction={transaction}
                    />
                </Formik>
            </section>
        </div>
    );
};
