import { TaxonomyMainCategories, Taxonomy } from "../../../common/categories";
import { XYSeries } from "@amcharts/amcharts4/charts";
import { Category } from "../../../common/types/category";
import {
    getMainCategoryId,
    isValidTaxonomy,
} from "../../../common/helpers/taxonomy";
import * as am4core from "@amcharts/amcharts4/core";

export const categoryColorsMap: Record<TaxonomyMainCategories, string> = {
    [Taxonomy.income]: "#68C7FF",
    [Taxonomy.uncategorized]: "#CCC",
    [Taxonomy.auto_vehicle]: "#C1CDD1",
    [Taxonomy.repairs_maintenance]: "#B8D0DD",
    [Taxonomy.donations]: "#93C2D8",
    [Taxonomy.insurance]: "#A4BDE9",
    [Taxonomy.personal]: "#7CAAFB",
    [Taxonomy.travel]: "#8589F8",
    [Taxonomy.transfer]: "#BE97D1",
    [Taxonomy.contributions_distributions]: "#BE97D1",
    [Taxonomy.legal_professional]: "#A4A7E4",
    [Taxonomy.supplies_equipment]: "#CBABAB",
    [Taxonomy.real_estate]: "#DEB5B1",
    [Taxonomy.health_dental]: "#EE85D0",
    [Taxonomy.entertainment]: "#D87DE7",
    [Taxonomy.software_subscriptions]: "#C48CFF",
    [Taxonomy.advertising_marketing]: "#FF779A",
    [Taxonomy.taxes]: "#F97C7C",
    [Taxonomy.credit_card_loans]: "#FF897E",
    [Taxonomy.meals]: "#FFAD77",
    [Taxonomy.fees]: "#FFD27E",
    [Taxonomy.rentals_leases]: "#F0D26E",
    [Taxonomy.utilities]: "#E9E47C",
    [Taxonomy.communications]: "#C9E47B",
    [Taxonomy.retirement]: "#9AE981",
    [Taxonomy.payroll]: "#83E4B1",
    [Taxonomy.learning_development]: "#7DE5E3",
    [Taxonomy.security_deposit]: "#E58181",
    [Taxonomy.cogs]: "#B3E1E0",
};

export function isValidCategoryWithAssignedColor(
    value: string,
): value is TaxonomyMainCategories {
    return Object.keys(categoryColorsMap).includes(value);
}

export function applySeriesColor(
    field: string,
    series: XYSeries,
    categoryMap: Record<Taxonomy, Category>,
) {
    if (isValidTaxonomy(field)) {
        const categoryId = getMainCategoryId(field, categoryMap);
        if (isValidCategoryWithAssignedColor(categoryId)) {
            series.fill = am4core.color(categoryColorsMap[categoryId]);
        }
    }
    series.stroke = am4core.color("#fff");
}
