import React from "react";
import { FinancialDocumentFilters } from "./lib";
import { FinancialDocumentStatus } from "../../../common/constants";

interface StatusFilterLabelProps {
    filters: Pick<FinancialDocumentFilters, "status">;
}
export const StatusFilterLabel: React.FC<StatusFilterLabelProps> = ({
    filters,
}) => {
    return (
        <>
            {filters.status === FinancialDocumentStatus.MATCHED
                ? "Matched"
                : "Not Matched"}
        </>
    );
};
