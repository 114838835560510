import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";

import styles from "./JournalEntryModal.module.scss";
import { EditableTableFooter } from "../editableTable/EditableTableFooter";
import {
    JournalEntryModalErrors,
    JournalEntryModalFormikConfig,
    JournalEntryModalMutableConfig,
} from "./JournalEntryModal";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { calculateDebitAndCreditTotals } from "./JournalEntryModalUtils";
import { useIsJournalEntryEditable } from "./JournalEntryModalHooks";

interface Props {
    tableTotalTranslateY: number;
    footerFulyVisible: boolean;
    loading: boolean;
    onDelete: () => void;
}

export const JournalEntryModalFooter: React.FC<Props> = ({
    tableTotalTranslateY,
    footerFulyVisible,
    loading,
    onDelete,
}) => {
    const formikContext = useFormikContext<JournalEntryModalFormikConfig>();
    const { values, setFieldValue, handleSubmit } = formikContext;
    const hideAfterSubmit = values.isHideAfterSubmit;
    const errors = formikContext.errors as JournalEntryModalErrors;

    const lines = values.journalEntry.lines;

    const debitAndCreditTotals = useMemo(() => {
        return calculateDebitAndCreditTotals(lines);
    }, [lines]);

    const isFormNotFilled = useMemo(() => {
        return (
            debitAndCreditTotals.debitAmount === 0 &&
            debitAndCreditTotals.creditAmount === 0
        );
    }, [debitAndCreditTotals]);

    const onSubmit = useCallback(
        (closeType: "save" | "save-and-new") => async () => {
            if (closeType === "save") {
                setFieldValue("isHideAfterSubmit", true);
            } else {
                setFieldValue("isHideAfterSubmit", false);
            }
            JournalEntryModalMutableConfig.validate = true;
            await handleSubmit();
        },
        [handleSubmit, setFieldValue],
    );

    const shownError = useMemo(() => {
        const hasErrorsInLines =
            errors.journalEntry?.lines && errors.journalEntry.lines.length > 0;
        const hasErrorsInDebitAndCreditSums = errors.debitAndCreditSums;
        if (hasErrorsInLines && hasErrorsInDebitAndCreditSums) {
            return "Please complete the highlighted fields and make sure the totals match";
        }
        if (hasErrorsInLines) {
            return "Please complete the highlighted fields";
        }
        if (hasErrorsInDebitAndCreditSums) {
            return "Please check that your total debits match total credits";
        }
        return "";
    }, [errors]);

    const isJournalEntryEditable = useIsJournalEntryEditable(values);

    return (
        <div className={styles.modalFooter}>
            <EditableTableFooter
                hide={footerFulyVisible}
                translateY={tableTotalTranslateY}
                opacity={!footerFulyVisible ? 1 : 0}
                className={classNames(
                    styles.stickyTotal,
                    !isJournalEntryEditable && styles.stickyTotalRounded,
                )}
                totals={debitAndCreditTotals}
            />

            {isJournalEntryEditable && (
                <div className={classNames(styles.footerButtons)}>
                    {values.journalEntry.id && (
                        <ButtonWithLoader
                            loading={loading && hideAfterSubmit}
                            disabled={isFormNotFilled || shownError.length > 0}
                            variant="secondary"
                            size="sm"
                            onClick={onDelete}
                            className={styles.deleteButton}
                        >
                            Delete Journal Entry
                        </ButtonWithLoader>
                    )}

                    <p className={classNames(styles.footerError)}>
                        {shownError}
                    </p>
                    <ButtonWithLoader
                        loading={loading && hideAfterSubmit}
                        disabled={isFormNotFilled || shownError.length > 0}
                        variant="secondary"
                        size="sm"
                        onClick={onSubmit("save")}
                    >
                        Save
                    </ButtonWithLoader>
                    <ButtonWithLoader
                        loading={loading && !hideAfterSubmit}
                        disabled={isFormNotFilled || shownError.length > 0}
                        onClick={onSubmit("save-and-new")}
                        variant="primary"
                        size="sm"
                    >
                        Save and new
                    </ButtonWithLoader>
                </div>
            )}
        </div>
    );
};
