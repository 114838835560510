import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import {
    PopoverContainer,
    PopoverContainerHandle,
} from "../../../general/PopoverContainer";
import { Placement } from "react-bootstrap/Overlay";
import { Field, Form, Formik, FormikConfig } from "formik";
import { FormRow } from "../../../forms/FormRow";
import { Button } from "react-bootstrap";
import "./NoteDropdown.scss";
import { FormikProps } from "formik/dist/types";
import { useBulkActions } from "../useBulkActions";

export interface NoteDropdownProps {
    className?: string;
    dropdownKey: string | number;
    inputPlaceholder: string;
    placement?: Placement;
    children?: React.ReactNode | ((open: boolean) => React.ReactNode);
    popoverRef?: React.RefObject<PopoverContainerHandle>;
    onSubmit(value: string): void;
}

interface FormSchema {
    value: string;
}

export const NoteDropdown: React.FC<NoteDropdownProps> = ({
    className,
    dropdownKey,
    placement,
    inputPlaceholder,
    onSubmit,
    children,
}) => {
    const { subscribeToSelectionChanges } = useBulkActions();
    const [opened, setOpened] = useState(false);

    const closedBySubmit = useRef<boolean>(false);
    const popoverRef = useRef<PopoverContainerHandle>(null);
    const formRef = useRef<FormikProps<FormSchema>>();

    const handleSelectionChanged = useCallback(() => {
        if (!opened && closedBySubmit.current) {
            formRef.current?.resetForm();
        }
    }, [opened]);

    useEffect(() => {
        return subscribeToSelectionChanges(handleSelectionChanged);
    }, [handleSelectionChanged, subscribeToSelectionChanges]);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            value: "",
        },
        onSubmit({ value }, helpers) {
            onSubmit(value);
            popoverRef.current?.close();
            closedBySubmit.current = true;
        },
    };

    return (
        <div
            className={classNames("note-dropdown", className, {
                "note-dropdown--opened": opened,
            })}
        >
            <Formik {...form} innerRef={formRef as any}>
                <PopoverContainer
                    id={`note_dropdown_${dropdownKey}`}
                    ref={popoverRef}
                    trigger={
                        children instanceof Function
                            ? children(opened)
                            : children
                    }
                    placement={placement}
                    offset={4}
                    onShow={() => {
                        closedBySubmit.current = false;
                        setOpened(true);
                    }}
                    onHide={() => setOpened(false)}
                >
                    <div className="note-dropdown__body">
                        <Form>
                            <FormRow fieldName="value">
                                <Field
                                    name="value"
                                    as="textarea"
                                    placeholder={inputPlaceholder}
                                    className="form-control"
                                />
                            </FormRow>

                            <Button type="submit" variant="secondary" size="sm">
                                Submit
                            </Button>
                        </Form>
                    </div>
                </PopoverContainer>
            </Formik>
        </div>
    );
};
