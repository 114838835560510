import { useUser } from "./useUser";
import { getTierForUsage, PricingTier } from "../common/billing";
import { useBillingStatus } from "./useBillingStatus";

export function useCurrentPricingTier(
    forCreditsUsed?: number,
    tiers?: PricingTier[],
): PricingTier {
    const user = useUser();
    const { creditsUsed, pricingTiers } = useBillingStatus();

    forCreditsUsed = forCreditsUsed ?? creditsUsed;
    tiers = tiers ?? pricingTiers;

    if (user.isSubscribed) {
        return getTierForUsage(tiers, forCreditsUsed);
    }

    return tiers[0];
}
