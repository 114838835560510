import React, { useCallback, useState } from "react";
import { useUser } from "../../../../hooks/useUser";
import { isConnectionOwner } from "../../../../hooks/useConnectionOwnership";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UpdateFinancialAccountEntity } from "../../../financialAccount/UpdateFinancialAccountEntity";
import { FinancialAccountTypeSelect } from "../../../financialAccount/FinancialAccountTypeSelect";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { useChangeFinancialAccountEntityMutation } from "../../../../mutations/financialAccount";
import { FinancialConnection } from "../../../../common/types/financialConnection";
import { useIsAdmin } from "../../../../hooks/useRoles";
import { useFinancialAccountEntitySelection } from "../../../../hooks/useFinancialAccountEntitySelection";

export interface AccountTypeChangeProps {
    account: FinancialAccount;
    connection?: FinancialConnection;
    showIcon?: boolean;
    showLabel?: boolean;
    onChanged?: () => void;
}

export const AccountTypeChange: React.FC<AccountTypeChangeProps> = ({
    account,
    connection,
    showIcon = true,
    showLabel = true,
    onChanged,
}) => {
    const user = useUser();
    const isAdmin = useIsAdmin(account.entity ?? undefined);
    const isOwner = connection ? isConnectionOwner(connection, user) : isAdmin;
    const { personalEntitiesToAdminister, nonMockBusinessEntities } =
        useFinancialAccountEntitySelection();
    const [showEntitySelection, setShowEntitySelection] = useState<{
        isBusiness: boolean;
    }>();
    const changeEntity = useChangeFinancialAccountEntityMutation(
        account,
        onChanged,
    );

    const handleChangeAccountType = useCallback(
        async (isBusiness: boolean) => {
            const entities = isBusiness
                ? nonMockBusinessEntities
                : personalEntitiesToAdminister;

            if (entities.length === 1) {
                changeEntity.mutate(entities[0]);
            } else {
                setShowEntitySelection({ isBusiness });
            }
        },
        [changeEntity, nonMockBusinessEntities, personalEntitiesToAdminister],
    );

    const onUpdatedViaModal = useCallback(() => {
        setShowEntitySelection(undefined);
        onChanged?.();
    }, [onChanged]);

    return (
        <>
            <OverlayTrigger
                trigger={isOwner ? [] : ["hover", "focus"]}
                overlay={
                    <Tooltip id={`not-owner-${account.id}`}>
                        Only user who connected the account can change it's type
                    </Tooltip>
                }
            >
                {/*Wrapper div added to display tooltip over disabled buttons*/}
                <div>
                    <FinancialAccountTypeSelect
                        value={account.isBusiness}
                        onChange={handleChangeAccountType}
                        loading={changeEntity.isLoading}
                        disabled={!isOwner}
                        showIcon={showIcon}
                        showLabel={showLabel}
                    />
                </div>
            </OverlayTrigger>
            <Modal
                show={Boolean(showEntitySelection)}
                onHide={() => setShowEntitySelection(undefined)}
            >
                <Modal.Header closeButton className="p-0" />
                <Modal.Body>
                    <p>
                        Select{" "}
                        {showEntitySelection?.isBusiness ? "business" : "where"}{" "}
                        you'd like to assign this account to:
                    </p>
                    <UpdateFinancialAccountEntity
                        account={account}
                        isBusiness={showEntitySelection?.isBusiness}
                        connection={connection}
                        dropdownKey={`updateEntityType_${account.id}`}
                        size="sm"
                        onUpdated={onUpdatedViaModal}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};
