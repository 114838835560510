import React, { useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { BinIcon } from "../../../icons";
import { AdditionalEmail } from "../../../common/types/additionalEmail";
import { ConfirmationModal } from "../../general/ConfirmationModal";

export interface ReceiptsEmailsRowProps {
    value: AdditionalEmail;
    onRemove(email: string): Promise<void>;
}

export const ReceiptsEmailsRow: React.FC<ReceiptsEmailsRowProps> = ({
    value,
    onRemove,
}) => {
    const [showRemovalConfirmation, setShowRemovalConfirmation] =
        useState<boolean>(false);

    return (
        <>
            <ListGroup.Item className="d-flex align-items-center py-4">
                <div>{value.email}</div>
                <div className="ml-auto">
                    <Button
                        variant="plain"
                        size="sm"
                        className="py-1 px-2 ml-2"
                        onClick={() => setShowRemovalConfirmation(true)}
                    >
                        <BinIcon />
                    </Button>
                </div>
            </ListGroup.Item>
            <Modal
                show={showRemovalConfirmation}
                onHide={() => setShowRemovalConfirmation(false)}
            >
                <ConfirmationModal
                    question={
                        <>
                            Receipts from <strong>{value.email}</strong> will no
                            longer be accepted. Are you sure?
                        </>
                    }
                    onConfirm={() => onRemove(value.email)}
                    onReject={() => setShowRemovalConfirmation(false)}
                />
            </Modal>
        </>
    );
};
