import React from "react";
import { Route, Switch } from "react-router-dom";
import { Login } from "./auth/Login";
import { SimulationsDashboard } from "./simulations/SimulationsDashboard";
import { OnboardingContainer } from "./onboarding/OnboardingContainer";
import { UnauthenticatedRoute } from "./UnauthenticatedRoute";
import { GoogleAuth } from "./auth/GoogleAuth/GoogleAuth";
import { GOOGLE_AUTH_REDIRECT_PATH } from "../common/constants";
import { useTypedFlags } from "../hooks/useTypedFlags";
import { OnboardingV2 } from "./onboardingV2/OnboardingV2";

interface Props {}

export const PublicInterface: React.FC<Props> = () => {
    const { newOnboarding } = useTypedFlags();

    return (
        <Switch>
            <Route path="/" exact={true}>
                <Login />
            </Route>
            <Route path="/register/:inviteCode">
                {newOnboarding ? (
                    <OnboardingV2 />
                ) : (
                    <OnboardingContainer showSidebar={true} />
                )}
            </Route>

            <Route path="/register-invite/:inviteCode">
                <OnboardingContainer showSidebar={false} />
            </Route>

            <Route path="/simulations">
                <SimulationsDashboard />
            </Route>

            <Route path={GOOGLE_AUTH_REDIRECT_PATH}>
                <GoogleAuth />
            </Route>

            <UnauthenticatedRoute />
        </Switch>
    );
};
