import { useState, useEffect } from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width,
        height,
    };
}

interface UseWindowDimensionsProps {
    onChange?: (dimensions: { width: number; height: number }) => void;
}
export function useWindowDimensions({
    onChange,
}: UseWindowDimensionsProps = {}) {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
            onChange?.(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [onChange]);

    return windowDimensions;
}
