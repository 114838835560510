import React, { useCallback, useState } from "react";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import {
    connectWithPlaid,
    getPlaidConnections,
    OnSuccessAccount,
    OnSuccessMetadata,
} from "../../../lib/plaidConnection";
import { CreateBankAccountConnectionDto } from "../../../common/dto/onboarding/create-bank-account-connection.dto";
import { ButtonVariant } from "react-bootstrap/types";
import {
    PlaidConnection,
    PlaidConnectionWithAccounts,
} from "../../../common/types/plaidConnection";
import { ButtonProps } from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { updateConnectAccountState } from "../../../reducers/appState";
import { CreatePlaidConnectionResponse } from "../../../common/dto/plaid/create-plaid-connection-response.dto";
import { FullAccountNumber } from "../../../common/dto/financialAccount/get-account-numbers-response.dto";
import {
    useAddPlaidAccountsMutation,
    useBankConnectionCreationMutation,
} from "../../../mutations/plaidConnection";
import { PossibleConnectionDuplicatesModal } from "./PossibleConnectionDuplicatesModal";
import { OnlyDuplicateAccountsConnectedModal } from "./OnlyDuplicateAccountsConnectedModal";
import { Entity } from "../../../common/types/entity";
import { StandardModal } from "../../general/Modal/Modal";
import { isFunction } from "lodash";

export interface ConnectPlaidAccountProps {
    onSavingConnection?: (saving: boolean) => void;
    btnVariant?: ButtonVariant;
    btnText?: React.ReactNode;
    btnSize?: ButtonProps["size"];
    btnClass?: string;
    onConnected?: (
        connection: PlaidConnection,
        numbers: FullAccountNumber[],
    ) => void;
    disabled?: boolean;
    defaultEntity?: Entity;
    beforeConnect?: () => Promise<void>;
    children?: (props: {
        connecting: boolean;
        initializeConnection: () => void;
    }) => React.ReactNode;
}

interface SavedLinkResponse {
    publicToken: string;
    metadata: OnSuccessMetadata;
}

interface DuplicatesAndValid {
    duplicates: OnSuccessAccount[];
    valid: OnSuccessAccount[];
}

interface OnlyDuplicatesContext {
    duplicates: OnSuccessAccount[];
    bankName: string;
    existingConnections: PlaidConnection[];
}

export const ConnectPlaid: React.FC<ConnectPlaidAccountProps> = ({
    btnVariant = "primary",
    btnText = "Connect Now",
    btnSize,
    btnClass,
    onSavingConnection,
    onConnected,
    disabled,
    defaultEntity,
    beforeConnect,
    children,
}) => {
    const dispatch = useDispatch();
    const [connecting, setConnecting] = useState(false);
    const [savedLinkResponse, setSavedLinkResponse] =
        useState<SavedLinkResponse>();
    const [possibleDuplicates, setPossibleDuplicates] =
        useState<PlaidConnection[]>();
    const [onlyDuplicatesContext, setOnlyDuplicatesContext] =
        useState<OnlyDuplicatesContext>();

    const createConnection = useBankConnectionCreationMutation();
    const addAccounts = useAddPlaidAccountsMutation();

    const handleSuccessfulConnection = useCallback(
        async (publicToken: string, metadata: OnSuccessMetadata) => {
            const similarConnections = await getPlaidConnections(
                metadata.institution.institution_id,
            );

            if (similarConnections.length > 0) {
                const { duplicates, valid } =
                    splitIntoDuplicateAndValidAccounts(
                        metadata.accounts,
                        similarConnections,
                    );

                if (valid.length === 0) {
                    setOnlyDuplicatesContext({
                        duplicates,
                        bankName: metadata.institution.name,
                        existingConnections: similarConnections,
                    });
                } else {
                    setPossibleDuplicates(similarConnections);
                    setSavedLinkResponse({ publicToken, metadata });
                }
            } else {
                return publicToken;
            }
        },
        [],
    );

    const authorize = useCallback(async () => {
        setConnecting(true);

        const connectResult = await connectWithPlaid();

        if (connectResult.connected) {
            return await handleSuccessfulConnection(
                connectResult.publicToken,
                connectResult.metadata,
            );
        } else {
            setConnecting(false);
        }
    }, [handleSuccessfulConnection]);

    const connect = useCallback(
        async (data: CreateBankAccountConnectionDto) => {
            onSavingConnection?.(true);
            dispatch(
                updateConnectAccountState({
                    isConnecting: true,
                    connection: null,
                    duplicates: null,
                }),
            );
            const response = await createConnection.mutateAsync(data);
            onSavingConnection?.(false);

            return response;
        },
        [dispatch, onSavingConnection, createConnection],
    );

    const onBankConnected = useCallback(
        ({ connection, numbers }: CreatePlaidConnectionResponse) => {
            setConnecting(false);
            onConnected?.(connection, numbers);
        },
        [onConnected],
    );

    const finalizeConnection = useCallback(
        async (publicToken: string) => {
            const connectedResult = await connect({
                publicToken,
                defaultEntityId: defaultEntity?.id,
            });
            onBankConnected(connectedResult);
        },
        [onBankConnected, connect, defaultEntity],
    );

    const initializeConnection = useCallback(async () => {
        if (connecting) {
            return;
        }

        try {
            if (beforeConnect) {
                await beforeConnect();
            }

            const publicToken = await authorize();

            if (publicToken) {
                await finalizeConnection(publicToken);
            }
        } catch (e) {
            setConnecting(false);
        }
    }, [authorize, beforeConnect, connecting, finalizeConnection]);

    const closeOnlyDuplicatesModal = useCallback(() => {
        setOnlyDuplicatesContext(undefined);
        setConnecting(false);
    }, []);

    const connectNewDespiteDuplicates = useCallback(() => {
        setPossibleDuplicates(undefined);
        if (savedLinkResponse) {
            finalizeConnection(savedLinkResponse.publicToken).catch(() =>
                setConnecting(false),
            );
            setSavedLinkResponse(undefined);
        }
    }, [finalizeConnection, savedLinkResponse]);

    const useExistingConnection = useCallback(
        (connection?: PlaidConnection) => {
            setPossibleDuplicates(undefined);
            setSavedLinkResponse(undefined);

            if (connection) {
                addAccounts
                    .mutateAsync(connection)
                    .finally(() => setConnecting(false));
            } else {
                setConnecting(false);
            }
        },
        [addAccounts],
    );

    return (
        <>
            {isFunction(children) ? (
                children({ connecting, initializeConnection })
            ) : (
                <ButtonWithLoader
                    loading={connecting}
                    onClick={initializeConnection}
                    variant={btnVariant}
                    size={btnSize}
                    className={btnClass}
                    disabled={disabled}
                    data-testid="connect-plaid-btn"
                >
                    {btnText}
                </ButtonWithLoader>
            )}

            <StandardModal show={!!possibleDuplicates} backdrop="static">
                {possibleDuplicates && (
                    <PossibleConnectionDuplicatesModal
                        connections={possibleDuplicates}
                        onConnectNew={connectNewDespiteDuplicates}
                        onUseExisting={useExistingConnection}
                    />
                )}
            </StandardModal>
            <StandardModal
                show={!!onlyDuplicatesContext}
                onHide={closeOnlyDuplicatesModal}
            >
                {onlyDuplicatesContext && (
                    <OnlyDuplicateAccountsConnectedModal
                        duplicates={onlyDuplicatesContext.duplicates}
                        bankName={onlyDuplicatesContext.bankName}
                        existingConnections={
                            onlyDuplicatesContext.existingConnections
                        }
                        close={closeOnlyDuplicatesModal}
                    />
                )}
            </StandardModal>
        </>
    );
};

function splitIntoDuplicateAndValidAccounts(
    accountsFromLink: OnSuccessAccount[],
    existingConnections: PlaidConnectionWithAccounts[],
): DuplicatesAndValid {
    const duplicates: OnSuccessAccount[] = [];
    const valid: OnSuccessAccount[] = [];
    const existingAccounts = existingConnections.flatMap((c) => c.accounts);

    for (const account of accountsFromLink) {
        const existing = existingAccounts.find(
            (a) => a.financialAccount.accountNumberMask === account.mask,
        );

        if (existing) {
            duplicates.push(account);
        } else {
            valid.push(account);
        }
    }

    return { duplicates, valid };
}
