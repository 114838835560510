import React from "react";
import { useFilenameParts } from "./useFilenameParts";
import {
    CsvIcon,
    DocIcon,
    JpgIcon,
    PdfIcon,
    PngIcon,
    PptIcon,
    XlsIcon,
} from "../../icons";

interface Props {
    filename: string;
    className?: string;
}

const extensionToIcon = {
    pdf: PdfIcon,
    doc: DocIcon,
    docx: DocIcon,
    ppt: PptIcon,
    xls: XlsIcon,
    xlsx: XlsIcon,
    png: PngIcon,
    csv: CsvIcon,
    jpg: JpgIcon,
    jpeg: JpgIcon,
} as const;

export const FinancialDocumentExtensionIcon: React.FC<Props> = ({
    filename,
    className,
}) => {
    const { extension } = useFilenameParts(filename);

    if (!extension || !(extension in extensionToIcon)) {
        return null;
    }

    const ExtensionIconComponent =
        extensionToIcon[extension as keyof typeof extensionToIcon];

    return <ExtensionIconComponent className={className} />;
};
