import React, { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { StandardModal } from "../general/Modal/Modal";

export interface NewVersionModalProps {
    show: boolean;
}
export const NewVersionModal: React.FC<NewVersionModalProps> = ({ show }) => {
    const refresh = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <StandardModal show={show}>
            <Modal.Header>
                <Modal.Title>New version available</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    A new version of the application is available. Please
                    refresh your browser window to continue using Kick.
                </p>

                <footer className="d-flex align-items-center justify-content-center">
                    <Button size="lg" onClick={refresh}>
                        Refresh
                    </Button>
                </footer>
            </Modal.Body>
        </StandardModal>
    );
};
