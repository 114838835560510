import { useEffect } from "react";

interface CallbackResult {
    preventDefault?: boolean;
    stopPropagation?: boolean;
}

interface UseKeyboardCommandsProps {
    commands: {
        key: string;
        requiresCtrlOrMeta?: boolean;
        stopPropagation?: boolean;
        preventDefault?: boolean;
        callback: (e: any) => CallbackResult | void;
    }[];
}

export const useKeyboardCommands = ({ commands }: UseKeyboardCommandsProps) => {
    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            for (const {
                key,
                stopPropagation = true,
                requiresCtrlOrMeta = true,
                preventDefault = true,
                callback,
            } of commands) {
                if (
                    key === e.key &&
                    (requiresCtrlOrMeta ? e.ctrlKey || e.metaKey : true)
                ) {
                    const callbackResult = callback(e) ?? {};
                    if (preventDefault || callbackResult.preventDefault) {
                        e.preventDefault();
                    }
                    if (stopPropagation || callbackResult.stopPropagation) {
                        e.stopPropagation();
                    }
                }
            }
        };

        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [commands]);
};
