import React from "react";
import styles from "./Quote.module.scss";
import { currencyFormatterNoFractions } from "../../../../../common/helpers/currency";

interface Props {
    items: React.ReactNode[];
    total: number;
    heading: React.ReactNode;
    comment?: React.ReactNode;
}

export const SubQuote: React.FC<Props> = ({
    items,
    total,
    heading,
    comment,
}) => {
    return (
        <section className={styles.subquote}>
            <header className={styles.heading}>{heading}</header>

            <ul className={styles.details}>
                {items}

                <li className={styles.divider} />
                <li className={styles.subtotal}>
                    <span>Subtotal</span>
                    <aside>{currencyFormatterNoFractions.format(total)}</aside>
                </li>
                {comment ? <li>{comment}</li> : null}
            </ul>
        </section>
    );
};
