import React, { useEffect } from "react";
import { Loader } from "../../general/Loader";
import { getOAuthAuthorizationUrl } from "../../../lib/oauth";

interface Props {
    service: string;
}

export const OAuthAuthorize: React.FC<Props> = ({ service }) => {
    useEffect(() => {
        getOAuthAuthorizationUrl(service).then(({ url }) => {
            window.location.href = url;
        });
    }, [service]);

    return <Loader />;
};
