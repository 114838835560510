import React, { useCallback } from "react";
import { SettingsSection } from "../../SettingsSection";
import styles from "./FlatRateBilling.module.scss";
import { Card } from "../../../general/Card/Card";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { currencyFormatterNoFractions } from "../../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../../common/helpers/monetary";
import { useToastMessages } from "../../../general/ToastMessages/useToastMessages";
import { EndTrialAction } from "../../../billing/AccessBlockedPage/EndTrialAction";
import { useAddPreviousYearMutation } from "../../../../mutations/flatRateBilling";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { previousYear } from "../Pricing/constants";

export const PreviousYearBookkeeping: React.FC = () => {
    const { isTrialing, includePreviousYear, currentPlan, plans } =
        useBillingStatus();

    const { toast } = useToastMessages();

    const onEnded = useCallback(() => {
        toast(`Plan updated.`);
    }, [toast]);

    const addPreviousYearMutation = useAddPreviousYearMutation(onEnded);

    const currentPlanData = plans.find((plan) => plan.plan === currentPlan)!;

    const defaultHeader = `${previousYear} bookkeeping`;

    let content: React.ReactNode;
    const priceFragment = (
        <div className={styles.price}>
            <strong>
                {currencyFormatterNoFractions.format(
                    fromIntToMonetary(currentPlanData.intervalAmountCents),
                )}
            </strong>
        </div>
    );

    if (includePreviousYear) {
        if (isTrialing) {
            // current trial with previous year included
            content = (
                <>
                    <div>
                        <header className={styles.title}>
                            {defaultHeader}
                        </header>
                        <span>Available 48 hours after your trial ends</span>
                    </div>

                    <aside>
                        <EndTrialAction variant="secondary" size="sm">
                            Upgrade now
                        </EndTrialAction>
                    </aside>
                </>
            );
        } else {
            // paid sub, previous year is handled
            content = (
                <>
                    <div>
                        <header className={styles.title}>
                            {defaultHeader}
                        </header>
                    </div>

                    <aside>
                        <Button size="sm" variant="secondary" disabled>
                            Active
                        </Button>
                    </aside>
                </>
            );
        }
    } else {
        content = (
            <>
                <div>
                    <header className={styles.title}>{defaultHeader}</header>
                    <span>Available after your subscription starts</span>
                </div>

                {priceFragment}

                <aside>
                    <ButtonWithLoader
                        size="sm"
                        variant="secondary"
                        mutation={addPreviousYearMutation}
                    >
                        Add
                    </ButtonWithLoader>
                </aside>
            </>
        );
    }

    return (
        <SettingsSection>
            <Card className={styles.card}>
                <section className={classNames(styles.section, styles.compact)}>
                    <main>{content}</main>
                </section>
            </Card>
        </SettingsSection>
    );
};
