import React from "react";
import { Taxonomy } from "../../../common/categories";
import {
    CategorySelectSimple,
    CategorySelectSimpleProps,
} from "../CategorySelect/CategorySelectSimple";
import styles from "./TransactionSplit.module.scss";
import { CustomCategory } from "../../../common/types/customCategory";

export interface TransactionCategoryInputProps
    extends Pick<
        CategorySelectSimpleProps,
        | "realEstateEnabled"
        | "showPersonal"
        | "transactionDirection"
        | "appendTo"
    > {
    name: string;
    splitIndex: number;
    onChange(category: Taxonomy | CustomCategory): void;
    value: Taxonomy | CustomCategory;
}

export const TransactionSplitCategoryInput: React.FC<
    TransactionCategoryInputProps
> = ({ name, splitIndex, onChange, value, ...rest }) => {
    return (
        <div className={styles.categoryInput}>
            <CategorySelectSimple
                dropdownKey={splitIndex}
                onSelected={onChange}
                value={value}
                {...rest}
            />
        </div>
    );
};
