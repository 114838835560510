import React, { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { without } from "lodash";
import { FieldInputProps } from "formik/dist/types";
import {
    PopoverButtonProps,
    PopoverContainer,
} from "../../general/PopoverContainer";
import { FilterSearch } from "../../general/FilterSearch/FilterSearch";
import { FormCheckbox } from "../FormCheckbox/FormCheckbox";
import { SelectDropdown } from "../../general/SelectDropdown/SelectDropdown";
import { SelectDropdownOption } from "../../general/SelectDropdown/SelectDropdownOption";
import "./Multiselect.scss";

type ValueType = string | number;

export interface MultiselectOption<T extends ValueType = ValueType> {
    value: T;
    label: React.ReactNode;
    searchableLabel?: string;
}

export interface MultiselectProps<T extends ValueType = ValueType>
    extends Pick<FieldInputProps<T[]>, "name" | "value" | "onBlur">,
        PopoverButtonProps {
    options: MultiselectOption<T>[];
    buttonText?:
        | React.ReactNode
        | ((value: T[], allOptions: MultiselectOption<T>[]) => React.ReactNode);
    onChange(value: T[]): void;
    filterFn?(search: string, option: MultiselectOption<T>): boolean;
    searchable?: boolean;
}

export const Multiselect = <T extends ValueType>({
    options,
    value,
    onChange,
    buttonText = (v, allOptions) => {
        if (!v.length) {
            return "Select";
        }

        if (v.length === allOptions.length) {
            return "All";
        }

        if (v.length < 3) {
            return value.join(", ");
        }

        return `${v.slice(0, 2).join(", ")}, and ${v.length - 2} more`;
    },
    buttonClass,
    name,
    searchable = true,
    filterFn = (search: string, option: MultiselectOption) => {
        const query = search.toLowerCase();

        if (!option.label) {
            return false;
        }

        if (typeof option.label === "string") {
            return option.label.toLowerCase().includes(query);
        }

        if (typeof option.label === "number") {
            return String(option.label).includes(query);
        }

        if (!option.searchableLabel) {
            return false;
        }

        return option.searchableLabel.toLowerCase().includes(query);
    },
    ...popoverButtonProps
}: MultiselectProps<T>) => {
    const isActive = value.length && value.length !== options.length;
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();

    const filteredOptions = useMemo(() => {
        return options.filter((option) => filterFn(search, option));
    }, [filterFn, options, search]);

    const isChecked = useCallback(
        (key: T) => Boolean(value?.includes(key)),
        [value],
    );

    const handleChange = useCallback(
        (key: T) => {
            if (isChecked(key)) {
                const newValue = without(value, key);

                onChange(newValue);
            } else {
                onChange([...(value ?? []), key]);
            }

            searchRef.current?.focus();
        },
        [value, isChecked, onChange],
    );

    return (
        <PopoverContainer
            buttonText={
                typeof buttonText === "function"
                    ? buttonText(value, options)
                    : buttonText
            }
            buttonClass={classNames("popover-activator", buttonClass, {
                "popover-activator--selected": !!isActive,
            })}
            id={name}
            onShow={() => searchRef.current?.focus()}
            onHide={() => setSearch("")}
            offset={4}
            {...popoverButtonProps}
        >
            <SelectDropdown
                className="multiselect"
                prepend={
                    searchable && (
                        <FilterSearch
                            value={search}
                            onChange={setSearch}
                            inputRef={searchRef}
                        />
                    )
                }
            >
                {filteredOptions.length > 0 ? (
                    filteredOptions.map((option) => (
                        <SelectDropdownOption
                            key={option.value}
                            className="p-0"
                        >
                            <FormCheckbox
                                value={option.value}
                                isChecked={isChecked(option.value)}
                                label={option.label}
                                handleChange={handleChange}
                            />
                        </SelectDropdownOption>
                    ))
                ) : (
                    <p className="multiselect__empty">No matching results</p>
                )}
            </SelectDropdown>
        </PopoverContainer>
    );
};
