import { createContext, Dispatch, SetStateAction } from "react";
import { noop } from "../../../helpers/general";

interface Context {
    hoveredAccountId?: number;
    setHoveredAccountId: Dispatch<SetStateAction<number | undefined>>;
}

export const AccountsChartEntityContext = createContext<Context>({
    setHoveredAccountId: noop,
});
