import React, { SyntheticEvent, useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { Loader } from "../general/Loader";
import "./TransactionsExport.scss";

interface Props {
    exporter: (withFilters: boolean) => Promise<void>;
    filtersActive: boolean;
}

export const TransactionsExport: React.FC<Props> = ({
    exporter,
    filtersActive,
}) => {
    const [opened, setOpened] = useState(false);
    const [exportingAll, setExportingAll] = useState(false);
    const [exportingFiltered, setExportingFiltered] = useState(false);

    const exportTransactions = useCallback(
        async (withFilters: boolean) => {
            const exporting = withFilters ? exportingFiltered : exportingAll;
            const exportingSetter = withFilters
                ? setExportingFiltered
                : setExportingAll;

            if (exporting) {
                return;
            }

            exportingSetter(true);

            try {
                await exporter(withFilters);
            } finally {
                exportingSetter(false);
            }
        },
        [exporter, exportingFiltered, exportingAll],
    );

    const onDropdownToggle = useCallback(
        (isOpen: boolean, event: SyntheticEvent<Dropdown>, metadata: any) => {
            if (metadata.source === "select") {
                setOpened(true);
            } else {
                setOpened(isOpen);
            }
        },
        [],
    );

    return (
        <Dropdown show={opened} onToggle={onDropdownToggle}>
            <Dropdown.Toggle
                id="transactions-export"
                variant="secondary"
                size="sm"
                className={classNames({
                    active: opened,
                })}
            >
                Download
            </Dropdown.Toggle>

            <Dropdown.Menu align="right">
                {filtersActive ? (
                    <Dropdown.Item onClick={() => exportTransactions(true)}>
                        {exportingFiltered ? (
                            <span className="transactions-export__icon">
                                <Loader inline={true} />
                            </span>
                        ) : null}
                        Filtered transactions (.csv)
                    </Dropdown.Item>
                ) : null}
                <Dropdown.Item onClick={() => exportTransactions(false)}>
                    {exportingAll ? (
                        <span className="transactions-export__icon">
                            <Loader inline={true} />
                        </span>
                    ) : null}
                    All transactions (.csv)
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
