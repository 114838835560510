import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { CATEGORIZATION_RULES_QUERY_KEY } from "../../../queries/categorizationRules";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { getCategorizationRules } from "../../../lib/categorizationRules";
import { Loader } from "../../general/Loader";
import { StandardModal } from "../../general/Modal/Modal";
import { AddRuleModal } from "./AddRuleModal";
import { CategorizationRule } from "./CategorizationRule";

export const RulesSettings: React.FC = () => {
    const { activeWorkspace } = useWorkspaceContext();
    const rules = useQuery(
        [CATEGORIZATION_RULES_QUERY_KEY, activeWorkspace?.id],
        () =>
            activeWorkspace ? getCategorizationRules(activeWorkspace.id) : [],
    );
    const [addRule, setAddRule] = useState(false);

    return (
        <main>
            <header className="d-flex align-items-start">
                <h3 className="settings__heading">Rules</h3>

                <Button
                    variant="secondary"
                    size="lg"
                    className="ml-auto"
                    onClick={() => setAddRule(true)}
                >
                    Add rule
                </Button>
            </header>

            {rules.isLoading && <Loader />}
            {rules.isSuccess &&
                rules.data.map((rule) => (
                    <CategorizationRule key={rule.id} rule={rule} />
                ))}

            <StandardModal show={addRule} onHide={() => setAddRule(false)}>
                <AddRuleModal close={() => setAddRule(false)} />
            </StandardModal>
        </main>
    );
};
