import { TransactionSortValue } from "./useSort";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import React from "react";
import { TransactionsTableCta } from "./TransactionsTableCta/TransactionsTableCta";
import { TransactionsFilters } from "./filters/lib";
import { endOfDay, startOfDay } from "date-fns";

interface UseActivityFeedCtaParams {
    filters: TransactionsFilters;
    currentSort: TransactionSortValue;
    currentPage: number;
    totalPages: number;
    transactionSelected: boolean;
}

export function useActivityFeedCta({
    filters,
    currentSort,
    currentPage,
    totalPages,
    transactionSelected,
}: UseActivityFeedCtaParams): [React.ReactNode, React.ReactNode] {
    const {
        showLimitReachedCta,
        showNotSubscribedCta,
        bookkeepingEndDate,
        bookkeepingStartDate,
    } = useBillingManagement();

    const sortedByDate = isSortedByDate(currentSort);

    const isDescending = currentSort === TransactionSortValue.DATE_DESC;

    if (!sortedByDate) {
        return [null, null];
    }

    let prependContent: React.ReactNode, appendContent: React.ReactNode;

    const showPrependContent =
        showLimitReachedCta &&
        transactionsMaybeAfterLimitReached(filters.end, bookkeepingEndDate);

    const showAppendContent =
        showNotSubscribedCta &&
        transactionsMaybeBeforeBookkeepingStart(
            filters.start,
            bookkeepingStartDate,
        );

    prependContent = showPrependContent ? (
        <TransactionsTableCta
            rows={isDescending ? 6 : 4}
            transactionSelected={transactionSelected}
            showAccountNames
            bulkActionsEnabled
            reversed={!isDescending}
        />
    ) : null;

    appendContent = showAppendContent ? (
        <TransactionsTableCta
            rows={isDescending ? 4 : 6}
            header="Upgrade to view earlier transactions"
            transactionSelected={transactionSelected}
            showAccountNames
            bulkActionsEnabled
            reversed={isDescending}
        />
    ) : null;

    if (!isDescending) {
        [prependContent, appendContent] = [appendContent, prependContent];
    }

    return [
        currentPage === 1 ? prependContent : null,
        currentPage === totalPages ? appendContent : null,
    ];
}

function isSortedByDate(currentSort: TransactionSortValue): boolean {
    return (
        currentSort === TransactionSortValue.DATE_ASC ||
        currentSort === TransactionSortValue.DATE_DESC
    );
}

function transactionsMaybeAfterLimitReached(
    filtersEndDate?: Date,
    limitReachedDate?: Date | null,
) {
    return Boolean(
        !filtersEndDate ||
            (limitReachedDate &&
                endOfDay(limitReachedDate) < endOfDay(filtersEndDate)),
    );
}

function transactionsMaybeBeforeBookkeepingStart(
    filtersStartDate?: Date,
    bookkeepingStartDate?: Date | null,
) {
    return Boolean(
        !filtersStartDate ||
            (bookkeepingStartDate &&
                startOfDay(bookkeepingStartDate) >
                    startOfDay(filtersStartDate)),
    );
}
