import { createContext } from "react";
import { AccountingReportFilters } from "./types";

interface Context {
    filters: AccountingReportFilters;
}

export const AccountingReportContext = createContext<Context>({
    filters: {},
});
