import React, { ReactNode, useCallback, useMemo, useState } from "react";
import {
    ToastMessage,
    ToastMessageItem,
    ToastMessagesContext,
} from "./ToastMessages.context";
import { ToastMessages } from "./ToastMessages";
import { isEqual } from "lodash";
import { ToastMessageType } from "./lib/common";

export const ToastMessagesProvider: React.FC = ({ children }) => {
    const [items, setItems] = useState<ToastMessageItem[]>([]);

    const registerToast = useCallback((key: string, message: ToastMessage) => {
        const item: ToastMessageItem = { key, message };
        setItems((prevItems) => {
            const index = prevItems.findIndex((i) => i.key === key);
            if (index === -1) {
                return [...prevItems, item];
            }
            if (isEqual(prevItems[index], item)) {
                return prevItems;
            }

            return [
                ...prevItems.slice(0, index),
                item,
                ...prevItems.slice(index + 1),
            ];
        });
    }, []);

    const hideToast = useCallback((key: string) => {
        setItems((prevItems) =>
            prevItems.some((i) => i.key === key)
                ? prevItems.filter((i) => i.key !== key)
                : prevItems,
        );
    }, []);

    const toast = useCallback(
        (message: ReactNode) => {
            const key = String(Math.random());
            registerToast(key, {
                type: ToastMessageType.CUSTOM,
                data: { message },
                startTimer: true,
            });

            return key;
        },
        [registerToast],
    );

    const contextValue = useMemo(
        () => ({ registerToast, hideToast, toast, items }),
        [hideToast, items, registerToast, toast],
    );

    return (
        <ToastMessagesContext.Provider value={contextValue}>
            {children}
            <ToastMessages />
        </ToastMessagesContext.Provider>
    );
};
