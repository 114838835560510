import { SubscriptionStatus } from "../billing";
import { UserEntityAssignment } from "./userEntityAssignment";

export enum UserIncomeType {
    INDIVIDUAL = "individual",
    BUSINESS = "business",
    EMPLOYEE = "employee",
    REAL_ESTATE = "real_estate",
}

export enum UserType {
    REGULAR = "regular",
    SUPPORT = "support",
    DEMO = "demo", // user who can log in
    DEMO_FAKE = "demo_fake", // users created in DB for demo account, but not able to log in
}

export interface UserEventsTriggered {
    initialCategorizationCompleted?: boolean;
    categorizationAfterSubscriptionCompleted?: boolean;
}

export interface User {
    id: number;
    name: string;
    legalName: string | null;
    preferredName: string | null;
    email: string | null;
    avatar: string | null;
    firstName: string;
    lastName: string;
    phoneNumber: string | null;
    lastCashback: Date | null;
    lastCashbackNotification: Date | null;
    phoneNumberDisplay: string | null;
    dateOfBirth: Date | null;
    incomeType: UserIncomeType[];
    onboardingComplete: boolean;
    showPotentialSavingsMessage: boolean;
    showBookkeepingReviewInvite: boolean;
    bookkeepingReviewScheduled: boolean;
    entityAssignments: UserEntityAssignment[];
    lastActivity: Date;
    iconColor: string;
    isSubscribed: boolean;
    savingsCreditLimit: number;
    freeUsageLimit: number;
    bookkeepingEndDate: Date | null;
    bookkeepingStartDate: Date;
    settings: UserSettings;
    createdAt: Date;
    onboardingCompletedAt: Date | null;
    subscribedAt: Date | null;
    eventsTriggered: UserEventsTriggered | null;
    stripeCustomerId: string | null;
    stripeSubscriptionStatus: SubscriptionStatus | null;
    bookedEnterpriseCall: boolean;
    type: UserType;
}

export interface UserSettings {
    weeklyInsightsEnabledAt: string | null;
    monthlyInsightsEnabledAt: string | null;
}

export interface PartnerStackData {
    xid: string;
    partnerKey?: string;
}
