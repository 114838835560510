import { createAction } from "@reduxjs/toolkit";
import { OnboardingKycValues } from "./onboarding";
import { User } from "../common/types/user";
import { PlaidConnection } from "../common/types/plaidConnection";
import { Category } from "../common/types/category";
import { EntityContext } from "../common/dto/onboarding/entity-context.dto";

export interface InitializeAppPayload {
    user: User | null;
    isDemoUser: boolean;
    bankConnections?: PlaidConnection[];
    amountSaved?: number;
    kycValues?: OnboardingKycValues;
    entities?: EntityContext[];
    categories: Category[];
}
export const initializeApp =
    createAction<InitializeAppPayload>("INITIALIZE_APP");

export const clearAuthorization = createAction("CLEAR_AUTHORIZATION");
