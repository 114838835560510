import { isValid, parse } from "date-fns";
import { ReportingTabCycle } from "../../../common/constants";
import {
    printLabel,
    REPORT_DATA_LABEL_FORMAT_MONTHLY,
    REPORT_DATA_LABEL_FORMAT_WEEKLY,
} from "../../../common/helpers/reports";
import { ReportFilters } from "../../../common/types/filters/reports";

export function validDateLabel(label: string) {
    return (
        isValid(parse(label, REPORT_DATA_LABEL_FORMAT_WEEKLY, new Date())) ||
        isValid(parse(label, REPORT_DATA_LABEL_FORMAT_MONTHLY, new Date()))
    );
}

export function parseCellLabel(label: string, cycle: ReportingTabCycle): Date {
    const format =
        cycle === ReportingTabCycle.WEEK
            ? REPORT_DATA_LABEL_FORMAT_WEEKLY
            : REPORT_DATA_LABEL_FORMAT_MONTHLY;
    return parse(label, format, new Date());
}

export function formatColHeaderLabel(
    filters: ReportFilters,
    label?: string,
    fullRange: boolean = false,
) {
    if (label && validDateLabel(label)) {
        return printLabel(label, filters, fullRange);
    }
    return label;
}
