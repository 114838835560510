import React from "react";
import "./Widget.scss";
import classNames from "classnames";
import { Card } from "./Card/Card";

interface Props {
    title?: React.ReactNode;
    className?: string;
}

export const Widget: React.FC<Props> = ({
    children,
    title,
    className = "",
}) => {
    return (
        <Card className={classNames("widget", className)}>
            {title ? <h4 className="widget__title">{title}</h4> : null}
            {children}
        </Card>
    );
};
