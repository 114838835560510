import { useMutation } from "react-query";
import {
    addPreviousYear,
    changePlan,
    checkout,
    convertTrial,
} from "../lib/flatRateBilling";
import { FLAT_RATE_PLANS } from "../common/flatRateBilling";
import { queryClient } from "../queryClient";
import { billingQueryKeys } from "../hooks/useBillingStatus";

export function useCheckoutMutation(setupId: string, onSuccess?: () => void) {
    return useMutation(() => checkout(setupId), {
        onSuccess,
    });
}

export function useChangePlanMutation(
    newPlan: FLAT_RATE_PLANS,
    onSuccess?: () => void,
) {
    return useMutation(() => changePlan(newPlan), {
        async onSuccess() {
            await queryClient.invalidateQueries(billingQueryKeys.all());
            onSuccess?.();
        },
    });
}

export function useConvertTrialMutation(onSuccess?: () => void) {
    return useMutation(convertTrial, {
        async onSuccess() {
            await queryClient.invalidateQueries(billingQueryKeys.all());
            onSuccess?.();
        },
    });
}

export function useAddPreviousYearMutation(onSuccess?: () => void) {
    return useMutation(addPreviousYear, {
        async onSuccess() {
            await queryClient.invalidateQueries(billingQueryKeys.all());
            onSuccess?.();
        },
    });
}
