import type { Pricing, PricingAndUsage } from "../../billing";
import { AppliedDiscount } from "../../types/billing";

interface CreditsUsedByMonthSerializedRecord {
    month: string;
    value: number;
}

export class GetPricingAndUsageResponseDto {
    static deserialize(dto: GetPricingAndUsageResponseDto): PricingAndUsage {
        return {
            ...dto,
        };
    }

    currentYearUsageByMonth: CreditsUsedByMonthSerializedRecord[] = [];

    constructor(
        public currentYearPricing: Pricing,
        public lastYearPricing: Pricing,
        public lastYearTotalUsage: number,
        public currentYearTotalUsage: number,
        public totalBeforeDiscount: number,
        public total: number,
        public discount: AppliedDiscount | null,
        public invalidDiscountCode: boolean,
    ) {}
}
