import React from "react";
import { ActiveFilterButton } from "../../general/ActiveFilters/ActiveFilterButton";
import { DateFilterLabel } from "../../general/ActiveFilters/DateFilterLabel";
import { useAggregatedFiltersContext } from "../../general/AggregatedFilters/AggregatedFilters.context";
import { CategoryFilterLabel } from "../../transactions/filters/AggregatedFilters/ActiveFilters/CategoryFilterLabel";
import { CashFlowPageFiltersProps } from "./CashFlowPageFilters";
import { ActiveCashFlowFilter, CashFlowPageFilterTabs } from "./lib";
import { useResetCashFlowPageFilter } from "./useResetCashFlowPageFilter";

const filterIdToTab: Record<ActiveCashFlowFilter, CashFlowPageFilterTabs> = {
    date: CashFlowPageFilterTabs.DATE,
    category: CashFlowPageFilterTabs.CATEGORY,
};

export interface CashFlowPageActiveFilterButtonProps
    extends CashFlowPageFiltersProps {
    filterId: ActiveCashFlowFilter;
}

export const CashFlowPageActiveFilterButton: React.FC<
    CashFlowPageActiveFilterButtonProps
> = ({ filterId, filters, onChange }) => {
    const resetFilter = useResetCashFlowPageFilter(onChange, filterId);
    const { open: openFilter } = useAggregatedFiltersContext();

    let label;

    switch (filterId) {
        case "date":
            label = <DateFilterLabel filters={filters} />;
            break;

        case "category":
            label = <CategoryFilterLabel filters={filters} />;
            break;
    }

    return (
        <ActiveFilterButton
            openFilter={() => openFilter(filterIdToTab[filterId])}
            resetFilter={resetFilter}
            label={label}
        />
    );
};
