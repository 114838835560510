import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { timeAgo } from "../../helpers/date";
import { AlertIcon } from "../../icons";
import { useConnectionOwnership } from "../../hooks/useConnectionOwnership";
import { FinancialConnection } from "../../common/types/financialConnection";
import { FixFinancialConnection } from "./FixFinancialConnection";

interface Props {
    connection: FinancialConnection;
    balanceSyncedAt: Date | null;
}

export const BrokenFinancialConnectionLink: React.FC<Props> = ({
    connection,
    balanceSyncedAt,
}) => {
    const isOwner = useConnectionOwnership(connection);

    return (
        <OverlayTrigger
            overlay={
                <Tooltip id={`fix-connection-${connection.id}`}>
                    <div className="mb-2">
                        <p className="mb-0">This account is disconnected.</p>
                        {!!balanceSyncedAt && (
                            <p className="mb-0">
                                Last sync {timeAgo(balanceSyncedAt)}.
                            </p>
                        )}
                        {!isOwner && (
                            <p className="mb-0">
                                Connected by {connection.user?.name}.
                            </p>
                        )}
                    </div>
                    Click to fix it.
                </Tooltip>
            }
        >
            <FixFinancialConnection
                connection={connection}
                variant="link"
                className="broken-connection-link danger"
                size="sm"
            >
                <AlertIcon className="icon-color-red-500 mr-2" />
                <span className="broken-connection-link__content">
                    Fix connection
                </span>
            </FixFinancialConnection>
        </OverlayTrigger>
    );
};
