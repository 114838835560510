import { Transaction } from "../../../common/types/transaction";
import { createContext } from "react";
import { noop } from "../../../helpers/general";
import { BulkUpdateDetails, BulkUpdateParams } from "./lib";
import { TaxQuestion, TaxRule } from "../../../common/taxSavingRules";
import { SelectedAnswer, TaxAnswers } from "../TaxQuestions/types";
import { TransactionDirectionType } from "../../../common/categories";

export type SelectedTransaction = Pick<
    Transaction,
    | "id"
    | "type"
    | "isBusiness"
    | "category"
    | "isIgnored"
    | "memo"
    | "taxQuestionAnswers"
    | "entity"
> & {
    transactionDirection: TransactionDirectionType;
};

export interface TransactionsBulkActionsContextValue {
    enabled: boolean;
    select(transactionOrTransactions: Transaction | Transaction[]): void;
    deselect(transactionOrTransactions: Transaction | Transaction[]): void;
    clearSelection(): void;
    isSelected(transaction: Transaction): boolean;
    updateSelected(params: BulkUpdateParams): Promise<void>;
    selected: Record<number, SelectedTransaction>;
    selectedCount: number;
    updateDetails: BulkUpdateDetails | null;
    subscribeToSelectionChanges(fn: () => void): () => void;
    taxRule: TaxRule | null;
    taxQuestions: TaxQuestion[];
    answers: TaxAnswers;
    handleAnswer(answers: SelectedAnswer[]): Promise<void>;

    selectAll(): void;
    hasSelectedAll: boolean;
    totalTransactions: number;
}

export const transactionsBulkActionsContext =
    createContext<TransactionsBulkActionsContextValue>({
        enabled: false,
        select: noop,
        deselect: noop,
        clearSelection: noop,
        isSelected: () => false,
        updateSelected: () => Promise.resolve(),
        selected: {},
        selectedCount: 0,
        updateDetails: null,
        subscribeToSelectionChanges: () => noop,
        taxRule: null,
        taxQuestions: [],
        answers: {} as TaxAnswers,
        handleAnswer: () => Promise.resolve(),
        selectAll: noop,
        hasSelectedAll: false,
        totalTransactions: 0,
    });
