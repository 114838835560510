import { Middleware } from "redux";
import { State } from "../store";
import { clearAuthorization, initializeApp } from "../reducers/_commonActions";
import * as Sentry from "@sentry/react";

export const sentryUserContextMiddleware: Middleware<{}, State> =
    (storeAPI) => (next) => (action) => {
        if (action.type === initializeApp.toString()) {
            if (action.payload.client) {
                Sentry.setUser({ email: action.payload.client.email });
            }
        }

        if (action.type === clearAuthorization.toString()) {
            Sentry.configureScope((scope) => scope.setUser(null));
        }

        return next(action);
    };
