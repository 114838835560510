import { useField } from "formik";
import React, { RefObject } from "react";
import { Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Taxonomy, TransactionDirectionType } from "../../../common/categories";
import { CustomCategory } from "../../../common/types/customCategory";
import { EntityIndustry } from "../../../common/types/entity";
import { Transaction } from "../../../common/types/transaction";
import { BinIcon } from "../../../icons";
import { FormRow } from "../../forms/FormRow";
import { TransactionSplitCategoryInput } from "./TransactionSplitCategoryInput";
import { TransactionSplitFormSchema } from "./types";
import styles from "./TransactionSplit.module.scss";

interface Props {
    index: number;
    transaction: Transaction;
    formRef: RefObject<HTMLFormElement>;
    remove: (index: number) => void;
}

export const TransactionSplitFormRow: React.FC<Props> = ({
    index,
    transaction,
    formRef,
    remove,
}) => {
    const [{ value: customCategory }, , customCategoryHelpers] = useField<
        TransactionSplitFormSchema["split"][0]["customCategory"]
    >(`split.${index}.customCategory`);
    return (
        <div className={styles.row}>
            <FormRow
                fieldName={`split.${index}.description`}
                label="Description"
            />

            <FormRow fieldName={`split.${index}.category`} label="Category">
                {(categoryHelpers, { value }) => {
                    const customSetValue = (
                        newValue: Taxonomy | CustomCategory,
                    ) => {
                        if (typeof newValue === "string") {
                            categoryHelpers.setValue(newValue);
                            customCategoryHelpers.setValue(undefined);
                            return;
                        }
                        categoryHelpers.setValue(newValue.mainCategoryId);
                        customCategoryHelpers.setValue(newValue);
                    };
                    return (
                        <TransactionSplitCategoryInput
                            name={`split.${index}.category`}
                            realEstateEnabled={
                                transaction.entity.industry ===
                                EntityIndustry.REAL_ESTATE
                            }
                            splitIndex={index}
                            showPersonal={false}
                            onChange={customSetValue}
                            value={customCategory ?? value}
                            appendTo={formRef.current ?? undefined}
                            transactionDirection={
                                transaction.amount > 0
                                    ? TransactionDirectionType.incoming
                                    : TransactionDirectionType.outgoing
                            }
                        />
                    );
                }}
            </FormRow>
            <FormRow
                fieldName={`split.${index}.amount`}
                label="Amount"
                showErrorMessage={index !== 0}
            >
                {({ setValue, setTouched }, { value }) => (
                    <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={value}
                        onValueChange={(v) => setValue(v.floatValue)}
                        onBlur={() => setTouched(true)}
                        allowNegative={true}
                        className="form-control"
                        prefix="$"
                        disabled={index === 0}
                    />
                )}
            </FormRow>

            <div className={styles.actionColumn}>
                <Button
                    size="sm"
                    variant="plain"
                    onClick={() => remove(index)}
                    disabled={index === 0}
                >
                    <BinIcon className="icon-color-grey" />
                </Button>
            </div>
        </div>
    );
};
