import React from "react";
import styles from "./EmptyObjectIcon.module.scss";
import classNames from "classnames";
import { ObjectIconProps } from "./ObjectIcon";

export type EmptyObjectIconProps = Pick<ObjectIconProps, "size" | "square">;

export const EmptyObjectIcon: React.FC<EmptyObjectIconProps> = ({
    square,
    size,
}) => {
    return (
        <div
            className={classNames(
                styles.emptyObjectIcon,
                `object-icon object-icon--${size}`,
                {
                    "object-icon--square": square,
                },
            )}
        />
    );
};
