import { useCategoryLabelGetter } from "../../hooks/useCategoryLabelGetter";
import React, { useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Taxonomy } from "../../common/categories";
import {
    ProfitLossChartDataSeriesCalculatedIdentifier,
    ProfitLossChartReportSeriesData,
    SpendingReportDataDto,
    SpendingReportDto,
} from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import * as am4core from "@amcharts/amcharts4/core";
import { difference, keys } from "lodash";

import {
    generateReportsCombinedTooltip,
    renderDataRows,
    renderTooltipRow,
    ReportTooltipSectionsRenderParams,
} from "./helpers/tooltip";
import { printLabel } from "../../common/helpers/reports";
import { useCategoryMap } from "../../hooks/useCategoryMap";
import { Category } from "../../common/types/category";
import { isValidTaxonomy } from "../../common/helpers/taxonomy";
import { ReportFilters } from "../../common/types/filters/reports";
import { applySeriesColor } from "./helpers/colors";
import { CreateFilteredChartParams, GenerateDataSeriesParams } from "./types";

function generateDataSeries({
    chart,
    field,
    getCategoryLabel,
    categoryMap,
}: GenerateDataSeriesParams) {
    // Set up series
    const series = chart.series.push(new am4charts.LineSeries());
    series.name = getCategoryLabel(field as Taxonomy);

    series.dataFields.valueY = field;
    series.dataFields.categoryX = "label";

    // Make it stacked
    series.stacked = true;
    applySeriesColor(field, series, categoryMap);
    series.strokeOpacity = 0.6;
    series.fillOpacity = 0.6;
    series.strokeWidth = 0.5;
    return series;
}

function renderTooltipSections({
    item,
    getCategoryLabel,
    seriesColorMap,
    filters,
}: ReportTooltipSectionsRenderParams) {
    const data = item.tooltipDataItem
        .dataContext as ProfitLossChartReportSeriesData;

    const tooltipSections = [];

    tooltipSections.push(
        `<header>${printLabel(data.label, filters, true)}</header>`,
    );

    let total = -difference(keys(data), ["label"]).reduce((acc, index) => {
        return (
            acc + data[index as ProfitLossChartDataSeriesCalculatedIdentifier]
        );
    }, 0);
    tooltipSections.push(renderTooltipRow("Total spend", total));

    const spendingHTML = renderDataRows({
        data,
        labelRenderer: getCategoryLabel,
        seriesColorMap,
        flipValuesSign: true,
    });
    tooltipSections.push(spendingHTML);
    return tooltipSections;
}

function createChart({
    id,
    report,
    getCategoryLabel,
    categoryMap,
    filters,
}: CreateFilteredChartParams<SpendingReportDto>) {
    const chart = am4core.create(id, am4charts.XYChart);

    chart.data = mergeDataCategories(report.data, categoryMap);
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.fill = am4core.color("#515d71");
    categoryAxis.renderer.labels.template.adapter.add("textOutput", (label) =>
        printLabel(label, filters),
    );
    categoryAxis.renderer.minLabelPosition = 0.02;
    categoryAxis.renderer.maxLabelPosition = 0.98;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.cursorTooltipEnabled = false;

    const series = [];

    const mergedSeries = Array.from(
        new Set(mergeDataSeries(report.series, categoryMap)),
    );

    for (const sid in mergedSeries) {
        const index = mergedSeries[sid];
        if (index !== null) {
            series.push(
                generateDataSeries({
                    chart,
                    field: index,
                    getCategoryLabel,
                    categoryMap,
                }),
            );
        }
    }

    generateReportsCombinedTooltip({
        seriesArray: series,
        seriesToHookInto: series[0],
        getCategoryLabel,
        filters,
        sectionsRenderMethod: renderTooltipSections,
    });

    /* Create a cursor */
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
    chart.cursor.behavior = "none";

    /* Style */
    chart.padding(0, 0, 0, 0);

    return chart;
}

function mergeDataSeries(
    series: Taxonomy[],
    categoryMap: Record<Taxonomy, Category>,
) {
    return series.map((item) => {
        return categoryMap[item].parentCategoryId
            ? categoryMap[item].parentCategoryId
            : item;
    });
}

function mergeDataCategories(
    data: SpendingReportDataDto[],
    categoryMap: Record<Taxonomy, Category>,
) {
    return data.map((item) => {
        const newItem = {} as SpendingReportDataDto;
        for (const key of keys(item)) {
            if (isValidTaxonomy(key)) {
                const parentId = categoryMap[key].parentCategoryId;
                if (parentId) {
                    newItem[parentId] = newItem[parentId]
                        ? newItem[parentId] + item[key]
                        : item[key];
                } else {
                    newItem[key] = item[key];
                }
            }
            newItem.label = item.label;
        }
        return newItem;
    });
}

interface Props {
    chart: SpendingReportDto;
    filters: ReportFilters;
}

export const SpendingAreaChart: React.FC<Props> = ({ chart, filters }) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();

    useEffect(() => {
        const areaChart = createChart({
            id: "spending-area-chart",
            report: chart,
            getCategoryLabel: getCategoryLabel,
            categoryMap: categoryMap,
            filters: filters,
        });
        return () => areaChart.dispose();
    }, [chart, getCategoryLabel, categoryMap, filters]);
    return (
        <div className="spending-area-chart">
            <div
                id="spending-area-chart"
                className="spending-area-chart__chart"
            ></div>
        </div>
    );
};
