import React, { useMemo } from "react";
import { ProfitLossReportDto } from "../../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import "./ProfitLossReportSummaryContainer.scss";
import { ReportFilters } from "../../../common/types/filters/reports";
import { eachMonthOfInterval } from "date-fns";
import { ProfitLossReportRevenueSummaryCard } from "./ProfitLossReportRevenueSummaryCard";
import { ProfitLossReportNetProfitSummaryCard } from "./ProfitLossReportNetProfitSummaryCard";
import { ProfitLossReportProfitMarginSummaryCard } from "./ProfitLossReportProfitMarginSummaryCard";
import { ProfitLossReportSummary } from "./types";
import {
    realReportDataEnd,
    realReportDataStart,
} from "../../../common/helpers/reports";
import { PLCell } from "../../../common/dto/reports/reports-common.dto";

export interface Props {
    report: ProfitLossReportDto;
    filters: ReportFilters;
}

function sumSection(data: PLCell[]): number {
    return data.reduce((acc, curr) => acc + curr.value, 0);
}

export const ProfitLossReportSummaryContainer: React.FC<Props> = ({
    report,
    filters,
}) => {
    const income = useMemo(
        () => sumSection(report.sections.income.data),
        [report],
    );
    const otherIncome = useMemo(
        () => sumSection(report.sections.otherIncome.data),
        [report],
    );
    const netOperatingIncome = useMemo(
        () => sumSection(report.sections.netOperatingIncome.data),
        [report],
    );
    const netOtherIncome = useMemo(
        () => sumSection(report.sections.netOtherIncome.data),
        [report],
    );

    const startDate = realReportDataStart(report.meta.dataStart, filters.start);
    const endDate = realReportDataEnd(report.meta.dataEnd, filters.end);
    const monthCount =
        endDate > startDate
            ? eachMonthOfInterval({ start: startDate, end: endDate }).length
            : 1;
    const summary: ProfitLossReportSummary = {
        startDate,
        endDate,
        monthCount,
        income,
        otherIncome,
        netOperatingIncome,
        netOtherIncome,
        revenue: income + otherIncome,
        netProfit: netOperatingIncome + netOtherIncome,
    };

    return (
        <div className="profit-loss-report-summary">
            <ProfitLossReportRevenueSummaryCard {...summary} />
            <ProfitLossReportNetProfitSummaryCard {...summary} />
            <ProfitLossReportProfitMarginSummaryCard {...summary} />
        </div>
    );
};
