import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Taxonomy } from "../../common/categories";
import {
    ProfitLossChartDataSeriesCalculatedIdentifier,
    ProfitLossReportDto,
} from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import {
    CategoryLabelGetter,
    useCategoryLabelGetter,
} from "../../hooks/useCategoryLabelGetter";
import { difference, values } from "lodash";
import { generateSingleCategoryTooltip } from "./helpers/tooltip";
import { printLabel } from "../../common/helpers/reports";
import { ReportFilters } from "../../common/types/filters/reports";
import { useCategoryMap } from "../../hooks/useCategoryMap";
import { applySeriesColor } from "./helpers/colors";
import { Card } from "../general/Card/Card";
import { CreateFilteredChartParams, GenerateDataSeriesParams } from "./types";

am4core.useTheme(am4themes_animated);

const chartLabelsMap: Record<
    ProfitLossChartDataSeriesCalculatedIdentifier,
    string
> = {
    other: "Other",
    income_rest: "Other",
    dummy: "Dummy",
};

function getSeriesLabel(
    field: string,
    getCategoryLabel: CategoryLabelGetter,
): string {
    if (
        values(ProfitLossChartDataSeriesCalculatedIdentifier).includes(
            field as ProfitLossChartDataSeriesCalculatedIdentifier,
        )
    ) {
        return chartLabelsMap[
            field as ProfitLossChartDataSeriesCalculatedIdentifier
        ];
    }

    return getCategoryLabel(field as Taxonomy);
}

function generateDataSeries({
    chart,
    field,
    getCategoryLabel,
    categoryMap,
}: GenerateDataSeriesParams) {
    // Set up series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = getSeriesLabel(field, getCategoryLabel);

    series.dataFields.valueY = field;
    series.dataFields.categoryX = "label";

    // Make it stacked
    series.stacked = true;
    series.strokeWidth = 0.5;

    applySeriesColor(field, series, categoryMap);
    series.strokeOpacity = 0.6;
    series.fillOpacity = 0.6;

    // Configure columns
    series.columns.template.width = am4core.percent(45);

    series.columns.template.tooltipHTML = ``;
    series.columns.template.tooltipY = am4core.percent(50);

    return series;
}

interface CreateProfitLossChartParams
    extends CreateFilteredChartParams<ProfitLossReportDto> {
    reportCreatedAt: number;
}

function createChart({
    id,
    report,
    getCategoryLabel,
    filters,
    categoryMap,
    reportCreatedAt,
}: CreateProfitLossChartParams) {
    const chart = am4core.create(id, am4charts.XYChart);

    chart.data = report.chart.data;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.fill = am4core.color("#515d71");
    categoryAxis.renderer.labels.template.adapter.add("textOutput", (label) =>
        printLabel(label, filters),
    );
    categoryAxis.renderer.minLabelPosition = 0.02;
    categoryAxis.renderer.maxLabelPosition = 0.98;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.stroke = am4core.color("#53647e");
    valueAxis.renderer.grid.template.opacity = 0.6;
    valueAxis.renderer.baseGrid.stroke = am4core.color("#53647e");
    valueAxis.renderer.baseGrid.opacity = 0.7;
    valueAxis.renderer.baseGrid.strokeWidth = 1.5;
    valueAxis.renderer.labels.template.fill = am4core.color("#515d71"); // gray-700
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.cursorTooltipEnabled = false;

    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "$#,###sa| -$#,###sa";

    const series = [];
    const reportSeries = difference(report.chart.series, [
        ProfitLossChartDataSeriesCalculatedIdentifier.DUMMY,
    ]);

    for (const sid in reportSeries) {
        series.push(
            generateDataSeries({
                chart: chart,
                field: reportSeries[sid],
                getCategoryLabel: getCategoryLabel,
                categoryMap: categoryMap,
            }),
        );
    }
    for (const seriesItem of series) {
        generateSingleCategoryTooltip(
            {
                chart,
                seriesToHookInto: seriesItem,
                filters,
            },
            reportCreatedAt,
        );
    }

    /* Style */
    chart.padding(30, 24, 12, 12);

    return chart;
}
interface Props {
    report: ProfitLossReportDto;
    filters: ReportFilters;
    reportCreatedAt: number;
}
export const ProfitLossReportChart: React.FC<Props> = ({
    report,
    filters,
    reportCreatedAt,
}) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();

    useEffect(() => {
        const chart = createChart({
            id: "profit-loss-chart",
            report: report,
            getCategoryLabel: getCategoryLabel,
            filters: filters,
            categoryMap: categoryMap,
            reportCreatedAt: reportCreatedAt,
        });
        return () => chart.dispose();
    }, [report, getCategoryLabel, filters, categoryMap, reportCreatedAt]);
    return (
        <Card className="profit-loss-chart">
            <div
                id="profit-loss-chart"
                className="profit-loss-chart__chart"
            ></div>
        </Card>
    );
};
