import React from "react";
import { Transaction } from "../../../common/types/transaction";
import classNames from "classnames";
import { getAmountSaved } from "../../../helpers/transaction";
import { isUncategorized } from "../lib";
import { currencyFormatter } from "../../../common/helpers/currency";
import "./TransactionSavings.scss";

export interface TransactionSavingsProps {
    transaction: Transaction;
}
export const TransactionSavings: React.FC<TransactionSavingsProps> = ({
    transaction,
}) => {
    const amountSaved = transaction && getAmountSaved(transaction);
    const pending =
        !isUncategorized(transaction) && transaction?.potentialAmountSaved;

    let content: React.ReactNode = null;

    if (transaction.isIgnored) {
        content = currencyFormatter.format(0);
    } else if (amountSaved) {
        content = (
            <>
                {!transaction.requirementsSnoozed ? "Savings" : ""} +{" "}
                {currencyFormatter.format(amountSaved)}
            </>
        );
    } else if (pending) {
        content = (
            <>
                Pending +{" "}
                {currencyFormatter.format(transaction.potentialAmountSaved!)}
            </>
        );
    }

    return (
        <span
            className={classNames("transaction-savings", {
                "transaction-savings--ignored": transaction.isIgnored,
                "transaction-savings--requirements-ignored":
                    transaction.requirementsSnoozed,
                "transaction-savings--pending": !!pending,
                "transaction-savings--saved": !!amountSaved,
            })}
        >
            {content}
        </span>
    );
};
