import React from "react";
import { Transaction } from "../../../common/types/transaction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TransactionStatus } from "../TransactionStatus";
import { useBreakableText } from "../../../hooks/useBreakableText";

interface Props {
    transaction: Transaction;
}

export const TransactionDescriptionColumnContent: React.FC<Props> = ({
    transaction,
}) => {
    const showTooltip = Boolean(
        transaction.bankDescription &&
            transaction.description !== transaction.bankDescription,
    );
    const breakableDescription = useBreakableText(
        transaction.description ?? transaction.bankDescription,
    );

    return (
        <div className="d-flex">
            <OverlayTrigger
                placement="top"
                trigger={showTooltip ? ["hover", "focus"] : []}
                overlay={
                    <Tooltip
                        id={`bankDescription:${transaction.id}`}
                        className="transaction-list-item__description__tooltip"
                    >
                        <span className="label">Bank description</span>
                        <p>{transaction.bankDescription}</p>
                    </Tooltip>
                }
            >
                <span
                    className="transaction-list-item__description__value"
                    data-testid="transaction-list-item-description"
                >
                    {breakableDescription}
                </span>
            </OverlayTrigger>
            <TransactionStatus transaction={transaction} />
        </div>
    );
};
