import { backendClient, unwrapResponse } from "./backendClient";
import { Entity } from "../common/types/entity";
import { Payroll } from "../common/types/payroll";
import { PayrollDto } from "../common/dto/gusto/payroll.dto";

export async function getEntityPayrolls(entity: Entity): Promise<Payroll[]> {
    const response = unwrapResponse(
        await backendClient.get("/payroll/" + entity.id),
    );

    return response.map((payrollDto: PayrollDto) =>
        PayrollDto.deserialize(payrollDto),
    );
}
