import React from "react";
import { ActiveFilterLabelProps } from "./lib";
import { MoneyDirection } from "../../../../../common/constants";

export const DirectionFilterLabel: React.FC<ActiveFilterLabelProps> = ({
    filters: { direction },
}) => {
    return (
        <>{direction === MoneyDirection.MONEY_IN ? "Money in" : "Money out"}</>
    );
};
