import React, { useCallback, useMemo } from "react";
import { TaxQuestion } from "./TaxQuestion";
import {
    getAnswerText,
    OTHER_ANSWER,
    TAX_SAVING_RULES,
    TaxQuestion as TaxQuestionType,
    TaxQuestionKey,
} from "../../../common/taxSavingRules";
import {
    AnswerHandler,
    SelectedAnswer,
    TaxAnswers,
    TaxQuestionAnswerComponentProps,
} from "./types";
import { Transaction } from "../../../common/types/transaction";

interface Props {
    questions: TaxQuestionType[];
    answers: TaxAnswers;
    onAnswer: AnswerHandler;
    renderAnswerComponent(
        options: TaxQuestionAnswerComponentProps,
    ): React.ReactNode;
    transaction?: Transaction;
}

export const TaxQuestions: React.FC<Props> = ({
    questions,
    answers,
    onAnswer,
    renderAnswerComponent,
    transaction,
}) => {
    const transactionYear = transaction?.date.getFullYear() ?? 2023;
    const rules = useMemo(
        () => TAX_SAVING_RULES[transactionYear] ?? TAX_SAVING_RULES[2022],
        [transactionYear],
    );
    const MET_WITH_QUESTION = useMemo(
        () =>
            rules.meals?.questions?.find(
                (q) => q.key === TaxQuestionKey.MEALS_MET_WITH,
            )!,
        [rules],
    );
    const MET_FOR_QUESTION = useMemo(
        () =>
            rules.meals?.questions?.find(
                (q) => q.key === TaxQuestionKey.MEALS_MET_FOR,
            )!,
        [rules],
    );

    const ANSWERS_MAP = useMemo(
        () =>
            MET_WITH_QUESTION.options.reduce(
                ($, option, idx) =>
                    Object.assign($, {
                        [getAnswerText(option)]: getAnswerText(
                            MET_FOR_QUESTION.options[idx],
                        ),
                    }),
                {} as Record<string, string>,
            ),
        [MET_WITH_QUESTION, MET_FOR_QUESTION],
    );

    const handleAnswer = useCallback(
        (key: TaxQuestionKey, answer: string) => {
            const answersToSave: SelectedAnswer[] = [{ key, answer }];

            if (
                key === TaxQuestionKey.MEALS_MET_WITH &&
                answer !== OTHER_ANSWER
            ) {
                const defaultAnswerSelectedPreviously =
                    answers[TaxQuestionKey.MEALS_MET_WITH] &&
                    answers[TaxQuestionKey.MEALS_MET_FOR] &&
                    answers[TaxQuestionKey.MEALS_MET_FOR] ===
                        ANSWERS_MAP[answers[TaxQuestionKey.MEALS_MET_WITH]];

                if (
                    !answers[TaxQuestionKey.MEALS_MET_FOR] ||
                    defaultAnswerSelectedPreviously
                ) {
                    answersToSave.push({
                        key: TaxQuestionKey.MEALS_MET_FOR,
                        answer: ANSWERS_MAP[answer],
                    });
                }
            }

            onAnswer(answersToSave);
        },
        [onAnswer, answers, ANSWERS_MAP],
    );

    return (
        <>
            {questions.map((question) => (
                <TaxQuestion
                    question={question}
                    answersByKey={answers}
                    onAnswer={handleAnswer}
                    key={question.key}
                    renderAnswerComponent={renderAnswerComponent}
                />
            ))}
        </>
    );
};
