import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { OAuthAuthorize } from "./OAuthAuthorize";
import { OAuthCallback } from "./OAuthCallback";

export const OAuthAuthorizationFlow: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${path}/authorize/:service`}
                render={({ match }) => (
                    <OAuthAuthorize service={match.params.service} />
                )}
            />
            <Route
                path={`${path}/callback/:service`}
                render={({ match }) => (
                    <OAuthCallback service={match.params.service} />
                )}
            />
        </Switch>
    );
};
