import React, { JSX, useMemo } from "react";
import { PopoverButtonProps, PopoverContainer } from "../PopoverContainer";
import { Entity } from "../../../common/types/entity";
import { EntityIcon } from "../../entity/EntityIcon";
import { pluralize } from "../../../common/helpers/string";
import { ObjectIcon } from "../ObjectIcon/ObjectIcon";
import { DotsHorizontalIcon } from "../../../icons";
import { AccountsFilterUICommonProps, EntityFilterSharedProps } from "./lib";
import { AccountsFilterBody } from "./AccountsFilterBody";

export interface AccountsFilterProps
    extends PopoverButtonProps,
        EntityFilterSharedProps,
        AccountsFilterUICommonProps {
    entities: Entity[];
    everythingSelected: boolean;
}

const ICONS_LIMIT = 4;

export const AccountsFilterDropdown: React.FC<AccountsFilterProps> = ({
    excludeNoBalanceAccounts,
    singleEntityName,
    entities,
    isEntitySelected,
    isAccountSelected,
    toggleEntity,
    toggleAccount,
    everythingSelected,
    showEntitiesWithoutAccounts,
    showSelectedIcons = true,
    onReset,
    ...popoverButtonProps
}) => {
    const selectedEntities = useMemo(
        () => entities.filter((e) => isEntitySelected(e.id)),
        [entities, isEntitySelected],
    );

    const label = useMemo(() => {
        let content: JSX.Element;
        if (everythingSelected) {
            content = <>All accounts</>;
        } else if (selectedEntities.length === 1 && singleEntityName) {
            content = (
                <>
                    {showSelectedIcons && (
                        <EntityIcon entity={selectedEntities[0]} size="xs" />
                    )}
                    <span className="accounts-filter__label__entity-name">
                        {selectedEntities[0].name}
                    </span>
                </>
            );
        } else {
            content = (
                <>
                    {showSelectedIcons && (
                        <>
                            {selectedEntities
                                .slice(0, ICONS_LIMIT)
                                .map((entity) => (
                                    <EntityIcon
                                        key={entity.id}
                                        entity={entity}
                                        size="xs"
                                    />
                                ))}
                            {selectedEntities.length > ICONS_LIMIT && (
                                <ObjectIcon
                                    name="..."
                                    defaultColor={
                                        selectedEntities[ICONS_LIMIT].iconColor
                                    }
                                    size="xs"
                                    square
                                >
                                    <DotsHorizontalIcon className="accounts-filter__more-icon" />
                                </ObjectIcon>
                            )}
                        </>
                    )}

                    <span className="accounts-filter__label__entities-num">
                        {selectedEntities.length}{" "}
                        {pluralize("Entity", selectedEntities.length)}
                    </span>
                </>
            );
        }

        return <span className="accounts-filter__label">{content}</span>;
    }, [
        selectedEntities,
        singleEntityName,
        everythingSelected,
        showSelectedIcons,
    ]);

    return (
        <PopoverContainer
            id="accounts-filter"
            buttonText={label}
            offset={4}
            {...popoverButtonProps}
        >
            <AccountsFilterBody
                entities={entities}
                isEntitySelected={isEntitySelected}
                toggleEntity={toggleEntity}
                showEntitiesWithoutAccounts={showEntitiesWithoutAccounts}
                isAccountSelected={isAccountSelected}
                toggleAccount={toggleAccount}
                excludeNoBalanceAccounts={excludeNoBalanceAccounts}
            />
        </PopoverContainer>
    );
};
