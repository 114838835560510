import { useUserCache } from "./useUserCache";
import { TransactionsEntityAccountsFilter } from "../common/types/filters/transactions";
import { useWorkspaceContext } from "../state/workspaceContext";

const ACCOUNTS_CACHE_KEY = "activity-page-selected-accounts";

export function useFinancialAccountsCache() {
    const { activeWorkspaceKey } = useWorkspaceContext();
    return useUserCache<TransactionsEntityAccountsFilter[]>(
        `${activeWorkspaceKey}/${ACCOUNTS_CACHE_KEY}`,
    );
}
