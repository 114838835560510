import React from "react";
import styles from "./FlatRateBilling.module.scss";
import { flatRatePlanNames } from "../../../../common/flatRateBilling";
import { format } from "date-fns";
import { fullDateFormat } from "../../../../common/helpers/date";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { EndTrialAction } from "../../../billing/AccessBlockedPage/EndTrialAction";

interface Props {}

export const CurrentPlanHeader: React.FC<Props> = () => {
    const { currentPlan, periodEnd, isTrialing, isDowngrading } =
        useBillingStatus();

    const planName = (
        <>
            {flatRatePlanNames[currentPlan]} {isTrialing && "- Free Trial"}
        </>
    );

    let additionalPlanInfo: React.ReactNode;

    if (isDowngrading && periodEnd) {
        additionalPlanInfo = (
            <span className={styles.additionalInfo}>
                (Access until {format(periodEnd, fullDateFormat)})
            </span>
        );
    } else if (isTrialing) {
        const cta = "Start subscription now";

        additionalPlanInfo = (
            <span className={styles.additionalInfo}>
                (Ends {format(periodEnd!, fullDateFormat)} -{" "}
                <EndTrialAction
                    className="p-0 border-0"
                    variant="link"
                    size={"xs" as any}
                >
                    {cta}
                </EndTrialAction>
                )
            </span>
        );
    }

    return (
        <header className={styles.title} data-testid="current-plan-name">
            {planName} {additionalPlanInfo}
        </header>
    );
};
