import React, { useMemo } from "react";
import { Transaction } from "../../../common/types/transaction";
import styles from "./TransactionDateColumnContent.module.scss";
import { formatDateForTransaction } from "../../../helpers/date";

interface Props {
    transaction: Transaction;
}

export const TransactionDateColumnContent: React.FC<Props> = ({
    transaction,
}) => {
    const formattedTransactionDate = useMemo(
        () => formatDateForTransaction(transaction.date),
        [transaction.date],
    );

    return (
        <span className={styles.value} data-testid="transaction-list-item-date">
            {formattedTransactionDate}
        </span>
    );
};
