import React from "react";
import { FilterProps } from "../../lib";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { FormRowRaw } from "../../../../forms/FormRowRaw";
import isNumber from "lodash/isNumber";
import { MoneyDirection } from "../../../../../common/constants";
import NumberFormat from "react-number-format";
import styles from "./TransactionAmountFilter.module.scss";

interface Props extends FilterProps {
    directionSwitchDisabled?: boolean;
}

type DirectionRadioDef = {
    id: string;
    label: React.ReactNode;
    direction?: MoneyDirection;
};

const directionRadios: DirectionRadioDef[] = [
    {
        id: "transactions-filter-form__direction__any",
        label: "Any",
        direction: undefined,
    },
    {
        id: "transactions-filter-form__direction__money_in",
        label: "Money in (e.g. deposits, refunds)",
        direction: MoneyDirection.MONEY_IN,
    },
    {
        id: "transactions-filter-form__direction__money_out",
        label: "Money out (e.g. purchases, charges)",
        direction: MoneyDirection.MONEY_OUT,
    },
];

export const TransactionAmountFilter: React.FC<Props> = ({
    filters,
    onChange,
    directionSwitchDisabled,
}) => {
    const minAmountValue = isNumber(filters.minAmount) ? filters.minAmount : "";
    const maxAmountValue = isNumber(filters.maxAmount) ? filters.maxAmount : "";

    return (
        <div className="transactions-filter-form__amount amount-filter">
            <section className={styles.direction}>
                <header className={styles.header}>Direction</header>

                {directionRadios.map(({ id, label, direction }) => (
                    <FormCheckbox
                        key={id}
                        value={direction ?? ""}
                        type="radio"
                        name="direction"
                        label={label}
                        isChecked={filters.direction === direction}
                        handleChange={() => onChange({ direction })}
                        small
                        disabled={directionSwitchDisabled}
                    />
                ))}
            </section>

            <section className={styles.values}>
                <FormRowRaw
                    value={minAmountValue}
                    label="From"
                    size="sm"
                    field={
                        <NumberFormat
                            value={minAmountValue}
                            onValueChange={(v) =>
                                onChange({
                                    minAmount: v.floatValue ?? undefined,
                                })
                            }
                            prefix="$ "
                            allowEmptyFormatting
                            className="form-control form-control-sm"
                        />
                    }
                />

                <FormRowRaw
                    value={maxAmountValue}
                    label="To"
                    size="sm"
                    field={
                        <NumberFormat
                            value={maxAmountValue}
                            onValueChange={(v) =>
                                onChange({
                                    maxAmount: v.floatValue ?? undefined,
                                })
                            }
                            prefix="$ "
                            allowEmptyFormatting
                            className="form-control form-control-sm"
                        />
                    }
                />
            </section>
        </div>
    );
};
