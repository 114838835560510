import React, { useCallback } from "react";
import { useToastMessages } from "../../general/ToastMessages/useToastMessages";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useConvertTrialMutation } from "../../../mutations/flatRateBilling";
import { ButtonProps } from "react-bootstrap/Button";

export const EndTrialAction: React.FC<Omit<ButtonProps, "onClick">> = (
    buttonProps,
) => {
    const { toast } = useToastMessages();

    const onEnded = useCallback(() => {
        toast(`Plan updated.`);
    }, [toast]);

    const convertTrialMutation = useConvertTrialMutation(onEnded);

    return (
        <ButtonWithLoader mutation={convertTrialMutation} {...buttonProps} />
    );
};
