import React from "react";
import classNames from "classnames";

interface Props {
    title: string;
    isString?: boolean;
}

export const GeneralLedgerTableHeaderCell: React.FC<Props> = ({
    title,
    isString,
}) => {
    return (
        <th
            className={classNames("data-table__header-cell", {
                "data-table__header-cell--string-col": isString,
            })}
        >
            {title}
            <span className="data-table__cover-cell">{title}</span>
        </th>
    );
};
