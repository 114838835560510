import React from "react";
import { Logo } from "../general/Logo";
import styles from "./OnboardingLayout.module.scss";
import classNames from "classnames";
import { EXIT_ANIMATION_DURATION } from "./constants";

interface Props {
    className?: string;
    nav?: React.ReactNode;
    stepKey?: string;
    isExiting?: boolean;
    exitAnimationDuration?: number;
}

export const OnboardingLayout: React.FC<Props> = ({
    nav,
    className,
    children,
    stepKey,
    isExiting,
    exitAnimationDuration = EXIT_ANIMATION_DURATION,
}) => {
    return (
        <div
            className={classNames(
                styles.layout,
                {
                    [styles.hasNav]: !!nav,
                },
                className,
            )}
            style={
                {
                    "--exit-animation-duration": `${exitAnimationDuration}ms`,
                } as any
            }
        >
            <header className={styles.header}>
                <Logo variant="full" />
            </header>

            <div className={styles.grid}>
                {nav ? <aside className={styles.sidebar}>{nav}</aside> : null}
                <main
                    className={classNames(styles.main, {
                        [styles.isExiting]: isExiting,
                    })}
                    key={stepKey}
                >
                    {children}
                </main>
            </div>
        </div>
    );
};
