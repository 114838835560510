export enum ReportType {
    PROFIT_LOSS = "profit-loss",
    SPENDING = "spending",
}

export const REPORTS_LABELS: Record<ReportType, string> = {
    [ReportType.PROFIT_LOSS]: "Profit & Loss",
    [ReportType.SPENDING]: "Spending",
};

export function isValidReport(report: string): report is ReportType {
    return Object.values(ReportType).includes(report as ReportType);
}
