import React from "react";
import { User } from "../../../common/types/user";
import { UserAvatar } from "../../user/UserAvatar";
import { PhoneNumberDisplay } from "../../phoneNumber/PhoneNumberDisplay";

interface Props {
    user: User;
}

export const UserView: React.FC<Props> = ({ user }) => {
    return (
        <div className="d-flex align-items-center">
            <UserAvatar user={user} size="lg" />
            <div className="ml-2">
                <div>{user.name}</div>
                <small className="text-grey">
                    {user.email ?? (
                        <PhoneNumberDisplay
                            phoneNumber={user.phoneNumberDisplay!}
                        />
                    )}
                </small>
            </div>
        </div>
    );
};
