import React from "react";
import { TaxQuestionProps } from "./types";

export const TaxQuestion: React.FC<TaxQuestionProps> = ({
    question,
    answersByKey,
    onAnswer,
    renderAnswerComponent,
}) => {
    const answer = answersByKey[question.key];

    return (
        <>
            {renderAnswerComponent({
                question,
                answer,
                onAnswer,
            })}
        </>
    );
};
