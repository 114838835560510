import React from "react";
import classNames from "classnames";

interface Props {
    className?: string;
}

export const SelectDropdownDivider: React.FC<Props> = ({ className }) => {
    return (
        <div className={classNames("select-dropdown__divider", className)} />
    );
};
