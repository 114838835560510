import React, { useState } from "react";
import {
    ExpandedSidebar,
    ExpandedSidebarProps,
} from "../layout/ExpandedSidebar/ExpandedSidebar";
import classNames from "classnames";
import { OnboardingNav } from "./OnboardingNav";
import { NavLink } from "react-router-dom";
import { useMobileView } from "../../hooks/useMobileView";
import { Intro } from "./Intro/Intro";
import { Register } from "./Register/Register";
import { useOnboardingSteps } from "./useOnboardingSteps";

interface Props {
    showSidebar: boolean;
    sidebarSize: ExpandedSidebarProps["sidebarSize"];
}

export const OnboardingRegister: React.FC<Props> = ({
    showSidebar,
    sidebarSize,
}) => {
    const [seenIntro, setSeenIntro] = useState(false);
    const isMobile = useMobileView();
    const onboardingSteps = useOnboardingSteps();

    return (
        <ExpandedSidebar
            className={classNames("onboarding", {
                "onboarding--no-sidebar": !showSidebar,
            })}
            header={
                <NavLink
                    to="/"
                    exact
                    className="btn btn-lg btn-secondary register__login-btn"
                >
                    Log in
                </NavLink>
            }
            sidebarSize={sidebarSize}
        >
            {!isMobile && showSidebar ? (
                <OnboardingNav step="intro" steps={onboardingSteps} />
            ) : null}
            {!seenIntro ? (
                <Intro next={() => setSeenIntro(true)} />
            ) : (
                <Register />
            )}
        </ExpandedSidebar>
    );
};
