import type { PricingTier } from "../../billing";
import { pick } from "lodash";
import type { FlatRatePlan } from "../../flatRateBilling";
import { BillingStatus, SubscriptionDetails } from "../../types/billing";
import { valueOrNull } from "../dto-helpers";
import { SubscriptionDetailsDto } from "./subscription-details.dto";

export class BillingStatusDto {
    static deserialize(data: BillingStatusDto): BillingStatus {
        return {
            ...data,
            subscriptionDetails: valueOrNull(
                data.subscriptionDetails,
                SubscriptionDetailsDto.deserialize,
            ),
        };
    }

    public pricingTiers: PricingTier[];
    public subscriptionDetails: SubscriptionDetailsDto | null;
    constructor(
        public creditsUsed: number,
        public totalUsage: number,
        public plans: FlatRatePlan[],
        public trialAvailable: boolean,
        pricingTiers: PricingTier[],
        subscriptionDetails?: SubscriptionDetails,
    ) {
        // cleanup the pricing tiers to only include the fields we want to expose
        this.pricingTiers = pricingTiers.map((tier) =>
            pick(tier, ["start", "end", "price"]),
        );

        this.subscriptionDetails = subscriptionDetails
            ? new SubscriptionDetailsDto(subscriptionDetails)
            : null;
    }
}
