import React from "react";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";
import { Button } from "react-bootstrap";

interface Props {
    back(): void;
}

export const WaitlistFinish: React.FC<Props> = ({ back }) => {
    return (
        <OnboardingStep title="We got it!" narrow>
            <p>
                We’ll reach out when we’re building for your industry or
                country. Follow us on X to stay in the loop on how we're
                progressing.
            </p>

            <OnboardingStepActions className="mt-5" onBack={back}>
                <Button
                    type="submit"
                    variant="primary"
                    href="https://x.com/kickfinance"
                    target="_blank"
                >
                    Follow us on X
                </Button>
            </OnboardingStepActions>
        </OnboardingStep>
    );
};
