import React, { useEffect, useRef, useState } from "react";
import { Tab } from "react-bootstrap";
import "./Settings.scss";
import { IntegrationsSettings } from "./Integrations/IntegrationsSettings";
import { GeneralSettings } from "./General/GeneralSettings";
import { SecuritySettings } from "./Security/SecuritySettings";
import {
    ActivityIcon,
    CogwheelIcon,
    CreditCardIcon,
    IntegrationsIcon,
    LockIcon,
    PlansIcon,
    ReceiptIcon,
} from "../../icons";
import Scrollbars from "react-custom-scrollbars-2";
import {
    EntitySettingsPages,
    UserSettingsPages,
    WorkspaceSettingsPages,
    useSettings,
} from "./SettingsProvider";
import { ReceiptsSettings } from "./Receipts/ReceiptsSettings";
import { useUser } from "../../hooks/useUser";
import { useEntities } from "../../hooks/useEntities";
import { EntityPanes } from "./EntityPanes";
import { NavigationItem } from "../navigation/NavigationItem";
import { NavigationMenu } from "../navigation/NavigationMenu";
import { SettingsNavLink } from "./SettingsNavLink";
import { EntitiesNavigation } from "../navigation/EntitiesNavigation";
import { EntitySettingsNavigation } from "./EntitySettingsNavigation";
import { getEntityPaneKey } from "./lib";
import { BillingPage } from "./Billing/BillingPage";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import { PlansPage } from "./Plans/PlansPage";
import classNames from "classnames";
import { SubscriptionType } from "../../common/types/billing";
import { useBillingType } from "../../hooks/useBillingType";
import { PlanManagementProvider } from "../billing/PlanManagement/PlanManagementProvider";
import "./SettingsWidget.scss";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { Categories } from "./Categories/Categories";
import { useRoles } from "../../hooks/useRoles";
import { ADMIN_ROLES } from "../../common/constants";
import { RulesSettings } from "./Rules/RulesSettings";

export const Settings: React.FC = () => {
    const user = useUser();
    const { open, path, entityId } = useSettings();
    const entities = useEntities();
    const [openedEntity, setOpenedEntity] = useState<number>();
    const { canManageBilling } = useBillingManagement();
    const billingType = useBillingType();

    useEffect(() => {
        if (entityId) {
            setOpenedEntity(entityId);
        }
    }, [entityId]);

    const scrollbarsRef = useRef<Scrollbars>(null);

    const showPlans =
        canManageBilling && billingType === SubscriptionType.FLAT_RATE;

    const { activeWorkspace } = useWorkspaceContext();
    const {
        customCategories: canUseCustomCategories,
        newCategorizationRules: canUseCategorizationRules,
    } = useTypedFlags();
    const isAdmin = useRoles(ADMIN_ROLES);

    return (
        <Tab.Container
            mountOnEnter
            unmountOnExit
            activeKey={path[0]}
            onSelect={(key) => open([key!])}
        >
            <div className={classNames("settings", `settings--${path[0]}`)}>
                <aside className="settings__navigation">
                    <Scrollbars>
                        <div className="settings__navigation__content">
                            <header className="settings__navigation__header">
                                {user.name}
                            </header>

                            <NavigationMenu className="flex-column">
                                <SettingsNavLink
                                    eventKey={UserSettingsPages.GENERAL}
                                >
                                    <NavigationItem
                                        icon={<CogwheelIcon />}
                                        children="General"
                                    />
                                </SettingsNavLink>
                                <SettingsNavLink
                                    eventKey={UserSettingsPages.RECEIPTS}
                                >
                                    <NavigationItem
                                        icon={<ReceiptIcon />}
                                        children="Receipts"
                                    />
                                </SettingsNavLink>
                                <SettingsNavLink
                                    eventKey={UserSettingsPages.INTEGRATIONS}
                                >
                                    <NavigationItem
                                        icon={<IntegrationsIcon />}
                                        children="Integrations"
                                    />
                                </SettingsNavLink>
                                <SettingsNavLink
                                    eventKey={UserSettingsPages.SECURITY}
                                >
                                    <NavigationItem
                                        icon={<LockIcon />}
                                        children="Security"
                                    />
                                </SettingsNavLink>

                                {showPlans && (
                                    <SettingsNavLink
                                        eventKey={UserSettingsPages.PLANS}
                                    >
                                        <NavigationItem
                                            icon={<PlansIcon />}
                                            children="Plans"
                                        />
                                    </SettingsNavLink>
                                )}

                                {canManageBilling && (
                                    <SettingsNavLink
                                        eventKey={UserSettingsPages.BILLING}
                                    >
                                        <NavigationItem
                                            icon={<CreditCardIcon />}
                                            children="Billing"
                                        />
                                    </SettingsNavLink>
                                )}
                            </NavigationMenu>

                            {activeWorkspace &&
                                isAdmin &&
                                (canUseCustomCategories ||
                                    canUseCategorizationRules) && (
                                    <>
                                        <header className="settings__navigation__workspaces-header">
                                            Workspace
                                        </header>

                                        <NavigationMenu className="flex-column">
                                            {canUseCustomCategories && (
                                                <SettingsNavLink
                                                    eventKey={
                                                        WorkspaceSettingsPages.CATEGORIES
                                                    }
                                                >
                                                    <NavigationItem
                                                        icon={<ActivityIcon />}
                                                        children="Categories"
                                                    />
                                                </SettingsNavLink>
                                            )}
                                            {canUseCategorizationRules && (
                                                <SettingsNavLink
                                                    eventKey={
                                                        WorkspaceSettingsPages.RULES
                                                    }
                                                >
                                                    <NavigationItem
                                                        icon={<ActivityIcon />}
                                                        children="Rules"
                                                    />
                                                </SettingsNavLink>
                                            )}
                                        </NavigationMenu>
                                    </>
                                )}

                            <EntitiesNavigation
                                isEntityActive={(entity) =>
                                    entityId === entity.id
                                }
                                onSelectEntity={(entity) =>
                                    open([
                                        getEntityPaneKey(
                                            EntitySettingsPages.ACCOUNTS,
                                            entity,
                                        ),
                                    ])
                                }
                                renderNavigation={(entity) => (
                                    <EntitySettingsNavigation entity={entity} />
                                )}
                                openedEntity={openedEntity}
                                setOpenedEntity={setOpenedEntity}
                            />
                        </div>
                    </Scrollbars>
                </aside>

                <div className="settings__content__panel">
                    <Scrollbars ref={scrollbarsRef}>
                        <main className="settings__content">
                            <Tab.Content>
                                <Tab.Pane
                                    eventKey={UserSettingsPages.GENERAL}
                                    title="General"
                                >
                                    <GeneralSettings />
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey={UserSettingsPages.RECEIPTS}
                                    title="Receipts"
                                >
                                    <ReceiptsSettings />
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey={UserSettingsPages.INTEGRATIONS}
                                    title="Integrations"
                                >
                                    <IntegrationsSettings
                                        scrollbars={scrollbarsRef}
                                    />
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey={UserSettingsPages.SECURITY}
                                    title="Security"
                                >
                                    <SecuritySettings />
                                </Tab.Pane>

                                {canManageBilling && (
                                    <Tab.Pane
                                        eventKey={UserSettingsPages.BILLING}
                                        title="Billing"
                                    >
                                        <BillingPage />
                                    </Tab.Pane>
                                )}

                                {showPlans && (
                                    <Tab.Pane
                                        eventKey={UserSettingsPages.PLANS}
                                        title="Plans"
                                    >
                                        <PlanManagementProvider>
                                            <PlansPage />
                                        </PlanManagementProvider>
                                    </Tab.Pane>
                                )}

                                {activeWorkspace &&
                                    isAdmin &&
                                    canUseCustomCategories && (
                                        <Tab.Pane
                                            eventKey={
                                                WorkspaceSettingsPages.CATEGORIES
                                            }
                                        >
                                            <PlanManagementProvider>
                                                <Categories />
                                            </PlanManagementProvider>
                                        </Tab.Pane>
                                    )}

                                {activeWorkspace &&
                                    isAdmin &&
                                    canUseCategorizationRules && (
                                        <Tab.Pane
                                            eventKey={
                                                WorkspaceSettingsPages.RULES
                                            }
                                        >
                                            <PlanManagementProvider>
                                                <RulesSettings />
                                            </PlanManagementProvider>
                                        </Tab.Pane>
                                    )}

                                {entities.map((entity) => (
                                    <EntityPanes
                                        entity={entity}
                                        scrollbars={scrollbarsRef}
                                        key={entity.id}
                                    />
                                ))}
                            </Tab.Content>
                        </main>
                    </Scrollbars>
                </div>
            </div>
        </Tab.Container>
    );
};
