import { Entity } from "../common/types/entity";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import {
    getPayrollQueryKey,
    gustoConnectionsQueryKey,
    queryClient,
} from "../queryClient";
import { StripeConnection } from "../common/types/stripeConnection";
import {
    addNotification,
    updateConnectAccountState,
} from "../reducers/appState";
import {
    createGustoConnection,
    fixGustoConnection,
    syncGustoConnection,
} from "../lib/gusto";
import { GustoConnection } from "../common/types/gustoConnection";
import { FinancialConnection } from "../common/types/financialConnection";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
    entitiesAccountsQueryKey,
} from "../queries/entitiesAccounts";

interface GustoConnectionCreationPayload {
    code: string;
    defaultEntity?: Entity;
}
export function useGustoConnectionCreationMutation(onSuccess?: () => void) {
    const dispatch = useDispatch();
    return useMutation(
        ({ code, defaultEntity }: GustoConnectionCreationPayload) =>
            createGustoConnection({
                code,
                defaultEntityId: defaultEntity?.id,
            }),
        {
            onSuccess: (connection) => {
                queryClient.setQueryData(gustoConnectionsQueryKey, [
                    ...(queryClient.getQueryData<StripeConnection[]>(
                        gustoConnectionsQueryKey,
                    ) ?? []),
                    connection,
                ]);
                addFinancialConnectionInQueryData(queryClient, connection);
                if (!connection.accounts[0].financialAccount.entityId) {
                    dispatch(
                        updateConnectAccountState({
                            isConnecting: false,
                            connection: connection,
                            duplicates: [],
                        }),
                    );
                }
                onSuccess?.();
            },
        },
    );
}

export function useSyncGustoConnectionMutation(entity: Entity) {
    const dispatch = useDispatch();
    return useMutation(
        (connection: GustoConnection) => syncGustoConnection(connection),
        {
            onSuccess: async (result) => {
                const key = getPayrollQueryKey(entity.id);
                await queryClient.invalidateQueries(key);
                queryClient.setQueryData(gustoConnectionsQueryKey, [
                    ...(queryClient.getQueryData<StripeConnection[]>(
                        gustoConnectionsQueryKey,
                    ) ?? []),
                    result,
                ]);
                queryClient.invalidateQueries(entitiesAccountsQueryKey);
                dispatch(
                    addNotification({
                        message: `Connection synced successfully`,
                        type: "success",
                    }),
                );
            },
            onError: (_1, oldConnection) => {
                queryClient.invalidateQueries(entitiesAccountsQueryKey);
                queryClient.invalidateQueries(gustoConnectionsQueryKey);
            },
        },
    );
}

interface FixGustoConnectionPayload {
    connection: FinancialConnection;
    code: string;
}
export function useFixGustoConnectionsMutation(onSuccess?: () => unknown) {
    return useMutation(
        (payload: FixGustoConnectionPayload) =>
            fixGustoConnection(
                payload.connection as GustoConnection,
                payload.code,
            ),
        {
            onSuccess: (connected) => {
                if (!connected) {
                    return;
                }
                clearConnectionErrorInQueryData(queryClient, connected.id);
                onSuccess?.();
                queryClient.setQueryData<FinancialConnection[] | undefined>(
                    gustoConnectionsQueryKey,
                    (connections) =>
                        connections?.map((c) =>
                            c.id === connected.id
                                ? {
                                      ...c,
                                      accounts: connected.accounts,
                                      error: null,
                                  }
                                : c,
                        ) ?? [],
                );
            },
        },
    );
}
