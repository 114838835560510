import { Entity } from "../common/types/entity";
import { useMutation } from "react-query";
import { createEntity, saveTaxLocations, updateAddress } from "../lib/entity";
import { USAddressDto } from "../common/dto/common/usAddress.dto";
import { getAddressQueryKey, queryClient } from "../queryClient";
import { CreateEntityDto } from "../common/dto/entity/create-entity.dto";
import { UpdateEntityDto } from "../common/dto/entity/update-entity.dto";
import { entityRest } from "../lib/restClient";
import { SaveTaxLocationsDto } from "../common/dto/entity/save-tax-locations.dto";
import {
    entitiesAccountsQueryKey,
    updateEntityInQueryData,
} from "../queries/entitiesAccounts";

export function useEntityAddressMutation(entity: Entity) {
    return useMutation(
        (address: USAddressDto) =>
            updateAddress({ entityId: entity.id, address }),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(getAddressQueryKey(entity.id), data);
            },
        },
    );
}

export function useEntityCreationMutation() {
    return useMutation((data: CreateEntityDto) => createEntity(data), {
        onSuccess: (entity) => {
            queryClient.invalidateQueries(entitiesAccountsQueryKey);
        },
    });
}

export function useEntityTaxLocationsMutation() {
    return useMutation((data: SaveTaxLocationsDto) => saveTaxLocations(data), {
        onSuccess: (entity) => {
            updateEntityInQueryData(queryClient, entity);
        },
    });
}

export function useEditEntityMutation(entity: Entity) {
    return useMutation<Entity, unknown, UpdateEntityDto, Entity>(
        (payload: UpdateEntityDto) => entityRest.update(entity.id, payload),
        {
            onMutate: (payload) => {
                updateEntityInQueryData(queryClient, {
                    ...entity,
                    ...payload,
                });

                return entity;
            },
            onError: (_1, _2, previousEntity) => {
                if (previousEntity) {
                    updateEntityInQueryData(queryClient, previousEntity);
                }
            },
            onSuccess: (updatedEntity) => {
                updateEntityInQueryData(queryClient, updatedEntity);
            },
        },
    );
}
