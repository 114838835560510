import React, { ReactElement } from "react";
import { TransactionsEntityAccountsFilter } from "../../../common/types/filters/transactions";
import { AccountFilterSharedProps, EntityFilterSharedProps } from "./lib";
import { Entity } from "../../../common/types/entity";
import { useEntityAccountsFilter } from "./useEntityAccountsFilter";

interface RenderFilterProps
    extends AccountFilterSharedProps,
        EntityFilterSharedProps {
    entities: Entity[];
    everythingSelected: boolean;
}

type RenderFilterFn = (
    props: RenderFilterProps,
) => ReactElement<any, any> | null;

export interface EntityAccountsFilterProps {
    onlyBusiness?: boolean;
    excludeMockEntities?: boolean;
    value?: TransactionsEntityAccountsFilter[];
    onChange(value: TransactionsEntityAccountsFilter[] | undefined): void;
    renderFilter: RenderFilterFn;
}

export const EntityAccountsFilter: React.FC<EntityAccountsFilterProps> = ({
    onlyBusiness,
    excludeMockEntities,
    value,
    onChange,
    renderFilter,
}) => {
    const {
        entities,
        isAccountSelected,
        toggleAccount,
        toggleEntity,
        isEntitySelected,
        everythingSelected,
    } = useEntityAccountsFilter({
        value,
        onChange,
        excludeAllMockEntities: excludeMockEntities,
        onlyBusiness,
    });

    return renderFilter({
        entities,
        everythingSelected,
        isEntitySelected,
        isAccountSelected,
        toggleEntity,
        toggleAccount,
        showEntitiesWithoutAccounts: true,
    });
};
