import React, { memo, ReactElement } from "react";
import { UserRole } from "../../../common/types/userEntityAssignment";
import { Entity } from "../../../common/types/entity";
import { ADMIN_ROLES, PRIMARY_ADMIN_ROLES } from "../../../common/constants";
import { useRoles } from "../../../hooks/useRoles";

export interface WhenRoleProps {
    entity?: Entity;
    children(): ReactElement<any, any> | null;
}
export interface WhenRolesProps extends WhenRoleProps {
    roles: UserRole[];
}

export const WhenRoles: React.FC<WhenRolesProps> = memo(
    ({ roles, entity, children }) => {
        const hasRequiredRole = useRoles(roles, entity);
        return hasRequiredRole ? children() : null;
    },
);

export const WhenPrimaryAdmin: React.FC<WhenRoleProps> = memo((props) => (
    <WhenRoles roles={PRIMARY_ADMIN_ROLES} {...props} />
));

export const WhenAdmin: React.FC<WhenRoleProps> = memo((props) => (
    <WhenRoles roles={ADMIN_ROLES} {...props} />
));
