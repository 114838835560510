import { createAction, createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../types";
import { CreateUnitIndividualApplicationDto } from "../common/dto/unit/create-unit-individual-application.dto";
import { CreateUnitBusinessApplicationDto } from "../common/dto/unit/create-unit-business-application.dto";
import { UnitPersonDto } from "../common/dto/unit/unit-person.dto";

export type OnboardingStep =
    | "intro"
    | "register"
    | "business_profile"
    | "business_description"
    | "redeem_credits"
    | "connect_accounts"
    | "personal_info"
    | "receipts_start"
    | "receipts_finish"
    | "choose_plan"
    | "outro";

export interface OnboardingState {
    forcedStep?: OnboardingStep;
}

export type OnboardingKycValues =
    | OnboardingKycValuesIndividual
    | OnboardingKycValuesBusiness;

export interface OnboardingKycValuesIndividual {
    type: "individual";
    values: Partial<CreateUnitIndividualApplicationDto>;
}

export interface OnboardingKycValuesBusiness {
    type: "business";
    values: BusinessValues;
}

export interface BusinessValues
    extends Partial<Omit<CreateUnitBusinessApplicationDto, "owner">> {
    owner?: Partial<UnitPersonDto>;
}

const initialState: OnboardingState = {};

export const setForcedStep = createAction<OnboardingStep>("SET_FORCED_STEP");
export const clearForcedStep = createAction("CLEAR_FORCED_STEP");

export const onboarding = createReducer<OnboardingState>(initialState, {
    [setForcedStep.toString()]: (
        state,
        { payload }: ActionWithPayload<OnboardingStep | undefined>,
    ) => {
        state.forcedStep = payload;
    },
    [clearForcedStep.toString()]: (state) => {
        state.forcedStep = undefined;
    },
});
