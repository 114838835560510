import React from "react";
import { PlaidConnection } from "../../common/types/plaidConnection";
import { ExternalConnectionRow } from "./ExternalConnection";

export interface ExternalConnectionsProps {
    connections: PlaidConnection[];
}

export const ExternalConnections: React.FC<ExternalConnectionsProps> = ({
    connections,
}) => {
    return (
        <section>
            <h3>External Bank Connections</h3>
            <table className="table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Entity Name</th>
                        <th>Bank</th>
                        <th />
                    </tr>
                </thead>

                <tbody>
                    {connections.map((connection) => (
                        <ExternalConnectionRow
                            connection={connection}
                            key={connection.id}
                        />
                    ))}
                </tbody>
            </table>
        </section>
    );
};
