import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { AggregatedFiltersTabs, FilterProps } from "../lib";
import { NavigationItem } from "../../../navigation/NavigationItem";
import { CalendarIcon, FinancingIcon, FolderIcon } from "../../../../icons";
import { AggregatedCategoryFilter } from "./CategoryFilter/AggregatedCategoryFilter";
import { TransactionAmountFilter } from "./AmountFilter/TransactionAmountFilter";
import { AggregatedDateFilter } from "../../../general/AggregatedFilters/DateFilter/AggregatedDateFilter";
import { AggregatedFiltersOverlay } from "../../../general/AggregatedFilters/AggregatedFiltersOverlay";

interface Props extends FilterProps {
    directionSwitchDisabled?: boolean;
}

export const AggregatedTransactionFiltersOverlay: React.FC<Props> = ({
    filters,
    onChange,
    directionSwitchDisabled = false,
}) => {
    return (
        <AggregatedFiltersOverlay
            navigation={
                <>
                    <Nav.Link eventKey={AggregatedFiltersTabs.DATE}>
                        <NavigationItem
                            icon={<CalendarIcon />}
                            children="Date"
                            activeIndicator
                        />
                    </Nav.Link>

                    <Nav.Link eventKey={AggregatedFiltersTabs.CATEGORY}>
                        <NavigationItem
                            icon={<FolderIcon />}
                            children="Category"
                            activeIndicator
                        />
                    </Nav.Link>

                    <Nav.Link eventKey={AggregatedFiltersTabs.AMOUNT}>
                        <NavigationItem
                            icon={<FinancingIcon />}
                            children="Amount"
                            activeIndicator
                        />
                    </Nav.Link>
                </>
            }
            content={
                <>
                    <Tab.Pane eventKey={AggregatedFiltersTabs.DATE}>
                        <AggregatedDateFilter
                            filters={filters}
                            onChange={onChange}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={AggregatedFiltersTabs.CATEGORY}>
                        <AggregatedCategoryFilter
                            filters={filters}
                            onChange={onChange}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={AggregatedFiltersTabs.AMOUNT}>
                        <TransactionAmountFilter
                            filters={filters}
                            onChange={onChange}
                            directionSwitchDisabled={directionSwitchDisabled}
                        />
                    </Tab.Pane>
                </>
            }
        />
    );
};
