import React from "react";
import { Tag } from "../../../../general/Tag/Tag";
import styles from "./UsageSection.module.scss";

interface Props {
    isCurrent?: boolean;
    isNext?: boolean;
}

export const TierTag: React.FC<Props> = ({ isCurrent, isNext }) => {
    if (!isCurrent && !isNext) {
        return null;
    }

    let label;

    if (isCurrent) {
        label = "Current tier";
    } else if (isNext) {
        label = "Tier needed";
    }

    return (
        <Tag
            className={styles.tierTag}
            variant={isCurrent ? "primary" : "warning"}
        >
            {label}
        </Tag>
    );
};
