import React, { useMemo } from "react";
import { Entity } from "../../../common/types/entity";
import {
    getTotalBalance,
    useFinancialAccounts,
} from "../../../hooks/useFinancialAccounts";
import { AccountsChartEntity } from "./AccountsChartEntity";
import "./AccountsChart.scss";

interface Props {
    entities: Entity[];
}

export const AccountsChart: React.FC<Props> = ({ entities }) => {
    const allAccounts = useFinancialAccounts({
        entityIds: entities.map((e) => e.id),
    });
    const totalBalance = useMemo(
        () => getTotalBalance(allAccounts),
        [allAccounts],
    );

    return (
        <section className="accounts-chart">
            {entities.map((entity) => (
                <AccountsChartEntity
                    entity={entity}
                    globalTotalBalance={totalBalance}
                    key={entity.id}
                />
            ))}
        </section>
    );
};
