import React from "react";
import styles from "./DocumentsEmptyState.module.scss";
import { DocumentThinIcon } from "../../icons";

interface Props {
    header?: React.ReactNode;
}

export const DocumentsEmptyState: React.FC<Props> = ({ header, children }) => {
    return (
        <section
            className={styles.container}
            data-testid="financial-documents-empty-state-section"
        >
            <aside className={styles.icon}>
                <DocumentThinIcon />
            </aside>

            <header>{header}</header>
            <div>{children}</div>
        </section>
    );
};
