import React, { useMemo } from "react";
import classNames from "classnames";
import "./ProgressList.scss";
import { CheckIcon } from "../../icons";
import { range } from "lodash";

interface StepListProps {
    items: string[];
}

interface StepCountProps {
    items: number;
}

type Props = StepListProps | StepCountProps;

export type ProgressListProps = {
    className?: string;
    currentStep: number;
    goToStep?(targetStep: number): void;
    canGoToStep?(targetStep: number): boolean;
    renderStep?(step: number): React.ReactNode;
} & Props;

export const ProgressList: React.FC<ProgressListProps> = ({
    items,
    currentStep,
    className,
    goToStep,
    canGoToStep,
    renderStep,
}) => {
    const navItems = useMemo(() => {
        if (typeof items === "number") {
            return range(0, items);
        } else {
            return items;
        }
    }, [items]);

    return (
        <ol className={classNames("list-unstyled progress-list", className)}>
            {navItems.map((item, idx) => (
                <li
                    key={item}
                    className={classNames({
                        hoverable: canGoToStep?.(idx + 1),
                        active: currentStep === idx + 1,
                        completed: currentStep > idx + 1,
                    })}
                    onClick={goToStep && (() => goToStep(idx + 1))}
                >
                    <span className="progress-list__marker">
                        {currentStep > idx + 1 ? <CheckIcon /> : null}
                    </span>
                    {renderStep ? renderStep(idx) : item}
                </li>
            ))}
        </ol>
    );
};
