import { useBillingStatus } from "./useBillingStatus";
import { useMemo } from "react";
import { EntityProfileType, EntityType } from "../common/types/entity";
import { FLAT_RATE_PLANS } from "../common/flatRateBilling";
import { useEntities } from "./useEntities";

export const ENTERPRISE_REVENUE_THRESHOLD = 1_000_000;
export function useRecommendedPlan() {
    const entities = useEntities();

    const { currentPlan, totalUsage, plans, isFetched } = useBillingStatus();

    const recommendedPlan = useMemo(() => {
        if (!isFetched || !entities.length) {
            return null;
        }

        const useForRecommendation = Math.max(
            totalUsage,
            ...entities.map((entity) => entity.profile?.annualExpenses ?? 0),
        );
        const penultimatePlan = plans[plans.length - 2]; // plan before enterprise

        if (
            entities.some(
                (entity) =>
                    (entity.profile?.annualRevenue ?? 0) >=
                    ENTERPRISE_REVENUE_THRESHOLD,
            )
        ) {
            return FLAT_RATE_PLANS.ENTERPRISE;
        }

        if (useForRecommendation >= penultimatePlan.usageLimit) {
            return FLAT_RATE_PLANS.ENTERPRISE;
        }

        const hasCCorpEntity = entities.some(
            (entity) =>
                (entity.profile?.entityType &&
                    [EntityType.C_CORP, EntityType.C_CORP_WITH_S_CORP].includes(
                        entity.profile?.entityType,
                    )) ||
                entity.profile?.type === EntityProfileType.C_CORP,
        );

        if (hasCCorpEntity) {
            return FLAT_RATE_PLANS.ENTERPRISE;
        }

        const hasSCorpOrPartnershipEntity = entities.some(
            (entity) =>
                entity.profile?.entityType === EntityType.LLC_WITH_S_CORP ||
                entity.profile?.type === EntityProfileType.S_CORP ||
                entity.profile?.type === EntityProfileType.PARTNERSHIP,
        );

        if (hasSCorpOrPartnershipEntity) {
            return FLAT_RATE_PLANS.PLUS;
        }

        return FLAT_RATE_PLANS.BASIC;
    }, [isFetched, plans, totalUsage, entities]);

    const isUpgrade =
        plans.findIndex(({ plan }) => plan === recommendedPlan) >
        plans.findIndex(({ plan }) => plan === currentPlan);

    return isUpgrade ? recommendedPlan : null;
}
