import React from "react";
import { capitalize } from "lodash";
import { Button } from "react-bootstrap";
import { SettingsSection } from "../../SettingsSection";
import { Card } from "../../../general/Card/Card";
import { getAnonymousAccountNumber } from "../../../../common/helpers/bankAccount";
import { getEnv } from "../../../../lib/environment";
import styles from "./FlatRateBilling.module.scss";
import classNames from "classnames";
import { useBillingInfo } from "../../../../hooks/useBillingInfo";
import { ButtonProps } from "react-bootstrap/Button";
import { Loader } from "../../../general/Loader";

export const PaymentDetails: React.FC = () => {
    const { data: billingInfo, isFetched } = useBillingInfo();
    const editDataButtonProps = {
        href: getEnv("VITE_STRIPE_PORTAL_URL"),
        target: "_blank",
        variant: "secondary",
        size: "sm",
    } as const satisfies ButtonProps;

    if (!isFetched) {
        return <Loader />;
    }

    if (!billingInfo) {
        return null;
    }

    let billedTo: React.ReactNode;

    if (billingInfo?.address || billingInfo?.name) {
        const addressLine = [
            billingInfo.name,
            billingInfo.address?.street,
            billingInfo.address?.country,
        ]
            .filter(Boolean)
            .join(", ");

        billedTo = (
            <section className={classNames(styles.section, styles.compact)}>
                <main>
                    <div>
                        <header className={styles.title}>Billed to</header>
                        {addressLine}
                    </div>
                    <aside>
                        <Button {...editDataButtonProps}>Edit address</Button>
                    </aside>
                </main>
            </section>
        );
    }

    return (
        <SettingsSection heading="Payment details">
            <Card className={styles.card}>
                {billingInfo?.paymentMethod && (
                    <section
                        className={classNames(styles.section, styles.compact)}
                    >
                        <main>
                            <div>
                                <header className={styles.title}>
                                    Payment method
                                </header>
                                {capitalize(
                                    billingInfo.paymentMethod.brand.toLowerCase(),
                                )}{" "}
                                {getAnonymousAccountNumber(
                                    billingInfo.paymentMethod.last4,
                                    2,
                                    "",
                                )}
                            </div>
                            <aside>
                                <Button {...editDataButtonProps}>
                                    Change method
                                </Button>
                            </aside>
                        </main>
                    </section>
                )}
                {billingInfo?.email && (
                    <section
                        className={classNames(styles.section, styles.compact)}
                    >
                        <main>
                            <div>
                                <header className={styles.title}>
                                    Billing email
                                </header>
                                {billingInfo.email}
                            </div>
                            <aside>
                                <Button {...editDataButtonProps}>
                                    Update email
                                </Button>
                            </aside>
                        </main>
                    </section>
                )}

                {billedTo}
            </Card>
        </SettingsSection>
    );
};
