import { PayrollTotals } from "../../types/payroll";

export class PayrollTotalsDto {
    static deserialize(dto: PayrollTotalsDto): PayrollTotals {
        return {
            ...dto,
        };
    }

    companyDebit: number;
    netPayDebit: number;
    taxDebit: number;
    reimbursementDebit: number;
    childSupportDebit: number;
    reimbursements: number;
    netPay: number;
    grossPay: number;
    employeeBonuses: number;
    employeeCommissions: number;
    employeeCashTips: number;
    employeePaycheckTips: number;
    additionalEarnings: number;
    ownersDraw: number;
    checkAmount: number;
    employerTaxes: number;
    employeeTaxes: number;
    benefits: number;
    employeeBenefitsDeductions: number;
    deferredPayrollTaxes: number;

    constructor(totals: PayrollTotals) {
        this.companyDebit = totals.companyDebit;
        this.netPayDebit = totals.netPayDebit;
        this.taxDebit = totals.taxDebit;
        this.reimbursementDebit = totals.reimbursementDebit;
        this.childSupportDebit = totals.childSupportDebit;
        this.reimbursements = totals.reimbursements;
        this.netPay = totals.netPay;
        this.grossPay = totals.grossPay;
        this.employeeBonuses = totals.employeeBonuses;
        this.employeeCommissions = totals.employeeCommissions;
        this.employeeCashTips = totals.employeeCashTips;
        this.employeePaycheckTips = totals.employeePaycheckTips;
        this.additionalEarnings = totals.additionalEarnings;
        this.ownersDraw = totals.ownersDraw;
        this.checkAmount = totals.checkAmount;
        this.employerTaxes = totals.employerTaxes;
        this.employeeTaxes = totals.employeeTaxes;
        this.benefits = totals.benefits;
        this.employeeBenefitsDeductions = totals.employeeBenefitsDeductions;
        this.deferredPayrollTaxes = totals.deferredPayrollTaxes;
    }
}
