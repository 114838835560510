import React from "react";
import { Button, Modal } from "react-bootstrap";
import { User } from "../../../common/types/user";
import { UserView } from "./UserView";
import { ModalComponentProps } from "../../../helpers/modal";
import { useRemoveMemberMutation } from "../../../mutations/user";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useEntity } from "../../entity/useEntity";

interface Props extends ModalComponentProps {
    user: User;
}

export const RemoveMemberModal: React.FC<Props> = ({ user, close }) => {
    const entity = useEntity();
    const remove = useRemoveMemberMutation(user, entity, close);

    return (
        <>
            <Modal.Header closeButton>
                <h3 className="mb-0">
                    Are you sure that you want to delete this member?
                </h3>
            </Modal.Header>
            <Modal.Body>
                <UserView user={user} />
                <footer className="mt-5 d-flex justify-content-between">
                    <Button variant="secondary" onClick={close} size="lg">
                        Cancel
                    </Button>
                    <ButtonWithLoader
                        variant="primary"
                        mutation={remove}
                        size="lg"
                    >
                        Yes, delete
                    </ButtonWithLoader>
                </footer>
            </Modal.Body>
        </>
    );
};
