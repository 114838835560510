import React, { useCallback } from "react";
import { getWebAuth } from "../../../lib/auth0";
import { getEnv } from "../../../lib/environment";
import { Button } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";
import { ButtonProps } from "react-bootstrap/Button";
import { GoogleIcon } from "../../../icons";
import { GOOGLE_AUTH_REDIRECT_PATH } from "../../../common/constants";

type LoginWithGoogleButtonProps = Omit<ButtonProps, "onClick">;

export const LoginWithGoogleButton: React.FC<LoginWithGoogleButtonProps> = ({
    children,
    ...props
}) => {
    const route = useRouteMatch<{ inviteCode: string }>();

    const handleGoogleLogin = useCallback(() => {
        const redirect = new URL(window.location.origin);
        redirect.pathname = GOOGLE_AUTH_REDIRECT_PATH;

        if (route.params.inviteCode) {
            redirect.searchParams.set("inviteCode", route.params.inviteCode);
        }

        getWebAuth().authorize({
            connection: "google-oauth2",
            responseType: "token",
            redirectUri: redirect.toString(),
            audience: getEnv("VITE_AUTH0_AUDIENCE"),
        });
    }, [route.params.inviteCode]);

    return (
        <Button {...props} onClick={handleGoogleLogin}>
            <GoogleIcon className="icon-color-revert mr-2" /> {children}
        </Button>
    );
};
