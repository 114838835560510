import React, { useEffect, useRef } from "react";
import {
    PLColumns,
    useProfitLossReportTable,
} from "./useProfitLossReportTable";
import Scrollbars from "react-custom-scrollbars-2";
import { ReportFilters } from "../../../common/types/filters/reports";
import { ProfitLossReportTable } from "./ProfitLossReportTable";
import { useTableData } from "./useTableData";
import { useProfitLossReportTableColumns } from "./useProfitLossReportTableColumns";
import { ProfitLossTableReportSection } from "../../../common/dto/reports/reports-common.dto";
import { ProfitLossReport } from "../types";
import classNames from "classnames";
import { StickyDataTable } from "../../general/DataTable/StickyDataTable";

export interface Props {
    report: ProfitLossReport;
    filters: ReportFilters;
    grouping: PLColumns[];
    highlightLastRow?: boolean;
    withBorder?: boolean;
    numberFormatter?: (value: number) => string;
}

export interface PLDataRow {
    id: number;
    section: ProfitLossTableReportSection;
    category: string;
    customCategory: string | null;
    isTotal: boolean;
    data: {
        [dataPoint: string]: number;
    };
}

export const ProfitLossReportTableContainer: React.FC<Props> = ({
    report,
    filters,
    grouping,
    highlightLastRow,
    withBorder,
    numberFormatter,
}) => {
    const tableData = useTableData(report);
    const columns = useProfitLossReportTableColumns(report, grouping);
    const table = useProfitLossReportTable(tableData, columns, grouping);
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollbarsRef = useRef<Scrollbars>(null);

    useEffect(() => {
        window.setTimeout(() => {
            scrollbarsRef.current?.scrollToRight();
        }, 0);
    }, [report]);

    return (
        <div
            className={classNames("profit-loss-report-table data-table", {
                "data-table--with-border": withBorder,
            })}
            ref={containerRef}
        >
            <StickyDataTable
                containerRef={containerRef}
                ref={scrollbarsRef}
                stickyLeft
                stickyRight
            >
                <ProfitLossReportTable
                    table={table}
                    filters={filters}
                    highlightLastRow={highlightLastRow}
                    numberFormatter={numberFormatter}
                />
            </StickyDataTable>
        </div>
    );
};
