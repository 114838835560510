import React from "react";
import { useOpeningBalancesLink } from "../../../hooks/useOpeningBalancesLink";
import { Button } from "react-bootstrap";

export const SubmitOpeningBalancesAction: React.FC = () => {
    const url = useOpeningBalancesLink();

    return (
        <Button href={url} target="_blank" variant="secondary">
            Submit opening balances
        </Button>
    );
};
