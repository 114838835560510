import React from "react";
import { capitalize } from "lodash";
import { SettingsSection } from "../../../SettingsSection";
import { getAnonymousAccountNumber } from "../../../../../common/helpers/bankAccount";
import styles from "./PaymentDetails.module.scss";
import { Button } from "react-bootstrap";
import { getEnv } from "../../../../../lib/environment";
import { useBillingInfo } from "../../../../../hooks/useBillingInfo";

export const PaymentDetails: React.FC = () => {
    const { data: info, isFetched } = useBillingInfo();

    if (!info?.paymentMethod || !isFetched) {
        return null;
    }

    const { brand, last4 } = info.paymentMethod;
    const brandLabel = capitalize(brand.toLowerCase());

    return (
        <SettingsSection heading="Payment">
            <div className={styles.details}>
                {brandLabel} {getAnonymousAccountNumber(last4, 2, "")}
                <Button
                    href={getEnv("VITE_STRIPE_PORTAL_URL")}
                    target="_blank"
                    variant="secondary"
                    size="sm"
                >
                    Update
                </Button>
            </div>

            <p className={styles.help}>
                For questions about billing, use chat or email{" "}
                <a href="mailto:support@kick.co">support@kick.co</a>
            </p>
        </SettingsSection>
    );
};
