import React from "react";
import "./ExpandedSidebar.scss";
import classNames from "classnames";
import { Logo, LogoProps } from "../../general/Logo";

export interface ExpandedSidebarProps {
    children: [React.ReactNode | null, React.ReactNode | null];
    className?: string;
    header?: React.ReactNode | null;
    sidebarSize?: 10 | 25 | 33;
    logo?: LogoProps["variant"];
}

export const ExpandedSidebar: React.FC<ExpandedSidebarProps> = ({
    children,
    className,
    header,
    sidebarSize = 33,
    logo = "full",
}) => {
    return (
        <div
            className={classNames(
                "expanded-sidebar",
                `expanded-sidebar--${sidebarSize}`,
                className,
            )}
        >
            <div className="expanded-sidebar__container">
                <div className="expanded-sidebar__logo">
                    <Logo variant={logo} />
                </div>

                <div className="expanded-sidebar__header">{header}</div>

                <aside className="expanded-sidebar__sidebar">
                    <main className="expanded-sidebar__sidebar__content">
                        {children[0]}
                    </main>
                </aside>

                <main className="expanded-sidebar__primary">
                    <div className="expanded-sidebar__primary__content">
                        {children[1]}
                    </div>
                </main>
            </div>
        </div>
    );
};
