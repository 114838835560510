import React, { useMemo } from "react";
import { Transaction } from "../../../../common/types/transaction";
import { TransactionDetailsSection } from "../TransactionDetailsSection";
import { last, orderBy } from "lodash";
import { Taxonomy } from "../../../../common/categories";
import { TransactionDetailsHistoryLog } from "./TransactionDetailsHistoryLog";
import "./TransactionDetailsHistory.scss";
import {
    CategoryAuditLogValue,
    TransactionAuditLogProperty,
} from "../../../../common/types/transactionAuditLog";

interface Props {
    transaction: Transaction;
}

export const TransactionDetailsHistory: React.FC<Props> = ({ transaction }) => {
    const displayedLogs = useMemo(() => {
        const sorted = orderBy(transaction.auditLogs, "date", "desc");
        const lastLog = last(sorted);

        if (
            lastLog?.property === TransactionAuditLogProperty.CATEGORY &&
            (lastLog?.newValue as CategoryAuditLogValue).category ===
                Taxonomy.uncategorized
        ) {
            sorted.pop();
        }

        return sorted;
    }, [transaction.auditLogs]);

    return displayedLogs.length ? (
        <TransactionDetailsSection title="History">
            <section
                className="transaction-details-history"
                data-testid="transaction-details-history"
            >
                {displayedLogs.map((log, index) => (
                    <TransactionDetailsHistoryLog log={log} key={log.id} />
                ))}
            </section>
        </TransactionDetailsSection>
    ) : null;
};
