import React, { useCallback, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { EditIcon } from "../../../icons";
import { Transaction } from "../../../common/types/transaction";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { Field, Form, Formik } from "formik";
import { ToggleableInput } from "../../general/ToggleableInput/ToggleableInput";
import { FormRow } from "../../forms/FormRow";
import "./TransactionDetailsMemo.scss";
import classNames from "classnames";
import { topStartPopperConfig } from "../../../helpers/overlays";

interface Props {
    transaction: Transaction;
    required: boolean;
}

interface FormSchema {
    memo: string;
}

export const TransactionDetailsMemo: React.FC<Props> = ({
    transaction,
    required,
}) => {
    const { onUpdate } = useContext(TransactionDetailsContext);
    const initialValues: FormSchema = { memo: transaction.memo ?? "" };
    const onSubmit = useCallback(
        async (values: FormSchema, toggleInput: () => void) => {
            toggleInput();
            if (transaction.memo !== values.memo) {
                await onUpdate({ memo: values.memo });
            }
        },
        [onUpdate, transaction.memo],
    );

    const tooltipText = transaction.memo
        ? "Edit note for this transaction"
        : "Add a note to this transaction";

    return (
        <div
            className={classNames("transaction-details-memo mb-3", {
                "transaction-details-memo--required":
                    !transaction.memo && required,
            })}
        >
            <ToggleableInput
                renderDefaultState={(toggleInput) => (
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="transaction-memo">
                                {tooltipText}
                            </Tooltip>
                        }
                        placement="top"
                        popperConfig={topStartPopperConfig}
                    >
                        <div
                            data-testid="transaction-details-note-container"
                            className={classNames(
                                "transaction-details-memo__display",
                                {
                                    "transaction-details-memo__display--filled":
                                        Boolean(transaction.memo),
                                    "transaction-details-memo__display--empty":
                                        !transaction.memo,
                                },
                            )}
                            {...toggleInput}
                        >
                            <div>
                                {transaction.memo ? (
                                    <span className="label">Note</span>
                                ) : (
                                    <span>Add note</span>
                                )}
                                {transaction.memo && (
                                    <div data-testid="transaction-note">
                                        {transaction.memo}{" "}
                                    </div>
                                )}
                            </div>
                            <aside className="ml-auto">
                                <Button
                                    variant="link"
                                    size="sm"
                                    className="p-0"
                                >
                                    <EditIcon className="transaction-details-memo__edit-icon" />
                                </Button>
                            </aside>
                        </div>
                    </OverlayTrigger>
                )}
                renderInputState={(toggleInput, inputRef) => (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values) =>
                            onSubmit(values, toggleInput.onClick)
                        }
                    >
                        {({ submitForm, values }) => (
                            <Form>
                                <FormRow
                                    fieldName="memo"
                                    showErrorMessage={false}
                                >
                                    <Field
                                        data-testid="transaction-note-input"
                                        name="memo"
                                        className={classNames(
                                            "form-control mb-0",
                                            { empty: !values.memo },
                                        )}
                                        innerRef={inputRef}
                                        onBlur={() => {
                                            submitForm();
                                        }}
                                    />
                                </FormRow>
                            </Form>
                        )}
                    </Formik>
                )}
            />
        </div>
    );
};
