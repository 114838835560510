import { useUser } from "./useUser";
import { useCallback, useMemo } from "react";

/**
 * WARNING: This hook only reads value from localStorage on mount, it won't refresh for already mounted component.
 */
export function useUserCache<T = any>(
    key: string,
): [T | undefined, (value: T | undefined) => void] {
    const user = useUser();
    const finalKey = `${key}-${user.id}`;

    const value = useMemo<T>(() => {
        const rawValue = localStorage.getItem(finalKey);
        return rawValue ? JSON.parse(rawValue) : undefined;
    }, [finalKey]);

    const setter = useCallback(
        (v: T | undefined) => {
            if (v === undefined) {
                localStorage.removeItem(finalKey);
            } else {
                localStorage.setItem(finalKey, JSON.stringify(v));
            }
        },
        [finalKey],
    );

    return [value, setter];
}
