import {
    CategoryBackup,
    Transaction,
    TransactionBase,
    TransactionStatus,
    TransactionType,
} from "../../types/transaction";
import { Taxonomy } from "../../categories";
import { TaxQuestionKey } from "../../taxSavingRules";

// without any relations loaded
export class TransactionBaseDto {
    id: number;
    amount: number;
    absoluteAmount: number;
    date: string;
    type: TransactionType;
    status: TransactionStatus;
    description: string;
    customDescription: string | null;
    kickDescription: string | null;
    plaidDescription: string | null;
    bankDescription: string;
    category: Taxonomy;
    categoryBackup: CategoryBackup | null;
    memo: string | null;
    amountSaved: number | null;
    potentialAmountSaved: number | null;
    requiredActions: string[];

    taxQuestions: TaxQuestionKey[];
    deductionRate: number;
    requirementsSnoozed: boolean;
    isIgnored: boolean;
    isBusiness: boolean;

    inReview: boolean;

    isEligibleForTaxSavings: boolean;
    deductionRateAdjustable: boolean;

    counterpartyId: string | null;

    static deserialize(dto: TransactionBaseDto): TransactionBase {
        return {
            ...dto,
            date: new Date(dto.date),
        };
    }

    constructor(
        transaction: Omit<
            Transaction,
            | "auditLogs"
            | "financialAccount"
            | "split"
            | "taxQuestionAnswers"
            | "entity"
            | "transactionMatches"
        >,
    ) {
        this.id = transaction.id;
        this.amount = transaction.amount;
        this.absoluteAmount = transaction.absoluteAmount;
        this.date = transaction.date.toISOString();
        this.type = transaction.type;
        this.status = transaction.status;
        this.description = transaction.description;
        this.customDescription = transaction.customDescription;
        this.kickDescription = transaction.kickDescription;
        this.plaidDescription = transaction.plaidDescription;
        this.bankDescription = transaction.bankDescription;
        this.category = transaction.category;
        this.categoryBackup = transaction.categoryBackup;
        this.memo = transaction.memo;
        this.amountSaved = transaction.amountSaved;
        this.potentialAmountSaved = transaction.potentialAmountSaved;
        this.requiredActions = transaction.requiredActions;
        this.taxQuestions = transaction.taxQuestions;
        this.deductionRate = transaction.deductionRate;
        this.requirementsSnoozed = transaction.requirementsSnoozed;
        this.isIgnored = transaction.isIgnored;
        this.isBusiness = transaction.isBusiness;
        this.inReview = transaction.inReview;
        this.isEligibleForTaxSavings = transaction.isEligibleForTaxSavings;
        this.deductionRateAdjustable = transaction.deductionRateAdjustable;
        this.counterpartyId = transaction.counterpartyId;
    }
}
