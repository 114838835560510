import React from "react";
import styles from "./EditableTable.module.scss";
import classNames from "classnames";

import {
    EditableTableCell,
    EditableTableCellProps,
    calculateCellBorders,
} from "./EditableTableCell";

interface EditableTableCellStringProps
    extends Omit<EditableTableCellProps, "children"> {
    onChange: undefined | ((value: string | number | null) => void);
    value: string;
}

export const EditableTableCellString = React.memo(
    ({
        id,
        isFirstRow,
        onChange,
        selectionState,
        value,
        onClick,
    }: EditableTableCellStringProps) => {
        const cellBorders = calculateCellBorders(selectionState);

        return (
            <EditableTableCell
                id={id}
                isFirstRow={isFirstRow}
                selectionState={selectionState}
            >
                <input
                    className={classNames(
                        "form-control form-control-sm",
                        styles.editableTableInput,
                    )}
                    onChange={(e) => {
                        onChange?.(e.target.value);
                    }}
                    value={value}
                    onFocus={onClick}
                    style={cellBorders}
                ></input>
            </EditableTableCell>
        );
    },
);
