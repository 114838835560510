import { toNumber } from "lodash";
import React from "react";
import { Taxonomy, TransactionDirectionType } from "../../common/categories";
import { getMainCategoryId } from "../../common/helpers/taxonomy";
import { Category } from "../../common/types/category";
import { useCategoryMap } from "../../hooks/useCategoryMap";
import { useCustomCategories } from "../../hooks/useCustomCategories";
import AdvertisingMarketingIcon from "../../icons/transaction_categories/advertising_marketing.svg?react";
import AutoVehicleIcon from "../../icons/transaction_categories/auto_vehicle.svg?react";
import BusinessOffice from "../../icons/transaction_categories/business_office.svg?react";
import CogsIcon from "../../icons/transaction_categories/cogs.svg?react";
import ContributionsIcon from "../../icons/transaction_categories/contribution.svg?react";
import ContributionsAndDistributionsIcon from "../../icons/transaction_categories/contribution_and_distribution.svg?react";
import CommunicationsIcon from "../../icons/transaction_categories/communications.svg?react";
import CreditCardLoansIcons from "../../icons/transaction_categories/credit_card_loans.svg?react";
import DistributionsIcon from "../../icons/transaction_categories/distribution.svg?react";
import DonationsIcon from "../../icons/transaction_categories/donations.svg?react";
import EntertainmentIcon from "../../icons/transaction_categories/entertainment.svg?react";
import FeesIcon from "../../icons/transaction_categories/fees.svg?react";
import HealthDentalIcon from "../../icons/transaction_categories/health_dental.svg?react";
import IncomeIcon from "../../icons/transaction_categories/income.svg?react";
import InsuranceIcon from "../../icons/transaction_categories/insurance.svg?react";
import LearningDevelopmentIcon from "../../icons/transaction_categories/learning_development.svg?react";
import LegalProfessionalIcon from "../../icons/transaction_categories/legal_professional.svg?react";
import MealsIcon from "../../icons/transaction_categories/meals.svg?react";
import PayrollIcon from "../../icons/transaction_categories/payroll.svg?react";
import PersonalIcon from "../../icons/transaction_categories/personal.svg?react";
import RealEstateIcon from "../../icons/transaction_categories/real_estate.svg?react";
import RentLeasesIcon from "../../icons/transaction_categories/rentals_leases.svg?react";
import RepairsMaintenanceIcon from "../../icons/transaction_categories/repairs_maintenance.svg?react";
import RetirementIcon from "../../icons/transaction_categories/retirement.svg?react";
import SecurityDepositIcon from "../../icons/transaction_categories/security_deposit.svg?react";
import SoftwareSubscriptionsIcon from "../../icons/transaction_categories/software_subscriptions.svg?react";
import SuppliesEquipmentIcon from "../../icons/transaction_categories/supplies_equipment.svg?react";
import TaxesIcon from "../../icons/transaction_categories/taxes.svg?react";
import TransferIcon from "../../icons/transaction_categories/transfer.svg?react";
import TravelIcon from "../../icons/transaction_categories/travel.svg?react";
import UncategorizedIcon from "../../icons/transaction_categories/uncategorized.svg?react";
import UtilitiesIcon from "../../icons/transaction_categories/utilities.svg?react";

interface IconMapFunctionParams {
    transactionDirection: TransactionDirectionType;
}
type IconMapFunction = (params: IconMapFunctionParams) => React.ReactNode;

const ICON_MAP: Partial<Record<Taxonomy, React.ReactNode | IconMapFunction>> = {
    advertising_marketing: <AdvertisingMarketingIcon />,
    auto_vehicle: <AutoVehicleIcon />,
    business_office: <BusinessOffice />,
    communications: <CommunicationsIcon />,
    cogs: <CogsIcon />,
    credit_card_loans: <CreditCardLoansIcons />,
    donations: <DonationsIcon />,
    learning_development: <LearningDevelopmentIcon />,
    entertainment: <EntertainmentIcon />,
    fees: <FeesIcon />,
    health_dental: <HealthDentalIcon />,
    income: <IncomeIcon />,
    insurance: <InsuranceIcon />,
    legal_professional: <LegalProfessionalIcon />,
    meals: <MealsIcon />,
    payroll: <PayrollIcon />,
    personal: <PersonalIcon />,
    real_estate: <RealEstateIcon />,
    rentals_leases: <RentLeasesIcon />,
    security_deposit: <SecurityDepositIcon />,
    repairs_maintenance: <RepairsMaintenanceIcon />,
    retirement: <RetirementIcon />,
    software_subscriptions: <SoftwareSubscriptionsIcon />,
    supplies_equipment: <SuppliesEquipmentIcon />,
    taxes: <TaxesIcon />,
    transfer: <TransferIcon />,
    contributions_distributions: ({ transactionDirection }) => {
        if (transactionDirection === TransactionDirectionType.incoming) {
            return <ContributionsIcon />;
        }
        if (transactionDirection === TransactionDirectionType.outgoing) {
            return <DistributionsIcon />;
        }
        return <ContributionsAndDistributionsIcon />;
    },
    travel: <TravelIcon />,
    uncategorized: <UncategorizedIcon />,
    utilities: <UtilitiesIcon />,
};

interface Props {
    category: Taxonomy | Category;
    transactionDirection?: TransactionDirectionType;
}

export const CategoryIcon: React.FC<Props> = ({
    category,
    transactionDirection = TransactionDirectionType.mixed,
}) => {
    const categoryMap = useCategoryMap();
    const customCategories = useCustomCategories();
    const customCategoryId =
        category && isFinite(toNumber(category))
            ? toNumber(category)
            : undefined;
    const customCategory = customCategories.find(
        ({ id }) => id === customCategoryId,
    );

    const id =
        customCategory?.mainCategoryId ??
        getMainCategoryId(category, categoryMap);

    const icon = ICON_MAP[id];

    if (typeof icon === "function") {
        return icon({ transactionDirection });
    }

    return <>{icon}</>;
};
