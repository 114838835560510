import React from "react";
import { LoginFlow } from "./LoginFlow/LoginFlow";
import "./Login.scss";
import { Logo } from "../general/Logo";

export const Login: React.FC = () => {
    return (
        <section className="login">
            <header>
                <Logo variant="full" />
            </header>
            <div className="login__content">
                <LoginFlow />
            </div>
        </section>
    );
};
