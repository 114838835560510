import React, { useMemo } from "react";
import { Transaction } from "../../../common/types/transaction";
import { TaxQuestions } from "../TaxQuestions/TaxQuestions";
import { TaxQuestionAnswerSelect } from "../TaxQuestions/TaxQuestionAnswerSelect";
import { keyBy, mapValues } from "lodash";
import { AnswerHandler, TaxAnswers } from "../TaxQuestions/types";
import { TaxQuestion } from "../../../common/taxSavingRules";

interface Props {
    transaction: Transaction;
    onAnswer: AnswerHandler;
    questions: TaxQuestion[];
}

export const TransactionTaxQuestions: React.FC<Props> = ({
    transaction,
    onAnswer,
    questions,
}) => {
    const answersByKey = useMemo(
        () =>
            mapValues(
                keyBy(transaction.taxQuestionAnswers, "key"),
                "answer",
            ) as TaxAnswers,
        [transaction.taxQuestionAnswers],
    );

    if (questions.length === 0) {
        return null;
    }

    return (
        <div
            className="transaction-details__questions"
            data-testid="transaction-details-questions-container"
        >
            <TaxQuestions
                questions={questions}
                answers={answersByKey}
                onAnswer={onAnswer}
                transaction={transaction}
                renderAnswerComponent={(
                    options, // nosonar
                ) => <TaxQuestionAnswerSelect {...options} />}
            />
        </div>
    );
};
