import React, { useMemo } from "react";
import { ProfitLossReportChart } from "./ProfitLossChart";
import { Loader } from "../general/Loader";
import { EmptyState } from "../general/EmptyState/EmptyState";
import { useReportsContext } from "./reports.context";
import { ProfitLossReportSummaryContainer } from "./ProfitLossReportSummary/ProfitLossReportSummaryContainer";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import { CallToActionBanner } from "../general/CallToActionBanner/CallToActionBanner";
import { isEqual, startOfDay, startOfYear } from "date-fns";
import { TransactionsDrillDownModal } from "./TransactionsDrillDownModal";
import { TransactionsDrillDownContextProvider } from "./TransactionsDrillDownContextProvider";
import { useIsCategorizationInProgress } from "../../hooks/useIsCategorizationInProgress";
import { CategorizationInProgressNotification } from "./CategorizationInProgressNotification";
import { Alert } from "react-bootstrap";
import { CashBasedProfitLossTableContainer } from "./CashBasedProfitLossTableContainer";

interface Props {
    resetFilters: () => void;
}

export const CashBasedProfitLoss: React.FC<Props> = ({ resetFilters }) => {
    const {
        profitLossReport,
        isProfitLossReportLoading,
        profitLossFiltersUsed,
        reportCreatedAt,
    } = useReportsContext();
    const { showNotSubscribedCta, showLimitReachedCta, bookkeepingStartDate } =
        useBillingManagement();

    const showCta = useMemo(() => {
        return (
            showLimitReachedCta ||
            (showNotSubscribedCta &&
                !isEqual(
                    startOfYear(bookkeepingStartDate),
                    startOfDay(bookkeepingStartDate),
                ))
        );
    }, [bookkeepingStartDate, showLimitReachedCta, showNotSubscribedCta]);

    const isCategorizationInProgress = useIsCategorizationInProgress();

    if (isProfitLossReportLoading) {
        return <Loader />;
    }

    return profitLossReport?.chart.series.length &&
        profitLossFiltersUsed &&
        reportCreatedAt ? (
        <>
            {showCta && (
                <CallToActionBanner>
                    {(ctaBtn) => (
                        <>
                            {isCategorizationInProgress && (
                                <CategorizationInProgressNotification />
                            )}{" "}
                            {ctaBtn} to get access to your annual Profit & Loss.
                        </>
                    )}
                </CallToActionBanner>
            )}
            {!showCta && isCategorizationInProgress && (
                <Alert
                    variant="primary"
                    className="reports__categorization-prompt"
                >
                    <CategorizationInProgressNotification />
                </Alert>
            )}
            <ProfitLossReportSummaryContainer
                report={profitLossReport}
                filters={profitLossFiltersUsed}
            />
            <ProfitLossReportChart
                report={profitLossReport}
                filters={profitLossFiltersUsed}
                reportCreatedAt={reportCreatedAt}
            />
            <TransactionsDrillDownContextProvider>
                <CashBasedProfitLossTableContainer
                    report={profitLossReport}
                    filters={profitLossFiltersUsed}
                />
                <TransactionsDrillDownModal
                    filters={profitLossFiltersUsed}
                    reportCreatedAt={reportCreatedAt}
                />
            </TransactionsDrillDownContextProvider>
        </>
    ) : (
        <div className="reporting-tab__empty-state">
            <EmptyState
                header="No matching transactions"
                body="The filters can't reproduce a report"
                ctaText="Reset all filters"
                action={resetFilters}
                buttonVariant={"secondary"}
            />
        </div>
    );
};
