import { useUser } from "./useUser";
import { useCallback } from "react";

const KEY = "ignoreLoosingAccessToTransaction";

export function useIgnoreLoosingAccessToTransaction() {
    const user = useUser();
    const key = KEY + user.id;
    const ignoreLoosingAccess = Boolean(localStorage.getItem(key));
    const setIgnoreLoosingAccess = useCallback(() => {
        localStorage.setItem(key, "true");
    }, [key]);

    return {
        ignoreLoosingAccess,
        setIgnoreLoosingAccess,
    };
}
