import React, { useCallback, useEffect, useRef, useState } from "react";
import { logStreamRest } from "../../../lib/restClient";
import { LogStreamEvent } from "../../../common/types/logStreamEvent";
import { Loader } from "../../general/Loader";
import { SettingsSection } from "../SettingsSection";
import { LogStreamEventRow } from "./LogStreamEventRow";
import "./SecuritySettings.scss";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useUser } from "../../../hooks/useUser";

export const SecuritySettings: React.FC = () => {
    const user = useUser();
    const pageSize = 10;
    const [events, setEvents] = useState<LogStreamEvent[]>();
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const page = useRef(1);

    const fetchEvents = useCallback(
        async (pageToFetch: number) => {
            const response = await logStreamRest.queryAsOwningUser(user.id, {
                page: pageToFetch,
                limit: pageSize,
            });

            setHasMore(response.pageCount > pageToFetch);
            return response.data;
        },
        [user.id],
    );

    const loadMore = useCallback(async () => {
        page.current = page.current + 1;
        const newEvents = await fetchEvents(page.current);
        setEvents((prev) => (prev ? [...prev, ...newEvents] : newEvents));
    }, [fetchEvents]);

    useEffect(() => {
        fetchEvents(1).then(setEvents);
    }, [fetchEvents]);

    const content = events ? (
        <section className="log-stream-events">
            <table className="table log-stream-events__list mb-0">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Browser</th>
                        <th>IP</th>
                        <th>Date and time</th>
                        <th>Country</th>
                    </tr>
                </thead>

                <tbody>
                    {events
                        ? events.map((logStreamEvent) => (
                              <LogStreamEventRow
                                  log={logStreamEvent}
                                  key={logStreamEvent.logId}
                              />
                          ))
                        : null}
                </tbody>

                {hasMore && (
                    <tfoot className="text-center">
                        <tr>
                            <td colSpan={5}>
                                <ButtonWithLoader
                                    variant="link"
                                    size="sm"
                                    onClick={loadMore}
                                    loading={loading}
                                    setLoading={setLoading}
                                >
                                    View more
                                </ButtonWithLoader>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </section>
    ) : (
        <Loader />
    );

    return (
        <div className="settings__security">
            <SettingsSection heading="Activity history">
                {content}
            </SettingsSection>
        </div>
    );
};
