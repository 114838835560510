import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { getBillingStatus } from "../lib/billing";
import { FLAT_RATE_PLANS, FlatRatePlan } from "../common/flatRateBilling";
import {
    ACTIVE_SUBSCRIPTION_STATUSES,
    PricingTier,
    SubscriptionStatus,
} from "../common/billing";
import {
    BillingStatus,
    SubscriptionDetails,
    SubscriptionType,
} from "../common/types/billing";
import { useTypedFlags } from "./useTypedFlags";
import { useUser } from "./useUser";
import { useWorkspaceContext } from "../state/workspaceContext";

export const billingQueryKeys = {
    all: () => ["billing"] as const,
    status: (workspaceId: string) =>
        [...billingQueryKeys.all(), "status", workspaceId] as const,
    info: (workspaceId: string) =>
        [...billingQueryKeys.all(), "info", workspaceId] as const,
    history: (workspaceId: string) =>
        [...billingQueryKeys.all(), "history", workspaceId] as const,
} as const;

export interface UseBillingStatus {
    query: UseQueryResult<BillingStatus, unknown>;
    invalidate: () => Promise<void>;
    isFetched: boolean;

    // common
    creditsUsed: number;
    totalUsage: number;
    isSubscribed: boolean;
    subscriptionDetails: SubscriptionDetails | null;
    periodEnd: Date | null;
    cancelsAt: Date | null;
    subscriptionType: SubscriptionType;

    // annual billing
    pricingTiers: PricingTier[];

    // flat rate billing
    currentPlan: FLAT_RATE_PLANS;
    nextPlan: FLAT_RATE_PLANS;
    trialAvailable: boolean;
    plans: FlatRatePlan[];
    isTrialing: boolean;
    isDowngrading: boolean;
    bookedEnterpriseCall: boolean;
    includePreviousYear: boolean;
}

export function useBillingStatus(): UseBillingStatus {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const client = useQueryClient();
    const { flatRateBilling } = useTypedFlags();
    const { bookedEnterpriseCall } = useUser();
    const query = useQuery(billingQueryKeys.status(activeWorkspaceKey), () =>
        getBillingStatus(activeWorkspaceKey),
    );

    const invalidate = useCallback(
        () => client.invalidateQueries(billingQueryKeys.all()),
        [client],
    );

    return useMemo(() => {
        if (!query.data) {
            return {
                query,
                invalidate,
                isFetched: false,
                creditsUsed: 0,
                totalUsage: 0,
                pricingTiers: [],
                plans: [],
                subscriptionDetails: null,
                currentPlan: FLAT_RATE_PLANS.FREE,
                trialAvailable: false,
                isSubscribed: false,
                isTrialing: false,
                isDowngrading: false,
                cancelsAt: null,
                periodEnd: null,
                subscriptionType: SubscriptionType.FLAT_RATE,
                nextPlan: FLAT_RATE_PLANS.FREE,
                bookedEnterpriseCall,
                includePreviousYear: false,
            };
        }

        const subscriptionDetails = query.data.subscriptionDetails;

        const isTrialing =
            query.data.subscriptionDetails?.status ===
            SubscriptionStatus.TRIALING;

        let subscriptionType: SubscriptionType;

        if (
            !subscriptionDetails ||
            subscriptionDetails.status !== SubscriptionStatus.ACTIVE
        ) {
            subscriptionType = flatRateBilling
                ? SubscriptionType.FLAT_RATE
                : SubscriptionType.ANNUAL;
        } else {
            subscriptionType = subscriptionDetails.type;
        }

        const currentPlan = subscriptionDetails?.plan ?? FLAT_RATE_PLANS.FREE;
        const nextPlan =
            subscriptionDetails?.nextPeriodPlan ?? FLAT_RATE_PLANS.FREE;

        return {
            query,
            invalidate,
            isFetched: query.isFetched && query.isSuccess,
            creditsUsed: query.data.creditsUsed,
            totalUsage: query.data.totalUsage,
            pricingTiers: query.data.pricingTiers,
            plans: query.data.plans,
            subscriptionDetails,
            currentPlan,
            nextPlan,
            trialAvailable: query.data.trialAvailable,
            isSubscribed: Boolean(
                subscriptionDetails?.status &&
                    ACTIVE_SUBSCRIPTION_STATUSES.includes(
                        subscriptionDetails.status,
                    ),
            ),
            isTrialing,
            isDowngrading: currentPlan !== nextPlan,
            cancelsAt: subscriptionDetails?.cancelsAt ?? null,
            periodEnd: subscriptionDetails?.periodEndsAt ?? null,
            subscriptionType,
            bookedEnterpriseCall,
            includePreviousYear:
                subscriptionDetails?.includePreviousYear ?? false,
        };
    }, [bookedEnterpriseCall, flatRateBilling, invalidate, query]);
}
