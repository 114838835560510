import React from "react";

interface Props {
    label: string;
    value: React.ReactNode;
}

export const TransactionDetailsField: React.FC<Props> = ({ label, value }) => {
    return (
        <div
            className="transaction-details__field"
            data-testid="transaction-details-field"
        >
            <label>{label}</label>
            <span>{value}</span>
        </div>
    );
};
