import React from "react";
import {
    CategoryAuditLogValue,
    TransactionAuditLog,
    TransactionAuditLogAuthorType,
} from "../../../../common/types/transactionAuditLog";
import { useCategoryLabelGetter } from "../../../../hooks/useCategoryLabelGetter";
import { isValidTaxonomy } from "../../../../common/helpers/taxonomy";
import { useAuditLogAuthorLabel } from "./useAuditLogAuthorLabel";
import { TransactionAuditLogSimilarityContent } from "./TransactionAuditLogSimilarityContent";
import { TransactionAuditLogTransferMatchContent } from "./TransactionAuditLogTransferMatchContent";
import { TransactionAuditLogCategorizationRuleContent } from "./TransactionAuditLogCategorizationRuleContent";

interface Props {
    log: TransactionAuditLog;
    newValue: CategoryAuditLogValue;
}

export const TransactionAuditLogCategoryContent: React.FC<Props> = ({
    log,
    newValue,
}) => {
    const categoryLabelGetter = useCategoryLabelGetter();
    const categoryLabel = isValidTaxonomy(newValue.category)
        ? categoryLabelGetter(newValue.category)
        : newValue.category;
    const authorLabel = useAuditLogAuthorLabel(log);

    if (log.authorType === TransactionAuditLogAuthorType.SIMILARITY) {
        return (
            <TransactionAuditLogSimilarityContent
                log={log}
                categoryLabel={categoryLabel}
            />
        );
    } else if (
        log.authorType === TransactionAuditLogAuthorType.TRANSFER_MATCHING
    ) {
        return (
            <TransactionAuditLogTransferMatchContent
                log={log}
                categoryLabel={categoryLabel}
            />
        );
    } else if (
        log.authorType === TransactionAuditLogAuthorType.CATEGORIZATION_RULE
    ) {
        return (
            <TransactionAuditLogCategorizationRuleContent
                log={log}
                categoryLabel={categoryLabel}
            />
        );
    } else {
        return (
            <>
                <strong>{authorLabel}</strong> changed the category to{" "}
                <strong>{categoryLabel}</strong>.
            </>
        );
    }
};
