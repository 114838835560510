import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { noopAsync } from "../../../helpers/general";
import { bulkAction } from "../../../lib/financialDocument";
import { useFinancialDocumentBulkSelection } from "../FinancialDocumentBulkSelection/useFinancialDocumentBulkSelection";
import { FinancialDocumentsBulkActionDto } from "../../../common/dto/financialDocument/bulk/financial-documents-bulk-action.dto";
import { queryClient } from "../../../queryClient";
import { FinancialDocumentBulkAction } from "../../../common/types/financialDocument";
import { financialDocumentQueryKeys } from "../lib";
import { useWorkspaceContext } from "../../../state/workspaceContext";

interface FinancialDocumentBulkActionsContextValue {
    run(action: FinancialDocumentBulkAction): Promise<void>;
    busy: boolean;
}

const financialDocumentBulkActionsContext =
    createContext<FinancialDocumentBulkActionsContextValue>({
        run: noopAsync,
        busy: false,
    });

export function useFinancialDocumentBulkActions() {
    return useContext(financialDocumentBulkActionsContext);
}

export const FinancialDocumentBulkActionsProvider: React.FC = ({
    children,
}) => {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const [busy, setBusy] = useState(false);
    const bulkSelection = useFinancialDocumentBulkSelection();

    const runBulkAction = useCallback(
        async (action: FinancialDocumentBulkAction) => {
            const payload: FinancialDocumentsBulkActionDto =
                bulkSelection.filtersSelection
                    ? {
                          workspaceId: activeWorkspaceKey,
                          action,
                          filters: {
                              ...bulkSelection.filtersSelection,
                          },
                      }
                    : {
                          workspaceId: activeWorkspaceKey,
                          action,
                          financialDocumentIds: bulkSelection.selected.map(
                              (fd) => fd.id,
                          ),
                      };

            try {
                setBusy(true);
                await bulkAction(payload);
                await queryClient.invalidateQueries(
                    financialDocumentQueryKeys.all(),
                );
            } finally {
                setBusy(false);
            }
        },
        [
            activeWorkspaceKey,
            bulkSelection.filtersSelection,
            bulkSelection.selected,
        ],
    );

    const value = useMemo(
        () => ({
            run: runBulkAction,
            busy,
        }),
        [busy, runBulkAction],
    );

    return (
        <financialDocumentBulkActionsContext.Provider value={value}>
            {children}
        </financialDocumentBulkActionsContext.Provider>
    );
};
