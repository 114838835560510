import { isPlaidConnection } from "../../helpers/financialConnection";
import { FinancialAccountSourceType } from "../../types/financialAccount";
import {
    ConnectionProviderType,
    FinancialConnection,
} from "../../types/financialConnection";
import { IntegrationAccount } from "../../types/integrationAccount";
import { isoStringOrNull, unpackDateOrNull, valueOrNull } from "../dto-helpers";
import { PlaidConnectionDto } from "../plaidConnection/plaid-connection.dto";
import { FinancialAccountDto } from "./financial-account.dto";
import { FinancialConnectionDto } from "./financial-connection.dto";

interface IntegrationAccountDtoOptions {
    nestedInFinancialAccount?: boolean;
}

export class IntegrationAccountDto {
    static deserialize(dto: IntegrationAccountDto): IntegrationAccount {
        const connectionDeserialization =
            dto.connection?.connectionProvider === ConnectionProviderType.PLAID
                ? PlaidConnectionDto.deserialize
                : FinancialConnectionDto.deserialize;

        return {
            ...dto,
            balanceSyncedAt: unpackDateOrNull(dto.balanceSyncedAt),
            transactionsSyncedAt: unpackDateOrNull(dto.transactionsSyncedAt),
            financialAccount: FinancialAccountDto.deserialize(
                dto.financialAccount,
            ),
            connection: dto.connection
                ? valueOrNull(
                      dto.connection,
                      connectionDeserialization as (
                          dto: FinancialConnectionDto,
                      ) => FinancialConnection,
                  )
                : null,
        };
    }
    id: string;
    balanceSyncedAt: string | null;
    transactionsSyncedAt: string | null;
    sourceType: FinancialAccountSourceType;
    connectionId: number | null;
    connection?: FinancialConnectionDto;
    financialAccountId: number;
    financialAccount: FinancialAccountDto;
    metadata: any;

    constructor(
        integrationAccount: IntegrationAccount,
        { nestedInFinancialAccount }: IntegrationAccountDtoOptions = {},
    ) {
        this.id = integrationAccount.id;
        this.balanceSyncedAt = isoStringOrNull(
            integrationAccount.balanceSyncedAt,
        );
        this.transactionsSyncedAt = isoStringOrNull(
            integrationAccount.transactionsSyncedAt,
        );
        this.sourceType = integrationAccount.sourceType;
        this.connectionId = integrationAccount.connectionId;
        if (integrationAccount.connection) {
            this.connection = this.serializeConnection(
                integrationAccount.connection,
            );
        }
        this.financialAccountId = integrationAccount.financialAccountId;
        this.financialAccount = new FinancialAccountDto(
            integrationAccount.financialAccount,
            {
                includeIntegrationAccounts: !nestedInFinancialAccount,
            },
        );
        this.metadata = integrationAccount.metadata;
    }

    serializeConnection(
        connection: FinancialConnection,
    ): FinancialConnectionDto {
        if (isPlaidConnection(connection)) {
            return new PlaidConnectionDto(connection);
        }

        return new FinancialConnectionDto(connection);
    }
}
