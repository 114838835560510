import React, { useState } from "react";
import "./Members.scss";
import { Button } from "react-bootstrap";
import { useEntity } from "../../entity/useEntity";
import { ListMembers } from "./ListMembers";
import { useQuery } from "react-query";
import { inviteCodeRest } from "../../../lib/restClient";
import { Loader } from "../../general/Loader";
import {
    EMPTY_COLLECTION,
    EMPTY_COLLECTION_QUERY_KEY,
    getEntityInvitesQueryKey,
    getEntityUsersQueryKey,
} from "../../../queryClient";
import { AddMember } from "./AddMember";
import { ArrowRightIcon } from "../../../icons";
import { getMembersForEntities } from "../../../lib/user";
import { WhenAdmin } from "../../general/WhenRoles/WhenRoles";
import { useIsAdmin } from "../../../hooks/useRoles";

export const Members: React.FC = () => {
    const entity = useEntity();
    const isAdmin = useIsAdmin(entity);
    const [addMember, setAddMember] = useState(false);

    const { data: assignments } = useQuery(
        getEntityUsersQueryKey(entity.id),
        () =>
            getMembersForEntities([entity]).then(
                (response) => response[entity.id],
            ),
    );

    const { data: invites } = useQuery(
        isAdmin
            ? getEntityInvitesQueryKey(entity.id)
            : EMPTY_COLLECTION_QUERY_KEY,
        () =>
            isAdmin
                ? inviteCodeRest.queryAsEntityOwner(entity.id, {
                      filter: [
                          {
                              field: "used",
                              operator: "eq",
                              value: false,
                          },
                      ],
                  })
                : EMPTY_COLLECTION,
    );

    let members;
    if (addMember) {
        members = <AddMember onCancel={() => setAddMember(false)} />;
    } else if (!assignments || !invites) {
        members = <Loader />;
    } else {
        members = <ListMembers assignments={assignments} invites={invites} />;
    }

    return (
        <div className="members">
            <header className="d-flex align-items-start">
                <div>
                    <small className="mb-3 d-block settings__breadcrumbs">
                        {entity.name}
                        {addMember && (
                            <>
                                <ArrowRightIcon />
                                Members
                            </>
                        )}
                    </small>
                    <h3 className="settings__heading">
                        {addMember ? "Add member" : "Members"}
                    </h3>
                </div>
                {!addMember && (
                    <WhenAdmin entity={entity}>
                        {() => (
                            <Button
                                variant="secondary"
                                size="lg"
                                className="ml-auto"
                                onClick={() => setAddMember(true)}
                            >
                                Add member
                            </Button>
                        )}
                    </WhenAdmin>
                )}
            </header>

            {members}
        </div>
    );
};
