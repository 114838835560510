import React, { useCallback, useMemo } from "react";
import { useAccountingTabContext } from "../useAccountingTab";
import { AccountingReportFiltersForm } from "../AccountingReportFiltersForm";
import { getAccountTransactions } from "../../../lib/accountingReports";
import { useAccountingReport } from "../useAccountingReport";
import { GeneralLedgerPageContent } from "./GeneralLedgerPageContent";
import { AccountsProvider } from "../accountsContext/AccountsProvider";
import { AccountsMultiSelect } from "../AccountsMultiSelect";
import {
    DelimitedNumericArrayParam,
    DelimitedArrayParam,
    useQueryParam,
} from "use-query-params";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useGeneralLedgerDownload } from "./useGeneralLedgerDownload";
import { formatISO } from "date-fns";
import { ReportFormat } from "../../../common/helpers/reports";
import { GetAccountTransactionsReportResponseDto } from "../../../common/dto/reports/get-account-transactions-report-response.dto";
import { GetAccountTransactionsReportDto } from "../../../common/dto/reports/get-account-transactions-report.dto";
import { LoaderOnInitialLoading } from "../../general/LoaderOnInitialLoading";
import { AccountClass } from "../../../common/types/domains/accounting/accounts";
import { useAccounts } from "../../../hooks/useAccounts";

export const GeneralLedgerPage: React.FC = () => {
    const { filters, setFilters, selectedEntity } = useAccountingTabContext();
    const accounts = useAccounts(selectedEntity?.id);

    const [selectedAccountsRaw, setSelectedAccountsQueryParam] = useQueryParam(
        "accountCodes",
        DelimitedNumericArrayParam,
    );
    const [selectedClassificationsRaw, setSelectedClassifications] =
        useQueryParam("classifications", DelimitedArrayParam);

    const setSelectedAccounts = useCallback(
        (value: number[]) => {
            setSelectedAccountsQueryParam(value);
            setSelectedClassifications([]);
        },
        [setSelectedAccountsQueryParam, setSelectedClassifications],
    );

    const selectedClassifications = useMemo(
        () =>
            (selectedClassificationsRaw?.filter(Boolean) ?? undefined) as
                | AccountClass[]
                | undefined,
        [selectedClassificationsRaw],
    );

    const selectedAccounts = useMemo(() => {
        const filteredAccounts = (selectedAccountsRaw?.filter(Boolean) ??
            undefined) as number[] | undefined;
        if (selectedClassifications) {
            return [
                ...accounts.accounts
                    .filter((account) =>
                        selectedClassifications.includes(account.class),
                    )
                    .map((account) => account.code),
                ...(filteredAccounts ?? []),
            ];
        }
        return filteredAccounts;
    }, [selectedAccountsRaw, selectedClassifications, accounts]);

    const { report: ledger, generationDate } = useAccountingReport<
        GetAccountTransactionsReportResponseDto,
        GetAccountTransactionsReportDto<ReportFormat.JSON>
    >({
        filters,
        entity: selectedEntity,
        queryKey: "generalLedger",
        reportGetter: getAccountTransactions,
        prepareQuery: (q) => ({
            ...q,
            accountCodes: selectedAccounts,
            classifications: selectedClassifications,
            format: ReportFormat.JSON,
        }),
    });

    const { download, downloading } = useGeneralLedgerDownload(
        selectedEntity
            ? {
                  startDate: filters.startDate
                      ? formatISO(filters.startDate, { representation: "date" })
                      : undefined,
                  endDate: filters.endDate
                      ? formatISO(filters.endDate, { representation: "date" })
                      : undefined,
                  entityId: selectedEntity.id,
                  accountCodes: selectedAccounts,
              }
            : null,
    );

    return (
        <>
            <AccountingReportFiltersForm
                filters={filters}
                setFilters={setFilters}
                additionalButtons={
                    <>
                        <ButtonWithLoader
                            onClick={download}
                            loading={downloading}
                            variant="primary"
                            size="sm"
                        >
                            Download (.xlsx)
                        </ButtonWithLoader>
                    </>
                }
                additionalFilters={
                    selectedEntity && (
                        <AccountsMultiSelect
                            availableAccounts={accounts.accounts}
                            value={selectedAccounts ?? []}
                            onChange={setSelectedAccounts}
                        />
                    )
                }
                selectExactDays
            />
            {selectedEntity && (
                <AccountsProvider entityId={selectedEntity.id}>
                    <LoaderOnInitialLoading queryResult={ledger}>
                        {ledger.data && selectedEntity && (
                            <GeneralLedgerPageContent
                                data={ledger.data.accountsWithTransactions}
                                filters={filters}
                                generationDate={generationDate}
                                entity={selectedEntity}
                            />
                        )}
                    </LoaderOnInitialLoading>
                </AccountsProvider>
            )}
        </>
    );
};
