import React from "react";
import { Amount } from "../general/Amount/Amount";
import { useTotalBalance } from "../../hooks/useFinancialAccounts";
import { NavLink } from "react-router-dom";
import { AddAccount } from "./AddAccount";
import { FeatureAccess } from "../general/FeatureAccess/FeatureAccess";
import { PREMIUM_FEATURES } from "../../common/flatRateBilling";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ThunderboltIcon } from "../../icons";

export const AccountsHeader: React.FC = () => {
    const totalBalance = useTotalBalance();

    return (
        <header className="accounts__header">
            <div className="accounts__header__total-balance">
                <span className="label">Cash balance</span>
                <Amount
                    amount={totalBalance}
                    separateCurrencySign
                    showNegative
                />
            </div>
            <aside>
                <FeatureAccess
                    feature={PREMIUM_FEATURES.UNLIMITED_ENTITIES}
                    renderAllowed={() => (
                        <NavLink
                            to="/new-entity"
                            className="btn btn-secondary btn-sm"
                            data-testid="add-entity-btn"
                        >
                            Add entity
                        </NavLink>
                    )}
                    renderBlocked={({ openPlans }) => (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id={`blocked-unlimited-entities`}>
                                    Upgrade to add multiple entities to Kick
                                </Tooltip>
                            }
                        >
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={openPlans}
                                data-testid="add-entity-btn-upgrade"
                            >
                                <ThunderboltIcon className="mr-2 icon-color-blue-600" />{" "}
                                Add entity
                            </Button>
                        </OverlayTrigger>
                    )}
                />

                <AddAccount
                    btnText={"Add account"}
                    btnVariant={"primary"}
                    btnSize={"sm"}
                    btnClassName={"ml-2"}
                />
            </aside>
        </header>
    );
};
