import React, { useCallback, useEffect, useState } from "react";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import { NavigationProvider } from "./navigation/NavigationContext";
import { OnboardingContainer } from "./onboarding/OnboardingContainer";
import { useOnboardingStatus } from "../hooks/useOnboardingStatus";
import { TransactionsPageContainer } from "./transactions/TransactionsPageContainer";
import { Dashboard } from "./dashboard/Dashboard";
import { MainInterface } from "./mainInterface/MainInterface";
import { SimulationsDashboard } from "./simulations/SimulationsDashboard";
import { OAuthAuthorizationFlow } from "./oauth/authorization/OAuthAuthorizationFlow";
import { TransactionsContextProvider } from "./transactions/context/TransactionsContextProvider";
import { AccountsPage } from "./accounts/AccountsPage";
import { NewConnectionHandler } from "./plaid/NewConnection/NewConnectionHandler";
import { SettingsProvider } from "./settings/SettingsProvider";
import { SettingsRoute } from "./settings/SettingsRoute";
import { clearRedirectPath } from "../lib/redirectAfterLogin";
import { ReportsPage } from "./reports/ReportsPage";
import { NewEntity } from "./newEntity/NewEntity";
import { FinalizeSubscription } from "./billing/FinalizeSubscription";
import { FinancialDocumentsPage } from "./documents/FinancialDocumentsPage";
import { ReportsTargetedPage } from "./reports/ReportsTargetedPage";
import { AutopilotPage } from "./autopilot/AutopilotPage";
import { AccountingPage } from "./accounting/AccountingPage";
import { ProfitLossPreviewPage } from "./accounting/profitLoss/ProfitLossPreviewPage";
import { FinancialDocumentUploadProvider } from "./documents/FinancialDocumentUpload/FinancialDocumentUploadProvider";
import { Checkout } from "./billing/Checkout";
import { NavigationSearchProvider } from "./navigation/Search/NavigationSearchProvider";
import { AccountingTabProvider } from "./accounting/useAccountingTab";
import { FeatureAccess } from "./general/FeatureAccess/FeatureAccess";
import { PREMIUM_FEATURES } from "../common/flatRateBilling";
import { CashFlowPage } from "./reports/CashFlowPage";
import { BalanceSheetAccessPage } from "./accounting/balanceSheet/BalanceSheetAccessPage";
import { GeneralLedgerAccessPage } from "./accounting/generalLedger/GeneralLedgerAccessPage";
import { useTypedFlags } from "../hooks/useTypedFlags";
import { OnboardingV2 } from "./onboardingV2/OnboardingV2";

export const PrivateInterface: React.FC = () => {
    const [redirectChecked, setRedirectChecked] = useState(false);
    const isOnboarded = useOnboardingStatus();
    const history = useHistory();
    const location = useLocation();
    const { newOnboarding } = useTypedFlags();

    const pathCanBeRedirected = useCallback((checkedPath: string) => {
        const pathsWithoutRedirection = [
            "/onboarding",
            "/oauth",
            "/checkout",
            "/simulations",
        ];

        return !pathsWithoutRedirection.some((path) =>
            checkedPath.startsWith(path),
        );
    }, []);

    useEffect(() => {
        if (pathCanBeRedirected(location.pathname) && !isOnboarded) {
            history.push("/onboarding");
        }

        if (isOnboarded && location.pathname.startsWith("/onboarding")) {
            history.push("/");
        }

        setRedirectChecked(true);
    }, [location.pathname, isOnboarded, history, pathCanBeRedirected]);

    useEffect(clearRedirectPath, []);

    if (!redirectChecked) {
        return null;
    }

    return (
        <NavigationProvider>
            <NavigationSearchProvider>
                <SettingsProvider>
                    <Switch>
                        <Route path="/" exact>
                            <MainInterface>
                                <Dashboard />
                            </MainInterface>
                        </Route>
                        <Route path="/accounts">
                            <MainInterface>
                                <AccountsPage />
                            </MainInterface>
                        </Route>
                        {/* support old links to transactions page */}
                        <Route path="/activity/:entityId?">
                            <MainInterface>
                                <TransactionsContextProvider>
                                    <TransactionsPageContainer />
                                </TransactionsContextProvider>
                            </MainInterface>
                        </Route>

                        <Route path="/transactions/:entityId?">
                            <MainInterface>
                                <TransactionsContextProvider>
                                    <TransactionsPageContainer />
                                </TransactionsContextProvider>
                            </MainInterface>
                        </Route>

                        <Route path="/autopilot">
                            <MainInterface>
                                <AutopilotPage />
                            </MainInterface>
                        </Route>

                        <Route path="/cashflow" exact>
                            <MainInterface>
                                <CashFlowPage />
                            </MainInterface>
                        </Route>

                        <Route path="/reports" exact>
                            <MainInterface>
                                <ReportsPage />
                            </MainInterface>
                        </Route>

                        <Route path="/reports/:report/:entityId">
                            <MainInterface>
                                <ReportsTargetedPage />
                            </MainInterface>
                        </Route>

                        <Route path="/documents/:documentId(\d+)?/:view?">
                            <MainInterface>
                                <FinancialDocumentUploadProvider>
                                    <FinancialDocumentsPage />
                                </FinancialDocumentUploadProvider>
                            </MainInterface>
                        </Route>

                        <Route
                            path="/docs"
                            render={({ location: routeLocation }) => (
                                // needed to preserve the query params
                                <Redirect
                                    to={{
                                        ...routeLocation,
                                        pathname: "/documents",
                                    }}
                                />
                            )}
                        />

                        <Route
                            path="/accounting"
                            render={({ match: { url } }) => (
                                <AccountingTabProvider>
                                    <Route path={`${url}`} exact>
                                        <MainInterface>
                                            <AccountingPage />
                                        </MainInterface>
                                    </Route>

                                    <Route path={`${url}/profit-loss`}>
                                        <MainInterface>
                                            <ProfitLossPreviewPage />
                                        </MainInterface>
                                    </Route>
                                    <Route path={`${url}/balance-sheet`}>
                                        <MainInterface>
                                            <BalanceSheetAccessPage />
                                        </MainInterface>
                                    </Route>
                                    <Route path={`${url}/general-ledger`}>
                                        <MainInterface>
                                            <GeneralLedgerAccessPage />
                                        </MainInterface>
                                    </Route>
                                </AccountingTabProvider>
                            )}
                        />

                        <Route path="/accounting" exact>
                            <MainInterface>
                                <AccountingPage />
                            </MainInterface>
                        </Route>

                        <Route path="/onboarding">
                            {newOnboarding ? (
                                <OnboardingV2 />
                            ) : (
                                <OnboardingContainer showSidebar={true} />
                            )}
                        </Route>
                        <Route path="/new-entity">
                            <FeatureAccess
                                feature={PREMIUM_FEATURES.UNLIMITED_ENTITIES}
                                renderAllowed={() => <NewEntity />}
                                renderBlocked={() => (
                                    <Redirect to="/settings/plans" />
                                )}
                            />
                        </Route>
                        <Route path="/settings">
                            <SettingsRoute />
                        </Route>
                        <Route path="/simulations">
                            <SimulationsDashboard />
                        </Route>
                        <Route path="/oauth">
                            <OAuthAuthorizationFlow />
                        </Route>

                        <Route path="/finalize-subscription">
                            <FinalizeSubscription />
                        </Route>

                        <Route path="/checkout/:setupId">
                            <Checkout />
                        </Route>
                        <Redirect to="/" />
                    </Switch>
                </SettingsProvider>
            </NavigationSearchProvider>

            <NewConnectionHandler />
        </NavigationProvider>
    );
};
