import React, { useCallback } from "react";
import {
    FinancialDocumentFilters,
    FinancialDocumentFilterTabs,
} from "../../../../documents/FinancialDocumentsPageFilters/lib";
import { isEqual } from "lodash";
import { useActiveFinancialDocumentsFilters } from "../../../../documents/FinancialDocumentsPageFilters/useActiveFinancialDocumentsFilters";
import { useResetFinancialDocumentsFilter } from "../../../../documents/FinancialDocumentsPageFilters/useResetFinancialDocumentsFilter";
import { Card } from "../../../../general/Card/Card";
import styles from "../../../../documents/FinancialDocumentsPageFilters/FinancialDocumentsPageFilters.module.scss";
import { SearchFilter } from "../../../../general/SearchFilter/SearchFilter";
import { AggregatedFilters } from "../../../../general/AggregatedFilters/AggregatedFilters";
import { FinancialDocumentsFiltersOverlay } from "../../../../documents/FinancialDocumentsPageFilters/FinancialDocumentsFiltersOverlay";
import { ActiveFilters } from "../../../../general/ActiveFilters/ActiveFilters";
import { FinancialDocumentsActiveFilterButton } from "../../../../documents/FinancialDocumentsPageFilters/FinancialDocumentsActiveFilterButton";
import { AggregatedFiltersProvider } from "../../../../general/AggregatedFilters/AggregatedFiltersProvider";

interface Props {
    filters: FinancialDocumentFilters;
    onChange: (filters: FinancialDocumentFilters) => void;
}

export const MatchingViewFilters: React.FC<Props> = ({ filters, onChange }) => {
    const updateSearch = useCallback(
        (search: FinancialDocumentFilters["search"]) => {
            onChange({ search });
        },
        [onChange],
    );

    const hasActiveFilters = !isEqual(filters, {});
    const activeFilters = useActiveFinancialDocumentsFilters(filters);
    const clearActiveFilters = useResetFinancialDocumentsFilter(
        onChange,
        activeFilters,
    );

    return (
        <AggregatedFiltersProvider
            defaultTab={FinancialDocumentFilterTabs.DATE}
        >
            <Card className={styles.filters}>
                <SearchFilter filter={filters.search} onChange={updateSearch} />
                <AggregatedFilters>
                    <FinancialDocumentsFiltersOverlay
                        filters={filters}
                        onChange={onChange}
                    />
                </AggregatedFilters>

                {hasActiveFilters && (
                    <ActiveFilters
                        activeFilters={activeFilters}
                        renderVisibleFilters={(visibleFilters) =>
                            visibleFilters.map((filterId) => (
                                <FinancialDocumentsActiveFilterButton
                                    key={filterId}
                                    filterId={filterId}
                                    filters={filters}
                                    onChange={onChange}
                                />
                            ))
                        }
                        clearFilters={clearActiveFilters}
                    />
                )}
            </Card>
        </AggregatedFiltersProvider>
    );
};
