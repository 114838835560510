import React from "react";
import {
    CategoryAuditLogValue,
    EntityAuditLogValue,
    TransactionAuditLog,
    TransactionAuditLogProperty,
} from "../../../../common/types/transactionAuditLog";
import { TransactionAuditLogCategoryContent } from "./TransactionAuditLogCategoryContent";
import { TransactionAuditLogEntityContent } from "./TransactionAuditLogEntityContent";

interface Props {
    log: TransactionAuditLog;
}

export const TransactionDetailsHistoryLogContent: React.FC<Props> = ({
    log,
}) => {
    if (log.property === TransactionAuditLogProperty.CATEGORY) {
        return (
            <TransactionAuditLogCategoryContent
                log={log}
                newValue={log.newValue as CategoryAuditLogValue}
            />
        );
    } else {
        return (
            <TransactionAuditLogEntityContent
                log={log}
                newValue={log.newValue as EntityAuditLogValue}
            />
        );
    }
};
