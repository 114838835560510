import { useMemo } from "react";
import { User } from "../../../common/types/user";
import {
    differenceInCalendarDays,
    format,
    isSameDay,
    isSameYear,
} from "date-fns";
import {
    fullDateFormat,
    shortDateFormatter,
} from "../../../common/helpers/date";

export function useMemberLastActivity(user: User) {
    return useMemo(() => {
        const activity = new Date(user.lastActivity);
        const now = new Date();

        if (isSameDay(activity, now)) {
            return "Today";
        } else if (differenceInCalendarDays(now, activity) === 1) {
            return "Yesterday";
        } else if (isSameYear(activity, now)) {
            return shortDateFormatter.format(activity);
        } else {
            return format(activity, fullDateFormat);
        }
    }, [user.lastActivity]);
}
