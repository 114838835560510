import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    BooleanParam,
    DateParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from "use-query-params";
import { useEntitiesWithAccountingAvailable } from "./useEntitiesWithAccountingAvailable";
import { AccountingReportFilters } from "./types";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { useUserCache } from "../../hooks/useUserCache";
import { Entity } from "../../common/types/entity";
import { JournalEntryModal } from "./journalEntryModal/JournalEntryModal";

interface FiltersWithNulls {
    startDate?: Date | null;
    endDate?: Date | null;
    entityId?: number | null;
    openJournalEntryModal?: boolean | null;
    openedJournalEntryId?: string | null;
}

const LAST_ENTITY_CACHE_KEY = "accounting-page-last-entity";
const LAST_DATE_PERIOD_CACHE_KEY = "accounting-page-last-date-period";

export type AccountingFiltersSetter = (
    setter: (current: AccountingReportFilters) => AccountingReportFilters,
) => void;

interface DatePeriod {
    startDate: Date | undefined;
    endDate: Date | undefined;
}

function useAccountingTab() {
    const [filtersQuery, setFiltersQuery] = useQueryParams({
        startDate: DateParam,
        endDate: DateParam,
        entityId: NumberParam,
        openJournalEntryModal: BooleanParam,
        openedJournalEntryId: StringParam,
    });

    const { activeWorkspaceKey, activeWorkspace } = useWorkspaceContext();
    const [cachedEntityId, setCachedEntityId] = useUserCache<
        number | undefined
    >(`${activeWorkspaceKey}/${LAST_ENTITY_CACHE_KEY}`);

    const availableEntities = useEntitiesWithAccountingAvailable();

    const [cachedDatePeriod, setCachedDatePeriod] = useUserCache<
        DatePeriod | undefined
    >(`${activeWorkspaceKey}/${LAST_DATE_PERIOD_CACHE_KEY}`);

    const parsedCachedDatePeriod = useMemo(() => {
        if (cachedDatePeriod) {
            return {
                startDate: new Date(cachedDatePeriod.startDate as any),
                endDate: new Date(cachedDatePeriod.endDate as any),
            };
        }
        return undefined;
    }, [cachedDatePeriod]);

    // Note: separate date period from last entity as we believe
    // that in future date period will be different
    const [lastDatePeriod, setLastDatePeriod] = useState<
        DatePeriod | undefined
    >(parsedCachedDatePeriod);

    const [lastEntityId, setLastEntityId] = useState<number | undefined>(
        cachedEntityId,
    );

    const setFilters = useCallback(
        (
            setter: (
                current: AccountingReportFilters,
            ) => AccountingReportFilters,
        ) => {
            setFiltersQuery((current: FiltersWithNulls) => {
                const newFilters = setter({
                    startDate: current.startDate ?? undefined,
                    endDate: current.endDate ?? undefined,
                    entityId: current.entityId ?? undefined,
                    openJournalEntryModal:
                        current.openJournalEntryModal ?? undefined,
                    openedJournalEntryId:
                        current.openedJournalEntryId ?? undefined,
                });

                if (newFilters.startDate && newFilters.endDate) {
                    setCachedDatePeriod({
                        startDate: newFilters.startDate.toISOString(),
                        endDate: newFilters.endDate.toISOString(),
                    } as any);
                    setLastDatePeriod({
                        startDate: newFilters.startDate,
                        endDate: newFilters.endDate,
                    });
                }
                if (newFilters.entityId) {
                    setCachedEntityId(newFilters.entityId);
                    setLastEntityId(newFilters.entityId);
                }
                return newFilters;
            });
        },
        [
            setFiltersQuery,
            setCachedEntityId,
            setLastEntityId,
            setCachedDatePeriod,
            setLastDatePeriod,
        ],
    );

    const cleanFilters = useMemo(
        () => ({
            startDate:
                filtersQuery.startDate ??
                lastDatePeriod?.startDate ??
                undefined,
            endDate:
                filtersQuery.endDate ?? lastDatePeriod?.endDate ?? undefined,
            entityId: filtersQuery.entityId ?? lastEntityId ?? undefined,
            openJournalEntryModal:
                filtersQuery.openJournalEntryModal ?? undefined,
            openedJournalEntryId:
                filtersQuery.openedJournalEntryId ?? undefined,
        }),
        [filtersQuery, lastDatePeriod, lastEntityId],
    );

    const selectedEntity = useMemo(
        () => availableEntities.find((e) => e.id === cleanFilters.entityId),
        [availableEntities, cleanFilters.entityId],
    );

    useEffect(() => {
        if (
            !selectedEntity &&
            availableEntities.length > 0 &&
            activeWorkspace?.id &&
            availableEntities[0].workspaceId === activeWorkspace?.id
        ) {
            const cachedEntity = availableEntities.find(
                (e) => e.id === cachedEntityId,
            );
            setFiltersQuery({
                entityId: cachedEntity
                    ? cachedEntity.id
                    : availableEntities[0].id,
                startDate:
                    parsedCachedDatePeriod?.startDate ?? filtersQuery.startDate,
                endDate:
                    parsedCachedDatePeriod?.endDate ?? filtersQuery.endDate,
            });
        }
    }, [
        setCachedEntityId,
        filtersQuery.entityId,
        selectedEntity,
        availableEntities,
        setFiltersQuery,
        cachedEntityId,
        activeWorkspace,
        parsedCachedDatePeriod,
        filtersQuery.startDate,
        filtersQuery.endDate,
    ]);

    const setShowCreateJournalEntryModal = useCallback(
        (value: boolean) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                openJournalEntryModal: value,
                openedJournalEntryId: undefined,
            }));
        },
        [setFilters],
    );

    return {
        filters: cleanFilters,
        setFilters: setFilters,
        setShowCreateJournalEntryModal,
        selectedEntity,
        openJournalEntryModal: filtersQuery.openJournalEntryModal ?? undefined,
        openedJournalEntryId: filtersQuery.openedJournalEntryId ?? undefined,
    };
}

interface AccountingTabContextType {
    filters: AccountingReportFilters;
    setFilters: AccountingFiltersSetter;
    selectedEntity: Entity | undefined;
    openJournalEntryModal: boolean | undefined;
    openedJournalEntryId: string | undefined;
    setShowCreateJournalEntryModal: (value: boolean) => void;
}

export const AccountingTabContext = createContext<AccountingTabContextType>({
    filters: {},
    setFilters: () => {},
    selectedEntity: undefined,
    openJournalEntryModal: undefined,
    openedJournalEntryId: undefined,
    setShowCreateJournalEntryModal: () => {},
});

export const useAccountingTabContext = () => {
    return useContext(AccountingTabContext);
};

export const AccountingTabProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const {
        filters,
        setFilters,
        selectedEntity,
        openJournalEntryModal,
        openedJournalEntryId,
        setShowCreateJournalEntryModal,
    } = useAccountingTab();
    return (
        <AccountingTabContext.Provider
            value={{
                filters,
                setFilters,
                selectedEntity,
                openJournalEntryModal,
                openedJournalEntryId,
                setShowCreateJournalEntryModal,
            }}
        >
            {filters.entityId && (
                <JournalEntryModal
                    journalEntryId={filters.openedJournalEntryId}
                    show={!!filters.openJournalEntryModal}
                    onHide={() => setShowCreateJournalEntryModal(false)}
                    entityId={filters.entityId}
                />
            )}
            {children}
        </AccountingTabContext.Provider>
    );
};
