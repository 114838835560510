import React from "react";
import { TransactionActivityRow } from "../../../common/dto/transactions/get-transactions-activity-response.dto";
import { AutopilotActivityRow } from "./AutopilotActivityRow";
import styles from "./AutopilotActivitySection.module.scss";

interface Props {
    title: string;
    rows: TransactionActivityRow[];
}

export const AutopilotActivitySection: React.FC<Props> = ({ title, rows }) => {
    return (
        <section className={styles.section}>
            <h6 className={styles.title}>{title}</h6>
            <main>
                {rows.map((row) => (
                    <AutopilotActivityRow
                        row={row}
                        key={row.date}
                        className={styles.row}
                    />
                ))}
            </main>
        </section>
    );
};
