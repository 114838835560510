import classNames from "classnames";
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReportFilters } from "../../../common/types/filters/reports";
import { ThunderboltIcon } from "../../../icons";
import { formatColHeaderLabel, validDateLabel } from "./cellLabels.helper";
import { useIsWithinBounds } from "./useIsWithinBounds";

export interface Props {
    label: string;
    isTitle?: boolean;
    filters: ReportFilters;
    stickyRight?: number;
}

export const CashFlowReportHeaderCell: React.FC<Props> = ({
    label,
    isTitle = false,
    filters,
    stickyRight,
}) => {
    const isWithinBounds = useIsWithinBounds(label, isTitle);

    const shortLabel = useMemo(
        () => formatColHeaderLabel(filters, label),
        [label, filters],
    );
    const fullLabel = useMemo(
        () => formatColHeaderLabel(filters, label, true),
        [label, filters],
    );

    const prefix = useMemo(
        () =>
            isWithinBounds ? null : (
                <ThunderboltIcon className="mr-1 icon-color-blue-500" />
            ),
        [isWithinBounds],
    );

    const validLabel = useMemo(() => validDateLabel(label), [label]);

    const cell = (
        <span className="data-table__cover-cell">
            {prefix}
            {shortLabel}
        </span>
    );

    return (
        <th
            className={classNames("data-table__header-cell", {
                "data-table__header-cell--title": isTitle,
            })}
            style={{ ...(stickyRight && { right: stickyRight }) }}
        >
            {prefix}
            {shortLabel}
            {validLabel ? (
                <OverlayTrigger
                    overlay={
                        <Tooltip
                            id={`data-table__cover-cell__tooltip-${label}`}
                        >
                            {fullLabel}
                        </Tooltip>
                    }
                >
                    {cell}
                </OverlayTrigger>
            ) : (
                cell
            )}
        </th>
    );
};
