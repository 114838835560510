import classNames from "classnames";
import React, { MouseEvent, useCallback } from "react";
import { Button } from "react-bootstrap";
import { GenericCategory } from "../../../../../common/types/genericCategory";
import { TriangleIcon } from "../../../../../icons";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { TreeNode, WithId } from "../../../../general/Tree/Tree.types";
import { useTreeContext } from "../../../../general/Tree/useTreeContext";
import { CategoryIcon } from "../../../CategoryIcon";
import css from "../FormCheckItem.module.scss";

interface Props<T extends WithId> {
    node: TreeNode<T>;
    isChecked(category: T): boolean;
    handleChange(category: T): void;
}
export const CategoryTreeItem: React.FC<Props<GenericCategory>> = ({
    node,
    isChecked,
    handleChange,
}) => {
    const category = node.current;
    const onChange = useCallback(
        () => handleChange(category),
        [category, handleChange],
    );

    const { isExpanded, expandNode, collapseNode } = useTreeContext();
    const handleToggle = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            isExpanded(node.nodeId)
                ? collapseNode(node.nodeId)
                : expandNode(node.nodeId);
        },
        [collapseNode, expandNode, isExpanded, node.nodeId],
    );

    return (
        <FormCheckbox
            small
            key={category.id}
            value={
                category.type === "customCategory"
                    ? category.id.toString()
                    : category.id
            }
            isChecked={isChecked(category)}
            handleChange={onChange}
            className={classNames(css.item, {
                [css.rootItem]: node.depth === 0,
                [css.expandedItem]: isExpanded(node.nodeId),
            })}
            label={
                <div className={css.itemLabel}>
                    {node.depth === 0 && category.type === "category" && (
                        <CategoryIcon category={category} />
                    )}
                    <span className="ml-2" data-testid="category-item">
                        {category.label}
                    </span>
                    {node.children.length > 0 && (
                        <Button
                            variant="link"
                            onClick={handleToggle}
                            className="ml-1"
                        >
                            <TriangleIcon />
                        </Button>
                    )}
                </div>
            }
        />
    );
};
