import {
    Transaction,
    TransactionClassification,
} from "../../common/types/transaction";
import React from "react";
import { BusinessIcon, HomeIcon } from "../../icons";
import { Taxonomy } from "../../common/categories";

export interface ActivityPageParams {
    entityId?: string;
}

export const transactionClassifications: TransactionClassification[] =
    Object.values(TransactionClassification);

export const transactionClassificationIcons: Record<
    TransactionClassification,
    React.ReactNode
> = {
    [TransactionClassification.BUSINESS]: <BusinessIcon />,
    [TransactionClassification.PERSONAL]: <HomeIcon />,
};

export function isUncategorized(transaction: Transaction) {
    return transaction.category === Taxonomy.uncategorized;
}
