import React, { useMemo } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableListItem } from "./SortableListItem";
import "./SortableList.scss";

interface Props {
    items: any[];
    getItemId: (item: any) => UniqueIdentifier;
    renderItem: (item: any) => React.ReactNode;
    listId: string;
}

export const SortableList: React.FC<Props> = ({
    items,
    getItemId,
    renderItem,
    listId,
}) => {
    const ids = useMemo(() => items.map(getItemId), [items, getItemId]);

    return (
        <SortableContext
            id={listId}
            items={ids}
            strategy={verticalListSortingStrategy}
        >
            {items.map((item) => (
                <SortableListItem id={getItemId(item)} key={getItemId(item)}>
                    {renderItem(item)}
                </SortableListItem>
            ))}
        </SortableContext>
    );
};
