import React, { useMemo } from "react";
import {
    UserEntityAssignment,
    UserRole,
} from "../../../common/types/userEntityAssignment";
import { Entity } from "../../../common/types/entity";
import { EntityTeamMember } from "./EntityTeamMember";
import "./EntityTeam.scss";
import {
    EntitySettingsPages,
    useSettings,
} from "../../settings/SettingsProvider";
import { getEntityPaneKey } from "../../settings/lib";
import { useInteraction } from "../../../hooks/useInteraction";

interface Props {
    entity: Entity;
    assignments?: UserEntityAssignment[];
}

const order = [
    UserRole.PRIMARY_ADMIN,
    UserRole.ADMIN,
    UserRole.ACCOUNTANT,
    UserRole.MEMBER,
];
const MAX_ASSIGNMENTS = 7;

export const EntityTeam: React.FC<Props> = ({ entity, assignments }) => {
    const sortedAssignments = useMemo(
        () =>
            order.flatMap((role) =>
                (assignments ?? []).filter((a) => a.role === role),
            ),
        [assignments],
    );
    const { open } = useSettings();
    const openTeamSettings = useInteraction(
        () =>
            !entity.isMockEntity &&
            open([getEntityPaneKey(EntitySettingsPages.MEMBERS, entity)]),
    );

    const displayedAssignments = sortedAssignments.slice(0, MAX_ASSIGNMENTS);

    return (
        <section
            className="entity-team"
            data-testid="entity-team"
            {...openTeamSettings}
        >
            {displayedAssignments.map((assignment) => (
                <EntityTeamMember
                    user={assignment.user}
                    role={assignment.role}
                    key={assignment.user.id}
                />
            ))}
            {sortedAssignments.length > MAX_ASSIGNMENTS && (
                <div className="entity-team__overflow">
                    +{sortedAssignments.length - MAX_ASSIGNMENTS}
                </div>
            )}
        </section>
    );
};
