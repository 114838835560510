import React from "react";
import { useParams } from "react-router-dom";
import { useEntities } from "../../hooks/useEntities";
import { isValidReport } from "../../helpers/reports";
import { ReportsPage } from "./ReportsPage";

interface Params {
    report: string;
    entityId: string;
}

export const ReportsTargetedPage: React.FC = () => {
    const { report, entityId } = useParams<Params>();
    const entities = useEntities();
    const entity = entities.find((e) => e.id === parseInt(entityId));
    const reportType = isValidReport(report) ? report : undefined;

    return <ReportsPage entity={entity} report={reportType} />;
};
