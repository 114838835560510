import React from "react";
import { HowItWorks } from "./HowItWorks";
import { WhatsIncluded } from "./WhatsIncluded";
import { CustomPricingCall } from "./CustomPricingCall";

export const BillingPageCustomPricing: React.FC = () => {
    return (
        <>
            <HowItWorks />
            <CustomPricingCall />
            <WhatsIncluded />
        </>
    );
};
