import React from "react";
import { Field } from "formik";
import { STATES_LIST } from "../../common/states";
import { FormRow, FormRowProps } from "./FormRow";
import { getInputClasses } from "../../helpers/form";

interface Props extends Omit<FormRowProps, "fieldName"> {
    customFieldName?: string;
}

export const StateSelector: React.FC<Props> = ({
    customFieldName,
    label = "State",
    size,
    ...formRowProps
}) => {
    const classes = getInputClasses(size);
    const fieldName = customFieldName ?? "state";

    return (
        <FormRow fieldName={fieldName} label={label} {...formRowProps}>
            <Field name={fieldName} as="select" className={classes}>
                <option value="">Select state...</option>
                {STATES_LIST.map((s) => (
                    <option key={s.abbreviation} value={s.abbreviation}>
                        {s.name}
                    </option>
                ))}
            </Field>
        </FormRow>
    );
};
