import React, { MouseEvent, useCallback, useState } from "react";
import { CloseIcon } from "../../../icons";
import classNames from "classnames";
import { useInteraction } from "../../../hooks/useInteraction";
import styles from "./ActiveFilterButton.module.scss";

interface ActiveFilterButtonProps {
    resetFilter: () => void;
    openFilter: () => void;
    label: React.ReactNode;
}

export const ActiveFilterButton: React.FC<ActiveFilterButtonProps> = ({
    openFilter,
    resetFilter,
    label,
}) => {
    const [hoverClear, setHoverClear] = useState(false);
    const handleMouseEnter = useCallback(() => setHoverClear(true), []);
    const handleMouseLeave = useCallback(() => setHoverClear(false), []);

    const handleClear = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            resetFilter();
        },
        [resetFilter],
    );

    const mouseEnter = useInteraction(handleMouseEnter, "onMouseEnter");
    const mouseLeave = useInteraction(handleMouseLeave, "onMouseLeave");
    const click = useInteraction(handleClear);

    return (
        <button
            data-testid="active-filter-button"
            className={classNames("btn", "btn-sm", styles.button, {
                [styles.clearHovered]: hoverClear,
            })}
            onClick={openFilter}
        >
            {label}

            <span
                {...click}
                {...mouseEnter}
                {...mouseLeave}
                className={styles.clear}
                data-testid="clear-filter-button"
            >
                <CloseIcon />
            </span>
        </button>
    );
};
