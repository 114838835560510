import {
    ConnectionProviderType,
    FinancialConnectionWithAccounts,
} from "../../types/financialConnection";
import { PlaidConnection } from "../../types/plaidConnection";
import { FinancialConnectionDto } from "../financialAccount/financial-connection.dto";
import { PlaidConnectionDto } from "../plaidConnection/plaid-connection.dto";

export class UserFinancialConnectionDto {
    connection: FinancialConnectionDto;
    numberOfNotVisibleAccounts: number;

    constructor(
        connection: FinancialConnectionWithAccounts,
        numberOfNotVisibleAccounts: number,
    ) {
        if (connection.connectionProvider === ConnectionProviderType.PLAID) {
            this.connection = new PlaidConnectionDto(
                connection as PlaidConnection,
            );
        } else {
            this.connection = new FinancialConnectionDto(connection);
        }
        this.numberOfNotVisibleAccounts = numberOfNotVisibleAccounts;
    }

    static deserialize(dto: UserFinancialConnectionDto) {
        return {
            connection:
                dto.connection.connectionProvider ===
                ConnectionProviderType.PLAID
                    ? PlaidConnectionDto.deserialize(
                          dto.connection as PlaidConnectionDto,
                      )
                    : FinancialConnectionDto.deserialize(dto.connection),
            numberOfNotVisibleAccounts: dto.numberOfNotVisibleAccounts,
        };
    }
}
