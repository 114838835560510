import { FinancialDocument } from "../../../common/types/financialDocument";
import { createContext } from "react";
import { noop } from "../../../helpers/general";
import { FinancialDocumentFilters } from "../FinancialDocumentsPageFilters/lib";

export interface FinancialDocumentBulkSelectionContextValue {
    selectedCount: number;
    selected: FinancialDocument[];
    totalCount: number;
    hasSelectedByCurrentFilters: boolean;
    filtersSelection: FinancialDocumentFilters | null;
    select(
        financialDocumentOrDocuments: FinancialDocument | FinancialDocument[],
    ): void;
    deselect(
        financialDocumentOrDocuments: FinancialDocument | FinancialDocument[],
    ): void;
    isSelected(financialDocument: FinancialDocument): boolean;
    clearSelection(): void;
    selectAll(): void;
}

export const financialDocumentBulkSelectionContext =
    createContext<FinancialDocumentBulkSelectionContextValue>({
        selected: [],
        selectedCount: 0,
        totalCount: 0,
        hasSelectedByCurrentFilters: false,
        select: noop,
        deselect: noop,
        isSelected: () => false,
        selectAll: noop,
        clearSelection: noop,
        filtersSelection: null,
    });
