import React, { useState } from "react";
import { Transaction } from "../../common/types/transaction";
import classNames from "classnames";
import "./TransactionAction.scss";
import { CheckIcon } from "../../icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAmountSaved } from "../../helpers/transaction";
import { pluralize } from "../../common/helpers/string";
import { Amount } from "../general/Amount/Amount";
import { TransactionActionsRequiredNumber } from "./TransactionActionsRequiredNumber";

interface Props {
    transaction: Transaction;
}

export const TransactionAction: React.FC<Props> = ({ transaction }) => {
    const amountSaved = getAmountSaved(transaction);

    const hasSavings = Boolean(amountSaved);
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipEnabled = Boolean(
        hasSavings || transaction.requiredActions.length,
    );

    const getStatusIcon = () => {
        if (transaction.requiredActions.length) {
            return (
                <TransactionActionsRequiredNumber
                    value={transaction.requiredActions.length}
                />
            );
        } else if (amountSaved) {
            return <CheckIcon className="icon-color-blue-600" />;
        } else {
            return "•";
        }
    };

    return (
        <OverlayTrigger
            placement="top"
            onToggle={setShowTooltip}
            show={showTooltip && tooltipEnabled}
            overlay={
                <Tooltip id={`savings-${transaction.id}`}>
                    <>
                        {transaction.requiredActions.length > 0 ? (
                            `${transaction.requiredActions.length} ${pluralize(
                                "action",
                                transaction.requiredActions.length,
                            )} pending`
                        ) : (
                            <>
                                {amountSaved && (
                                    <div className="transaction-action__savings">
                                        <span>Tax savings</span>
                                        <span>
                                            <Amount
                                                amount={amountSaved}
                                                showPositive
                                            />
                                        </span>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                </Tooltip>
            }
        >
            <span
                className={classNames(
                    `transaction-action transaction-action--${transaction.status}`,
                    {
                        "transaction-action--has-savings": hasSavings,
                    },
                )}
                data-testid={`transaction-action${hasSavings ? "-has-savings" : ""}`}
            >
                {getStatusIcon()}
            </span>
        </OverlayTrigger>
    );
};
