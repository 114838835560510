import { queryClient } from "../../../queryClient";
import { useQuery } from "react-query";
import { getTransactionDetails } from "../../../lib/transactions";
import { TransactionDetailsResponseDto } from "../../../common/dto/transactions/transaction-details-response.dto";
import { useMemo } from "react";
import { Transaction } from "../../../common/types/transaction";

const TRANSACTION_DETAILS_QUERY_KEY = "transactionDetails";

export function updateTransactionsDetailsQueryData(
    transactionId: number,
    transactionUpdate: Partial<Transaction>,
) {
    queryClient.setQueriesData(
        {
            queryKey: [TRANSACTION_DETAILS_QUERY_KEY, transactionId],
        },
        ((oldData: TransactionDetailsResponseDto) => {
            return {
                ...oldData,
                transaction: {
                    ...oldData.transaction,
                    ...transactionUpdate,
                },
            };
        }) as any,
    );
}

export function useTransactionDetailsQuery(
    id: number | null,
    onSuccess?: (data: TransactionDetailsResponseDto) => void,
) {
    const { data } = useQuery(
        [TRANSACTION_DETAILS_QUERY_KEY, id],
        () => (id ? getTransactionDetails(id) : null),
        {
            onSuccess,
        },
    );

    return useMemo(
        () =>
            data ?? {
                transaction: undefined,
                receiptRequired: false,
                memoRequired: false,
            },
        [data],
    );
}

export function invalidateTransactionDetailsQueries(
    transactionId?: number,
): Promise<void> {
    return queryClient.invalidateQueries({
        queryKey: transactionId
            ? [TRANSACTION_DETAILS_QUERY_KEY, transactionId]
            : TRANSACTION_DETAILS_QUERY_KEY,
    });
}
