import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlags } from "../lib/launchdarkly";
import { camelCase } from "lodash";
import { useMemo } from "react";

const camelizeKeys = (obj: any): FeatureFlags =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [camelCase(key)]: obj[key] },
        }),
        {},
    ) as FeatureFlags;

export function useTypedFlags() {
    const useflags = useFlags<FeatureFlags>();
    const flags = import.meta.env.VITE_LAUNCHDARKLY_FLAGS;

    const flagConfig = useMemo(() => {
        if (flags !== undefined) {
            return JSON.parse(flags);
        }
        return { flagValues: {} };
    }, [flags]);

    if (flags === undefined) {
        return useflags;
    }

    return camelizeKeys(flagConfig.flagValues);
}
