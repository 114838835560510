import React, { useEffect } from "react";
import styles from "./Outro.module.scss";
import { PageCoverLoader } from "../../general/PageCoverLoader/PageCoverLoader";
import { useTimer } from "../../../hooks/useTimer";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
} from "../../../common/flatRateBilling";
import { SubscriptionType } from "../../../common/types/billing";

interface Props {
    onFinished(): void;
}
export const Outro: React.FC<Props> = ({ onFinished }) => {
    const { start } = useTimer(onFinished, 3_000);
    const { isFetched, currentPlan, subscriptionType, bookedEnterpriseCall } =
        useBillingStatus();

    useEffect(() => {
        if (isFetched) {
            start();
        }
    }, [isFetched, start]);

    const currentPlanName = flatRatePlanNames[currentPlan];
    let headline: React.ReactNode;

    if (
        subscriptionType !== SubscriptionType.FLAT_RATE ||
        currentPlan === FLAT_RATE_PLANS.FREE
    ) {
        headline = "Get ready to kick back..";
    } else if (bookedEnterpriseCall) {
        headline = (
            <>
                Try <em>{currentPlanName}</em> Plan free for 30 days while we
                finish setting up your account
            </>
        );
    } else {
        headline = (
            <>
                and starting your 30 day <em>{currentPlanName}</em> Trial
            </>
        );
    }

    return (
        <PageCoverLoader backdrop className={styles.cover} logo={false}>
            <div className={styles.outro}>
                <h1 className="onboarding__title">Creating your dashboard</h1>
                <p className="onboarding__headline">{headline}</p>
            </div>
        </PageCoverLoader>
    );
};
