import React, { useCallback, useEffect } from "react";
import styles from "./AutopilotCard.module.scss";
import colors from "@styles/colors.module.scss";
import { animated, useSpring } from "@react-spring/web";
import Color from "color";
import { AnimatedCard } from "../general/Card/AnimatedCard";

interface Props {
    ctas?: React.ReactNode;
    opened?: boolean;
    isCompleted?: boolean;
    onClick?(): void;
    onCardClosing?(): void;
    onCardClosed?(): void;
    firstSection(style: any, inOpened?: boolean): React.ReactNode;
    otherSections?(inOpened?: boolean): React.ReactNode;
}

const commonCardStyles = {
    display: "grid",
    gridTemplateRows: "1fr",
    paddingBlock: styles.spacing,
    marginBlock: styles.spacing,
};

const noBoxShadow = `0px 0px 0px 0px transparent`;
const closedCard = {
    boxShadow: noBoxShadow,
    borderColor: colors.miscAlpha300,
    ...commonCardStyles,
};

const blue600 = Color(colors.blue600);
const openedCard = {
    boxShadow: `0px 0px 30px 0px ${blue600.alpha(0.15).toString()}`,
    borderColor: colors.blue300,
    ...commonCardStyles,
};

const removedCard = {
    ...openedCard,
    gridTemplateRows: "0fr",
    paddingBlock: "0rem",
    marginBlock: "0rem",
    boxShadow: noBoxShadow,
    borderColor: colors.white,
};

const sectionsCommonStyles = {
    display: "grid",
};
const closedSections = {
    gridTemplateRows: "0fr",
    ...sectionsCommonStyles,
};
const openedSections = {
    gridTemplateRows: "1fr",
    ...sectionsCommonStyles,
};

const firstSectionInOpened = {
    paddingBottom: "40px",
};
const firstSectionInClosed = {
    paddingBottom: "0px",
};

const CARD_CLOSE_DELAY = 2000 as const;

export const AutopilotCard: React.FC<Props> = ({
    ctas,
    opened,
    isCompleted,
    onClick,
    onCardClosing,
    onCardClosed,
    firstSection,
    otherSections,
}) => {
    const [cardSpring, cardApi] = useSpring(() => ({
        from: closedCard,
    }));
    const [sectionsSpring, sectionsApi] = useSpring(() => ({
        from: closedSections,
    }));
    const [firstSectionSpring, firstSectionApi] = useSpring(() => ({
        from: firstSectionInClosed,
    }));

    useEffect(() => {
        if (!isCompleted) {
            cardApi.start(opened ? openedCard : closedCard);
            sectionsApi.start(opened ? openedSections : closedSections);
            firstSectionApi.start(
                opened ? firstSectionInOpened : firstSectionInClosed,
            );
        }
    }, [opened, isCompleted, cardApi, sectionsApi, firstSectionApi]);

    const closeCard = useCallback(() => {
        onCardClosing?.();
        cardApi.start(removedCard)[0].then(() => {
            onCardClosed?.();
        });
    }, [cardApi, onCardClosing, onCardClosed]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (isCompleted) {
            sectionsApi.start(closedSections);

            timeout = setTimeout(() => {
                closeCard();
            }, CARD_CLOSE_DELAY);
        }

        return () => timeout && clearTimeout(timeout);
    }, [isCompleted, sectionsApi, closeCard]);

    return (
        <AnimatedCard
            className={styles.card}
            onClick={onClick}
            style={cardSpring}
        >
            {ctas && opened && !isCompleted && (
                <aside className={styles.ctas}>{ctas}</aside>
            )}
            {firstSection(firstSectionSpring, opened)}
            {otherSections && (
                <animated.div style={sectionsSpring}>
                    <div className={styles.otherSections}>
                        {otherSections(opened)}
                    </div>
                </animated.div>
            )}
        </AnimatedCard>
    );
};
