import React from "react";
import { Entity } from "../../common/types/entity";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Tag } from "../general/Tag/Tag";
import { ReportType } from "../../helpers/reports";
import { useTypedFlags } from "../../hooks/useTypedFlags";

interface Props {
    entity: Entity;
}

export const EntitySideNavMenu: React.FC<Props> = ({ entity }) => {
    const { accountingTab } = useTypedFlags();

    return (
        <>
            <NavLink
                to={`/reports/${ReportType.PROFIT_LOSS}/${entity.id}`}
                activeClassName="active"
                data-testid={`entity-side-nav-${accountingTab ? "cash-activity" : "profit-loss"}`}
            >
                {accountingTab ? "Cash Activity" : "Profit & Loss"}
            </NavLink>
            <Nav.Link disabled>
                Revenue
                <Tag variant="secondary" className="ml-2" size="sm">
                    Soon
                </Tag>
            </Nav.Link>
        </>
    );
};
