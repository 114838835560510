import React from "react";
import { LockIcon } from "../../../icons";
import { Alert } from "react-bootstrap";
import PlaidLogo from "../../../static/images/logos/plaid.svg?react";
import styles from "./PlaidAlert.module.scss";

export const PlaidAlert: React.FC = () => {
    return (
        <Alert variant="primary" className={styles.alert}>
            <LockIcon className="icon-color-current mr-1" /> Kick does not store
            your passwords and account information. Connection is secured by{" "}
            <PlaidLogo
                className="icon-color-current"
                style={{ height: 16, width: 42 }}
            />
        </Alert>
    );
};
