import React from "react";
import { PREMIUM_FEATURES } from "../../../common/flatRateBilling";
import { GENERAL_LEDGER_PREVIEW_URL } from "../../../constants";
import { GeneralLedgerPage } from "./GeneralLedgerPage";
import { ReportAccess } from "../ReportAccess";

export const GeneralLedgerAccessPage: React.FC = () => {
    return (
        <ReportAccess
            feature={PREMIUM_FEATURES.GENERAL_LEDGER}
            report="General Ledger"
            previewUrl={GENERAL_LEDGER_PREVIEW_URL}
            renderReportPage={() => <GeneralLedgerPage />}
        />
    );
};
