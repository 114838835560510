import React from "react";
import { OnboardingStep, OnboardingStepKey } from "../../usePrimaryFlowSteps";
import { ProgressList } from "../../../general/ProgressList";
import styles from "./PrimaryFlowNavigation.module.scss";
import { useLogout } from "../../../../hooks/useLogout";
import { Button } from "react-bootstrap";
import { useUser } from "../../../../hooks/useUser";

interface Props {
    currentStep: OnboardingStepKey;
    steps: OnboardingStep[];
}

export const PrimaryFlowNavigation: React.FC<Props> = ({
    steps,
    currentStep,
}) => {
    const user = useUser();
    const logout = useLogout();
    const currentStepIndex =
        steps.findIndex((s) =>
            (s.steps as ReadonlyArray<OnboardingStepKey>).includes(currentStep),
        ) + 1;

    return (
        <>
            <ProgressList
                items={steps.length}
                renderStep={(idx) => steps[idx].label}
                currentStep={currentStepIndex}
                className={styles.navigation}
            />

            {logout && user && (
                <Button
                    variant="secondary"
                    size="sm"
                    block
                    className={styles.logout}
                    onClick={logout}
                >
                    Log out
                </Button>
            )}
        </>
    );
};
