import { PHONE_NUMBER_PREFIX } from "../constants";

export function isEmail(phoneNumberOrEmail: string): boolean {
    return phoneNumberOrEmail.includes("@");
}
export function anonymousEmail(email: string): string {
    return email.replace(
        /^\w((?:\w|.)+)@(?:\w|\.)+$/i,
        (sub, group: string) => {
            const secret = group.replace(/./g, "*");
            return sub.replace(group, secret);
        },
    );
}
export function anonymousPhone(phone: string): string {
    return phone.substring(phone.length - 3).padStart(phone.length, "•");
}

export function displayPhone(phone: string): string {
    return phone.substring(PHONE_NUMBER_PREFIX.length);
}
