import { isEqual, startOfDay, startOfYear } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { Alert } from "react-bootstrap";
import { ProfitLossReportDto } from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { Category } from "../../common/types/category";
import { ReportFilters } from "../../common/types/filters/reports";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import { useIsCategorizationInProgress } from "../../hooks/useIsCategorizationInProgress";
import { CallToActionBanner } from "../general/CallToActionBanner/CallToActionBanner";
import { EmptyState } from "../general/EmptyState/EmptyState";
import { Loader } from "../general/Loader";
import { useCashFlowContext } from "./cash-flow.context";
import { CashFlowReportChart } from "./CashFlowReportChart";
import { CashFlowReportTableContainer } from "./CashFlowReportTable/CashFlowReportTableContainer";
import { CategorizationInProgressNotification } from "./CategorizationInProgressNotification";
import { TransactionsDrillDownContextProvider } from "./TransactionsDrillDownContextProvider";
import { TransactionsDrillDownModal } from "./TransactionsDrillDownModal";

export interface CashFlowReportProps {
    resetFilters: () => void;
    onChange(filters: Partial<ReportFilters>): void;
}

export const CashFlowReport: React.FC<CashFlowReportProps> = ({
    resetFilters,
    onChange,
}) => {
    const {
        cashFlowReport,
        isCashFlowReportLoading,
        cashFlowFiltersUsed,
        reportCreatedAt,
    } = useCashFlowContext();
    const { showNotSubscribedCta, showLimitReachedCta, bookkeepingStartDate } =
        useBillingManagement();

    const showCta = useMemo(() => {
        return (
            showLimitReachedCta ||
            (showNotSubscribedCta &&
                !isEqual(
                    startOfYear(bookkeepingStartDate),
                    startOfDay(bookkeepingStartDate),
                ))
        );
    }, [bookkeepingStartDate, showLimitReachedCta, showNotSubscribedCta]);

    const isCategorizationInProgress = useIsCategorizationInProgress();

    const setCategoryFilter = useCallback<
        (categories: Array<Category>) => void
    >((category) => onChange({ category }), [onChange]);

    if (isCashFlowReportLoading) {
        return <Loader />;
    }

    return cashFlowReport?.chart.series.length &&
        cashFlowFiltersUsed &&
        reportCreatedAt ? (
        <>
            {showCta && (
                <CallToActionBanner>
                    {(ctaBtn) => (
                        <>
                            {isCategorizationInProgress && (
                                <CategorizationInProgressNotification />
                            )}{" "}
                            {ctaBtn} to get access to your annual Profit & Loss.
                        </>
                    )}
                </CallToActionBanner>
            )}
            {!showCta && isCategorizationInProgress && (
                <Alert
                    variant="primary"
                    className="reports__categorization-prompt"
                >
                    <CategorizationInProgressNotification />
                </Alert>
            )}
            <CashFlowReportChart
                report={cashFlowReport as unknown as ProfitLossReportDto}
                filters={cashFlowFiltersUsed}
                reportCreatedAt={reportCreatedAt}
                setCategoryFilter={setCategoryFilter}
            />
            <TransactionsDrillDownContextProvider>
                <CashFlowReportTableContainer
                    report={cashFlowReport}
                    filters={cashFlowFiltersUsed}
                    withBorder
                />
                <TransactionsDrillDownModal
                    filters={cashFlowFiltersUsed}
                    reportCreatedAt={reportCreatedAt}
                />
            </TransactionsDrillDownContextProvider>
        </>
    ) : (
        <div className="reporting-tab__empty-state">
            <EmptyState
                header="No matching transactions"
                body="The filters can't reproduce a report"
                ctaText="Reset all filters"
                action={resetFilters}
                buttonVariant={"secondary"}
            />
        </div>
    );
};
