import React from "react";
import { SettingsSection } from "../SettingsSection";
import { getEnv } from "../../../lib/environment";
import { NewWindowIcon } from "../../../icons";

export const ManageSubscription: React.FC = () => {
    return (
        <SettingsSection
            heading="Manage subscription"
            className="billing__manage"
        >
            <a
                className="btn btn-secondary btn-lg"
                href={getEnv("VITE_STRIPE_PORTAL_URL")}
                target="_blank"
                rel="noreferrer"
            >
                Customer Portal
                <NewWindowIcon className="ml-2" />
            </a>
        </SettingsSection>
    );
};
