import React, { useCallback, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getPossibleSavings } from "../../../lib/transactions";
import { Loader } from "../../general/Loader";
import { AutopilotPossibleSaving } from "./AutopilotPossibleSaving";
import { Transaction } from "../../../common/types/transaction";
import { AutopilotSavingsEmptyState } from "./AutopilotSavingsEmptyState";
import { GetPossibleSavingsResponse } from "../../../common/dto/transactions/get-possible-savings-response.dto";

export const AutopilotSavings: React.FC = () => {
    const queryKey = "possibleSavings";
    const possibleSavingsResponse = useQuery(
        queryKey,
        () => getPossibleSavings(),
        {
            onSuccess: ({ possibleSavings }) => {
                if (!openedId) {
                    setOpenedId(possibleSavings[0]?.transaction.id);
                }
            },
        },
    );
    const possibleSavings = possibleSavingsResponse.data?.possibleSavings;
    const queryClient = useQueryClient();

    const onConfirmed = useCallback(
        (transaction: Transaction) => {
            queryClient.setQueryData(
                queryKey,
                (data?: GetPossibleSavingsResponse) => {
                    return {
                        possibleSavings: data
                            ? data.possibleSavings.filter(
                                  (ps) => ps.transaction.id !== transaction.id,
                              )
                            : [],
                    };
                },
            );
        },
        [queryClient],
    );

    const [openedId, setOpenedId] = useState<number | undefined>(undefined);

    const openNext = useCallback(
        (currentlyOpened: Transaction) => {
            if (!possibleSavings) {
                return;
            }

            const currentIdx = possibleSavings.findIndex(
                (ps) => ps.transaction.id === currentlyOpened.id,
            );

            if (currentIdx === -1 || possibleSavings.length === 1) {
                return;
            }

            const nextPossibleSaving =
                possibleSavings[currentIdx + 1] ||
                possibleSavings[currentIdx - 1];
            setOpenedId(nextPossibleSaving.transaction.id);
        },
        [possibleSavings],
    );

    if (possibleSavingsResponse.isLoading) {
        return <Loader />;
    }

    if (possibleSavings?.length === 0) {
        return <AutopilotSavingsEmptyState />;
    }

    return (
        <>
            {possibleSavings?.map(({ type, transaction }, idx) => (
                <AutopilotPossibleSaving
                    type={type}
                    transaction={transaction}
                    onConfirmed={onConfirmed}
                    onOpenNext={openNext}
                    onClick={(t) => setOpenedId(t.id)}
                    opened={transaction.id === openedId}
                    key={transaction.id}
                />
            ))}
        </>
    );
};
