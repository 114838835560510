import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import { Taxonomy, TransactionDirectionType } from "../../common/categories";
import { UpdateTransactionDto } from "../../common/dto/transactions/update-transaction.dto";
import { REMOVE_REAL_ESTATE_CATEGORY_ACTION } from "../../common/requiredActions";
import { Category } from "../../common/types/category";
import { CustomCategory } from "../../common/types/customCategory";
import { Entity, EntityIndustry } from "../../common/types/entity";
import { Transaction } from "../../common/types/transaction";
import { useCategoryMap } from "../../hooks/useCategoryMap";
import { useSingleBusinessEntity } from "../../hooks/useSingleBusinessEntity";
import { SelectBusinessEntityModal } from "../entity/SelectBusinessEntityModal";
import {
    CategorySelectCustom,
    CategorySelectCustomProps,
} from "./CategorySelect/CategorySelectCustom";
import { CategorySelectPrompt } from "./CategorySelect/CategorySelectPrompt";
import { isUncategorized } from "./lib";
import { PlanManagementProvider } from "../billing/PlanManagement/PlanManagementProvider";

interface Props {
    transaction: Transaction;
    onUpdate: (payload: UpdateTransactionDto) => Promise<void>;
    enableWarning?: boolean;
    placement?: Placement;
}

export const TransactionCategoryChange: React.FC<Props> = ({
    transaction,
    onUpdate,
    enableWarning,
    placement = "bottom-end",
}) => {
    const categoryMap = useCategoryMap();
    const singleBusinessEntity = useSingleBusinessEntity();
    const [switchingToBusinessCategory, setSwitchingToBusinessCategory] =
        useState<Category>();
    const hideBusinessSelection = useCallback(
        () => setSwitchingToBusinessCategory(undefined),
        [],
    );

    const onSelected = useCallback<CategorySelectCustomProps["onSelected"]>(
        (selected: Taxonomy | CustomCategory) => {
            if (typeof selected !== "string") {
                // handle custom category id
                const customCategory = selected;
                const mainCategory = categoryMap[customCategory.mainCategoryId];
                return onUpdate({
                    customCategoryId: customCategory.id,
                    ...(mainCategory.isBusiness &&
                        singleBusinessEntity && {
                            entityId: singleBusinessEntity.id,
                        }),
                });
            }

            const selectedCategory = categoryMap[selected];

            if (!selectedCategory.isBusiness) {
                return onUpdate({ category: selectedCategory.id });
            }
            if (transaction.isBusiness) {
                return onUpdate({ category: selectedCategory.id });
            } else if (singleBusinessEntity) {
                return onUpdate({
                    category: selectedCategory.id,
                    entityId: singleBusinessEntity.id,
                });
            } else {
                setSwitchingToBusinessCategory(selectedCategory);
            }
        },
        [onUpdate, singleBusinessEntity, transaction.isBusiness, categoryMap],
    );

    const onBusinessSelected = useCallback(
        (entity: Entity) => {
            if (switchingToBusinessCategory) {
                setSwitchingToBusinessCategory(undefined);
                return onUpdate({
                    category: switchingToBusinessCategory.id,
                    entityId: entity.id,
                });
            }
        },
        [switchingToBusinessCategory, onUpdate],
    );

    const uncategorizedWarning =
        isUncategorized(transaction) &&
        transaction.isBusiness &&
        !transaction.inReview;
    const realEstateWarning =
        transaction.isBusiness &&
        transaction.requiredActions.includes(
            REMOVE_REAL_ESTATE_CATEGORY_ACTION,
        );

    return (
        <>
            <PlanManagementProvider>
                <CategorySelectCustom
                    dropdownKey={transaction.id}
                    onSelected={onSelected}
                    realEstateEnabled={
                        transaction.entity.industry ===
                        EntityIndustry.REAL_ESTATE
                    }
                    placement={placement}
                    transactionDirection={
                        transaction.amount > 0
                            ? TransactionDirectionType.incoming
                            : TransactionDirectionType.outgoing
                    }
                >
                    {(open) => (
                        <CategorySelectPrompt
                            variant={
                                enableWarning &&
                                (uncategorizedWarning || realEstateWarning)
                                    ? "warning"
                                    : "default"
                            }
                            transaction={transaction}
                            active={open}
                        />
                    )}
                </CategorySelectCustom>
            </PlanManagementProvider>
            <Modal
                show={!!switchingToBusinessCategory}
                onHide={hideBusinessSelection}
            >
                <SelectBusinessEntityModal
                    prompt="To assign a business category please select a company you'd like to move this transaction to:"
                    onCancel={hideBusinessSelection}
                    onSelected={onBusinessSelected}
                    selectPlaceholder="Select company"
                />
            </Modal>
        </>
    );
};
