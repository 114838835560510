import classNames from "classnames";
import { toNumber } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import {
    REAL_ESTATE_CATEGORIES,
    Taxonomy,
    TransactionDirectionType,
    mapCategoryName,
} from "../../../common/categories";
import { ADMIN_ROLES } from "../../../common/constants";
import {
    FLAT_RATE_PLANS,
    PREMIUM_FEATURES,
} from "../../../common/flatRateBilling";
import { NonUndefined } from "../../../common/helpers/typescript";
import { Category } from "../../../common/types/category";
import { CustomCategory } from "../../../common/types/customCategory";
import { useAllowFeature } from "../../../hooks/useAllowFeature";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { useCategories } from "../../../hooks/useCategories";
import { useCustomCategories } from "../../../hooks/useCustomCategories";
import { useRoles } from "../../../hooks/useRoles";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { DotsHorizontalIcon, ThunderboltIcon } from "../../../icons";
import { useCreateCustomCategoryMutation } from "../../../mutations/customCategories";
import { usePlanManagement } from "../../billing/PlanManagement/PlanManagementContext";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";
import { CaptureClicks } from "../../general/CaptureClicks";
import {
    WorkspaceSettingsPages,
    useSettings,
} from "../../settings/SettingsProvider";
import { CategoryIcon } from "../CategoryIcon";
import "./CategorySelectCustom.scss";

const PLAN_UPGRADE_VALUE = "#plan" as Taxonomy;
const INLINE_CATEGORY_CREATION = false;

export interface CategorySelectCustomProps {
    children: React.ReactNode | ((open: boolean) => React.ReactNode);
    onSelected(category: Taxonomy | CustomCategory): void;
    dropdownKey: string | number;
    placement?: Placement;
    realEstateEnabled?: boolean;
    showPersonal?: boolean;
    hideCustomCategories?: boolean;
    appendTo?: HTMLElement;
    transactionDirection: TransactionDirectionType;
}

export const CategorySelectCustom: React.FC<CategorySelectCustomProps> = ({
    children,
    onSelected,
    dropdownKey,
    placement = "bottom-end",
    realEstateEnabled = false,
    showPersonal = true,
    hideCustomCategories = false,
    appendTo,
    transactionDirection,
}) => {
    const categories = useCategories();
    const isAdmin = useRoles(ADMIN_ROLES);
    let customCategories = useCustomCategories();
    if (hideCustomCategories) {
        customCategories = [];
    }

    const [search, setSearch] = useState("");

    const { open: openSettings } = useSettings();
    const { upgrade } = usePlanManagement();
    const { trialAvailable, isTrialing } = useBillingStatus();

    const createCustomCategoryMutation = useCreateCustomCategoryMutation();

    const handleSelected = useCallback(
        async (value: Taxonomy | string) => {
            if (isFinite(toNumber(value))) {
                const customCategory = customCategories.find(
                    ({ id }) => id === toNumber(value),
                )!;
                onSelected(customCategory);
                return;
            }

            if (value.startsWith("/")) {
                openSettings(value.split("/").slice(1));
                return;
            }

            if (value === PLAN_UPGRADE_VALUE) {
                upgrade(FLAT_RATE_PLANS.PLUS, trialAvailable || isTrialing);
                return;
            }

            if (!(value as string).startsWith("@new/")) {
                onSelected(value as Taxonomy);
                return;
            }

            const mainCategoryId = (value as string).slice(5) as Taxonomy;
            const label = search;
            const customCategory =
                await createCustomCategoryMutation.mutateAsync({
                    mainCategoryId,
                    label,
                });
            onSelected(customCategory);
        },
        [
            createCustomCategoryMutation,
            customCategories,
            isTrialing,
            onSelected,
            openSettings,
            search,
            trialAvailable,
            upgrade,
        ],
    );

    const isFeatureAllowed = useAllowFeature(
        PREMIUM_FEATURES.CUSTOM_CATEGORIES,
    );
    const canUseCustomCategories = useTypedFlags().customCategories;

    const getSettingsOptions = useCallback(
        (value: string): CustomSelectOption<string>[] => {
            if (!canUseCustomCategories || !isAdmin) {
                return [];
            }
            return [
                isFeatureAllowed
                    ? {
                          value: `/${WorkspaceSettingsPages.CATEGORIES}/${value}`,
                          label: "Manage categories",
                      }
                    : {
                          value: PLAN_UPGRADE_VALUE,
                          label: "Manage categories",
                          renderAddon: () => (
                              <ThunderboltIcon className="ml-auto icon-color-blue-600 icon-size-text" />
                          ),
                      },
            ];
        },
        [canUseCustomCategories, isAdmin, isFeatureAllowed],
    );

    const [categoryWithOptionsOpened, setCategoryWithOptionsOpened] = useState<
        string | null
    >(null);

    const renderAddon = useCallback<
        NonUndefined<CustomSelectOption<string>["renderAddon"]>
    >(
        ({ value, onSelected: optionOnSelected }) => {
            if (!canUseCustomCategories || !isAdmin) {
                return null;
            }

            return (
                <CaptureClicks>
                    <CustomSelect
                        className="select-dropdown-settings"
                        popoverClassName="select-dropdown-settings__popover"
                        placement="right"
                        dropdownKey={`select-dropdown-settings-${value}-value`}
                        onSelected={optionOnSelected!}
                        options={getSettingsOptions(value)}
                        onShowPopover={() =>
                            setCategoryWithOptionsOpened(value)
                        }
                        onHidePopover={() =>
                            setCategoryWithOptionsOpened((currentValue) =>
                                currentValue === value ? null : currentValue,
                            )
                        }
                        canShowPopover={categoryWithOptionsOpened === value}
                    >
                        <Button variant="link" className="ml-1 settings-btn">
                            <DotsHorizontalIcon />
                        </Button>
                    </CustomSelect>
                </CaptureClicks>
            );
        },
        [
            canUseCustomCategories,
            categoryWithOptionsOpened,
            getSettingsOptions,
            isAdmin,
        ],
    );

    const sortedCategories: Category[] = useMemo(
        () =>
            categories
                .filter(
                    (category) =>
                        (realEstateEnabled ||
                            !REAL_ESTATE_CATEGORIES.includes(category.id)) &&
                        (showPersonal || category.isBusiness),
                )
                .sort((a, b) =>
                    !b.isBusiness ? -1 : a.label.localeCompare(b.label),
                ),
        [categories, realEstateEnabled, showPersonal],
    );

    // include built-in subcategories and custom subcategories
    const mappedCategories = useMemo(
        () =>
            sortedCategories.flatMap<CustomSelectOption<string>>((category) => [
                {
                    value: category.id,
                    label: (
                        <>
                            <CategoryIcon
                                category={category.id}
                                transactionDirection={transactionDirection}
                            />
                            <span className="ml-2">
                                {mapCategoryName({
                                    category,
                                    transactionDirection,
                                })}
                            </span>
                        </>
                    ),
                    searchableLabel: [
                        category.label,
                        ...category.subcategories.map((s) => s.label),
                        ...customCategories
                            .filter(
                                ({ mainCategoryId }) =>
                                    mainCategoryId === category.id,
                            )
                            .map((customCategory) => customCategory.label),
                    ].join("_"),
                    className: classNames("category-select__category", {
                        "category-select__locked_option":
                            categoryWithOptionsOpened === category.id,
                    }),
                    renderAddon,
                },
                ...category.subcategories.map<CustomSelectOption<string>>(
                    (subcategory, index) => ({
                        value: subcategory.id,
                        label: subcategory.label,
                        searchableLabel: `${category.label}_${subcategory.label}`,
                        className: classNames("category-select__subcategory", {
                            "category-select__first_subcategory": index === 0,
                            "category-select__locked_option":
                                categoryWithOptionsOpened === subcategory.id,
                        }),
                        renderAddon,
                    }),
                ),
                ...customCategories
                    .filter(
                        ({ mainCategoryId }) => mainCategoryId === category.id,
                    )
                    .map<CustomSelectOption<string>>(
                        (customCategory, index) => ({
                            value: String(
                                customCategory.id,
                            ) as unknown as Taxonomy,
                            label: customCategory.label,
                            searchableLabel: `${category.label}_${customCategory.label}`,
                            className: classNames(
                                "category-select__subcategory",
                                {
                                    "category-select__first_subcategory":
                                        category.subcategories.length === 0 &&
                                        index === 0,
                                    "category-select__locked_option":
                                        categoryWithOptionsOpened ===
                                        customCategory.id.toString(),
                                },
                            ),
                            renderAddon,
                        }),
                    ),
            ]),
        [
            sortedCategories,
            transactionDirection,
            customCategories,
            categoryWithOptionsOpened,
            renderAddon,
        ],
    );

    // include input options to create a new custom subcategory under a chosen main category
    const inputOptions = useMemo<CustomSelectOption<string>[]>(() => {
        if (!canUseCustomCategories) {
            return [];
        }

        if (!search) {
            return [];
        }

        if (!INLINE_CATEGORY_CREATION) {
            return [];
        }

        return [
            {
                value: `@new`,
                label: <span className="ml-2">Add custom "{search}"</span>,
                searchableLabel: search,
                suboptions: sortedCategories.map((category) => ({
                    value: `@new/${category.id}`,
                    label: (
                        <>
                            <CategoryIcon
                                category={category.id}
                                transactionDirection={transactionDirection}
                            />
                            <span className="ml-2">
                                ... under{" "}
                                {mapCategoryName({
                                    category,
                                    transactionDirection,
                                })}
                            </span>
                        </>
                    ),
                })),
                suboptionsSelectProps: {
                    searchable: true,
                },
            },
        ];
    }, [
        transactionDirection,
        canUseCustomCategories,
        search,
        sortedCategories,
    ]);

    return (
        <CustomSelect
            onSelected={handleSelected}
            onSearch={setSearch}
            dropdownKey={`category_${dropdownKey}`}
            options={mappedCategories}
            customOptions={inputOptions}
            shouldShowCustomOptionsWhileSearching
            customOptionsPlacement="bottom"
            placement={placement}
            empty="No matching categories"
            className="category-select"
            dropdownClassName={classNames("category-select__popover", {
                "category-select__popover-locked":
                    categoryWithOptionsOpened != null,
            })}
            searchable
            appendTo={appendTo}
        >
            {(open) =>
                children instanceof Function ? children(open) : children
            }
        </CustomSelect>
    );
};
