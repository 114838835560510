import React from "react";
import { useFinancialDocumentUpload } from "./useFinancialDocumentUpload";
import styles from "./FinancialDocumentUploadDropzone.module.scss";
import classNames from "classnames";

interface FinancialDocumentUploadDropzoneProps {
    dropzoneClassName?: string;
    coverClassName?: string;
    cover?: React.ReactNode;
}

export const FinancialDocumentUploadDropzone: React.FC<
    FinancialDocumentUploadDropzoneProps
> = ({ children, cover, coverClassName, dropzoneClassName }) => {
    const { isDragActive, rootProps } = useFinancialDocumentUpload();

    return (
        <div
            className={classNames(styles.dropzone, dropzoneClassName)}
            data-testid="financial-documents-dropzone"
            {...rootProps}
        >
            {children}

            <aside
                className={classNames(styles.cover, coverClassName, {
                    [styles.coverActive]: isDragActive,
                })}
            >
                {cover}
            </aside>
        </div>
    );
};
