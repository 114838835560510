import React, { useCallback, useState } from "react";
import { MemberRow } from "./MemberRow";
import { User } from "../../../common/types/user";
import { InviteCode } from "../../../common/types/inviteCode";
import { InviteRow } from "./InviteRow";
import { RemoveMemberModal } from "./RemoveMemberModal";
import { UserEntityAssignment } from "../../../common/types/userEntityAssignment";
import { StandardModal } from "../../general/Modal/Modal";

interface Props {
    assignments: UserEntityAssignment[];
    invites: InviteCode[];
}

export const ListMembers: React.FC<Props> = ({ assignments, invites }) => {
    const [userForRemoval, setUserForRemoval] = useState<User>();
    const hideRemovalModal = useCallback(
        () => setUserForRemoval(undefined),
        [],
    );

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Role</th>
                        <th>Last active</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {assignments.map((a) => (
                        <MemberRow
                            user={a.user}
                            role={a.role}
                            key={a.user.id}
                            onRemove={() => setUserForRemoval(a.user)}
                        />
                    ))}
                    {invites.map((invite) => (
                        <InviteRow invite={invite} key={invite.id} />
                    ))}
                </tbody>
            </table>

            <StandardModal show={!!userForRemoval} onHide={hideRemovalModal}>
                {userForRemoval && (
                    <RemoveMemberModal
                        user={userForRemoval}
                        close={hideRemovalModal}
                    />
                )}
            </StandardModal>
        </>
    );
};
