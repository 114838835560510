import { useBillingStatus } from "./useBillingStatus";
import { useTypedFlags } from "./useTypedFlags";
import { useUser } from "./useUser";
import { SubscriptionStatus } from "../common/billing";
import { SubscriptionType } from "../common/types/billing";

export function useBillingType() {
    const { flatRateBilling } = useTypedFlags();
    const { stripeSubscriptionStatus } = useUser();
    const { subscriptionDetails } = useBillingStatus();

    if (
        !subscriptionDetails ||
        stripeSubscriptionStatus !== SubscriptionStatus.ACTIVE
    ) {
        return flatRateBilling
            ? SubscriptionType.FLAT_RATE
            : SubscriptionType.ANNUAL;
    }

    return subscriptionDetails.type;
}
