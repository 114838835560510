import { Transaction } from "../../../../common/types/transaction";
import {
    useMatchTransfer,
    useUnmatchTransfer,
} from "../../../../mutations/transaction";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import styles from "./MatchButton.module.scss";

interface MatchButtonProps {
    matchedTransaction: Transaction | null;
    originTransaction: Transaction;
    targetTransaction: Transaction;
    onMatch: (
        originTransaction: Transaction,
        targetTransaction: Transaction,
    ) => void;
    onUnmatch: () => void;
}
export const MatchButton: React.FC<MatchButtonProps> = ({
    matchedTransaction,
    originTransaction,
    targetTransaction,
    onUnmatch,
    onMatch,
}: MatchButtonProps) => {
    const unmatchTransaction = useUnmatchTransfer({
        financialTransaction: originTransaction,
        onSuccess: () => {
            onUnmatch();
        },
    });

    const matchTransaction = useMatchTransfer({
        originTransaction,
        targetTransaction,
        onSuccess: ({
            originTransaction: originTransactionUpdated,
            targetTransaction: targetTransactionUpdated,
        }) => {
            onMatch(
                // NOTE: merge new and previous states to keep eager loaded entities
                // consistency with transactions table
                { ...originTransaction, ...originTransactionUpdated },
                { ...targetTransaction, ...targetTransactionUpdated },
            );
        },
    });

    return (
        <ButtonWithLoader
            data-testid="add-agg-filter"
            size="sm"
            variant="secondary"
            onClick={() =>
                matchedTransaction?.id === targetTransaction.id
                    ? unmatchTransaction.mutateAsync()
                    : matchTransaction.mutateAsync()
            }
            loading={unmatchTransaction.isLoading || matchTransaction.isLoading}
            className={styles.matchButton}
        >
            {matchedTransaction?.id === targetTransaction.id
                ? "Unmatch"
                : "Match"}
        </ButtonWithLoader>
    );
};
