import { useEntities } from "../../../hooks/useEntities";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { useAccountIdsByEntity } from "./useAccountIdsByEntity";
import { useCallback } from "react";
import { difference, uniq } from "lodash";

interface UseAccountFilterOptions {
    value: number[];
    onChange(value: number[]): void;
    onlyBusiness?: boolean;
    excludeAllMockEntities?: boolean;
    excludeEmptyMockEntities?: boolean;
    excludeNoBalanceAccounts?: boolean;
}
export function useAccountFilter({
    value,
    onChange,
    excludeAllMockEntities,
    excludeEmptyMockEntities,
    onlyBusiness,
    excludeNoBalanceAccounts,
}: UseAccountFilterOptions) {
    const entities = useEntities({
        onlyBusiness,
        excludeAllMockEntities,
        excludeEmptyMockEntities,
    });
    const availableAccounts = useFinancialAccounts({
        excludeNoBalanceAccounts,
        onlyBusiness,
    });

    const accountKeysByEntity = useAccountIdsByEntity();

    const isEntitySelected = useCallback(
        (entityId: number) =>
            !value.length ||
            accountKeysByEntity[entityId].some((key) => value.includes(key)),
        [accountKeysByEntity, value],
    );

    const isAccountSelected = useCallback(
        (accountId: number) => !value.length || value.includes(accountId),
        [value],
    );

    const prepareValue = useCallback(() => {
        return value.length
            ? value
            : availableAccounts.map((account) => account.id);
    }, [value, availableAccounts]);

    const handleAddedAccounts = useCallback(
        (newValue: number[]) => {
            if (newValue.length === availableAccounts.length) {
                onChange([]);
            } else {
                onChange(newValue);
            }
        },
        [onChange, availableAccounts],
    );

    const onToggleAccount = useCallback(
        (accountId: number) => {
            const processedValue = prepareValue();

            if (processedValue.includes(accountId)) {
                onChange(processedValue.filter((key) => key !== accountId));
            } else {
                handleAddedAccounts(uniq([...processedValue, accountId]));
            }
        },
        [onChange, prepareValue, handleAddedAccounts],
    );

    const onSelectAccounts = useCallback(
        (accountsIds: number[]) => {
            handleAddedAccounts(uniq([...value, ...accountsIds]));
        },
        [handleAddedAccounts, value],
    );

    const onDeselectAccounts = useCallback(
        (accountsIds: number[]) => {
            const processedValue = prepareValue();
            onChange(difference(processedValue, accountsIds));
        },
        [onChange, prepareValue],
    );

    const toggleEntity = useCallback(
        (entityId: number) => {
            if (isEntitySelected(entityId)) {
                onDeselectAccounts(accountKeysByEntity[entityId]);
            } else {
                onSelectAccounts(accountKeysByEntity[entityId]);
            }
        },
        [
            isEntitySelected,
            onDeselectAccounts,
            onSelectAccounts,
            accountKeysByEntity,
        ],
    );

    return {
        entities,
        isEntitySelected,
        isAccountSelected,
        onToggleAccount,
        toggleEntity,
    };
}
