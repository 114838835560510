import React from "react";
import { Button } from "react-bootstrap";
import css from "./ClearFilter.module.scss";

interface Props {
    onClick: () => void;
}
export const ClearFilter: React.FC<Props> = ({ children, onClick }) => {
    return (
        <Button
            children={children}
            onClick={onClick}
            variant="secondary"
            className={css.button}
        />
    );
};
