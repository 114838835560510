import React, { useEffect, useRef } from "react";
import { FieldArray, Form, useFormikContext } from "formik";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { Button } from "react-bootstrap";
import { SplitRowData, TransactionSplitFormSchema } from "./types";
import {
    fromIntToMonetary,
    fromMonetaryToInt,
} from "../../../common/helpers/monetary";
import { Transaction } from "../../../common/types/transaction";
import { Taxonomy } from "../../../common/categories";
import { TransactionSplitFormRow } from "./TransactionSplitFormRow";
import styles from "./TransactionSplit.module.scss";

function emptySplit(): SplitRowData {
    return { description: "", category: Taxonomy.uncategorized, amount: "" };
}

interface TransactionSplitFormProps {
    transaction: Transaction;
    busy: boolean;
}

export const TransactionSplitForm: React.FC<TransactionSplitFormProps> = ({
    busy,
    transaction,
}) => {
    const { values, setFieldValue } =
        useFormikContext<TransactionSplitFormSchema>();
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (values.split.length > 1) {
            const allButFirstTotal = values.split
                .slice(1)
                .reduce(
                    (acc, row) => acc + fromMonetaryToInt(row.amount || 0),
                    0,
                );

            const reminder = fromIntToMonetary(
                fromMonetaryToInt(transaction.amount) - allButFirstTotal,
            );

            const currentValue = values.split[0].amount;

            if (currentValue !== reminder) {
                setFieldValue(`split.0.amount`, reminder, true);
            }
        } else {
            if (values.split[0].description !== transaction.description) {
                setFieldValue("split.0.description", transaction.description);
            }

            if (values.split[0].amount !== transaction.amount) {
                setFieldValue(`split.0.amount`, transaction.amount, true);
            }
        }
    }, [
        setFieldValue,
        transaction.amount,
        transaction.description,
        values.split,
    ]);

    useEffect(() => {
        if (formRef.current) {
            // focussing an element in form prevents issues with focus in dropdowns
            // don't ask why, remove when we change the dropdowns library
            formRef.current.querySelector("input")?.focus();
        }
    }, []);

    return (
        <Form ref={formRef}>
            <FieldArray name="split">
                {({ remove, push }) => (
                    <>
                        {values.split.map((row, index) => (
                            <TransactionSplitFormRow
                                key={/* nosonar */ index}
                                index={index}
                                transaction={transaction}
                                formRef={formRef}
                                remove={remove}
                            />
                        ))}

                        <aside className="align-self-center">
                            <Button
                                size="sm"
                                variant="link"
                                onClick={() => push(emptySplit())}
                            >
                                + Add line
                            </Button>
                        </aside>
                    </>
                )}
            </FieldArray>

            <footer className={styles.footer}>
                <ButtonWithLoader loading={busy} type="submit">
                    Save and close
                </ButtonWithLoader>
            </footer>
        </Form>
    );
};
