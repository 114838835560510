import React, { useCallback, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { IntentType } from "../../../common/billing";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { ConfirmPaymentData, StripeError } from "@stripe/stripe-js";

interface Props {
    intent: IntentType;
    code?: string;
}

export const PaymentMethodModal: React.FC<Props> = ({ intent, code }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<StripeError | null>(null);
    const stripe = useStripe();
    const elements = useElements();

    const handleConfirm = useCallback(async () => {
        if (!stripe || !elements) {
            return;
        }

        const returnUrl = new URL(
            `${window.location.origin}/finalize-subscription`,
        );

        const params = new URLSearchParams(returnUrl.search);
        params.append("code", code ?? "");
        returnUrl.search = params.toString();

        const options: ConfirmPaymentData = {
            return_url: returnUrl.toString(),
        };

        setLoading(true);
        setError(null);
        const response =
            intent === IntentType.PAYMENT
                ? await stripe.confirmPayment({
                      elements,
                      confirmParams: options,
                  })
                : await stripe.confirmSetup({
                      elements,
                      confirmParams: options,
                  });

        if (response.error) {
            setError(response.error);
        }

        setLoading(false);
    }, [code, elements, intent, stripe]);

    return (
        <>
            <Modal.Header closeButton>
                <h3>Add your credit card details</h3>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <aside className="mb-4">
                        <Alert variant="danger">{error.message}</Alert>
                    </aside>
                )}

                <main className="mb-6">
                    <PaymentElement />
                </main>

                <footer>
                    <ButtonWithLoader
                        loading={loading}
                        setLoading={setLoading}
                        size="lg"
                        onClick={handleConfirm}
                    >
                        Confirm Payment
                    </ButtonWithLoader>
                </footer>
            </Modal.Body>
        </>
    );
};
