import React from "react";
import NumberFormat from "react-number-format";

interface Props {
    phoneNumber: string;
}

export const PhoneNumberDisplay: React.FC<Props> = ({ phoneNumber }) => {
    return (
        <NumberFormat
            value={phoneNumber}
            displayType="text"
            format="(###) ###-####"
        />
    );
};
