import { createContext } from "react";
import { Transaction } from "../../../common/types/transaction";
import { noop } from "lodash";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { EagerLoaded } from "../../../common/types/base/orm";

interface Context {
    transaction?: EagerLoaded<
        Transaction,
        "transactionMatches" | "pairedTransfer"
    >;
    downloadingReceipt?: boolean;
    onDownloadReceipt(): void;
    onRemoveReceipt(): void;
    uploadingReceipt?: boolean;
    onAddReceipt(file: File): Promise<void>;
    onUpdate(payload: UpdateTransactionDto): Promise<void>;
    onSplit(): void;
    setTransaction(transaction: Transaction): void;
    receiptRequired: boolean;
    memoRequired: boolean;
}

export const TransactionDetailsContext = createContext<Context>({
    onDownloadReceipt: noop,
    onRemoveReceipt: noop,
    onAddReceipt: () => Promise.resolve(),
    onUpdate: () => Promise.resolve(),
    onSplit: noop,
    setTransaction: noop,
    receiptRequired: false,
    memoRequired: false,
});
