import { JournalEntryLineProxyObject } from "./JournalEntryModal";

export function calculateDebitAndCreditTotals(
    lines: JournalEntryLineProxyObject[],
) {
    const debitAmount = lines.reduce((acc, line) => {
        if (line.debitAmount) {
            acc += line.debitAmount;
        }
        return acc;
    }, 0);
    const creditAmount = lines.reduce((acc, line) => {
        if (line.creditAmount) {
            acc += line.creditAmount;
        }
        return acc;
    }, 0);
    return { debitAmount, creditAmount };
}

const DEFAULT_NUMBER_OF_LINES = 20;

export function buildEmptyLines(
    size: number = DEFAULT_NUMBER_OF_LINES,
): JournalEntryLineProxyObject[] {
    return new Array(size).fill(null).map(() => ({
        id: Math.random().toString(),
        accountCode: "",
        debitAmount: null,
        creditAmount: null,
        description: "",
    }));
}
