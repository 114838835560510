import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Taxonomy } from "../../../common/categories";
import { Category } from "../../../common/types/category";
import { CustomCategory } from "../../../common/types/customCategory";
import { useCategories } from "../../../hooks/useCategories";
import { useCustomCategories } from "../../../hooks/useCustomCategories";
import { Tree } from "../../general/Tree/Tree";
import { TreeItem, TreeNode } from "../../general/Tree/Tree.types";
import { TreeProvider } from "../../general/Tree/TreeProvider";
import { useSettings } from "../SettingsProvider";
import css from "./Categories.module.scss";
import { CategoryTreeItem } from "./CategoryTreeItem";
import classNames from "classnames";

export interface GenericCategoryItem {
    id: string;
    category?: Category;
    customCategory?: CustomCategory;
    subcategories?: GenericCategoryItem[];
    isDraft?: boolean;
}

export const Categories: React.FC = () => {
    const kickCategories = useCategories();
    const customCategories = useCustomCategories();

    const [addingForTaxonomy, setAddingForTaxonomy] = useState<
        Taxonomy | undefined
    >(undefined);
    const [editingNodeId, setEditingNodeId] = useState<
        GenericCategoryItem["id"] | undefined
    >(undefined);

    const draftInputRef = useRef<HTMLInputElement | undefined>(undefined);

    useEffect(
        () => draftInputRef.current?.focus(),
        [addingForTaxonomy, editingNodeId],
    );

    const onAddSubcategoryDraft = useCallback(
        (id: typeof addingForTaxonomy) => {
            setEditingNodeId(undefined);
            setAddingForTaxonomy(id);
        },
        [setAddingForTaxonomy],
    );

    const onEditNode = useCallback((nodeId: typeof editingNodeId) => {
        setAddingForTaxonomy(undefined);
        setEditingNodeId(nodeId);
    }, []);

    const [lockedItemId, setLockedItemId] = useState<
        GenericCategoryItem["id"] | null
    >(null);

    const genericCategoriesTreeItems: TreeItem<GenericCategoryItem>[] = useMemo(
        () =>
            kickCategories
                .map(
                    (category): GenericCategoryItem => ({
                        id: category.id,
                        category,
                        subcategories: [
                            ...category.subcategories.map((subcategory) => ({
                                id: subcategory.id,
                                category: subcategory,
                            })),
                            ...customCategories
                                .filter(
                                    ({ mainCategoryId }) =>
                                        mainCategoryId === category.id,
                                )
                                .map((customCategory) => ({
                                    id: customCategory.id.toString(),
                                    customCategory,
                                })),
                            ...(addingForTaxonomy === category.id
                                ? [
                                      {
                                          id: "draft",
                                          isDraft: true,
                                      },
                                  ]
                                : []),
                        ],
                    }),
                )
                .map(
                    (genericCategory): TreeItem<GenericCategoryItem> => ({
                        ...genericCategory,
                        children: genericCategory.subcategories?.map(
                            (subcategory) => ({
                                ...subcategory,
                                className: classNames(css.categoryItem, {
                                    [css.lockedItem]:
                                        subcategory.id === lockedItemId,
                                }),
                            }),
                        ),
                        className: classNames(css.categoryItem, {
                            [css.lockedItem]:
                                genericCategory.id === lockedItemId,
                        }),
                    }),
                ),
        [addingForTaxonomy, customCategories, kickCategories, lockedItemId],
    );

    const { path } = useSettings();
    const initiallyExpanded = useMemo(() => {
        const [, pathId] = path;
        if (!pathId) {
            return [];
        }
        return genericCategoriesTreeItems
            .filter(
                (cat) =>
                    cat.id === pathId ||
                    cat.subcategories?.find((subCat) => subCat.id === pathId) !=
                        null,
            )
            .map((cat) => cat.id);
    }, [genericCategoriesTreeItems, path]);

    return (
        <>
            <header>
                <h3
                    data-testid="categories-heading"
                    className="settings__heading"
                >
                    Categories
                </h3>
            </header>
            <div className={css.wrapper}>
                <div
                    className={classNames(css.categories, {
                        [css.hasLockedItem]: lockedItemId != null,
                    })}
                >
                    {genericCategoriesTreeItems.length > 0 ? (
                        <TreeProvider
                            items={genericCategoriesTreeItems}
                            initiallyExpanded={initiallyExpanded}
                        >
                            <Tree
                                renderNode={(
                                    node: TreeNode<GenericCategoryItem>,
                                ) => (
                                    <CategoryTreeItem
                                        node={node}
                                        onAddSubcategoryDraft={
                                            onAddSubcategoryDraft
                                        }
                                        onEditNode={onEditNode}
                                        isEditing={
                                            editingNodeId === node.nodeId
                                        }
                                        ref={
                                            node.current.isDraft ||
                                            editingNodeId === node.nodeId
                                                ? (draftInputRef as React.Ref<HTMLInputElement>)
                                                : undefined
                                        }
                                        onShowManagement={() =>
                                            setLockedItemId(node.current.id)
                                        }
                                        onHideManagement={() =>
                                            setLockedItemId((currentValue) =>
                                                currentValue === node.current.id
                                                    ? null
                                                    : currentValue,
                                            )
                                        }
                                    />
                                )}
                            />
                        </TreeProvider>
                    ) : (
                        <p className={css.empty}>No matching categories</p>
                    )}
                </div>
            </div>
        </>
    );
};
