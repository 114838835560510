import React, { useState } from "react";
import {
    Entity,
    EntityProfile,
    EntityProfileIndustry,
    EntityProfileType,
} from "../../../common/types/entity";
import { entityTypeV2Labels, industryLabels } from "../../entity/constants";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";
import { number, object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { Col, Row } from "react-bootstrap";
import { FormRow } from "../../forms/FormRow";
import { Form, Formik, FormikConfig } from "formik";
import NumberFormat from "react-number-format";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { pick } from "lodash";

export interface BusinessProfileProps {
    entity: Entity;
    onSubmit(name: string, profile: EntityProfile): Promise<void>;
    back?(): void;
}

const entityTypes = Object.values(EntityProfileType);
const industries = Object.values(EntityProfileIndustry);

const entityTypeOptions: CustomSelectOption[] = entityTypes.map((value) => ({
    value,
    label: entityTypeV2Labels[value],
}));

const industryOptions: CustomSelectOption[] = industries.map((value) => ({
    value,
    label: industryLabels[value],
}));

export interface FormSchema {
    name: string;
    type?: EntityProfileType;
    industry?: EntityProfileIndustry;
    annualRevenue?: number;
}

export const BusinessProfile: React.FC<BusinessProfileProps> = ({
    entity,
    onSubmit,
    back,
}) => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            name: entity?.name ?? "",
            type: entity?.profile?.type,
            industry: entity?.profile?.industry,
            annualRevenue: entity?.profile?.annualRevenue,
        },
        validationSchema: object().shape({
            name: string().required("Business name is required"),
            annualRevenue: number()
                .typeError("Annual revenue must be a number")
                .required("Annual revenue is required")
                .moreThan(0, "Annual revenue must be greater than $0"),
            type: string().oneOf(entityTypes).required("Select an entity type"),
            industry: string().oneOf(industries).required("Select an industry"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                const fieldsToPick: Array<keyof FormSchema> = [
                    "type",
                    "industry",
                    "annualRevenue",
                ];

                const profile = pick(values, fieldsToPick);

                await onSubmit(values.name, {
                    ...profile,
                    annualRevenue: Number(profile.annualRevenue),
                });
            },
        }),
    };

    return (
        <OnboardingStep title="Create business profile" narrow>
            <Formik {...form}>
                <Form>
                    <Row>
                        <Col md={9}>
                            <FormRow
                                fieldName="name"
                                label="Business name"
                                size="sm"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}>
                            <FormRow fieldName="type">
                                {(helpers, field) => (
                                    <CustomSelect
                                        label="Entity type"
                                        value={field.value}
                                        onSelected={(value) =>
                                            helpers.setValue(value)
                                        }
                                        dropdownKey={field.name}
                                        options={entityTypeOptions}
                                        fullWidthDropdown
                                        size="sm"
                                    />
                                )}
                            </FormRow>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}>
                            <FormRow fieldName="industry">
                                {(helpers, field) => (
                                    <CustomSelect
                                        label="What industry is your company in?"
                                        value={field.value}
                                        onSelected={(value) =>
                                            helpers.setValue(value)
                                        }
                                        dropdownKey={field.name}
                                        options={industryOptions}
                                        fullWidthDropdown
                                        size="sm"
                                    />
                                )}
                            </FormRow>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}>
                            <FormRow
                                fieldName="annualRevenue"
                                label="Annual revenue"
                            >
                                {({ setValue }, { value }) => (
                                    <NumberFormat
                                        value={value}
                                        onValueChange={(v) => setValue(v.value)}
                                        prefix="$"
                                        decimalScale={0}
                                        allowEmptyFormatting
                                        thousandSeparator
                                        className="form-control form-control-sm"
                                    />
                                )}
                            </FormRow>
                        </Col>
                    </Row>

                    <OnboardingStepActions className="mt-4" onBack={back}>
                        <ButtonWithLoader
                            type="submit"
                            variant="primary"
                            loading={loading}
                        >
                            Next
                        </ButtonWithLoader>
                    </OnboardingStepActions>
                </Form>
            </Formik>
        </OnboardingStep>
    );
};
