import {
    FinancialAccount,
    FinancialAccountSourceType,
    FinancialAccountType,
} from "../../types/financialAccount";
import { isoStringOrNull, unpackDateOrNull, valueOrNull } from "../dto-helpers";
import { EntityDto } from "../entity/entity.dto";
import { IntegrationAccountDto } from "./integration-account.dto";

interface FinancialAccountDtoOptions {
    includeIntegrationAccounts?: boolean;
}

export class FinancialAccountDto {
    static deserialize(dto: FinancialAccountDto): FinancialAccount {
        return {
            ...dto,
            balanceSyncedAt: unpackDateOrNull(dto.balanceSyncedAt),
            transactionsSyncedAt: unpackDateOrNull(dto.transactionsSyncedAt),
            entity: valueOrNull(dto.entity, EntityDto.deserialize),
            integrationAccounts: dto.integrationAccounts?.map(
                (integrationAccount) =>
                    IntegrationAccountDto.deserialize(integrationAccount),
            ),
        };
    }
    id: number;
    balance: number | null;
    balanceSyncedAt: string | null;
    transactionsSyncedAt: string | null;
    entityId: number | null;
    entity: EntityDto | null;
    name: string | null;
    isBusiness: boolean | null;
    order: number;
    sourceType: FinancialAccountSourceType;
    routingNumber: string | null;
    wireRoutingNumber: string | null;
    accountNumberMask: string | null;
    disabled: boolean;
    financialType: FinancialAccountType;
    integrationAccounts?: IntegrationAccountDto[];

    constructor(
        financialAccount: FinancialAccount,
        { includeIntegrationAccounts = true }: FinancialAccountDtoOptions = {},
    ) {
        this.id = financialAccount.id;
        this.balance = financialAccount.balance;
        this.balanceSyncedAt = isoStringOrNull(
            financialAccount.balanceSyncedAt,
        );
        this.transactionsSyncedAt = isoStringOrNull(
            financialAccount.transactionsSyncedAt,
        );
        this.entityId = financialAccount.entityId;
        this.entity = financialAccount.entity
            ? new EntityDto(financialAccount.entity)
            : null;
        this.name = financialAccount.name;
        this.isBusiness = financialAccount.isBusiness;
        this.order = financialAccount.order;
        this.sourceType = financialAccount.sourceType;
        this.routingNumber = financialAccount.routingNumber;
        this.wireRoutingNumber = financialAccount.wireRoutingNumber;
        this.accountNumberMask = financialAccount.accountNumberMask;
        this.disabled = financialAccount.disabled;
        this.financialType = financialAccount.financialType;

        if (
            financialAccount.integrationAccounts &&
            includeIntegrationAccounts
        ) {
            this.integrationAccounts = financialAccount.integrationAccounts.map(
                (integrationAccount) =>
                    new IntegrationAccountDto(
                        {
                            ...integrationAccount,
                            financialAccount,
                        },
                        { nestedInFinancialAccount: true },
                    ),
            );
        }
    }
}
