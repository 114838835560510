import React from "react";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { ReportType } from "../../helpers/reports";

interface Props {
    activeTab: ReportType;
    setActiveTab: (tab: ReportType) => void;
}

export const ReportsPageNav: React.FC<Props> = ({
    activeTab,
    setActiveTab,
}) => {
    const { spendingTab, accountingTab } = useTypedFlags();

    return (
        <Nav variant="tabs">
            <Nav.Item>
                <Nav.Link
                    active={activeTab === ReportType.PROFIT_LOSS}
                    onClick={() => setActiveTab(ReportType.PROFIT_LOSS)}
                >
                    {accountingTab ? "Cash Activity" : "Profit & Loss"}
                </Nav.Link>
            </Nav.Item>
            {spendingTab ? (
                <Nav.Item>
                    <Nav.Link
                        active={activeTab === ReportType.SPENDING}
                        onClick={() => setActiveTab(ReportType.SPENDING)}
                    >
                        Spending
                    </Nav.Link>
                </Nav.Item>
            ) : (
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`spending-tab-nav-coming-soon`}>
                            Coming soon
                        </Tooltip>
                    }
                >
                    <Nav.Item>
                        <Nav.Link disabled>Spending</Nav.Link>
                    </Nav.Item>
                </OverlayTrigger>
            )}

            {!accountingTab && (
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`balance-sheet-nav-coming-soon`}>
                            Coming soon
                        </Tooltip>
                    }
                >
                    <Nav.Item>
                        <Nav.Link disabled>Balance Sheet</Nav.Link>
                    </Nav.Item>
                </OverlayTrigger>
            )}
        </Nav>
    );
};
