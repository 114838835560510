import { useFormikContext } from "formik";
import React from "react";

import { JournalEntryModalFormikConfig } from "./JournalEntryModal";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import { useDeleteJournalEntry } from "../../../mutations/accounting";

interface Props {
    onBack: () => void;
    onDeleteSuccess: () => void;
}

export const JournalEntryModalDelete: React.FC<Props> = ({
    onBack,
    onDeleteSuccess,
}) => {
    const formikContext = useFormikContext<JournalEntryModalFormikConfig>();
    const { values, resetForm } = formikContext;

    const onDelete = useDeleteJournalEntry({
        journalEntryId: values.journalEntryId,
        entityId: values.entityId,
        onSuccess: () => {
            onDeleteSuccess();
            resetForm();
        },
    });

    return (
        <ConfirmationModal
            title="Are you sure that you want to delete this journal entry?"
            no="Back"
            onReject={onBack}
            onConfirm={onDelete.mutate}
        />
    );
};
