import React from "react";
import { Button } from "react-bootstrap";
import { noop } from "../../../helpers/general";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import {
    CodeVerification,
    CodeVerificationProps,
} from "../../general/CodeVerification/CodeVerification";
import { ResendCode } from "../../general/CodeVerification/ResendCode";

interface LoginFlowPhoneAuthProps {
    providedPhone: string;
    onBack(): void;
    onRequestCode: CodeVerificationProps["request"];
    onVerify: CodeVerificationProps["verify"];
}

export const LoginFlowPhoneAuth: React.FC<LoginFlowPhoneAuthProps> = ({
    providedPhone,
    onBack,
    onVerify,
    onRequestCode,
}) => {
    return (
        <>
            <h2 className="login__heading">
                Check your phone
                <div>
                    We just sent a 6-digit code to your phone. Proceed by typing
                    your code below.
                </div>
            </h2>

            <CodeVerification
                phoneOrEmail={providedPhone}
                verify={onVerify}
                request={onRequestCode}
                requestOnMount={false}
                onSuccess={noop}
                lead={null}
                renderSubmit={(valid, verifying) => (
                    <>
                        <section className="mb-5 text-center">
                            <ResendCode request={onRequestCode} btnSize="lg" />
                        </section>

                        <footer className="d-flex justify-content-center mt-5">
                            <Button
                                variant="secondary-icon"
                                onClick={onBack}
                                className="mr-2"
                                size={"xl" as any}
                            >
                                <span className="fas fa-arrow-left" />
                            </Button>

                            <ButtonWithLoader
                                disabled={!valid}
                                loading={verifying}
                                type="submit"
                                size={"xl" as any}
                            >
                                Log in
                            </ButtonWithLoader>
                        </footer>
                    </>
                )}
            />
        </>
    );
};
