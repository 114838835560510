import { backendClient, unwrapResponse } from "./backendClient";
import { CreateBankAccountConnectionDto } from "../common/dto/onboarding/create-bank-account-connection.dto";
import { GetPlaidLinkTokenResponseDto } from "../common/dto/plaid/get-plaid-link-token-response.dto";
import {
    CreatePlaidConnectionResponse,
    CreatePlaidConnectionResponseDto,
} from "../common/dto/plaid/create-plaid-connection-response.dto";
import { PlaidConnection } from "../common/types/plaidConnection";
import { AddNewPlaidAccountsResponseDto } from "../common/dto/plaid/add-new-plaid-accounts-response.dto";
import { PlaidConnectionDto } from "../common/dto/plaidConnection/plaid-connection.dto";

declare const Plaid: any;

export interface PlaidSuccessfulResponse {
    connected: true;
    publicToken: string;
    metadata: OnSuccessMetadata;
}

export interface PlaidFailedResponse {
    connected: false;
    publicToken?: never;
    metadata?: never;
}

export type PlaidResponse = PlaidSuccessfulResponse | PlaidFailedResponse;

export interface CreateLinkOptions {
    linkToken: string;
    onSuccess(publicToken: string, metadata: OnSuccessMetadata): void;
    onExit?(error: any): void;
}

export interface PlaidLink {
    open(): void;
}

export interface OnSuccessInstitution {
    institution_id: string;
    name: string;
}

export interface OnSuccessAccount {
    id: string;
    mask?: string;
    name?: string;
}

export interface OnSuccessMetadata {
    account_id: string;
    institution: OnSuccessInstitution;
    accounts: OnSuccessAccount[];
}

export async function connectWithPlaid(
    connectionId?: number,
    addAccounts?: boolean,
): Promise<PlaidResponse> {
    const { token } = await getPlaidLinkToken(connectionId, addAccounts);

    return await new Promise<PlaidResponse>((resolve, reject) => {
        createLink({
            linkToken: token,
            onSuccess: (publicToken, metadata) =>
                resolve({
                    connected: true,
                    publicToken,
                    metadata,
                }),
            onExit: (error: Error) =>
                error ? reject(error) : resolve({ connected: false }),
        }).open();
    });
}

async function getPlaidLinkToken(
    connectionId?: number,
    addAccounts?: boolean,
): Promise<GetPlaidLinkTokenResponseDto> {
    return unwrapResponse(
        await backendClient.get(
            `/plaid-connection/link-token${
                connectionId ? `/${connectionId}` : ""
            }`,
            { params: { addAccounts } },
        ),
    );
}

function createLink(options: CreateLinkOptions): PlaidLink {
    return Plaid.create({
        token: options.linkToken,
        onSuccess: (publicToken: string, metadata: OnSuccessMetadata) => {
            options.onSuccess(publicToken, metadata);
        },
        onExit: (error: any) => {
            options.onExit?.(error);
        },
    });
}

export async function connectPlaidConnection(
    params: CreateBankAccountConnectionDto,
): Promise<CreatePlaidConnectionResponse> {
    return CreatePlaidConnectionResponseDto.deserialize(
        unwrapResponse(await backendClient.post("/plaid-connection", params)),
    );
}

export async function getPlaidConnections(institutionId?: string) {
    const params = institutionId ? { institutionId } : undefined;

    const response = unwrapResponse(
        await backendClient.get<PlaidConnectionDto[]>("/plaid-connection", {
            params,
        }),
    );

    return response.map((dto: PlaidConnectionDto) =>
        PlaidConnectionDto.deserialize(dto),
    );
}

export async function connectNewPlaidAccounts(
    connection: PlaidConnection,
): Promise<AddNewPlaidAccountsResponseDto> {
    return unwrapResponse(
        await backendClient.post(
            `/plaid-connection/${connection.id}/new-accounts`,
        ),
    );
}

export async function clearPlaidConnectionErrors(
    connectionId: number,
): Promise<void> {
    return unwrapResponse(
        await backendClient.post(
            `/plaid-connection/clear-errors/${connectionId}`,
        ),
    );
}

export async function deletePlaidConnection(
    connectionId: number,
): Promise<void> {
    return await backendClient.delete(`/plaid-connection/${connectionId}`);
}
