import React, { useContext } from "react";
import { TransactionActionsMessage } from "./TransactionActionsMessage";
import { IgnoreRequirementsIcon } from "../../../icons";
import { Button } from "react-bootstrap";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { Taxonomy } from "../../../common/categories";

export const TransactionRequirementsIgnoredMessage: React.FC = () => {
    const { onUpdate } = useContext(TransactionDetailsContext);

    return (
        <TransactionActionsMessage
            header={
                <>
                    <IgnoreRequirementsIcon className="icon-color-current" />{" "}
                    Requirements ignored
                </>
            }
        >
            <p>
                This means you may not have the substantiation required by the
                IRS during an audit.
            </p>
            <p>
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => onUpdate({ category: Taxonomy.personal })}
                >
                    Mark as personal
                </Button>
                or
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => onUpdate({ requirementsSnoozed: false })}
                >
                    Enable requirements
                </Button>
                to reduce your risk.
            </p>
        </TransactionActionsMessage>
    );
};
