import React, { useLayoutEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import styles from "./ActiveFilters.module.scss";
import { useAggregatedFiltersContext } from "../AggregatedFilters/AggregatedFilters.context";

export interface ActiveFiltersProps<TFilter extends string = string> {
    activeFilters: TFilter[];
    clearFilters(): void;
    renderVisibleFilters(visibleFilters: TFilter[]): React.ReactNode;
}

export const ActiveFilters = <TFilter extends string>({
    activeFilters,
    clearFilters,
    renderVisibleFilters,
}: ActiveFiltersProps<TFilter>) => {
    const [maxActiveFilters, setMaxActiveFilters] = useState(2);
    const { open: openFilters } = useAggregatedFiltersContext();
    const containerRef = React.useRef<HTMLElement>(null);

    const hasActiveFilters = activeFilters.length > 0;
    const hasMoreActiveFilters = activeFilters.length > maxActiveFilters;

    const visibleActiveFilters = useMemo(() => {
        return activeFilters.slice(
            0,
            hasMoreActiveFilters ? maxActiveFilters : undefined,
        );
    }, [activeFilters, hasMoreActiveFilters, maxActiveFilters]);

    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const width = containerRef.current.clientWidth;

        if (width > 700) {
            setMaxActiveFilters(3);
        } else if (width > 500) {
            setMaxActiveFilters(2);
        } else {
            setMaxActiveFilters(1);
        }
    }, [containerRef.current?.clientWidth]);

    return (
        <section className={styles.activeFilters} ref={containerRef}>
            {hasActiveFilters && (
                <div className={styles.list}>
                    {renderVisibleFilters(visibleActiveFilters)}

                    {hasMoreActiveFilters && (
                        <Button
                            variant="plain"
                            size="sm"
                            onClick={() => openFilters()}
                        >
                            +
                            {activeFilters.length - visibleActiveFilters.length}{" "}
                            more
                        </Button>
                    )}
                </div>
            )}
            {hasActiveFilters && (
                <Button
                    variant="plain"
                    size="sm"
                    onClick={clearFilters}
                    data-testid="clear-filters-button"
                >
                    Clear filters
                </Button>
            )}
        </section>
    );
};
