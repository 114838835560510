import React from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";

interface Props {
    id: UniqueIdentifier;
}

export const SortableListItem: React.FC<Props> = ({ id, children }) => {
    const { setNodeRef, transform, transition, isDragging } = useSortable({
        id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <div
            className={classNames("sortable-list__item", {
                "sortable-list__item--dragged": isDragging,
            })}
            style={style}
            ref={setNodeRef}
        >
            {children}
        </div>
    );
};
