import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

import { StandardModal } from "../../general/Modal/Modal";
import { noop } from "../../../helpers/general";
import { NavigationSearch } from "./NavigationSearch";
import styles from "./NavigationSearch.module.scss";

export type NavigationSearchState = "workspaces" | null;

interface NavigationSearchContextContextProps {
    state: NavigationSearchState;
    open(newState: Omit<NavigationSearchState, "null">): void;
    close(): void;
}

export const NavigationSearchContext =
    createContext<NavigationSearchContextContextProps>({
        state: "workspaces",
        open: noop,
        close: noop,
    });

export function useNavigationSearch() {
    const ctx = useContext(NavigationSearchContext);
    if (!ctx) {
        throw new Error("Can only be used inside a SettingsProvider");
    }

    return ctx;
}

export const NavigationSearchProvider: React.FC = ({ children }) => {
    const [state, setState] = useState<NavigationSearchState>(null);

    const open = useCallback(
        (newState: Omit<NavigationSearchState, "null">) => {
            setState(newState as NavigationSearchState);
        },
        [setState],
    );

    const close = useCallback(() => {
        setState(null);
    }, [setState]);

    const navigationSearchContextValue = useMemo(
        () => ({
            state,
            open,
            close,
        }),
        [state, open, close],
    );

    return (
        <NavigationSearchContext.Provider value={navigationSearchContextValue}>
            {children}

            <StandardModal
                contentClassName={styles.navigationSearchContainer}
                centered
                show={state !== null}
                onHide={close}
                size="lg"
            >
                <NavigationSearch onClose={close} />
            </StandardModal>
        </NavigationSearchContext.Provider>
    );
};
