export enum PlaidAccountMetadata {
    INITIAL_SYNC_STATUS = "initialSyncStatus",
    ACCOUNT_CHURN = "accountChurn",
    LAST_PLAID_CURSOR = "lastPlaidCursor",
}

export enum PlaidTransactionMetadata {
    REMOVE_AT = "removeAt",
}

export enum PlaidInitialSyncStatus {
    NOT_STARTED = "NOT_STARTED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETE = "COMPLETE",
}
