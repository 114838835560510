import { useCallback, useState } from "react";
import { BALANCE_SHEET_DOWNLOAD_TABLE_ID } from "./BalanceSheetDownloadTable";
import { utils, writeFileXLSX } from "@sheet/core";
import {
    disableGridLines,
    getColumn,
    getRow,
} from "../../../../common/helpers/sheet";

export function useBalanceSheetDownload() {
    const [downloading, setDownloading] = useState(false);

    const download = useCallback(() => {
        setDownloading(true);
        const tbl = document.getElementById(BALANCE_SHEET_DOWNLOAD_TABLE_ID);
        const ws = utils.table_to_sheet(tbl, {
            borders: true,
            cellStyles: true,
        });
        disableGridLines(ws);

        getColumn(ws, 0).wpx = 20;
        getColumn(ws, 1).wpx = 300;
        getColumn(ws, 2).wpx = 20;
        getColumn(ws, 3).wpx = 80;

        const dataRowsStartingAt = 6;
        for (let rowIdx = dataRowsStartingAt; rowIdx < 300; rowIdx++) {
            getRow(ws, rowIdx).hpx = 20;
        }

        if (tbl) {
            const rows = tbl.querySelectorAll("tr");

            rows.forEach((row, idx) => {
                const indentedCell = row.querySelector("td[data-indent]");

                if (indentedCell) {
                    const indent = parseInt(
                        indentedCell.getAttribute("data-indent")!,
                        10,
                    );

                    if (indent > 0) {
                        ws[`B${idx + 1}`].s.alignment.indent = indent;
                    }
                }
            });
        }

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Balance Sheet");
        writeFileXLSX(wb, "BalanceSheetDownload.xlsx", { cellStyles: true });
        setDownloading(false);
    }, []);

    return {
        download,
        downloading,
    };
}
