import React, { useCallback, useMemo } from "react";
import { Multiselect } from "../forms/Multiselect/Multiselect";
import { noop } from "lodash";
import { pluralize } from "../../common/helpers/string";
import { Account } from "../../common/types/domains/accounting/account";

interface Props {
    availableAccounts: Account[];
    value: number[];
    onChange: (value: number[]) => void;
}

export const AccountsMultiSelect: React.FC<Props> = ({
    availableAccounts,
    value,
    onChange,
}) => {
    const options = useMemo(
        () =>
            availableAccounts.map((account) => ({
                label: `${account.code} - ${account.name}`,
                value: account.code,
            })),
        [availableAccounts],
    );
    const buttonText = useCallback((selected: number[]) => {
        if (selected.length === 0) {
            return "All accounts";
        }

        return `${selected.length} ${pluralize("account", selected.length)}`;
    }, []);

    return (
        <Multiselect
            options={options}
            onChange={onChange}
            value={value}
            name="accounts"
            onBlur={noop}
            buttonSize="sm"
            buttonText={buttonText}
        />
    );
};
