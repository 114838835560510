import React, { useCallback, useState } from "react";

import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useReportsContext } from "../reports.context";
import { utils, writeFileXLSX } from "@sheet/core";
import {
    PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID,
    ProfitLossReportDownloadTable,
} from "./ProfitLossReportDownloadTable";

export const ProfitLossReportDownloadButton: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const {
        profitLossReport,
        isProfitLossReportLoading,
        profitLossFiltersUsed,
    } = useReportsContext();

    const downloadReport = useCallback(() => {
        setLoading(true);
        const tbl = document.getElementById(
            PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID,
        );
        const ws = utils.table_to_sheet(tbl, { borders: true });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Profit & Loss");
        writeFileXLSX(wb, "ProfitLossDownload.xlsx", { cellStyles: true });
        setLoading(false);
    }, []);

    return (
        <>
            <ButtonWithLoader
                loading={loading}
                disabled={isProfitLossReportLoading}
                variant="secondary"
                size="sm"
                onClick={downloadReport}
            >
                Download
            </ButtonWithLoader>
            {profitLossReport && profitLossFiltersUsed && (
                <ProfitLossReportDownloadTable
                    report={profitLossReport}
                    filters={profitLossFiltersUsed}
                />
            )}
        </>
    );
};
