import React from "react";
import { currencyFormatterNoFractions } from "../../../common/helpers/currency";
import { ProfitLossReportSummaryCard } from "./ProfitLossReportSummaryCard";
import { ProfitLossReportSummary } from "./types";

export const ProfitLossReportNetProfitSummaryCard: React.FC<
    ProfitLossReportSummary
> = ({
    startDate,
    endDate,
    netProfit,
    monthCount,
    netOperatingIncome,
    netOtherIncome,
}) => {
    return (
        <ProfitLossReportSummaryCard
            label="Net Profit"
            value={currencyFormatterNoFractions.format(netProfit)}
            startDate={startDate}
            endDate={endDate}
        >
            <div className="pl-summary-card-tooltip__value">
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Net Operating Income</span>
                    <span>
                        {currencyFormatterNoFractions.format(
                            netOperatingIncome,
                        )}
                    </span>
                </div>
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Net Other Income</span>
                    <span>
                        {currencyFormatterNoFractions.format(netOtherIncome)}
                    </span>
                </div>
                <div className="pl-summary-card-tooltip__value__line--spacer" />
                <div className="pl-summary-card-tooltip__value__line--total">
                    <span>Total</span>
                    <span>
                        {currencyFormatterNoFractions.format(netProfit)}
                    </span>
                </div>
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Per Month</span>
                    <span>
                        {currencyFormatterNoFractions.format(netProfit)} ÷{" "}
                        {monthCount} ={" "}
                        {currencyFormatterNoFractions.format(
                            netProfit / monthCount,
                        )}
                    </span>
                </div>
            </div>
        </ProfitLossReportSummaryCard>
    );
};
