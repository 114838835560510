import React from "react";
import {
    TransactionCategorizationRule,
    TransactionCategorizationRuleCondition,
} from "../../../common/types/transactionCategorizationRule";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { isString } from "lodash";
import { isValidTaxonomy } from "../../../common/helpers/taxonomy";
import { FormRow } from "../../forms/FormRow";
import { CategorySelectSimple } from "../../transactions/CategorySelect/CategorySelectSimple";
import { Taxonomy, TransactionDirectionType } from "../../../common/categories";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { CustomCategory } from "../../../common/types/customCategory";

export interface CategorizationRuleSubmitData {
    name: string;
    categoryId?: Taxonomy;
    customCategoryId?: number;
    conditions: TransactionCategorizationRuleCondition[];
}

interface Props {
    rule?: TransactionCategorizationRule;
    onSubmit: (data: CategorizationRuleSubmitData) => Promise<void>;
    isSubmitting: boolean;
    submitText: string;
}

interface FormSchema {
    name: string;
    category?: Taxonomy | CustomCategory;
    conditions: string;
}

export const CategorizationRuleForm: React.FC<Props> = ({
    rule,
    onSubmit,
    isSubmitting,
    submitText,
}) => {
    const form: FormikConfig<FormSchema> = {
        initialValues: {
            name: rule?.name ?? "",
            category: rule
                ? rule.categoryId ?? rule.customCategory!
                : undefined,
            conditions: rule ? JSON.stringify(rule.conditions) : "",
        },
        validationSchema: object().shape({
            name: string().required("Name is required"),
            conditions: string().required("Conditions are required"),
        }),
        validate: ({ category }) => {
            if (!category) {
                return { category: "Category is required" };
            }
        },
        onSubmit: submitHelper({
            handler: async ({ name, category, conditions }) => {
                if (!category) {
                    return;
                }
                let parsedConditions;

                try {
                    parsedConditions = JSON.parse(conditions);
                } catch (e) {
                    alert("Invalid JSON");
                    throw new Error("Invalid JSON");
                }

                await onSubmit({
                    name,
                    categoryId:
                        isString(category) && isValidTaxonomy(category)
                            ? category
                            : undefined,
                    customCategoryId: !isString(category)
                        ? category.id
                        : undefined,
                    conditions: parsedConditions,
                });
            },
            loading: isSubmitting,
        }),
    };

    return (
        <Formik {...form}>
            <Form>
                <FormRow fieldName="name" label="Rule name" />
                <FormRow fieldName="category" label="Category">
                    {({ setValue }, { value }) => (
                        <CategorySelectSimple
                            onSelected={setValue}
                            value={value}
                            dropdownKey="categorizationRuleCategory"
                            transactionDirection={
                                TransactionDirectionType.mixed
                            }
                        />
                    )}
                </FormRow>
                <FormRow
                    fieldName="conditions"
                    label="Conditions JSON"
                    autoResize
                />

                <footer className="d-flex justify-content-end">
                    <ButtonWithLoader
                        type="submit"
                        loading={isSubmitting}
                        size="lg"
                    >
                        {submitText}
                    </ButtonWithLoader>
                </footer>
            </Form>
        </Formik>
    );
};
