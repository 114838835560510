import React, {
    Children,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { Field, FieldMetaProps, useField } from "formik";
import { FieldHelperProps, FieldInputProps } from "formik/dist/types";
import autosize from "autosize";

import { FieldProps, FormRowRaw } from "./FormRowRaw";
import { getInputClasses } from "../../helpers/form";

export interface FormRowProps extends FieldProps {
    className?: string;
    fieldName: string;
    forceShowError?: (error?: string) => boolean;
    required?: boolean;
    children?:
        | ReactNode
        | ReactElement
        | ((
              helpers: FieldHelperProps<any>,
              field: FieldInputProps<any>,
              meta: FieldMetaProps<any>,
          ) => ReactElement);
    isInline?: boolean;
}

export const FormRow: React.FC<FormRowProps> = ({
    placeholder,
    fieldName,
    fieldType,
    children,
    forceShowError,
    showErrorMessage = true,
    disabled,
    required,
    size,
    autoResize,
    autoResizeShrinkOnBlur,
    ...props
}) => {
    const [field, meta, helpers] = useField(fieldName);
    const inputClasses = getInputClasses(size);

    const hasProvidedCustomComponent =
        Children.count(children) > 0 || typeof children === "function";

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const autosizeTextarea = useCallback(() => {
        if (autoResize && textareaRef.current) {
            autosize(textareaRef.current);
        }
    }, [autoResize, textareaRef]);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            autosizeTextarea();
        },
        [autosizeTextarea],
    );

    const onBlur = useCallback(() => {
        if (autoResizeShrinkOnBlur && textareaRef.current) {
            autosize.destroy(textareaRef.current);
        }
    }, [autoResizeShrinkOnBlur, textareaRef]);

    useEffect(() => {
        autosizeTextarea();
    }, [autosizeTextarea]);

    let fieldComponent;
    if (!hasProvidedCustomComponent) {
        fieldComponent = (
            <Field
                placeholder={placeholder}
                name={fieldName}
                type={fieldType}
                disabled={disabled}
                className={inputClasses}
                as={autoResize ? "textarea" : "input"}
                onKeyDown={handleKeyDown}
                innerRef={autoResize ? textareaRef : undefined}
                rows={autoResize ? 1 : undefined}
                onFocus={autosizeTextarea}
                onBlur={onBlur}
                InputProps={{
                    onChange: autosizeTextarea,
                }}
            />
        );
    } else if (typeof children === "function") {
        fieldComponent = children(helpers, field, meta);
    } else {
        fieldComponent = children;
    }

    const errorComponent =
        meta.error && (meta.touched || forceShowError?.(meta.error))
            ? meta.error
            : undefined;

    return (
        <FormRowRaw
            value={field.value}
            field={fieldComponent}
            error={errorComponent}
            placeholder={placeholder}
            descriptionId={`field-${fieldName}`}
            fieldType={fieldType}
            showErrorMessage={showErrorMessage}
            disabled={disabled}
            required={required}
            {...props}
        />
    );
};
