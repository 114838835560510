import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

export function styleDefaultChartTooltip(series: am4charts.XYSeries) {
    if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color(
            "rgba(255, 255, 255, 1)",
        );
        series.tooltip.background.stroke = am4core.color(
            "rgba(83, 100, 126, 0.18)",
        );
        series.tooltip.background.marginBottom = 10;

        series.tooltip.background.strokeWidth = 1;
        series.tooltip.background.fillOpacity = 1;
        series.tooltip.background.cornerRadius = 8;
        series.tooltip.label.padding(8, 12, 8, 12);
        series.tooltip.label.strokeWidth = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.background.pointerLength = 0;
        series.tooltip.dy = -8;

        const shadow = series.tooltip.background.filters.getIndex(
            0,
        ) as am4core.DropShadowFilter;
        shadow.dx = 0;
        shadow.dy = 5;
        shadow.blur = 10;
        shadow.color = am4core.color("#0f1826");
        shadow.opacity = 0.05;
    }
}
