import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useFinancialDocumentUpload } from "../documents/FinancialDocumentUpload/useFinancialDocumentUpload";
import { StandardModal } from "../general/Modal/Modal";
import { ManualImport } from "./ManualImport";
import { useRemoveFinancialDocumentMutation } from "../../mutations/financialDocument";

export const ManualImportAccountModal: React.FC<
    React.ComponentProps<typeof StandardModal>
> = ({ ...modalProps }) => {
    const removeFinancialDocumentMutation =
        useRemoveFinancialDocumentMutation();

    const { uploadingDocuments, clearUploads } = useFinancialDocumentUpload();

    const cleanupAndHide = useCallback(() => {
        uploadingDocuments.forEach((item) => {
            if (item.financialDocument) {
                removeFinancialDocumentMutation.mutate(item.financialDocument);
            }
        });

        clearUploads();

        modalProps.onHide?.();
    }, [
        clearUploads,
        modalProps,
        removeFinancialDocumentMutation,
        uploadingDocuments,
    ]);

    return (
        <StandardModal size="lg" {...modalProps} onHide={cleanupAndHide}>
            <Modal.Header closeButton>
                <Modal.Title>Manual import account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ManualImport />
            </Modal.Body>
        </StandardModal>
    );
};
