import React, { useCallback, useMemo, useRef, useState } from "react";
import { FilterSearch } from "../../../../general/FilterSearch/FilterSearch";
import Scrollbars from "react-custom-scrollbars-2";
import { useCallbackWithFocus } from "../../../../../hooks/useCallbackWithFocus";
import { noop } from "../../../../../helpers/general";
import { ClearFilter } from "../../../ClearFilter/ClearFilter";
import { FilterProps } from "../../lib";
import { useFinancialAccounts } from "../../../../../hooks/useFinancialAccounts";
import { EntityAccountsFilterProps } from "../../../../general/AccountsFilter/EntityAccountsFilter";
import { useEntityAccountsFilter } from "../../../../general/AccountsFilter/useEntityAccountsFilter";
import { useHasActiveAccountsFilter } from "../../useHasActiveAccountsFilter";
import { Entity } from "../../../../../common/types/entity";
import { TreeHelpers, TreeNode } from "../../../../general/Tree/Tree.types";
import { partition } from "lodash";
import { TreeProvider } from "../../../../general/Tree/TreeProvider";
import { Tree } from "../../../../general/Tree/Tree";
import { EntityTreeItem } from "./EntityTreeItem";
import { AccountTreeItem } from "./AccountTreeItem";
import styles from "./AggregatedAccountFilter.module.scss";
import { useExpandedEntities } from "./useExpandedEntities";
import { FinancialAccount } from "../../../../../common/types/financialAccount";

type ItemType = Entity | FinancialAccount;
type NodeType = TreeNode<Entity> | TreeNode<FinancialAccount>;

export const AccountFilterOverlay: React.FC<FilterProps> = ({
    filters,
    onChange,
}) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();

    const bankAccounts = useFinancialAccounts();
    const handleChange: EntityAccountsFilterProps["onChange"] = useCallback(
        (value) => {
            onChange({ entitiesAccounts: value });
        },
        [onChange],
    );

    const {
        toggleEntity,
        toggleAccount,
        entities,
        isAccountSelected,
        isEntitySelected,
    } = useEntityAccountsFilter({
        value: filters.entitiesAccounts,
        onChange: handleChange,
        allowDeselectingLastItem: true,
        defaultSelected: false,
        excludeEmptyMockEntities: true,
    });

    const forceExpandedEntities = useExpandedEntities(filters.entitiesAccounts);

    const onReset = useCallback(() => {
        onChange({ entitiesAccounts: undefined });
    }, [onChange]);

    const hasActiveFilters = useHasActiveAccountsFilter(filters);

    const entitiesWithAccounts = useMemo(
        () =>
            entities.map((entity) => ({
                ...entity,
                children: bankAccounts.filter(
                    (account) => account.entity?.id === entity.id,
                ),
            })),
        [bankAccounts, entities],
    );

    const [personalEntities, businessEntities] = useMemo(() => {
        let filteredEntities = entitiesWithAccounts.filter(
            (entity) => !entity.isMockEntity || entity.children.length > 0,
        ); // hide mock entities with no accounts

        if (search) {
            filteredEntities = entitiesWithAccounts.filter((entity) =>
                entity.name?.toLowerCase().includes(search.toLowerCase()),
            );
        }

        return partition(filteredEntities, (entity) => entity.isPersonal);
    }, [entitiesWithAccounts, search]);

    const displayAnyEntities =
        personalEntities.length > 0 || businessEntities.length > 0;

    const handleToggleEntity = useCallbackWithFocus(toggleEntity, searchRef);
    const handleToggleAccount = useCallbackWithFocus(toggleAccount, searchRef);
    const handleReset = useCallbackWithFocus(onReset ?? noop, searchRef);

    const renderNode = useCallback(
        (node: NodeType, helpers: TreeHelpers) => {
            if (isEntityNode(node)) {
                return (
                    <EntityTreeItem
                        {...helpers}
                        node={node}
                        toggleSelected={handleToggleEntity}
                        isSelected={isEntitySelected}
                    />
                );
            } else {
                return (
                    <AccountTreeItem
                        {...helpers}
                        node={node}
                        toggleSelected={handleToggleAccount}
                        isSelected={isAccountSelected}
                    />
                );
            }
        },
        [
            handleToggleAccount,
            handleToggleEntity,
            isAccountSelected,
            isEntitySelected,
        ],
    );

    return (
        <div className={styles.filter}>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
                focus
            />

            <Scrollbars
                style={{ width: "100%" }}
                autoHeight
                autoHeightMax={400}
                autoHeightMin={80}
            >
                {displayAnyEntities ? (
                    <>
                        {personalEntities.length > 0 && (
                            <section className={styles.section}>
                                <header>Personal</header>
                                <TreeProvider
                                    items={personalEntities as ItemType[]}
                                    expandAll={!!search}
                                    forceExpanded={forceExpandedEntities}
                                >
                                    <Tree renderNode={renderNode} />
                                </TreeProvider>
                            </section>
                        )}

                        {businessEntities.length > 0 && (
                            <section className={styles.section}>
                                <header>Business</header>
                                <TreeProvider
                                    items={businessEntities as ItemType[]}
                                    expandAll={!!search}
                                    forceExpanded={forceExpandedEntities}
                                >
                                    <Tree renderNode={renderNode} />
                                </TreeProvider>
                            </section>
                        )}
                    </>
                ) : (
                    <p className={styles.empty}>No matching entities</p>
                )}
            </Scrollbars>

            {hasActiveFilters ? (
                <ClearFilter onClick={handleReset}>Reset</ClearFilter>
            ) : null}
        </div>
    );
};

function isEntityNode(node: NodeType): node is TreeNode<Entity> {
    return "profile" in node.current;
}
