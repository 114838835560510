import React, { useCallback, useState } from "react";
import { Switch } from "../general/Switch/Switch";
import { DisconnectFinancialAccountModal } from "./DisconnectFinancialAccountModal/DisconnectFinancialAccountModal";
import { usePlaidConnectionFixingMutation } from "../../mutations/plaidConnection";
import { Placement } from "react-bootstrap/Overlay";
import { OverlayTrigger } from "react-bootstrap";
import { FinancialAccount } from "../../common/types/financialAccount";
import {
    useAccountDisablingMutation,
    useAccountEnablingMutation,
    useFinancialAccountRemovalMutation,
} from "../../mutations/financialAccount";
import { FinancialConnection } from "../../common/types/financialConnection";
import { PlaidConnection } from "../../common/types/plaidConnection";
import { FinancialConnectionProviderLabel } from "../../common/constants";

export interface FinancialAccountStateManagerProps {
    account: FinancialAccount;
    connection: FinancialConnection;
    tooltip?: React.ReactElement;
    tooltipPlacement?: Placement;
}

export const FinancialAccountStateManager: React.FC<
    FinancialAccountStateManagerProps
> = ({ account, connection, tooltip, tooltipPlacement = "left" }) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const hideModal = useCallback(() => {
        setModalVisible(false);
    }, []);

    const enableAccount = useAccountEnablingMutation(account);
    const disableAccount = useAccountDisablingMutation(account, hideModal);
    const removeAccount = useFinancialAccountRemovalMutation(
        account,
        hideModal,
    );

    const handleDisabledStateChange = useCallback(
        async (active: boolean) => {
            try {
                setBusy(true);

                if (active) {
                    await enableAccount.mutateAsync();
                } else {
                    setModalVisible(true);
                }
            } catch (e) {
            } finally {
                setBusy(false);
            }
        },
        [enableAccount],
    );

    const handleConnectionFixed = useCallback(async () => {
        if (account.disabled) {
            await handleDisabledStateChange(true);
        }
    }, [account.disabled, handleDisabledStateChange]);

    const fixAccount = usePlaidConnectionFixingMutation(
        connection as PlaidConnection,
        handleConnectionFixed,
    );

    const [busy, setBusy] = useState<boolean>(false);

    const modalPartial = modalVisible ? (
        <DisconnectFinancialAccountModal
            account={account}
            onDisable={disableAccount}
            onRemove={removeAccount}
            onCancel={hideModal}
        />
    ) : null;

    const label = `Connected to ${
        FinancialConnectionProviderLabel[connection.connectionProvider]
    }`;

    let controlPartial: React.ReactElement | null;

    if (connection.error) {
        controlPartial = (
            <Switch
                label={label}
                active={!connection.error}
                onChanged={() => fixAccount.mutate()}
                loading={fixAccount.isLoading}
                size="sm"
                danger
                direction="rtl"
            />
        );
    } else {
        controlPartial = (
            <Switch
                label={label}
                active={!account.disabled}
                onChanged={handleDisabledStateChange}
                loading={busy}
                size="sm"
                direction="rtl"
            />
        );
    }

    if (tooltip) {
        controlPartial = (
            <OverlayTrigger
                placement={tooltipPlacement}
                trigger={!account.disabled ? ["hover"] : []}
                overlay={tooltip}
            >
                <span className="w-100">{controlPartial}</span>
            </OverlayTrigger>
        );
    }

    return (
        <>
            {controlPartial}
            {modalPartial}
        </>
    );
};
