import { useMemo } from "react";

const ZERO_WIDTH_SPACE = "\u200B";

export function useBreakableText(text: string) {
    return useMemo(() => {
        const words = text.split(" ");

        return words
            .map((word) => {
                if (word.length > 20) {
                    word = word.replace(
                        /([a-zA-Z0-9][^a-zA-Z0-9])/g,
                        `$1${ZERO_WIDTH_SPACE}`,
                    );

                    if (!word.includes(ZERO_WIDTH_SPACE)) {
                        word =
                            word.match(/.{1,20}/g)?.join(ZERO_WIDTH_SPACE) ??
                            "";
                    }
                }

                return word;
            })
            .join(" ");
    }, [text]);
}
