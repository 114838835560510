import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export function useQueryParam(name: string): string | null {
    const location = useLocation();

    return useMemo(
        () => new URLSearchParams(location.search).get(name),
        [location.search, name],
    );
}
