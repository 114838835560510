import React, { useState } from "react";
import {
    Entity,
    EntityProfile,
    FirmSize,
    ProfessionalType,
} from "../../../common/types/entity";
import { firmSizeLabels, professionalTypeLabels } from "../../entity/constants";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";
import { object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { FormRow } from "../../forms/FormRow";
import { Form, Formik, FormikConfig } from "formik";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";

export interface FirmInfoProps {
    entity: Entity;
    onSubmit(
        payload: Pick<
            EntityProfile,
            "professionalType" | "firmSize" | "howDidYouLearn"
        >,
    ): Promise<void>;
    back(): void;
}

const sizes = Object.values(FirmSize);
const professionalTypes = Object.values(ProfessionalType);

const sizeOptions: CustomSelectOption[] = sizes.map((value) => ({
    value,
    label: firmSizeLabels[value],
}));

const professionalTypeOptions: CustomSelectOption[] = professionalTypes.map(
    (value) => ({
        value,
        label: professionalTypeLabels[value],
    }),
);

export interface FormSchema {
    firmSize?: FirmSize;
    professionalType?: ProfessionalType;
    howDidYouLearn?: string;
}

export const FirmInfo: React.FC<FirmInfoProps> = ({
    entity,
    onSubmit,
    back,
}) => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            firmSize: entity.profile?.firmSize,
            professionalType: entity.profile?.professionalType,
            howDidYouLearn: entity.profile?.howDidYouLearn,
        },
        validationSchema: object().shape({
            firmSize: string().oneOf(sizes).required("Select a size"),
            professionalType: string()
                .oneOf(professionalTypes)
                .required("Select a type"),
            howDidYouLearn: string().required("Required"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ firmSize, howDidYouLearn, professionalType }) => {
                await onSubmit({
                    firmSize,
                    howDidYouLearn,
                    professionalType,
                });
            },
        }),
    };

    return (
        <OnboardingStep title="Tell us about your firm" narrow contentNarrow>
            <Formik {...form}>
                <Form>
                    <FormRow fieldName="firmSize">
                        {(helpers, field) => (
                            <CustomSelect
                                label="Number of employees"
                                value={field.value}
                                onSelected={(value) => helpers.setValue(value)}
                                dropdownKey={field.name}
                                options={sizeOptions}
                                fullWidthDropdown
                                size="sm"
                            />
                        )}
                    </FormRow>

                    <FormRow fieldName="professionalType">
                        {(helpers, field) => (
                            <CustomSelect
                                label="Type of professional"
                                value={field.value}
                                onSelected={(value) => helpers.setValue(value)}
                                dropdownKey={field.name}
                                options={professionalTypeOptions}
                                fullWidthDropdown
                                size="sm"
                            />
                        )}
                    </FormRow>

                    <FormRow
                        fieldName="howDidYouLearn"
                        label="How did you hear about Kick?"
                        size="sm"
                    />

                    <OnboardingStepActions className="mt-4" onBack={back}>
                        <ButtonWithLoader
                            type="submit"
                            variant="primary"
                            loading={loading}
                        >
                            Next
                        </ButtonWithLoader>
                    </OnboardingStepActions>
                </Form>
            </Formik>
        </OnboardingStep>
    );
};
