import React from "react";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UsageValue } from "./UsageValue";
import { currentYear } from "../settings/Billing/Pricing/constants";
import "./UsageWidget.scss";

export const UsageWidget: React.FC = () => {
    const { creditsUsed, isFetched } = useBillingStatus();

    if (!isFetched) {
        return null;
    }

    return (
        <div className="usage-widget">
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="usage-widget-tooltip">
                        An estimate of the tax savings Kick
                        <br /> has calculated for the {currentYear} tax year
                    </Tooltip>
                }
            >
                <div>
                    <UsageValue value={creditsUsed} />
                </div>
            </OverlayTrigger>
        </div>
    );
};
