import React from "react";
import ReactDOM from "react-dom";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import * as Sentry from "@sentry/react";
import { App } from "./App";
import { getEnv, initEnvironment } from "./lib/environment";
import { Provider } from "react-redux";
import { Store } from "./store";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./queryClient";
import * as am4core from "@amcharts/amcharts4/core";
import { browserTracingIntegration } from "@sentry/browser";
import { WorkspaceProvider } from "./state/workspaceContext";
import { intercom } from "./lib/intercom";
import { setupIntercom } from "./lib/setupIntercom";

initEnvironment();

if (getEnv("VITE_SENTRY_ENABLED") === "true") {
    Sentry.init({
        dsn: getEnv("VITE_SENTRY_DSN"),
        environment: getEnv("NODE_ENV"),
        integrations: [browserTracingIntegration()],
        tracesSampleRate: 0.2,
        normalizeDepth: 6,
        beforeSend(event: Sentry.Event, hint: Sentry.EventHint) {
            if ((hint.originalException as any)?.code === "ERR_CANCELED") {
                return null;
            }

            return event;
        },
    });
}

if (getEnv("VITE_INTERCOM_APP_ID")) {
    setupIntercom();

    intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: getEnv("VITE_INTERCOM_APP_ID"),
        hide_default_launcher: true,
        custom_launcher_selector: "#intercom-launcher",
        alignment: "left",
        vertical_padding: 56,
        horizontal_padding: 12,
        action_color: "#515D71", // gray-700
        background_color: "white",
    });
}

if (getEnv("VITE_AMCHARTS_LICENSE")) {
    am4core.addLicense(getEnv("VITE_AMCHARTS_LICENSE"));
}

const hotjarId = getEnv("VITE_HOTJAR_ID");
if (hotjarId && /^\d+$/.exec(hotjarId)) {
    // @ts-ignore
    // prettier-ignore
    // eslint-disable-next-line
    /*nosonar*/ (function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:hotjarId,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

ReactDOM.render(
    <Provider store={Store}>
        <QueryClientProvider client={queryClient}>
            <LDProvider
                clientSideID={getEnv("VITE_LAUNCHDARKLY_CLIENT_SIDE_ID")}
            >
                <WorkspaceProvider>
                    <App />
                </WorkspaceProvider>
            </LDProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </Provider>,
    document.getElementById("root"),
);
