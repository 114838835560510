import React, { useCallback, useState } from "react";
import { OnboardingStep } from "../../../onboarding/OnboardingStep";
import { OnboardingStepActions } from "../../../onboarding/OnboardingStepActions";
import { Button } from "react-bootstrap";
import { OptionGroupItem } from "../../../general/OptionGroup/OptionGroupItem";
import StripeLogo from "../../../../icons/logos/Stripe.svg?react";
import PaypalLogo from "../../../../icons/logos/Paypal.svg?react";
import VenmoLogo from "../../../../icons/logos/Venmo.svg?react";
import CashAppLogo from "../../../../icons/logos/CashApp.svg?react";
import { useProcessorConnectionStatus } from "./useProcessorConnectionStatus";
import { ConnectPlaid } from "../../../plaid/ConnectPlaid/ConnectPlaid";
import {
    financialConnectionsQueryKey,
    queryClient,
} from "../../../../queryClient";
import { CreateStripeConnection } from "../../../stripe/CreateStripeConnection";
import { OptionGroupCustomItem } from "../../../general/OptionGroup/OptionGroupCustomItem";
import { FinancialAccount } from "../../../../common/types/financialAccount";

interface Props {
    financialAccounts: FinancialAccount[];
    onSubmit(requested: string): void;
    back(): void;
}

export const PaymentProcessor: React.FC<Props> = ({
    onSubmit,
    back,
    financialAccounts,
}) => {
    const [requested, setRequested] = useState<string>("");

    const {
        venmoConnected,
        paypalConnected,
        stripeConnected,
        cashAppConnected,
    } = useProcessorConnectionStatus(financialAccounts);

    const handleConnected = useCallback(() => {
        void queryClient.invalidateQueries(financialConnectionsQueryKey);
    }, []);

    return (
        <OnboardingStep title="Select payment processor" contentNarrow>
            <div className="mb-5">
                <CreateStripeConnection onCreated={handleConnected}>
                    {({ initializeConnection }) => (
                        <OptionGroupItem
                            icon={<StripeLogo />}
                            title="Stripe"
                            selected={stripeConnected}
                            onToggle={initializeConnection}
                        />
                    )}
                </CreateStripeConnection>

                <ConnectPlaid onConnected={handleConnected}>
                    {({ initializeConnection }) => (
                        <>
                            <OptionGroupItem
                                icon={<PaypalLogo />}
                                title="PayPal"
                                selected={paypalConnected}
                                onToggle={initializeConnection}
                            />
                            <OptionGroupItem
                                icon={<VenmoLogo />}
                                title="Vemno"
                                selected={venmoConnected}
                                onToggle={initializeConnection}
                            />
                            <OptionGroupItem
                                icon={<CashAppLogo />}
                                title="Cash App"
                                selected={cashAppConnected}
                                onToggle={initializeConnection}
                            />
                        </>
                    )}
                </ConnectPlaid>

                <OptionGroupCustomItem
                    value={requested}
                    empty="Other"
                    onChange={setRequested}
                />
            </div>

            <OnboardingStepActions className="mt-4" onBack={back}>
                <Button variant="primary" onClick={() => onSubmit(requested)}>
                    Next
                </Button>
            </OnboardingStepActions>
        </OnboardingStep>
    );
};
