import React, { useMemo } from "react";
import { SettingsSection } from "../SettingsSection";
import { Faq, Question } from "../../general/FAQ/Faq";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import {
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../common/helpers/currency";
import { PAID_PLAN_USAGE_LIMIT } from "../../../common/flatRateBilling";
import { ENTERPRISE_TRIAL_CALL_URL } from "../../../common/billing";
import { BookCallButton } from "../../general/BookCall/BookCallButton";
import { useSettings } from "../SettingsProvider";

interface Props {
    onCallBooked: () => void;
}

export const FAQSection: React.FC<Props> = ({ onCallBooked }) => {
    const { plans, isFetched } = useBillingStatus();
    const { open: openSettings } = useSettings();

    const questions: Question[] = useMemo(() => {
        if (!isFetched) {
            return [];
        }

        const freeUsageLimitFormatted = currencyFormatterShort
            .format(plans[0].usageLimit!)
            .toLowerCase();
        const paidUsageLimitFormatted = currencyFormatterShort
            .format(PAID_PLAN_USAGE_LIMIT)
            .toLowerCase();

        return [
            {
                question: "How does Kick’s pricing work?",
                answer: (
                    <>
                        <p>
                            Kick plans are tailored to support Business Owners
                            at every stage:{" "}
                        </p>

                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="no-wrap">
                                        Annual business expenses
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td className="no-wrap">
                                        $0 - {freeUsageLimitFormatted}
                                    </td>
                                    <td>
                                        Kick is completely free to use for as
                                        long as you want.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-wrap">
                                        {freeUsageLimitFormatted} -{" "}
                                        {paidUsageLimitFormatted}
                                    </td>
                                    <td>
                                        Enjoy a 30-day trial of our Basic or
                                        Plus plan. Cancel anytime during your
                                        trial. After your trial ends, you’ll be
                                        charged for full-year bookkeeping.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-wrap">
                                        {paidUsageLimitFormatted}+
                                    </td>
                                    <td>
                                        <BookCallButton
                                            variant="link"
                                            size={"xs" as any}
                                            url={ENTERPRISE_TRIAL_CALL_URL}
                                            onBooked={onCallBooked}
                                            className="btn-link--inline"
                                        >
                                            Book a call
                                        </BookCallButton>{" "}
                                        with our team to get a discount tailored
                                        to your industry.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ),
            },
            {
                question: "What’s included in my 30-day free trial?",
                answer: "When you start a 30-day Free Trial on the Basic or Plus Plan, you get access to all paid features and the last four months of transaction categorization to view your Profit and Loss and other reports.",
            },
            {
                question: `What happens if I have more than ${currencyFormatterNoFractions.format(plans[0].usageLimit!)} in annual expenses?`,
                answer: (
                    <>
                        Business Owners with more than {freeUsageLimitFormatted}{" "}
                        in annual expenses can upgrade to one of our paid plans
                        to continue using Kick. Once the{" "}
                        {freeUsageLimitFormatted} limit is reached, access to
                        Bookkeeping, Accounting and Reporting features will be
                        restricted going forward. You’ll always have access to
                        your prior reports.
                    </>
                ),
            },
            {
                question: "Does Kick also offer tax services?",
                answer: (
                    <>
                        Kick focuses on automating the bookkeeping and the tax
                        prep workflows for Business Owners and their
                        Accountants. We recommend partnering with a local
                        Accountant. Reach out to{" "}
                        <a href="mailto:support@kick.co">support@kick.co</a> for
                        a list of recommended tax professionals.
                    </>
                ),
            },
            {
                question: "Can Kick help me get last year’s bookkeeping done?",
                answer: (
                    <>
                        Yes! When selecting a plan, you’ll have the option to
                        select “Last Year’s Bookkeeping,” which we’ll handle for
                        you once your Free Trial is complete.
                    </>
                ),
            },
            {
                question: "How do I pause or cancel my subscription?",
                answer: (
                    <>
                        To cancel your subscription go to{" "}
                        <button
                            className="btn btn-xs btn-link btn-link--inline"
                            onClick={() => openSettings(["billing"])}
                        >
                            Settings {">"} Billing
                        </button>
                        {". "}
                        After canceling you will continue to have access to data
                        during your Subscription period.
                    </>
                ),
            },
            {
                question: "Can I delete my Kick account?",
                answer: (
                    <>
                        Yes, reach out to{" "}
                        <a href="mailto:support@kick.co">support@kick.co</a>.
                    </>
                ),
            },
        ];
    }, [isFetched, onCallBooked, openSettings, plans]);

    return (
        <SettingsSection heading="FAQ">
            <Faq questions={questions} />
        </SettingsSection>
    );
};
