import React from "react";
import { PopupButton } from "react-calendly";
import { CUSTOM_PRICING_CALL_URL } from "../../../common/billing";

interface Props {
    className?: string;
    text: string;
}

export const CustomPricingCallButton: React.FC<Props> = ({
    className,
    text,
}) => {
    return (
        <PopupButton
            className={className}
            url={CUSTOM_PRICING_CALL_URL}
            rootElement={document.body}
            text={text}
        />
    );
};
