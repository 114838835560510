import React, { ReactNode } from "react";
import { SettingsSection } from "../../../SettingsSection";
import { CheckIcon } from "../../../../../icons";
import { Card } from "../../../../general/Card/Card";
import styles from "./Features.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Feature {
    label: string;
    tooltip: ReactNode;
}

const FEATURES: Feature[] = [
    {
        label: "Profit & Loss (Cash Basis)",
        tooltip:
            "Always know where your business stands, and gain insights into what’s driving profitability",
    },
    {
        label: "Deduction Optimization",
        tooltip: "Maximize write-offs and minimize tax bills",
    },
    {
        label: "Real-time Categorization",
        tooltip:
            "KickAI categorizes transactions as they happen to maximize tax savings and minimize busywork",
    },
    {
        label: "Unlimited Accounts",
        tooltip:
            "Connect all credit cards and bank accounts across businesses to maximize deductions",
    },
    {
        label: "Receipt Matching",
        tooltip:
            "Send us receipts and we’ll automatically match to the right transactions",
    },
    {
        label: "Unlimited Members",
        tooltip:
            "Securely share access to any business with your team and CPA. ",
    },
    {
        label: "Expense Monitoring",
        tooltip: "Monitor spend, and cut unnecessary business expenses.",
    },

    {
        label: "Expert Support",
        tooltip: "Get expert support from real humans",
    },
];

export const FeaturesSection: React.FC = () => {
    return (
        <SettingsSection heading="What's Included">
            <Card className={styles.card}>
                <ul className={styles.list}>
                    {FEATURES.map((item, idx) => (
                        <li className={styles.item} key={item.label}>
                            <CheckIcon />
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={`billing-feature-${idx}`}>
                                        {item.tooltip}
                                    </Tooltip>
                                }
                            >
                                <span>{item.label}</span>
                            </OverlayTrigger>
                        </li>
                    ))}
                </ul>
            </Card>
        </SettingsSection>
    );
};
