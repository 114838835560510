import React from "react";
import { currencyFormatterNoFractions } from "../../../common/helpers/currency";
import { ProfitLossReportSummaryCard } from "./ProfitLossReportSummaryCard";
import { ProfitLossReportSummary } from "./types";
import { EMPTY_STATE } from "../../../common/constants";

function printProfitMargin(revenue: number, netProfit: number) {
    if (revenue < 1) return EMPTY_STATE;
    const profitMargin = Math.round((netProfit / revenue) * 100);
    if (profitMargin < -100) return EMPTY_STATE;
    return `${profitMargin}%`;
}

export const ProfitLossReportProfitMarginSummaryCard: React.FC<
    ProfitLossReportSummary
> = ({ startDate, endDate, netProfit, revenue }) => {
    return (
        <ProfitLossReportSummaryCard
            label="Profit Margin"
            value={printProfitMargin(revenue, netProfit)}
            startDate={startDate}
            endDate={endDate}
        >
            <div className="pl-summary-card-tooltip__value">
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Net Profit</span>
                    <span>
                        {currencyFormatterNoFractions.format(netProfit)}
                    </span>
                </div>
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Revenue</span>
                    <span>
                        ÷ {currencyFormatterNoFractions.format(revenue)}
                    </span>
                </div>
                <div className="pl-summary-card-tooltip__value__line--spacer" />
                <div className="pl-summary-card-tooltip__value__line--total">
                    <span>Total</span>
                    <span>{printProfitMargin(revenue, netProfit)}</span>
                </div>
            </div>
        </ProfitLossReportSummaryCard>
    );
};
