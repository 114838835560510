import { getEnv } from "./environment";
import auth0 from "auth0-js";
import { PHONE_NUMBER_PREFIX } from "../common/constants";
import { isEmail } from "../common/helpers/accounts";
import { Auth0Error } from "./errors/auth0.error";

let webAuth: auth0.WebAuth;

const ACCESS_TOKEN_KEY = "Kick Access Token";
const ACCESS_TOKEN_EXPIRY_KEY = "Kick Access Token Expires At";

export function getWebAuth() {
    if (!webAuth) {
        webAuth = new auth0.WebAuth({
            domain: getEnv("VITE_AUTH0_DOMAIN"),
            clientID: getEnv("VITE_AUTH0_CLIENT_ID"),
            redirectUri: getEnv("VITE_AUTH0_REDIRECT_URI"),
            audience: getEnv("VITE_AUTH0_AUDIENCE"),
        });
    }

    return webAuth;
}

export function saveAccessToken(token: string, expiresIn?: number) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    if (expiresIn) {
        localStorage.setItem(
            ACCESS_TOKEN_EXPIRY_KEY,
            (Date.now() + expiresIn * 1000).toString(),
        );
    }
}

export function handleLogin(): Promise<string> {
    return new Promise((resolve, reject) => {
        getWebAuth().parseHash((e, result) => {
            if (e) {
                reject(new Auth0Error(e));
            } else if (result) {
                saveAccessToken(result.accessToken ?? "", result.expiresIn);
                resolve(result.accessToken ?? "");
            } else {
                reject(new Error("No response"));
            }
        });
    });
}

export function getUserProfile(token: string): Promise<auth0.Auth0UserProfile> {
    return new Promise((resolve, reject) => {
        getWebAuth().client.userInfo(token, (e, user) => {
            if (e) {
                reject(new Auth0Error(e));
            } else {
                resolve(user);
            }
        });
    });
}

export function getAccessToken(): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        const expiresAt = localStorage.getItem(ACCESS_TOKEN_EXPIRY_KEY);

        if (accessToken) {
            if (expiresAt && parseInt(expiresAt, 10) - 60 * 1000 < Date.now()) {
                getWebAuth().checkSession(
                    {
                        usePostMessage: true,
                        responseType: "token",
                    },
                    (e, result) => {
                        if (e) {
                            reject(new Auth0Error(e));
                        } else if (result) {
                            saveAccessToken(
                                result.accessToken,
                                result.expiresIn,
                            );
                            resolve(result.accessToken);
                        } else {
                            reject(new Error("No response"));
                        }
                    },
                );
            } else {
                resolve(accessToken);
            }
        } else {
            resolve(null);
        }
    });
}

export function clearAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRY_KEY);
}

export async function passwordlessLogin(
    phoneNumberOrEmail: string,
): Promise<void> {
    return new Promise((resolve, reject) => {
        getWebAuth().passwordlessStart(
            {
                ...(isEmail(phoneNumberOrEmail)
                    ? {
                          email: phoneNumberOrEmail,
                          connection: "email",
                      }
                    : {
                          phoneNumber: PHONE_NUMBER_PREFIX + phoneNumberOrEmail,
                          connection: "sms",
                      }),
                send: "code",
                authParams: {
                    responseType: "token",
                },
            },
            (e) => {
                if (e) {
                    reject(new Auth0Error(e));
                } else {
                    resolve();
                }
            },
        );
    });
}

export function finishPasswordlessLogin(
    phoneNumberOrEmail: string,
    code: string,
): Promise<boolean> {
    return new Promise((resolve, reject) => {
        getWebAuth().passwordlessLogin(
            {
                ...(isEmail(phoneNumberOrEmail)
                    ? {
                          email: phoneNumberOrEmail,
                          connection: "email",
                      }
                    : {
                          phoneNumber: PHONE_NUMBER_PREFIX + phoneNumberOrEmail,
                          connection: "sms",
                      }),
                verificationCode: code,
                responseType: "token",
            },
            (e) => {
                if (e) {
                    if (e.code === "access_denied") {
                        resolve(false);
                    } else {
                        reject(new Auth0Error(e));
                    }
                } else {
                    resolve(true);
                }
            },
        );
    });
}
