import React, { useCallback, useMemo, useState } from "react";
import { array, number, object, string } from "yup";
import { Formik, FormikConfig, FormikErrors } from "formik";
import { useQuery, useQueryClient } from "react-query";

import { submitHelper } from "../../../helpers/form";
import { CreateManualJournalEntryDto } from "../../../common/dto/manualJournalEntry/create-manual-journal-entry.dto";
import { useAccounts } from "../../../hooks/useAccounts";
import { JournalEntryModalContent } from "./JournalEntryModalContent";
import { ManualJournalEntryLineDto } from "../../../common/dto/manualJournalEntry/manual-journal-entry-line.dto";
import { Modal } from "react-bootstrap";
import {
    createManualJournalEntry,
    getJournalEntry,
    upsertJournalEntry,
} from "../../../lib/accounting";
import { addNotification } from "../../../reducers/appState";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../helpers/date";
import { useEntitiesWithAccountingAvailable } from "../useEntitiesWithAccountingAvailable";
import { buildEmptyLines } from "./JournalEntryModalUtils";
import { JournalEntrySource } from "../../../common/types/domains/accounting/journalEntry";
import { JournalEntryModalDelete } from "./JournalEntryModalDelete";

interface Props {
    show: boolean;
    onHide: () => void;
    entityId: number;
    journalEntryId: string | undefined;
}

export type JournalEntryLineProxyObject = Omit<
    ManualJournalEntryLineDto,
    "accountCode"
> & {
    id: string;
    accountCode: string;
};

export type JournalEntryFormProxyObject = Omit<
    CreateManualJournalEntryDto,
    "lines" | "memo"
> & {
    id?: string;
    memo: string;
    lines: JournalEntryLineProxyObject[];
    source: JournalEntrySource | null;
};

export interface JournalEntryModalFormikConfig {
    journalEntry: JournalEntryFormProxyObject;
    isHideAfterSubmit: boolean;
    entityId: number;
    journalEntryId: string | undefined;
}

export type JournalEntryModalErrors =
    FormikErrors<JournalEntryModalFormikConfig> & {
        debitAndCreditSums?: string;
    };

export const NUMBER_OF_CELLS_PER_LINE = 5;

export const COLUMN_INDEX_TO_NAME: Record<
    number,
    keyof JournalEntryLineProxyObject
> = {
    1: "accountCode",
    2: "debitAmount",
    3: "creditAmount",
    4: "description",
};

// Fixing Formik's bad design: https://github.com/jaredpalmer/formik/issues/1251
export const JournalEntryModalMutableConfig = {
    validate: false,
};

export const JournalEntryModal: React.FC<Props> = ({
    show,
    onHide,
    entityId: entityIdProp,
    journalEntryId,
}) => {
    const availableEntities = useEntitiesWithAccountingAvailable();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [formEntityId, setFormEntityId] = useState<number>(entityIdProp);

    const selectedEntity = useMemo(() => {
        return availableEntities.find((e) => e.id === formEntityId)!;
    }, [availableEntities, formEntityId]);

    const [submittedWithErrors, setSubmittedWithErrors] = useState(false);

    const [showDeletionConfirmation, setShowDeletionConfirmation] =
        useState(false);

    const { accounts } = useAccounts(formEntityId);

    const queryClient = useQueryClient();

    const fetchedJournalEntry = useQuery(
        ["journalEntry", journalEntryId, entityIdProp],
        async () => {
            if (!journalEntryId) {
                return null;
            }
            const je = await getJournalEntry(journalEntryId, entityIdProp);
            const lines = buildEmptyLines();

            for (const [index, line] of je.lines.entries()) {
                lines[index] = {
                    id: Math.random().toString(),
                    accountCode: line.accountCode.toString(),
                    debitAmount: line.debitAmount,
                    creditAmount: line.creditAmount,
                    description: line.description,
                };
            }
            return {
                id: je.id,
                date: je.date.toString(),
                memo: je.memo ?? "",
                lines,
                source: je.source,
            };
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );

    const memoizedInitialValues: JournalEntryModalFormikConfig = useMemo(() => {
        return {
            journalEntry: fetchedJournalEntry.data ?? {
                date: new Date().toISOString(),
                memo: "",
                lines: buildEmptyLines(),
                source: null,
            },
            isHideAfterSubmit: false,
            entityId: entityIdProp,
            journalEntryId: journalEntryId,
        };
    }, [fetchedJournalEntry.data, entityIdProp, journalEntryId]);

    const onDeleteSuccess = useCallback(() => {
        onHide();
        setShowDeletionConfirmation(false);
    }, [onHide]);

    const form: FormikConfig<JournalEntryModalFormikConfig> = useMemo(
        () => ({
            validateOnBlur: true,
            validateOnChange: true,
            initialValues: memoizedInitialValues,
            enableReinitialize: true,
            validate: (values) => {
                // Fixing Formik's bad design: https://github.com/jaredpalmer/formik/issues/1251
                if (
                    !JournalEntryModalMutableConfig.validate &&
                    !submittedWithErrors
                ) {
                    return { journalEntry: undefined };
                }

                const errors: JournalEntryModalErrors = {};
                const lines = values.journalEntry.lines;
                const debitAndCreditSums = lines.reduce(
                    (acc, curr) => {
                        if (curr.debitAmount) {
                            acc.debit += curr.debitAmount;
                        }
                        if (curr.creditAmount) {
                            acc.credit += curr.creditAmount;
                        }
                        return acc;
                    },
                    {
                        debit: 0,
                        credit: 0,
                    },
                );
                if (
                    (debitAndCreditSums.debit || debitAndCreditSums.credit) &&
                    debitAndCreditSums.debit !== debitAndCreditSums.credit
                ) {
                    errors.debitAndCreditSums =
                        "Please check that your total debits match total credits";
                }
                if (JournalEntryModalMutableConfig.validate) {
                    setSubmittedWithErrors(true);
                    JournalEntryModalMutableConfig.validate = false;
                }
                return errors;
            },
            validationSchema: object().shape({
                journalEntry: object().shape({
                    lines: array().of(
                        object().shape({
                            accountCode: string().when(
                                ["debitAmount", "creditAmount"],
                                {
                                    is: (
                                        debitAmount: number | string | null,
                                        creditAmount: number | string | null,
                                    ) =>
                                        Boolean(debitAmount) ||
                                        Boolean(creditAmount),
                                    then: () =>
                                        string()
                                            .required()
                                            .oneOf(
                                                [
                                                    ...accounts.map((a) =>
                                                        String(a.code),
                                                    ),
                                                ],
                                                "Invalid account code",
                                            ),
                                },
                            ),
                            debitAmount: number().moreThan(0).nullable(),
                            creditAmount: number().moreThan(0).nullable(),
                        }),
                    ),
                }),
            }),
            onSubmit: submitHelper({
                loading,
                setLoading,
                handler: async (
                    { journalEntry, isHideAfterSubmit, entityId },
                    { validateForm, resetForm },
                ) => {
                    const errors: JournalEntryModalErrors = await validateForm({
                        journalEntry,
                    });
                    if (Object.keys(errors).length > 0) {
                        setSubmittedWithErrors(true);
                        return;
                    }
                    const journalEntryDto: CreateManualJournalEntryDto = {
                        memo:
                            journalEntry.memo.length > 0
                                ? journalEntry.memo
                                : null,
                        lines: journalEntry.lines
                            .filter(
                                (line) =>
                                    line.accountCode.length > 0 ||
                                    line.debitAmount ||
                                    line.creditAmount,
                            )
                            .map((line) => ({
                                debitAmount: line.debitAmount,
                                creditAmount: line.creditAmount,
                                accountCode: Number(line.accountCode),
                                description:
                                    line.description.length > 0
                                        ? line.description
                                        : "Manual journal entry",
                            })),
                        date: formatDate(new Date(journalEntry.date)),
                    };
                    if (journalEntryId) {
                        await upsertJournalEntry(
                            entityId,
                            journalEntryId,
                            journalEntryDto,
                        );
                        dispatch(
                            addNotification({
                                message: `Journal entry updated for ${selectedEntity.name}`,
                                type: "success",
                                confetti: true,
                            }),
                        );
                    } else {
                        await createManualJournalEntry(
                            entityId,
                            journalEntryDto,
                        );
                        dispatch(
                            addNotification({
                                message: `Journal entry created for ${selectedEntity.name}`,
                                type: "success",
                                confetti: true,
                            }),
                        );
                    }

                    resetForm();
                    if (isHideAfterSubmit) {
                        onHide();
                    }
                    setSubmittedWithErrors(false);

                    queryClient.invalidateQueries("generalLedger");
                },
            }),
        }),
        [
            accounts,
            loading,
            onHide,
            submittedWithErrors,
            setSubmittedWithErrors,
            selectedEntity,
            dispatch,
            queryClient,
            memoizedInitialValues,
            journalEntryId,
        ],
    );

    const onEscapeKeyDown = useCallback((e: KeyboardEvent) => {
        e.preventDefault();
    }, []);

    return (
        <Formik {...form}>
            <Modal
                size={showDeletionConfirmation ? "lg" : "xl"}
                onEscapeKeyDown={onEscapeKeyDown}
                onHide={
                    showDeletionConfirmation
                        ? () => setShowDeletionConfirmation(false)
                        : onHide
                }
                show={show}
            >
                {showDeletionConfirmation ? (
                    <JournalEntryModalDelete
                        onBack={() => setShowDeletionConfirmation(false)}
                        onDeleteSuccess={onDeleteSuccess}
                    />
                ) : (
                    <JournalEntryModalContent
                        formEntityId={formEntityId}
                        setFormEntityId={setFormEntityId}
                        loading={loading}
                        onDelete={() => setShowDeletionConfirmation(true)}
                    />
                )}
            </Modal>
        </Formik>
    );
};
