import React from "react";

import { ExtendedFinancialAccount } from "../../../../lib/financialAccount";
import { AccountDetailsField } from "./AccountDetailsField";
import { GustoConnectedCompanyMetadata } from "../../../../common/types/gustoConnection";

export interface AccountDetailsProps {
    account: ExtendedFinancialAccount;
}

export const GustoAccountDetails: React.FC<AccountDetailsProps> = ({
    account,
}) => {
    const integrationAccount = account.integrationAccounts?.[0];
    const companyMetadata =
        integrationAccount?.metadata as GustoConnectedCompanyMetadata;
    if (!companyMetadata) return null;
    return (
        <>
            <AccountDetailsField title="Company name">
                {companyMetadata.name}
            </AccountDetailsField>
            <AccountDetailsField title="EIN">
                {companyMetadata.ein}
            </AccountDetailsField>
        </>
    );
};
