import { ADMIN_ROLES } from "../common/constants";
import { getTotalBalance } from "./useFinancialAccounts";
import { useQuery } from "react-query";
import { useWorkspaceContext } from "../state/workspaceContext";
import { getUserEntities } from "../lib/user";
import { useMemo } from "react";
import { getEntitiesAccountsQueryKey } from "../queries/entitiesAccounts";

interface Options {
    requireAdminAccess?: boolean;
    onlyBusiness?: boolean;
    onlyPersonal?: boolean;
    onlyWithBalance?: boolean;
    excludeMockEntities?: boolean;
    excludeEmptyMockEntities?: boolean;
}

export function useEntitiesContexts({
    requireAdminAccess,
    onlyBusiness,
    onlyPersonal,
    onlyWithBalance,
    excludeMockEntities,
    excludeEmptyMockEntities = true,
}: Options = {}) {
    const { activeWorkspace } = useWorkspaceContext();

    const query = useQuery(
        getEntitiesAccountsQueryKey(activeWorkspace?.id ?? ""),
        () => {
            if (!activeWorkspace) {
                return undefined;
            }

            return getUserEntities(activeWorkspace.id);
        },
        {
            refetchOnMount: false,
        },
    );

    return useMemo(() => {
        if (!query.data) {
            return undefined;
        }

        let contexts = query.data.entities;

        if (requireAdminAccess) {
            contexts = contexts.filter((e) => ADMIN_ROLES.includes(e.role));
        }
        if (onlyBusiness) {
            contexts = contexts.filter((e) => e.entity.isBusiness);
        }
        if (onlyPersonal) {
            contexts = contexts.filter((e) => e.entity.isPersonal);
        }
        if (excludeMockEntities) {
            contexts = contexts.filter((e) => !e.entity.isMockEntity);
        }
        if (excludeEmptyMockEntities) {
            contexts = contexts.filter(
                (e) => !e.entity.isMockEntity || e.financialAccounts.length > 0,
            );
        }
        if (onlyWithBalance) {
            contexts = contexts.filter(
                (e) => getTotalBalance(e.financialAccounts) > 0,
            );
        }

        return contexts;
    }, [
        query.data,
        requireAdminAccess,
        onlyBusiness,
        onlyPersonal,
        onlyWithBalance,
        excludeMockEntities,
        excludeEmptyMockEntities,
    ]);
}
