import React from "react";
import { ProfitLossReportHeaderCell } from "./ProfitLossReportHeaderCell";
import classNames from "classnames";
import { ProfitLossReportCell } from "./ProfitLossReportCell";
import { ReportFilters } from "../../../common/types/filters/reports";
import { Table } from "@tanstack/react-table";
import { PLDataRow } from "./ProfitLossReportTableContainer";
import { ProfitLossReportSummarySections } from "../../../common/dto/reports/reports-common.dto";

interface Props {
    table: Table<PLDataRow>;
    filters: ReportFilters;
    tableProps?: React.HTMLAttributes<HTMLTableElement>;
    highlightLastRow?: boolean;
    numberFormatter?: (value: number) => string;
}
export const ProfitLossReportTable: React.FC<Props> = ({
    table,
    filters,
    tableProps = {},
    highlightLastRow,
    numberFormatter,
}) => {
    return (
        <table
            className={classNames(
                "data-table__table data-table__table--fixed-layout",
                {
                    "data-table--highlight-last-row": highlightLastRow,
                },
            )}
            {...tableProps}
        >
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <ProfitLossReportHeaderCell
                                key={header.id}
                                header={header}
                                filters={filters}
                            />
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        className={classNames(
                            "data-table__row",
                            `data-table__row--depth-${row.depth}`,
                            {
                                "data-table__row--total": row.original.isTotal,
                                "data-table__row--summary-section":
                                    ProfitLossReportSummarySections.includes(
                                        row.original.section,
                                    ),
                            },
                        )}
                    >
                        {row.getVisibleCells().map((cell) => (
                            <ProfitLossReportCell
                                key={cell.id}
                                cell={cell}
                                numberFormatter={numberFormatter}
                            />
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
