import React from "react";
import classNames from "classnames";

export interface AccountDetailsFieldProps {
    title?: React.ReactNode;
    className?: string;
}

export const AccountDetailsField: React.FC<AccountDetailsFieldProps> = ({
    title,
    children,
    className,
}) => {
    return (
        <div className={classNames("account-details__field", className)}>
            {title && (
                <header className="account-details__field__heading">
                    {title}
                </header>
            )}
            <div className="account-details__field__content">{children}</div>
        </div>
    );
};
