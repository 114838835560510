import React, { forwardRef } from "react";
import { ButtonProps } from "react-bootstrap/Button";
import { useInteraction } from "../../../hooks/useInteraction";
import styles from "./CustomToggle.module.scss";

export const CustomToggle: React.FC<ButtonProps> = forwardRef(
    ({ children, onClick }, ref) => {
        const toggleClick = useInteraction(onClick);

        return (
            <div {...toggleClick} className={styles.toggle}>
                {children}
                <div ref={ref as any} />
            </div>
        );
    },
);
