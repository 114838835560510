import classNames from "classnames";
import React, { useContext } from "react";
import { Loader } from "../../general/Loader";
import "./TransactionDetails.scss";
import { TransactionDetailsHeader } from "./TransactionDetailsHeader";
import { TransactionDetailsMiscFields } from "./TransactionDetailsMiscFields";
import { TransactionDetailsCategory } from "./TransactionDetailsCategory";
import { TransactionEntity } from "../TransactionEntity";
import Scrollbars from "react-custom-scrollbars-2";
import { TransactionDetailsFooter } from "./TransactionDetailsFooter";
import { TransactionActionsSection } from "./TransactionActionsSection";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { TransactionDetailsHistory } from "./TransactionDetailsHistory/TransactionDetailsHistory";

interface Props {
    onClose(): void;
}

export const TransactionDetails: React.FC<Props> = ({ onClose }) => {
    const { transaction } = useContext(TransactionDetailsContext);

    return (
        <section
            className={classNames(
                `transaction-details transaction-details--${transaction?.status}`,
                {
                    "transaction-details--credit":
                        transaction && transaction.amount > 0,
                    "transaction-details--debit":
                        transaction && transaction.amount < 0,
                    "transaction-details--requirements-ignored":
                        transaction?.requirementsSnoozed,
                },
            )}
        >
            {transaction ? (
                <Scrollbars autoHeight autoHeightMax="calc(100vh - 76px)">
                    <header className="transaction-details__header">
                        <TransactionDetailsHeader
                            transaction={transaction}
                            onClose={onClose}
                        />
                        <TransactionEntity transaction={transaction} />
                    </header>
                    <main className="transaction-details__main">
                        <TransactionDetailsCategory transaction={transaction} />

                        <TransactionActionsSection transaction={transaction} />

                        <TransactionDetailsMiscFields
                            transaction={transaction}
                        />

                        <TransactionDetailsHistory transaction={transaction} />

                        <TransactionDetailsFooter transaction={transaction} />
                    </main>
                </Scrollbars>
            ) : (
                <Loader />
            )}
        </section>
    );
};
