export function fromMonetaryToInt(value: number): number {
    return Math.round(value * 100);
}

export function fromIntToMonetary(value: number): number {
    return value / 100;
}

export function sumMonetaryValues(values: number[]) {
    return fromIntToMonetary(
        values.reduce((acc, value) => {
            acc += fromMonetaryToInt(value);
            return acc;
        }, 0),
    );
}

export function subtractMonetaryValues(minuend: number, subtrahend: number) {
    return fromIntToMonetary(
        fromMonetaryToInt(minuend) - fromMonetaryToInt(subtrahend),
    );
}
