import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    CashFlowColumn,
    CashFlowTableData,
    CashFlowTableRow,
} from "../../../common/dto/reports/cash-flow-tab-report-response.dto";
import {
    CASH_FLOW_PERCENT_DIFF_SUFFIX,
    CASH_FLOW_VALUE_DIFF_SUFFIX,
} from "../../../common/dto/reports/reports-common.dto";
import { NonUndefined } from "../../../common/helpers/typescript";
import { ReportFilters } from "../../../common/types/filters/reports";
import { CashFlowReportCell } from "./CashFlowReportCell";
import { CashFlowReportHeaderCell } from "./CashFlowReportHeaderCell";

export interface CashFlowReportTableProps {
    tableData: CashFlowTableData;
    filters: ReportFilters;
    tableProps?: React.HTMLAttributes<HTMLTableElement>;
    getStickyRightOffset?: (col: CashFlowColumn) => number | undefined;
}

export const CashFlowReportTable: React.FC<CashFlowReportTableProps> = ({
    tableData,
    filters,
    tableProps = {},
    getStickyRightOffset,
}) => {
    const { rows, columns } = tableData;
    const defaultRowVisibility = useMemo<Record<string, boolean>>(
        () =>
            Object.fromEntries(
                rows.flatMap(function fn({
                    id,
                    children,
                }): Array<[string, boolean]> {
                    return [
                        [id, !hasSuffix(id)],
                        ...(children?.flatMap(fn) ?? []),
                    ];
                }),
            ),
        [rows],
    );
    const [rowVisibility, setRowVisibility] = useState(defaultRowVisibility);
    useEffect(
        () => setRowVisibility(defaultRowVisibility),
        [defaultRowVisibility],
    );
    const toggleRow = useCallback<
        NonUndefined<CashFlowReportRowProps["toggleRow"]>
    >(
        (id) =>
            setRowVisibility((map) => ({
                ...map,
                [id]: !map[id],
            })),
        [],
    );

    return (
        <table
            className={classNames(
                "data-table__table data-table__table--fixed-layout",
            )}
            {...tableProps}
        >
            <thead>
                <tr>
                    {columns.map((column) => (
                        <CashFlowReportHeaderCell
                            key={column.id}
                            label={
                                {
                                    title: "",
                                    percent: "%",
                                    total: "Total",
                                }[column.id] ?? column.id
                            }
                            isTitle={column.isTitle}
                            filters={filters}
                            stickyRight={getStickyRightOffset?.(column)}
                        />
                    ))}
                </tr>
            </thead>

            <tbody>
                {rows.map(
                    (row) =>
                        rowVisibility[row.id] && (
                            <CashFlowReportRow
                                key={row.id}
                                columns={tableData.columns}
                                row={row}
                                depth={0}
                                toggleRow={toggleRow}
                                rowVisibility={rowVisibility}
                                getStickyRightOffset={getStickyRightOffset}
                            />
                        ),
                )}
            </tbody>
        </table>
    );
};

interface CashFlowReportRowProps {
    columns: CashFlowColumn[];
    row: CashFlowTableRow;
    depth: number;
    toggleRow?: (id: string) => void;
    rowVisibility?: Record<string, boolean>;
    getStickyRightOffset?: (col: CashFlowColumn) => number | undefined;
}
export const CashFlowReportRow: React.FC<CashFlowReportRowProps> = ({
    columns,
    row,
    depth,
    toggleRow,
    rowVisibility,
    getStickyRightOffset,
}) => {
    const [isExpanded, setIsExpanded] = useState(row.isExpanded ?? false);
    return (
        <>
            <tr
                key={row.id}
                className={classNames(
                    "data-table__row",
                    `data-table__row--depth-${depth}`,
                    {
                        "data-table__row--total": row.isTotal,
                        "data-table__row--summary-section": row.isSummary,
                        "data-table__row--underlined":
                            depth === 0 && !hasSuffix(row.id),
                        "data-table__row--highlighted": row.isHighlighted,
                    },
                )}
            >
                {columns.map((col) => (
                    <CashFlowReportCell
                        key={col.id}
                        column={col}
                        row={row}
                        isTitle={col.id === "title"}
                        stickyRight={getStickyRightOffset?.(col)}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        toggleRow={toggleRow}
                        rowVisibility={rowVisibility}
                    />
                ))}
            </tr>
            {isExpanded &&
                row.children?.map(
                    (childRow) =>
                        rowVisibility?.[childRow.id] !== false && (
                            <CashFlowReportRow
                                key={childRow.id}
                                columns={columns}
                                row={childRow}
                                depth={depth + 1}
                                toggleRow={toggleRow}
                                rowVisibility={rowVisibility}
                                getStickyRightOffset={getStickyRightOffset}
                            />
                        ),
                )}
        </>
    );
};

function hasSuffix(id: string) {
    return (
        id.endsWith(CASH_FLOW_PERCENT_DIFF_SUFFIX) ||
        id.endsWith(CASH_FLOW_VALUE_DIFF_SUFFIX)
    );
}
