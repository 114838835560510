import React, { useCallback, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { currencyFormatter } from "../../common/helpers/currency";
import colors from "@styles/colors.module.scss";

interface Props {
    value: number;
    onEnd: () => void;
    onAboutToEnd: () => void;
}

export const AnimatedSavings: React.FC<Props> = ({
    value,
    onEnd,
    onAboutToEnd,
}) => {
    const isRunning = useRef(false);
    const [containerSpring, containerApi] = useSpring(() => ({
        from: { top: 100, backgroundColor: colors.white, opacity: 0 },
        config: { duration: 300 },
    }));

    const [valueSpring, valueApi] = useSpring(() => ({
        from: { number: 0 },
        config: { duration: 1000 },
    }));

    // https://github.com/pmndrs/react-spring/issues/1660
    const [valueAnimationFinished, setValueAnimationFinished] = useState(false);

    const cleanupAnimation = useCallback(() => {
        setTimeout(() => {
            containerApi.start({
                to: { opacity: 0 },
                onRest: onEnd,
            });
        }, 1000);
    }, [containerApi, onEnd]);

    useEffect(() => {
        if (!isRunning.current) {
            containerApi.start({
                to: { top: 0, backgroundColor: colors.blue100, opacity: 1 },
            });
            valueApi.start({
                to: { number: value },
                onRest: () => {
                    cleanupAnimation();
                    onAboutToEnd();
                    setValueAnimationFinished(true);
                },
            });
            isRunning.current = true;
        }
    }, [value, containerApi, valueApi, cleanupAnimation, onAboutToEnd]);

    return (
        <animated.div
            className="usage-widget__animated-value"
            style={{
                ...containerSpring,
                top: containerSpring.top.to((t) => `calc(${t}% - 1px)`),
            }}
        >
            {valueAnimationFinished
                ? `+ ${currencyFormatter.format(valueSpring.number.get())}`
                : valueSpring.number.to(
                      (n) => `+ ${currencyFormatter.format(n)}`,
                  )}
        </animated.div>
    );
};
