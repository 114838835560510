import React, { useCallback, useMemo, useState } from "react";
import { financialDocumentUploadContext } from "./financialDocumentUploadContext";
import { FileRejection, useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../reducers/appState";
import {
    RECEIPT_TYPES,
    SPREADSHEET_TYPES,
    UploadingDocumentPlaceholder,
    WORD_TYPES,
} from "../lib";
import { without } from "lodash";
import { uploadDocument } from "../../../lib/financialDocument";
import { toBase64 } from "../../../helpers/file";
import { useWorkspaceContext } from "../../../state/workspaceContext";

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const ERROR_CODES: Record<string, string> = {
    "file-too-large": `Max allowed size is ${MAX_FILE_SIZE_MB}MB`,
    "file-invalid-type": "Only PDF, PNG, JPEG files allowed",
};

interface FinancialDocumentUploadProviderProps {
    accept?: string[];
}

export const FinancialDocumentUploadProvider: React.FC<
    FinancialDocumentUploadProviderProps
> = ({ children, accept }) => {
    const [uploadingDocuments, setUploadingDocuments] = useState<
        UploadingDocumentPlaceholder[]
    >([]);

    const { activeWorkspaceKey } = useWorkspaceContext();
    const dispatch = useDispatch();
    const handleFilesRejected = useCallback(
        (rejections: FileRejection[]) => {
            rejections.forEach((rejection) => {
                dispatch(
                    addNotification({
                        type: "danger",
                        message: ERROR_CODES[rejection.errors[0].code]
                            ? `${rejection.file.name} - ${
                                  ERROR_CODES[rejection.errors[0].code]
                              }`
                            : "Unknown error when uploading file",
                    }),
                );
            });
        },
        [dispatch],
    );

    const processFile = useCallback(
        async (file: File) => {
            const placeholder: UploadingDocumentPlaceholder = {
                fileName: file.name,
                isUploading: true,
            };

            const contentToUpload = await toBase64(file);

            try {
                setUploadingDocuments((prev) => [...prev, placeholder]);
                const uploadedDocument = await uploadDocument({
                    workspaceId: activeWorkspaceKey,
                    file: contentToUpload,
                    filename: file.name,
                    contentType: file.type,
                });

                setUploadingDocuments((prev) =>
                    prev.map((el) =>
                        el.fileName === placeholder.fileName
                            ? {
                                  ...el,
                                  isUploading: false,
                                  financialDocument: uploadedDocument,
                              }
                            : el,
                    ),
                );
            } catch (e) {
                setUploadingDocuments((prev) => without(prev, placeholder));
            }
        },
        [activeWorkspaceKey],
    );

    const handleFilesAccepted = useCallback(
        (files: File[]) => {
            files.forEach(processFile);
        },
        [processFile],
    );

    const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
        onDropRejected: handleFilesRejected,
        onDropAccepted: handleFilesAccepted,
        accept: accept ?? [
            ...RECEIPT_TYPES,
            ...SPREADSHEET_TYPES,
            ...WORD_TYPES,
        ],
        maxSize: MAX_FILE_SIZE_BYTES,
        multiple: true,
        noClick: true,
    });

    const clearUploads = useCallback(() => {
        setUploadingDocuments([]);
    }, []);

    const value = useMemo(() => {
        return {
            isDragActive: isDragAccept,
            inputProps: getInputProps(),
            rootProps: getRootProps(),
            open,
            uploadingDocuments,
            updateUploads: setUploadingDocuments,
            clearUploads,
        };
    }, [
        clearUploads,
        getInputProps,
        getRootProps,
        isDragAccept,
        open,
        uploadingDocuments,
    ]);

    return (
        <financialDocumentUploadContext.Provider value={value}>
            {children}
        </financialDocumentUploadContext.Provider>
    );
};
