import { FinancialConnectionWithAccounts } from "../../types/financialConnection";
import { UserFinancialConnectionDto } from "./user-financial-connection.dto";

export interface UserFinancialConnection {
    connection: FinancialConnectionWithAccounts;
    numberOfNotVisibleAccounts: number;
}

export interface UserIntegrationsResponse {
    financialConnections: UserFinancialConnection[];
}

export class UserIntegrationsResponseDto {
    financialConnections: UserFinancialConnectionDto[];

    constructor(financialConnections: UserFinancialConnection[]) {
        this.financialConnections = financialConnections.map(
            ({ connection, numberOfNotVisibleAccounts }) =>
                new UserFinancialConnectionDto(
                    connection,
                    numberOfNotVisibleAccounts,
                ),
        );
    }

    static deserialize(
        dto: UserIntegrationsResponseDto,
    ): UserIntegrationsResponse {
        return {
            financialConnections: dto.financialConnections.map(
                UserFinancialConnectionDto.deserialize,
            ),
        };
    }
}
