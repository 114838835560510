import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { difference, values } from "lodash";
import React, { useEffect } from "react";
import { Taxonomy } from "../../common/categories";
import {
    ProfitLossChartDataSeriesCalculatedIdentifier,
    ProfitLossReportDto,
} from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { printLabel } from "../../common/helpers/reports";
import { Category } from "../../common/types/category";
import { ReportFilters } from "../../common/types/filters/reports";
import {
    CategoryLabelGetter,
    useCategoryLabelGetter,
} from "../../hooks/useCategoryLabelGetter";
import { useCategoryMap } from "../../hooks/useCategoryMap";
import { Card } from "../general/Card/Card";
import { applySeriesColor } from "./helpers/colors";
import { generateSingleCategoryTooltip } from "./helpers/tooltip";
import { CreateFilteredChartParams, GenerateDataSeriesParams } from "./types";

am4core.useTheme(am4themes_animated);

const chartLabelsMap: Record<
    ProfitLossChartDataSeriesCalculatedIdentifier,
    string
> = {
    other: "Other",
    income_rest: "Other",
    dummy: "Dummy",
};

function getSeriesLabel(
    field: string,
    getCategoryLabel: CategoryLabelGetter,
): string {
    if (
        values(ProfitLossChartDataSeriesCalculatedIdentifier).includes(
            field as ProfitLossChartDataSeriesCalculatedIdentifier,
        )
    ) {
        return chartLabelsMap[
            field as ProfitLossChartDataSeriesCalculatedIdentifier
        ];
    }

    return getCategoryLabel(field as Taxonomy);
}

function generateDataSeries({
    chart,
    field,
    getCategoryLabel,
    categoryMap,
}: GenerateDataSeriesParams) {
    // Set up series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = getSeriesLabel(field, getCategoryLabel);

    series.dataFields.valueY = field;
    series.dataFields.categoryX = "label";

    // Make it stacked
    series.stacked = true;
    series.strokeWidth = 0.5;

    applySeriesColor(field, series, categoryMap);
    series.strokeOpacity = 0.6;
    series.fillOpacity = 0.6;

    // Configure columns
    series.columns.template.width = am4core.percent(45);

    series.columns.template.tooltipHTML = ``;
    series.columns.template.tooltipY = am4core.percent(50);

    return series;
}

interface CreateCashFlowChartParams
    extends CreateFilteredChartParams<ProfitLossReportDto> {
    reportCreatedAt: number;
    setCategoryFilter?: (categories: Array<Category>) => void;
}

function createChart({
    id,
    report,
    getCategoryLabel,
    filters,
    categoryMap,
    reportCreatedAt,
    setCategoryFilter,
}: CreateCashFlowChartParams) {
    const chart = am4core.create(id, am4charts.XYChart);

    chart.data = report.chart.data;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.fill = am4core.color("#515d71");
    categoryAxis.renderer.labels.template.adapter.add("textOutput", (label) =>
        printLabel(label, filters),
    );
    categoryAxis.renderer.minLabelPosition = 0.02;
    categoryAxis.renderer.maxLabelPosition = 0.98;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.stroke = am4core.color("#53647e");
    valueAxis.renderer.grid.template.opacity = 0.6;
    valueAxis.renderer.baseGrid.stroke = am4core.color("#53647e");
    valueAxis.renderer.baseGrid.opacity = 0.7;
    valueAxis.renderer.baseGrid.strokeWidth = 1.5;
    valueAxis.renderer.labels.template.fill = am4core.color("#515d71"); // gray-700
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.cursorTooltipEnabled = false;

    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "$#,###sa| -$#,###sa";

    const series = [];
    const reportSeries = difference(report.chart.series, [
        ProfitLossChartDataSeriesCalculatedIdentifier.DUMMY,
    ]);

    for (const sid in reportSeries) {
        series.push(
            generateDataSeries({
                chart: chart,
                field: reportSeries[sid],
                getCategoryLabel: getCategoryLabel,
                categoryMap: categoryMap,
            }),
        );
    }
    for (const seriesItem of series) {
        generateSingleCategoryTooltip(
            {
                chart,
                seriesToHookInto: seriesItem,
                filters,
            },
            reportCreatedAt,
        );
    }

    const columnSeries = series;
    for (const seriesItem of series) {
        if (setCategoryFilter) {
            seriesItem.columns.template.cursorOverStyle =
                am4core.MouseCursorStyle.pointer;
            seriesItem.columns.template.events.on("hit", function () {
                const category = seriesItem.dataFields.valueY!;

                if (category !== "other") {
                    const selectedCategory = categoryMap[category as Taxonomy];
                    setCategoryFilter([selectedCategory]);
                    return;
                }

                const categories: Array<Category> =
                    filters.category?.filter(
                        (cat) => cat.type === "category",
                    ) ?? Object.values(categoryMap);
                const shownCategories = columnSeries
                    .filter((item) => item !== seriesItem)
                    .map(
                        (item) =>
                            categoryMap[item.dataFields.valueY! as Taxonomy],
                    );
                const selectedTaxonomies = categories.filter((cat) =>
                    shownCategories.every((cat2) => cat2.id !== cat.id),
                );
                setCategoryFilter(selectedTaxonomies);
            });
        }
    }

    /* Style */
    chart.padding(30, 24, 12, 12);

    return chart;
}

interface Props {
    report: ProfitLossReportDto;
    filters: ReportFilters;
    reportCreatedAt: number;
    setCategoryFilter?: (categories: Array<Category>) => void;
}

export const CashFlowReportChart: React.FC<Props> = ({
    report,
    filters,
    reportCreatedAt,
    setCategoryFilter,
}) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();

    useEffect(() => {
        const chart = createChart({
            id: "cash-flow-chart",
            report: report,
            getCategoryLabel: getCategoryLabel,
            filters: filters,
            categoryMap,
            reportCreatedAt,
            setCategoryFilter,
        });
        return () => chart.dispose();
    }, [
        report,
        getCategoryLabel,
        filters,
        categoryMap,
        reportCreatedAt,
        setCategoryFilter,
    ]);
    return (
        <Card className="cash-flow-chart">
            <div id="cash-flow-chart" className="cash-flow-chart__chart"></div>
        </Card>
    );
};
