import React, { MutableRefObject, Ref, useEffect } from "react";
import "./FilterSearch.scss";
import classNames from "classnames";

export interface TransactionFilterSearchProps {
    value?: string;
    onChange(value: string): void;
    inputRef?: MutableRefObject<HTMLInputElement | undefined>;
    focus?: boolean;
    onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
    inputClassName?: string;
}

export const FilterSearch: React.FC<TransactionFilterSearchProps> = ({
    value = "",
    onChange,
    inputRef,
    focus,
    onFocus,
    onBlur,
    inputClassName,
}) => {
    useEffect(() => {
        if (focus) {
            // in timeout to prevent page scrolling up before the element is positioned
            setTimeout(() => inputRef?.current?.focus(), 0);
        }
    }, [focus, inputRef]);

    return (
        <div className="filter-search">
            <input
                data-testid="filter-search"
                className={classNames("form-control", inputClassName)}
                value={value}
                onChange={(ev) => onChange(ev.target.value)}
                placeholder="Search..."
                ref={inputRef as Ref<HTMLInputElement>}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    );
};
