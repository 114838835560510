import React from "react";
import { useMobileView } from "../../../hooks/useMobileView";
import { PageHeading } from "../PageHeading/PageHeading";
import styles from "./PageHeader.module.scss";
import classNames from "classnames";

interface PageHeaderProps {
    heading?: React.ReactNode;
    headingClassName?: string;
    actions?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
    heading,
    headingClassName,
    actions,
}) => {
    const isMobile = useMobileView();

    return (
        <header className={styles.header}>
            <PageHeading
                className={classNames(styles.heading, headingClassName)}
            >
                {heading}
            </PageHeading>

            {!isMobile && actions ? (
                <aside className={styles.actions} data-testid="header-actions">
                    {actions}
                </aside>
            ) : null}
        </header>
    );
};
