import React from "react";
import {
    Transaction,
    TransactionType,
} from "../../../common/types/transaction";
import "./TransactionInstitution.scss";
import { FinancialConnectionIcon } from "../../financialConnection/FinancialConnectionIcon";
import { getConnectionLabel } from "../../../common/helpers/financialConnection";
import { getLeadingAccountConnection } from "../../../common/helpers/financialAccount";

export interface TransactionInstitutionProps {
    transaction: Transaction;
}

export const TransactionInstitution: React.FC<TransactionInstitutionProps> = ({
    transaction,
}) => {
    if (transaction.type === TransactionType.IMPORTED) {
        return null;
    }

    const connection = getLeadingAccountConnection(
        transaction.financialAccount,
    );

    return connection ? (
        <div className="transaction-institution">
            <div className="transaction-institution__icon">
                {connection && (
                    <FinancialConnectionIcon
                        connection={connection}
                        account={transaction.financialAccount}
                    />
                )}
            </div>

            <div className="transaction-institution__name">
                {getConnectionLabel(connection)}
            </div>
        </div>
    ) : null;
};
