import React from "react";
import { useQuery } from "react-query";
import { fetchFinancialDocument } from "../../../lib/financialDocument";
import { financialDocumentQueryKeys } from "../lib";
import { FinancialDocumentDetails } from "./FinancialDocumentDetails";

interface Props {
    financialDocumentId: number;
}

export const FinancialDocumentDetailsContainer: React.FC<Props> = ({
    financialDocumentId,
}) => {
    const { data: financialDocument } = useQuery(
        financialDocumentQueryKeys.detail(financialDocumentId),
        () => fetchFinancialDocument(financialDocumentId),
    );

    if (!financialDocument) {
        return null;
    }

    return <FinancialDocumentDetails financialDocument={financialDocument} />;
};
