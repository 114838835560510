import {
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    isAfter,
    isBefore,
    isValid,
    parse,
    startOfDay,
} from "date-fns";
import { useMemo } from "react";
import { ReportingTabCycle } from "../../../common/constants";
import { applyReportFiltersToLabel } from "../../../common/helpers/reports";
import { useCashFlowContext } from "../cash-flow.context";
import { parseCellLabel } from "./cellLabels.helper";

export function useIsWithinBounds(label: string, isTitle: boolean) {
    const { cashFlowReport, cashFlowFiltersUsed } = useCashFlowContext();

    const meta = cashFlowReport?.meta;

    const startDate = useMemo(() => {
        if (!cashFlowFiltersUsed?.cycle || isTitle) {
            return null;
        }

        const parsed = applyReportFiltersToLabel(
            cashFlowFiltersUsed,
            parseCellLabel(label, cashFlowFiltersUsed.cycle),
        );
        return isValid(parsed) ? parsed : null;
    }, [cashFlowFiltersUsed, isTitle, label]);

    const endDate = useMemo(() => {
        if (!cashFlowFiltersUsed?.cycle || !startDate || isTitle) {
            return null;
        }

        return applyReportFiltersToLabel(
            cashFlowFiltersUsed,
            cashFlowFiltersUsed.cycle === ReportingTabCycle.MONTH
                ? endOfMonth(startDate)
                : endOfISOWeek(startDate),
        );
    }, [isTitle, cashFlowFiltersUsed, startDate]);

    return useMemo(() => {
        if (!meta?.dataStart || !startDate || !endDate || isTitle) {
            return true;
        }

        const { dataStart, dataEnd } = meta;

        const metaStartDate = startOfDay(
            parse(dataStart, "yyyy-MM-dd", new Date()),
        );
        const metaEndDate =
            dataEnd && endOfDay(parse(dataEnd, "yyyy-MM-dd", new Date()));

        if (!metaEndDate) {
            return !isBefore(startDate, metaStartDate);
        } else {
            return (
                !isBefore(startDate, metaStartDate) &&
                !isAfter(endDate, metaEndDate)
            );
        }
    }, [endDate, meta, startDate, isTitle]);
}
