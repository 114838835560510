import { FinancialDocument } from "../../common/types/financialDocument";
import { Entity } from "../../common/types/entity";

import { getMatchedTransaction } from "./lib";

export function useEffectiveEntity(
    financialDocument: FinancialDocument,
): Entity | null {
    const matchedTransaction = getMatchedTransaction(financialDocument);

    if (matchedTransaction) {
        return matchedTransaction.entity;
    }

    return financialDocument.entity;
}
