import React from "react";
import { format } from "date-fns";
import { LeftArrowIcon, RightArrowIcon } from "../../../../icons";
import Calendar, {
    CalendarProps,
    FormatterCallback,
    OnChangeDateRangeCallback,
    ViewCallback,
} from "react-calendar";
import classNames from "classnames";
import calendarStyles from "../Calendar.module.scss";

export interface DateRangeCalendarProps {
    maxDate: Date;
    calendarValue: CalendarProps["value"];
    changeDateRange: OnChangeDateRangeCallback;
    activeStart: Date;
    onActiveStartDateChange: ViewCallback;

    className?: string;
}

const monthFormatter: FormatterCallback = (_, date) => format(date, "MMM");

export const DateRangeCalendar: React.FC<DateRangeCalendarProps> = ({
    maxDate,
    calendarValue,
    changeDateRange,
    activeStart,
    onActiveStartDateChange,
    className,
}) => {
    return (
        <Calendar
            className={classNames(
                calendarStyles.dateRangePickerCalendar,
                className,
            )}
            maxDetail="year"
            minDetail="year"
            formatMonth={monthFormatter}
            prevLabel={<LeftArrowIcon />}
            nextLabel={<RightArrowIcon />}
            maxDate={maxDate}
            showDoubleView={true}
            value={calendarValue}
            onChange={changeDateRange}
            selectRange={true}
            activeStartDate={activeStart}
            goToRangeStartOnSelect={false}
            onActiveStartDateChange={onActiveStartDateChange}
        />
    );
};
