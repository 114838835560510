import React, { useState } from "react";
import { Field, Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { FormRow } from "../../../forms/FormRow";
import styles from "./Registration.module.scss";
import classNames from "classnames";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { submitHelper } from "../../../../helpers/form";
import { registerClient } from "../../../../lib/user";
import { passwordlessLogin } from "../../../../lib/auth0";
import { Button } from "react-bootstrap";
import { ResendCode } from "../../../general/CodeVerification/ResendCode";

interface Props {
    inviteCode: string;
}

interface FormSchema {
    email: string;
}

export const RegisterWithEmail: React.FC<Props> = ({ inviteCode }) => {
    const [touched, setTouched] = useState(false);
    const [linkSent, setLinkSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            email: "",
        },
        validationSchema: object().shape({
            email: string()
                .required("Email is required")
                .email("Provide a valid email"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ email }) => {
                await registerClient({
                    email,
                    inviteCode,
                });

                await passwordlessLogin(email);
                setLinkSent(true);
            },
        }),
    };

    return (
        <Formik {...form}>
            {({ isValid, values }) =>
                linkSent ? (
                    <div className={styles.submittedState}>
                        We just emailed {values.email}. Click the link in that
                        email to sign in.
                        <br />
                        <ResendCode
                            request={() => passwordlessLogin(values.email)}
                        >
                            {({ handleResend, resendBlocked }) => (
                                <Button
                                    variant="link"
                                    size="sm"
                                    onClick={handleResend}
                                    disabled={resendBlocked}
                                    className="p-0"
                                >
                                    {!resendBlocked ? (
                                        <>Resend email</>
                                    ) : (
                                        <>Email re-sent</>
                                    )}
                                </Button>
                            )}
                        </ResendCode>
                    </div>
                ) : (
                    <Form
                        className={classNames(styles.form, {
                            [styles.touched]: touched,
                        })}
                    >
                        <FormRow
                            fieldName="email"
                            className={styles.emailInput}
                        >
                            <Field
                                name="email"
                                className="form-control form-control-sm"
                                placeholder="Enter your email"
                                onFocus={() => setTouched(true)}
                            />
                        </FormRow>

                        <ButtonWithLoader
                            type="submit"
                            variant="secondary"
                            loading={loading}
                            disabled={!isValid}
                            className={styles.submitButton}
                        >
                            Continue
                        </ButtonWithLoader>
                    </Form>
                )
            }
        </Formik>
    );
};
