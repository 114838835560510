import { Transaction } from "../../common/types/transaction";
import { useContext } from "react";
import { TransactionsContext } from "./context/transactions.context";
import { usePotentialSavingsContext } from "./PotentialSavingsContext";
import { Entity } from "../../common/types/entity";
import { useMutation } from "react-query";

export function useChangeTransactionEntity(transaction: Transaction) {
    const { saveTransaction } = useContext(TransactionsContext);
    const { onTransactionUpdated: updateSavings } =
        usePotentialSavingsContext();

    return useMutation(
        async (entity: Entity) => {
            const previousEntity = transaction.entity;

            if (previousEntity !== entity) {
                return await saveTransaction(transaction, {
                    entityId: entity.id,
                });
            }
        },
        {
            onSuccess: (saved) => {
                if (saved) {
                    updateSavings(transaction, saved);
                }
            },
        },
    );
}
