import React, { useCallback, useRef, useState } from "react";
import { AlertIcon, TrashIcon } from "../../icons";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { EntityIcon } from "../entity/EntityIcon";
import { timeAgo } from "../../helpers/date";
import { updateConnectAccountState } from "../../reducers/appState";
import { useDispatch } from "react-redux";

import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { FixFinancialConnection } from "../financialAccount/FixFinancialConnection";
import { FinancialAccountManagementDropdown } from "../financialAccount/FinancialAccountManagementDropdown";
import "./DisplayIntegrationAccount.scss";
import { IntegrationAccount } from "../../common/types/integrationAccount";
import { RemoveIntegrationAccount } from "./RemoveIntegrationAccount";

interface Props {
    className?: string;
    connection: FinancialConnectionWithAccounts;
    integrationAccount: IntegrationAccount;
}

export const DisplayIntegrationAccount: React.FC<Props> = ({
    connection,
    integrationAccount,
    className,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();

    const addMissingInfo = useCallback(() => {
        dispatch(
            updateConnectAccountState({
                isConnecting: false,
                connection: connection,
                duplicates: [],
            }),
        );
    }, [dispatch, connection]);

    const el = useRef();

    let accountTooltipContent: React.ReactNode;

    if (connection.error) {
        accountTooltipContent = (
            <>
                This account has been disconnected. <br />
                {!!integrationAccount.balanceSyncedAt && (
                    <>
                        Last sync {timeAgo(integrationAccount.balanceSyncedAt)}.
                    </>
                )}
            </>
        );
    }

    const financialAccount = integrationAccount.financialAccount;

    return (
        <section
            ref={el as any}
            className={classNames(
                "integration-account",
                {
                    "integration-account--active": dropdownOpen,
                    "integration-account--disabled": financialAccount.disabled,
                },
                className,
            )}
        >
            <aside className="object-icon object-icon--sm mr-3">
                {financialAccount.entity && (
                    <EntityIcon entity={financialAccount.entity} />
                )}
            </aside>
            <main className="integration-account__content">
                <span>
                    <div>{financialAccount.name}</div>
                    {financialAccount.entity ? (
                        <small>
                            {financialAccount.isBusiness
                                ? "Business"
                                : "Personal"}
                        </small>
                    ) : (
                        <Button
                            variant="link"
                            className="btn-xs p-0 danger"
                            onClick={addMissingInfo}
                        >
                            <AlertIcon className="icon-color-red-600 mr-2" />
                            Add missing info
                        </Button>
                    )}
                </span>
            </main>

            {connection.error ? (
                <aside className="align-self-start">
                    <OverlayTrigger
                        trigger={
                            accountTooltipContent ? ["hover", "focus"] : []
                        }
                        container={el.current}
                        overlay={
                            <Tooltip
                                id={`account-hidden-${integrationAccount.id}`}
                            >
                                {accountTooltipContent}
                            </Tooltip>
                        }
                    >
                        <FixFinancialConnection
                            connection={connection}
                            variant="plain"
                            size="sm"
                            className="broken-connection-link danger"
                        >
                            <AlertIcon />
                            <span>Fix connection</span>
                        </FixFinancialConnection>
                    </OverlayTrigger>
                </aside>
            ) : null}

            <aside className="ml-auto integration-account__actions">
                {financialAccount.entity ? (
                    <FinancialAccountManagementDropdown
                        account={financialAccount}
                        connection={connection}
                        allowRemove
                        onToggle={setDropdownOpen}
                    />
                ) : (
                    <RemoveIntegrationAccount
                        account={integrationAccount}
                        variant="plain"
                    >
                        <TrashIcon />
                    </RemoveIntegrationAccount>
                )}
            </aside>
        </section>
    );
};
