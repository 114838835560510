import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Transaction } from "../../../common/types/transaction";
import { LinkIcon } from "../../../icons";
import "./TransferMatchLink.scss";

interface TransferMatchLinkProps {
    onClick?: (transaction: Transaction) => void;
    transaction: Transaction;
}
export const TransferMatchLink = ({
    onClick,
    transaction,
}: TransferMatchLinkProps) => {
    if (!onClick) {
        return (
            <button className="transfer-match-link transfer-match-link--readonly">
                <LinkIcon
                    width={12}
                    height={12}
                    className="icon-color-gray-700"
                />
            </button>
        );
    }
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`transfer-match-link-${transaction.id}`}>
                    Click to view or change the transfer match.
                </Tooltip>
            }
        >
            <button
                className="transfer-match-link"
                onClick={() => onClick?.(transaction)}
            >
                <LinkIcon
                    width={12}
                    height={12}
                    className="icon-color-gray-700"
                />
            </button>
        </OverlayTrigger>
    );
};
