import React, { useMemo } from "react";
import { Transaction } from "../../../common/types/transaction";
import { Col, Row } from "react-bootstrap";
import { TransactionDetailsField } from "./TransactionDetailsField";
import { TransactionDetailsSection } from "./TransactionDetailsSection";
import { format, getMinutes, getSeconds } from "date-fns";
import {
    fullDateFormat,
    fullDateFormatWithTime,
} from "../../../common/helpers/date";
import { getTransactionAccountNameWithLast4 } from "../../../common/helpers/transactions";
import { TransactionInstitution } from "./TransactionInstitution";
import { getLeadingAccountConnection } from "../../../common/helpers/financialAccount";

interface Props {
    transaction: Transaction;
}

export const TransactionDetailsMiscFields: React.FC<Props> = ({
    transaction,
}) => {
    const formattedDate = useMemo(() => {
        const formatter =
            getMinutes(transaction.date) || getSeconds(transaction.date)
                ? fullDateFormatWithTime
                : fullDateFormat;

        return format(transaction.date, formatter);
    }, [transaction.date]);
    const connection = getLeadingAccountConnection(
        transaction.financialAccount,
    );

    return (
        <TransactionDetailsSection title="Transaction details">
            <Row>
                {connection ? (
                    <Col md={12}>
                        <TransactionDetailsField
                            label="Institution"
                            value={
                                <TransactionInstitution
                                    transaction={transaction}
                                />
                            }
                        />
                    </Col>
                ) : null}

                <Col md={12}>
                    <TransactionDetailsField
                        label="Description"
                        value={transaction.description}
                    />
                </Col>

                {transaction.description !== transaction.bankDescription ? (
                    <Col md={12}>
                        <TransactionDetailsField
                            label="Bank description"
                            value={transaction.bankDescription}
                        />
                    </Col>
                ) : null}

                <Col md={12}>
                    <TransactionDetailsField
                        label="Date"
                        value={formattedDate}
                    />
                </Col>

                <Col md={12}>
                    <TransactionDetailsField
                        label="Account"
                        value={getTransactionAccountNameWithLast4(transaction)}
                    />
                </Col>
            </Row>
        </TransactionDetailsSection>
    );
};
