import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NavigationContainer } from "../navigation/NavigationContainer";
import { trackPage } from "../../lib/analytics";
import { Navigation } from "../navigation/Navigation";
import { MainInterfaceHeader } from "./MainInterfaceHeader";
import styles from "./MainInterface.module.scss";

interface Props {
    navigation?: React.ReactNode;
    header?: React.ReactNode;
}

export const MainInterface: React.FC<Props> = ({
    children,
    navigation = <Navigation />,
    header = <MainInterfaceHeader />,
}) => {
    const location = useLocation();
    useEffect(() => {
        trackPage(location.pathname);
    }, [location]);

    return (
        <>
            <NavigationContainer>{navigation}</NavigationContainer>
            <div className={styles.mainInterface}>
                {header}

                <main className={`${styles.container} ${styles.mainContainer}`}>
                    {children}
                </main>
            </div>
        </>
    );
};
