import React from "react";
import { User } from "../../../common/types/user";
import { ROLES_LABELS } from "../../../common/constants";
import { TrashIcon } from "../../../icons";
import { Button } from "react-bootstrap";
import { useUser } from "../../../hooks/useUser";
import { UserView } from "./UserView";
import { UserRole } from "../../../common/types/userEntityAssignment";
import { useEditMemberRoleMutation } from "../../../mutations/user";
import { useMemberLastActivity } from "./useMemberLastActivity";
import { useEntity } from "../../entity/useEntity";
import { without } from "lodash";
import { useIsAdmin } from "../../../hooks/useRoles";

const assignableRoles = without(
    Object.values(UserRole),
    UserRole.PRIMARY_ADMIN,
    UserRole.SUPPORT,
);

interface Props {
    user: User;
    role: UserRole;
    onRemove: () => void;
}

export const MemberRow: React.FC<Props> = ({
    user,
    role: currentRole,
    onRemove,
}) => {
    const entity = useEntity();
    const activeUser = useUser();
    const isAdmin = useIsAdmin();
    const editRole = useEditMemberRoleMutation(user, entity);
    const lastActivity = useMemberLastActivity(user);
    const canEditRole =
        isAdmin &&
        activeUser.id !== user.id &&
        currentRole !== UserRole.PRIMARY_ADMIN &&
        currentRole !== UserRole.SUPPORT;

    return (
        <tr>
            <td className="members__name">
                <UserView user={user} />
            </td>
            <td className="members__role">
                {canEditRole ? (
                    <select
                        className="form-control form-control-sm"
                        value={currentRole}
                        onChange={(e) =>
                            editRole.mutate(e.target.value as UserRole)
                        }
                    >
                        {assignableRoles.map((role) => (
                            <option key={role} value={role}>
                                {ROLES_LABELS[role]}
                            </option>
                        ))}
                    </select>
                ) : (
                    ROLES_LABELS[currentRole]
                )}
            </td>
            <td className="members__activity">{lastActivity}</td>
            <td className="members__remove">
                {isAdmin && activeUser.id !== user.id && (
                    <Button variant="link" onClick={onRemove}>
                        <TrashIcon />
                    </Button>
                )}
            </td>
        </tr>
    );
};
