import React from "react";
import { EmptyState } from "../general/EmptyState/EmptyState";

export type NoMatchingTransactionsProps = {
    onResetFilters: () => void;
};

export const NoMatchingTransactions: React.FC<NoMatchingTransactionsProps> = ({
    onResetFilters,
}) => {
    return (
        <EmptyState
            header="No matching transactions"
            body="The filters don’t match any of the transactions."
            ctaText="Reset all filters"
            action={onResetFilters}
        />
    );
};
