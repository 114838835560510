import React, { useEffect, useState } from "react";
import "./Register.scss";
import { Loader } from "../../general/Loader";
import { RegisterForm } from "./RegisterForm";
import { OnboardingStep } from "../OnboardingStep";
import { useRouteMatch } from "react-router-dom";
import { isInviteCodeValid } from "../../../lib/user";
import { useQuery } from "react-query";
import { SUGGESTED_EMAIL_KEY } from "../../../constants";
import { LoginWithGoogleButton } from "../../auth/GoogleAuth/LoginWithGoogleButton";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { HorizontalLine } from "../../general/HorizontalLine/HorizontalLine";
import { Col, Row } from "react-bootstrap";

interface Props {}

export const Register: React.FC<Props> = () => {
    const route = useRouteMatch<{ inviteCode: string }>();
    const [codeSent, setCodeSent] = useState(false);
    const [useEmail, setUseEmail] = useState(false);
    const { googleLogin } = useTypedFlags();

    const { data, isFetching } = useQuery(
        ["inviteCode", route.params.inviteCode],
        () => isInviteCodeValid(route.params.inviteCode),
        {
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        if (data?.email) {
            localStorage.setItem(SUGGESTED_EMAIL_KEY, data.email);
        }
    }, [data?.email]);

    let title: string;
    if (codeSent) {
        title = `Check your ${useEmail ? "email" : "phone"}`;
    } else if (data) {
        if (data.valid) {
            title = `What's your ${useEmail ? "email" : "phone number"}?`;
        } else {
            title = "Invite already used";
        }
    } else {
        title = "";
    }

    let content;
    if (isFetching) {
        content = <Loader />;
    } else if (data?.valid) {
        content = (
            <RegisterForm
                inviteCode={route.params.inviteCode}
                isInvite={data?.isInvite}
                onCodeSent={() => setCodeSent(true)}
                setUseEmail={setUseEmail}
            />
        );
    } else {
        content = <p>This invite link has already been used.</p>;
    }

    return (
        <OnboardingStep title={title}>
            {content}
            {googleLogin && (
                <Row>
                    <Col sm={4}>
                        <HorizontalLine className="my-3">or</HorizontalLine>

                        <LoginWithGoogleButton variant="secondary" block>
                            Sign up with Google
                        </LoginWithGoogleButton>
                    </Col>
                </Row>
            )}
        </OnboardingStep>
    );
};
