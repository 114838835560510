import { useCallback, useEffect, useMemo } from "react";
import { debounce, DebouncedFunc } from "lodash";

export function useDebouncedCallback<TCallback extends (...args: any) => any>(
    cb: TCallback,
    deps: any[],
    delay: number,
): DebouncedFunc<TCallback> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(cb, deps);
    const debounced = useMemo(
        () => debounce(callback, delay),
        [callback, delay],
    );

    useEffect(() => {
        return () => debounced.cancel();
    }, [debounced]);

    return debounced;
}
