import { BorderStyle, WorkSheet } from "@sheet/core";

export interface CellRange {
    startRow: number;
    startColumn: number;
    endRow?: number;
    endColumn?: number;
}

export const SHEET_ACCOUNTING_FORMAT_NO_CURRENCY =
    '_(* #,##0.00_);_(* \\(#,##0.00\\);_(* "-"??_);_(@_)';
export const SHEET_ACCOUNTING_FORMAT_WITH_CURRENCY =
    '_("$"* #,##0.00_);_("$"* \\(#,##0.00\\);_("$"* "-"??_);_(@_)';

export function disableGridLines(ws: WorkSheet) {
    ws["!gridlines"] = false;
}

// pass top-left cell of area that is not frozen
export function freezePane(ws: WorkSheet, topLeftCell: string) {
    ws["!freeze"] = topLeftCell;
}

export function getColumn(ws: WorkSheet, index: number) {
    if (!ws["!cols"]) {
        ws["!cols"] = [];
    }

    if (!ws["!cols"][index]) {
        ws["!cols"][index] = {};
    }

    return ws["!cols"][index];
}

export function getRow(ws: WorkSheet, index: number) {
    /* create !cols array if it does not exist */
    if (!ws["!rows"]) {
        ws["!rows"] = [];
    }

    /* create column metadata object if it does not exist */
    if (!ws["!rows"][index]) {
        ws["!rows"][index] = {};
    }

    return ws["!rows"][index];
}

export function mergeCells(ws: WorkSheet, range: CellRange) {
    if (!ws["!merges"]) {
        ws["!merges"] = [];
    }

    ws["!merges"].push({
        s: { r: range.startRow, c: range.startColumn },
        e: {
            r: range.endRow ?? range.startRow,
            c: range.endColumn ?? range.startColumn,
        },
    });
}

export function getCell(ws: WorkSheet, cellCords: string) {
    if (!ws[cellCords]) {
        throw new Error(`Cell ${cellCords} does not exist in the worksheet`);
    }

    return ws[cellCords];
}

export function getCellStyles(ws: WorkSheet, cellCords: string) {
    const cell = getCell(ws, cellCords);

    if (!cell.s) {
        cell.s = {};
    }

    return cell.s;
}

export function setFontSize(ws: WorkSheet, cellCords: string, size: number) {
    const cellStyles = getCellStyles(ws, cellCords);

    cellStyles.sz = size;
}

export function boldCell(ws: WorkSheet, cellCords: string) {
    const cellStyles = getCellStyles(ws, cellCords);

    cellStyles.bold = true;
}

export function addCellFormatting(
    ws: WorkSheet,
    cellCords: string,
    formatting: string,
) {
    const cell = getCell(ws, cellCords);

    cell.z = formatting;
}

interface BorderSpec {
    direction: "top" | "bottom" | "left" | "right";
    style: BorderStyle;
    // rgb color, defaults to 0x000000
    color?: number;
}

export function addCellBorder(
    ws: WorkSheet,
    cellCords: string,
    { direction, style, color }: BorderSpec,
) {
    const cellStyles = getCellStyles(ws, cellCords);

    cellStyles[direction] = {
        style,
        color: { rgb: color },
    };
}
