import React, { CSSProperties } from "react";
import classNames from "classnames";

export interface Props {
    className?: string;
    style?: CSSProperties;
    setNodeRef?: (node: HTMLElement | null) => void;
    entityOrAccount?: React.ReactNode;
    team?: React.ReactNode;
    lastSync?: React.ReactNode;
    balanceAndActions?: React.ReactNode;
}

export const EntityRow: React.FC<Props> = ({
    className,
    style,
    setNodeRef,
    entityOrAccount,
    team,
    lastSync,
    balanceAndActions,
}) => {
    return (
        <div
            className={classNames("entity__row", className)}
            style={style}
            ref={setNodeRef}
        >
            <div className="entity__row__name" data-testid="entity-row-name">
                {entityOrAccount}
            </div>
            <div className="entity__row__team" data-testid="entity-row-team">
                {team}
            </div>
            <div
                className="entity__row__last-sync"
                data-testid="entity-row-last-sync"
            >
                {lastSync}
            </div>
            <div
                className="entity__row__balance"
                data-testid="entity-row-balance"
            >
                {balanceAndActions}
            </div>
        </div>
    );
};
