import React from "react";
import { Entity } from "../../../common/types/entity";
import { AccountsChartEntityAccount } from "./AccountsChartEntityAccount";
import classNames from "classnames";
import { FinancialAccount } from "../../../common/types/financialAccount";

interface Props {
    entity: Entity;
    totalBalance: number;
    accounts: FinancialAccount[];
    proxy?: boolean;
}

export const AccountsChartEntityAccounts: React.FC<Props> = ({
    entity,
    totalBalance,
    accounts,
    proxy,
}) => {
    return (
        <div
            className={classNames("accounts-chart__entity__accounts", {
                "accounts-chart__entity__accounts--proxy": proxy,
            })}
        >
            {accounts.map((account) => (
                <AccountsChartEntityAccount
                    entity={entity}
                    account={account}
                    entityBalance={totalBalance}
                    key={account.id}
                    proxy={proxy}
                />
            ))}
        </div>
    );
};
