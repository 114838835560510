import React, { ReactNode } from "react";
import styles from "./FAQ.module.scss";
import { Collapsible } from "../Collapsible/Collapsible";

export interface Question {
    question: string;
    answer: ReactNode;
}

interface Props {
    questions: Question[];
}

export const Faq: React.FC<Props> = ({ questions }) => {
    return (
        <section>
            {questions.map(({ question, answer }) => (
                <Collapsible
                    key={question}
                    header={question}
                    className={styles.question}
                >
                    <div className="mb-3">{answer}</div>
                </Collapsible>
            ))}
        </section>
    );
};
