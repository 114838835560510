import React from "react";
import { SettingsSection } from "../../../SettingsSection";
import { CurrentYearPricingAndUsage } from "./CurrentYearPricingAndUsage";
import { useBillingStatus } from "../../../../../hooks/useBillingStatus";
import { CustomPricingBanner } from "../../CustomPricingBanner";
import { useAnnualBillingContext } from "../AnnualBilling.context";

export const UsageSection: React.FC = () => {
    const { pricingTiers } = useBillingStatus();
    const { pricingAndUsage } = useAnnualBillingContext();
    const lastTier = pricingTiers[pricingTiers.length - 1];

    let content;

    if (!pricingAndUsage) {
        return null;
    }

    if (pricingAndUsage?.currentYearTotalUsage > lastTier.end) {
        content = <CustomPricingBanner />;
    } else {
        content = <CurrentYearPricingAndUsage />;
    }

    return (
        <SettingsSection heading={<h4>Annual Usage</h4>}>
            {content}
        </SettingsSection>
    );
};
