import React, { forwardRef, PropsWithChildren, Ref } from "react";
import classNames from "classnames";
import { CopyOnClick } from "../CopyOnClick";
import "./BankNumber.scss";

export interface BankNumberProps {
    title?: React.ReactNode;
    value: string;
    className?: string;
    tooltipText?: string;
}

export const BankNumber = forwardRef<
    HTMLElement,
    PropsWithChildren<BankNumberProps>
>(({ children, title, value, className, ...other }, ref) => {
    return (
        <span ref={ref as Ref<any>}>
            <CopyOnClick
                className={classNames("bank-number", className)}
                value={value}
                {...other}
            >
                <div className="bank-number__number">{children ?? value}</div>
                {title && <div className="bank-number__title">{title}</div>}
            </CopyOnClick>
        </span>
    );
});
