import { PlaidConnectionErrors } from "../../constants";
import { ConnectionProviderType } from "../../types/financialConnection";
import { IntegrationAccount } from "../../types/integrationAccount";
import {
    PlaidConnection,
    PlaidConnectionWithAccounts,
} from "../../types/plaidConnection";
import { IntegrationAccountDto } from "../financialAccount/integration-account.dto";
import { UserDto } from "../user/user.dto";

function hideConnectionError(user: UserDto): boolean {
    return user?.email === process.env.DEMO_ACCOUNT_USER_EMAIL;
}

export class PlaidConnectionDto {
    static deserialize(dto: PlaidConnectionDto): PlaidConnectionWithAccounts {
        return {
            ...dto,
            createdAt: new Date(dto.createdAt),
            user: UserDto.deserialize(dto.user),
            accounts: dto.accounts.map(IntegrationAccountDto.deserialize),
        };
    }

    id: number;
    bankName: string;
    error: PlaidConnectionErrors | null;
    accounts: IntegrationAccountDto[];
    bankLogoUrl: string | null;
    institutionId: string | null;
    historicalDataProvidedManually: boolean;
    createdAt: string;
    user: UserDto;
    userId: number;
    connectionProvider: ConnectionProviderType;
    connectionExternalId: string;

    constructor(plaidConnection: PlaidConnection) {
        this.id = plaidConnection.id;
        this.bankName = plaidConnection.bankName;
        this.error = plaidConnection.error;
        this.accounts = (plaidConnection.accounts ?? []).map(
            (account: IntegrationAccount) => new IntegrationAccountDto(account),
        );
        this.bankLogoUrl = plaidConnection.bankLogoUrl;
        this.institutionId = plaidConnection.institutionId;
        this.historicalDataProvidedManually =
            plaidConnection.historicalDataProvidedManually;
        this.createdAt = plaidConnection.createdAt.toISOString();
        this.user = new UserDto(plaidConnection.user);
        this.userId = plaidConnection.userId;
        this.connectionProvider = plaidConnection.connectionProvider;
        this.connectionExternalId = plaidConnection.connectionExternalId;

        if (hideConnectionError(this.user)) {
            this.error = null;
        }
    }
}
