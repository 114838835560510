import React from "react";
import { useUser } from "../../../hooks/useUser";
import { BillingPageSubscribed } from "./BillingPageSubscribed";
import { BillingPageCustomPricing } from "./BillingPageCustomPricing";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { NotSubscribedView } from "./AnnualBilling/NotSubscribedView";
import { FlatRateBilling } from "./FlatRate/FlatRateBilling";
import { useBillingType } from "../../../hooks/useBillingType";
import { SubscriptionType } from "../../../common/types/billing";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { trackEvent } from "../../../lib/analytics";

export const BillingPage: React.FC = () => {
    const user = useUser();
    const { annualBilling } = useTypedFlags();
    const billingType = useBillingType();

    useEffectOnce(() => {
        void trackEvent("view_billing_tab");
    });

    let content;

    if (billingType === SubscriptionType.FLAT_RATE) {
        content = <FlatRateBilling />;
    } else if (user.isSubscribed) {
        content = <BillingPageSubscribed />;
    } else if (annualBilling) {
        content = <NotSubscribedView />;
    } else {
        content = <BillingPageCustomPricing />;
    }

    return (
        <div className="settings__billing billing">
            <header>
                <h3 className="settings__heading">Billing</h3>
            </header>

            {content}
        </div>
    );
};
