import React, { ReactNode, useMemo } from "react";
import { SettingsSection } from "../../../SettingsSection";
import { Collapsible } from "../../../../general/Collapsible/Collapsible";
import styles from "./FAQ.module.scss";
import { PricingExampleTable } from "./PricingExampleTable";
import { useUser } from "../../../../../hooks/useUser";
import { currencyFormatterShort } from "../../../../../common/helpers/currency";

interface Question {
    question: string;
    answer: ReactNode;
}

export const FAQSection: React.FC = () => {
    const user = useUser();
    const formattedFreeExpensesLimit = currencyFormatterShort
        .format(user.freeUsageLimit)
        .toLowerCase();

    const QUESTIONS: Question[] = useMemo(
        () => [
            {
                question: "How does pricing work?",
                answer: (
                    <>
                        <p>
                            Kick is free to start, with your first{" "}
                            {formattedFreeExpensesLimit} business expenses
                            covered. Once you hit {formattedFreeExpensesLimit},
                            you’ll automatically move up to the next tier the
                            following month.
                        </p>

                        <p>
                            Kick is billed annually with pricing scaling down (%
                            of expenses) as you grow.
                        </p>

                        <PricingExampleTable />
                    </>
                ),
            },
            {
                question: "Can Kick help me get last year’s bookkeeping done?",
                answer: `Yes! In the billing tab, select ‘last year’s bookkeeping’ and we’ll get that handled.`,
            },
            {
                question: "How do I pause or cancel my subscription?",
                answer: `You can update or pause your subscription on the Billing area of your Settings (the gear icon on the top right of your dashboard). You’ll still have access to all your data from before your pause date.`,
            },
            {
                question:
                    "Does my business expense allotment renew every year?",
                answer: (
                    <>
                        Each year, you'll stay on your current bookkeeping plan
                        until you exceed your annual allotment. If you need to
                        downgrade, reach out to{" "}
                        <a href="mailto:support@kick.co">support@kick.co</a> for
                        help.
                    </>
                ),
            },
            {
                question: "Can I delete my Kick account?",
                answer: `Yes, reach out to support@kick.co.`,
            },
        ],
        [formattedFreeExpensesLimit],
    );

    return (
        <SettingsSection heading="FAQ">
            {QUESTIONS.map(({ question, answer }) => (
                <Collapsible
                    key={question}
                    header={question}
                    className={styles.question}
                >
                    <div className="mb-3">{answer}</div>
                </Collapsible>
            ))}
        </SettingsSection>
    );
};
