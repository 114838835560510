import React from "react";
import { FormRow } from "../../forms/FormRow";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";

interface Props {
    fieldName: string;
    label?: string;
    className?: string;
}

export const TextMessageConsent: React.FC<Props> = ({
    fieldName,
    label = "I consent to receiving text messages from Kick",
    className,
}) => {
    return (
        <FormRow fieldName={fieldName}>
            {({ setValue }, { value }) => (
                <FormCheckbox
                    value={fieldName}
                    isChecked={value}
                    handleChange={() => setValue(!value)}
                    label={label}
                    className={className}
                    small
                />
            )}
        </FormRow>
    );
};
