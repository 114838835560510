import { useMemo } from "react";
import {
    getApplicableQuestions,
    TaxQuestion,
    taxQuestions,
} from "../../../common/taxSavingRules";
import { Category } from "../../../common/types/category";
import { TransactionsFilters } from "../filters/lib";
import { TaxAnswers } from "../TaxQuestions/types";
import { SelectedTransaction } from "./transactionsBulkActionsContext";
import { getTaxSavingRuleForSelection } from "./useTaxSavingRulesForBulkActions";

export function useTaxQuestionsForBulkActions(
    selection: Record<number, SelectedTransaction>,
    filters: TransactionsFilters | null,
    answers: TaxAnswers,
): TaxQuestion[] {
    return useMemo(() => {
        if (filters) {
            return getQuestionsForFilters(filters, answers);
        } else {
            return getQuestionsForSelection(selection, answers);
        }
    }, [answers, filters, selection]);
}

function getQuestionsForFilters(
    filters: TransactionsFilters,
    answers: TaxAnswers,
) {
    if (filters.category?.length !== 1) {
        return [];
    }

    const questionsForCategory =
        taxQuestions[2023][(filters.category[0] as Category).id];

    return getApplicableQuestions(questionsForCategory, answers);
}

function getQuestionsForSelection(
    selection: Record<number, SelectedTransaction>,
    answers: TaxAnswers,
) {
    const rules = getTaxSavingRuleForSelection(selection);

    if (!rules?.questions) {
        return [];
    }

    return getApplicableQuestions(rules.questions, answers);
}
