import { useCallback } from "react";
import { clearAccessToken, getWebAuth } from "../lib/auth0";
import { Store } from "../store";
import { clearAuthorization } from "../reducers/_commonActions";
import { useWorkspaceContext } from "../state/workspaceContext";
import { intercom } from "../lib/intercom";

export function useLogout() {
    const { changeActiveWorkspace } = useWorkspaceContext();

    return useCallback(() => {
        intercom("shutdown");
        clearAccessToken();
        getWebAuth().logout({ returnTo: window.location.origin });
        Store.dispatch(clearAuthorization());
        void changeActiveWorkspace(null);
    }, [changeActiveWorkspace]);
}
