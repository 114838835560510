import { noop } from "lodash";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useMobileView } from "../../hooks/useMobileView";

export interface NavigationContextProps {
    isOpen: boolean;
    open: () => void;
    close: () => void;
}

const NavigationContext = createContext<NavigationContextProps>({
    isOpen: false,
    open: noop,
    close: noop,
});

export function useNavigationContext() {
    const ctx = useContext(NavigationContext);

    if (!ctx) {
        throw new Error("Should only be used inside Navigation provider");
    }

    return ctx;
}

export const NavigationProvider: React.FC = ({ children }) => {
    const isMobile = useMobileView();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const open = useCallback(() => {
        if (!isMobile) {
            return;
        }

        setIsOpen(true);
    }, [isMobile]);

    const close = useCallback(() => {
        if (!isMobile) {
            return;
        }

        setIsOpen(false);
    }, [isMobile]);

    useEffect(() => {
        close();
    }, [close, location.pathname]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [isOpen]);

    const navigationContextValue = useMemo(
        () => ({
            isOpen,
            open,
            close,
        }),
        [close, isOpen, open],
    );

    return (
        <NavigationContext.Provider value={navigationContextValue}>
            {children}
        </NavigationContext.Provider>
    );
};
