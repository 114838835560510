import React from "react";
import { BankNumber } from "../general/BankNumber/BankNumber";
import { ACHIcon, WireIcon } from "../../icons";
import { EMPTY_STATE } from "../../common/constants";
import { FinancialAccount } from "../../common/types/financialAccount";

interface Props {
    account: FinancialAccount;
}

export const FinancialAccountRoutingNumbers: React.FC<Props> = ({
    account,
}) => {
    const routing = account.routingNumber;
    const wire = account.wireRoutingNumber;
    const showIcons = wire && wire !== routing;

    return routing ? (
        <>
            <BankNumber
                value={routing}
                tooltipText={showIcons ? "Click to copy for ACH" : undefined}
                className="ach"
            >
                {routing} {showIcons ? <ACHIcon /> : null}
            </BankNumber>
            {wire && wire !== routing && (
                <BankNumber
                    value={wire}
                    tooltipText="Click to copy for wire"
                    className="wire"
                >
                    {wire} {showIcons ? <WireIcon /> : null}
                </BankNumber>
            )}
        </>
    ) : (
        <>{EMPTY_STATE}</>
    );
};
