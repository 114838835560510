import React from "react";
import { Transaction } from "../../../common/types/transaction";
import { AutopilotCardTransaction } from "../AutopilotCardTransaction";
import { AutopilotCardSection } from "../AutopilotCardSection";

interface Props {
    inOpened?: boolean;
    style: any;
    transaction: Transaction;
}

export const AutopilotSavingsFirstSection: React.FC<Props> = ({
    transaction,
    ...rest
}) => {
    return (
        <AutopilotCardSection
            header="Was this transaction for business?"
            {...rest}
        >
            <AutopilotCardTransaction transaction={transaction} />
        </AutopilotCardSection>
    );
};
