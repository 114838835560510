import { Entity } from "./entity";
import { User } from "./user";

export enum UserRole {
    PRIMARY_ADMIN = "primary_admin",
    ADMIN = "admin",
    MEMBER = "member",
    SUPPORT = "support",
    ACCOUNTANT = "accountant",
}

export interface UserEntityAssignment {
    user: User;
    entity: Entity;
    role: UserRole;
}
