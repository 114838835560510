import { useCallback, useState } from "react";
import { getFinancialDocumentFile } from "../../../lib/financialDocument";
import { extension as getExtensionFromMime } from "mime-types";
import fileDownload from "js-file-download";
import { FinancialDocumentBase } from "../../../common/types/financialDocument";

export function useDownloadFinancialDocumentFile(
    financialDocument: FinancialDocumentBase,
) {
    const [downloading, setDownloading] = useState(false);

    const download = useCallback(async () => {
        try {
            setDownloading(true);

            const downloadResponse = await getFinancialDocumentFile(
                financialDocument.id,
            );

            if (!downloadResponse) {
                return;
            }

            const ext = getExtensionFromMime(downloadResponse.contentType);
            const filenameHasExtension = Boolean(
                ext && financialDocument.fileName.endsWith(ext),
            );

            const filenameForDownload = filenameHasExtension
                ? financialDocument.fileName
                : `${financialDocument.fileName}.${ext}`;

            fileDownload(
                downloadResponse.data,
                filenameForDownload,
                downloadResponse.contentType,
            );
        } finally {
            setDownloading(false);
        }
    }, [financialDocument.fileName, financialDocument.id]);

    return {
        download,
        downloading,
    };
}
