import { useUser } from "../../../hooks/useUser";
import { useMemo } from "react";

export function useWidgetOptions() {
    const { email, firstName, lastName, preferredName, legalName } = useUser();

    return useMemo(() => {
        return {
            prefill: {
                name: preferredName ?? legalName ?? `${firstName} ${lastName}`,
                email: email ?? "",
                firstName,
                lastName,
            },
            pageSettings: {
                primaryColor: "#1A78C0",
            },
        };
    }, [email, firstName, lastName, legalName, preferredName]);
}
