import React from "react";
import { Button, Modal } from "react-bootstrap";
import { OnSuccessAccount } from "../../../lib/plaidConnection";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { getAnonymousAccountNumber } from "../../../common/helpers/bankAccount";
import { ModalComponentProps } from "../../../helpers/modal";
import { FixPlaidConnection } from "../FixPlaidConnection";

interface Props extends ModalComponentProps {
    duplicates: OnSuccessAccount[];
    bankName: string;
    existingConnections: PlaidConnection[];
}

export const OnlyDuplicateAccountsConnectedModal: React.FC<Props> = ({
    duplicates,
    bankName,
    existingConnections,
    close,
}) => {
    const connectionsWithErrors = existingConnections.filter(
        (connection) => connection.error,
    );
    const singleConnectionWithError =
        connectionsWithErrors.length === 1
            ? connectionsWithErrors[0]
            : undefined;
    const hasErrors = connectionsWithErrors.length > 0;

    let errorsAndSupport;
    if (singleConnectionWithError) {
        errorsAndSupport = (
            <p>
                Your existing <strong>{bankName}</strong> connection is broken.
                Would you like to fix it?
            </p>
        );
    } else if (hasErrors) {
        errorsAndSupport = (
            <p>
                Some of your existing <strong>{bankName}</strong> connections
                are broken. Please fix them instead.
            </p>
        );
    } else {
        errorsAndSupport = (
            <p>If you have any questions, please contact support@kick.co</p>
        );
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Accounts already connected</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    It looks like you have the following{" "}
                    <strong>{bankName}</strong> accounts already connected to
                    Kick:
                </p>

                <ul>
                    {duplicates.map((duplicate) => (
                        <li key={duplicate.name + (duplicate.mask ?? "")}>
                            {duplicate.name}{" "}
                            {duplicate.mask
                                ? getAnonymousAccountNumber(duplicate.mask)
                                : ""}
                        </li>
                    ))}
                </ul>

                {errorsAndSupport}

                <footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={close} size="lg">
                        Go Back
                    </Button>
                    {singleConnectionWithError ? (
                        <FixPlaidConnection
                            connection={singleConnectionWithError}
                            onFixed={close}
                            size="lg"
                        >
                            Fix existing connection
                        </FixPlaidConnection>
                    ) : null}
                </footer>
            </Modal.Body>
        </>
    );
};
