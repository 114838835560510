import { uniq } from "lodash";
import { useMemo } from "react";
import { Taxonomy } from "../../../../../common/categories";
import { GenericCategory } from "../../../../../common/types/genericCategory";

/**
 * Returns list of taxonomies that need to be expanded so that
 * all selectedCategories can be visible
 */
export function useExpandParentCategory(
    selectedCategories: GenericCategory[] = [],
): Taxonomy[] {
    return useMemo(
        () =>
            uniq(
                selectedCategories
                    .map((selectedCategory) =>
                        selectedCategory.type === "category"
                            ? selectedCategory.parentCategoryId
                            : selectedCategory.mainCategoryId,
                    )
                    .filter((id): id is Taxonomy => id != null),
            ),
        [selectedCategories],
    );
}
