import React from "react";
import {
    EntityAuditLogValue,
    TransactionAuditLog,
} from "../../../../common/types/transactionAuditLog";
import { useAuditLogAuthorLabel } from "./useAuditLogAuthorLabel";
import { Taxonomy } from "../../../../common/categories";
import { useCategoryLabelGetter } from "../../../../hooks/useCategoryLabelGetter";
import { isValidTaxonomy } from "../../../../common/helpers/taxonomy";

interface Props {
    log: TransactionAuditLog;
    newValue: EntityAuditLogValue;
}

export const TransactionAuditLogEntityContent: React.FC<Props> = ({
    log,
    newValue,
}) => {
    const authorLabel = useAuditLogAuthorLabel(log);
    const categoryLabelGetter = useCategoryLabelGetter();
    const categoryLabel =
        newValue.category &&
        newValue.category !== Taxonomy.uncategorized &&
        newValue.category !== Taxonomy.personal &&
        isValidTaxonomy(newValue.category)
            ? categoryLabelGetter(newValue.category)
            : newValue.category;
    const categoryUpdateMessage = categoryLabel ? (
        <>
            {" "}
            and updated category to <strong>{categoryLabel}</strong>
        </>
    ) : (
        ""
    );

    if (newValue.isBusiness) {
        return (
            <>
                <strong>{authorLabel}</strong> moved transaction to{" "}
                <strong>{newValue.entityName}</strong> entity
                {newValue.classificationChanged ? (
                    <> and marked it as business</>
                ) : null}
                {categoryUpdateMessage}.
            </>
        );
    } else {
        return (
            <>
                <strong>{authorLabel}</strong> marked transaction as personal
                {categoryUpdateMessage}.
            </>
        );
    }
};
