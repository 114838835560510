import { Workspace } from "../../types/workspace";
import { EntityDto } from "../entity/entity.dto";

export interface UserWorkspacesResponse {
    workspaces: Workspace[];
}

export class UserWorkspacesResponseDto {
    static deserialize(
        data: UserWorkspacesResponseDto,
    ): UserWorkspacesResponse {
        return data;
    }
    workspaces: Workspace[];
    constructor(data: UserWorkspacesResponse) {
        this.workspaces = data.workspaces.map((workspace) => ({
            ...workspace,
            entities: workspace.entities?.map((entity) =>
                EntityDto.deserialize(new EntityDto(entity)),
            ),
        }));
    }
}
