import { Transaction } from "../../common/types/transaction";
import { useContext } from "react";
import { TransactionsContext } from "./context/transactions.context";
import { usePotentialSavingsContext } from "./PotentialSavingsContext";
import { useMutation } from "react-query";
import { Taxonomy } from "../../common/categories";

export function useChangeTransactionToPersonal(transaction: Transaction) {
    const { saveTransaction } = useContext(TransactionsContext);
    const { onTransactionUpdated: updateSavings } =
        usePotentialSavingsContext();

    return useMutation(
        async () => {
            const previousEntity = transaction.entity;

            if (!previousEntity.isPersonal) {
                return await saveTransaction(transaction, {
                    category: Taxonomy.personal,
                });
            }
        },
        {
            onSuccess: (saved) => {
                if (saved) {
                    updateSavings(transaction, saved);
                }
            },
        },
    );
}
