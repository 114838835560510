import React, { useEffect, useRef } from "react";
import "./styles/main.scss";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "./store";
import { Loader } from "./components/general/Loader";
import { PrivateInterface } from "./components/PrivateInterface";
import { Notifications } from "./components/general/Notifications";
import { PublicInterface } from "./components/PublicInterface";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { User } from "./common/types/user";
import { getEnv } from "./lib/environment";
import { NewVersionModal } from "./components/newVersion/NewVersionModal";
import { getLDUserKey } from "./common/helpers/featureFlags";
import { ToastMessagesProvider } from "./components/general/ToastMessages/ToastMessagesProvider";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { initApp } from "./lib";
import { useWorkspaceContext } from "./state/workspaceContext";

const getCypressOrRealUser = (user: User, kickEnv: string) => {
    if (window.hasOwnProperty("Cypress")) {
        return "e2e-test-user";
    }
    return getLDUserKey(kickEnv, user);
};

export const App: React.FC = () => {
    const { initialized, user, newVersionAvailable } = useSelector(
        (state: State) => state.appState,
    );
    const wasInitialized = useRef(false);

    useEffect(() => {
        if (wasInitialized.current) {
            return;
        }
        wasInitialized.current = true;

        initApp().catch((err) => {
            throw err;
        });
    }, []);

    const ldClient = useLDClient();
    const kickEnv = getEnv("KICK_ENV");

    useEffect(() => {
        if (user) {
            ldClient?.identify({
                key: getCypressOrRealUser(user, kickEnv),
                kind: "user",
                name: user.name,
                email: user.email,
                custom: {
                    createdAt: user.createdAt.toISOString(),
                },
            });
        }
    }, [user, ldClient, kickEnv]);

    const { activeWorkspaceKey } = useWorkspaceContext();

    const stateReloadingKey = [activeWorkspaceKey, user?.id].join("-");

    return initialized ? (
        <>
            <Notifications />
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                    <ToastMessagesProvider>
                        {user ? (
                            <PrivateInterface key={stateReloadingKey} />
                        ) : (
                            <PublicInterface />
                        )}
                    </ToastMessagesProvider>
                </QueryParamProvider>
            </BrowserRouter>
            <NewVersionModal show={newVersionAvailable} />
        </>
    ) : (
        <Loader />
    );
};
