import React from "react";
import { Modal } from "react-bootstrap";

import "./DisconnectFinancialAccountModal.scss";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { Loader } from "../../general/Loader";
import { UseMutationResult } from "react-query";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { StandardModal } from "../../general/Modal/Modal";

export interface DisconnectAccountModalProps {
    account: FinancialAccount;
    onDisable: UseMutationResult<unknown, unknown, void>;
    onRemove: UseMutationResult<unknown, unknown, void>;
    onCancel(): void;
    pending?: boolean;
}

export const DisconnectFinancialAccountModal: React.FC<
    DisconnectAccountModalProps
> = ({ account, pending, onCancel, onDisable, onRemove }) => {
    return (
        <StandardModal
            show
            className="disconnect-account-modal"
            onHide={onCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Remove from Kick?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="disconnect-account-modal__body">
                    This action will hide <strong>{account.name}</strong> from
                    Kick and prevent Kick from searching for tax deductions or
                    cash back savings. Do you also want to remove historical
                    data?
                </p>

                <footer className="disconnect-account-modal__actions">
                    {pending ? (
                        <Loader inline />
                    ) : (
                        <>
                            <ButtonWithLoader
                                variant="secondary"
                                mutation={onRemove}
                                disabled={onDisable.isLoading}
                                size="lg"
                            >
                                Yes, remove history
                            </ButtonWithLoader>
                            <ButtonWithLoader
                                variant="primary"
                                mutation={onDisable}
                                disabled={onRemove.isLoading}
                                size="lg"
                            >
                                No, keep history
                            </ButtonWithLoader>
                        </>
                    )}
                </footer>
            </Modal.Body>
        </StandardModal>
    );
};
