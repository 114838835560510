import React from "react";
import { ActiveFilterLabelProps } from "./lib";
import { pluralize } from "../../../../../common/helpers/string";

export const CategoryFilterLabel: React.FC<ActiveFilterLabelProps> = ({
    filters: { category },
}) => {
    if (category?.length === 1) {
        return <>{category[0].label}</>;
    }

    return (
        <>
            {category?.length} {pluralize("Category", category?.length ?? 0)}
        </>
    );
};
