import React from "react";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { AccountDetailsField } from "./AccountDetailsField";
import { getLeadingAccountConnection } from "../../../../common/helpers/financialAccount";

interface Props {
    account: FinancialAccount;
}
export const FinancialAccountInfo: React.FC<Props> = ({ account }) => {
    const connection = getLeadingAccountConnection(account);

    return (
        <AccountDetailsField title={`${connection?.connectionProvider} ID`}>
            {connection?.connectionExternalId}
        </AccountDetailsField>
    );
};
