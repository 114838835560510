import React, { useCallback, useMemo } from "react";
import { FinancialDocumentUploadDropzone } from "./FinancialDocumentUpload/FinancialDocumentUploadDropzone";
import classNames from "classnames";
import styles from "./DocumentsPage.module.scss";
import { FormCheckbox } from "../forms/FormCheckbox/FormCheckbox";
import { pluralize } from "../../common/helpers/string";
import { FinancialDocument } from "../../common/types/financialDocument";
import { DocumentTableRow } from "./DocumentTableRow";
import { useFinancialDocumentUpload } from "./FinancialDocumentUpload/useFinancialDocumentUpload";
import { UploadingPlaceholderRow } from "./UploadingPlaceholderRow";
import { Entity } from "../../common/types/entity";
import { useFinancialDocumentBulkSelection } from "./FinancialDocumentBulkSelection/useFinancialDocumentBulkSelection";
import { UploadIcon } from "../../icons";

interface FinancialDocumentsPageProps {
    emptyState: React.ReactNode;
    pagination: React.ReactNode;
    financialDocuments?: FinancialDocument[];
    total: number;
    openDetails: (financialDocument: FinancialDocument) => void;
    removeDocument: (financialDocument: FinancialDocument) => void;
    changeEntity: (
        financialDocument: FinancialDocument,
        entity: Entity,
    ) => void;
}

export const FinancialDocumentsPageBody: React.FC<
    FinancialDocumentsPageProps
> = ({
    emptyState,
    total,
    financialDocuments,
    pagination,
    openDetails,
    removeDocument,
    changeEntity,
}) => {
    const { uploadingDocuments } = useFinancialDocumentUpload();
    const bulkSelection = useFinancialDocumentBulkSelection();

    const allVisibleDocumentsSelected = useMemo(() => {
        return (
            bulkSelection.totalCount > 0 &&
            (bulkSelection.hasSelectedByCurrentFilters ||
                !!financialDocuments?.every((t) => bulkSelection.isSelected(t)))
        );
    }, [bulkSelection, financialDocuments]);

    const selectAllVisibleDocuments = useCallback(() => {
        if (!financialDocuments) {
            return;
        }

        if (allVisibleDocumentsSelected) {
            bulkSelection.deselect(financialDocuments);
        } else {
            bulkSelection.select(financialDocuments);
        }
    }, [allVisibleDocumentsSelected, bulkSelection, financialDocuments]);

    let body: React.ReactNode;

    if (emptyState) {
        body = emptyState;
    } else {
        body = (
            <tbody>
                {uploadingDocuments.map((placeholder) => (
                    <UploadingPlaceholderRow
                        key={placeholder.fileName}
                        row={placeholder}
                    />
                ))}

                {financialDocuments?.map((doc) => (
                    <DocumentTableRow
                        onOpenDetails={() => openDetails(doc)}
                        onRemoveDocument={() => removeDocument(doc)}
                        onChangeEntity={(newEntity) =>
                            changeEntity(doc, newEntity)
                        }
                        financialDocument={doc}
                        key={doc.id}
                    />
                ))}
            </tbody>
        );
    }

    return (
        <main>
            <FinancialDocumentUploadDropzone
                cover={
                    <>
                        <span className={styles.icon}>
                            <UploadIcon />
                        </span>
                        <p className={styles.uploadCta}>
                            Drop your files to upload
                        </p>
                    </>
                }
            >
                <table className={classNames("table", styles.table)}>
                    <thead>
                        <tr>
                            <th className={styles.narrow}>
                                <FormCheckbox
                                    value="page"
                                    isChecked={allVisibleDocumentsSelected}
                                    disabled={
                                        bulkSelection.hasSelectedByCurrentFilters
                                    }
                                    handleChange={selectAllVisibleDocuments}
                                    preventClickPropagation
                                    small
                                />
                            </th>
                            <th className={styles.dateColumn}>Upload date</th>
                            <th className={styles.wide}>Name</th>
                            <th className={styles.narrow}>Entity</th>
                            <th className={styles.narrow}>Status</th>
                            <th className={styles.actionsColumn}></th>
                        </tr>
                    </thead>

                    {emptyState ?? body}

                    <tfoot>
                        <tr>
                            <td colSpan={99}>
                                {total} {pluralize("document", total)}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </FinancialDocumentUploadDropzone>

            {pagination}
        </main>
    );
};
