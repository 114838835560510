import { createContext, Dispatch, SetStateAction } from "react";
import { Transaction } from "../../../common/types/transaction";
import { noop } from "../../../helpers/general";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";

interface Context {
    transactions?: Transaction[];
    setTransactions: Dispatch<SetStateAction<Transaction[] | undefined>>;
    saveTransaction: (
        transaction: Transaction,
        payload: UpdateTransactionDto,
    ) => Promise<Transaction | undefined>;
    upsertTransaction(transaction: Transaction): void;
    updateMany(transactions: Transaction[]): void;
    shownTransactionId?: number;
    setShownTransactionId: (t?: number) => void;
}

export const TransactionsContext = createContext<Context>({
    setTransactions: noop,
    upsertTransaction: noop,
    updateMany: noop,
    saveTransaction: async (t) => t,
    setShownTransactionId: noop,
});
