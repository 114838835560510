import React from "react";
import styles from "./EntityFilter.module.scss";
import { EntityIcon } from "../../entity/EntityIcon";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { Entity } from "../../../common/types/entity";

export interface EntityFilterItemProps {
    entity: Entity;
    isSelected: boolean;
    onToggle(entityId: number): void;
}

export const EntityFilterItem: React.FC<EntityFilterItemProps> = ({
    isSelected,
    entity,
    onToggle,
}) => {
    return (
        <FormCheckbox
            small
            value={entity.id}
            isChecked={isSelected}
            handleChange={() => onToggle(entity.id)}
            className={styles.item}
            label={
                <div className={styles.itemLabel}>
                    <EntityIcon entity={entity} size="sm" />
                    <span className="ml-2">{entity.name}</span>
                </div>
            }
        />
    );
};
