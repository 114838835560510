import React, { useState } from "react";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import "./DisplayPlaidConnection.scss";
import classNames from "classnames";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import {
    useAddPlaidAccountsMutation,
    useBankConnectionRemovalMutation,
} from "../../../mutations/plaidConnection";
import { Collapsible } from "../../general/Collapsible/Collapsible";
import { CaptureClicks } from "../../general/CaptureClicks";
import { ExternalConnectionSettingsDropdown } from "./PlaidConnectionSettingsDropdown/ExternalConnectionSettingsDropdown";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { DisplayPlaidConnectionHeader } from "./DisplayPlaidConnectionHeader";
import { ManualDataUploadPrompt } from "./ManualDataUploadPrompt";
import { useManualDataUpload } from "./useManualDataUpload";
import { StandardModal } from "../../general/Modal/Modal";
import { CollapsibleInfoBanner } from "../../general/Collapsible/CollapsibleInfoBanner";
import { pluralize } from "../../../common/helpers/string";
import { DisplayIntegrationAccount } from "../../integrationAccount/DisplayIntegrationAccount";

interface Props {
    connection: PlaidConnectionWithAccounts;
    numberOfNotVisibleAccounts: number;
}

export const DisplayPlaidConnection: React.FC<Props> = ({
    connection,
    numberOfNotVisibleAccounts,
}) => {
    const [showRemovalConfirmation, setShowRemovalConfirmation] =
        useState<boolean>(false);
    const connectionRemoval = useBankConnectionRemovalMutation(connection);
    const addAccounts = useAddPlaidAccountsMutation();

    const { isMissingData } = useManualDataUpload(connection);

    return (
        <Collapsible
            data-connection={connection.id}
            className={classNames("plaid-connection", {
                "plaid-connection--has-error": !!connection.error,
            })}
            actions={
                <CaptureClicks>
                    <ExternalConnectionSettingsDropdown
                        connection={connection}
                        onRemove={() => setShowRemovalConfirmation(true)}
                        busy={addAccounts.isLoading}
                    />
                </CaptureClicks>
            }
            header={<DisplayPlaidConnectionHeader connection={connection} />}
            footer={
                <ButtonWithLoader
                    loading={addAccounts.isLoading}
                    variant="secondary"
                    onClick={() => addAccounts.mutate(connection)}
                >
                    Connect another account
                </ButtonWithLoader>
            }
        >
            <>
                {isMissingData && (
                    <ManualDataUploadPrompt connection={connection} />
                )}
                {numberOfNotVisibleAccounts > 0 && (
                    <CollapsibleInfoBanner>
                        {numberOfNotVisibleAccounts}{" "}
                        {pluralize("account", numberOfNotVisibleAccounts)}{" "}
                        connected to entities you don't have access to.
                    </CollapsibleInfoBanner>
                )}
                {connection.accounts.map((account) => (
                    <DisplayIntegrationAccount
                        key={account.id}
                        connection={connection}
                        integrationAccount={account}
                    />
                ))}

                <StandardModal
                    show={showRemovalConfirmation}
                    onHide={() => setShowRemovalConfirmation(false)}
                >
                    <ConfirmationModal
                        title="Remove from Kick?"
                        question={
                            <>
                                This action will hide all{" "}
                                <strong>{connection.bankName}</strong> accounts
                                from Kick and prevent Kick from searching for
                                tax deductions or cash back savings.
                                <br />
                                <br />
                                Do you wish to continue?
                            </>
                        }
                        onConfirmMutation={connectionRemoval}
                        onReject={() => setShowRemovalConfirmation(false)}
                        yes="Confirm"
                        no="Cancel"
                    />
                </StandardModal>
            </>
        </Collapsible>
    );
};
