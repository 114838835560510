import { useMutation, useQueryClient } from "react-query";
import {
    createCategorizationRule,
    removeCategorizationRule,
    updateCategorizationRule,
} from "../lib/categorizationRules";
import { CreateCategorizationRuleDto } from "../common/dto/categorizationRule/create-categorization-rule.dto";
import { CATEGORIZATION_RULES_QUERY_KEY } from "../queries/categorizationRules";
import { TransactionCategorizationRule } from "../common/types/transactionCategorizationRule";
import { UpdateCategorizationRuleDto } from "../common/dto/categorizationRule/update-categorization-rule.dto";

export function useCategorizationRuleCreationMutation(onSuccess?: () => void) {
    const client = useQueryClient();

    return useMutation(
        (data: CreateCategorizationRuleDto) => createCategorizationRule(data),
        {
            onSuccess: (rule) => {
                client.setQueriesData<TransactionCategorizationRule[]>(
                    CATEGORIZATION_RULES_QUERY_KEY,
                    (data) => {
                        if (!data) {
                            return [rule];
                        }

                        return [...data, rule];
                    },
                );
                onSuccess?.();
            },
        },
    );
}

export function useCategorizationRuleUpdateMutation(
    rule: TransactionCategorizationRule,
    onSuccess?: () => void,
) {
    const client = useQueryClient();

    return useMutation(
        (data: UpdateCategorizationRuleDto) =>
            updateCategorizationRule(rule, data),
        {
            onSuccess: (updatedRule) => {
                client.setQueriesData<TransactionCategorizationRule[]>(
                    CATEGORIZATION_RULES_QUERY_KEY,
                    (data) => {
                        if (!data) {
                            return [updatedRule];
                        }

                        return data.map((r) =>
                            r.id === updatedRule.id ? updatedRule : r,
                        );
                    },
                );
                onSuccess?.();
            },
        },
    );
}

export function useCategorizationRuleRemovalMutation(
    rule: TransactionCategorizationRule,
    onSuccess?: () => void,
) {
    const client = useQueryClient();

    return useMutation(() => removeCategorizationRule(rule), {
        onSuccess: () => {
            client.setQueriesData<TransactionCategorizationRule[]>(
                CATEGORIZATION_RULES_QUERY_KEY,
                (data) => {
                    if (!data) {
                        return [];
                    }

                    return data.filter((r) => r.id !== rule.id);
                },
            );
            onSuccess?.();
        },
    });
}
