import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useUser } from "../../../../hooks/useUser";
import { Entity, EntityProfile } from "../../../../common/types/entity";
import {
    OnboardingStepKey,
    usePrimaryFlowSteps,
} from "../../usePrimaryFlowSteps";
import { UserInfo } from "../../steps/UserInfo";
import { UpdateUserDto } from "../../../../common/dto/user/update-user.dto";
import { useUpdateUserMutation } from "../../../../mutations/user";
import { AxiosError } from "axios";
import { addNotification } from "../../../../reducers/appState";
import { useDispatch } from "react-redux";
import { OnboardingLayout } from "../../OnboardingLayout";
import { PrimaryFlowNavigation } from "./PrimaryFlowNavigation";
import { PhoneNumber } from "../../steps/PhoneNumber";
import {
    confirmPhoneNumber,
    finishProfileSetup,
    requestPhoneNumberVerification,
    sendReceiptsIntro,
} from "../../../../lib/user";
import { VerifyPhoneNumber } from "../../steps/VerifyPhoneNumber";
import {
    BusinessProfile,
    BusinessProfileProps,
} from "../../steps/BusinessProfile";
import { getOnboardingFlow } from "../../getOnboardingFlow";
import { useEditEntityMutation } from "../../../../mutations/entity";
import { trackEvent } from "../../../../lib/analytics";
import { OnboardingFlowType } from "../../types";
import { Waitlist } from "../../steps/Waitlist";
import { WaitlistFinish } from "../../steps/WaitlistFinish";
import { FirmInfo, FirmInfoProps } from "../../steps/FirmInfo";
import { IntroCall } from "../../steps/IntroCall";
import { startEnterpriseTrial } from "../../../../lib/flatRateBilling";
import { PlanManagementProvider } from "../../../billing/PlanManagement/PlanManagementProvider";
import { FLAT_RATE_PLANS } from "../../../../common/flatRateBilling";
import { ChoosePlan } from "../../steps/ChoosePlan";
import {
    useFinancialAccounts,
    usePlaidFinancialAccounts,
} from "../../../../hooks/useFinancialAccounts";
import { ConnectAccounts } from "../../../onboarding/ConnectAccounts/ConnectAccounts";
import { usePromise } from "../../../../hooks/usePromise";
import { EntityProvider } from "../../../entity/EntityProvider";
import { ConfirmationModal } from "../../../general/ConfirmationModal";
import { Modal } from "react-bootstrap";
import { PaymentProcessor } from "../../steps/PaymentProcessor/PaymentProcessor";
import { Payroll } from "../../steps/Payroll";
import { ReceiptsStart } from "../../../onboarding/Receipts/ReceiptsStart";
import { ReceiptsFinish } from "../../../onboarding/Receipts/ReceiptsFinish";
import { Outro } from "../../../onboarding/Outro/Outro";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { Loader } from "../../../general/Loader";
import { useFetchAsset } from "../../../../hooks/useFetchAsset";
import { EXIT_ANIMATION_DURATION } from "../../constants";
import { useProcessorConnectionStatus } from "../../steps/PaymentProcessor/useProcessorConnectionStatus";

const STEPS_WITH_NO_SIDEBAR: OnboardingStepKey[] = [
    "waitlist",
    "waitlistFinish",
    "outro",
];

interface Props {
    entity: Entity;
    onFinish(): Promise<void>;
}

export const PrimaryOnboardingFlow: React.FC<Props> = ({
    entity,
    onFinish,
}) => {
    const user = useUser();
    const [providedPhoneNumber, setProvidedPhoneNumber] = useState<string>(
        user.phoneNumberDisplay ?? "",
    );
    const { isFetched: billingStatusFetched, isSubscribed } =
        useBillingStatus();

    // preload videos for later steps
    useFetchAsset("/images/receipts/receiptsStart.webm");
    useFetchAsset("/images/receipts/receiptsFinish.webm");

    const steps = usePrimaryFlowSteps(entity);
    const updateUserMutation = useUpdateUserMutation();
    const updateEntityMutation = useEditEntityMutation(entity);
    const plaidAccounts = usePlaidFinancialAccounts();
    const allFinancialAccounts = useFinancialAccounts();
    const dispatch = useDispatch();
    const flow = getOnboardingFlow(entity);

    const { hasAnyProcessorConnected } =
        useProcessorConnectionStatus(allFinancialAccounts);

    const [forcedStep, setForcedStep] = useState<
        OnboardingStepKey | undefined
    >();
    const [isExiting, setIsExiting] = useState(false);
    const timerRef = useRef<number>();

    const currentStep: OnboardingStepKey = useMemo(() => {
        if (forcedStep) {
            return forcedStep;
        }

        if (!user.preferredName) {
            return "userInfo";
        }

        if (!user.phoneNumber) {
            return "phoneNumber";
        }

        if (!entity.profile?.industry) {
            return "businessProfile";
        }

        if (flow === OnboardingFlowType.WAITLIST) {
            if (!entity.profile?.waitlistComment) {
                return "waitlist";
            }

            return "waitlistFinish";
        }

        if (flow === OnboardingFlowType.ACCOUNTANT) {
            if (!entity.profile?.firmSize) {
                return "firmInfo";
            }

            if (!user.bookedEnterpriseCall) {
                return "introCall";
            }
        }

        if (
            (!billingStatusFetched || !isSubscribed) &&
            !user.bookedEnterpriseCall
        ) {
            return "choosePlan";
        }

        if (
            !plaidAccounts?.length ||
            plaidAccounts.some((acc) => acc.isBusiness === null)
        ) {
            return "connectAccounts";
        }

        if (
            !hasAnyProcessorConnected &&
            !entity.profile?.paymentProcessorRequest
        ) {
            return "paymentProcessor";
        }

        if (!entity.profile?.payrollProviders?.length) {
            return "payroll";
        }

        return "receiptsStart";
    }, [
        forcedStep,
        user.preferredName,
        user.phoneNumber,
        user.bookedEnterpriseCall,
        entity.profile?.industry,
        entity.profile?.payrollProviders?.length,
        entity.profile?.waitlistComment,
        entity.profile?.firmSize,
        entity.profile?.paymentProcessorRequest,
        flow,
        billingStatusFetched,
        isSubscribed,
        plaidAccounts,
        hasAnyProcessorConnected,
    ]);

    const requiredDataLoaded = billingStatusFetched;

    useEffect(() => {
        if (requiredDataLoaded) {
            setForcedStep(currentStep); // prevents animation flicker if this is the first step after load
        }
    }, [currentStep, requiredDataLoaded]);

    const go = useCallback((stepKey: OnboardingStepKey) => {
        const next = stepKey;

        setIsExiting(true);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = window.setTimeout(() => {
            setForcedStep(next);
            setIsExiting(false);
        }, EXIT_ANIMATION_DURATION);
    }, []);

    const handleUserInfo = useCallback(
        async (update: UpdateUserDto) => {
            try {
                await updateUserMutation.mutateAsync(update);
                go("phoneNumber");
            } catch (e) {
                if (!(e as AxiosError).isAxiosError) {
                    dispatch(
                        addNotification({
                            type: "danger",
                            message: "Oops, something went wrong",
                        }),
                    );
                }
            }
        },
        [dispatch, go, updateUserMutation],
    );

    const handlePhoneNumberProvided = useCallback(
        async (phoneNumber: string) => {
            if (providedPhoneNumber !== phoneNumber) {
                await requestPhoneNumberVerification(phoneNumber);
                setProvidedPhoneNumber(phoneNumber);
                go("verifyPhoneNumber");
            } else {
                go("businessProfile");
            }
        },
        [go, providedPhoneNumber],
    );

    const handlePhoneNumberVerified = useCallback(async () => {
        await finishProfileSetup({
            phoneNumber: providedPhoneNumber!,
        });

        go("businessProfile");
    }, [go, providedPhoneNumber]);

    const handleEntityProfileUpdate = useCallback(
        async (payload: Partial<EntityProfile>) => {
            return await updateEntityMutation.mutateAsync({
                profile: {
                    ...entity.profile,
                    ...payload,
                },
            });
        },
        [entity.profile, updateEntityMutation],
    );

    const handleBusinessProfile: BusinessProfileProps["onSubmit"] = useCallback(
        async (name, profile) => {
            const updatedEntity = await handleEntityProfileUpdate(profile);

            await trackEvent("onboarding_business_profile_created", {
                entity_name: name,
                entity_type: profile.type,
                industry: profile.industry,
            });

            const flowForUpdatedEntity = getOnboardingFlow(updatedEntity);

            if (flowForUpdatedEntity === OnboardingFlowType.WAITLIST) {
                go("waitlist");
            } else if (flowForUpdatedEntity === OnboardingFlowType.ACCOUNTANT) {
                go("firmInfo");
            } else {
                go("choosePlan");
            }
        },
        [go, handleEntityProfileUpdate],
    );

    const handleWaitlist = useCallback(
        async (comment: string) => {
            await handleEntityProfileUpdate({ waitlistComment: comment });
            go("waitlistFinish");
        },
        [go, handleEntityProfileUpdate],
    );

    const handleFirmInfo: FirmInfoProps["onSubmit"] = useCallback(
        async (payload) => {
            await handleEntityProfileUpdate(payload);
            go("introCall");
        },
        [go, handleEntityProfileUpdate],
    );

    const handlePayrollProviders = useCallback(
        async (payrollProviders: string[]) => {
            await handleEntityProfileUpdate({
                payrollProviders,
            });
            go("receiptsStart");
        },
        [go, handleEntityProfileUpdate],
    );

    const handlePaymentProcessors = useCallback(
        async (requestedProcessors: string) => {
            await handleEntityProfileUpdate({
                paymentProcessorRequest: requestedProcessors,
            });
            go("payroll");
        },
        [go, handleEntityProfileUpdate],
    );

    const handleIntroCallBooked = useCallback(async () => {
        await startEnterpriseTrial();
        await updateUserMutation.mutateAsync({ bookedEnterpriseCall: true });

        go("connectAccounts");
    }, [go, updateUserMutation]);

    const handleOnboardingPaymentInitiated = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            void trackEvent("onboarding_payment_initiated", {
                plan,
            });
        },
        [],
    );

    const [showConnectAllAccountsReminder, setShowConnectAllAccountsReminder] =
        useState(false);

    const [reminderPromise, { resolve: confirmReminder }] = usePromise<void>();

    const beforeFirstConnection = useCallback(() => {
        if (plaidAccounts.length > 0) {
            confirmReminder();
        } else {
            setShowConnectAllAccountsReminder(true);
            reminderPromise.then(() =>
                setShowConnectAllAccountsReminder(false),
            );
        }

        return reminderPromise;
    }, [confirmReminder, plaidAccounts.length, reminderPromise]);

    const handleReminderConfirmed = useCallback(() => {
        setShowConnectAllAccountsReminder(false);
        confirmReminder();
    }, [confirmReminder]);

    const sendReceiptIntro = useCallback(() => {
        void sendReceiptsIntro();
        go("receiptsFinish");
    }, [go]);

    const handleReceiptsFinished = useCallback(() => {
        go("outro");
        void trackEvent("onboarding_receipts_matched");
    }, [go]);

    const connectAccountsBack = useMemo(() => {
        if (user.isSubscribed || user.bookedEnterpriseCall) {
            return undefined;
        } else if (flow === OnboardingFlowType.ACCOUNTANT) {
            return () => go("introCall");
        } else {
            return () => go("choosePlan");
        }
    }, [flow, go, user.bookedEnterpriseCall, user.isSubscribed]);

    let currentStepView: React.ReactNode;

    switch (currentStep) {
        case "userInfo":
            currentStepView = <UserInfo onSubmit={handleUserInfo} />;
            break;
        case "phoneNumber":
            currentStepView = (
                <PhoneNumber
                    onSubmit={handlePhoneNumberProvided}
                    initialPhoneNumber={providedPhoneNumber ?? ""}
                    back={() => go("userInfo")}
                />
            );
            break;
        case "verifyPhoneNumber":
            currentStepView = (
                <VerifyPhoneNumber
                    phoneNumber={providedPhoneNumber}
                    requestVerification={() =>
                        requestPhoneNumberVerification(providedPhoneNumber)
                    }
                    verifyCode={(code) =>
                        confirmPhoneNumber(providedPhoneNumber, code)
                    }
                    onSuccess={handlePhoneNumberVerified}
                    back={() => go("phoneNumber")}
                />
            );
            break;
        case "businessProfile":
            currentStepView = (
                <BusinessProfile
                    entity={entity}
                    back={() => go("phoneNumber")}
                    onSubmit={handleBusinessProfile}
                />
            );
            break;

        case "waitlist":
            currentStepView = (
                <Waitlist
                    entity={entity}
                    back={() => go("businessProfile")}
                    onSubmit={handleWaitlist}
                />
            );
            break;

        case "waitlistFinish":
            currentStepView = <WaitlistFinish back={() => go("waitlist")} />;
            break;

        case "firmInfo":
            currentStepView = (
                <FirmInfo
                    entity={entity}
                    back={() => go("businessProfile")}
                    onSubmit={handleFirmInfo}
                />
            );
            break;

        case "introCall":
            currentStepView = (
                <IntroCall
                    back={() => go("firmInfo")}
                    onCallBooked={handleIntroCallBooked}
                />
            );
            break;

        case "choosePlan":
            currentStepView = (
                <PlanManagementProvider
                    onBeforeUpgrade={handleOnboardingPaymentInitiated}
                >
                    <ChoosePlan
                        onContinue={() => go("connectAccounts")}
                        back={() => go("businessProfile")}
                    />
                </PlanManagementProvider>
            );
            break;

        case "connectAccounts":
            currentStepView = (
                <ConnectAccounts
                    onBack={connectAccountsBack}
                    onFinished={() => {
                        go("paymentProcessor");
                    }}
                    beforeConnect={beforeFirstConnection}
                    allowNoAccounts={
                        localStorage.getItem("kickTest:allowNoAccounts") ===
                        "true"
                    }
                />
            );
            break;

        case "paymentProcessor":
            currentStepView = (
                <PaymentProcessor
                    financialAccounts={allFinancialAccounts}
                    back={() => go("connectAccounts")}
                    onSubmit={handlePaymentProcessors}
                />
            );
            break;

        case "payroll":
            currentStepView = (
                <Payroll
                    back={() => go("paymentProcessor")}
                    onSubmit={handlePayrollProviders}
                    payrollProviders={entity.profile?.payrollProviders ?? []}
                />
            );
            break;

        case "receiptsStart":
            currentStepView = (
                <ReceiptsStart
                    onBack={() => go("payroll")}
                    onNext={sendReceiptIntro}
                />
            );
            break;

        case "receiptsFinish":
            currentStepView = (
                <ReceiptsFinish
                    onBack={() => go("receiptsStart")}
                    onNext={handleReceiptsFinished}
                />
            );
            break;

        case "outro":
            currentStepView = <Outro onFinished={onFinish} />;
            break;
    }

    if (!requiredDataLoaded) {
        return <Loader />;
    }

    return (
        <OnboardingLayout
            nav={
                !STEPS_WITH_NO_SIDEBAR.includes(currentStep) && (
                    <PrimaryFlowNavigation
                        currentStep={currentStep}
                        steps={steps}
                    />
                )
            }
            stepKey={currentStep}
            isExiting={isExiting}
        >
            {currentStepView}

            <EntityProvider entity={entity}>
                <Modal
                    show={showConnectAllAccountsReminder}
                    onHide={handleReminderConfirmed}
                >
                    <ConfirmationModal
                        closeButton
                        title="Connect all accounts"
                        question="To fully put your books on autopilot, we
                                    recommend you add all business and personal
                                    accounts to Kick."
                        onConfirm={handleReminderConfirmed}
                        yes="Got it!"
                    />
                </Modal>
            </EntityProvider>
        </OnboardingLayout>
    );
};
