import React, { useEffect, useState } from "react";
import styles from "./PlanManagement.module.scss";
import { Alert, Modal } from "react-bootstrap";
import classNames from "classnames";
import { PaymentElement } from "@stripe/react-stripe-js";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { Loader } from "../../general/Loader";
import { StripeError } from "@stripe/stripe-js";
import { Collapsible } from "../../general/Collapsible/Collapsible";
import { useBillingInfo } from "../../../hooks/useBillingInfo";
import { capitalize } from "lodash";
import { getAnonymousAccountNumber } from "../../../common/helpers/bankAccount";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { SelectedPaymentMethod } from "./lib";

interface Props {
    onConfirm(selectedPaymentMethod: SelectedPaymentMethod): Promise<void>;
    isLoading: boolean;
    busy: boolean;
    stripeError: StripeError | null;
    submitText: React.ReactNode;
    header: React.ReactNode;
}

export const ModalWithPaymentSetupContent: React.FC<Props> = ({
    onConfirm,
    isLoading,
    busy,
    stripeError,
    submitText,
    header,
    children,
}) => {
    const { data, isFetched } = useBillingInfo();
    const [paymentMethod, setPaymentMethod] = useState<SelectedPaymentMethod>(
        SelectedPaymentMethod.EXISTING,
    );

    useEffect(() => {
        if (isFetched && !data) {
            setPaymentMethod(SelectedPaymentMethod.NEW);
        }
    }, [data, isFetched]);

    let payment: React.ReactNode;

    if (!isFetched) {
        payment = <Loader />;
    } else if (data?.paymentMethod) {
        payment = (
            <>
                <Collapsible
                    className={styles.paymentCollapsible}
                    open={false}
                    header={
                        <FormCheckbox
                            small
                            label={
                                <>
                                    {capitalize(
                                        data.paymentMethod.brand.toLowerCase(),
                                    )}{" "}
                                    {getAnonymousAccountNumber(
                                        data.paymentMethod.last4,
                                        2,
                                        "",
                                    )}
                                </>
                            }
                            type="radio"
                            value={SelectedPaymentMethod.EXISTING}
                            isChecked={
                                paymentMethod === SelectedPaymentMethod.EXISTING
                            }
                            handleChange={setPaymentMethod}
                        />
                    }
                    stacked
                />
                <Collapsible
                    className={styles.paymentCollapsible}
                    header={
                        <FormCheckbox
                            small
                            label="Add payment method"
                            type="radio"
                            handleChange={setPaymentMethod}
                            value={SelectedPaymentMethod.NEW}
                            isChecked={
                                paymentMethod === SelectedPaymentMethod.NEW
                            }
                        />
                    }
                    stacked
                    last
                    open={paymentMethod === SelectedPaymentMethod.NEW}
                >
                    <PaymentElement />
                </Collapsible>
            </>
        );
    } else {
        payment = <PaymentElement />;
    }

    return (
        <>
            <Modal.Header closeButton />

            <Modal.Body
                className={classNames(styles.planManagement, {
                    [styles.loading]: isLoading,
                })}
            >
                <section className={styles.planManagementLayout}>
                    <main className={styles.details}>
                        <header className={styles.header}>{header}</header>

                        <section>{children}</section>
                    </main>
                    <aside className={styles.payment}>
                        <h3>Payment Details</h3>
                        {stripeError && (
                            <aside className="mb-4">
                                <Alert variant="danger">
                                    {stripeError.message}
                                </Alert>
                            </aside>
                        )}

                        <section className="mb-4">{payment}</section>
                        <ButtonWithLoader
                            loading={busy}
                            onClick={() => onConfirm(paymentMethod)}
                            variant="cta"
                            className="btn-block mt-auto"
                            data-testid="subscription-submit-button"
                        >
                            {submitText}
                        </ButtonWithLoader>
                    </aside>
                </section>

                <aside className={styles.loader}>
                    <Loader />
                </aside>
            </Modal.Body>
        </>
    );
};
