import { useContext } from "react";
import { financialDocumentUploadContext } from "./financialDocumentUploadContext";

export function useFinancialDocumentUpload() {
    const ctx = useContext(financialDocumentUploadContext);

    if (!ctx) {
        throw new Error(
            "useFinancialDocumentUpload must be used within a FinancialDocumentUploadProvider",
        );
    }

    return ctx;
}
