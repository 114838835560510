import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";

export interface FeaturesSection {
    title: string;
    features: string[];
}

export const detailedFeaturesSections = [
    {
        title: "Bookkeeping",
        features: [
            "Unlimited entities",
            "Unlimited feeds",
            "Unlimited members",
            "Auto-categorization",
            "Expert review",
            "Receipt matching",
            "Custom categories",
        ],
    },
    {
        title: "Accounting",
        features: [
            "Profit & loss",
            "Balance sheet",
            "General ledger",
            "Manual journal entry",
            "Bank reconciliation",
            "Accrual flexibility",
        ],
    },

    {
        title: "Insights",
        features: [
            "Cash flow insights",
            "Expense insights",
            "Revenue insights",
            "Weekly insights",
        ],
    },

    {
        title: "Services & Support",
        features: [
            "Service level",
            "Data access",
            "Review calls",
            "CPA onboarding",
            "CPA handoff",
            "1099 processing",
        ],
    },

    {
        title: "Admin & security",
        features: ["2FA", "Permissions", "Audit log"],
    },

    {
        title: "Entity support",
        features: [
            "Sole Prop / LLC",
            "Partnership / MMLLC",
            "S Corp",
            "C Corp",
        ],
    },
] as const satisfies FeaturesSection[];

export type DetailedFeatures =
    (typeof detailedFeaturesSections)[number]["features"][number];

export const highlightsPerPlan = {
    [FLAT_RATE_PLANS.FREE]: [
        "Profit & loss",
        "Auto-categorization",
        "Receipt matching",
    ],
    [FLAT_RATE_PLANS.BASIC]: ["Unlimited entities", "Deduction optimization"],
    [FLAT_RATE_PLANS.PLUS]: ["Balance sheet", "Custom categories"],
    [FLAT_RATE_PLANS.ENTERPRISE]: [
        "Accrual flexibility",
        "1099 processing",
        "CPA onboarding",
        "Dedicated support",
    ],
} as const satisfies Record<FLAT_RATE_PLANS, string[]>;

export type HighlightFeatures =
    (typeof highlightsPerPlan)[FLAT_RATE_PLANS][number];

export const detailedFeaturesByPlan: Record<
    FLAT_RATE_PLANS,
    Record<DetailedFeatures, string | boolean>
> = {
    [FLAT_RATE_PLANS.FREE]: {
        // Bookkeeping
        "Unlimited entities": false,
        "Unlimited feeds": true,
        "Unlimited members": true,
        "Auto-categorization": true,
        "Expert review": true,
        "Receipt matching": true,
        "Custom categories": false,

        // Accounting
        "Profit & loss": true,
        "Balance sheet": false,
        "General ledger": false,
        "Manual journal entry": false,
        "Bank reconciliation": false,
        "Accrual flexibility": false,

        // Insights
        "Cash flow insights": true,
        "Expense insights": false,
        "Revenue insights": false,
        "Weekly insights": true,

        // Services & Support
        "Service level": "Self Serve",
        "Data access": false,
        "Review calls": false,
        "CPA onboarding": false,
        "CPA handoff": false,
        "1099 processing": false,

        // Admin & security
        "2FA": true,
        Permissions: true,
        "Audit log": true,

        // Entity support
        "Sole Prop / LLC": true,
        "Partnership / MMLLC": true,
        "S Corp": false,
        "C Corp": false,
    },
    [FLAT_RATE_PLANS.BASIC]: {
        // Bookkeeping
        "Unlimited entities": true,
        "Unlimited feeds": true,
        "Unlimited members": true,
        "Auto-categorization": true,
        "Expert review": true,
        "Receipt matching": true,
        "Custom categories": false,

        // Accounting
        "Profit & loss": true,
        "Balance sheet": false,
        "General ledger": false,
        "Manual journal entry": false,
        "Bank reconciliation": false,
        "Accrual flexibility": false,

        // Insights
        "Cash flow insights": true,
        "Expense insights": true,
        "Revenue insights": false,
        "Weekly insights": true,

        // Services & Support
        "Service level": "Basic",
        "Data access": false,
        "Review calls": false,
        "CPA onboarding": false,
        "CPA handoff": false,
        "1099 processing": false,

        // Admin & security
        "2FA": true,
        Permissions: true,
        "Audit log": true,

        // Entity support
        "Sole Prop / LLC": true,
        "Partnership / MMLLC": true,
        "S Corp": true,
        "C Corp": false,
    },
    [FLAT_RATE_PLANS.PLUS]: {
        // Bookkeeping
        "Unlimited entities": true,
        "Unlimited feeds": true,
        "Unlimited members": true,
        "Auto-categorization": true,
        "Expert review": true,
        "Receipt matching": true,
        "Custom categories": true,

        // Accounting
        "Profit & loss": true,
        "Balance sheet": true,
        "General ledger": true,
        "Manual journal entry": true,
        "Bank reconciliation": true,
        "Accrual flexibility": false,

        // Insights
        "Cash flow insights": true,
        "Expense insights": true,
        "Revenue insights": true,
        "Weekly insights": true,

        // Services & Support
        "Service level": "Priority",
        "Data access": true,
        "Review calls": false,
        "CPA onboarding": false,
        "CPA handoff": false,
        "1099 processing": false,

        // Admin & security
        "2FA": true,
        Permissions: true,
        "Audit log": true,

        // Entity support
        "Sole Prop / LLC": true,
        "Partnership / MMLLC": true,
        "S Corp": true,
        "C Corp": false,
    },
    [FLAT_RATE_PLANS.ENTERPRISE]: {
        // Bookkeeping
        "Unlimited entities": true,
        "Unlimited feeds": true,
        "Unlimited members": true,
        "Auto-categorization": true,
        "Expert review": true,
        "Receipt matching": true,
        "Custom categories": true,

        // Accounting
        "Profit & loss": true,
        "Balance sheet": true,
        "General ledger": true,
        "Manual journal entry": true,
        "Bank reconciliation": true,
        "Accrual flexibility": true,

        // Insights
        "Cash flow insights": true,
        "Expense insights": true,
        "Revenue insights": true,
        "Weekly insights": true,

        // Services & Support
        "Service level": "Dedicated",
        "Data access": true,
        "Review calls": true,
        "CPA onboarding": true,
        "CPA handoff": true,
        "1099 processing": true,

        // Admin & security
        "2FA": true,
        Permissions: true,
        "Audit log": true,

        // Entity support
        "Sole Prop / LLC": true,
        "Partnership / MMLLC": true,
        "S Corp": true,
        "C Corp": true,
    },
};

export const featureDescriptions: Record<DetailedFeatures, string | null> = {
    "Unlimited entities": "High-level view across all your entities",
    "Unlimited feeds":
        "Securely connect all your bank accounts and credit cards",
    "Unlimited members": "Team, Partner and Accountant permissions",
    "Auto-categorization": "Business transactions categorized in real-time",
    "Expert review": "Business transactions reviewed by an expert for accuracy",
    "Receipt matching": "Automated receipt matching over text and email",
    "Custom categories":
        "Custom categories for detailed revenue and expense breakdowns",

    "Profit & loss": "On demand Profit & loss (Modified cash basis)",
    "Balance sheet":
        "Balance sheet to share with your Accountant available upon request",
    "General ledger": "General ledger drill down to share with your Accountant",
    "Manual journal entry": "Manual journal entry creation for your Accountant",
    "Bank reconciliation": "Match bank activity with your financial records",
    "Accrual flexibility": "Year-end adjustment for Accrual Basis tax filing ",

    "Cash flow insights": "Real-time breakdown of profitability",
    "Expense insights": "Real-time breakdown of business spend",
    "Revenue insights": "Real-time breakdown of revenue streams",
    "Weekly insights": "Weekly and monthly financial insights emails",

    "Service level": null,
    "Data access": null,
    "Review calls": "Financial review calls available on request",
    "CPA onboarding": "Get onboarded by a CPA",
    "CPA handoff":
        "Annual financial statement hand off call with your Accountant",
    "1099 processing": "1099s process for your Contractors",

    "2FA": "Enhanced security to protect your financial information",
    Permissions: "Control access for team Members, Partners and Accountants",
    "Audit log": "Track Member login activity",

    "Sole Prop / LLC": "Support for Sole Proprietorship and LLCs",
    "Partnership / MMLLC": "Support for Partnerships and Multi-Member LLCs",
    "S Corp": "Support for S Corporations",
    "C Corp": "Support for C Corporations",
};

export const highlightDescriptions: Record<HighlightFeatures, string | null> = {
    "Unlimited entities": "High-level view across all your entities",
    "Auto-categorization": "Business transactions categorized in real-time",
    "Receipt matching": "Automated receipt matching over text and email",
    "Custom categories":
        "Custom categories for detailed revenue and expense breakdowns",

    "Profit & loss": "On demand Profit & loss (Modified cash basis)",
    "Balance sheet":
        "Balance sheet to share with your Accountant available upon request",
    "Accrual flexibility": "Year-end adjustment for Accrual Basis tax filing ",

    "CPA onboarding": "Get onboarded by a CPA",
    "1099 processing": "1099s process for your Contractors",
    "Dedicated support": null,
    "Deduction optimization":
        "Maximize write offs and stay compliant with IRS compliance",
};
