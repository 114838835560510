import React, { forwardRef, PropsWithChildren } from "react";
import classNames from "classnames";
import css from "./Card.module.scss";
import { animated } from "@react-spring/web";

interface Props {
    className?: string;
    onClick?: () => void;
    style?: any;
}
export const AnimatedCard = forwardRef<
    HTMLDivElement,
    PropsWithChildren<Props>
>(({ children, className, style, ...otherProps }, ref) => {
    return (
        <animated.div
            ref={ref}
            className={classNames(css.card, className)}
            style={style}
            {...otherProps}
        >
            {children}
        </animated.div>
    );
});
