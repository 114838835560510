import { UpdateProfileDto } from "../common/dto/user/update-profile.dto";
import { backendClient, unwrapResponse } from "./backendClient";
import {
    UserStatusResponse,
    UserStatusResponseDto,
} from "../common/dto/onboarding/user-status-response.dto";
import { InviteCodeValidResponseDto } from "../common/dto/user/invite-code-valid-response.dto";
import { RegisterUserDto } from "../common/dto/user/register-user.dto";
import { AddAdditionalEmailDto } from "../common/dto/user/add-additional-email.dto";
import { AdditionalEmail } from "../common/types/additionalEmail";
import { User } from "../common/types/user";
import { GetAdditionalEmailsResponseDto } from "../common/dto/user/get-additional-emails-response.dto";
import { InviteMemberDto } from "../common/dto/user/invite-member.dto";
import { InviteCode } from "../common/types/inviteCode";
import { EditRoleDto } from "../common/dto/user/edit-role.dto";
import { Entity } from "../common/types/entity";
import { GetMembersForEntitiesResponseDto } from "../common/dto/user/get-members-for-entities-response.dto";
import { UserDto } from "../common/dto/user/user.dto";
import {
    UserWorkspacesResponse,
    UserWorkspacesResponseDto,
} from "../common/dto/onboarding/user-workspaces-response.dto";
import {
    UserEntitiesResponse,
    UserEntitiesResponseDto,
} from "../common/dto/onboarding/user-entities-response.dto";
import { OnboardingPersonalInfoDto } from "../common/dto/user/onboarding-personal-info.dto";
import { AxiosError } from "axios";
import { UserIntegrationsResponseDto } from "../common/dto/user/user-integrations-response.dto";
import { HandleGoogleAuthDto } from "../common/dto/user/handle-google-auth.dto";
import { RequestPhoneNumberVerificationDto } from "../common/dto/phoneNumber/request-phone-number-verification.dto";
import { VerifyPhoneNumberDto } from "../common/dto/phoneNumber/verify-phone-number.dto";
import { OnboardingFinishProfileDto } from "../common/dto/user/onboarding-finish-profile.dto";
import { VerifyPhoneNumberResponseDto } from "../common/dto/phoneNumber/verify-phone-number.response.dto";

export async function getUserStatus(): Promise<UserStatusResponse | void> {
    try {
        const response: UserStatusResponseDto = unwrapResponse(
            await backendClient.get(`/user/status`),
        );

        return UserStatusResponseDto.deserialize(response);
    } catch (err) {
        // ignore error
    }
}

export class TeamNotFoundError extends Error {
    constructor() {
        super("Team not found");
    }
}

export async function getUserEntities(
    activeWorkspaceId: string,
): Promise<UserEntitiesResponse | void> {
    try {
        const response: UserEntitiesResponseDto = unwrapResponse(
            await backendClient.get(
                `/user/workspaces/${activeWorkspaceId}/entities`,
            ),
        );

        return UserEntitiesResponseDto.deserialize(response);
    } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
            throw new TeamNotFoundError();
        }
        // ignore other errors
    }
}

export async function getUserWorkspaces(
    withEntities: boolean,
): Promise<UserWorkspacesResponse | void> {
    try {
        const response: UserWorkspacesResponseDto = unwrapResponse(
            await backendClient.get(`/user/workspaces/`, {
                params: { withEntities: withEntities },
            }),
        );

        return UserWorkspacesResponseDto.deserialize(response);
    } catch (err) {
        // ignore error
    }
}

export async function isInviteCodeValid(
    code: string,
): Promise<InviteCodeValidResponseDto> {
    return unwrapResponse(
        await backendClient.get(`/user/onboarding/invite-code-valid/${code}`),
    );
}

export async function registerClient(data: RegisterUserDto): Promise<void> {
    return unwrapResponse(
        await backendClient.post("/user/onboarding/register", data),
    );
}

export async function updateProfile(data: UpdateProfileDto): Promise<User> {
    return UserDto.deserialize(
        unwrapResponse(await backendClient.patch("/user/profile", data)),
    );
}

export async function getAdditionalEmailsForUser(): Promise<GetAdditionalEmailsResponseDto> {
    return unwrapResponse(
        await backendClient.get("/user/profile/additional-email"),
    );
}

export async function addAdditionalEmail(
    data: AddAdditionalEmailDto,
): Promise<AdditionalEmail> {
    return unwrapResponse(
        await backendClient.post("/user/profile/additional-email", data),
    );
}

export async function removeAdditionalEmail(email: string): Promise<void> {
    return await backendClient.delete(
        `/user/profile/additional-email/${email}`,
    );
}

export async function completeOnboarding(): Promise<User> {
    return UserDto.deserialize(
        unwrapResponse(await backendClient.post(`/user/onboarding/complete`)),
    );
}

export async function updatePersonalInfo(
    data: OnboardingPersonalInfoDto,
): Promise<User> {
    return UserDto.deserialize(
        unwrapResponse(
            await backendClient.post(`/user/onboarding/personal-info`, data),
        ),
    );
}

export async function addMember(data: InviteMemberDto): Promise<void> {
    return unwrapResponse(await backendClient.post("/member/invite", data));
}

export async function resendInvite(invite: InviteCode): Promise<void> {
    return await backendClient.post(`/member/resend-invite/${invite.id}`);
}

export async function removeMember(user: User, entity: Entity): Promise<void> {
    return await backendClient.delete(`/member/${user.id}`, {
        params: { entityId: entity.id },
    });
}

export async function editMemberRole(
    user: User,
    data: EditRoleDto,
): Promise<void> {
    return await backendClient.patch(`/member/${user.id}/role`, data);
}

export async function getMembersForEntities(
    entities: Entity[],
): Promise<GetMembersForEntitiesResponseDto> {
    return unwrapResponse(
        await backendClient.get("/member", {
            params: { entities: entities.map((e) => e.id) },
        }),
    );
}

export async function sendReceiptsIntro() {
    await backendClient.post("/user/onboarding/receipts-intro");
}

export async function getUserIntegrations() {
    const dto: UserIntegrationsResponseDto = unwrapResponse(
        await backendClient.get("/user/integrations"),
    );

    return UserIntegrationsResponseDto.deserialize(dto);
}

export async function handleGoogleAuth(data: HandleGoogleAuthDto) {
    return unwrapResponse(await backendClient.post("/google-auth", data));
}

export async function requestPhoneNumberVerification(phoneNumber: string) {
    await backendClient.post("/user/onboarding/request-phone-verification", {
        phone: phoneNumber,
    } satisfies RequestPhoneNumberVerificationDto);
}

export async function confirmPhoneNumber(
    phoneNumber: string,
    code: string,
): Promise<VerifyPhoneNumberResponseDto> {
    return unwrapResponse(
        await backendClient.post("/user/onboarding/verify-phone", {
            phone: phoneNumber,
            code,
        } satisfies VerifyPhoneNumberDto),
    );
}

export async function finishProfileSetup(data: OnboardingFinishProfileDto) {
    return UserDto.deserialize(
        unwrapResponse(
            await backendClient.post(
                `/user/onboarding/finish-profile-setup`,
                data,
            ),
        ),
    );
}
