import { ModalComponentProps } from "../../helpers/modal";
import { Button, Modal } from "react-bootstrap";
import React from "react";

export const AccountTypeChangeForbiddenModal: React.FC<ModalComponentProps> = ({
    close,
}) => {
    return (
        <>
            <Modal.Header closeButton>
                <h3>Account type change forbidden</h3>
            </Modal.Header>
            <Modal.Body>
                <p className="text-prominent mb-5">
                    You can't change the account type of this account to
                    personal.
                </p>
                <footer className="d-flex justify-content-between">
                    <Button
                        variant="secondary"
                        size="lg"
                        onClick={() => close()}
                    >
                        Close
                    </Button>
                </footer>
            </Modal.Body>
        </>
    );
};
