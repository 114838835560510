import { useMemo } from "react";
import { Entity } from "../../common/types/entity";
import { getOnboardingFlow } from "./getOnboardingFlow";
import { OnboardingFlowType } from "./types";

export function usePrimaryFlowSteps(entity?: Entity) {
    const flow = getOnboardingFlow(entity);

    return useMemo(() => {
        const businessProfile = {
            label: "Business profile",
            steps: [
                "userInfo",
                "phoneNumber",
                "verifyPhoneNumber",
                "businessProfile",
            ],
        } as const;

        const choosePlan = {
            label: "Choose plan",
            steps: ["choosePlan"],
        } as const;

        const connectAccounts = {
            label: "Connect accounts",
            steps: ["connectAccounts", "paymentProcessor", "payroll"],
        } as const;

        const matchReceipts = {
            label: "Match receipts",
            steps: ["receiptsStart", "receiptsFinish"],
        } as const;

        const firmInfo = {
            label: "Firm info",
            steps: ["firmInfo"],
        } as const;

        const introCall = {
            label: "Intro call",
            steps: ["introCall"],
        } as const;

        const waitlist = {
            label: "Waitlist",
            steps: ["waitlist", "waitlistFinish"],
        } as const;

        const outro = {
            label: "Start autopilot",
            steps: ["outro"],
        } as const;

        switch (flow) {
            case OnboardingFlowType.ACCOUNTANT:
                return [
                    businessProfile,
                    firmInfo,
                    introCall,
                    connectAccounts,
                    matchReceipts,
                    outro,
                ];
            case OnboardingFlowType.WAITLIST:
                return [businessProfile, waitlist];
            case OnboardingFlowType.SELF_SERVE:
                return [
                    businessProfile,
                    choosePlan,
                    connectAccounts,
                    matchReceipts,
                    outro,
                ];
        }
    }, [flow]);
}

export type OnboardingStep = ReturnType<typeof usePrimaryFlowSteps>[number];
export type OnboardingStepKey = OnboardingStep["steps"][number];
