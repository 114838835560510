import React from "react";
import { YourSavingsCredits } from "./YourSavingsCredits";
import { PricingTiers } from "./PricingTiers/PricingTiers";
import { CurrentTier } from "./CurrentTier/CurrentTier";
import { ManageSubscription } from "./ManageSubscription";
import { useBillingStatus } from "../../../hooks/useBillingStatus";

export const BillingPageSubscribedToMonthly: React.FC = () => {
    const { creditsUsed } = useBillingStatus();
    return (
        <>
            <YourSavingsCredits creditsUsed={creditsUsed} />
            <ManageSubscription />
            <CurrentTier />
            <PricingTiers />
        </>
    );
};
