import React, { useCallback, useState } from "react";
import { Formik, FormikConfig } from "formik";
import { boolean, object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { noop } from "../../../helpers/general";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import "./RegisterForm.scss";
import { registerClient } from "../../../lib/user";
import { finishPasswordlessLogin, passwordlessLogin } from "../../../lib/auth0";
import {
    CodeVerification,
    CodeVerificationProps,
} from "../../general/CodeVerification/CodeVerification";
import { ResendCode } from "../../general/CodeVerification/ResendCode";
import { OnboardingStepActions } from "../OnboardingStepActions";
import { isEmail } from "../../../common/helpers/accounts";
import { LoginFlowMagicLink } from "../../auth/LoginFlow/LoginFlowMagicLink";
import { RegisterFormSchema } from "./types";
import { RegisterFormContent } from "./RegisterFormContent";

interface Props {
    inviteCode: string;
    isInvite: boolean;
    onCodeSent(): void;
    setUseEmail(useEmail: boolean): void;
}

export const RegisterForm: React.FC<Props> = ({
    inviteCode,
    isInvite,
    onCodeSent,
    setUseEmail,
}) => {
    const [loading, setLoading] = useState(false);
    const [providedPhoneNumberOrEmail, setProvidedPhoneNumberOrEmail] =
        useState<string>();

    const requestCode = useCallback(
        async (phoneNumber = providedPhoneNumberOrEmail) => {
            if (phoneNumber) {
                try {
                    await passwordlessLogin(phoneNumber);
                    return true;
                } catch (e) {
                    return false;
                }
            }
        },
        [providedPhoneNumberOrEmail],
    );

    const form: FormikConfig<RegisterFormSchema> = {
        initialValues: {
            phoneNumber: "",
            email: "",
            useEmail: false,
            textMessageConsent: false,
        },
        validationSchema: object().shape({
            phoneNumber: string().when("useEmail", {
                is: false,
                then: () =>
                    string()
                        .required("Phone number is required")
                        .matches(/\d{10}/, "Provide valid US phone number"),
            }),
            email: string().when("useEmail", {
                is: true,
                then: () =>
                    string()
                        .required("Email is required")
                        .email("Provide a valid email"),
            }),
            textMessageConsent: boolean().when("useEmail", {
                is: false,
                then: () =>
                    boolean().oneOf(
                        [true],
                        "You must consent to receive text messages",
                    ),
            }),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ phoneNumber, email, useEmail }) => {
                const phoneNumberOrEmail = useEmail ? email : phoneNumber;
                await registerClient({
                    phoneNumber: useEmail ? undefined : phoneNumber,
                    email: useEmail ? email : undefined,
                    inviteCode,
                });
                const requestSuccessful = await requestCode(phoneNumberOrEmail);

                if (requestSuccessful) {
                    setProvidedPhoneNumberOrEmail(phoneNumberOrEmail);
                    onCodeSent();
                }
            },
        }),
    };

    const verifyLogin: CodeVerificationProps["verify"] = useCallback(
        async (verificationCode) => {
            if (providedPhoneNumberOrEmail) {
                return await finishPasswordlessLogin(
                    providedPhoneNumberOrEmail,
                    verificationCode,
                );
            } else {
                return false;
            }
        },
        [providedPhoneNumberOrEmail],
    );

    const verification =
        providedPhoneNumberOrEmail && isEmail(providedPhoneNumberOrEmail) ? (
            <LoginFlowMagicLink
                providedEmail={providedPhoneNumberOrEmail}
                onRequestCode={requestCode}
            />
        ) : (
            <CodeVerification
                phoneOrEmail={providedPhoneNumberOrEmail ?? ""}
                verify={verifyLogin}
                request={requestCode}
                requestOnMount={false}
                onSuccess={noop}
                resend={null}
                renderSubmit={(valid, verifying) => (
                    <section>
                        <div className="mt-5">
                            <ResendCode request={requestCode} btnSize="lg" />
                        </div>
                        <OnboardingStepActions
                            btnSize="xl"
                            onBack={() =>
                                setProvidedPhoneNumberOrEmail(undefined)
                            }
                        >
                            <ButtonWithLoader
                                disabled={!valid}
                                loading={verifying}
                                type="submit"
                                size={"xl" as any}
                            >
                                Next
                            </ButtonWithLoader>
                        </OnboardingStepActions>
                    </section>
                )}
            />
        );

    return (
        <section className="register-form">
            {providedPhoneNumberOrEmail ? (
                verification
            ) : (
                <Formik {...form}>
                    <RegisterFormContent
                        isInvite={isInvite}
                        loading={loading}
                        setUseEmail={setUseEmail}
                    />
                </Formik>
            )}
        </section>
    );
};
