import React from "react";
import { currentYear } from "../../settings/Billing/Pricing/constants";
import { currencyFormatterFractionsIfNeeded } from "../../../common/helpers/currency";
import {
    FlatRatePlan,
    flatRatePlanNames,
    FlatRatePrice,
} from "../../../common/flatRateBilling";
import { YearCard } from "./YearCard";
import { fromIntToMonetary } from "../../../common/helpers/monetary";
import { format } from "date-fns";
import { fullDateFormat } from "../../../common/helpers/date";

interface Props {
    pricing: FlatRatePrice;
    selectedPlan: FlatRatePlan;
    currentPlan: FlatRatePlan;
    isTrial: boolean;
    isUpgrade: boolean;
}

export const CurrentYearPriceCard: React.FC<Props> = ({
    pricing,
    selectedPlan,
    currentPlan,
    isTrial,
    isUpgrade,
}) => {
    let description: React.ReactNode;

    if (isTrial) {
        description = (
            <>
                After your trial ends, you’ll be charged{" "}
                {currencyFormatterFractionsIfNeeded.format(
                    fromIntToMonetary(selectedPlan.intervalAmountCents),
                )}{" "}
                for the {currentYear} tax year. Contract renews{" "}
                {format(pricing.nextBillingDate, fullDateFormat)}.
            </>
        );
    } else if (isUpgrade) {
        description = (
            <>
                Includes refund for {currentYear} Bookkeeping -{" "}
                {flatRatePlanNames[currentPlan.plan]}.
            </>
        );
    } else {
        description = (
            <>
                You’ll be charged{" "}
                {currencyFormatterFractionsIfNeeded.format(
                    fromIntToMonetary(selectedPlan.intervalAmountCents),
                )}{" "}
                for the {currentYear} tax year. Contract renews{" "}
                {format(pricing.nextBillingDate, fullDateFormat)}.
            </>
        );
    }

    let currentYearPrice: number;

    if (isTrial) {
        currentYearPrice = 0;
    } else if (isUpgrade) {
        currentYearPrice = fromIntToMonetary(pricing?.totalBeforeDiscount ?? 0);
    } else {
        currentYearPrice = fromIntToMonetary(selectedPlan.intervalAmountCents);
    }

    let title: React.ReactNode;

    if (isUpgrade) {
        title = (
            <>
                {currentYear} {flatRatePlanNames[selectedPlan.plan]} plan
                upgrade
            </>
        );
    } else {
        title = (
            <>
                {currentYear} Bookkeeping -{" "}
                {flatRatePlanNames[selectedPlan.plan]} {isTrial && "Trial"}
            </>
        );
    }

    return (
        <YearCard
            year={currentYear}
            priceFormatted={currencyFormatterFractionsIfNeeded.format(
                currentYearPrice,
            )}
            title={title}
            description={description}
            selected={true}
        />
    );
};
