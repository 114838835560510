import { useMemo } from "react";
import { isEqual } from "lodash";
import {
    DEFAULT_ACTIVITY_FEED_FILTERS,
    TransactionsFilters,
} from "./filters/lib";

export function useFiltersActive(currentFilters: TransactionsFilters): boolean {
    return useMemo(
        () => !isEqual(currentFilters, DEFAULT_ACTIVITY_FEED_FILTERS),
        [currentFilters],
    );
}
