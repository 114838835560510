import React from "react";
import styles from "./AccountingReportLink.module.scss";
import { ArrowRightIcon } from "../../icons";
import { useInteraction } from "../../hooks/useInteraction";
import classNames from "classnames";

interface Props {
    title: React.ReactNode;
    description: string;
    active?: boolean;
    onClick?: () => void;
}

export const AccountingReportLink: React.FC<Props> = ({
    title,
    description,
    onClick,
    active,
}) => {
    const interaction = useInteraction(onClick);

    return (
        <section
            className={classNames(styles.section, { [styles.active]: active })}
            {...interaction}
        >
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            <ArrowRightIcon />
        </section>
    );
};
