import React from "react";
import { Entity } from "../../common/types/entity";
import { Dropdown } from "react-bootstrap";
import { ManagementDropdown } from "../general/ManagementDropdown/ManagementDropdown";
import { EntitySettingsPages, useSettings } from "../settings/SettingsProvider";
import { getEntityPaneKey } from "../settings/lib";
import { NavLink } from "react-router-dom";
import { useIsAdmin } from "../../hooks/useRoles";
import styles from "./EntityManagementDropdown.module.scss";

interface Props {
    entity: Entity;
    onToggle?: (isOpen: boolean) => void;
    showMembers?: boolean;
    showAccounts?: boolean;
}

export const EntityManagementDropdown: React.FC<Props> = ({
    entity,
    onToggle,
    showMembers,
    showAccounts,
}) => {
    const { open } = useSettings();
    const isAdmin = useIsAdmin(entity);

    return (
        <ManagementDropdown
            id={`entity-dropdown__${entity.id}`}
            onToggle={onToggle}
            className={styles.dropdown}
        >
            <NavLink
                to={`/transactions?entityId=${entity.id}`}
                className="dropdown-item"
                data-testid="entity-management-dropdown-transactions"
            >
                Transactions
            </NavLink>
            {(isAdmin || entity.isPersonal) && !entity.isMockEntity && (
                <Dropdown.Item
                    as="button"
                    onClick={() =>
                        open([
                            getEntityPaneKey(
                                EntitySettingsPages.SETTINGS,
                                entity,
                            ),
                        ])
                    }
                    data-testid="entity-management-dropdown-settings"
                >
                    Settings
                </Dropdown.Item>
            )}
            {showAccounts && (isAdmin || entity.isPersonal) && (
                <Dropdown.Item
                    as="button"
                    onClick={() =>
                        open([
                            getEntityPaneKey(
                                EntitySettingsPages.ACCOUNTS,
                                entity,
                            ),
                        ])
                    }
                    data-testid="entity-management-dropdown-accounts"
                >
                    Accounts
                </Dropdown.Item>
            )}
            {showMembers && isAdmin && !entity.isMockEntity && (
                <Dropdown.Item
                    as="button"
                    onClick={() =>
                        open([
                            getEntityPaneKey(
                                EntitySettingsPages.MEMBERS,
                                entity,
                            ),
                        ])
                    }
                    data-testid="entity-management-dropdown-members"
                >
                    Members
                </Dropdown.Item>
            )}
        </ManagementDropdown>
    );
};
