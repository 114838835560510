import React from "react";
import classNames from "classnames";
import styles from "./Receipts.module.scss";

interface Props {
    animate?: boolean;
}

export const Conversation: React.FC<Props> = ({ animate }) => {
    return (
        <div
            className={classNames(styles.conversation, {
                [styles.animatedConversation]: animate,
            })}
        >
            <div className={styles.kickMessage}>
                <img src="/images/receipt.png" alt="" />
            </div>

            <div className={styles.userMessage}>
                <img src="/images/kick-message-1.png" alt="" />
            </div>

            <div className={styles.userMessage}>
                <img src="/images/kick-message-2.png" alt="" />
            </div>
        </div>
    );
};
