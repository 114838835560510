import React, { useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormRow } from "../../forms/FormRow";
import { PhoneNumberInput } from "../../phoneNumber/PhoneNumberInput";
import { TextMessageConsent } from "./TextMessageConsent";
import { OnboardingStepActions } from "../OnboardingStepActions";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { RegisterConsent } from "./RegisterConsent";
import { Form, useFormikContext } from "formik";
import { RegisterFormSchema } from "./types";
import { useTypedFlags } from "../../../hooks/useTypedFlags";

interface Props {
    isInvite: boolean;
    loading: boolean;
    setUseEmail: (useEmail: boolean) => void;
}

export const RegisterFormContent: React.FC<Props> = ({
    isInvite,
    loading,
    setUseEmail,
}) => {
    const { values, setFieldValue, isValid, dirty } =
        useFormikContext<RegisterFormSchema>();

    const changeUseEmail = useCallback(() => {
        setFieldValue("useEmail", !values.useEmail);
        setUseEmail(!values.useEmail);
    }, [setFieldValue, values.useEmail, setUseEmail]);

    const { registerWithEmail } = useTypedFlags();

    return (
        <Form>
            <p className="register-form__prompt">
                {isInvite
                    ? "You'll always use your phone number to log in to Kick."
                    : "We need it to verify that you’re you."}
            </p>
            {values.useEmail ? (
                <Row>
                    <Col sm={4}>
                        <FormRow fieldName="email" label="Email" />
                    </Col>
                </Row>
            ) : (
                <>
                    <Row>
                        <Col sm={4}>
                            <PhoneNumberInput
                                label={isInvite ? "" : undefined}
                            />
                        </Col>
                    </Row>
                    <TextMessageConsent
                        fieldName="textMessageConsent"
                        className="register-form__consent"
                    />
                </>
            )}
            {registerWithEmail ? (
                <Button
                    variant="link"
                    size="sm"
                    className="register-form__email-phone-switch"
                    onClick={changeUseEmail}
                >
                    Use {values.useEmail ? "phone" : "email"} instead
                </Button>
            ) : null}
            <OnboardingStepActions btnSize="xl">
                <ButtonWithLoader
                    loading={loading}
                    type="submit"
                    size={"xl" as any}
                    disabled={!isValid || !dirty}
                >
                    Next
                </ButtonWithLoader>
            </OnboardingStepActions>
            <div className="mt-5">
                <RegisterConsent buttonText={"Next"} />
            </div>
        </Form>
    );
};
