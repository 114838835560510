import { FormikErrors, FormikHelpers, yupToFormErrors } from "formik";
import { Schema, ValidationError } from "yup";
import classNames from "classnames";

export type InputSize = "sm" | "lg";

export interface SubmitHelperOptions<V> {
    handler(values: V, formikHelpers: FormikHelpers<V>): Promise<void>;
    failureHandler?(
        error: Error,
        values: V,
        formikHelpers: FormikHelpers<V>,
    ): Promise<void>;
    loading?: boolean;
    setLoading?: (value: boolean) => void;
}

export function submitHelper<Values>(
    options: SubmitHelperOptions<Values>,
): (values: Values, formikHelpers: FormikHelpers<Values>) => Promise<void> {
    return async (values: Values, formikHelpers: FormikHelpers<Values>) => {
        if (options.loading) {
            return;
        }

        options.setLoading?.(true);

        try {
            await options.handler(values, formikHelpers);
        } catch (e) {
            await options.failureHandler?.(e as Error, values, formikHelpers);
        }

        options.setLoading?.(false);
    };
}

export function getErrorsSync<TFormSchema>(
    validationSchema: Schema<TFormSchema>,
    values: TFormSchema,
): undefined | FormikErrors<TFormSchema> {
    try {
        validationSchema.validateSync(values);
    } catch (e) {
        if (e instanceof ValidationError) {
            return yupToFormErrors(e);
        }

        throw e;
    }
}

export function getInputClasses(size?: InputSize) {
    const inputClasses = ["form-control"];
    if (size) {
        inputClasses.push(`form-control-${size}`);
    }

    return classNames(inputClasses);
}
