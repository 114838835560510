import React, { useCallback, useMemo } from "react";
import { TransactionListTableItem } from "./ListItem/TransactionListTableItem";
import { Transaction } from "../../common/types/transaction";
import { TransactionSortValue } from "./useSort";
import { SortableHeader } from "./SortableHeader";
import { useBulkActions } from "./TransactionsBulkActions/useBulkActions";
import { FormCheckbox } from "../forms/FormCheckbox/FormCheckbox";
import classNames from "classnames";
import { useDesktopView } from "../../hooks/useMobileView";

export interface TransactionsTableProps {
    transactions?: Transaction[];
    sort?: TransactionSortValue;
    onSortChange?: (sort: TransactionSortValue) => void;
    shownTransactionId?: number;
    onTransactionSelected?(transaction: Transaction): void;
    footer?: React.ReactNode;
    emptyState?: React.ReactNode;
    showAccountNames?: boolean;
    prependContent?: React.ReactNode;
    appendContent?: React.ReactNode;
    disableBulkActions?: boolean;
    actionButtonComponent?: (transaction: Transaction) => React.ReactNode;
    onTransferMatchClick?: (transaction: Transaction) => void;
    lastRowContent?: React.ReactNode;
    disableTransactionChange?: boolean;
}

export const TransactionsTable: React.FC<TransactionsTableProps> = ({
    transactions,
    onSortChange,
    sort,
    shownTransactionId,
    onTransactionSelected,
    footer,
    emptyState,
    showAccountNames = true,
    prependContent,
    appendContent,
    disableBulkActions = false,
    actionButtonComponent,
    onTransferMatchClick,
    lastRowContent,
    disableTransactionChange = false,
}) => {
    const bulkActions = useBulkActions();

    const allTransactionsSelected = useMemo(() => {
        if (bulkActions) {
            return (
                bulkActions.hasSelectedAll ||
                !!transactions?.every((t) => bulkActions.isSelected(t))
            );
        } else {
            return false;
        }
    }, [bulkActions, transactions]);

    const handleBulkSelect = useCallback(() => {
        if (!transactions) {
            return;
        }

        if (allTransactionsSelected) {
            bulkActions.deselect(transactions);
        } else {
            bulkActions.select(transactions);
        }
    }, [allTransactionsSelected, bulkActions, transactions]);

    let columnsNumber = 4;

    if (bulkActions.enabled) {
        columnsNumber++;
    }
    if (!shownTransactionId) {
        columnsNumber += 2;
    }

    if (showAccountNames) {
        columnsNumber += 1;
    }

    const isDesktop = useDesktopView();

    return (
        <table
            className="table transactions-table position-relative"
            data-testid="transactions-table"
        >
            <thead>
                <tr>
                    {!disableBulkActions && bulkActions.enabled ? (
                        <th>
                            <span hidden={!!emptyState}>
                                <FormCheckbox
                                    value="page"
                                    isChecked={allTransactionsSelected}
                                    disabled={bulkActions.hasSelectedAll}
                                    handleChange={() => handleBulkSelect()}
                                    label=""
                                    small
                                />
                            </span>
                        </th>
                    ) : null}
                    {isDesktop && (
                        <th
                            className="transaction-list-item__date"
                            data-testid="transactions-table-date-header"
                        >
                            <SortableHeader
                                asc={TransactionSortValue.DATE_ASC}
                                desc={TransactionSortValue.DATE_DESC}
                                sort={sort}
                                onChange={onSortChange}
                            >
                                Date
                            </SortableHeader>
                        </th>
                    )}
                    <th className="transaction-list-item__description">
                        Description
                    </th>
                    {!shownTransactionId && (
                        <th className="transaction-list-item__category">
                            Category
                        </th>
                    )}
                    {showAccountNames && (
                        <th className="d-none d-md-table-cell transaction-list-item__account">
                            Account
                        </th>
                    )}

                    <th
                        className="transaction-list-item__amount"
                        data-testid="transactions-table-amount-header"
                    >
                        <SortableHeader
                            asc={TransactionSortValue.AMOUNT_ASC}
                            desc={TransactionSortValue.AMOUNT_DESC}
                            sort={sort}
                            onChange={onSortChange}
                        >
                            Amount
                        </SortableHeader>
                    </th>
                    {!shownTransactionId && (
                        <th className="transaction-list-item__is-business" />
                    )}
                    <th
                        className={classNames("transaction-list-item__action", {
                            "transaction-list-item__action--extended":
                                actionButtonComponent,
                        })}
                    >
                        Action
                    </th>
                </tr>
            </thead>
            {emptyState ? (
                <tbody>
                    <tr>
                        <td colSpan={columnsNumber}>{emptyState}</td>
                    </tr>
                </tbody>
            ) : (
                <>
                    {prependContent}
                    <tbody>
                        {transactions?.map((t) => (
                            <TransactionListTableItem
                                transaction={t}
                                key={t.id}
                                onClick={() => onTransactionSelected?.(t)}
                                isActive={t.id === shownTransactionId}
                                isFullView={!shownTransactionId}
                                showAccountName={showAccountNames}
                                disableBulkActions={disableBulkActions}
                                actionButtonComponent={actionButtonComponent}
                                onTransferMatchClick={onTransferMatchClick}
                                readonly={disableTransactionChange}
                            />
                        ))}
                        {lastRowContent && (
                            <tr>
                                <td colSpan={columnsNumber}>
                                    {lastRowContent}
                                </td>
                            </tr>
                        )}
                    </tbody>
                    {appendContent}
                </>
            )}

            {footer}
        </table>
    );
};
