import React, { useCallback } from "react";
import {
    FinancialDocumentFilters,
    FinancialDocumentFiltersProps,
    FinancialDocumentFilterTabs,
} from "./lib";
import { Card } from "../../general/Card/Card";
import { AggregatedFiltersProvider } from "../../general/AggregatedFilters/AggregatedFiltersProvider";
import { SearchFilter } from "../../general/SearchFilter/SearchFilter";
import { AggregatedFilters } from "../../general/AggregatedFilters/AggregatedFilters";
import styles from "./FinancialDocumentsPageFilters.module.scss";
import { FinancialDocumentsFiltersOverlay } from "./FinancialDocumentsFiltersOverlay";
import { isEqual } from "lodash";
import { ActiveFilters } from "../../general/ActiveFilters/ActiveFilters";
import { useActiveFinancialDocumentsFilters } from "./useActiveFinancialDocumentsFilters";
import { FinancialDocumentsActiveFilterButton } from "./FinancialDocumentsActiveFilterButton";
import { useResetFinancialDocumentsFilter } from "./useResetFinancialDocumentsFilter";
import classNames from "classnames";
import css from "../../general/AggregatedFilters/AggregatedFilters.module.scss";
import { EntityFilter } from "../../general/EntityFilter/EntityFilter";

export const FinancialDocumentsPageFilters: React.FC<
    FinancialDocumentFiltersProps
> = ({ onChange, filters }) => {
    const updateSearch = useCallback(
        (search: FinancialDocumentFilters["search"]) => {
            onChange({ search });
        },
        [onChange],
    );

    const hasActiveFilters = !isEqual(filters, {});
    const activeFilters = useActiveFinancialDocumentsFilters(filters);
    const clearActiveFilters = useResetFinancialDocumentsFilter(
        onChange,
        activeFilters,
    );

    return (
        <AggregatedFiltersProvider
            defaultTab={FinancialDocumentFilterTabs.DATE}
        >
            <Card className={styles.filters}>
                <SearchFilter filter={filters.search} onChange={updateSearch} />
                <AggregatedFilters>
                    <FinancialDocumentsFiltersOverlay
                        filters={filters}
                        onChange={onChange}
                    />
                </AggregatedFilters>

                {hasActiveFilters && (
                    <ActiveFilters
                        activeFilters={activeFilters}
                        renderVisibleFilters={(visibleFilters) =>
                            visibleFilters.map((filterId) => (
                                <FinancialDocumentsActiveFilterButton
                                    key={filterId}
                                    filterId={filterId}
                                    filters={filters}
                                    onChange={onChange}
                                />
                            ))
                        }
                        clearFilters={clearActiveFilters}
                    />
                )}

                <aside className={classNames(css.filters, "ml-auto")}>
                    <EntityFilter filters={filters} onChange={onChange} />
                </aside>
            </Card>
        </AggregatedFiltersProvider>
    );
};
