import React, { MouseEvent } from "react";
import classNames from "classnames";
import { useInteraction } from "../../../hooks/useInteraction";

interface Props<T> {
    value: T;
    isChecked: boolean;
    label?: React.ReactNode;
    labelClass?: string;
    handleChange?: (value: T) => void;
    type?: "checkbox" | "radio";
    name?: string;
    disabled?: boolean;
    preventClickPropagation?: boolean;
    small?: boolean;
    caption?: string;
    className?: string;
}

export const FormCheckbox = <T extends string | number>({
    value,
    isChecked,
    label,
    labelClass,
    handleChange,
    type = "checkbox",
    name,
    disabled,
    preventClickPropagation,
    small,
    caption,
    className,
}: Props<T>) => {
    const interaction = useInteraction(
        (e: MouseEvent) => preventClickPropagation && e.stopPropagation(),
    );

    return (
        <label
            data-testid={`form-checkbox-${value}-label`}
            className={classNames("form-check", className, {
                "form-check--small": small,
            })}
            {...interaction}
        >
            <input
                data-testid={`form-checkbox-${value}-input`}
                className="form-check-input"
                name={name}
                type={type}
                value={value}
                checked={isChecked}
                onChange={() => handleChange?.(value)}
                disabled={disabled}
            />
            <span className={classNames("form-check-label", labelClass)}>
                <span>
                    {label}
                    {caption && (
                        <span className="form-check__caption">{caption}</span>
                    )}
                </span>
            </span>
        </label>
    );
};
