import React from "react";
import { PREMIUM_FEATURES } from "../../../common/flatRateBilling";
import { BALANCE_SHEET_PREVIEW_URL } from "../../../constants";
import { BalanceSheetPreviewPage } from "./BalanceSheetPreviewPage";
import { ReportAccess } from "../ReportAccess";

export const BalanceSheetAccessPage: React.FC = () => {
    return (
        <ReportAccess
            feature={PREMIUM_FEATURES.BALANCE_SHEET}
            report="Balance Sheet"
            previewUrl={BALANCE_SHEET_PREVIEW_URL}
            renderReportPage={() => <BalanceSheetPreviewPage />}
        />
    );
};
