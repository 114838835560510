import classNames from "classnames";
import { Transaction } from "../../../../common/types/transaction";
import { TransactionListTableItem } from "../../ListItem/TransactionListTableItem";
import styles from "./TransferMatchingTransactionsHeader.module.scss";
import { LinkIcon } from "../../../../icons";
import { MatchButton } from "../MatchButton/MatchButton";

interface TransferMatchingTransactionsHeaderProps {
    transactionsHeaderRef?: React.RefObject<HTMLDivElement>;
    isSticky?: boolean;
    originTransaction: Transaction | undefined;
    pairedTransaction: Transaction | undefined;
    visible?: boolean;
}

export const TransferMatchingTransactionsHeader = ({
    transactionsHeaderRef,
    isSticky,
    originTransaction,
    pairedTransaction,
    visible,
}: TransferMatchingTransactionsHeaderProps) => {
    return (
        <div
            ref={transactionsHeaderRef}
            className={styles.headerTransactionsContainer}
            style={{
                position: isSticky ? "absolute" : "relative",
                left: 0,
                top: isSticky ? -1 : 0,
                display: visible ? "block" : "none",
                borderTopLeftRadius: isSticky ? 16 : 0,
                borderTopRightRadius: isSticky ? 16 : 0,
            }}
        >
            {originTransaction && (
                <div
                    className={classNames(
                        styles.headerTransactionListTableItemContainer,
                    )}
                >
                    <table className="table">
                        <TransactionListTableItem
                            transaction={originTransaction}
                            onClick={() => {}}
                            isActive={false}
                            isFullView
                            showAccountName
                            disableBulkActions
                            actionButtonComponent={() => null}
                            hideTransferMatchLink
                            readonly
                        />
                    </table>
                </div>
            )}
            {originTransaction && pairedTransaction && (
                <div
                    className={classNames(
                        styles.headerTransactionListTableItemContainer,
                        styles.headerTransactionListTableItemContainerWithTopBorders,
                    )}
                >
                    <div
                        className={
                            styles.headerTransactionListTableItemContainerLinkIcon
                        }
                    >
                        <LinkIcon
                            width={16}
                            height={16}
                            className="icon-color-gray-500"
                        />
                    </div>
                    <table className="table">
                        <TransactionListTableItem
                            transaction={pairedTransaction}
                            onClick={() => {}}
                            isActive={false}
                            isFullView
                            showAccountName
                            disableBulkActions
                            hideTransferMatchLink
                            readonly
                            actionButtonComponent={(targetTransaction) => (
                                <MatchButton
                                    matchedTransaction={targetTransaction}
                                    originTransaction={originTransaction}
                                    targetTransaction={targetTransaction}
                                    onUnmatch={() => {}}
                                    onMatch={() => {}}
                                />
                            )}
                        />
                    </table>
                </div>
            )}
        </div>
    );
};
