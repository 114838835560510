import { Taxonomy } from "../../categories";
import { Category } from "../../types/category";
import { CustomCategory } from "../../types/customCategory";
import { TransactionCategorizationRule } from "../../types/transactionCategorizationRule";
import { TransactionCategorizationRuleConditionDto } from "./transaction-categorization-rule-condition.dto";

export class TransactionCategorizationRuleDto {
    id: string;
    name: string;
    categoryId: Taxonomy | null;
    category: Category | null;
    customCategoryId: number | null;
    customCategory: CustomCategory | null;
    workspaceId: string;
    conditions: TransactionCategorizationRuleConditionDto[];

    constructor(rule: TransactionCategorizationRule) {
        this.id = rule.id;
        this.name = rule.name;
        this.categoryId = rule.categoryId;
        this.category = rule.category;
        this.customCategoryId = rule.customCategoryId;
        this.customCategory = rule.customCategory;
        this.workspaceId = rule.workspaceId;
        this.conditions = rule.conditions.map(
            (condition) =>
                new TransactionCategorizationRuleConditionDto(condition),
        );
    }

    static deserialize(
        dto: TransactionCategorizationRuleDto,
    ): TransactionCategorizationRule {
        return {
            id: dto.id,
            name: dto.name,
            categoryId: dto.categoryId,
            category: dto.category,
            customCategoryId: dto.customCategoryId,
            customCategory: dto.customCategory,
            workspaceId: dto.workspaceId,
            conditions: dto.conditions.map(
                TransactionCategorizationRuleConditionDto.deserialize,
            ),
        };
    }
}
