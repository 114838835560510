import React, { useRef } from "react";
import { Button, Overlay, Popover } from "react-bootstrap";
import classNames from "classnames";
import { FilterIcon } from "../../../icons";
import styles from "./AggregatedFilters.module.scss";
import { useAggregatedFiltersContext } from "./AggregatedFilters.context";

const popperConfig = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, 8],
            },
        },
    ],
};

export const AggregatedFilters: React.FC = ({ children }) => {
    const { open, close, isOpen } = useAggregatedFiltersContext();
    const ref = useRef<HTMLElement>();
    const containerRef = useRef<HTMLElement>();

    return (
        <span ref={containerRef as any} className={styles.filtersWrapper}>
            <Button
                data-testid="add-agg-filter"
                ref={ref as any}
                size="sm"
                variant="secondary"
                onClick={() => (isOpen ? close() : open())}
            >
                <FilterIcon className="mr-2" /> Add filter
            </Button>

            <Overlay
                target={ref as any}
                placement="bottom-start"
                rootClose={true}
                onHide={close}
                popperConfig={popperConfig}
                flip={true}
                show={isOpen}
                container={containerRef as any}
            >
                <Popover
                    id="aggregatedFiltersPopover"
                    className={classNames("popover-container", styles.popover)}
                >
                    <Popover.Content>{children}</Popover.Content>
                </Popover>
            </Overlay>
        </span>
    );
};
