import React, { useCallback } from "react";
import { DEFAULT_TAB, FilterProps } from "../lib";
import { Card } from "../../../general/Card/Card";

import css from "../../../general/AggregatedFilters/AggregatedFilters.module.scss";
import { TransactionActionsToggleFilter } from "../TransactionActionsToggleFilter";
import classNames from "classnames";
import { AggregatedAccountFilter } from "./AccountFilter/AggregatedAccountFilter";
import { ActiveFilters } from "../../../general/ActiveFilters/ActiveFilters";
import { useFiltersActive } from "../../useFiltersActive";
import { AggregatedFilters } from "../../../general/AggregatedFilters/AggregatedFilters";
import { AggregatedFiltersProvider } from "../../../general/AggregatedFilters/AggregatedFiltersProvider";
import { AggregatedTransactionFiltersOverlay } from "./AggregatedTransactionFiltersOverlay";
import {
    useActiveFilters,
    useResetFilter,
} from "./ActiveFilters/ActivityFilter.hooks";
import { TransactionsActiveFilterButton } from "./ActiveFilters/TransactionsActiveFilterButton";
import { SearchFilter } from "../../../general/SearchFilter/SearchFilter";

export const AggregatedTransactionFilters: React.FC<FilterProps> = ({
    filters,
    onChange,
}) => {
    const filtersActive = useFiltersActive(filters);
    const activeFilters = useActiveFilters(filters);
    const resetActiveFilters = useResetFilter(onChange, activeFilters);
    const updateDescriptionFilter = useCallback(
        (description?: string) => {
            onChange({
                description,
            });
        },
        [onChange],
    );

    return (
        <AggregatedFiltersProvider defaultTab={DEFAULT_TAB}>
            <Card className={css.filters}>
                <section className={css.filters}>
                    <SearchFilter
                        filter={filters.description}
                        onChange={updateDescriptionFilter}
                        placeholder="Description..."
                    />

                    <AggregatedFilters>
                        <AggregatedTransactionFiltersOverlay
                            filters={filters}
                            onChange={onChange}
                        />
                    </AggregatedFilters>
                </section>

                {filtersActive && (
                    <ActiveFilters
                        activeFilters={activeFilters}
                        renderVisibleFilters={(visibleFilters) =>
                            visibleFilters.map((filterId) => (
                                <TransactionsActiveFilterButton
                                    key={filterId}
                                    filterId={filterId}
                                    filters={filters}
                                    onChange={onChange}
                                />
                            ))
                        }
                        clearFilters={resetActiveFilters}
                    />
                )}

                <section className={classNames(css.filters, "ml-auto")}>
                    <AggregatedAccountFilter
                        filters={filters}
                        onChange={onChange}
                    />

                    <TransactionActionsToggleFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </section>
            </Card>
        </AggregatedFiltersProvider>
    );
};
