import { useMutation } from "react-query";
import { Transaction } from "../common/types/transaction";
import { UpdateTransactionDto } from "../common/dto/transactions/update-transaction.dto";
import {
    detachFinancialTransactionMatch,
    matchTransfer,
    unmatchTransfer,
    updateTransaction,
} from "../lib/transactions";
import { useBillingStatus } from "../hooks/useBillingStatus";
import { TransactionMatch } from "../common/types/transactionMatch";
import {
    invalidateTransactionDetailsQueries,
    updateTransactionsDetailsQueryData,
} from "../components/transactions/TransactionDetails/useTransactionDetailsQuery";
import { createLinkFinancialDocumentToFinancialTransaction } from "../lib/financialDocument";
import { FinancialDocument } from "../common/types/financialDocument";
import {
    invalidateTransactionsQueries,
    updateTransactionInTransactionsQuery,
} from "../components/transactions/useTransactionsQuery";
import { updateTransactionInTransactionsForTransferMatchingQuery } from "../components/transactions/TransferMatching/useTransactionsForTransferMatching";

interface UpdateTransactionOptions {
    transaction: Transaction;
    update: UpdateTransactionDto;
}

export function useUpdateTransactionMutation() {
    const { invalidate: invalidateCreditsUsed } = useBillingStatus();

    return useMutation(
        ({ transaction, update }: UpdateTransactionOptions) =>
            updateTransaction(transaction, update),
        {
            onSuccess: async () => {
                await invalidateCreditsUsed();
            },
        },
    );
}

export function useDetachTransactionMatchMutation(
    transaction: Transaction,
    match: TransactionMatch,
) {
    const { invalidate: invalidateCreditsUsed } = useBillingStatus();

    return useMutation(
        () => detachFinancialTransactionMatch(transaction.id, match.id),
        {
            onSuccess: async () => {
                await invalidateCreditsUsed();
                await invalidateTransactionDetailsQueries();
            },
        },
    );
}

export function useCreateLinkToFinancialDocument(
    financialTransaction: Transaction,
    financialDocument: FinancialDocument,
    onLinked?: () => Promise<unknown> | void,
) {
    return useMutation(
        () =>
            createLinkFinancialDocumentToFinancialTransaction({
                financialDocumentId: financialDocument.id,
                financialTransactionId: financialTransaction.id,
            }),
        {
            async onSuccess() {
                await invalidateTransactionDetailsQueries();
                await onLinked?.();
            },
        },
    );
}

interface UnmatchTransferParams {
    financialTransaction: Transaction;
    onSuccess: (transaction: Transaction) => void;
}

export function useUnmatchTransfer({
    financialTransaction,
    onSuccess,
}: UnmatchTransferParams) {
    return useMutation({
        mutationFn: () => unmatchTransfer(financialTransaction.id),
        onSuccess: async (transaction) => {
            await invalidateTransactionDetailsQueries();
            await invalidateTransactionsQueries();
            onSuccess(transaction);
        },
        mutationKey: ["unmatchTransfer", financialTransaction],
    });
}

interface MatchTransferOnSuccessParams {
    originTransaction: Transaction;
    targetTransaction: Transaction;
}

export function useMatchTransfer({
    originTransaction,
    targetTransaction,
    onSuccess,
}: {
    originTransaction: Transaction;
    targetTransaction: Transaction;
    onSuccess: ({
        originTransaction,
        targetTransaction,
    }: MatchTransferOnSuccessParams) => void;
}) {
    return useMutation({
        mutationFn: () =>
            matchTransfer(originTransaction.id, targetTransaction.id),
        onSuccess: async ({
            originTransaction: originTransactionUpdated,
            targetTransaction: targetTransactionUpdated,
        }) => {
            updateTransactionInAllQueries(
                originTransactionUpdated.id,
                originTransactionUpdated,
            );
            updateTransactionInAllQueries(
                targetTransactionUpdated.id,
                targetTransactionUpdated,
            );
            onSuccess({
                originTransaction: originTransactionUpdated,
                targetTransaction: targetTransactionUpdated,
            });
        },
        mutationKey: [
            "unmatchTransfer",
            originTransaction.id,
            targetTransaction.id,
        ],
    });
}

export function updateTransactionInAllQueries(
    transactionId: number,
    transactionUpdate: Partial<Transaction>,
) {
    updateTransactionInTransactionsQuery(transactionId, transactionUpdate);
    updateTransactionInTransactionsForTransferMatchingQuery(
        transactionId,
        transactionUpdate,
    );
    updateTransactionsDetailsQueryData(transactionId, transactionUpdate);
}
