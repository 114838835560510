import React from "react";
import { Col, Row } from "react-bootstrap";
import { AutopilotActivity } from "./activity/AutopilotActivity";
import { Widget } from "../general/Widget";
import { AutopilotContent } from "./AutopilotContent";

export const AutopilotPage: React.FC = () => {
    return (
        <Row>
            <Col sm={8}>
                <AutopilotContent />
            </Col>
            <Col sm={4} className="d-none d-sm-block">
                <Widget title="Activity">
                    <AutopilotActivity />
                </Widget>
            </Col>
        </Row>
    );
};
