import React from "react";
import { useWindowMessage } from "../../hooks/useWindowMessage";
import {
    OAuthAuthorizationResponse,
    triggerAuthorization,
} from "../../lib/oauth";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { OAuthServiceType } from "../../common/constants";
import { useInteraction } from "../../hooks/useInteraction";
import { Entity } from "../../common/types/entity";
import { ButtonProps } from "react-bootstrap";
import { useGustoConnectionCreationMutation } from "../../mutations/gustoConnection";

interface Props {
    onCreated?: () => void;
    children?: React.ReactNode;
    defaultEntity?: Entity;
    btnVariant?: ButtonProps["variant"];
}
export const CreateGustoConnection: React.FC<Props> = ({
    children = <>Connect</>,
    onCreated,
    defaultEntity,
    btnVariant = "link",
}) => {
    const mutation = useGustoConnectionCreationMutation(onCreated);
    const handler = ({ code }: OAuthAuthorizationResponse) => {
        mutation.mutate({ code, defaultEntity });
    };

    useWindowMessage<OAuthAuthorizationResponse>({
        scope: OAuthServiceType.GUSTO,
        handler,
    });

    const authorize = useInteraction(() =>
        triggerAuthorization(OAuthServiceType.GUSTO),
    );

    return (
        <span {...authorize}>
            <ButtonWithLoader loading={mutation.isLoading} variant={btnVariant}>
                {children}
            </ButtonWithLoader>
        </span>
    );
};
