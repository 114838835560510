import { useCallback, useEffect, useState } from "react";
import { addNotification } from "../../../reducers/appState";
import { useDispatch } from "react-redux";
import axios from "axios";

export type UseResend = {
    resendBlocked: boolean;
    handleResend: () => Promise<void>;
};

const RESEND_DELAY = 60_000;

export function useResend(request: () => Promise<void>): UseResend {
    const [resendBlocked, setResendBlocked] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        let timer: number;
        if (resendBlocked) {
            timer = window.setTimeout(() => {
                setResendBlocked(false);
            }, RESEND_DELAY);
        }

        return () => {
            window.clearTimeout(timer);
        };
    }, [resendBlocked]);

    const handleResend = useCallback(async () => {
        try {
            setResendBlocked(true);
            await request();
        } catch (error) {
            if (
                axios.isAxiosError(error) &&
                (error.response?.data as any)?.message
            ) {
                dispatch(
                    addNotification({
                        type: "danger",
                        message: (error.response?.data as any)?.message,
                    }),
                );
            } else {
                dispatch(
                    addNotification({
                        type: "danger",
                        message: "There was a problem sending the code",
                    }),
                );
            }

            setResendBlocked(false);
        }
    }, [dispatch, request]);

    return {
        handleResend,
        resendBlocked,
    };
}
