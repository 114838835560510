import React from "react";
import { Transaction } from "../../../common/types/transaction";
import { getConnectionLabel } from "../../../common/helpers/financialConnection";
import { getTransactionAccountNameWithLast4 } from "../../../common/helpers/transactions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AccountCard } from "../../general/AccountCard/AccountCard";
import { getLeadingAccountConnection } from "../../../common/helpers/financialAccount";

interface Props {
    transaction: Transaction;
}

export const TransactionAccountColumnContent: React.FC<Props> = ({
    transaction,
}) => {
    const account = transaction.financialAccount;
    const connection = getLeadingAccountConnection(account);
    const institutionName = connection && getConnectionLabel(connection);

    return (
        <OverlayTrigger
            placement="top"
            trigger={account ? ["hover", "focus"] : []}
            overlay={
                <Tooltip
                    id={`bankDescription:${transaction.id}`}
                    className="transaction-list-item__account__tooltip"
                >
                    {institutionName && <label>{institutionName}</label>}
                    <p>{getTransactionAccountNameWithLast4(transaction, 2)}</p>
                </Tooltip>
            }
        >
            <div>
                <AccountCard
                    account={account}
                    onlyNumber={true}
                    showType={false}
                    iconSize="sm"
                    maxNameLength={8}
                />
            </div>
        </OverlayTrigger>
    );
};
