import React, { useState } from "react";
import { Transaction } from "../../../../common/types/transaction";
import { TransactionAmount } from "../../../transactions/TransactionAmount";
import { TransactionDateColumnContent } from "../../../transactions/ListItem/TransactionDateColumnContent";
import { EntityIcon } from "../../../entity/EntityIcon";
import { TransactionAccountColumnContent } from "../../../transactions/ListItem/TransactionAccountColumnContent";
import { TransactionDescriptionColumnContent } from "../../../transactions/ListItem/TransactionDescriptionColumnContent";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import { EagerLoaded } from "../../../../common/types/base/orm";

interface Props {
    transaction: EagerLoaded<Transaction, "transactionMatches">;
    financialDocument: FinancialDocument;
    onMatch(): Promise<unknown>;
}

export const MatchingViewTransaction: React.FC<Props> = ({
    transaction,
    financialDocument,
    onMatch,
}) => {
    const [loading, setLoading] = useState(false);

    const alreadyLinkedToCurrentFinancialDocument =
        transaction.transactionMatches.some(
            (tm) => tm.financialDocument?.id === financialDocument.id,
        );

    return (
        <tr data-testid="transaction-row">
            <td data-testid="transaction-cell-date">
                <TransactionDateColumnContent transaction={transaction} />
            </td>
            <td data-testid="transaction-cell-description">
                <TransactionDescriptionColumnContent
                    transaction={transaction}
                />
            </td>
            <td className="text-right" data-testid="transaction-cell-account">
                <TransactionAccountColumnContent transaction={transaction} />
            </td>
            <td className="text-right" data-testid="transaction-cell-amount">
                <TransactionAmount
                    transaction={transaction}
                    addSign={true}
                    color={true}
                />
            </td>
            <td>
                <EntityIcon entity={transaction.entity} size="xs" />
            </td>
            <td data-testid="transaction-cell-link">
                <ButtonWithLoader
                    size="sm"
                    variant="secondary"
                    disabled={alreadyLinkedToCurrentFinancialDocument}
                    onClick={onMatch}
                    loading={loading}
                    setLoading={setLoading}
                >
                    {alreadyLinkedToCurrentFinancialDocument
                        ? "Linked"
                        : "Link"}
                </ButtonWithLoader>
            </td>
        </tr>
    );
};
