import { JournalEntrySourceType } from "../types/domains/accounting/journalEntry";
import { getShortUuidWithPrefix } from "./uuid";

export const JournalEntrySourceTypeLabels: Record<
    JournalEntrySourceType,
    string
> = {
    [JournalEntrySourceType.Transaction]: "Transactions",
    [JournalEntrySourceType.ManualJournalEntry]: "Manual Journal",
};

export function getShortJournalEntryReferenceNumber(referenceNumber: string) {
    return getShortUuidWithPrefix(referenceNumber, "JE");
}
