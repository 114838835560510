import React, { MouseEvent, useCallback } from "react";
import { TreeHelpers, TreeNode } from "../../../../general/Tree/Tree.types";
import { Entity } from "../../../../../common/types/entity";
import { EntityIcon } from "../../../../entity/EntityIcon";
import { Button } from "react-bootstrap";
import { TriangleIcon } from "../../../../../icons";
import classNames from "classnames";
import css from "../FormCheckItem.module.scss";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";

interface EntityTreeItemProps extends TreeHelpers {
    node: TreeNode<Entity>;
    toggleSelected: (id: number) => void;
    isSelected: (id: number) => boolean;
}
export const EntityTreeItem: React.FC<EntityTreeItemProps> = ({
    node,
    collapseNode,
    isExpanded,
    toggleSelected,
    isSelected,
    expandNode,
}) => {
    const handleToggleExpanded = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            isExpanded(node.nodeId)
                ? collapseNode(node.nodeId)
                : expandNode(node.nodeId);
        },
        [collapseNode, expandNode, isExpanded, node.nodeId],
    );

    return (
        <FormCheckbox
            small
            key={node.current.id}
            value={node.current.id}
            isChecked={isSelected(node.current.id)}
            handleChange={() => toggleSelected(node.current.id)}
            className={classNames(css.item, {
                [css.rootItem]: node.depth === 0,
                [css.expandedItem]: isExpanded(node.nodeId),
            })}
            label={
                <div className={css.itemLabel}>
                    <EntityIcon entity={node.current} size="sm" />
                    <span className="ml-2">{node.current.name}</span>
                    {node.children.length > 0 && (
                        <Button
                            variant="link"
                            onClick={handleToggleExpanded}
                            className="ml-1"
                            data-testid="entity-tree-item-toggle"
                        >
                            <TriangleIcon />
                        </Button>
                    )}
                </div>
            }
        />
    );
};
