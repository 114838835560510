import { toNumber } from "lodash";
import { useCallback } from "react";
import {
    Taxonomy,
    TransactionDirectionType,
    mapCategoryName,
} from "../common/categories";
import { useCategoryMap } from "./useCategoryMap";
import { useCustomCategories } from "./useCustomCategories";

export type CategoryLabelGetter = (
    id: Taxonomy,
    transactionDirection?: TransactionDirectionType,
) => string;

export function useCategoryLabelGetter(): CategoryLabelGetter {
    const categories = useCategoryMap();
    const customCategories = useCustomCategories();

    return useCallback(
        (
            id: Taxonomy,
            transactionDirection = TransactionDirectionType.mixed,
        ) => {
            const customCategoryId =
                id && isFinite(toNumber(id)) ? toNumber(id) : undefined;
            return (
                customCategories.find(
                    (category) => category.id === customCategoryId,
                )?.label ??
                mapCategoryName({
                    category: categories[id],
                    transactionDirection: transactionDirection,
                })
            );
        },
        [categories, customCategories],
    );
}
