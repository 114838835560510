import { backendClient, unwrapResponse } from "./backendClient";
import { GetAccountNumbersResponseDto } from "../common/dto/financialAccount/get-account-numbers-response.dto";
import { FinancialAccount } from "../common/types/financialAccount";
import { FinancialAccountDto } from "../common/dto/financialAccount/financial-account.dto";

export interface ExtendedFinancialAccount extends FinancialAccount {
    accountNumber?: string;
}

export async function disableFinancialAccount(
    accountId: number,
): Promise<FinancialAccount> {
    return FinancialAccountDto.deserialize(
        unwrapResponse(
            await backendClient.post<FinancialAccountDto>(
                `/financial-account/${accountId}/disable`,
            ),
        ),
    );
}

export async function enableFinancialAccount(
    accountId: number,
): Promise<FinancialAccount> {
    return FinancialAccountDto.deserialize(
        unwrapResponse(
            await backendClient.post<FinancialAccountDto>(
                `/financial-account/${accountId}/enable`,
            ),
        ),
    );
}

export async function getFinancialAccountNumbers(): Promise<GetAccountNumbersResponseDto> {
    return unwrapResponse(
        await backendClient.get("/financial-account/account-numbers"),
    );
}

export async function getUnassignedFinancialAccounts(): Promise<
    FinancialAccount[]
> {
    return (
        await backendClient.get<FinancialAccountDto[]>(
            "/financial-account/unassigned",
        )
    ).data.map((account) => FinancialAccountDto.deserialize(account));
}
