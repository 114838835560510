import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export interface DashboardChartProps {
    startDate: Date;
    endDate: Date;
}

export function getDashboardChartBasis(id: string) {
    const chart = am4core.create(id, am4charts.XYChart);

    const dateAxis = new am4charts.DateAxis();
    chart.xAxes.push(dateAxis);
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.fill = am4core.color("#D8D6DC");
    dateAxis.renderer.labels.template.fontSize = 12;
    dateAxis.renderer.labels.template.fontWeight = "bold";
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.cursorTooltipEnabled = false;

    if (dateAxis.tooltip) {
        dateAxis.tooltip.fontSize = 15;
    }

    const valuesAxis = new am4charts.ValueAxis();
    chart.yAxes.push(valuesAxis);
    valuesAxis.renderer.grid.template.disabled = true;
    valuesAxis.renderer.labels.template.disabled = true;
    valuesAxis.cursorTooltipEnabled = false;
    valuesAxis.min = 0;

    chart.numberFormatter.numberFormat = "'$'#a";

    chart.paddingLeft = 24;
    chart.paddingRight = 24;
    chart.paddingBottom = 5;
    chart.maskBullets = false;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = -1;
    chart.cursor.lineY.disabled = true;
    chart.cursor.behavior = "none";

    return {
        chart,
        valuesAxis,
        dateAxis,
    };
}

export function createCornerRadiusAdapter(
    chart: am4charts.XYChart,
    radius: number,
) {
    return (originalRadius: any, item: am4core.RoundedRectangle) => {
        let dataItem = item.dataItem;

        // Find the last series in this stack
        let lastSeries;
        chart.series.each((series) => {
            if (
                dataItem &&
                (dataItem.dataContext as any)[
                    series.dataFields.valueY as string
                ] &&
                !series.isHidden &&
                !series.isHiding
            ) {
                lastSeries = series;
            }
        });

        // If current series is the one, use rounded corner
        return dataItem?.component === lastSeries ? radius : originalRadius;
    };
}
