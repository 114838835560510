import React, { useEffect, useState } from "react";
import Calendar, { CalendarProps, Detail } from "react-calendar";
import { format } from "date-fns";
import "../dates.scss";
import "./DatePicker.scss";
import { YearSelect } from "../YearSelect/YearSelect";
import classNames from "classnames";
import { CommonDatepickerProps } from "../types";
import {
    addTimeZoneOffset,
    removeTimeZoneOffset,
} from "../../../../helpers/date";

import calendarStyles from "../Calendar.module.scss";

interface Props extends CommonDatepickerProps {
    initialValue?: Date;
    onChange: (value: Date | undefined) => void;
    showYearSelect?: boolean;
    value?: Date;
}

export const DatePicker: React.FC<Props> = ({
    initialValue,
    onChange,
    showYearSelect = false,
    value,
    ...calendarProps
}) => {
    const [activeDate, setActiveDate] = useState<Date>(
        initialValue ?? removeTimeZoneOffset(new Date()),
    );
    const [currentView, setCurrentView] = useState<Detail>(
        calendarProps.view ?? "month",
    );

    const activeDateWithTimezone = addTimeZoneOffset(activeDate);

    useEffect(() => {
        if (value) {
            setActiveDate(value);
        }
    }, [value]);

    return (
        <div
            className={classNames("date-picker", {
                "date-picker--year-select": showYearSelect,
            })}
        >
            {showYearSelect && (
                <YearSelect
                    onChange={(year) => {
                        setActiveDate(new Date(`${year}-01-01`));
                        setCurrentView("year");
                    }}
                >
                    {activeDateWithTimezone?.getFullYear()}{" "}
                    <span className="fas fa-chevron-down ml-2" />
                </YearSelect>
            )}

            <Calendar
                {...calendarProps}
                activeStartDate={activeDateWithTimezone}
                onActiveStartDateChange={({ activeStartDate }) =>
                    setActiveDate(removeTimeZoneOffset(activeStartDate))
                }
                formatYear={(_, date) =>
                    showYearSelect ? "Select month" : format(date, "yyyy")
                }
                formatMonthYear={(_, date) =>
                    format(date, showYearSelect ? "MMMM" : "MMMM yyyy")
                }
                formatMonth={(_, date) => format(date, "MMM")}
                prevLabel={
                    currentView === "month" || !showYearSelect ? (
                        <span className="fas fa-chevron-left" />
                    ) : (
                        ""
                    )
                }
                nextLabel={
                    currentView === "month" || !showYearSelect ? (
                        <span className="fas fa-chevron-right" />
                    ) : (
                        ""
                    )
                }
                defaultValue={initialValue}
                showNeighboringMonth={false}
                onChange={onChange as CalendarProps["onChange"]}
                view={currentView}
                onViewChange={({ view }) => setCurrentView(view)}
                className={classNames(calendarStyles.datePickerCalendar, {
                    [calendarStyles.datePickerCalendarYearSelect]:
                        showYearSelect,
                })}
                value={value}
            />
        </div>
    );
};
