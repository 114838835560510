import { formatISO } from "date-fns";
import {
    AccountClass,
    BalanceSheetDerivativeLines,
} from "../../common/types/domains/accounting/accounts";

export interface DrillDownUrlOptions {
    startDate?: Date;
    endDate?: Date;
    entityId?: number;
    accountCodes?: Array<number | string>;
}

export function useDrillDownUrl({
    startDate,
    endDate,
    accountCodes,
    entityId,
}: DrillDownUrlOptions) {
    const params = new URLSearchParams();

    if (startDate) {
        params.append(
            "startDate",
            formatISO(startDate, { representation: "date" }),
        );
    }
    if (endDate) {
        params.append(
            "endDate",
            formatISO(endDate, { representation: "date" }),
        );
    }
    if (entityId) {
        params.append("entityId", entityId.toString());
    }
    if (accountCodes && accountCodes.length > 0) {
        const includesNetIncome = accountCodes.includes(
            BalanceSheetDerivativeLines.netIncome,
        );
        if (includesNetIncome) {
            params.append(
                "classifications",
                [AccountClass.expenses, AccountClass.income].join("_"),
            );
        }
        params.append(
            "accountCodes",
            (includesNetIncome
                ? accountCodes.filter(
                      (code) => code !== BalanceSheetDerivativeLines.netIncome,
                  )
                : accountCodes
            ).join("_"),
        );
    }

    return `/accounting/general-ledger?${params.toString()}`;
}
