import React from "react";
import { FilterProps } from "../../lib";
import { AccountFilterOverlay } from "./AccountFilterOverlay";
import styles from "./AggregatedAccountFilter.module.scss";
import { PopoverContainer } from "../../../../general/PopoverContainer";
import { AccountFilterTrigger } from "./AccountFilterTrigger";

export const AggregatedAccountFilter: React.FC<FilterProps> = (props) => {
    return (
        <PopoverContainer
            id="accounts-filter"
            buttonSize="sm"
            popoverClass={styles.popover}
            buttonActiveClass=""
            buttonText={
                <AccountFilterTrigger value={props.filters.entitiesAccounts} />
            }
            offset={4}
        >
            <AccountFilterOverlay {...props} />
        </PopoverContainer>
    );
};
