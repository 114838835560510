import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import {
    Transaction,
    TransactionClassification as TransactionClassificationValues,
} from "../../common/types/transaction";
import { TRANSACTION_CLASSIFICATION_LABELS } from "../../common/constants";
import {
    CheckboxButtonGroup,
    CheckboxButtonOption,
} from "../general/RadioButtonGroup/CheckboxButtonGroup";
import { useChangeTransactionEntity } from "./useChangeTransactionEntity";
import { useChangeTransactionToPersonal } from "./useChangeTransactionToPersonal";
import { ObjectIcon } from "../general/ObjectIcon/ObjectIcon";
import { useEntities } from "../../hooks/useEntities";
import { EntityIcon } from "../entity/EntityIcon";
import "./TransactionEntity.scss";
import { useClickOutside } from "../../hooks/useClickOutside";
import classNames from "classnames";
import { Dropdown, Overlay, Popover } from "react-bootstrap";
import { TransactionEntityMenuItem } from "./TransactionEntityMenuItem";
import "./TransactionEntityMenu.scss";
import { DEFAULT_ICON_COLOR } from "../../constants";
import { TriangleIcon } from "../../icons";

interface Props {
    transaction: Transaction;
}

export const TransactionEntity: React.FC<Props> = ({ transaction }) => {
    const changeToBusiness = useChangeTransactionEntity(transaction);
    const changeToPersonal = useChangeTransactionToPersonal(transaction);
    const [changingBusiness, setChangingBusiness] = useState(false);
    const entities = useEntities({ onlyBusiness: true });
    const multiBusiness = entities.length > 1;
    const selectedInUi = changingBusiness || transaction.isBusiness;

    const getLabel = useCallback(
        (isBusiness: boolean) => {
            if (isBusiness && multiBusiness) {
                return transaction.entity.name;
            } else {
                return TRANSACTION_CLASSIFICATION_LABELS[
                    isBusiness
                        ? TransactionClassificationValues.BUSINESS
                        : TransactionClassificationValues.PERSONAL
                ];
            }
        },
        [multiBusiness, transaction.entity],
    );

    const options: CheckboxButtonOption<boolean>[] = useMemo(
        () =>
            Object.values(TransactionClassificationValues).map(
                (classification) => {
                    const isBusiness =
                        classification ===
                        TransactionClassificationValues.BUSINESS;

                    return {
                        value: isBusiness,
                        label: (
                            <>
                                {transaction.isBusiness === isBusiness ? (
                                    <>
                                        <EntityIcon
                                            entity={transaction.entity}
                                            size="xs"
                                        />
                                        <span className="transaction-entity__name">
                                            {getLabel(isBusiness)}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <ObjectIcon
                                            name={
                                                TRANSACTION_CLASSIFICATION_LABELS[
                                                    classification
                                                ]
                                            }
                                            defaultColor={DEFAULT_ICON_COLOR}
                                            size="xs"
                                            square
                                        />
                                        {
                                            TRANSACTION_CLASSIFICATION_LABELS[
                                                classification
                                            ]
                                        }
                                    </>
                                )}
                                {isBusiness && multiBusiness && (
                                    <TriangleIcon className="transaction-entity__toggle" />
                                )}
                            </>
                        ),
                    };
                },
            ),
        [transaction.isBusiness, transaction.entity, getLabel, multiBusiness],
    );

    const handleChange = useCallback(
        (e: MouseEvent, isBusiness: boolean) => {
            e.stopPropagation();
            if (
                isBusiness &&
                (!transaction.isBusiness || entities.length > 1)
            ) {
                if (entities.length > 1) {
                    setChangingBusiness(!changingBusiness);
                } else {
                    changeToBusiness.mutate(entities[0]);
                }
            }

            if (!isBusiness && transaction.isBusiness) {
                setChangingBusiness(false);
                changeToPersonal.mutate();
            }
        },
        [
            transaction.isBusiness,
            entities,
            changingBusiness,
            changeToBusiness,
            changeToPersonal,
        ],
    );

    const target = useClickOutside(() => setChangingBusiness(false));

    return (
        <section
            data-testid="transaction-entity-section"
            className={classNames("transaction-entity", {
                "transaction-entity--menu-opened": changingBusiness,
                "transaction-entity--multi-business": multiBusiness,
            })}
            ref={target}
        >
            <CheckboxButtonGroup
                options={options}
                value={selectedInUi}
                onChange={(value, e) => handleChange(e, value)}
                loading={
                    changeToBusiness.isLoading || changeToPersonal.isLoading
                }
            />

            <Overlay
                target={target.current}
                show={changingBusiness}
                placement="bottom-start"
            >
                <Popover
                    id={`transaction-entity-${transaction.id}`}
                    className="transaction-entity-menu"
                >
                    <Dropdown.Menu show>
                        {entities.map((entity) => (
                            <TransactionEntityMenuItem
                                key={entity.id}
                                transaction={transaction}
                                entity={entity}
                                onSelect={() => {
                                    changeToBusiness.mutate(entity);
                                    setChangingBusiness(false);
                                }}
                            />
                        ))}
                    </Dropdown.Menu>
                </Popover>
            </Overlay>
        </section>
    );
};
