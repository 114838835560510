export const defaultPopperConfig = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, 8],
            },
        },
    ],
};

export const topStartPopperConfig = {
    modifiers: [
        {
            name: "arrow",
            options: {
                padding: (({ popper }) => ({ right: popper.width - 30 })) as (
                    config: any,
                ) => any,
            },
        },
    ],
};
