import { backendClient, unwrapResponse } from "./backendClient";
import { CalculatePriceDto } from "../common/dto/billing/flatRate/calculate-price.dto";
import { CalculatePriceResponseDto } from "../common/dto/billing/flatRate/calculate-price-response.dto";
import { PrepareCheckoutResponseDto } from "../common/dto/billing/flatRate/prepare-checkout-response.dto";
import { SetupSubscriptionDto } from "../common/dto/billing/flatRate/setup-subscription.dto";
import { SetupSubscriptionResponseDto } from "../common/dto/billing/flatRate/setup-subscription-response.dto";
import { FLAT_RATE_PLANS } from "../common/flatRateBilling";

export async function getPricing(payload: CalculatePriceDto) {
    const response = unwrapResponse(
        await backendClient.post(`/flat-rate-billing/calculate-price`, payload),
    );

    return CalculatePriceResponseDto.deserialize(response);
}

export async function prepareCheckout(): Promise<PrepareCheckoutResponseDto> {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/prepare-checkout`),
    );
}

export async function setupSubscription(payload: SetupSubscriptionDto) {
    const response = unwrapResponse(
        await backendClient.post(`/flat-rate-billing/setup`, payload),
    );

    return response as SetupSubscriptionResponseDto;
}

export async function checkout(setupId: string) {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/checkout`, { setupId }),
    );
}

export async function changePlan(plan: FLAT_RATE_PLANS) {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/change-plan`, { plan }),
    );
}

export async function startEnterpriseTrial() {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/enterprise-trial`),
    );
}

export async function convertTrial() {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/convert-trial`),
    );
}

export async function addPreviousYear() {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/add-previous-year`),
    );
}
