import React from "react";
import { Modal } from "react-bootstrap";
import {
    TransactionsDrillDownModalParams,
    useTransactionsDrillDownContext,
} from "./transactionsDrillDown.context";
import { ReportFilters } from "../../common/types/filters/reports";
import { format, parse } from "date-fns";
import {
    getCycleEndForDate,
    getLabelFormatForCycle,
} from "../../common/helpers/reports";
import { REPORTS_CONTROLLER_DATE_FORMAT } from "../../common/constants";
import { ProfitLossChartReportSeriesIdentifier } from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { useQuery } from "react-query";
import { getProfitLossTabTopTransactions } from "../../lib/ownerReports";
import { Taxonomy } from "../../common/categories";
import { Loader } from "../general/Loader";
import { currencyFormatterFinancialStatements } from "../../common/helpers/currency";
import { StandardModal } from "../general/Modal/Modal";

interface Props {
    filters: ReportFilters;
    reportCreatedAt: number;
}

interface CreateRequestDtoParams {
    label: string;
    category: Taxonomy;
    filters: ReportFilters;
    cacheBuster: number;
}

function createRequestDto({
    label,
    category,
    filters,
    cacheBuster,
}: CreateRequestDtoParams) {
    const start = parse(
        label,
        getLabelFormatForCycle(filters.cycle),
        new Date(),
    );

    return {
        startDate: format(start, REPORTS_CONTROLLER_DATE_FORMAT),
        endDate: format(
            getCycleEndForDate(start, filters.cycle),
            REPORTS_CONTROLLER_DATE_FORMAT,
        ),
        entitiesAccounts: filters.entitiesAccounts,
        categoryIdentifier: category as ProfitLossChartReportSeriesIdentifier,
        itemsShown: [] as ProfitLossChartReportSeriesIdentifier[],
        cacheBuster,
    };
}
function getQueryKey(
    params: TransactionsDrillDownModalParams,
    filters: ReportFilters,
    cacheBuster: number,
) {
    return [
        "TransactionsDrillDown",
        params.label,
        params.category,
        filters.start,
        filters.end,
        filters.cycle,
        filters.entitiesAccounts,
        cacheBuster,
    ];
}
export const TransactionsDrillDownModal: React.FC<Props> = ({
    filters,
    reportCreatedAt,
}) => {
    const { params, close } = useTransactionsDrillDownContext();
    const { data, isLoading } = useQuery(
        getQueryKey(params, filters, reportCreatedAt),
        () => {
            if (params.label && params.category) {
                return getProfitLossTabTopTransactions(
                    createRequestDto({
                        label: params.label,
                        category: params.category,
                        filters,
                        cacheBuster: reportCreatedAt,
                    }),
                );
            }
        },
    );
    const showModal =
        params.label !== undefined && params.category !== undefined;
    // proper API is out of scope for now, so we are using same endpoint as chart tooltip, just ignoring 'other' section from it, and displaying few top transactions
    return (
        <StandardModal show={showModal} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>Transactions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loader />}
                {!isLoading && data && (
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.top.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.description}</td>
                                    <td>
                                        {currencyFormatterFinancialStatements.format(
                                            item.value,
                                            {
                                                addCurrency: true,
                                                displaySign: false,
                                            },
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </Modal.Body>
        </StandardModal>
    );
};
