import React from "react";
import { Tab } from "react-bootstrap";
import { CashBasedProfitLoss } from "./CashBasedProfitLoss";
import { Spending } from "./Spending";
import { ReportType } from "../../helpers/reports";

interface Props {
    resetFilters: () => void;
}

export const ReportsPageContent: React.FC<Props> = ({ resetFilters }) => {
    return (
        <Tab.Content>
            <Tab.Pane eventKey={ReportType.PROFIT_LOSS}>
                <CashBasedProfitLoss resetFilters={resetFilters} />
            </Tab.Pane>
            <Tab.Pane eventKey={ReportType.SPENDING}>
                <Spending resetFilters={resetFilters} />
            </Tab.Pane>
        </Tab.Content>
    );
};
