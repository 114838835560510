import React from "react";
import classNames from "classnames";

export interface CaptureClicksProps {
    className?: string;
    children: React.ReactNode;
}
export const CaptureClicks: React.FC<CaptureClicksProps> = ({
    className,
    children,
}) => (
    <span
        className={classNames("d-inline-flex", className)}
        onClick={(ev) => ev.stopPropagation()}
    >
        {children}
    </span>
);
