import React, { forwardRef, useMemo } from "react";
import styles from "./EditableTable.module.scss";
import classNames from "classnames";
import {
    EditableTableCell,
    EditableTableCellSelectionState,
} from "./EditableTableCell";
import { Field, FieldProps } from "formik";
import {
    EditableCellSearchOption,
    EditableTableCellSearch,
} from "./EditableTableCellSearch";
import { EditableTableCellNumber } from "./EditableTableCellNumber";
import { EditableTableCellString } from "./EditableTableCellString";
import { JournalEntryTableLineStateValue } from "./EditableTableBody";

export interface EditableTableRowProps {
    id: string;
    lineIndex: number;
    selectionState: EditableTableCellSelectionState[] | undefined;
    insertToThisLine: boolean;
    hasSelectedAllLines: boolean;
    onChangeCallback:
        | undefined
        | Array<(value: JournalEntryTableLineStateValue) => void>;
    onClickCallback: undefined | Array<() => void>;
    searchOptions: EditableCellSearchOption[];
    isEditable?: boolean;
}

export const EditableTableRow = React.memo(
    forwardRef(
        (
            {
                id,
                lineIndex,
                selectionState,
                insertToThisLine,
                hasSelectedAllLines,
                onChangeCallback,
                onClickCallback,
                searchOptions,
                isEditable,
            }: EditableTableRowProps,
            ref: React.Ref<HTMLDivElement>,
        ) => {
            const cellOrderContent = useMemo(() => {
                return (
                    <label
                        className={classNames(
                            "title small",
                            styles.editableTableTitle,
                        )}
                    >
                        {lineIndex + 1}
                    </label>
                );
            }, [lineIndex]);

            return (
                <div className={styles.editableTableRow}>
                    {insertToThisLine && (
                        <div className={styles.editableTableRowInsertionLine} />
                    )}
                    <EditableTableCell
                        key={`order-${id}`}
                        id={`order-${id}`}
                        isFirstRow={lineIndex === 0}
                        className={styles.editableTableOrderCell}
                        selectionState={selectionState?.[0]}
                        draggable={selectionState?.[0] && !hasSelectedAllLines}
                    >
                        {cellOrderContent}
                    </EditableTableCell>
                    <Field
                        name={`journalEntry.lines[${lineIndex}].accountCode`}
                    >
                        {({ meta }: FieldProps) => {
                            return (
                                <EditableTableCellSearch
                                    key={`account-${id}`}
                                    id={`account-${id}`}
                                    isFirstRow={lineIndex === 0}
                                    options={searchOptions}
                                    onChange={onChangeCallback?.[1]}
                                    value={meta.value}
                                    error={meta.error}
                                    selectionState={selectionState?.[1]}
                                    onClick={onClickCallback?.[1]}
                                    isDisabled={!isEditable}
                                />
                            );
                        }}
                    </Field>

                    <Field
                        name={`journalEntry.lines[${lineIndex}].debitAmount`}
                    >
                        {({ meta }: FieldProps) => {
                            return (
                                <EditableTableCellNumber
                                    key={`debit-${id}`}
                                    id={`debit-${id}`}
                                    isFirstRow={lineIndex === 0}
                                    onChange={onChangeCallback?.[2]}
                                    value={meta.value}
                                    error={meta.error}
                                    selectionState={selectionState?.[2]}
                                    onClick={onClickCallback?.[2]}
                                    isDisabled={!isEditable}
                                />
                            );
                        }}
                    </Field>

                    <Field
                        name={`journalEntry.lines[${lineIndex}].creditAmount`}
                    >
                        {({ meta }: FieldProps) => {
                            return (
                                <EditableTableCellNumber
                                    key={`credit-${id}`}
                                    id={`credit-${id}`}
                                    isFirstRow={lineIndex === 0}
                                    onChange={onChangeCallback?.[3]}
                                    value={meta.value}
                                    error={meta.error}
                                    selectionState={selectionState?.[3]}
                                    onClick={onClickCallback?.[3]}
                                    isDisabled={!isEditable}
                                />
                            );
                        }}
                    </Field>
                    <Field
                        name={`journalEntry.lines[${lineIndex}].description`}
                    >
                        {({ meta }: FieldProps) => {
                            return (
                                <EditableTableCellString
                                    key={`description-${id}`}
                                    id={`description-${id}`}
                                    isFirstRow={lineIndex === 0}
                                    onChange={onChangeCallback?.[4]}
                                    value={meta.value}
                                    error={meta.error}
                                    selectionState={selectionState?.[4]}
                                    onClick={onClickCallback?.[4]}
                                />
                            );
                        }}
                    </Field>
                </div>
            );
        },
    ),
);
