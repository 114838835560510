import React, { ReactNode } from "react";
import { clamp } from "lodash";
import { PricingTier } from "../../../../../common/billing";
import styles from "./PricingTierWithUsage.module.scss";
import classNames from "classnames";
import {
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../../../common/helpers/currency";

export enum TierVariant {
    PRIMARY = "primary",
    WARNING = "warning",
    NEUTRAL = "neutral",
}

interface Props {
    usage: number;
    tier: PricingTier;
    tag?: ReactNode;
    variant: TierVariant;
    outOfCredits?: boolean;
}

export const PricingTierWithUsage: React.FC<Props> = ({
    tier,
    usage,
    tag,
    children,
    variant,
    outOfCredits,
}) => {
    const totalTierUsage = tier.end - tier.start;
    const savingsCreditsUsedInTier = clamp(
        usage - tier.start,
        0,
        totalTierUsage,
    );

    const progress = Math.round(
        (savingsCreditsUsedInTier / totalTierUsage) * 100,
    );

    return (
        <article
            className={classNames(styles.tier, {
                [styles.emptyTier]: progress === 0,
                [styles.fullTier]: progress === 100,
                [styles.primaryTierVariant]: variant === TierVariant.PRIMARY,
                [styles.warningTierVariant]: variant === TierVariant.WARNING,
                [styles.warningProgress]: outOfCredits,
            })}
            style={{ "--progress": `${progress}%` } as any}
        >
            <div className={styles.track}>
                <div className={styles.progress} />
            </div>

            <span className={styles.currentValue}>{children}</span>

            <header className={styles.header}>
                {tier.price === 0 ? (
                    "Free"
                ) : (
                    <>
                        {currencyFormatterNoFractions.format(tier.price)}
                        <small>/m</small>
                    </>
                )}
            </header>

            {tag}

            <aside className={styles.tierMin}>
                {currencyFormatterShort.format(tier.start).toLowerCase()}
            </aside>
            <aside className={styles.tierMax}>
                {currencyFormatterShort.format(tier.end).toLowerCase()}
            </aside>
        </article>
    );
};
