import React from "react";
import { dateTimeFormat } from "../../../common/helpers/date";
import {
    EventConnection,
    EventType,
    LogStreamEvent,
} from "../../../common/types/logStreamEvent";
import { format } from "date-fns";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface LogStreamEventRowProps {
    log: LogStreamEvent;
}

const eventTypeLabel: Record<EventType, string> = {
    [EventType.LOGIN_FAILED]: "Failed login",
    [EventType.LOGIN_SUCCESS]: "Login",
    [EventType.LOGOUT_SUCCESS]: "Logout",
    [EventType.LOGOUT_FAILED]: "Failed logout",
};

export const LogStreamEventRow: React.FC<LogStreamEventRowProps> = ({
    log,
}) => {
    let label = eventTypeLabel[log.eventType];

    if (
        [EventType.LOGIN_SUCCESS, EventType.LOGIN_FAILED].includes(
            log.eventType,
        )
    ) {
        label += ` (${
            log.connection === EventConnection.SMS ? "phone" : "email"
        })`;
    }

    return (
        <tr className="log-stream-events__row">
            <td>{label}</td>
            <td>
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Tooltip id={`user_agent_${log.logId}`}>
                            {log.userAgent}
                        </Tooltip>
                    }
                >
                    <span className="log-stream-events__short">
                        {log.userAgent}
                    </span>
                </OverlayTrigger>
            </td>
            <td>
                <OverlayTrigger
                    placement="auto"
                    overlay={<Tooltip id={`ip_${log.logId}`}>{log.ip}</Tooltip>}
                >
                    <span className="log-stream-events__short">{log.ip}</span>
                </OverlayTrigger>
            </td>
            <td>{format(new Date(log.date), dateTimeFormat)}</td>
            <td>{log.ipLocation}</td>
        </tr>
    );
};
