import React from "react";
import { useBillingHistory } from "../../../../hooks/useBillingHistory";
import styles from "./FlatRateBilling.module.scss";
import { SettingsSection } from "../../SettingsSection";
import { Card } from "../../../general/Card/Card";
import classNames from "classnames";
import { format } from "date-fns";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import { InvoiceStatus } from "../../../../common/billing";
import { currencyFormatterNoFractions } from "../../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../../common/helpers/monetary";
import { Button } from "react-bootstrap";
import { DownloadIcon } from "../../../../icons";
import { Loader } from "../../../general/Loader";

export const invoiceStatusLabel = {
    [InvoiceStatus.DRAFT]: "Draft",
    [InvoiceStatus.OPEN]: "Open",
    [InvoiceStatus.PAID]: "Paid",
    [InvoiceStatus.VOID]: "Voided",
    [InvoiceStatus.UNCOLLECTIBLE]: "Uncollectible",
} as const satisfies Record<InvoiceStatus, string>;

export const BillingHistory: React.FC = () => {
    const { data: billingHistory, isFetched } = useBillingHistory();

    if (!isFetched) {
        return <Loader />;
    }

    if (!billingHistory?.invoices?.length) {
        return null;
    }

    return (
        <SettingsSection heading="Billing history">
            <Card className={classNames(styles.card, styles.invoices)}>
                {billingHistory.invoices.map((invoice) => (
                    <section
                        key={invoice.date.toISOString()}
                        className={classNames(styles.section, styles.compact)}
                    >
                        <strong>
                            {format(invoice.date, fullDateFullYearFormat)}
                        </strong>

                        <span className="text-right">
                            {invoiceStatusLabel[invoice.status]}
                        </span>
                        <span className="text-gray-700">
                            {currencyFormatterNoFractions.format(
                                fromIntToMonetary(invoice.totalInCents),
                            )}
                        </span>

                        {invoice.url ? (
                            <Button
                                variant="secondary"
                                className="btn-icon"
                                size="sm"
                                href={invoice.url}
                                target="_blank"
                            >
                                <DownloadIcon />
                            </Button>
                        ) : null}
                    </section>
                ))}
            </Card>
        </SettingsSection>
    );
};
