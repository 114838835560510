import { useMemo } from "react";
import { useEntitiesContexts } from "../../../hooks/useEntitiesContexts";

export function useAccountIdsByEntity() {
    const entitiesContexts = useEntitiesContexts();

    return useMemo(
        () =>
            entitiesContexts?.reduce(
                (acc, context) => {
                    acc[context.entity.id] = context.financialAccounts.map(
                        (a) => a.id,
                    );

                    return acc;
                },
                {} as Record<number, number[]>,
            ) ?? {},
        [entitiesContexts],
    );
}
