import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import classnames from "classnames";
import "./ButtonWithLoader.scss";
import { noop } from "../../../helpers/general";
import { SpinnerIcon } from "../../../icons";
import { UseMutationResult } from "react-query";

interface CommonProps extends ButtonProps {
    className?: string;
}

interface HandlerProps extends CommonProps {
    loading: boolean;
    setLoading?: (isLoading: boolean) => void;
    onClick?: () => Promise<unknown> | void;
    mutation?: never;
}

interface MutationProps extends CommonProps {
    loading?: never;
    setLoading?: never;
    onClick?: never;
    mutation: UseMutationResult<unknown, unknown, void>;
}

export const ButtonWithLoader: React.FC<HandlerProps | MutationProps> = ({
    loading,
    setLoading = noop,
    onClick,
    children,
    className = "",
    mutation,
    ...props
}) => {
    if (!mutation && loading === undefined) {
        throw new Error("Either mutation or loading must be set");
    }

    const finalClassName = classnames(`btn-with-loader ${className}`, {
        "btn-with-loader--loading": loading ?? mutation?.isLoading,
    });

    const handleClick = () => {
        if (onClick) {
            if (loading) {
                return;
            }

            setLoading(true);

            const promise = onClick();

            if (promise) {
                promise.finally(() => setLoading(false));
            } else {
                setLoading(false);
            }
        } else if (mutation) {
            if (mutation.isLoading) {
                return;
            }

            mutation.mutate();
        }
    };

    return (
        <Button className={finalClassName} onClick={handleClick} {...props}>
            <span className="btn-with-loader__content">{children}</span>
            <aside className="btn-with-loader__spinner">
                <SpinnerIcon className="icon-color-current" />
            </aside>
        </Button>
    );
};
