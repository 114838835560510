import React from "react";
import { useQuery } from "react-query";
import { getTransactionsActivity } from "../../../lib/transactions";
import { Loader } from "../../general/Loader";
import { AutopilotActivitySection } from "./AutopilotActivitySection";
import { format, parseISO } from "date-fns";

export const AutopilotActivity: React.FC = () => {
    const activity = useQuery("transactionsActivity", () =>
        getTransactionsActivity(),
    );

    if (activity.isLoading) {
        return <Loader />;
    }

    const noActivity =
        activity.data &&
        !activity.data.last7Days.length &&
        !activity.data.monthly.length;

    if (noActivity) {
        return <>No activity</>;
    }

    return (
        <>
            {activity.data && activity.data.last7Days.length > 0 ? (
                <AutopilotActivitySection
                    title="Last 7 days"
                    rows={activity.data.last7Days}
                />
            ) : null}

            {activity.data?.monthly.map((month) => (
                <AutopilotActivitySection
                    key={month.date}
                    title={format(parseISO(month.date), "MMMM")}
                    rows={month.rows}
                />
            ))}
        </>
    );
};
