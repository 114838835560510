import React, { useCallback, useMemo, useState } from "react";
import { Transaction } from "../../../common/types/transaction";
import { AutopilotCard } from "../AutopilotCard";
import styles from "./AutopilotPossibleSaving.module.scss";
import { useEntities } from "../../../hooks/useEntities";
import { Entity } from "../../../common/types/entity";
import { Taxonomy } from "../../../common/categories";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useUpdateTransactionMutation } from "../../../mutations/transaction";
import { AutopilotSavingsFirstSection } from "./AutopilotSavingsFirstSection";
import { AutopilotSavingsOtherSections } from "./AutopilotSavingsOtherSections";
import { Amount } from "../../general/Amount/Amount";
import { AutopilotCardCompleted } from "../AutopilotCardCompleted";
import { PossibleSavingType } from "../../../common/dto/transactions/get-possible-savings-response.dto";
import { CustomCategory } from "../../../common/types/customCategory";

interface Props {
    type: PossibleSavingType;
    transaction: Transaction;
    onConfirmed: (transaction: Transaction) => void;
    onOpenNext: (currentlyOpened: Transaction) => void;
    onClick: (transaction: Transaction) => void;
    opened?: boolean;
}

export const AutopilotPossibleSaving: React.FC<Props> = ({
    type,
    transaction,
    onConfirmed,
    onOpenNext,
    onClick,
    opened,
}) => {
    const entities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
    });
    const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>(
        entities[0],
    );
    const [selectedCategory, setSelectedCategory] = useState<
        Taxonomy | CustomCategory | undefined
    >(() => {
        switch (type) {
            case PossibleSavingType.SOFTWARE_SUBS:
                return Taxonomy.software_subscriptions;
            case PossibleSavingType.MEALS:
                return Taxonomy.meals;
            default:
                return undefined;
        }
    });

    const updateTransaction = useUpdateTransactionMutation();
    const doUpdate = useCallback(async () => {
        await updateTransaction.mutateAsync({
            transaction,
            update: {
                entityId: selectedEntity?.id,
                ...(typeof selectedCategory === "string"
                    ? {
                          category: selectedCategory,
                      }
                    : {
                          customCategoryId: selectedCategory!.id,
                      }),
            },
        });
    }, [selectedEntity, selectedCategory, updateTransaction, transaction]);
    const isCompleted = updateTransaction.isSuccess;
    const amountSaved = useMemo(() => {
        if (updateTransaction.data && "transaction" in updateTransaction.data) {
            return updateTransaction.data.transaction.amountSaved;
        }
    }, [updateTransaction.data]);

    const ctas = (
        <ButtonWithLoader
            onClick={doUpdate}
            loading={updateTransaction.isLoading}
            size="sm"
        >
            Confirm
        </ButtonWithLoader>
    );

    const firstSectionGetter = useCallback(
        (style: any, inOpened?: boolean) =>
            isCompleted ? (
                <AutopilotCardCompleted header="Write-off confirmed">
                    <div className={styles.writeOff}>
                        {amountSaved && (
                            <Amount amount={amountSaved} showPositive />
                        )}
                    </div>
                </AutopilotCardCompleted>
            ) : (
                <AutopilotSavingsFirstSection
                    style={style}
                    inOpened={inOpened}
                    transaction={transaction}
                />
            ),
        [transaction, isCompleted, amountSaved],
    );

    const otherSectionsGetter = useCallback(
        (inOpened?: boolean) => (
            <AutopilotSavingsOtherSections
                transaction={transaction}
                entities={entities}
                selectedEntity={selectedEntity}
                setSelectedEntity={setSelectedEntity}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                inOpened={inOpened}
            />
        ),
        [transaction, entities, selectedEntity, selectedCategory],
    );

    const onClosing = useCallback(() => {
        onOpenNext(transaction);
    }, [onOpenNext, transaction]);

    const onClosed = useCallback(() => {
        onConfirmed(transaction);
    }, [onConfirmed, transaction]);

    return (
        <AutopilotCard
            ctas={ctas}
            onClick={() => onClick(transaction)}
            isCompleted={isCompleted}
            opened={opened}
            firstSection={firstSectionGetter}
            otherSections={otherSectionsGetter}
            onCardClosing={onClosing}
            onCardClosed={onClosed}
        />
    );
};
