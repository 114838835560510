import React from "react";
import { TransactionMatch } from "../../../../common/types/transactionMatch";
import { Transaction } from "../../../../common/types/transaction";
import { useDownloadFinancialDocumentFile } from "../../../documents/FinancialDocumentDetails/useDownloadFinancialDocumentFile";
import { useDetachTransactionMatchMutation } from "../../../../mutations/transaction";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { DetachIcon, TrashIcon } from "../../../../icons";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./TransactionMatches.module.scss";
import { useRemoveFinancialDocumentMutation } from "../../../../mutations/financialDocument";
import classNames from "classnames";

interface Props {
    transactionMatch: EagerLoaded<TransactionMatch, "financialDocument">;
    financialTransaction: Transaction;
}

export const TransactionMatchFinancialDocument: React.FC<Props> = ({
    financialTransaction,
    transactionMatch,
}) => {
    const { download } = useDownloadFinancialDocumentFile(
        transactionMatch.financialDocument,
    );
    const detachMutation = useDetachTransactionMatchMutation(
        financialTransaction,
        transactionMatch,
    );

    const removeDocumentMutation = useRemoveFinancialDocumentMutation();

    return (
        <div className={styles.match} data-testid="transaction-document">
            <Button
                data-testid="transaction-document-name"
                variant="link"
                size="sm"
                className={classNames("mr-auto", styles.documentName)}
                onClick={download}
            >
                <span>{transactionMatch.financialDocument.fileName}</span>
            </Button>

            <OverlayTrigger
                overlay={
                    <Tooltip id={`detach-${transactionMatch.id}`}>
                        Detach from this transaction
                    </Tooltip>
                }
            >
                <ButtonWithLoader
                    data-testid="detach-document-button"
                    variant="plain"
                    className="btn-icon"
                    size={"xs" as any}
                    mutation={detachMutation}
                >
                    <DetachIcon />
                </ButtonWithLoader>
            </OverlayTrigger>

            <OverlayTrigger
                overlay={
                    <Tooltip id={`detach-${transactionMatch.id}`}>
                        Delete receipt
                    </Tooltip>
                }
            >
                <ButtonWithLoader
                    data-testid="remove-document-button"
                    variant="plain"
                    className="btn-icon"
                    size={"xs" as any}
                    loading={removeDocumentMutation.isLoading}
                    onClick={() =>
                        removeDocumentMutation.mutate(
                            transactionMatch.financialDocument,
                        )
                    }
                >
                    <TrashIcon />
                </ButtonWithLoader>
            </OverlayTrigger>
        </div>
    );
};
