import React, { Fragment } from "react";
import classNames from "classnames";
import { noop } from "../../../helpers/general";
import {
    PLColumns,
    sectionLabels,
} from "../ProfitLossReportTable/useProfitLossReportTable";
import { PLDataRow } from "../ProfitLossReportTable/ProfitLossReportTableContainer";
import { Cell } from "@tanstack/react-table";
import { Taxonomy } from "../../../common/categories";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { useCategoryMap } from "../../../hooks/useCategoryMap";
import { rowExpansionHelper } from "../ProfitLossReportTable/rowExpansionHelper";
import { range } from "lodash";
import {
    ProfitLossReportSummarySections,
    ProfitLossTableReportSection,
} from "../../../common/dto/reports/reports-common.dto";

interface RenderCellProps {
    cell: Cell<PLDataRow, any>;
}
const NUMBER_FORMAT_CURRENCY_NEGATIVES = '"$"#,##0.00;\\-"$"#,##0.00';
const NUMBER_FORMAT_CURRENCY_NO_NEGATIVES = '"$"#,##0.00;"$"#,##0.00';
const NUMBER_FORMAT_DIGITS_NO_NEGATIVES = "#,##0.00;#,##0.00";

function getNumberFn(section: string, isTotal: boolean) {
    if (
        ProfitLossReportSummarySections.includes(
            section as ProfitLossTableReportSection,
        )
    ) {
        return NUMBER_FORMAT_CURRENCY_NEGATIVES;
    }
    if (isTotal) {
        return NUMBER_FORMAT_CURRENCY_NO_NEGATIVES;
    }
    return NUMBER_FORMAT_DIGITS_NO_NEGATIVES;
}
const INDENT_UNIT = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

function renderIndent(depth: number) {
    return range(depth).map((i) => <Fragment key={i}>{INDENT_UNIT}</Fragment>);
}

const Title: React.FC<RenderCellProps> = ({ cell }) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useCategoryMap();
    const { original, depth } = cell.getContext().row;

    const labelDepth = original.isTotal ? depth - 1 : depth;
    const prefix = original.isTotal ? "Total " : "";

    switch (labelDepth) {
        case 0:
            return (
                <>
                    {renderIndent(depth)}
                    {prefix} {sectionLabels[original.section]}
                </>
            );

        case 1: {
            const categoryDef = categoryMap[original.category as Taxonomy];
            if (!categoryDef) return null;
            if (categoryDef.parentCategoryId) {
                return (
                    <>
                        {renderIndent(depth)}
                        {prefix}
                        {getCategoryLabel(categoryDef.parentCategoryId)}
                    </>
                );
            } else {
                return (
                    <>
                        {renderIndent(depth)}
                        {prefix}
                        {getCategoryLabel(original.category as Taxonomy)}
                    </>
                );
            }
        }
        case 2:
            return (
                <>
                    {renderIndent(depth)}
                    {prefix}
                    {getCategoryLabel(original.category as Taxonomy)}
                </>
            );

        default:
            return null;
    }
};
export const ProfitLossReportDownloadCell: React.FC<RenderCellProps> = ({
    cell,
}) => {
    const categoryMap = useCategoryMap();
    const isTitle = cell.column.id === PLColumns.TITLE;
    const isTotal = cell.row.original.isTotal;
    const parentRow = cell.row.getParentRow();
    const { original, depth } = cell.getContext().row;
    const effectiveDepth = original.isTotal ? depth - 1 : depth;
    const value = isTotal
        ? parentRow?.getValue(cell.column.id)
        : cell.getValue();

    const { expandToggle, canExpand } = rowExpansionHelper(
        cell.row,
        categoryMap,
    );

    const classes = classNames("reports-download-table__table__cell", {
        "reports-download-table__cell--bold": effectiveDepth === 0 || isTotal,
        "reports-download-table__cell--right": !isTitle,
        "reports-download-table__cell--left": isTitle,
        "reports-download-table__cell--border-top": isTotal && !isTitle,
    });

    const numberFN = getNumberFn(original.section, isTotal);

    if (isTitle) {
        return (
            <td className={classes} onClick={canExpand ? expandToggle : noop}>
                <Title cell={cell} />
            </td>
        );
    }

    return (
        <td className={classes} data-z={numberFN}>
            {!cell.getIsAggregated() || !canExpand ? value : null}
        </td>
    );
};
