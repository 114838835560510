import React from "react";
import { FormRowRaw } from "../FormRowRaw";
import { BottomArrowIcon } from "../../../icons";
import classNames from "classnames";

export interface CustomSelectDefaultTriggerProps {
    value?: React.ReactNode;
    label?: string;
    insideLabel?: boolean;
    size?: "sm" | "lg";
    disabled?: boolean;
}

export const CustomSelectDefaultTrigger: React.FC<
    CustomSelectDefaultTriggerProps
> = ({ value, label, insideLabel, size, disabled }) => {
    return (
        <div className="custom-select-default-trigger">
            <FormRowRaw
                value={value}
                label={label}
                insideLabel={insideLabel}
                disabled={disabled}
                field={
                    <div
                        data-testid={`${typeof label === "string" ? label.toLowerCase() : ""}`}
                        className={classNames(
                            [
                                "form-control",
                                size ? `form-control-${size}` : undefined,
                            ],
                            {
                                "form-control-disabled": disabled,
                            },
                        )}
                    >
                        {value}
                    </div>
                }
                icon={!disabled && <BottomArrowIcon />}
                iconPosition="right"
            />
        </div>
    );
};
