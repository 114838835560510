import React from "react";
import { AggregatedFiltersOverlay } from "../../general/AggregatedFilters/AggregatedFiltersOverlay";
import { NavigationItem } from "../../navigation/NavigationItem";
import { CalendarIcon, CheckIcon } from "../../../icons";
import { Nav, Tab } from "react-bootstrap";
import {
    FinancialDocumentFiltersProps,
    FinancialDocumentFilterTabs,
} from "./lib";
import { AggregatedDateFilter } from "../../general/AggregatedFilters/DateFilter/AggregatedDateFilter";
import { FinancialDocumentStatusFilter } from "./FinancialDocumentStatusFilter/FinancialDocumentStatusFilter";

export const FinancialDocumentsFiltersOverlay: React.FC<
    FinancialDocumentFiltersProps
> = ({ filters, onChange }) => {
    return (
        <AggregatedFiltersOverlay
            navigation={
                <>
                    <Nav.Link eventKey={FinancialDocumentFilterTabs.DATE}>
                        <NavigationItem
                            icon={<CalendarIcon />}
                            children="Date"
                            activeIndicator
                        />
                    </Nav.Link>

                    <Nav.Link eventKey={FinancialDocumentFilterTabs.STATUS}>
                        <NavigationItem
                            icon={<CheckIcon />}
                            children="Status"
                            activeIndicator
                        />
                    </Nav.Link>
                </>
            }
            content={
                <>
                    <Tab.Pane eventKey={FinancialDocumentFilterTabs.DATE}>
                        <AggregatedDateFilter
                            filters={filters}
                            onChange={onChange}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={FinancialDocumentFilterTabs.STATUS}>
                        <FinancialDocumentStatusFilter
                            filters={filters}
                            onChange={onChange}
                        />
                    </Tab.Pane>
                </>
            }
        />
    );
};
