import React from "react";
import { TransactionsExport } from "../TransactionsExport";
import { TransactionsFilters } from "../filters/lib";
import { useFiltersActive } from "../useFiltersActive";
import { PageHeader } from "../../general/PageHeader/PageHeader";

interface TransactionsHeaderProps {
    currentFilters: TransactionsFilters;
    exportTransactions: (withFilters: boolean) => Promise<void>;
}

export const TransactionsHeader: React.FC<TransactionsHeaderProps> = ({
    exportTransactions,
    currentFilters,
}) => {
    const filtersActive = useFiltersActive(currentFilters);

    return (
        <PageHeader
            heading="Transactions"
            actions={
                <TransactionsExport
                    exporter={exportTransactions}
                    filtersActive={filtersActive}
                />
            }
        />
    );
};
