import { useMemo, useState } from "react";
import { SortOrder } from "../../common/constants";

export enum TransactionSortValue {
    DATE_ASC = "DATE_ASC",
    DATE_DESC = "DATE_DESC",
    AMOUNT_ASC = "AMOUNT_ASC",
    AMOUNT_DESC = "AMOUNT_DESC",
    NONE = "NONE",
}

export interface Sort {
    field: string;
    order: SortOrder;
}

export type UseSort = {
    currentSort: TransactionSortValue;
    handleSortChange: (value: TransactionSortValue) => void;
    sortExpression: Sort | undefined;
};

export function useSort(
    defaultSort: TransactionSortValue = TransactionSortValue.DATE_DESC,
): UseSort {
    const [currentSort, setCurrentSort] =
        useState<TransactionSortValue>(defaultSort);

    const sortExpression: Sort | undefined = useMemo(() => {
        switch (currentSort) {
            case TransactionSortValue.DATE_DESC:
                return {
                    field: "date",
                    order: SortOrder.DESC,
                };
            case TransactionSortValue.DATE_ASC:
                return {
                    field: "date",
                    order: SortOrder.ASC,
                };
            case TransactionSortValue.AMOUNT_DESC:
                return {
                    field: "amount",
                    order: SortOrder.DESC,
                };
            case TransactionSortValue.AMOUNT_ASC:
                return {
                    field: "amount",
                    order: SortOrder.ASC,
                };
            case TransactionSortValue.NONE:
                return undefined;
        }
    }, [currentSort]);

    return {
        currentSort,
        handleSortChange: setCurrentSort,
        sortExpression,
    };
}
