import React, { useContext, useEffect, useMemo, useState } from "react";
import { AccountsDndContext } from "./AccountsDnd/AccountsDnd.context";
import { useDroppable } from "@dnd-kit/core";
import { useEntity } from "../entity/useEntity";

interface Props {
    children: (isOver: boolean) => React.ReactNode;
    id: string;
}

export const EntityDropArea: React.FC<Props> = ({ id, children }) => {
    const { draggedAccount } = useContext(AccountsDndContext);
    const entity = useEntity();
    const { setNodeRef, isOver, node } = useDroppable({
        id,
    });

    // hardcode height when dragging item to prevent list from shrinking
    const [fixedHeight, setFixedHeight] = useState<number>();
    useEffect(() => {
        if (draggedAccount?.entity?.id === entity.id) {
            setFixedHeight(node.current?.getBoundingClientRect().height);
        } else {
            setFixedHeight(undefined);
        }
    }, [draggedAccount, entity.id, node]);
    const style = useMemo(
        () =>
            fixedHeight
                ? ({
                      "--container-height": fixedHeight + "px",
                  } as React.CSSProperties)
                : undefined,
        [fixedHeight],
    );

    return (
        <div ref={setNodeRef} style={style} className="entity__drop-area">
            {children(isOver)}
        </div>
    );
};
