import React from "react";
import { OnboardingStep } from "../../onboarding/OnboardingStep";
import { BookCallWidget } from "../../general/BookCall/BookCallWidget";
import { ENTERPRISE_TRIAL_CALL_URL } from "../../../common/billing";
import { OnboardingStepActions } from "../../onboarding/OnboardingStepActions";
import styles from "./IntroCall.module.scss";

interface Props {
    back(): void;
    onCallBooked: () => Promise<void>;
}

export const IntroCall: React.FC<Props> = ({ back, onCallBooked }) => {
    return (
        <OnboardingStep
            title="You’re a great fit for Kick’s Partner Program"
            description="Schedule a 30-min walkthrough with a Kick CPA and set up a test account."
            wide
        >
            <BookCallWidget
                url={ENTERPRISE_TRIAL_CALL_URL}
                className={styles.container}
                onBooked={onCallBooked}
            />

            <OnboardingStepActions className="mt-4" onBack={back} />
        </OnboardingStep>
    );
};
