import { PlaidConnection } from "../common/types/plaidConnection";
import { useMutation } from "react-query";
import {
    clearPlaidConnectionErrors,
    connectNewPlaidAccounts,
    connectPlaidConnection,
    connectWithPlaid,
    deletePlaidConnection,
} from "../lib/plaidConnection";
import {
    financialConnectionsQueryKey,
    plaidConnectionsQueryKey,
    queryClient,
} from "../queryClient";
import {
    addNotification,
    updateConnectAccountState,
} from "../reducers/appState";
import { useDispatch } from "react-redux";
import { CreateBankAccountConnectionDto } from "../common/dto/onboarding/create-bank-account-connection.dto";
import { usePlaidFinancialAccounts } from "../hooks/useFinancialAccounts";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
    entitiesAccountsQueryKey,
} from "../queries/entitiesAccounts";
import { IntegrationAccount } from "../common/types/integrationAccount";
import { UserIntegrationsResponse } from "../common/dto/user/user-integrations-response.dto";
import { ConnectionProviderType } from "../common/types/financialConnection";

export function useBankConnectionCreationMutation() {
    const dispatch = useDispatch();

    return useMutation(
        (data: CreateBankAccountConnectionDto) => connectPlaidConnection(data),
        {
            onSuccess: ({ connection, duplicates }) => {
                queryClient.setQueryData<PlaidConnection[]>(
                    plaidConnectionsQueryKey,
                    (connections) => [...(connections ?? []), connection],
                );
                addFinancialConnectionInQueryData(queryClient, connection);

                const hasAccountsNotReadyForSync = connection.accounts.some(
                    (a) => !a.financialAccount.entityId,
                );
                if (hasAccountsNotReadyForSync || duplicates.length > 0) {
                    dispatch(
                        updateConnectAccountState({
                            isConnecting: false,
                            connection: connection,
                            duplicates,
                        }),
                    );
                } else {
                    dispatch(
                        updateConnectAccountState({
                            isConnecting: false,
                            connection: null,
                            duplicates: null,
                        }),
                    );
                }
            },
        },
    );
}

export function useBankConnectionRemovalMutation(connection: PlaidConnection) {
    const dispatch = useDispatch();

    return useMutation(() => deletePlaidConnection(connection.id), {
        onSuccess: () => {
            queryClient.setQueryData<PlaidConnection[] | undefined>(
                plaidConnectionsQueryKey,
                (connections) =>
                    connections?.filter((c) => c.id !== connection.id) ?? [],
            );
            queryClient.setQueryData<UserIntegrationsResponse | undefined>(
                financialConnectionsQueryKey,
                (response) =>
                    response
                        ? {
                              financialConnections:
                                  response.financialConnections.filter(
                                      (fc) =>
                                          fc.connection.id !== connection.id ||
                                          fc.connection.connectionProvider !==
                                              ConnectionProviderType.PLAID,
                                  ),
                          }
                        : undefined,
            );
            queryClient.invalidateQueries(entitiesAccountsQueryKey);
        },
        onError: () => {
            dispatch(
                addNotification({
                    message: "Failed to remove connection",
                    type: "danger",
                }),
            );
        },
    });
}

export function usePlaidConnectionFixingMutation(
    connection: PlaidConnection,
    onSuccess?: () => unknown,
) {
    return useMutation(() => connectWithPlaid(connection.id), {
        onSuccess: async ({ connected }) => {
            if (connected) {
                clearConnectionErrorInQueryData(queryClient, connection.id);

                await clearPlaidConnectionErrors(connection.id);
                onSuccess?.();

                queryClient.setQueryData<PlaidConnection[] | undefined>(
                    plaidConnectionsQueryKey,
                    (connections) =>
                        connections?.map((c) =>
                            c.id === connection.id
                                ? {
                                      ...c,
                                      error: null,
                                  }
                                : c,
                        ) ?? [],
                );
            }
        },
    });
}
export function useAddPlaidAccountsMutation(onSuccess?: () => void) {
    const dispatch = useDispatch();
    const allAccounts = usePlaidFinancialAccounts();

    return useMutation(
        async (connection: PlaidConnection) => {
            const { connected } = await connectWithPlaid(connection.id, true);

            if (connected) {
                dispatch(
                    updateConnectAccountState({
                        isConnecting: true,
                        connection: null,
                        duplicates: null,
                    }),
                );

                return await connectNewPlaidAccounts(connection);
            }
        },
        {
            onSuccess: (response, connection) => {
                const connectionAccounts = allAccounts
                    .flatMap((a) => a.integrationAccounts ?? [])
                    .filter((ia) => ia.connectionId === connection.id);
                const currentAccountsIds = connectionAccounts.map((a) => a.id);

                if (response) {
                    const { connection: updatedConnection, duplicates } =
                        response;
                    const newAccounts: IntegrationAccount[] = [];

                    updatedConnection.accounts.forEach((a) => {
                        const account = { ...a, connection };
                        if (!currentAccountsIds.includes(a.id)) {
                            newAccounts.push(account);
                        }
                    });

                    queryClient.invalidateQueries(entitiesAccountsQueryKey);

                    if (
                        newAccounts.some((a) => !a.financialAccount.entityId) ||
                        duplicates.length > 0
                    ) {
                        dispatch(
                            updateConnectAccountState({
                                isConnecting: false,
                                connection: updatedConnection,
                                duplicates,
                            }),
                        );
                    } else {
                        dispatch(
                            updateConnectAccountState({
                                isConnecting: false,
                                connection: null,
                                duplicates: null,
                            }),
                        );
                        dispatch(
                            addNotification({
                                message: "Accounts reconnected",
                                type: "success",
                            }),
                        );
                    }

                    queryClient.setQueryData<PlaidConnection[] | undefined>(
                        plaidConnectionsQueryKey,
                        (connections) =>
                            connections?.map((c) =>
                                c.id === updatedConnection.id
                                    ? updatedConnection
                                    : c,
                            ) ?? [],
                    );
                } else {
                    dispatch(
                        updateConnectAccountState({
                            isConnecting: false,
                            connection: null,
                            duplicates: null,
                        }),
                    );
                }

                onSuccess?.();
            },
            onError: () => {
                dispatch(
                    updateConnectAccountState({
                        isConnecting: false,
                        connection: null,
                        duplicates: null,
                    }),
                );
            },
        },
    );
}
