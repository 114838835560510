import { EagerLoaded } from "../../types/base/orm";
import { TaxQuestionAnswer } from "../../types/taxQuestionAnswer";
import { Transaction } from "../../types/transaction";
import { TransactionAuditLog } from "../../types/transactionAuditLog";
import { TransactionSplit } from "../../types/transactionSplit";
import { EntityDto } from "../entity/entity.dto";
import { FinancialAccountDto } from "../financialAccount/financial-account.dto";
import { TransactionMatchDto } from "../transactionMatch/transaction-match.dto";
import { TransactionBaseDto } from "./transaction-base.dto";

export class TransactionDto extends TransactionBaseDto {
    financialAccount: FinancialAccountDto;
    entity: EntityDto;
    split?: TransactionSplit[];
    auditLogs: TransactionAuditLog[];
    taxQuestionAnswers: TaxQuestionAnswer[];
    transactionMatches?: TransactionMatchDto[];

    static deserialize(dto: TransactionDto): Transaction {
        return {
            ...dto,
            ...TransactionBaseDto.deserialize(dto),
            financialAccount: FinancialAccountDto.deserialize(
                dto.financialAccount,
            ),
            entity: EntityDto.deserialize(dto.entity),
            transactionMatches: dto.transactionMatches?.map(
                TransactionMatchDto.deserialize,
            ),
        };
    }

    constructor(
        transaction: EagerLoaded<Transaction, "financialAccount" | "entity">,
    ) {
        super(transaction);

        this.financialAccount = new FinancialAccountDto(
            transaction.financialAccount,
        );
        this.entity = new EntityDto(transaction.entity);
        this.auditLogs = transaction.auditLogs;
        this.taxQuestionAnswers = transaction.taxQuestionAnswers;
        this.split = transaction.split;
        this.transactionMatches = transaction.transactionMatches?.map(
            (tm) => new TransactionMatchDto(tm),
        );
    }
}
