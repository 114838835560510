import { GetAccountTransactionsReportDto } from "../../../common/dto/reports/get-account-transactions-report.dto";
import { useCallback, useState } from "react";
import { getAccountTransactions } from "../../../lib/accountingReports";
import { ReportFormat } from "../../../common/helpers/reports";
import fileDownload from "js-file-download";

export function useGeneralLedgerDownload(
    requestDto: GetAccountTransactionsReportDto | null,
) {
    const [downloading, setDownloading] = useState(false);

    const download = useCallback(async () => {
        if (downloading || !requestDto) {
            return;
        }
        setDownloading(true);

        const response = await getAccountTransactions({
            ...requestDto,
            format: ReportFormat.XLSX,
        });

        const fileName =
            response.headers["content-disposition"]?.split("filename=")[1] ??
            "General Ledger.xlsx";

        fileDownload(response.data, fileName, response.headers["content-type"]);

        setDownloading(false);
    }, [requestDto, downloading]);

    return {
        download,
        downloading,
    };
}
