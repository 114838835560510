import React from "react";
import { Button } from "react-bootstrap";
import { ProgressList } from "../general/ProgressList";
import "./OnboardingNav.scss";
import { useUser } from "../../hooks/useUser";

export interface OnboardingNavStep<StepType> {
    keys: StepType[];
    label: string;
}

export interface OnboardingNavProps<StepType> {
    step: StepType;
    steps: OnboardingNavStep<StepType>[];
    logout?: () => void;
}

export const OnboardingNav = /* nosonar */ <StepType extends unknown>({
    step,
    steps,
    logout,
}: OnboardingNavProps<StepType>) => {
    const user = useUser();

    const currentStep = steps.findIndex((s) => s.keys.includes(step)) + 1;

    return (
        <section className="onboarding-nav">
            <ProgressList
                items={steps.length}
                renderStep={(idx) => steps[idx].label}
                currentStep={currentStep}
                className="d-none d-md-block"
            />
            {logout && user && (
                <Button
                    variant="secondary"
                    size="lg"
                    className="onboarding-nav__logout"
                    onClick={logout}
                >
                    Log out
                </Button>
            )}
        </section>
    );
};
