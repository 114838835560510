import React, { RefObject, useCallback, useEffect, useRef } from "react";
import { Loader } from "../../general/Loader";
import { SettingsSection } from "../SettingsSection";
import "./IntegrationsSettings.scss";
import { useQuery } from "react-query";
import { financialConnectionsQueryKey } from "../../../queryClient";
import { useSettings } from "../SettingsProvider";
import Scrollbars from "react-custom-scrollbars-2";
import { AddAccount } from "../../accounts/AddAccount";
import { getUserIntegrations } from "../../../lib/user";
import { FinancialConnectionsSettings } from "./FinancialConnectionsSettings";

interface Props {
    scrollbars: RefObject<Scrollbars>;
}

export const IntegrationsSettings: React.FC<Props> = ({ scrollbars }) => {
    const { path } = useSettings();
    const actionRef = useRef<string | null>(null);

    useEffect(() => {
        const [, action] = path;
        actionRef.current = action;
    }, [path]);

    const integrations = useQuery(financialConnectionsQueryKey, () =>
        getUserIntegrations(),
    );

    const handleFixAction = useCallback(() => {
        const firstBrokenConnection =
            integrations.data?.financialConnections.find(
                (fc) => !!fc.connection.error,
            );

        if (!firstBrokenConnection) {
            return;
        }

        const el = document.querySelector(
            `[data-connection="${firstBrokenConnection.connection.id}"]`,
        );

        if (el && scrollbars.current) {
            const elementRect = el.getBoundingClientRect();
            const scrollbarsRect =
                scrollbars.current.container.getBoundingClientRect();

            scrollbars.current.scrollTop(
                elementRect.top - scrollbarsRect.top - 20,
            );
        }
    }, [integrations.data, scrollbars]);

    useEffect(() => {
        if (integrations.isLoading || !actionRef.current) {
            return;
        }

        if (actionRef.current === "fix") {
            handleFixAction();
        }

        actionRef.current = null;
    }, [integrations.data, handleFixAction, integrations.isLoading]);

    if (!integrations.isSuccess) {
        return <Loader />;
    }

    return (
        <div className="settings__integrations">
            <SettingsSection
                className="settings__integrations__bank-accounts"
                heading={
                    <>
                        <div>
                            <h4>Connected Accounts</h4>
                        </div>

                        <aside>
                            <AddAccount
                                btnText={"Connect new account"}
                                btnVariant={"secondary"}
                                btnSize={"sm"}
                                btnClassName={"mb-0"}
                            />
                        </aside>
                    </>
                }
            >
                <FinancialConnectionsSettings
                    connections={integrations.data.financialConnections}
                />
            </SettingsSection>
        </div>
    );
};
