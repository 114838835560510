import React from "react";
import { TransactionMatch } from "../../../../common/types/transactionMatch";
import styles from "./FinancialDocumentMatches.module.scss";
import { format } from "date-fns";
import { fullDateFormat } from "../../../../common/helpers/date";
import { Amount } from "../../../general/Amount/Amount";
import { useShowTransactionDetails } from "../../../../hooks/useShowTransactionDetails";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ArrowCornerIcon, DetachIcon } from "../../../../icons";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { useDetachFinancialDocumentMutation } from "../../../../mutations/financialDocument";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { FinancialDocument } from "../../../../common/types/financialDocument";

interface Props {
    transactionMatch: EagerLoaded<TransactionMatch, "financialTransaction">;
    financialDocument: FinancialDocument;
}

export const FinancialTransactionMatch: React.FC<Props> = ({
    financialDocument,
    transactionMatch,
}) => {
    const financialTransaction = transactionMatch.financialTransaction;
    const showTransactionDetails = useShowTransactionDetails();

    const detachMutation = useDetachFinancialDocumentMutation(
        financialDocument,
        transactionMatch,
    );

    return (
        <article
            className={styles.item}
            data-testid="financial-document-transaction-match-item"
        >
            <main>
                <div
                    className="text-gray-700"
                    data-testid="financial-document-transaction-match-item-date"
                >
                    {format(financialTransaction.date, fullDateFormat)}
                </div>

                <div
                    className={styles.description}
                    data-testid="financial-document-transaction-match-item-description"
                >
                    {financialTransaction.description}
                </div>
            </main>

            <Amount amount={financialTransaction!.amount} />
            <aside>
                <OverlayTrigger
                    overlay={
                        <Tooltip
                            id={`detach-transaction-${financialTransaction.id}`}
                        >
                            Detach from this transaction
                        </Tooltip>
                    }
                >
                    <ButtonWithLoader
                        variant="plain"
                        className="btn-icon"
                        size={"xs" as any}
                        mutation={detachMutation}
                        data-testid="financial-document-transaction-match-detach-button"
                    >
                        <DetachIcon />
                    </ButtonWithLoader>
                </OverlayTrigger>

                <OverlayTrigger
                    overlay={
                        <Tooltip
                            id={`go-to-transaction-${financialTransaction.id}`}
                        >
                            View transaction
                        </Tooltip>
                    }
                >
                    <Button
                        variant="plain"
                        size={"xs" as any}
                        className="btn-icon"
                        onClick={() =>
                            showTransactionDetails(financialTransaction)
                        }
                        data-testid="financial-document-transaction-match-view-transaction-button"
                    >
                        <ArrowCornerIcon />
                    </Button>
                </OverlayTrigger>
            </aside>
        </article>
    );
};
