import React, { useCallback, useEffect, useState } from "react";
import {
    FINANCIAL_DOCUMENTS_PAGE_SIZE,
    useFinancialDocumentsQuery,
} from "./useFinancialDocumentsQuery";
import { Button, Modal } from "react-bootstrap";
import { FinancialDocumentDetailsContainer } from "./FinancialDocumentDetails/FinancialDocumentDetailsContainer";
import { PageHeader } from "../general/PageHeader/PageHeader";
import { FinancialDocumentsDownload } from "./FinancialDocumentsDownload/FinancialDocumentsDownload";
import { FinancialDocumentsPageFilters } from "./FinancialDocumentsPageFilters/FinancialDocumentsPageFilters";
import { FinancialDocumentsPageBody } from "./FinancialDocumentsPageBody";
import { Pagination } from "../general/Pagination/Pagination";
import { FinancialDocumentsUpload } from "./FinancialDocumentUpload/FinancialDocumentsUpload";
import {
    useChangeEntityMutation,
    useRemoveFinancialDocumentMutation,
} from "../../mutations/financialDocument";
import { StandardModal } from "../general/Modal/Modal";
import { FinancialDocumentBulkSelectionContextProvider } from "./FinancialDocumentBulkSelection/FinancialDocumentBulkSelectionContextProvider";
import { FinancialDocumentActionsBar } from "./FinancialDocumentActionsBar/FinancialDocumentActionsBar";
import { FinancialDocumentBulkActionsProvider } from "./FinancialDocumentBulkActions/FinancialDocumentBulkActions";
import interfaceStyles from "../mainInterface/MainInterface.module.scss";
import { useDocumentDetails } from "./useDocumentDetails";
import { useFinancialDocumentUpload } from "./FinancialDocumentUpload/useFinancialDocumentUpload";
import { DocumentsEmptyState } from "./DocumentsEmptyState";
import documentStyles from "./DocumentsPage.module.scss";
import { useFinancialDocumentFilters } from "./useFinancialDocumentFilters";
import { Loader } from "../general/Loader";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { queryClient } from "../../queryClient";
import { financialDocumentQueryKeys } from "./lib";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";

export const FinancialDocumentsPage: React.FC = () => {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const [page, setPage] = useState<number>(1);
    const { shownDocumentId, showDocumentDetails, closeDetails } =
        useDocumentDetails();
    const onFiltersChange = useCallback(() => setPage(1), []);
    const { filters, resetFilters, hasActiveFilters, handleFiltersChange } =
        useFinancialDocumentFilters(onFiltersChange);

    const currentQueryParams = {
        page: page - 1,
        workspace: activeWorkspaceKey,
        limit: FINANCIAL_DOCUMENTS_PAGE_SIZE,
    };

    const { data: financialDocumentsResponse } = useFinancialDocumentsQuery({
        ...currentQueryParams,
        filters,
    });
    const { uploadingDocuments, clearUploads } = useFinancialDocumentUpload();

    const handleRemoveDocument = useRemoveFinancialDocumentMutation();
    const handleChangeEntity = useChangeEntityMutation();

    const totalIncludingUploading =
        (financialDocumentsResponse?.total ?? 0) + uploadingDocuments.length;

    const upload = (
        <FinancialDocumentsUpload
            render={(open) => (
                <ButtonWithLoader
                    loading={uploadingDocuments.length > 0}
                    variant="primary"
                    onClick={open}
                    size="sm"
                    data-testid="financial-documents-upload-button"
                >
                    Upload
                </ButtonWithLoader>
            )}
        />
    );

    let emptyState: React.ReactNode;

    useEffect(() => {
        if (
            uploadingDocuments.length &&
            uploadingDocuments.every((el) => !el.isUploading)
        ) {
            void queryClient.invalidateQueries(
                financialDocumentQueryKeys.lists(),
            );
            clearUploads();
        }
    }, [clearUploads, uploadingDocuments]);

    if (!financialDocumentsResponse) {
        emptyState = (
            <tbody>
                <tr>
                    <td colSpan={99} className={documentStyles.empty}>
                        <Loader />
                    </td>
                </tr>
            </tbody>
        );
    } else if (totalIncludingUploading === 0) {
        emptyState = (
            <tbody>
                <tr>
                    <td colSpan={99} className={documentStyles.empty}>
                        {hasActiveFilters ? (
                            <DocumentsEmptyState header="The filters don’t match any of the documents.">
                                <Button
                                    size="sm"
                                    variant="option"
                                    onClick={resetFilters}
                                >
                                    Reset all filters
                                </Button>
                            </DocumentsEmptyState>
                        ) : (
                            <DocumentsEmptyState header="Upload or drag and drop your files">
                                {upload}
                            </DocumentsEmptyState>
                        )}
                    </td>
                </tr>
            </tbody>
        );
    }

    const shouldShowPagination =
        financialDocumentsResponse &&
        financialDocumentsResponse.total > 0 &&
        financialDocumentsResponse.pageCount > 1;

    const totalCount = financialDocumentsResponse?.total ?? 0;

    return (
        <>
            <FinancialDocumentBulkSelectionContextProvider
                currentFilters={filters}
                totalCount={totalCount}
            >
                <FinancialDocumentBulkActionsProvider>
                    <article className={interfaceStyles.fullHeightContent}>
                        <PageHeader
                            heading="Documents"
                            actions={
                                <>
                                    {upload}
                                    <FinancialDocumentsDownload />
                                </>
                            }
                        />

                        <section className="mb-4">
                            <FinancialDocumentsPageFilters
                                filters={filters}
                                onChange={handleFiltersChange}
                            />
                        </section>

                        <FinancialDocumentsPageBody
                            emptyState={emptyState}
                            pagination={
                                shouldShowPagination && (
                                    <Pagination
                                        pageCount={
                                            financialDocumentsResponse.pageCount
                                        }
                                        currentPage={page}
                                        totalCount={totalCount}
                                        onPageChange={setPage}
                                        pageSize={FINANCIAL_DOCUMENTS_PAGE_SIZE}
                                    />
                                )
                            }
                            financialDocuments={
                                financialDocumentsResponse?.data
                            }
                            total={totalCount}
                            openDetails={(document) =>
                                showDocumentDetails(document.id)
                            }
                            removeDocument={handleRemoveDocument.mutate}
                            changeEntity={(financialDocument, newEntity) =>
                                handleChangeEntity.mutate({
                                    financialDocument,
                                    newEntity,
                                })
                            }
                        />
                    </article>
                    <FinancialDocumentActionsBar />
                </FinancialDocumentBulkActionsProvider>
            </FinancialDocumentBulkSelectionContextProvider>

            <StandardModal
                className="fullscreen-modal"
                show={!!shownDocumentId}
                onHide={closeDetails}
            >
                <Modal.Header closeButton />
                {shownDocumentId && (
                    <FinancialDocumentDetailsContainer
                        financialDocumentId={shownDocumentId}
                    />
                )}
            </StandardModal>
        </>
    );
};
