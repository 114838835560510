import React from "react";
import { SettingsSection } from "../SettingsSection";
import { CheckIcon, CurrencyGraphIcon, Dollar2Icon } from "../../../icons";
import "./HowItWorks.scss";

export const HowItWorks: React.FC = () => {
    return (
        <SettingsSection
            heading="How it works"
            className="billing__how-it-works"
        >
            <section className="billing__how-it-works__features">
                <article className="billing__how-it-works__feature">
                    <header>
                        <Dollar2Icon /> What are credits?
                    </header>
                    <p>
                        Savings Credits are calculated based on an estimate of
                        your business deductions multiplied by an effective tax
                        rate.
                    </p>
                </article>
                <article className="billing__how-it-works__feature">
                    <header>
                        <CurrencyGraphIcon /> Priced for scale
                    </header>
                    <p>
                        Kick is priced competitively and your cost per
                        categorization decreases as you connect more accounts or
                        businesses.
                    </p>
                </article>
                <article className="billing__how-it-works__feature">
                    <header>
                        <CheckIcon /> Only pay when you save
                    </header>
                    <p>
                        Upgrade to Pro when you use up your $10k in Savings
                        Credits or want to immediately get a handle of a full
                        year of bookkeeping.
                    </p>
                </article>
            </section>
        </SettingsSection>
    );
};
