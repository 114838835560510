import React from "react";
import {
    FinancialAccount,
    FinancialAccountSourceType,
} from "../../common/types/financialAccount";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { displayAccountBalance } from "../../helpers/account";

interface Props {
    account: FinancialAccount;
}

function getTextWhenNoBalance(account: FinancialAccount): string {
    if (account.sourceType === FinancialAccountSourceType.FILE_IMPORT) {
        return "This account does not have a balance because it was created through a manual CSV import.";
    }

    return "No Cash Balance available for this account.";
}

export const FinancialAccountBalance: React.FC<Props> = ({ account }) => {
    const noBalanceText =
        account.balance === null ? getTextWhenNoBalance(account) : null;

    return (
        <OverlayTrigger
            trigger={noBalanceText ? ["hover", "focus"] : []}
            overlay={
                <Tooltip id={`no-balance-${account.id}`}>
                    {noBalanceText}
                </Tooltip>
            }
        >
            <span>{displayAccountBalance(account)}</span>
        </OverlayTrigger>
    );
};
