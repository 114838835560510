import React, { useContext } from "react";
import { ToastMessagesContext } from "./ToastMessages.context";
import { ToastMessage } from "./ToastMessage";
import "./ToastMessages.scss";

export const ToastMessages: React.FC = () => {
    const { items } = useContext(ToastMessagesContext);

    return (
        <div className="toast-messages">
            {items.map(({ key, message }) => (
                <ToastMessage key={key} itemKey={key} message={message} />
            ))}
        </div>
    );
};
