import React, { useCallback } from "react";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";
import { Button } from "react-bootstrap";
import { OnboardingStep } from "../OnboardingStep";
import { Plans } from "../../billing/Plans/Plans";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { ThunderboltIcon } from "../../../icons";
import { usePlanManagement } from "../../billing/PlanManagement/PlanManagementContext";
import { BookCallButton } from "../../general/BookCall/BookCallButton";
import { ENTERPRISE_TRIAL_CALL_URL } from "../../../common/billing";
import { startEnterpriseTrial } from "../../../lib/flatRateBilling";
import { useUpdateUserMutation } from "../../../mutations/user";
import { useRecommendedPlan } from "../../../hooks/useRecommendedPlan";

interface Props {
    onContinue: () => void;
}

export const ChoosePlan: React.FC<Props> = ({ onContinue }) => {
    const { upgrade } = usePlanManagement();
    const { plans } = useBillingStatus();
    const recommendedPlan = useRecommendedPlan();
    const updateUser = useUpdateUserMutation();

    const handleEnterpriseCallBooked = useCallback(async () => {
        await startEnterpriseTrial();
        await updateUser.mutateAsync({ bookedEnterpriseCall: true });
        onContinue();
    }, [onContinue, updateUser]);

    const renderAction = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            if (plan === FLAT_RATE_PLANS.FREE) {
                return (
                    <Button variant="secondary" size="sm" onClick={onContinue}>
                        Continue on free
                    </Button>
                );
            }

            if (plan === FLAT_RATE_PLANS.ENTERPRISE) {
                return (
                    <BookCallButton
                        variant="cta"
                        size="sm"
                        url={ENTERPRISE_TRIAL_CALL_URL}
                        onBooked={handleEnterpriseCallBooked}
                    >
                        <ThunderboltIcon className="mr-1" /> Request trial
                    </BookCallButton>
                );
            }

            return (
                <Button
                    variant="cta"
                    size="sm"
                    onClick={() => upgrade(plan, true)}
                    data-testid={`upgrade-${plan.toLowerCase()}`}
                >
                    <ThunderboltIcon className="mr-1" /> Start trial
                </Button>
            );
        },
        [handleEnterpriseCallBooked, upgrade, onContinue],
    );

    return (
        <OnboardingStep
            title={
                <>
                    Try a paid plan <em>free for 30 days</em>
                </>
            }
            description="Cancel anytime. We'll remind you before the trial ends."
            wide
        >
            <Plans
                plans={plans}
                renderAction={renderAction}
                recommended={recommendedPlan}
                stickyHeaders
            />
        </OnboardingStep>
    );
};
