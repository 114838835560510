import fileDownload from "js-file-download";
import { extension as getExtensionFromMime } from "mime-types";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toBase64 } from "../../../helpers/file";
import { useMobileView } from "../../../hooks/useMobileView";
import {
    detachFinancialTransactionMatch,
    uploadTransactionReceipt,
} from "../../../lib/transactions";
import { TransactionDetails } from "./TransactionDetails";
import { TransactionDetailsProps } from "./types";
import { TransactionsContext } from "../context/transactions.context";
import {
    TransactionSplitContainer,
    TransactionSplitContainerProps,
} from "../TransactionSplit/TransactionSplitContainer";
import { SplitIcon } from "../../../icons";
import { usePotentialSavingsContext } from "../PotentialSavingsContext";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { ReceiptUploadDropzone } from "../ReceiptUpload/ReceiptUploadDropzone";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { Card } from "../../general/Card/Card";
import {
    invalidateTransactionDetailsQueries,
    useTransactionDetailsQuery,
} from "./useTransactionDetailsQuery";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { invalidateTransactionsQueries } from "../useTransactionsQuery";
import { getFinancialDocumentFile } from "../../../lib/financialDocument";
import { StandardModal } from "../../general/Modal/Modal";
import splitStyles from "../TransactionSplit/TransactionSplit.module.scss";

export interface TransactionDetailsContainerProps {
    id: number;
    onClose(): void;
}

export const TransactionDetailsContainer: React.FC<
    TransactionDetailsContainerProps
> = ({ id, onClose }) => {
    const [downloading, setDownloading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showSplit, setShowSplit] = useState(false);
    const { saveTransaction, upsertTransaction } =
        useContext(TransactionsContext);

    const { invalidate: invalidateSavings } = useBillingStatus();

    const { onTransactionUpdated: updateSavings } =
        usePotentialSavingsContext();

    const { transaction, memoRequired, receiptRequired } =
        useTransactionDetailsQuery(id, (response) => {
            if (transaction) {
                updateSavings(transaction, response.transaction);
            }
        });

    const downloadReceipt = useCallback(async () => {
        const financialDocument =
            transaction?.transactionMatches[0]?.financialDocument;

        if (!financialDocument) {
            return;
        }

        setDownloading(true);

        try {
            const { data, contentType } = await getFinancialDocumentFile(
                financialDocument.id,
            );
            const ext = getExtensionFromMime(contentType);
            const filenameHasExtension = Boolean(
                ext && financialDocument.fileName.endsWith(ext),
            );

            const filenameForDownload = filenameHasExtension
                ? financialDocument.fileName
                : `${financialDocument.fileName}.${ext}`;

            fileDownload(data, filenameForDownload, contentType);
        } finally {
            setDownloading(false);
        }
    }, [transaction]);

    const updateTransaction: TransactionDetailsProps["onUpdate"] = useCallback(
        async (payload: UpdateTransactionDto) => {
            if (transaction) {
                const updated = await saveTransaction(transaction, payload);
                if (updated) {
                    updateSavings(transaction, updated);
                }
            }
        },
        [transaction, saveTransaction, updateSavings],
    );

    const addReceipt: TransactionDetailsProps["onAddReceipt"] = useCallback(
        async (file) => {
            setUploading(true);

            try {
                const receipt = await toBase64(file);

                await uploadTransactionReceipt(id, {
                    receipt,
                    contentType: file.type,
                    name: file.name,
                });

                await invalidateTransactionDetailsQueries();
                await invalidateSavings();
            } finally {
                setUploading(false);
            }
        },
        [id, invalidateSavings],
    );

    const removeReceipt: TransactionDetailsProps["onRemoveReceipt"] =
        useCallback(async () => {
            if (!transaction) {
                return;
            }

            const link = transaction?.transactionMatches[0];

            if (!link) {
                return;
            }

            await detachFinancialTransactionMatch(transaction.id, link.id);
            await invalidateTransactionDetailsQueries();
            await invalidateSavings();
        }, [transaction, invalidateSavings]);

    const handleSplitChanged: TransactionSplitContainerProps["onComplete"] =
        useCallback(async () => {
            await invalidateTransactionDetailsQueries();
            await invalidateTransactionsQueries();
            setShowSplit(false);
        }, []);

    const isMobile = useMobileView();

    const contextValue = useMemo(
        () => ({
            transaction,
            downloadingReceipt: downloading,
            onDownloadReceipt: downloadReceipt,
            onRemoveReceipt: removeReceipt,
            uploadingReceipt: uploading,
            onAddReceipt: addReceipt,
            onUpdate: updateTransaction,
            onSplit: () => setShowSplit(true),
            setTransaction: upsertTransaction,
            memoRequired,
            receiptRequired,
        }),
        [
            transaction,
            downloading,
            downloadReceipt,
            removeReceipt,
            uploading,
            addReceipt,
            updateTransaction,
            upsertTransaction,
            memoRequired,
            receiptRequired,
        ],
    );

    const transactionDetailsBody = (
        <TransactionDetailsContext.Provider value={contextValue}>
            <ReceiptUploadDropzone onAddReceipt={addReceipt}>
                <TransactionDetails onClose={onClose} />
            </ReceiptUploadDropzone>
        </TransactionDetailsContext.Provider>
    );

    return (
        <>
            {isMobile ? (
                <StandardModal show>{transactionDetailsBody}</StandardModal>
            ) : (
                <Card className="transactions__details-aside">
                    {transactionDetailsBody}
                </Card>
            )}

            {transaction && (
                <Modal
                    show={showSplit}
                    size="xl"
                    onHide={() => setShowSplit(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="icon-color-grey">
                            Split Transaction{" "}
                            <SplitIcon
                                className="d-none d-sm-inline-block"
                                height={18}
                            />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className={splitStyles.modalBody}>
                        {showSplit && (
                            <TransactionSplitContainer
                                transaction={transaction}
                                onComplete={handleSplitChanged}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};
