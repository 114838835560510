interface FilenameParts {
    name: string;
    extension: string | null;
}
export function useFilenameParts(filename: string): FilenameParts {
    const match = /^(.+)\.([a-z]{3,4}$)/i.exec(filename);

    if (match) {
        const [, name, extension] = match;

        return {
            name,
            extension,
        };
    } else {
        return {
            name: filename,
            extension: null,
        };
    }
}
