import { useCallback, useRef } from "react";

export interface UseTimer {
    start(): void;
    pause(): void;
    clear(): void;
    resume(): void;
}
export function useTimer(onExpired: () => void, delayInMs: number): UseTimer {
    const timerId = useRef<number | null>(null);
    const startedAt = useRef<number | null>(null);
    const pausedAt = useRef<number | null>(null);

    const clear = useCallback(() => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }

        startedAt.current = null;
        pausedAt.current = null;
        timerId.current = null;
    }, []);

    const handleExpired = useCallback(() => {
        clear();
        onExpired();
    }, [clear, onExpired]);

    const start = useCallback(() => {
        clear();
        timerId.current = window.setTimeout(handleExpired, delayInMs);
        startedAt.current = Date.now();
    }, [clear, delayInMs, handleExpired]);

    const pause = useCallback(() => {
        if (!timerId.current || !startedAt.current || pausedAt.current) {
            return;
        }

        clearTimeout(timerId.current);
        timerId.current = null;
        pausedAt.current = Date.now();
    }, []);

    const resume = useCallback(() => {
        if (!pausedAt.current || !startedAt.current) {
            return;
        }

        const remainingTime =
            delayInMs - (pausedAt.current - startedAt.current);

        pausedAt.current = null;
        timerId.current = window.setTimeout(handleExpired, remainingTime);
    }, [delayInMs, handleExpired]);

    return {
        start,
        clear,
        pause,
        resume,
    };
}
