import React from "react";

interface Props {
    name: string;
    balance: string;
    className: string;
    icon?: React.ReactNode;
    addon?: React.ReactNode;
}

export const DashboardAccountsItem: React.FC<Props> = ({
    name,
    balance,
    icon,
    className,
    addon,
}) => {
    return (
        <div className={className}>
            {icon}
            <div className="dashboard-accounts__name">{name}</div>
            <div className="dashboard-accounts__balance">{balance}</div>
            {addon}
        </div>
    );
};
