import { createContext } from "react";
import { DropzoneInputProps } from "react-dropzone";
import { noop } from "../../../helpers/general";

interface ReceiptUploadContextValue {
    isDragActive: boolean;
    isUploading: boolean;

    inputProps: DropzoneInputProps;
    open(): void;
}

export const receiptUploadContext = createContext<ReceiptUploadContextValue>({
    isDragActive: false,
    isUploading: false,
    inputProps: {},
    open: noop,
});

export const ReceiptUploadContextProvider = receiptUploadContext.Provider;
