import React, { useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useFormikContext } from "formik";
import classNames from "classnames";

import styles from "./JournalEntryModal.module.scss";
import { EntitySelect } from "../../entity/EntitySelect";
import { DatePickerInput } from "../../forms/dates/DatePickerInput/DatePickerInput";
import { EditableTableHeader } from "../editableTable/EditableTableHeader";
import { JournalEntryModalFormikConfig } from "./JournalEntryModal";
import { DefaultCashBasisAccountCodes } from "../../../common/types/domains/accounting/accounts";
import { Entity } from "../../../common/types/entity";
import { FormRow } from "../../forms/FormRow";
import { useEntitiesWithAccountingAvailable } from "../useEntitiesWithAccountingAvailable";
import { useIsJournalEntryEditable } from "./JournalEntryModalHooks";
import { FormRowRaw } from "../../forms/FormRowRaw";
import { getShortJournalEntryReferenceNumber } from "../../../common/helpers/accounting";

interface Props {
    setFormEntityId: (entityId: number) => void;
}

export const JournalEntryModalHeader: React.FC<Props> = ({
    setFormEntityId,
}) => {
    const availableEntities = useEntitiesWithAccountingAvailable();

    const formikContext = useFormikContext<JournalEntryModalFormikConfig>();
    const { values, setFieldValue } = formikContext;

    const selectedEntity = useMemo(() => {
        return availableEntities.find((e) => e.id === values.entityId)!;
    }, [availableEntities, values.entityId]);

    const onEntityChange = useCallback(
        (entity: Entity | undefined) => {
            if (!entity) {
                return;
            }

            setFieldValue("entityId", entity.id);
            setFormEntityId(entity.id);

            const defaultCoA = new Set(
                Object.keys(DefaultCashBasisAccountCodes),
            );
            for (const [index, line] of values.journalEntry.lines.entries()) {
                if (line.accountCode.length === 0) {
                    return;
                }
                const isAccountInDefaultCoA = defaultCoA.has(line.accountCode);
                if (isAccountInDefaultCoA) {
                    continue;
                }
                setFieldValue(`journalEntry.lines.${index}.accountCode`, "");
            }
        },
        [values.journalEntry.lines, setFormEntityId, setFieldValue],
    );

    const isJournalEntryEditable = useIsJournalEntryEditable(values);

    return (
        <Modal.Header className={styles.modalHeader} closeButton>
            <div className={styles.modalHeaderPaddingContainer}>
                <Modal.Title className={styles.modalTitle}>
                    {values.journalEntryId
                        ? "Journal entry"
                        : "New journal entry"}
                </Modal.Title>
                <div className={classNames(styles.card, styles.cardAlignTop)}>
                    {values.journalEntryId && (
                        <FormRowRaw
                            value={getShortJournalEntryReferenceNumber(
                                values.journalEntryId,
                            )}
                            size="sm"
                            showErrorMessage={false}
                            className={classNames(
                                styles.headerFormRow,
                                styles.headerJournalEntryId,
                            )}
                            disabled
                        />
                    )}
                    <DatePickerInput
                        value={values.journalEntry.date}
                        name="date"
                        onChange={(value) => {
                            if (value) {
                                setFieldValue("journalEntry.date", value);
                            }
                        }}
                        inputSize="sm"
                        disabled={!isJournalEntryEditable}
                    />
                    <EntitySelect
                        entities={availableEntities}
                        selectedEntity={selectedEntity}
                        onChange={onEntityChange}
                        dropdownKey={"accounting-report-entity-select"}
                        className={styles.entitySelect}
                        size="sm"
                        disabled={
                            !isJournalEntryEditable ||
                            values.journalEntryId !== undefined
                        }
                    />
                    <FormRow
                        fieldName="journalEntry.memo"
                        placeholder={
                            isJournalEntryEditable ? "Add Memo..." : ""
                        }
                        size="sm"
                        label="Memo"
                        isInline
                        className={styles.headerFormRow}
                        autoResize
                        autoResizeShrinkOnBlur
                        showErrorMessage={false}
                        disabled={!isJournalEntryEditable}
                    />
                </div>
            </div>

            <EditableTableHeader />
        </Modal.Header>
    );
};
