import React from "react";
import { User } from "../../common/types/user";
import { ObjectIcon, ObjectIconProps } from "../general/ObjectIcon/ObjectIcon";

interface Props extends Pick<ObjectIconProps, "size" | "square" | "border"> {
    user: User;
}

export const UserAvatar: React.FC<Props> = ({ user, size = "lg", ...rest }) => {
    return (
        <ObjectIcon
            name={user.name ?? user.phoneNumberDisplay}
            iconUrl={user.avatar ?? undefined}
            defaultColor={user.iconColor}
            size={size}
            {...rest}
        />
    );
};
