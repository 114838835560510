import {
    ConnectionProviderType,
    FinancialConnection,
} from "../../../../common/types/financialConnection";
import { isPlaidConnection } from "../../../../common/helpers/financialConnection";
import { useMemo } from "react";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { getLeadingAccountConnection } from "../../../../common/helpers/financialAccount";

function isProcessorConnected(
    connections: FinancialConnection[],
    processor: string,
) {
    if (processor === "stripe") {
        return connections.some(
            (connection) =>
                connection.connectionProvider === ConnectionProviderType.STRIPE,
        );
    }

    return connections
        .filter(isPlaidConnection)
        .some((fc) => fc.bankName.toLowerCase() === processor.toLowerCase());
}

export function useProcessorConnectionStatus(
    financialAccounts: FinancialAccount[],
) {
    return useMemo(() => {
        const financialConnections = financialAccounts
            .map(getLeadingAccountConnection)
            .filter((fc) => fc !== null);

        const statuses = {
            stripeConnected: isProcessorConnected(
                financialConnections,
                "stripe",
            ),
            paypalConnected: isProcessorConnected(
                financialConnections,
                "paypal",
            ),
            venmoConnected: isProcessorConnected(
                financialConnections,
                "Venmo - Personal",
            ),
            cashAppConnected: isProcessorConnected(
                financialConnections,
                "cashapp",
            ),
        };

        return {
            ...statuses,
            hasAnyProcessorConnected: Object.values(statuses).some(Boolean),
        };
    }, [financialAccounts]);
}
