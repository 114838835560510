import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { Workspace } from "../common/types/workspace";
import { ACTIVE_USER_ID_KEY } from "../reducers/appState";

export type ChangeActiveWorkspaceType = (
    workspace: Workspace | null,
    refreshEntities?: boolean,
) => Promise<void>;

interface WorkspaceContextType {
    activeWorkspace: Workspace | null;
    activeWorkspaceKey: string;
    changeActiveWorkspace: ChangeActiveWorkspaceType;
}

export const WorkspaceContext = createContext<WorkspaceContextType>({
    activeWorkspace: null,
    changeActiveWorkspace: async () => {},
    activeWorkspaceKey: "anonymous",
});

export const useWorkspaceContext = () => {
    return useContext(WorkspaceContext);
};

export const ACTIVE_WORKSPACE_KEY = "active-workspace-key";

// NOTE: allowing to remember separate workspace when switching users
const getActiveWorkspaceKey = () => {
    const userId = localStorage.getItem(ACTIVE_USER_ID_KEY) ?? "anonymous";
    return getActiveWorkspaceKeyForUserId(userId);
};

export const getActiveWorkspaceKeyForUserId = (userId: string | number) => {
    return [userId, ACTIVE_WORKSPACE_KEY].join("-");
};

export const activeWorkspaceProxy: {
    activeWorkspaceId: string | null;
    changeActiveWorkspace: ChangeActiveWorkspaceType;
} = {
    activeWorkspaceId: null,
    changeActiveWorkspace: async () => {},
};

export const WorkspaceProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [activeWorkspace, setActiveWorkspace] = useState<Workspace | null>(
        null,
    );

    const changeActiveWorkspace = useCallback(
        async (
            workspace: Workspace | null,
            refreshEntities: boolean = true,
        ) => {
            if (!workspace) {
                setActiveWorkspace(null);
                return;
            }

            setActiveWorkspace(workspace);
            localStorage.setItem(getActiveWorkspaceKey(), workspace.id);
        },
        [],
    );

    const context = useMemo(
        () => ({
            activeWorkspace,
            changeActiveWorkspace,
            activeWorkspaceKey: activeWorkspace?.id ?? "anonymous",
        }),
        [activeWorkspace, changeActiveWorkspace],
    );
    activeWorkspaceProxy.changeActiveWorkspace = changeActiveWorkspace;
    activeWorkspaceProxy.activeWorkspaceId = activeWorkspace?.id ?? null;
    return (
        <WorkspaceContext.Provider value={context}>
            {children}
        </WorkspaceContext.Provider>
    );
};
