import React, { useState } from "react";
import { TransactionCategorizationRule } from "../../../common/types/transactionCategorizationRule";
import { Card } from "../../general/Card/Card";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { pluralize } from "../../../common/helpers/string";
import { Button, Modal } from "react-bootstrap";
import { useCategorizationRuleRemovalMutation } from "../../../mutations/categorizationRules";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { EditRuleModal } from "./EditRuleModal";

interface Props {
    rule: TransactionCategorizationRule;
}

export const CategorizationRule: React.FC<Props> = ({ rule }) => {
    const labelGetter = useCategoryLabelGetter();
    const categoryLabel = rule.categoryId
        ? labelGetter(rule.categoryId)
        : rule.customCategory?.label;
    const removal = useCategorizationRuleRemovalMutation(rule);
    const [showEdit, setShowEdit] = useState(false);

    return (
        <>
            <Card className="mb-4">
                <header className="d-flex justify-content-between">
                    <h5>{rule.name}</h5>
                    <div>
                        <Button
                            variant="link"
                            onClick={() => setShowEdit(true)}
                        >
                            Edit
                        </Button>
                        <ButtonWithLoader variant="link" mutation={removal}>
                            Remove
                        </ButtonWithLoader>
                    </div>
                </header>
                <div>
                    {rule.conditions.length}{" "}
                    {pluralize("condition", rule.conditions.length)}, categorize
                    as <strong>{categoryLabel}</strong>
                </div>
            </Card>
            <Modal show={showEdit} onHide={() => setShowEdit(false)}>
                <EditRuleModal rule={rule} close={() => setShowEdit(false)} />
            </Modal>
        </>
    );
};
