import React, { useMemo } from "react";
import { range } from "lodash";
import { SkeletonCell } from "../../general/Skeleton/SkeletonCell";
import "./TransactionsTableCta.scss";
import { currencyFormatterShort } from "../../../common/helpers/currency";
import { ThunderboltIcon } from "../../../icons";
import { Button } from "react-bootstrap";
import { useBillingManagement } from "../../../hooks/useBillingManagement";
import classNames from "classnames";
import { useBillingStatus } from "../../../hooks/useBillingStatus";

export interface TransactionsTableCtaProps {
    rows: number;
    showAccountNames?: boolean;
    bulkActionsEnabled?: boolean;
    transactionSelected?: boolean;
    header?: React.ReactNode;
    description?: React.ReactNode;
    reversed?: boolean;
}
export const TransactionsTableCta: React.FC<TransactionsTableCtaProps> = ({
    rows: rowsCount,
    header,
    description,
    showAccountNames,
    bulkActionsEnabled,
    transactionSelected,
    reversed,
}) => {
    const { onUpgrade } = useBillingManagement();
    const [freeTier] = useBillingStatus().pricingTiers;
    const rows = useMemo(() => range(rowsCount), [rowsCount]);

    return (
        <tbody
            className={classNames(
                "transactions-table-cta",
                `transactions-table-cta--rows-${rowsCount}`,
                {
                    "transactions-table-cta--reversed": reversed,
                },
            )}
        >
            {rows.map((row) => (
                <tr key={row} className="transaction-list-item">
                    {bulkActionsEnabled ? (
                        <SkeletonCell
                            skeleton={20}
                            className="transaction-list-item__select"
                            style={
                                {
                                    "--background": "transparent",
                                    "--border-color":
                                        "rgba(83, 100, 126, 0.18)", // misc-alpha-300
                                } as React.CSSProperties
                            }
                        />
                    ) : null}

                    <SkeletonCell
                        skeleton={40}
                        className="transaction-list-item__date d-none d-md-table-cell"
                    />

                    <SkeletonCell
                        skeleton={row % 2 ? 120 : 160}
                        className="transaction-list-item__description"
                    />

                    {!transactionSelected && (
                        <SkeletonCell
                            skeleton={row % 2 ? 100 : 140}
                            className="transaction-list-item__category"
                        />
                    )}

                    {showAccountNames && (
                        <SkeletonCell
                            skeleton={88}
                            className="d-none d-md-table-cell transaction-list-item__account"
                        />
                    )}

                    <SkeletonCell
                        skeleton={row % 2 ? 60 : 68}
                        className="transaction-list-item__amount"
                    />

                    {!transactionSelected && (
                        <SkeletonCell
                            skeleton={20}
                            className="transaction-list-item__is-business"
                        />
                    )}

                    <SkeletonCell
                        skeleton={20}
                        className="transaction-list-item__action"
                    />
                </tr>
            ))}

            <aside
                className="transactions-table-cta__cover"
                data-testid="transactions-cta-cover"
            >
                <h3 className="m-0">
                    {header ?? (
                        <>
                            You've reached{" "}
                            {currencyFormatterShort.format(freeTier.end)} in
                            business expenses
                        </>
                    )}
                </h3>

                <p className="m-0">
                    {description ?? (
                        <>
                            Upgrade to view and to get all your transactions
                            categorized by Kick.
                        </>
                    )}
                </p>

                <Button
                    variant="cta"
                    onClick={onUpgrade}
                    data-testid="cta-cover-button"
                >
                    Subscribe <ThunderboltIcon className="ml-1" />
                </Button>
            </aside>
        </tbody>
    );
};
