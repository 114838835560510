import React from "react";
import styles from "./DocumentsPage.module.scss";
import { FinancialDocumentExtensionIcon } from "./FinancialDocumentExtensionIcon";
import { useFilenameParts } from "./useFilenameParts";

interface Props {
    fileName: string;
    icon?: boolean;
}

export const DocumentName: React.FC<Props> = ({ fileName, icon }) => {
    const { extension, name } = useFilenameParts(fileName);

    return (
        <span className={styles.documentName}>
            {icon && <FinancialDocumentExtensionIcon filename={fileName} />}

            <span>
                {name}
                {extension ? (
                    <span className="text-gray-500">.{extension}</span>
                ) : null}
            </span>
        </span>
    );
};
