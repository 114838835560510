import { MutableRefObject, useCallback } from "react";

export function useCallbackWithFocus<T extends (...args: any[]) => void>(
    callback: T,
    inputRef: MutableRefObject<HTMLInputElement | undefined>,
): T {
    return useCallback(
        (...args: Parameters<T>) => {
            const result = callback(...args);
            inputRef.current?.focus();
            return result;
        },
        [callback, inputRef],
    ) as T;
}
