import React from "react";
import { Button } from "react-bootstrap";
import { OnboardingStepActions } from "../OnboardingStepActions";
import styles from "./Receipts.module.scss";
import { PhoneMockup } from "./PhoneMockup";
import { Conversation } from "./Conversation";
import classNames from "classnames";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { useFetchAsset } from "../../../hooks/useFetchAsset";

interface Props {
    onBack(): void;
    onNext(): void;
}

export const ReceiptsStart: React.FC<Props> = ({ onBack, onNext }) => {
    const { newOnboarding } = useTypedFlags();
    const { data: receiptsStartVideo } = useFetchAsset(
        "/images/receipts/receiptsStart.webm",
    );

    return (
        <section className={styles.container}>
            <main className={styles.content}>
                <h1 className="onboarding__title mb-5">Match your receipts</h1>

                <p className="onboarding__headline">
                    Kick AI helps you manage audit risk by matching any receipts
                    you send to your transactions. Click “Next” to turn on auto
                    receipt matching.
                </p>
            </main>

            <OnboardingStepActions
                onBack={onBack}
                className={classNames(styles.buttons, "mt-0")}
                btnSize={newOnboarding ? undefined : "xl"}
            >
                <Button
                    variant="primary"
                    size={newOnboarding ? undefined : ("xl" as any)}
                    onClick={onNext}
                >
                    Next
                </Button>
            </OnboardingStepActions>

            <aside className={styles.figure}>
                {newOnboarding ? (
                    <video
                        autoPlay
                        muted
                        loop={false}
                        className={styles.video}
                        src={receiptsStartVideo}
                    />
                ) : (
                    <PhoneMockup>
                        <Conversation animate />
                    </PhoneMockup>
                )}
            </aside>
        </section>
    );
};
