import React, { useMemo } from "react";
import NumberFormat from "react-number-format";
import classNames from "classnames";

import styles from "./EditableTable.module.scss";
import {
    EditableTableCell,
    EditableTableCellProps,
    calculateCellBorders,
} from "./EditableTableCell";

interface EditableTableCellNumberProps
    extends Omit<EditableTableCellProps, "children"> {
    onChange?: (value: string | number | null) => void;
    value: number | null;
    isDisabled?: boolean;
    inputClassName?: string;
}

export const EditableTableCellNumber = ({
    id,
    isFirstRow,
    onChange,
    selectionState,
    value,
    isDisabled,
    inputClassName,
    error,
    onClick,
}: EditableTableCellNumberProps) => {
    const memoizedNumberInput = useMemo(() => {
        const cellBorders = calculateCellBorders(selectionState);

        return (
            <NumberFormat
                fixedDecimalScale
                decimalSeparator="."
                thousandSeparator=","
                decimalScale={2}
                className={classNames(
                    "form-control form-control-sm",
                    styles.editableTableInput,
                    inputClassName,
                )}
                onValueChange={(inputValue, sourceInfo) => {
                    if (sourceInfo.source === "event") {
                        onChange?.(inputValue.floatValue ?? null);
                    }
                }}
                value={value ?? ""}
                isAllowed={() => !isDisabled}
                onFocus={onClick}
                style={cellBorders}
            />
        );
    }, [value, onChange, onClick, inputClassName, isDisabled, selectionState]);

    return (
        <EditableTableCell
            id={id}
            isFirstRow={isFirstRow}
            selectionState={selectionState}
            error={error}
        >
            {memoizedNumberInput}
        </EditableTableCell>
    );
};
