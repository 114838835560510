import { addHours, isValid, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
});

export const shortDateFormatter = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
});
export const dateTimeFormatter = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
});
export const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
});

export const fullDateFormat = "MMM d, ''yy";
export const fullDateFullYearFormat = "MMM d, yyyy";
export const fullDateFormatWithTime = "MMM d, ''yy', 'h:mm a";
export const fullDateShortFormat = "MM/dd/yy";
export const fullDateLongFormat = "M/d/yyyy";
export const monthlyDateFormat = "MMM yyyy";
export const monthlyDateFormatShort = "MMM ''yy";
export const shortDateWithOrdinalsFormat = "MMM do";
export const dateTimeFormat = "MMM d', 'h:mm a";

export const monthOnlyFormat = "MMMM";

export function checkDateFormat(dateString: string, format: string) {
    try {
        const date = parse(dateString, format, new Date());

        return isValid(date);
    } catch (error) {
        return false;
    }
}

export const PST_HOURS_OFFSET = 8;

export function getPSTDate(reference = new Date()) {
    return utcToZonedTime(reference, "PST");
}

export function getUTCDateForPST(reference = new Date()) {
    return addHours(reference, PST_HOURS_OFFSET);
}
