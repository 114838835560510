import React, { useMemo } from "react";
import { differenceInMinutes } from "date-fns";
import { LAST_DOCUMENTS_DOWNLOAD_KEY } from "./lib";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useDownloadDocuments } from "./useDownloadDocuments";
import { useWorkspaceContext } from "../../../state/workspaceContext";

export const FinancialDocumentsDownload: React.FC = () => {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const download = useDownloadDocuments(activeWorkspaceKey);
    const lastDownload = localStorage.getItem(LAST_DOCUMENTS_DOWNLOAD_KEY);

    const allowDownload = useMemo(() => {
        const lastDownloadDate = lastDownload
            ? new Date(parseInt(lastDownload))
            : null;
        return (
            !lastDownloadDate ||
            differenceInMinutes(new Date(), lastDownloadDate) > 5
        );
    }, [lastDownload]);

    return allowDownload ? (
        <ButtonWithLoader
            variant="secondary"
            size="sm"
            mutation={download}
            data-testid="financial-documents-download-button"
        >
            Download
        </ButtonWithLoader>
    ) : (
        <>Your file is processing. You'll receive an email when it's ready</>
    );
};
