import React from "react";
import { Card } from "../../../../general/Card/Card";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import billingStyles from "../AnnualBilling.module.scss";
import styles from "./YearForQuote.module.scss";
import classnames from "classnames";

interface Props {
    year: number;
    selected: boolean;
    onChange?(): void;
    formattedPrice: React.ReactNode;
    description: React.ReactNode;
    priceInfo: React.ReactNode;
}

export const YearForQuote: React.FC<Props> = ({
    year,
    selected,
    onChange,
    formattedPrice,
    description,
    priceInfo,
}) => {
    return (
        <Card className={classnames(billingStyles.card, styles.container)}>
            <FormCheckbox
                value={year}
                isChecked={selected}
                handleChange={onChange}
                small
                label={
                    <section className={styles.body}>
                        <main>
                            <header>{year} Bookkeeping</header>
                            <p>{description}</p>
                        </main>

                        <aside>
                            <header>{formattedPrice}</header>
                            <p>{priceInfo}</p>
                        </aside>
                    </section>
                }
            />
        </Card>
    );
};
