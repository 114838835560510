import React, { useMemo } from "react";
import { TransactionsFilters } from "../../lib";
import { useEntities } from "../../../../../hooks/useEntities";
import { Entity } from "../../../../../common/types/entity";
import { EntityIconList } from "../../../../general/EntityIconList/EntityIconList";

interface AccountFilterTriggerProps {
    value: TransactionsFilters["entitiesAccounts"];
}

const MAX_ENTITIES_DISPLAY = 4;

export const AccountFilterTrigger: React.FC<AccountFilterTriggerProps> = ({
    value,
}) => {
    const entities = useEntities();

    const selectedEntities = useMemo(() => {
        if (!value?.length) {
            return entities;
        }

        return value
            .map((v) => entities.find((e) => e.id === v.entityId))
            .filter(Boolean) as Entity[];
    }, [entities, value]);

    return (
        <EntityIconList
            entities={selectedEntities}
            maxEntitiesToDisplay={MAX_ENTITIES_DISPLAY}
        />
    );
};
