import React from "react";
import { Entity } from "../../../common/types/entity";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { getAccountNameWithLast4 } from "../../../common/helpers/financialAccount";
import { displayAccountBalance } from "../../../helpers/account";
import { FinancialAccount } from "../../../common/types/financialAccount";

interface Props {
    entity: Entity;
    account?: FinancialAccount;
}

export const AccountChartTooltip: React.FC<Props> = ({ entity, account }) => {
    const allAccounts = useFinancialAccounts({ entityId: entity.id });
    const accounts = account ? [account] : allAccounts;

    return (
        <>
            <header>{entity.name}</header>
            <ul className="list-unstyled mb-0">
                {accounts.map((a) => (
                    <li key={a.id}>
                        <span>{getAccountNameWithLast4(a, 2)}</span>
                        <aside>{displayAccountBalance(a)}</aside>
                    </li>
                ))}
            </ul>
        </>
    );
};
