import { Address } from "./embeddable/address";
import { TaxLocation } from "./taxLocation";
import { User } from "./user";

export enum EntityIndustry {
    CREATOR = "CREATOR",
    REAL_ESTATE = "REAL_ESTATE",
    OTHER = "OTHER",
}

export interface Entity {
    id: number;
    name: string | null;
    address: Address | null;
    bookkeepingYears: number[];
    bookkeepingStart: Date;
    taxLocations: TaxLocation[];
    onboardingComplete: boolean;
    industry: EntityIndustry;
    iconColor: string;
    isBusiness: boolean;
    isPersonal: boolean;
    user: User | null;
    userId: number | null;
    profile: EntityProfile | null;
    isMockEntity: boolean;
    workspaceId: string | null;
    openingBalanceSubmitted: boolean;
    reportsReady: boolean;
}

export enum EntityType {
    LLC_WITH_S_CORP = "llc_s_corp",
    C_CORP_WITH_S_CORP = "c_corp_with_s_corp",
    NOT_REGISTERED = "not_registered",
    LLC = "llc",
    C_CORP = "c_corp",
}

// will be renamed to EntityType after old onboarding is removed
export enum EntityProfileType {
    SOLE_PROPRIETORSHIP = "not_registered",
    LLC = "llc",
    PARTNERSHIP = "partnership",
    C_CORP = "c_corp",
    S_CORP = "s_corp",
    NO_LEGAL_ENTITY = "no_legal_entity",
}

export enum EntityProfileIndustry {
    ACCOUNTANT = "accountant",
    ADVERTISING = "advertising",
    BOOKKEEPER = "bookkeeper",
    CONSUMER_GOODS = "consumer_goods",
    CONTENT_CREATION = "content_creation",
    CONSULTING_SERVICES = "consulting_services",
    ECOMMERCE = "ecommerce",
    HEALTHCARE = "healthcare",
    MANUFACTURING = "manufacturing",
    MARKETPLACE = "marketplace",
    REAL_ESTATE = "real_estate",
    RETAIL = "retail",
    VENTURE_CAPITAL = "venture_capital",
    VIRTUAL_GOODS = "virtual_goods",
    TECHNOLOGY = "technology",
    OTHER = "other",
    NOT_SURE = "not_sure",
}

export enum ProfessionalType {
    DESIGNATED_ACCOUNTANT = "designated_accountant",
    NON_DESIGNATED_ACCOUNTANT = "non_designated_accountant",
    BOOKKEEPER = "bookkeeper",
    TAX_ADVISOR = "tax_advisor",
}

export enum FirmSize {
    "0_5" = "0_5",
    "6_9" = "6_9",
    "10_20" = "10_20",
    "21_49" = "21_49",
    "50_PLUS" = "50_PLUS",
}

export interface EntityProfile {
    description?: string;
    isPartnership?: boolean;
    partnershipPercentage?: number;
    website?: string;
    annualExpenses?: number;
    entityType?: EntityType;

    // Onboarding V2 profile
    annualRevenue?: number;
    type?: EntityProfileType;
    industry?: EntityProfileIndustry;

    // Onboarding V2 firm info
    professionalType?: ProfessionalType;
    firmSize?: FirmSize;
    howDidYouLearn?: string;

    // Onboarding V2 waitlist
    waitlistComment?: string;

    payrollProviders?: string[];
    paymentProcessorRequest?: string;
}
