import React from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import { ButtonProps } from "react-bootstrap/Button";

export type OnboardingStepActionsProps = {
    onBack?: () => void;
    onBackDisabled?: boolean;
    className?: string;
    btnSize?: ButtonProps["size"] | "xl";
};

export const OnboardingStepActions: React.FC<OnboardingStepActionsProps> = ({
    children,
    onBack,
    onBackDisabled,
    className,
    btnSize,
}) => {
    return (
        <footer className={classNames("onboarding__footer", className)}>
            {onBack && (
                <Button
                    type="button"
                    variant="secondary-icon"
                    size={btnSize as any}
                    onClick={onBack}
                    disabled={onBackDisabled}
                >
                    <span className="fas fa-chevron-left" />
                </Button>
            )}

            {children}
        </footer>
    );
};
