import React from "react";
import { Taxonomy, TransactionDirectionType } from "../../../common/categories";
import { CustomCategory } from "../../../common/types/customCategory";
import { Entity } from "../../../common/types/entity";
import { Transaction } from "../../../common/types/transaction";
import { EntitySelect } from "../../entity/EntitySelect";
import { CategorySelectSimple } from "../../transactions/CategorySelect/CategorySelectSimple";
import { AutopilotCardSection } from "../AutopilotCardSection";
import styles from "./AutopilotPossibleSaving.module.scss";

interface Props {
    inOpened?: boolean;
    transaction: Transaction;
    entities: Entity[];
    selectedEntity: Entity | undefined;
    setSelectedEntity: (entity: Entity | undefined) => void;
    selectedCategory: Taxonomy | CustomCategory | undefined;
    setSelectedCategory: (
        category: Taxonomy | CustomCategory | undefined,
    ) => void;
}

export const AutopilotSavingsOtherSections: React.FC<Props> = ({
    inOpened,
    transaction,
    setSelectedEntity,
    selectedEntity,
    setSelectedCategory,
    selectedCategory,
    entities,
}) => {
    return (
        <AutopilotCardSection
            header="Confirm the entity and the category"
            inOpened={inOpened}
        >
            <div className={styles.entityAndCategory}>
                <EntitySelect
                    entities={entities}
                    onChange={setSelectedEntity}
                    selectedEntity={selectedEntity}
                    size="sm"
                    dropdownKey={`possible-savings-entity-${transaction.id}`}
                />

                <CategorySelectSimple
                    onSelected={setSelectedCategory}
                    value={selectedCategory}
                    dropdownKey={`possible-savings-category-${transaction.id}`}
                    size="sm"
                    hideCustomCategories
                    transactionDirection={
                        transaction.amount > 0
                            ? TransactionDirectionType.incoming
                            : TransactionDirectionType.outgoing
                    }
                />
            </div>
        </AutopilotCardSection>
    );
};
