import React from "react";
import {
    FLAT_RATE_PLANS,
    FlatRatePlan,
    flatRatePlanNames,
} from "../../../common/flatRateBilling";
import {
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../common/helpers/monetary";
import { PlansRow } from "./PlansRow";
import { CheckIcon } from "../../../icons";
import grid from "./Plans.module.scss";
import styles from "./PlanHeader.module.scss";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { highlightDescriptions, HighlightFeatures } from "./lib";

interface Props {
    plan: FlatRatePlan;
    action: React.ReactNode;
    highlights: HighlightFeatures[];
    label: React.ReactNode;
}

export const PlanHeader: React.FC<Props> = ({
    plan,
    highlights,
    action,
    label,
}) => {
    let priceDetails: React.ReactNode;

    if (plan.plan === FLAT_RATE_PLANS.FREE) {
        priceDetails = <>&nbsp;</>;
    } else if (plan.plan === FLAT_RATE_PLANS.ENTERPRISE) {
        priceDetails = "Billed quarterly";
    } else {
        priceDetails = (
            <>
                {currencyFormatterNoFractions.format(
                    fromIntToMonetary(plan.intervalAmountCents),
                )}{" "}
                billed {plan.billingInterval === 12 ? "annually" : "quarterly"}
            </>
        );
    }

    return (
        <article className={classNames(grid.wrapper, styles.planHeader)}>
            <header className={grid.wrapper}>
                <PlansRow sticky first className={grid.label}>
                    {label}
                </PlansRow>
                <PlansRow sticky>
                    <h3 className={styles.name}>
                        {flatRatePlanNames[plan.plan]}
                    </h3>
                </PlansRow>
                <PlansRow sticky>
                    <span className={styles.price}>
                        {plan.plan === FLAT_RATE_PLANS.ENTERPRISE ? (
                            <span className={styles.customPrice}>Custom</span>
                        ) : (
                            <>
                                {currencyFormatterNoFractions.format(
                                    fromIntToMonetary(plan.monthlyAmountCents),
                                )}
                                <small>/m</small>
                            </>
                        )}
                    </span>

                    <span className={styles.priceDetails}>{priceDetails}</span>
                </PlansRow>

                <PlansRow className={styles.action} sticky>
                    {action}
                </PlansRow>

                <PlansRow className={styles.limit}>
                    {plan.usageLimit ? (
                        <>
                            Up to{" "}
                            <strong>
                                {currencyFormatterShort
                                    .format(plan.usageLimit)
                                    .toLowerCase()}
                            </strong>
                        </>
                    ) : (
                        <strong>Unlimited</strong>
                    )}
                </PlansRow>
            </header>

            <PlansRow className="align-content-start" last>
                <ul className={styles.highlights}>
                    {highlights.map((highlight) => {
                        const description = highlightDescriptions[highlight];

                        return (
                            <li key={highlight?.toString()}>
                                <OverlayTrigger
                                    trigger={
                                        description ? ["hover", "focus"] : []
                                    }
                                    overlay={
                                        <Tooltip
                                            id={`plans-tooltip-for-${highlight}-highlight`}
                                        >
                                            {description}
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <CheckIcon className="icon-color-blue-600" />
                                        <span>{highlight}</span>
                                    </span>
                                </OverlayTrigger>
                            </li>
                        );
                    })}
                </ul>
            </PlansRow>
        </article>
    );
};
