import classNames from "classnames";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { ReportingTabCycle } from "../../../common/constants";
import { Entity } from "../../../common/types/entity";
import { ReportFilters } from "../../../common/types/filters/reports";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import { ArrowDownIcon, ArrowUpIcon } from "../../../icons";
import { EntityIcon } from "../../entity/EntityIcon";
import { CustomSelect } from "../../forms/CustomSelect/CustomSelect";
import { AccountsFilterDropdown } from "../../general/AccountsFilter/AccountsFilterDropdown";
import { EntityAccountsFilter } from "../../general/AccountsFilter/EntityAccountsFilter";
import { DateRangeFilter } from "../../general/DateRangeFilter/DateRangeFilter";
import { ProfitLossReportDownloadButton } from "../ProfitLossReportDownload/ProfitLossReportDownloadButton";

const cycleLabels: Record<ReportingTabCycle, string> = {
    [ReportingTabCycle.WEEK]: "By week",
    [ReportingTabCycle.MONTH]: "By month",
};
const cycleOptions = [
    {
        value: ReportingTabCycle.WEEK,
        label: cycleLabels[ReportingTabCycle.WEEK],
    },
    {
        value: ReportingTabCycle.MONTH,
        label: cycleLabels[ReportingTabCycle.MONTH],
    },
];

export interface ReportsPageFiltersProps {
    filters: ReportFilters;
    onChange(filters: Partial<ReportFilters>): void;
    hardcodedEntity?: Entity;
}

export const ReportsPageFilters: React.FC<ReportsPageFiltersProps> = ({
    filters,
    onChange,
    hardcodedEntity,
}) => {
    const { reportDownloadOnReportingTab, accountingTab } = useTypedFlags();

    return (
        <section className="reports-filter-form">
            <div className="reports-filter-form__nav"></div>
            <div className="reports-filter-form__filters">
                <ButtonGroup>
                    <DateRangeFilter
                        dateRange={{
                            start: filters.start,
                            end: filters.end,
                        }}
                        onChange={onChange}
                        buttonSize="sm"
                        monthOnly
                    />
                    <CustomSelect
                        onSelected={(cycle) => onChange({ cycle })}
                        value={filters.cycle}
                        dropdownKey="reports-tab-cycle-select"
                        options={cycleOptions}
                        className="reports-filter-form__cycle-select"
                        popoverClassName="reports-filter-form__cycle-select-popover"
                    >
                        {(opened) => (
                            <Button
                                variant="secondary"
                                className={classNames({
                                    active: opened,
                                })}
                                size="sm"
                            >
                                {cycleLabels[filters.cycle]}
                                {opened ? (
                                    <ArrowUpIcon className="ml-2" />
                                ) : (
                                    <ArrowDownIcon className="ml-2" />
                                )}
                            </Button>
                        )}
                    </CustomSelect>
                </ButtonGroup>
                {hardcodedEntity ? (
                    <Button variant="secondary" size="sm">
                        <EntityIcon entity={hardcodedEntity} size="xs" />
                        <span className="reports-filter-form__hardcoded-entity__name">
                            {hardcodedEntity.name}
                        </span>
                    </Button>
                ) : (
                    <EntityAccountsFilter
                        value={filters.entitiesAccounts}
                        onChange={(entitiesAccounts) =>
                            onChange({ entitiesAccounts })
                        }
                        onlyBusiness
                        excludeMockEntities
                        renderFilter={(props) => (
                            <AccountsFilterDropdown
                                {...props}
                                buttonSize="sm"
                                singleEntityName
                                showSelectedIcons
                            />
                        )}
                    />
                )}
                {reportDownloadOnReportingTab && !accountingTab && (
                    <ProfitLossReportDownloadButton />
                )}
            </div>
        </section>
    );
};
