import { isObject } from "lodash";
import { Taxonomy } from "../categories";
import { Category } from "../types/category";

export function isValidTaxonomy(value: string): value is Taxonomy {
    return value in Taxonomy;
}

export function getMainCategoryId(
    category: Taxonomy | Category,
    categoryMap: Record<Taxonomy, Category>,
): Taxonomy {
    const categoryObject = isObject(category)
        ? category
        : categoryMap[category];

    return categoryObject.parentCategoryId ?? categoryObject.id;
}
