import React from "react";
import { ConnectMoreModalProps } from "./types";
import { Button, Modal } from "react-bootstrap";
import { ConnectPlaid } from "../../plaid/ConnectPlaid/ConnectPlaid";
import { StandardModal } from "../../general/Modal/Modal";

export const ConnectMoreAccountsModal: React.FC<ConnectMoreModalProps> = ({
    onContinue,
    onConnected,
    onHide,
    show,
}) => {
    return (
        <StandardModal show={show} size="lg" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Have you connected all your accounts?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Every account with business or personal expenses you connect
                    helps make Kick self driving and can save you thousands more
                    per year.
                </p>

                <footer className="d-flex justify-content-between mt-5">
                    <ConnectPlaid
                        btnText="Add more accounts"
                        btnVariant="secondary"
                        btnSize="lg"
                        onConnected={onConnected}
                    />

                    <Button
                        variant="primary"
                        onClick={onContinue}
                        className="ml-2"
                        size="lg"
                    >
                        I've added them all
                    </Button>
                </footer>
            </Modal.Body>
        </StandardModal>
    );
};
