import { IntegrationAccount } from "./integrationAccount";
import { User } from "./user";
export enum ConnectionProviderType {
    STRIPE = "Stripe",
    GUSTO = "Gusto",
    PLAID = "Plaid",
}

export interface FinancialConnection {
    id: number;
    connectionProvider: ConnectionProviderType;
    connectionExternalId: string;
    createdAt: Date;
    user: User;
    userId: number;
    accounts?: IntegrationAccount[];
    error: string | null;
}

export interface FinancialConnectionWithAccounts extends FinancialConnection {
    accounts: IntegrationAccount[];
}
