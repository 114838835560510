import React, { useCallback, useState } from "react";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { useUser } from "../../hooks/useUser";
import classNames from "classnames";
import { Button, Modal } from "react-bootstrap";
import { useFinancialAccountEntitySelection } from "../../hooks/useFinancialAccountEntitySelection";
import { FinancialAccountsConnectionProvider } from "./FinancialAccountsConnectionContext";
import { FinancialAccountsConnectionWidget } from "./FinancialAccountConnectionWidget";
import { isValidConnection } from "./helpers";

export interface FinancialAccountsConnectedModalProps {
    show: boolean;
    connection: FinancialConnectionWithAccounts;
    onHide(): void;
}

export const FinancialAccountsConnectedModal: React.FC<
    FinancialAccountsConnectedModalProps
> = ({ show, connection, onHide }) => {
    const { showBusinessEntitySelection } =
        useFinancialAccountEntitySelection();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const user = useUser();
    const handleSubmit = useCallback(() => {
        if (
            isValidConnection(connection) ||
            submitted ||
            !user.onboardingComplete
        ) {
            setSubmitted(false);
            onHide();
        } else {
            setSubmitted(true);
        }
    }, [connection, onHide, submitted, user.onboardingComplete]);

    return (
        <FinancialAccountsConnectionProvider
            showAccountTypeWarning={submitted && user.onboardingComplete}
        >
            <Modal
                show={show}
                onHide={onHide}
                size={connection && showBusinessEntitySelection ? "xl" : "lg"}
                backdrop={connection ? true : "static"}
                keyboard={!!connection}
                className={classNames("accounts-connected-modal", {
                    "accounts-connected-modal--with-entities":
                        showBusinessEntitySelection,
                })}
            >
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Select entity</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>For which entity are you adding these accounts?</p>

                        <FinancialAccountsConnectionWidget
                            accounts={connection.accounts}
                            connection={connection}
                        />

                        <footer className="d-flex align-items-center justify-content-end">
                            <Button onClick={handleSubmit} size="lg">
                                Continue
                            </Button>
                        </footer>
                    </Modal.Body>
                </>
            </Modal>
        </FinancialAccountsConnectionProvider>
    );
};
