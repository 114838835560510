import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { OnboardingStep } from "../OnboardingStep";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { OnboardingStepActions } from "../OnboardingStepActions";
import { Col, Row } from "react-bootstrap";
import { FormRow } from "../../forms/FormRow";
import { useUser } from "../../../hooks/useUser";
import { SUGGESTED_EMAIL_KEY } from "../../../constants";

export interface PersonalInfoFormData {
    firstName: string;
    lastName: string;
    email: string;
}

export interface PersonalInfoProps {
    onSubmit(payload: PersonalInfoFormData): Promise<void>;
}

export const PersonalInfo: React.FC<PersonalInfoProps> = ({ onSubmit }) => {
    const user = useUser();
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<PersonalInfoFormData> = {
        initialValues: {
            firstName: user.firstName ?? "",
            lastName: user.lastName ?? "",
            email:
                user.email ?? localStorage.getItem(SUGGESTED_EMAIL_KEY) ?? "",
        },
        validationSchema: object().shape({
            firstName: string().required("First name is required"),
            lastName: string().required("Last name is required"),
            email: string()
                .required("Email is required")
                .email("Please provide a valid email address"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                await onSubmit(values);
                localStorage.removeItem(SUGGESTED_EMAIL_KEY);
            },
        }),
    };

    return (
        <OnboardingStep title="Create business profile " narrow>
            <Formik {...form}>
                {({ isValid }) => (
                    <Form>
                        <Row>
                            <Col>
                                <FormRow
                                    fieldName="firstName"
                                    label="First name"
                                />
                            </Col>

                            <Col>
                                <FormRow
                                    fieldName="lastName"
                                    label="Last name"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormRow
                                    fieldName="email"
                                    fieldType="email"
                                    label="Email address"
                                />
                            </Col>
                        </Row>

                        <OnboardingStepActions btnSize="xl" className="mt-4">
                            <ButtonWithLoader
                                type="submit"
                                variant="primary"
                                size={"xl" as any}
                                loading={loading}
                                disabled={!isValid}
                            >
                                Next
                            </ButtonWithLoader>
                        </OnboardingStepActions>
                    </Form>
                )}
            </Formik>
        </OnboardingStep>
    );
};
