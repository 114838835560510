import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ManualImportAccountModal } from "./ManualImportAccountModal";
import { FinancialDocumentUploadProvider } from "../documents/FinancialDocumentUpload/FinancialDocumentUploadProvider";

const ALLOWED_FILE_TYPES = ["text/csv"];

export const ManualImportTrigger: React.FC = () => {
    const [connecting, setConnecting] = useState(false);

    return (
        <>
            <Button
                variant="plan"
                size="sm"
                onClick={() => setConnecting(true)}
            >
                <span className="fas fa-chevron-right" />
            </Button>

            <FinancialDocumentUploadProvider accept={ALLOWED_FILE_TYPES}>
                <ManualImportAccountModal
                    show={connecting}
                    onHide={() => setConnecting(false)}
                />
            </FinancialDocumentUploadProvider>
        </>
    );
};
