import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { Transaction } from "../../../../common/types/transaction";
import styles from "./MatchingView.module.scss";
import { TransferMatchingViewFilters } from "./MatchingViewFilters";
import { useInView } from "react-intersection-observer";
import { TransactionsTable } from "../../TransactionsTable";
import { useEntities } from "../../../../hooks/useEntities";
import {
    TransactionsFilters,
    DEFAULT_ACTIVITY_FEED_FILTERS,
} from "../../filters/lib";
import { TransactionSortValue, useSort } from "../../useSort";
import { MoneyDirection } from "../../../../common/constants";
import { Loader } from "../../../general/Loader";
import { isEqual } from "lodash";
import { EmptyState } from "../../../general/EmptyState/EmptyState";
import { MatchButton } from "../MatchButton/MatchButton";
import {
    MatchingFiltersPreferenceOption,
    useTransactionsForTransferMatching,
} from "../useTransactionsForTransferMatching";

interface Props {
    transaction: EagerLoaded<Transaction, "pairedTransfer">;
    pairedTransaction: Transaction | null;
    setPairedTransaction: (transaction: Transaction | undefined) => void;
}

export const MatchingView: React.FC<Props> = ({
    transaction,
    pairedTransaction,
    setPairedTransaction,
}) => {
    const entities = useEntities();
    const initialFilters = useMemo(() => {
        return {
            ...DEFAULT_ACTIVITY_FEED_FILTERS,
            direction:
                transaction.amount > 0
                    ? MoneyDirection.MONEY_OUT
                    : MoneyDirection.MONEY_IN,
        };
    }, [transaction.amount]);
    const [filters, setFilters] = useState<TransactionsFilters>(initialFilters);
    const {
        currentSort,
        handleSortChange: setSort,
        sortExpression,
    } = useSort(TransactionSortValue.NONE);

    const hasActiveFilters = useMemo(() => {
        const filtersWithoutDirection = {
            ...filters,
        };
        delete filtersWithoutDirection.direction;
        return !isEqual(filtersWithoutDirection, DEFAULT_ACTIVITY_FEED_FILTERS);
    }, [filters]);

    const queryFiltersToPass = {
        ...filters,

        entitiesAccounts:
            filters.entitiesAccounts && filters.entitiesAccounts.length > 0
                ? filters.entitiesAccounts
                : entities.map((e) => ({
                      entityId: e.id,
                  })),
    };

    const [preference, setPreference] =
        useState<MatchingFiltersPreferenceOption>(
            MatchingFiltersPreferenceOption.Suggested,
        );

    const setPreferenceCallback = useCallback(
        (preferenceNew: MatchingFiltersPreferenceOption) => {
            setPreference(preferenceNew);
            if (preferenceNew === MatchingFiltersPreferenceOption.Suggested) {
                setSort(TransactionSortValue.NONE);
            } else if (currentSort === TransactionSortValue.NONE) {
                setSort(TransactionSortValue.DATE_DESC);
            }
        },
        [currentSort, setSort],
    );

    const {
        data: transactionsResponse,
        isLoading,
        hasNextPage,
        fetchNextPage,
    } = useTransactionsForTransferMatching({
        preference,
        transaction,
        queryFiltersToPass,
        sortExpression,
    });

    const { inView, ref } = useInView({
        skip: isLoading,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, inView]);

    const transactions = useMemo(() => {
        return transactionsResponse?.pages.flatMap((_) => _.data);
    }, [transactionsResponse?.pages]);

    let empty: React.ReactNode;
    if (isLoading) {
        empty = <Loader />;
    } else if ((transactions?.length ?? 0) > 0) {
        empty = null;
    } else if (hasActiveFilters) {
        empty = (
            <EmptyState
                header="No matching transactions"
                body="The filters don’t match any of the transactions."
                ctaText="Reset all filters"
                action={() => setFilters(DEFAULT_ACTIVITY_FEED_FILTERS)}
            />
        );
    } else {
        empty = (
            <EmptyState
                header={
                    preference === MatchingFiltersPreferenceOption.Suggested
                        ? "No suggested transactions"
                        : "No transactions"
                }
                body={
                    preference === MatchingFiltersPreferenceOption.Suggested
                        ? "We couldn't find a potential match for this transaction"
                        : "It may take a few hours till first transactions are visible."
                }
                ctaText={
                    preference === MatchingFiltersPreferenceOption.Suggested
                        ? "View all transactions"
                        : undefined
                }
                action={() =>
                    preference === MatchingFiltersPreferenceOption.Suggested &&
                    setPreference(MatchingFiltersPreferenceOption.All)
                }
            />
        );
    }

    const resetFilters = useCallback(() => {
        setFilters(initialFilters);
    }, [initialFilters]);

    const onUnmatch = useCallback(() => {
        setPairedTransaction(undefined);
    }, [setPairedTransaction]);

    const onMatch = useCallback(
        (_, targetTransactionUpdated: Transaction) => {
            setPairedTransaction(targetTransactionUpdated);
        },
        [setPairedTransaction],
    );

    return (
        <main className={styles.container}>
            <TransferMatchingViewFilters
                filters={filters}
                resetFilters={resetFilters}
                onChange={setFilters}
                preference={preference}
                setPreference={setPreferenceCallback}
            />

            <section className={styles.transactionsContainer}>
                <TransactionsTable
                    emptyState={empty}
                    disableBulkActions
                    sort={currentSort}
                    transactions={transactions}
                    onSortChange={setSort}
                    actionButtonComponent={(targetTransaction) => (
                        <MatchButton
                            matchedTransaction={pairedTransaction}
                            originTransaction={transaction}
                            targetTransaction={targetTransaction}
                            onUnmatch={onUnmatch}
                            onMatch={onMatch}
                        />
                    )}
                    lastRowContent={
                        preference ===
                            MatchingFiltersPreferenceOption.Suggested && (
                            <div className={styles.dontSeeTransactionQuestion}>
                                Don't see the transaction you're looking for?{" "}
                                <button
                                    onClick={() =>
                                        setPreference(
                                            MatchingFiltersPreferenceOption.All,
                                        )
                                    }
                                >
                                    View all transactions
                                </button>
                            </div>
                        )
                    }
                    disableTransactionChange
                />
                <footer className={styles.observerFooter} ref={ref}></footer>
            </section>
        </main>
    );
};
