import React, { useMemo } from "react";
import { FinancialDocument } from "../../../common/types/financialDocument";
import styles from "./FinancialDocumentMetadata.module.scss";
import { currencyFormatter } from "../../../common/helpers/currency";
import { fullDateLongFormat } from "../../../common/helpers/date";
import { format } from "date-fns";

interface Props {
    financialDocument: FinancialDocument;
}

interface Item {
    label: string;
    value: string;
}

export const FinancialDocumentMetadata: React.FC<Props> = ({
    financialDocument,
}) => {
    const items = useMemo(() => {
        const result: Item[] = [];

        const { amount, date } = financialDocument;

        if (amount) {
            result.push({
                label: "Amount",
                value: currencyFormatter.format(amount),
            });
        }

        if (date) {
            result.push({
                label: "Date",
                value: format(date, fullDateLongFormat),
            });
        }

        return result;
    }, [financialDocument]);

    if (items.length === 0) {
        return null;
    }

    return (
        <section className={styles.section}>
            {items.map((item) => (
                <div key={item.label} className={styles.item}>
                    <div className={styles.label}>{item.label}</div>
                    <div
                        className={styles.value}
                        data-testid={`financial-document-details-${item.label.toLowerCase()}`}
                    >
                        {item.value}
                    </div>
                </div>
            ))}
        </section>
    );
};
