import React from "react";
import { TransactionActivityRow } from "../../../common/dto/transactions/get-transactions-activity-response.dto";
import styles from "./AutopilotActivityRow.module.scss";
import classNames from "classnames";
import kickLogo from "../../../static/images/logo_icon.svg";
import { pluralize } from "../../../common/helpers/string";

interface Props {
    row: TransactionActivityRow;
    className?: string;
}

export const AutopilotActivityRow: React.FC<Props> = ({ row, className }) => {
    return (
        <div className={classNames(styles.row, className)}>
            <img src={kickLogo} alt="Kick" />
            <span>
                Categorized {row.numberOfActivities}{" "}
                {pluralize("transaction", row.numberOfActivities)}
            </span>
        </div>
    );
};
