import React from "react";
import classNames from "classnames";

export interface CaptureInteractionsProps {
    className?: string;
    children: React.ReactNode;
}
export const CaptureInteractions: React.FC<CaptureInteractionsProps> = ({
    className,
    children,
}) => (
    <span
        className={classNames("d-inline-flex", className)}
        onClick={(ev) => ev.stopPropagation()}
        onKeyPress={(ev) => ev.stopPropagation()}
    >
        {children}
    </span>
);
