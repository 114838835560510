import { useEffect, useState } from "react";
import { rowExpansionHelper } from "./rowExpansionHelper";
import { PLDataRow } from "./ProfitLossReportTableContainer";
import { Row, Table } from "@tanstack/react-table";
import { ProfitLossReportDto } from "../../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { useCategoryMap } from "../../../hooks/useCategoryMap";
import { LedgerBasedProfitLossResponseDto } from "../../../common/dto/reports/ledger-based-profit-loss-response.dto";

type ShouldExpand = (canExpand: boolean, row: Row<PLDataRow>) => boolean;
const defaultShouldExpand: ShouldExpand = (canExpand, row) =>
    canExpand && row.depth === 0;

export function useExpandRows(
    table: Table<PLDataRow>,
    report: ProfitLossReportDto | LedgerBasedProfitLossResponseDto,
    shouldExpand: ShouldExpand = defaultShouldExpand,
) {
    const [ready, setReady] = useState(false);
    const categoryMap = useCategoryMap();

    useEffect(() => {
        setReady(false);
        const rowsToExpand: Record<string, true> = {};
        const checkRow = (row: Row<PLDataRow>) => {
            const { canExpand } = rowExpansionHelper(row, categoryMap);

            if (shouldExpand(canExpand, row)) {
                rowsToExpand[row.id] = true;
            }

            row.subRows.forEach(checkRow);
        };

        table.getRowModel().rows.forEach(checkRow);

        // has to be in timeout for table to register state change
        window.setTimeout(() => {
            table.setExpanded(rowsToExpand);
            setReady(true);
        }, 0);
    }, [table, report, categoryMap, shouldExpand]);

    return ready;
}
