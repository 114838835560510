import React from "react";
import { ProgressBar } from "react-bootstrap";
import { BulkActionsToastData } from "../../../general/ToastMessages/lib/bulkActions";
import { BaseToastMessageData } from "../../../general/ToastMessages/lib/common";

type Props = BulkActionsToastData & BaseToastMessageData;
export const ToastUpdating: React.FC<Props> = ({
    updateDetails,
    updateProgress,
}) => {
    const total = updateDetails.transactionsCount;
    const processed = Math.min(
        updateDetails.transactionsCount,
        updateProgress ?? 0,
    );
    const progress = total > 0 ? Math.floor((100 * processed) / total) : 0;

    return (
        <div className="transactions-update-toast__content">
            <p className="transactions-update-toast__label">
                Updating transactions...
            </p>
            <section className="transactions-update-toast__progress">
                <ProgressBar now={progress} /> <span>{progress}%</span>
            </section>
        </div>
    );
};
