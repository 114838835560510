import React, { useEffect, useMemo } from "react";
import { ReportsContext } from "./reports.context";
import { useQuery } from "react-query";
import { cloneDeep, noop } from "lodash";
import {
    getReportingTabProfitLoss,
    getReportingTabSpending,
} from "../../lib/ownerReports";
import { format } from "date-fns";
import { REPORTS_CONTROLLER_DATE_FORMAT } from "../../common/constants";
import { ReportFilters } from "../../common/types/filters/reports";
import { GetReportingTabReportDto } from "../../common/dto/reports/get-reporting-tab-report.dto";
import {
    ProfitLossReportDto,
    SpendingReportDto,
} from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import {
    materialUnconfirmedTransactionsQueryKey,
    queryClient,
} from "../../queryClient";
import { useEntities } from "../../hooks/useEntities";
import { WithRequiredProperties } from "../../common/types/base/generics";

interface Props {
    filters: ReportFilters;
}

interface ReportsQueryOptions<
    T extends ProfitLossReportDto | SpendingReportDto,
> {
    filters: WithRequiredProperties<ReportFilters, "entitiesAccounts">;
    queryKeyBase: string;
    queryFn: (dto: GetReportingTabReportDto) => Promise<T>;
}

function useReportsQuery<T extends ProfitLossReportDto | SpendingReportDto>({
    filters,
    queryFn,
    queryKeyBase,
}: ReportsQueryOptions<T>) {
    return useQuery(
        [
            queryKeyBase,
            filters.start,
            filters.end,
            filters.cycle,
            filters.entitiesAccounts,
        ],
        async () => {
            const filtersUsed = cloneDeep(filters);
            return {
                report: await queryFn({
                    startDate: filters.start
                        ? format(filters.start, REPORTS_CONTROLLER_DATE_FORMAT)
                        : undefined,
                    endDate: filters.end
                        ? format(filters.end, REPORTS_CONTROLLER_DATE_FORMAT)
                        : undefined,
                    cycle: filters.cycle,
                    entitiesAccounts: filters.entitiesAccounts,
                }),
                filtersUsed,
                reportCreatedAt: new Date().getTime(),
            };
        },
        {
            refetchOnWindowFocus: false,
        },
    );
}

export const ReportsContextProvider: React.FC<Props> = ({
    filters,
    children,
}) => {
    const entities = useEntities();
    const filtersToPass = {
        ...filters,
        entitiesAccounts:
            filters.entitiesAccounts && filters.entitiesAccounts.length > 0
                ? filters.entitiesAccounts
                : entities.map((e) => ({
                      entityId: e.id,
                  })),
    };
    const { data: plData, isLoading: isProfitLossReportLoading } =
        useReportsQuery({
            filters: filtersToPass,
            queryKeyBase: "reportsTabProfitLossReport",
            queryFn: getReportingTabProfitLoss,
        });
    const { data: spendingData, isLoading: isSpendingReportLoading } =
        useReportsQuery({
            filters: filtersToPass,
            queryKeyBase: "reportsTabSpendingReport",
            queryFn: getReportingTabSpending,
        });

    useEffect(() => {
        queryClient
            .invalidateQueries(materialUnconfirmedTransactionsQueryKey)
            .catch(noop);
    }, [filters.start, filters.end, filters.entitiesAccounts]);

    const value = useMemo(() => {
        return {
            profitLossReport: plData?.report,
            profitLossFiltersUsed: plData?.filtersUsed,
            reportCreatedAt: plData?.reportCreatedAt,
            isProfitLossReportLoading,
            spendingReport: spendingData?.report,
            spendingFiltersUsed: spendingData?.filtersUsed,
            isSpendingReportLoading,
        };
    }, [
        plData,
        spendingData,
        isProfitLossReportLoading,
        isSpendingReportLoading,
    ]);

    return (
        <ReportsContext.Provider value={value}>
            {children}
        </ReportsContext.Provider>
    );
};
