export interface PLCell {
    value: number;
    label: string;
}
export interface PLRow {
    data: PLCell[];
    category: string;
    customCategory: string | null;
    meta?: {
        code: number;
    };
}

export interface PLSection {
    rows?: PLRow[];
    data: PLCell[];
    meta?: {
        summaryOfSections?: ProfitLossTableReportSection[];
    };
}

export interface ProfitLossReportMetaDto {
    dataStart: string;
    dataEnd?: string | null;
}

export type ProfitLossTableReportSectionsDto = Record<
    ProfitLossTableReportSection,
    PLSection
>;

export enum ProfitLossTableReportSection {
    INCOME = "income",
    COGS = "cogs",
    GROSS_PROFIT = "grossProfit",
    EXPENSES = "expenses",
    NET_OPERATING_INCOME = "netOperatingIncome",
    OTHER_INCOME = "otherIncome",
    OTHER_EXPENSES = "otherExpenses",
    NET_OTHER_INCOME = "netOtherIncome",
    NET_INCOME = "netIncome",
}

export const ProfitLossReportSummarySections: ProfitLossTableReportSection[] = [
    ProfitLossTableReportSection.GROSS_PROFIT,
    ProfitLossTableReportSection.NET_OPERATING_INCOME,
    ProfitLossTableReportSection.NET_OTHER_INCOME,
    ProfitLossTableReportSection.NET_INCOME,
] as const;

export const CASH_FLOW_PERCENT_DIFF_SUFFIX = "-percent-diff";
export const CASH_FLOW_VALUE_DIFF_SUFFIX = "-value-diff";
