import React, { useCallback, useMemo } from "react";
import { AccountingReportFilters } from "./types";
import { Card } from "../general/Card/Card";
import { DateRange } from "../../helpers/date";
import { DateRangeFilter } from "../general/DateRangeFilter/DateRangeFilter";
import styles from "./AccountingReportFiltersForm.module.scss";
import { EntitySelect } from "../entity/EntitySelect";
import { useEntitiesWithAccountingAvailable } from "./useEntitiesWithAccountingAvailable";
import {
    AccountingFiltersSetter,
    useAccountingTabContext,
} from "./useAccountingTab";
import { Button } from "react-bootstrap";

interface Props {
    filters: AccountingReportFilters;
    setFilters: AccountingFiltersSetter;
    additionalButtons?: React.ReactNode;
    additionalFilters?: React.ReactNode;
    selectExactDays?: boolean;
    requireDateSelection?: boolean;
}

export const AccountingReportFiltersForm: React.FC<Props> = ({
    filters,
    setFilters,
    additionalButtons,
    additionalFilters,
    selectExactDays,
    requireDateSelection,
}) => {
    const { setShowCreateJournalEntryModal } = useAccountingTabContext();

    const availableEntities = useEntitiesWithAccountingAvailable();
    const setFilterValue = useCallback(
        (key: keyof AccountingReportFilters, value: any) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                [key]: value,
            }));
        },
        [setFilters],
    );

    const selectRange = useCallback(
        (range?: Partial<DateRange>) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                startDate: range?.start,
                endDate: range?.end,
            }));
        },
        [setFilters],
    );

    const dateRange = useMemo(
        () => ({
            start: filters.startDate,
            end: filters.endDate,
        }),
        [filters.startDate, filters.endDate],
    );

    const selectedEntity = useMemo(
        () => availableEntities.find((e) => e.id === filters.entityId),
        [filters.entityId, availableEntities],
    );

    return (
        <Card className={styles.card}>
            <DateRangeFilter
                onChange={selectRange}
                monthOnly={!selectExactDays}
                dateRange={dateRange}
                buttonSize="sm"
                requireSelection={requireDateSelection}
            />

            {additionalFilters}

            <EntitySelect
                entities={availableEntities}
                selectedEntity={selectedEntity}
                onChange={(e) => setFilterValue("entityId", e?.id)}
                dropdownKey={"accounting-report-entity-select"}
                className={styles.entitySelect}
                size="sm"
            />

            <Button
                onClick={() => setShowCreateJournalEntryModal(true)}
                variant="secondary"
                size="sm"
            >
                Create journal entry
            </Button>
            {additionalButtons}
        </Card>
    );
};
