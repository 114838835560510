import React, { useState } from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { ButtonProps } from "react-bootstrap/Button";
import { Button } from "react-bootstrap";
import { useWidgetOptions } from "./useWidgetOptions";

interface BookCallProps
    extends Pick<ButtonProps, "variant" | "size" | "disabled" | "className"> {
    url: string;
    onBooked?: () => void;
}

export const BookCallButton: React.FC<BookCallProps> = ({
    url,
    children,
    onBooked,
    ...btnProps
}) => {
    const { prefill, pageSettings } = useWidgetOptions();
    const [open, setOpen] = useState(false);

    useCalendlyEventListener({
        onEventScheduled: onBooked,
    });

    return (
        <>
            <Button {...btnProps} onClick={() => setOpen(true)}>
                {children}
            </Button>

            <PopupModal
                onModalClose={() => setOpen(false)}
                open={open}
                rootElement={document.body}
                url={url}
                pageSettings={pageSettings}
                prefill={prefill}
            />
        </>
    );
};
