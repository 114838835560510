import { PlaidConnection } from "../../../common/types/plaidConnection";
import { differenceInMonths, subMonths } from "date-fns";
import { institutionsWithManuallyProvidedData } from "../../../common/constants";

const DATA_FETCH_RANGE_MONTHS = 6;
export function useManualDataUpload(connection: PlaidConnection) {
    const earliestBookkeepingStart = new Date(
        Math.min(
            ...(connection.accounts ?? [])
                .filter((acc) => !!acc.financialAccount.entity)
                .map((acc) => {
                    return new Date(
                        acc.financialAccount.entity!.bookkeepingStart,
                    ).getTime();
                }),
        ),
    );

    const isMissingData =
        connection.institutionId &&
        !connection.historicalDataProvidedManually &&
        institutionsWithManuallyProvidedData.includes(
            connection.institutionId,
        ) &&
        differenceInMonths(connection.createdAt, earliestBookkeepingStart) >
            DATA_FETCH_RANGE_MONTHS;

    return {
        isMissingData: Boolean(isMissingData),
        missingDataStart: earliestBookkeepingStart,
        missingDataEnd: subMonths(
            connection.createdAt,
            DATA_FETCH_RANGE_MONTHS,
        ),
    };
}
