import React, { useCallback, useMemo } from "react";
import { Plans } from "../../billing/Plans/Plans";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";
import { Button } from "react-bootstrap";
import { ChatIcon, ThunderboltIcon } from "../../../icons";
import { SettingsSection } from "../SettingsSection";
import { usePlanManagement } from "../../billing/PlanManagement/PlanManagementContext";
import { ENTERPRISE_TRIAL_CALL_URL } from "../../../common/billing";
import { BookCallButton } from "../../general/BookCall/BookCallButton";
import { format } from "date-fns";
import { fullDateFormat } from "../../../common/helpers/date";
import { useUpdateUserMutation } from "../../../mutations/user";
import { intercom } from "../../../lib/intercom";
import { FAQSection } from "./FAQSection";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { trackEvent } from "../../../lib/analytics";

export const PlansPage: React.FC = () => {
    const { downgrade, upgrade } = usePlanManagement();
    const {
        plans,
        currentPlan,
        nextPlan,
        periodEnd,
        trialAvailable,
        isTrialing,
        bookedEnterpriseCall,
    } = useBillingStatus();
    const updateUser = useUpdateUserMutation();

    useEffectOnce(() => {
        void trackEvent("view_plans_tab");
    });

    const handleCallBooked = useCallback(() => {
        updateUser.mutate({ bookedEnterpriseCall: true });
    }, [updateUser]);

    const inactiveEnterpriseAction = useMemo(() => {
        let inactiveEnterpriseCta: React.ReactNode;

        if (bookedEnterpriseCall) {
            inactiveEnterpriseCta = <>Call booked</>;
        } else if (trialAvailable) {
            inactiveEnterpriseCta = (
                <>
                    <ChatIcon className="icon-size-md mr-2" /> Request trial
                </>
            );
        } else {
            inactiveEnterpriseCta = (
                <>
                    <ChatIcon className="icon-size-md mr-2" /> Book a call
                </>
            );
        }

        return (
            <BookCallButton
                variant={bookedEnterpriseCall ? "secondary" : "cta"}
                size="sm"
                url={ENTERPRISE_TRIAL_CALL_URL}
                disabled={bookedEnterpriseCall}
                onBooked={handleCallBooked}
                data-testid="book-call-button"
            >
                {inactiveEnterpriseCta}
            </BookCallButton>
        );
    }, [bookedEnterpriseCall, handleCallBooked, trialAvailable]);

    const renderUnsubscribedUserAction = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            if (plan === FLAT_RATE_PLANS.FREE) {
                return (
                    <Button
                        variant="secondary"
                        size="sm"
                        data-testid="active-plan-button"
                        disabled
                    >
                        Active
                    </Button>
                );
            } else if (plan === FLAT_RATE_PLANS.ENTERPRISE) {
                return inactiveEnterpriseAction;
            } else {
                return (
                    <Button
                        variant="cta"
                        size="sm"
                        onClick={() => upgrade(plan, trialAvailable)}
                        data-testid={
                            trialAvailable
                                ? `start-trial-${plan.toLowerCase()}-button`
                                : `upgrade-${plan.toLowerCase()}-button`
                        }
                    >
                        <ThunderboltIcon className="icon-size-sm mr-1" />{" "}
                        {trialAvailable ? "Start trial" : "Upgrade"}
                    </Button>
                );
            }
        },
        [inactiveEnterpriseAction, trialAvailable, upgrade],
    );

    const renderSubscribedUserAction = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            if (plan === currentPlan) {
                return (
                    <Button
                        variant="secondary"
                        size="sm"
                        disabled
                        data-testid="until-period-end-button"
                    >
                        {currentPlan !== nextPlan ? (
                            <>Until {format(periodEnd!, fullDateFormat)}</>
                        ) : (
                            "Active"
                        )}
                    </Button>
                );
            } else if (plan === FLAT_RATE_PLANS.ENTERPRISE) {
                return inactiveEnterpriseAction;
            } else if (
                plans.findIndex((p) => p.plan === plan) >
                plans.findIndex((p) => p.plan === currentPlan)
            ) {
                return (
                    <Button
                        variant="cta"
                        size="sm"
                        onClick={() => upgrade(plan, isTrialing)}
                        data-testid={`upgrade-${plan.toLowerCase()}-button`}
                    >
                        <ThunderboltIcon className="mr-1" /> Upgrade
                    </Button>
                );
            } else if (plan === nextPlan) {
                return (
                    <Button
                        variant="secondary"
                        size="sm"
                        disabled
                        data-testid="after-period-end-button"
                    >
                        After {format(periodEnd!, fullDateFormat)}
                    </Button>
                );
            } else {
                return (
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => downgrade(plan)}
                        data-testid={`downgrade-${plan.toLowerCase()}-button`}
                    >
                        Downgrade
                    </Button>
                );
            }
        },
        [
            currentPlan,
            downgrade,
            inactiveEnterpriseAction,
            isTrialing,
            nextPlan,
            periodEnd,
            plans,
            upgrade,
        ],
    );

    const renderAction = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            return currentPlan === FLAT_RATE_PLANS.FREE
                ? renderUnsubscribedUserAction(plan)
                : renderSubscribedUserAction(plan);
        },
        [currentPlan, renderSubscribedUserAction, renderUnsubscribedUserAction],
    );

    return (
        <div>
            <header>
                <h3 className="settings__heading">Plans</h3>
            </header>

            <SettingsSection
                heading="All plans"
                subheading={
                    <>
                        Need help deciding which plan is best for you?{" "}
                        <Button
                            variant="link"
                            size="sm"
                            className="p-0"
                            onClick={() => intercom("show")}
                        >
                            Talk to our experts
                        </Button>
                    </>
                }
            >
                <Plans
                    plans={plans}
                    renderAction={renderAction}
                    recommended={null}
                />
            </SettingsSection>

            <FAQSection onCallBooked={handleCallBooked} />
        </div>
    );
};
