import { FinalizeSubscriptionDto } from "../common/dto/billing/finalize-subscription.dto";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { subscribe } from "../lib/billing";
import { updateUser } from "../reducers/appState";

export function useSubscribeMutation() {
    const dispatch = useDispatch();

    return useMutation((params: FinalizeSubscriptionDto) => subscribe(params), {
        onSuccess: (user) => {
            dispatch(updateUser(user));
        },
    });
}
