import React from "react";
import { useQueryParam } from "../../hooks/useQueryParam";
import { PageCoverLoader } from "../general/PageCoverLoader/PageCoverLoader";
import { useSubscribeMutation } from "../../mutations/billing";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "../../hooks/useEffectOnce";

export const FinalizeSubscription: React.FC = () => {
    const history = useHistory();
    const paymentIntentId = useQueryParam("payment_intent") ?? undefined;
    const setupIntentId = useQueryParam("setup_intent") ?? undefined;
    const code = useQueryParam("code") ?? undefined;
    const subscribeMutation = useSubscribeMutation();

    useEffectOnce(() => {
        subscribeMutation
            .mutateAsync({
                paymentIntentId,
                setupIntentId,
                code,
            })
            .then(() => {
                history.push("/settings/billing");
            });
    });

    return <PageCoverLoader>Waiting for partner response</PageCoverLoader>;
};
