import { useMutation } from "react-query";
import { Taxonomy } from "../common/categories";
import { invalidateTransactionDetailsQueries } from "../components/transactions/TransactionDetails/useTransactionDetailsQuery";
import { invalidateTransactionsQueries } from "../components/transactions/useTransactionsQuery";
import { customCategoriesRest } from "../lib/restClient";
import { CUSTOM_CATEGORIES_QUERY_KEY, queryClient } from "../queryClient";
import { useWorkspaceContext } from "../state/workspaceContext";

export function useCreateCustomCategoryMutation() {
    const { activeWorkspace } = useWorkspaceContext();
    if (!activeWorkspace) {
        throw new Error("Expecting active workspace");
    }
    return useMutation(
        async (vars: { mainCategoryId: Taxonomy; label: string }) =>
            customCategoriesRest.create({
                primaryAdminUserId: activeWorkspace.primaryAdminId,
                ...vars,
            }),
        {
            async onSuccess() {
                await invalidateCustomCategoriesQueries();
            },
        },
    );
}

export function useUpdateCustomCategoryMutation(categoryId?: number) {
    return useMutation(
        async (vars: { label: string }) =>
            categoryId && customCategoriesRest.update(categoryId, vars),
        {
            async onSuccess() {
                await invalidateCustomCategoriesQueries();
            },
        },
    );
}

export function useDeleteCustomCategoryMutation(categoryId?: number) {
    return useMutation(
        async () => categoryId && customCategoriesRest.delete(categoryId),
        {
            async onSuccess() {
                await invalidateCustomCategoriesQueries();
            },
        },
    );
}

async function invalidateCustomCategoriesQueries() {
    await Promise.all([
        queryClient.invalidateQueries(CUSTOM_CATEGORIES_QUERY_KEY),
        invalidateTransactionsQueries(),
        invalidateTransactionDetailsQueries(),
    ]);
}
