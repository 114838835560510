import { useMemo } from "react";
import { useQueryParam } from "../../hooks/useQueryParam";
import { ReportingTabCycle } from "../../common/constants";

export interface ReportParams {
    entityId?: number;
    cycle?: ReportingTabCycle;
    start?: Date;
    end?: Date;
}

export function useReportParams(): ReportParams {
    const entityParam = useQueryParam("entity");
    const cycleParam = useQueryParam("cycle");
    const startParam = useQueryParam("start");
    const endParam = useQueryParam("end");

    const entityId = entityParam ? Number(entityParam) : undefined;
    const cycle = cycleParam ? (cycleParam as ReportingTabCycle) : undefined;
    const start = useMemo(
        () => (startParam ? new Date(startParam) : undefined),
        [startParam],
    );
    const end = useMemo(
        () => (endParam ? new Date(endParam) : undefined),
        [endParam],
    );

    return {
        entityId,
        cycle,
        start,
        end,
    };
}
