import { useUser } from "./useUser";
import { useIsPrimaryAdminInAnyEntity } from "./useRoles";
import { addBusinessDays } from "date-fns";
import { User } from "../common/types/user";
import { useDefaultBookkeepingStartDate } from "./useDefaultBookkeepingStartDate";

const HOURS_FOR_INITIAL_CATEGORIZATION = 24;
const HOURS_FOR_CATEGORIZATION_AFTER_SUBSCRIPTION = 48;

function getDeadlineForCategorization(
    requiredBusinessHours: number,
    eventTime: Date,
): Date {
    const requiredBusinessDays = Math.ceil(requiredBusinessHours / 24);

    return addBusinessDays(eventTime, requiredBusinessDays);
}

function getDeadlineForUser(user: User, defaultBookkeepingStartDate: Date) {
    if (
        user.subscribedAt &&
        user.bookkeepingStartDate < defaultBookkeepingStartDate &&
        !user.eventsTriggered?.categorizationAfterSubscriptionCompleted
    ) {
        return getDeadlineForCategorization(
            HOURS_FOR_CATEGORIZATION_AFTER_SUBSCRIPTION,
            user.subscribedAt,
        );
    }

    if (
        !user.subscribedAt &&
        user.onboardingCompletedAt &&
        !user.eventsTriggered?.initialCategorizationCompleted
    ) {
        return getDeadlineForCategorization(
            HOURS_FOR_INITIAL_CATEGORIZATION,
            user.onboardingCompletedAt,
        );
    }
}

export function useIsCategorizationInProgress() {
    const user = useUser();
    const isPrimaryAdmin = useIsPrimaryAdminInAnyEntity();
    const defaultBookkeepingStartDate = useDefaultBookkeepingStartDate();

    if (!isPrimaryAdmin) {
        return false;
    }

    const deadline = getDeadlineForUser(user, defaultBookkeepingStartDate);

    if (deadline) {
        return deadline > new Date();
    }

    return false;
}
