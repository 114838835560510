import React from "react";

export const CategorizationInProgressNotification: React.FC = () => {
    // correct link to be provided later
    return (
        <>
            Your transactions are being{" "}
            <a
                href="https://kickfinance.notion.site/Your-transactions-are-under-review-199baa47b5a246fbb43e2a589b9c2f22?pvs=4"
                target="_blank"
                rel="noreferrer"
            >
                reviewed
            </a>
            .
        </>
    );
};
