import React from "react";
import { Alert, Button } from "react-bootstrap";
import { ThunderboltIcon } from "../../../icons";
import { useBillingManagement } from "../../../hooks/useBillingManagement";
import "./CallToActionBanner.scss";

export interface CallToActionBannerProps {
    children:
        | React.ReactNode
        | ((goProBtn: React.ReactNode) => React.ReactNode);
    customCta?: React.ReactNode;
}

export const CallToActionBanner: React.FC<CallToActionBannerProps> = ({
    children,
    customCta,
}) => {
    const { onUpgrade } = useBillingManagement();

    return (
        <Alert variant="primary" className="call-to-action-banner">
            <span className="call-to-action-banner__content">
                {typeof children === "function"
                    ? children(
                          <Button
                              variant="plain"
                              onClick={onUpgrade}
                              className="mr-1"
                          >
                              Subscribe <ThunderboltIcon className="ml-1" />
                          </Button>,
                      )
                    : children}
            </span>

            <aside className="call-to-action-banner__action">
                {customCta ?? (
                    <Button variant="cta" size="sm" onClick={onUpgrade}>
                        Subscribe <ThunderboltIcon className="ml-1" />
                    </Button>
                )}
            </aside>
        </Alert>
    );
};
