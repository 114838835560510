import React from "react";
import { SpendingAreaChart } from "./SpendingAreaChart";
import { SpendingPieChart } from "./SpendingPieChart";
import { Loader } from "../general/Loader";
import { SpendingLegend } from "./SpendingLegend";
import { EmptyState } from "../general/EmptyState/EmptyState";
import { useReportsContext } from "./reports.context";
import { Card } from "../general/Card/Card";

interface Props {
    resetFilters: () => void;
}

export const Spending: React.FC<Props> = ({ resetFilters }) => {
    const { spendingReport, isSpendingReportLoading, spendingFiltersUsed } =
        useReportsContext();

    if (isSpendingReportLoading) {
        return <Loader />;
    } else {
        return spendingReport?.data.length && spendingFiltersUsed ? (
            <>
                <SpendingAreaChart
                    chart={spendingReport}
                    filters={spendingFiltersUsed}
                />
                <Card className="spending-pie-chart">
                    <SpendingPieChart chart={spendingReport} />
                    <div className="spending-pie-chart__legend">
                        <SpendingLegend chart={spendingReport} />
                    </div>
                </Card>
            </>
        ) : (
            <div className="reporting-tab__empty-state">
                <EmptyState
                    header="No matching transactions"
                    body="The filters can't reproduce a report"
                    ctaText="Reset all filters"
                    action={resetFilters}
                    buttonVariant={"secondary"}
                />
            </div>
        );
    }
};
