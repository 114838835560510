import { Entity } from "../../types/entity";
import { FinancialAccount } from "../../types/financialAccount";
import { UserRole } from "../../types/userEntityAssignment";
import { EntityDto } from "../entity/entity.dto";
import { FinancialAccountDto } from "../financialAccount/financial-account.dto";

export interface EntityContext {
    entity: Entity;
    role: UserRole;
    financialAccounts: FinancialAccount[];
    isPaying: boolean;
}

export class EntityContextDto {
    static deserialize(dto: EntityContextDto): EntityContext {
        return {
            entity: EntityDto.deserialize(dto.entity),
            role: dto.role,
            financialAccounts: dto.financialAccounts.map(
                FinancialAccountDto.deserialize,
            ),
            isPaying: dto.isPaying,
        };
    }

    entity: EntityDto;
    role: UserRole;
    financialAccounts: FinancialAccountDto[];
    isPaying: boolean;

    constructor(context: EntityContext) {
        this.entity = new EntityDto(context.entity);
        this.role = context.role;
        this.financialAccounts = context.financialAccounts.map(
            (cwa: FinancialAccount) => new FinancialAccountDto(cwa),
        );
        this.isPaying = context.isPaying;
    }
}
