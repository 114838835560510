import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { DetachIcon, SearchIcon } from "../../../../icons";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { topStartPopperConfig } from "../../../../helpers/overlays";
import { RECEIPT_UPLOAD_HIGHLIGHTED_ELEMENT } from "../../ReceiptUpload/lib";
import { StandardModal } from "../../../general/Modal/Modal";
import { formatDateForTransaction } from "../../../../helpers/date";

import styles from "./TransactionActionMatchTransfer.module.scss";
import { TransferMatchingContainer } from "../../TransferMatching/TransferMatchingContainer";
import { Transaction } from "../../../../common/types/transaction";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { useUnmatchTransfer } from "../../../../mutations/transaction";
import { Amount } from "../../../general/Amount/Amount";

interface Props {
    required: boolean;
    transaction: EagerLoaded<
        Transaction,
        "transactionMatches" | "pairedTransfer"
    >;
}

const TRUNCATED_DESCRIPTION_LENGTH = 20;

export const TransactionActionsMatchTransfer: React.FC<Props> = ({
    transaction,
    required,
}) => {
    const [showMatchingModal, setShowMatchingModal] = useState(false);

    const [pairedTransaction, setPairedTransaction] = useState(
        transaction?.pairedTransfer,
    );

    useEffect(() => {
        setPairedTransaction(transaction?.pairedTransfer);
    }, [transaction?.pairedTransfer]);

    const description = pairedTransaction?.description ?? "";

    const truncatedDescription =
        description.length > TRUNCATED_DESCRIPTION_LENGTH
            ? description.slice(0, TRUNCATED_DESCRIPTION_LENGTH - 3) + "..."
            : description;

    const unlinkMatch = useUnmatchTransfer({
        financialTransaction: transaction,
        onSuccess: () => {
            setPairedTransaction(null);
        },
    });

    return (
        <>
            <div
                className={classNames(
                    "form-group form-group--with-label form-group--inside-label form-group--icon form-group--icon--right",
                    RECEIPT_UPLOAD_HIGHLIGHTED_ELEMENT,
                    styles.addReceipt,
                    styles.transactionActionMatch,
                    required && !pairedTransaction && styles.required,
                )}
            >
                {pairedTransaction ? (
                    <div>
                        <div className="d-flex justify-content-between align-items-center form-control mb-0">
                            <span className="form-group__label">
                                Matched transaction
                            </span>

                            <div
                                className={
                                    styles.matchedTransactionIconsContainer
                                }
                            >
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip
                                            id={`transaction-unmatch-${transaction.id}`}
                                        >
                                            Unmatch transaction
                                        </Tooltip>
                                    }
                                    placement="top"
                                    popperConfig={topStartPopperConfig}
                                >
                                    <button className="form-group__icon">
                                        <DetachIcon
                                            onClick={() => {
                                                unlinkMatch.mutateAsync();
                                            }}
                                        />
                                    </button>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    overlay={
                                        <Tooltip
                                            id={`transaction-match-replace-${transaction.id}`}
                                        >
                                            Replace transaction match
                                        </Tooltip>
                                    }
                                    placement="top"
                                    popperConfig={topStartPopperConfig}
                                >
                                    <button
                                        className="form-group__icon"
                                        onClick={() =>
                                            setShowMatchingModal(true)
                                        }
                                    >
                                        <SearchIcon />
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className={styles.cardFooter}>
                            <div className={styles.cardFooterText}>
                                <span>
                                    {formatDateForTransaction(
                                        new Date(pairedTransaction.date),
                                    )}
                                </span>
                                {description.length >
                                TRUNCATED_DESCRIPTION_LENGTH ? (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip
                                                id={`transaction-actions-match-description-${transaction.id}`}
                                            >
                                                {description}
                                            </Tooltip>
                                        }
                                        placement="bottom"
                                        popperConfig={topStartPopperConfig}
                                    >
                                        <span className={styles.truncatedText}>
                                            {truncatedDescription}
                                        </span>
                                    </OverlayTrigger>
                                ) : (
                                    <span className={styles.truncatedText}>
                                        {truncatedDescription}
                                    </span>
                                )}
                            </div>
                            <div className={classNames(styles.cardFooterText)}>
                                <span
                                    className={classNames(
                                        styles.cardFooterTextAmount,
                                        pairedTransaction.amount > 0 &&
                                            styles.cardFooterTextAmountPositive,
                                    )}
                                >
                                    <Amount
                                        amount={pairedTransaction.amount}
                                        separateCurrencySign
                                        showNegative={true}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center form-control mb-0">
                        <span className="form-group__label">
                            Find a transaction match
                        </span>

                        <div
                            className={styles.matchedTransactionIconsContainer}
                        >
                            <OverlayTrigger
                                overlay={
                                    <Tooltip
                                        id={`transaction-match-${transaction.id}`}
                                    >
                                        Link a transaction match for this
                                        transfer
                                    </Tooltip>
                                }
                                placement="top"
                                popperConfig={topStartPopperConfig}
                            >
                                <button
                                    className="form-group__icon"
                                    onClick={() => setShowMatchingModal(true)}
                                >
                                    <SearchIcon />
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                )}
            </div>

            <StandardModal
                className="fullscreen-modal"
                show={showMatchingModal}
                onHide={() => setShowMatchingModal(false)}
            >
                <Modal.Header closeButton />
                {transaction && showMatchingModal && (
                    <TransferMatchingContainer
                        key={transaction.id}
                        transaction={transaction}
                        transactionId={transaction.id}
                    />
                )}
            </StandardModal>
        </>
    );
};
