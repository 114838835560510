import { Cell, Header } from "@tanstack/react-table";
import { PLDataRow } from "./ProfitLossReportTableContainer";
import { useReportsContext } from "../reports.context";
import { useMemo } from "react";
import { parseCellLabel } from "./cellLabels.helper";
import { ReportingTabCycle } from "../../../common/constants";
import {
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    isAfter,
    isBefore,
    isValid,
    parse,
    startOfDay,
} from "date-fns";
import { PLColumns } from "./useProfitLossReportTable";
import { applyReportFiltersToLabel } from "../../../common/helpers/reports";

export function useIsWithinBounds(
    cell: Cell<PLDataRow, any> | Header<PLDataRow, any>,
) {
    const { profitLossReport, profitLossFiltersUsed } = useReportsContext();

    const cellHeader = cell.column.columnDef.header as string;
    const isTitle = cell.column.id === PLColumns.TITLE;
    const meta = profitLossReport?.meta;

    const startDate = useMemo(() => {
        if (!profitLossFiltersUsed?.cycle || isTitle) {
            return null;
        }

        const parsed = applyReportFiltersToLabel(
            profitLossFiltersUsed,
            parseCellLabel(cellHeader, profitLossFiltersUsed.cycle),
        );
        return isValid(parsed) ? parsed : null;
    }, [profitLossFiltersUsed, isTitle, cellHeader]);

    const endDate = useMemo(() => {
        if (!profitLossFiltersUsed?.cycle || !startDate || isTitle) {
            return null;
        }

        return applyReportFiltersToLabel(
            profitLossFiltersUsed,
            profitLossFiltersUsed.cycle === ReportingTabCycle.MONTH
                ? endOfMonth(startDate)
                : endOfISOWeek(startDate),
        );
    }, [isTitle, profitLossFiltersUsed, startDate]);

    return useMemo(() => {
        if (!meta?.dataStart || !startDate || !endDate || isTitle) {
            return true;
        }

        const { dataStart, dataEnd } = meta;

        const metaStartDate = startOfDay(
            parse(dataStart, "yyyy-MM-dd", new Date()),
        );
        const metaEndDate =
            dataEnd && endOfDay(parse(dataEnd, "yyyy-MM-dd", new Date()));

        if (!metaEndDate) {
            return !isBefore(startDate, metaStartDate);
        } else {
            return (
                !isBefore(startDate, metaStartDate) &&
                !isAfter(endDate, metaEndDate)
            );
        }
    }, [endDate, meta, startDate, isTitle]);
}
