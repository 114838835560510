import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    SelectedTransaction,
    transactionsBulkActionsContext,
    TransactionsBulkActionsContextValue,
} from "./transactionsBulkActionsContext";
import { Transaction } from "../../../common/types/transaction";
import { keyBy, mapValues, omit, omitBy } from "lodash";
import { bulkUpdateTransactions } from "../../../lib/transactions";
import {
    BULK_ACTIONS_CHUNK_SIZE,
    BulkUpdateDetails,
    BulkUpdateMode,
    BulkUpdateParams,
    BulkUpdateStatus,
    preparePayloadsForFilters,
    preparePayloadsForSelectedTransactions,
    preparePayloadsForUndo,
} from "./lib";
import { useObserver } from "../../../hooks/useObserver";
import { useTaxSavingRulesForBulkActions } from "./useTaxSavingRulesForBulkActions";
import { SelectedAnswer, TaxAnswers } from "../TaxQuestions/types";
import { TransactionsFilters } from "../filters/lib";
import { useSequentialRequest } from "../../../hooks/useSequentialRequests";
import { BulkUpdateTransactionsDto } from "../../../common/dto/transactions/bulk/bulk-update-transactions.dto";
import { useTaxQuestionsForBulkActions } from "./useTaxQuestionsForBulkActions";
import { ToastMessagesContext } from "../../general/ToastMessages/ToastMessages.context";
import { PotentialSavingsContext } from "../PotentialSavingsContext";
import { ToastMessageType } from "../../general/ToastMessages/lib/common";
import { useEntities } from "../../../hooks/useEntities";
import { TransactionDirectionType } from "../../../common/categories";

function mapTransactionToSelected(
    transaction: Transaction,
): SelectedTransaction {
    return {
        id: transaction.id,
        type: transaction.type,
        isIgnored: transaction.isIgnored,
        category: transaction.category,
        isBusiness: transaction.isBusiness,
        memo: transaction.memo,
        taxQuestionAnswers: transaction.taxQuestionAnswers,
        entity: transaction.entity,
        transactionDirection:
            transaction.amount > 0
                ? TransactionDirectionType.incoming
                : TransactionDirectionType.outgoing,
    };
}

const TOAST_MESSAGE_KEY = "transactions-bulk-actions";

interface Props {
    onUpdated(
        updatedTransactions: Transaction[],
        details: BulkUpdateDetails,
    ): void;
    totalTransactions: number;
    currentFilters: TransactionsFilters;
}

export const TransactionsBulkActionsContextProvider: React.FC<Props> = ({
    onUpdated,
    children,
    currentFilters,
    totalTransactions,
}) => {
    const [updateDetails, setUpdateDetails] =
        useState<BulkUpdateDetails | null>(null);
    const [answers, setAnswers] = useState<TaxAnswers>({} as TaxAnswers);
    const { fetchSavings } = useContext(PotentialSavingsContext);

    const [selected, setSelected] = useState<
        Record<number, SelectedTransaction>
    >({});

    const [filtersSelection, setFiltersSelection] =
        useState<TransactionsFilters | null>(null);

    const {
        notify: notifySelectionChanged,
        subscribe: subscribeToSelectionChanges,
    } = useObserver<void>();
    const { registerToast } = useContext(ToastMessagesContext);

    const updateSequence = useSequentialRequest(bulkUpdateTransactions);

    const entities = useEntities();

    useEffect(() => {
        if (
            updateSequence.progress > 0 &&
            updateSequence.progress < updateSequence.total &&
            updateDetails
        ) {
            registerToast(TOAST_MESSAGE_KEY, {
                type: ToastMessageType.FINANCIAL_TRANSACTIONS_BULK_UPDATE,
                data: {
                    updateDetails,
                    updateProgress:
                        updateSequence.progress * BULK_ACTIONS_CHUNK_SIZE,
                },
                startTimer: false,
            });
        }
    }, [
        updateSequence.progress,
        updateSequence.total,
        updateDetails,
        registerToast,
    ]);

    const selectedCount = useMemo(
        () =>
            filtersSelection ? totalTransactions : Object.keys(selected).length,
        [filtersSelection, selected, totalTransactions],
    );

    useEffect(() => {
        setAnswers({} as TaxAnswers);
        notifySelectionChanged();
    }, [selectedCount, notifySelectionChanged]);

    useEffect(() => {
        setFiltersSelection(null);
    }, [currentFilters]);

    const taxRule = useTaxSavingRulesForBulkActions(selected, filtersSelection);
    const taxQuestions = useTaxQuestionsForBulkActions(
        selected,
        filtersSelection,
        answers,
    );

    const isSelected: TransactionsBulkActionsContextValue["isSelected"] =
        useCallback((transaction) => !!selected[transaction.id], [selected]);

    const select: TransactionsBulkActionsContextValue["select"] = useCallback(
        (transactionOrTransactions) => {
            const selectionUpdate: Record<number, SelectedTransaction> =
                Array.isArray(transactionOrTransactions)
                    ? keyBy(
                          transactionOrTransactions.map(
                              mapTransactionToSelected,
                          ),
                          "id",
                      )
                    : {
                          [transactionOrTransactions.id]:
                              mapTransactionToSelected(
                                  transactionOrTransactions,
                              ),
                      };

            setSelected((prev) => ({ ...prev, ...selectionUpdate }));
        },
        [],
    );
    const deselect: TransactionsBulkActionsContextValue["deselect"] =
        useCallback((transactionOrTransactions) => {
            if (Array.isArray(transactionOrTransactions)) {
                const dict = keyBy(transactionOrTransactions, "id");
                setSelected((prev) =>
                    omitBy(prev, (value) => value.id in dict),
                );
            } else {
                setSelected((prev) => omit(prev, transactionOrTransactions.id));
            }
        }, []);

    const doUpdate = useCallback(
        async (
            details: BulkUpdateDetails,
            payloads: BulkUpdateTransactionsDto[],
        ) => {
            setUpdateDetails(details);

            const result = (await updateSequence.start(payloads)).flat();

            if (details.mode === BulkUpdateMode.SELECTED) {
                setSelected(keyBy(result.map(mapTransactionToSelected), "id"));
            }

            onUpdated(result, details);

            if (details.mode === BulkUpdateMode.SELECTED) {
                fetchSavings();
            }

            setUpdateDetails((prev) => ({
                ...prev!,
                status: BulkUpdateStatus.SUCCESS,
            }));
        },
        [onUpdated, updateSequence, fetchSavings],
    );

    const undo = useCallback(
        async (
            previousTransactions: Record<number, SelectedTransaction>,
            updateDetailsForUndo: BulkUpdateDetails,
        ) => {
            const payloads = preparePayloadsForUndo({
                lastUpdateParams: updateDetailsForUndo,
                previousTransactions,
            });

            try {
                const newDetails = {
                    ...updateDetailsForUndo,
                    status: BulkUpdateStatus.UPDATING,
                    transactionsCount: Object.keys(previousTransactions).length,
                    isUndo: true,
                };
                await doUpdate(newDetails, payloads);

                registerToast(TOAST_MESSAGE_KEY, {
                    type: ToastMessageType.FINANCIAL_TRANSACTIONS_BULK_UPDATE,
                    data: {
                        updateDetails: {
                            ...newDetails,
                            status: BulkUpdateStatus.SUCCESS,
                        },
                    },
                    startTimer: true,
                });
            } catch (e) {
                setUpdateDetails((prev) => ({
                    ...prev!,
                    status: BulkUpdateStatus.ERROR,
                }));
            }
        },
        [doUpdate, registerToast],
    );

    const updateSelected = useCallback(
        async (params: BulkUpdateParams) => {
            const payloads = filtersSelection
                ? preparePayloadsForFilters({
                      updateParams: params,
                      filters: {
                          ...filtersSelection,
                          entitiesAccounts:
                              filtersSelection.entitiesAccounts ??
                              entities.map((e) => ({
                                  entityId: e.id,
                              })),
                      },
                      transactionCount: selectedCount,
                  })
                : preparePayloadsForSelectedTransactions({
                      updateParams: params,
                      selected: selected,
                  });

            try {
                const newDetails = {
                    status: BulkUpdateStatus.UPDATING,
                    transactionsCount: selectedCount,
                    mode: filtersSelection
                        ? BulkUpdateMode.FILTERS
                        : BulkUpdateMode.SELECTED,
                    ...params,
                };

                await doUpdate(newDetails, payloads);

                registerToast(TOAST_MESSAGE_KEY, {
                    type: ToastMessageType.FINANCIAL_TRANSACTIONS_BULK_UPDATE,
                    data: {
                        updateDetails: {
                            ...newDetails,
                            status: BulkUpdateStatus.SUCCESS,
                        },
                        previousTransactions: filtersSelection
                            ? undefined
                            : { ...selected },
                        undo,
                    },
                    startTimer: true,
                });
            } catch (e) {
                setUpdateDetails((prev) => ({
                    ...prev!,
                    status: BulkUpdateStatus.ERROR,
                }));
            }
        },
        [
            doUpdate,
            filtersSelection,
            selected,
            selectedCount,
            registerToast,
            undo,
            entities,
        ],
    );

    const handleAnswer = useCallback(
        (selectedAnswers: SelectedAnswer[]) => {
            setAnswers((prev) => ({
                ...prev,
                ...mapValues(keyBy(selectedAnswers, "key"), "answer"),
            }));

            return updateSelected({
                update: "taxQuestionAnswers",
                value: selectedAnswers,
            });
        },
        [updateSelected],
    );

    const clearSelection: TransactionsBulkActionsContextValue["clearSelection"] =
        useCallback(() => {
            setSelected({});
            setFiltersSelection(null);
        }, []);

    const selectByCurrentFilters = useCallback(() => {
        clearSelection();
        setFiltersSelection(currentFilters);
    }, [clearSelection, currentFilters]);

    const bulkActionsContextValue = useMemo(
        () => ({
            enabled: true,
            select,
            selected,
            deselect,
            clearSelection,
            isSelected,
            selectedCount,
            updateDetails: updateDetails,
            updateSelected,
            subscribeToSelectionChanges,
            taxRule,
            answers,
            taxQuestions,
            handleAnswer,
            selectAll: selectByCurrentFilters,
            hasSelectedAll: !!filtersSelection,
            totalTransactions,
        }),
        [
            answers,
            clearSelection,
            selected,
            deselect,
            filtersSelection,
            handleAnswer,
            isSelected,
            select,
            selectByCurrentFilters,
            selectedCount,
            subscribeToSelectionChanges,
            taxQuestions,
            taxRule,
            totalTransactions,
            updateDetails,
            updateSelected,
        ],
    );

    return (
        <transactionsBulkActionsContext.Provider
            value={bulkActionsContextValue}
        >
            {children}
        </transactionsBulkActionsContext.Provider>
    );
};
