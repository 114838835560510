import { currencyFormatterFinancialStatements } from "./currency";

export const numberFormatterShort = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
});

export const numberFormatter = new Intl.NumberFormat("en-US");

export const numberFormatterNoFractions = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
});

export const numberFormatterCurrency = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const numberFormatterFinancialStatements =
    currencyFormatterFinancialStatements;

export const accountingNumberFormatter = (value: number) => {
    if (!value) {
        return "-";
    }

    const output = currencyFormatterFinancialStatements.format(value, {
        includeFractions: true,
    });

    return value < 0 ? `(${output})` : output;
};
