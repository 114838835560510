import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormRow } from "./FormRow";
import { StateSelector } from "./StateSelector";
import { ObjectShape, string } from "yup";
import { CountrySelector } from "./CountrySelector";
import { useFormikContext } from "formik";
import { isPoBoxAddress } from "../../common/helpers/address";
import { get } from "lodash";
import { InputSize } from "../../helpers/form";

interface Props {
    prefix?: string;
    includeCountry?: boolean;
    wide?: boolean;
    fieldSize?: InputSize;
    readOnly?: boolean;
}

export function getUSAddressSchema(allowPoBox: boolean): ObjectShape {
    let street = string().required().label("Street");

    if (!allowPoBox) {
        street = street.test(
            "isPoBox",
            "PO boxes not allowed",
            (v) => !isPoBoxAddress(v),
        );
    }

    return {
        street,
        city: string().required().label("City"),
        state: string().required("Required").label("State"),
        postalCode: string()
            .required("Required")
            .matches(/^\d{5}$/, "Provide 5 digits")
            .label("ZIP"),
    };
}

export const InternationalAddressSchema: ObjectShape = {
    street: string().required().label("Street"),
    city: string().required().label("City"),
    postalCode: string().required("Required").label("ZIP"),
    country: string().required().label("Country"),
};

export const AddressForm: React.FC<Props> = ({
    prefix = "address",
    includeCountry,
    wide,
    fieldSize,
    readOnly,
}) => {
    const finalPrefix = prefix.length ? `${prefix}.` : "";

    const { values } = useFormikContext();
    const showState =
        !includeCountry ||
        (prefix ? get(values, prefix) : values).country === "US";

    return (
        <>
            {includeCountry ? (
                <Row>
                    <Col>
                        <CountrySelector fieldName={`${finalPrefix}country`} />
                    </Col>
                </Row>
            ) : null}

            {!wide || !showState ? (
                <Row>
                    <Col>
                        <FormRow
                            fieldName={`${finalPrefix}street`}
                            label="Street"
                            size={fieldSize}
                            showErrorMessage={!readOnly}
                        />
                    </Col>
                </Row>
            ) : null}

            {showState ? (
                <>
                    <Row>
                        {wide ? (
                            <Col>
                                <FormRow
                                    fieldName={`${finalPrefix}street`}
                                    label="Street"
                                    size={fieldSize}
                                    showErrorMessage={!readOnly}
                                />
                            </Col>
                        ) : null}

                        <Col>
                            <FormRow
                                fieldName={`${finalPrefix}city`}
                                label="City"
                                size={fieldSize}
                                showErrorMessage={!readOnly}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StateSelector
                                customFieldName={`${finalPrefix}state`}
                                size={fieldSize}
                                showErrorMessage={!readOnly}
                            />
                        </Col>

                        <Col sm={wide ? 6 : 4}>
                            <FormRow
                                fieldName={`${finalPrefix}postalCode`}
                                label="ZIP"
                                size={fieldSize}
                                showErrorMessage={!readOnly}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <Row>
                    <Col>
                        <FormRow
                            fieldName={`${finalPrefix}city`}
                            label="City"
                            size={fieldSize}
                        />
                    </Col>

                    <Col sm={wide ? 6 : 4}>
                        <FormRow
                            fieldName={`${finalPrefix}postalCode`}
                            label="ZIP"
                            size={fieldSize}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};
