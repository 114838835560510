import React, { useCallback } from "react";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
} from "../../../../common/flatRateBilling";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { Button, Modal } from "react-bootstrap";
import { format } from "date-fns";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import { PlanFeature } from "./PlanFeature";
import { intercom } from "../../../../lib/intercom";
import styles from "./DowngradeModal.module.scss";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { useChangePlanMutation } from "../../../../mutations/flatRateBilling";
import {
    BusinessIcon,
    ChatIcon,
    CustomCategoryIcon,
    LedgerIcon,
    SheetIcon,
} from "../../../../icons";
import { DowngradeFeature } from "./lib";
import { useToastMessages } from "../../../general/ToastMessages/useToastMessages";

interface Props {
    downgradingToPlan: FLAT_RATE_PLANS;
    onHide(): void;
}

const loosingFeaturesDowngradingToBasic: DowngradeFeature[] = [
    { label: "Balance sheet", icon: <SheetIcon /> },
    { label: "General ledger", icon: <LedgerIcon /> },
    { label: "Custom categories", icon: <CustomCategoryIcon /> },
    { label: "Priority support", icon: <ChatIcon /> },
] as const;

const loosingFeaturesDowngradingToFree = [
    { label: "Unlimited entities", icon: <BusinessIcon /> },
    ...loosingFeaturesDowngradingToBasic,
];

export const DowngradeModalContent: React.FC<Props> = ({
    downgradingToPlan,
    onHide,
}) => {
    const { toast } = useToastMessages();
    const { currentPlan, subscriptionDetails, isTrialing } = useBillingStatus();

    const onDowngraded = useCallback(() => {
        if (isTrialing) {
            if (downgradingToPlan === FLAT_RATE_PLANS.FREE) {
                toast(
                    `Plan updated. You still have access to ${flatRatePlanNames[currentPlan]} until the end of your trial.`,
                );
            } else {
                toast(`Plan updated.`);
            }
        } else {
            toast(
                `Plan updated. You still have access to ${flatRatePlanNames[currentPlan]} until ${format(subscriptionDetails?.periodEndsAt!, fullDateFullYearFormat)}`,
            );
        }

        onHide();
    }, [
        currentPlan,
        downgradingToPlan,
        subscriptionDetails?.periodEndsAt,
        isTrialing,
        onHide,
        toast,
    ]);
    const downgradeMutation = useChangePlanMutation(
        downgradingToPlan,
        onDowngraded,
    );

    let subheading: React.ReactNode;

    const endDateText = format(
        subscriptionDetails?.periodEndsAt!,
        fullDateFullYearFormat,
    );

    if (!isTrialing || downgradingToPlan === FLAT_RATE_PLANS.FREE) {
        subheading = (
            <p>
                You'll still have access to these features until {endDateText}.
            </p>
        );
    } else if (downgradingToPlan === FLAT_RATE_PLANS.BASIC) {
        subheading = <p>You'll lose access to these features.</p>;
    }

    return (
        <>
            <Modal.Header className="modal-header--empty" closeButton />

            <Modal.Body>
                <header className={styles.header}>
                    <h3>
                        Are you sure you want to downgrade to{" "}
                        <em>{flatRatePlanNames[downgradingToPlan]}</em>?
                    </h3>

                    {subheading}
                </header>

                <main className={styles.body}>
                    <section className={styles.planFeatures}>
                        {(downgradingToPlan === FLAT_RATE_PLANS.FREE
                            ? loosingFeaturesDowngradingToFree
                            : loosingFeaturesDowngradingToBasic
                        ).map((feature) => (
                            <PlanFeature
                                key={feature.label}
                                feature={feature}
                            />
                        ))}
                    </section>

                    <section className={styles.team}>
                        <img
                            src="/images/team.png"
                            alt="Echo, Andrew, Bridget"
                        />{" "}
                        If you have any questions, please{" "}
                        <Button
                            variant="link"
                            size="sm"
                            onClick={() => intercom("show")}
                        >
                            reach out to our team
                        </Button>
                    </section>

                    <footer className={styles.actions}>
                        <Button
                            variant="secondary"
                            onClick={onHide}
                            disabled={downgradeMutation.isLoading}
                        >
                            Back
                        </Button>
                        <ButtonWithLoader
                            variant="primary"
                            mutation={downgradeMutation}
                        >
                            Yes, downgrade to{" "}
                            {flatRatePlanNames[downgradingToPlan]}
                        </ButtonWithLoader>
                    </footer>
                </main>
            </Modal.Body>
        </>
    );
};
