import {
    User,
    UserEventsTriggered,
    UserIncomeType,
    UserType,
} from "../../types/user";
import { UserEntityAssignment } from "../../types/userEntityAssignment";
import { isoStringOrNull, unpackDateOrNull } from "../dto-helpers";
import { SubscriptionStatus } from "../../billing";
import { UserSettingsDto } from "./user-settings.dto";

export class UserDto {
    static deserialize(dto: UserDto): User {
        return {
            ...dto,
            lastActivity: new Date(dto.lastActivity),
            lastCashback: unpackDateOrNull(dto.lastCashback),
            lastCashbackNotification: unpackDateOrNull(
                dto.lastCashbackNotification,
            ),
            dateOfBirth: unpackDateOrNull(dto.dateOfBirth),
            bookkeepingEndDate: unpackDateOrNull(dto.bookkeepingEndDate),
            bookkeepingStartDate: new Date(dto.bookkeepingStartDate),
            settings: UserSettingsDto.deserialize(dto.settings),
            createdAt: new Date(dto.createdAt),
            onboardingCompletedAt: unpackDateOrNull(dto.onboardingCompletedAt),
            subscribedAt: unpackDateOrNull(dto.subscribedAt),
            eventsTriggered: dto.eventsTriggered,
        };
    }

    id: number;
    name: string;
    legalName: string | null;
    preferredName: string | null;
    email: string | null;
    avatar: string | null;
    firstName: string;
    lastName: string;
    phoneNumber: string | null;
    lastCashback: string | null;
    lastCashbackNotification: string | null;
    phoneNumberDisplay: string | null;
    dateOfBirth: string | null;
    incomeType: UserIncomeType[];
    onboardingComplete: boolean;
    showPotentialSavingsMessage: boolean;
    showBookkeepingReviewInvite: boolean;
    bookkeepingReviewScheduled: boolean;
    entityAssignments: UserEntityAssignment[];
    lastActivity: string;
    iconColor: string;
    isSubscribed: boolean;
    savingsCreditLimit: number;
    freeUsageLimit: number;
    bookkeepingEndDate: string | null;
    bookkeepingStartDate: string;
    settings: UserSettingsDto;
    createdAt: string;
    onboardingCompletedAt: string | null;
    subscribedAt: string | null;
    eventsTriggered: UserEventsTriggered | null;
    stripeCustomerId: string | null;
    stripeSubscriptionStatus: SubscriptionStatus | null;
    bookedEnterpriseCall: boolean;
    type: UserType;

    constructor(user: User) {
        this.id = user.id;
        this.name = user.name;
        this.legalName = user.legalName;
        this.preferredName = user.preferredName;
        this.email = user.email;
        this.avatar = user.avatar;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.phoneNumber = user.phoneNumber;
        this.lastCashback = isoStringOrNull(user.lastCashback);
        this.lastCashbackNotification = isoStringOrNull(
            user.lastCashbackNotification,
        );
        this.phoneNumberDisplay = user.phoneNumberDisplay;
        this.dateOfBirth = isoStringOrNull(user.dateOfBirth);
        this.incomeType = user.incomeType;
        this.onboardingComplete = user.onboardingComplete;
        this.showPotentialSavingsMessage = user.showPotentialSavingsMessage;
        this.showBookkeepingReviewInvite = user.showBookkeepingReviewInvite;
        this.bookkeepingReviewScheduled = user.bookkeepingReviewScheduled;
        this.entityAssignments = user.entityAssignments;
        this.lastActivity = user.lastActivity.toISOString();
        this.iconColor = user.iconColor;
        this.isSubscribed = user.isSubscribed;
        this.savingsCreditLimit = user.savingsCreditLimit;
        this.freeUsageLimit = user.freeUsageLimit;
        this.bookkeepingEndDate = isoStringOrNull(user.bookkeepingEndDate);
        this.bookkeepingStartDate = user.bookkeepingStartDate.toISOString();
        this.settings = new UserSettingsDto(user.settings);
        this.createdAt = user.createdAt.toISOString();
        this.onboardingCompletedAt = isoStringOrNull(
            user.onboardingCompletedAt,
        );
        this.subscribedAt = isoStringOrNull(user.subscribedAt);
        this.eventsTriggered = user.eventsTriggered;
        this.stripeCustomerId = user.stripeCustomerId;
        this.stripeSubscriptionStatus = user.stripeSubscriptionStatus;
        this.bookedEnterpriseCall = user.bookedEnterpriseCall;
        this.type = user.type;
    }
}
