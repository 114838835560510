import { Transaction } from "../../types/transaction";
import { TransactionDto } from "./transaction.dto";

export enum PossibleSavingType {
    SOFTWARE_SUBS = "SOFTWARE_SUBS",
    MEALS = "MEALS",
}

export interface PossibleSaving {
    type: PossibleSavingType;
    transaction: Transaction;
}

export interface PossibleSavingDto {
    type: PossibleSavingType;
    transaction: TransactionDto;
}

export interface GetPossibleSavingsResponse {
    possibleSavings: PossibleSaving[];
}

export class GetPossibleSavingsResponseDto {
    possibleSavings: PossibleSavingDto[];

    static deserialize(
        dto: GetPossibleSavingsResponseDto,
    ): GetPossibleSavingsResponse {
        return {
            possibleSavings: dto.possibleSavings.map((ps) => ({
                type: ps.type,
                transaction: TransactionDto.deserialize(ps.transaction),
            })),
        };
    }

    constructor(possibleSavings: PossibleSaving[]) {
        this.possibleSavings = possibleSavings.map((ps) => ({
            type: ps.type,
            transaction: new TransactionDto(ps.transaction),
        }));
    }
}
