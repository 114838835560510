import { useCallback, useEffect } from "react";

const PREFIX = "kick_event_";

export interface UseWindowMessageOptions<T> {
    scope: string;
    handler: (message: T) => void;
}

function getEventKey(name: string) {
    return PREFIX + name;
}

export function sendWindowMessage(scope: string, message: any) {
    localStorage.setItem(getEventKey(scope), JSON.stringify(message));
    localStorage.removeItem(getEventKey(scope));
}

export function useWindowMessage<T = any>({
    scope,
    handler,
}: UseWindowMessageOptions<T>): void {
    const receiveMessage = useCallback(
        (event: StorageEvent) => {
            if (event.key === getEventKey(scope) && event.newValue) {
                handler(JSON.parse(event.newValue));
            }
        },
        [scope, handler],
    );

    useEffect(() => {
        window.addEventListener("storage", receiveMessage, false);

        return () => window.removeEventListener("storage", receiveMessage);
    }, [receiveMessage]);
}
