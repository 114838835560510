import React, { useCallback } from "react";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { Modal } from "react-bootstrap";
import { useCategorizationRuleCreationMutation } from "../../../mutations/categorizationRules";
import { ModalComponentProps } from "../../../helpers/modal";
import {
    CategorizationRuleForm,
    CategorizationRuleSubmitData,
} from "./CategorizationRuleForm";

export const AddRuleModal: React.FC<ModalComponentProps> = ({ close }) => {
    const { activeWorkspace } = useWorkspaceContext();
    const creation = useCategorizationRuleCreationMutation(close);

    const onCreate = useCallback(
        async (data: CategorizationRuleSubmitData) => {
            if (!activeWorkspace) {
                return;
            }

            await creation.mutateAsync({
                ...data,
                workspaceId: activeWorkspace.id,
            });
        },
        [activeWorkspace, creation],
    );

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Add Rule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CategorizationRuleForm
                    onSubmit={onCreate}
                    isSubmitting={creation.isLoading}
                    submitText="Add Rule"
                />
            </Modal.Body>
        </>
    );
};
