import { backendClient, unwrapResponse } from "./backendClient";
import { GetPricingAndUsageResponseDto } from "../common/dto/billing/get-pricing-and-usage-response.dto";
import { PrepareSubscriptionResponseDto } from "../common/dto/billing/prepare-subscription-response.dto";
import { PrepareSubscriptionDto } from "../common/dto/billing/prepare-subscription.dto";
import { FinalizeSubscriptionDto } from "../common/dto/billing/finalize-subscription.dto";
import { UserDto } from "../common/dto/user/user.dto";
import { BillingHistory, BillingStatus } from "../common/types/billing";
import { BillingStatusDto } from "../common/dto/billing/billing-status.dto";
import { PricingAndUsage } from "../common/billing";
import { BillingInfoDto } from "../common/dto/billing/billing-info.dto";
import { BillingHistoryDto } from "../common/dto/billing/billing-history.dto";

export async function getBillingStatus(
    workspaceId: string,
): Promise<BillingStatus> {
    return BillingStatusDto.deserialize(
        unwrapResponse(
            await backendClient.get(`/billing/billing-status/${workspaceId}`),
        ),
    );
}

export async function getBillingInfo(workspaceId: string) {
    return unwrapResponse(
        await backendClient.get(`/billing/billing-info/${workspaceId}`),
    ) as BillingInfoDto | null;
}

export async function getBillingHistory(
    workspaceId: string,
): Promise<BillingHistory> {
    return BillingHistoryDto.deserialize(
        unwrapResponse(
            await backendClient.get(`/billing/billing-history/${workspaceId}`),
        ),
    );
}

export async function getPricingAndUsage(
    includePreviousYear?: boolean,
    discountCode?: string,
): Promise<PricingAndUsage> {
    return GetPricingAndUsageResponseDto.deserialize(
        unwrapResponse(
            await backendClient.get("/billing/pricing", {
                params: {
                    includePreviousYear,
                    discountCode,
                },
            }),
        ),
    );
}

export async function prepareSubscription(
    includePreviousYear: boolean,
    code?: string,
) {
    return PrepareSubscriptionResponseDto.deserialize(
        unwrapResponse(
            await backendClient.post("/billing/prepare-subscription", {
                includePreviousYear,
                code,
            } as PrepareSubscriptionDto),
        ),
    );
}

export async function subscribe(data: FinalizeSubscriptionDto) {
    return UserDto.deserialize(
        unwrapResponse(await backendClient.post("/billing/subscribe", data)),
    );
}
