import React from "react";
import { FormRow } from "./FormRow";
import { Field } from "formik";
import { COUNTRIES } from "../../common/countries";
import { getInputClasses, InputSize } from "../../helpers/form";

interface Props {
    fieldName?: string;
    label?: string;
    size?: InputSize;
}

export const CountrySelector: React.FC<Props> = ({
    fieldName = "country",
    label = "Country",
    size,
}) => {
    const classes = getInputClasses(size);

    return (
        <FormRow fieldName={fieldName} label={label}>
            <Field name={fieldName} as="select" className={classes}>
                <option value="">Select country...</option>
                {COUNTRIES.map((c) => (
                    <option key={c.code} value={c.code}>
                        {c.name}
                    </option>
                ))}
            </Field>
        </FormRow>
    );
};
