import { MoneyDirection } from "../../../common/constants";
import { TransactionsEntityAccountsFilter } from "../../../common/types/filters/transactions";
import { GenericCategory } from "../../../common/types/genericCategory";
import { TransactionClassification } from "../../../common/types/transaction";

export interface TransactionsFilters {
    description?: string;
    start?: Date;
    end?: Date;
    minAmount?: number;
    maxAmount?: number;
    direction?: MoneyDirection;
    entitiesAccounts?: TransactionsEntityAccountsFilter[];
    category?: GenericCategory[];
    requiredAction?: boolean;
    classification?: TransactionClassification;
    hasTransferMatch?: boolean;
}

export type FiltersChangeFn = (payload: Partial<TransactionsFilters>) => void;

export type FilterProps = {
    filters: TransactionsFilters;
    onChange: FiltersChangeFn;
};

export const DEFAULT_ACTIVITY_FEED_FILTERS: TransactionsFilters = {
    requiredAction: false,
};

export enum AggregatedFiltersTabs {
    CATEGORY = "category",
    AMOUNT = "amount",
    DATE = "date",
}

export const DEFAULT_TAB = AggregatedFiltersTabs.DATE;
