import {
    TransactionAuditLog,
    TransactionAuditLogAuthorMetadata,
    TransactionAuditLogAuthorType,
} from "../../../../common/types/transactionAuditLog";
import { AUDIT_LOG_SYSTEM_COMPONENT } from "../../../../common/constants";
import { useUser } from "../../../../hooks/useUser";

export function useAuditLogAuthorLabel(log: TransactionAuditLog): string {
    const user = useUser();

    switch (log.authorType) {
        case TransactionAuditLogAuthorType.USER:
            return log.userId === user.id
                ? "You"
                : (log.metadata as TransactionAuditLogAuthorMetadata).author;
        case TransactionAuditLogAuthorType.SYSTEM:
            return (log.metadata as TransactionAuditLogAuthorMetadata)
                .author === AUDIT_LOG_SYSTEM_COMPONENT.CATEGORIZATION_API
                ? "Kick AI"
                : "Kick";
        case TransactionAuditLogAuthorType.OPS:
            return "Kick";
        default:
            return "Unknown";
    }
}
