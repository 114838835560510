import { TransactionsFilters } from "./lib";
import { useEntities } from "../../../hooks/useEntities";

export function useHasActiveAccountsFilter(filters: TransactionsFilters) {
    const entities = useEntities({ excludeEmptyMockEntities: false });

    if (!filters.entitiesAccounts || filters.entitiesAccounts.length === 0) {
        return false;
    }

    if (filters.entitiesAccounts.length !== entities.length) {
        return true;
    }

    return filters.entitiesAccounts.some((v) => {
        return v.excludedAccounts && v.excludedAccounts.length > 0;
    });
}
