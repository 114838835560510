import React from "react";
import classNames from "classnames";
import styles from "./Registration.module.scss";
import { useTypedFlags } from "../../../../hooks/useTypedFlags";
import { HorizontalLine } from "../../../general/HorizontalLine/HorizontalLine";
import { LoginWithGoogleButton } from "../../../auth/GoogleAuth/LoginWithGoogleButton";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "react-query";
import { isInviteCodeValid } from "../../../../lib/user";
import { PageCoverLoader } from "../../../general/PageCoverLoader/PageCoverLoader";
import { RegisterWithEmail } from "./RegisterWithEmail";
import { OnboardingLayout } from "../../OnboardingLayout";

export const Registration: React.FC = () => {
    const { googleLogin } = useTypedFlags();
    const route = useRouteMatch<{ inviteCode: string }>();

    const { data, isFetching } = useQuery(
        ["inviteCode", route.params.inviteCode],
        () => isInviteCodeValid(route.params.inviteCode),
        {
            refetchOnWindowFocus: false,
        },
    );

    if (isFetching || !data) {
        return <PageCoverLoader />;
    }

    return (
        <OnboardingLayout>
            <div className={styles.contentContainer}>
                <header className={classNames(styles.header, styles.section)}>
                    <h1 className={styles.title}>Welcome to Kick</h1>
                    <p>Put your books on autopilot</p>
                </header>

                <section className={styles.section}>
                    {googleLogin && (
                        <>
                            <LoginWithGoogleButton variant="secondary" block>
                                Sign up with Google
                            </LoginWithGoogleButton>

                            <HorizontalLine className={styles.spacer}>
                                or
                            </HorizontalLine>
                        </>
                    )}

                    <RegisterWithEmail inviteCode={route.params.inviteCode} />
                </section>

                <footer className={styles.consent}>
                    By continuing you agree to the{" "}
                    <a
                        href="https://kick.co/legal#terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms
                    </a>{" "}
                    &amp;{" "}
                    <a
                        href="https://kick.co/legal#privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                    .
                </footer>
            </div>
        </OnboardingLayout>
    );
};
