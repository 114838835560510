import { parseISO } from "date-fns";
import { Payroll } from "../../types/payroll";
import { isoStringOrNull, unpackDateOrNull } from "../dto-helpers";
import { PayrollTotalsDto } from "./payroll-totals.dto";

export class PayrollDto {
    static deserialize(dto: PayrollDto): Payroll {
        return {
            ...dto,
            processedDate: unpackDateOrNull(dto.processedDate),
            calculatedAt: unpackDateOrNull(dto.calculatedAt),
            payPeriodStartDate: parseISO(dto.payPeriodStartDate),
            payPeriodEndDate: parseISO(dto.payPeriodEndDate),
            checkDate: unpackDateOrNull(dto.checkDate),
            createdAt: unpackDateOrNull(dto.createdAt),
            totals: dto.totals
                ? PayrollTotalsDto.deserialize(dto.totals)
                : null,
        };
    }

    id: number;
    payrollUuid: string;
    companyUuid: string;
    entityId: number;
    offCycle: boolean;
    processed: boolean;
    processedDate: string | null;
    calculatedAt: string | null;
    payPeriodStartDate: string;
    payPeriodEndDate: string;
    payScheduleUuid?: string | null;
    checkDate: string | null;
    external: boolean;
    totals?: PayrollTotalsDto | null;
    createdAt: string | null;

    constructor(payroll: Payroll) {
        this.id = payroll.id;
        this.payrollUuid = payroll.payrollUuid;
        this.companyUuid = payroll.companyUuid;
        this.entityId = payroll.entityId;
        this.offCycle = payroll.offCycle;
        this.processed = payroll.processed;
        this.processedDate = isoStringOrNull(payroll.processedDate);
        this.calculatedAt = isoStringOrNull(payroll.calculatedAt);
        this.payPeriodStartDate = payroll.payPeriodStartDate.toISOString();
        this.payPeriodEndDate = payroll.payPeriodEndDate.toISOString();
        this.payScheduleUuid = payroll.payScheduleUuid;
        this.checkDate = isoStringOrNull(payroll.checkDate);
        this.external = payroll.external;
        this.totals = payroll.totals
            ? new PayrollTotalsDto(payroll.totals)
            : null;
        this.createdAt = isoStringOrNull(payroll.createdAt);
    }
}
