import { User } from "../../types/user";
import { UserDto } from "../user/user.dto";

export interface UserStatusResponse {
    user: User;
    isDemoUser: boolean;
    intercomHash?: string;
}

export class UserStatusResponseDto {
    static deserialize(status: UserStatusResponseDto): UserStatusResponse {
        return {
            ...status,
            user: UserDto.deserialize(status.user),
        };
    }

    user: UserDto;
    isDemoUser: boolean;
    intercomHash?: string;

    constructor(status: UserStatusResponse) {
        this.user = new UserDto(status.user);
        this.isDemoUser = status.isDemoUser;
        this.intercomHash = status.intercomHash;
    }
}
