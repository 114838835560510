import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { submitHelper } from "../../helpers/form";
import { simulateIncomingSms } from "../../lib/simulations";
import { FormRow } from "../forms/FormRow";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";

interface FormSchema {
    From: string;
    Body: string;
    MediaUrl0: string;
    MediaUrl1: string;
}

export const SimulateIncomingSms: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            From: "",
            Body: "",
            MediaUrl0: "",
            MediaUrl1: "",
        },
        validationSchema: object().shape({
            From: string().required().matches(/^\d+$/).label("Number"),
            Body: string(),
            MediaUrl0: string().url(),
            MediaUrl1: string().url(),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (body, { resetForm }) => {
                const numberOfAttachments: number = [
                    body.MediaUrl0,
                    body.MediaUrl1,
                ].filter(Boolean).length;

                await simulateIncomingSms({
                    ...body,
                    NumMedia: numberOfAttachments,
                });

                resetForm();
            },
        }),
    };

    return (
        <section>
            <h3>Simulate Incoming SMS</h3>

            <Formik {...form}>
                <Form>
                    <FormRow fieldName="From" label="From" />
                    <FormRow fieldName="Body" label="Body" />
                    <FormRow fieldName="MediaUrl0" label="Attachment URL" />
                    <FormRow fieldName="MediaUrl1" label="Attachment URL" />
                    <ButtonWithLoader loading={loading} type="submit">
                        Submit
                    </ButtonWithLoader>
                </Form>
            </Formik>
        </section>
    );
};
