import React from "react";
import { ConnectMoreModalProps } from "./types";
import { Button, Modal } from "react-bootstrap";
import { ConnectPlaid } from "../../plaid/ConnectPlaid/ConnectPlaid";
import { StandardModal } from "../../general/Modal/Modal";

export const ConnectCreditCardsModal: React.FC<ConnectMoreModalProps> = ({
    onContinue,
    onConnected,
    onHide,
    show,
}) => {
    return (
        <StandardModal show={show} size="lg" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you don't have any credit cards?
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Adding all your personal and business credit cards will help
                    Kick automate your bookkeeping
                </p>

                <footer className="d-flex justify-content-between mt-5">
                    <ConnectPlaid
                        btnText="Add credit card"
                        btnVariant="primary"
                        btnSize="lg"
                        onConnected={onConnected}
                    />

                    <Button
                        variant="secondary"
                        onClick={onContinue}
                        className="ml-2"
                        size="lg"
                    >
                        Continue without credit card
                    </Button>
                </footer>
            </Modal.Body>
        </StandardModal>
    );
};
