import { useMutation } from "react-query";
import { removeIntegrationAccount } from "../lib/integrationAccount";
import { IntegrationAccount } from "../common/types/integrationAccount";

export function useIntegrationAccountRemovalMutation(
    account: IntegrationAccount,
    onRemove?: () => void,
) {
    return useMutation(() => removeIntegrationAccount(account), {
        onSuccess: () => {
            onRemove?.();
        },
    });
}
