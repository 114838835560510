import React from "react";
import classNames from "classnames";

export interface SettingsSectionProps {
    heading?: string | React.ReactNode;
    subheading?: string | React.ReactNode;
    className?: string;
}

export const SettingsSection: React.FC<SettingsSectionProps> = ({
    heading,
    subheading,
    className,
    children,
}) => {
    return (
        <section className={classNames("settings__section", className)}>
            {heading && (
                <header className="settings__section__header">
                    {typeof heading === "string" ? <h4>{heading}</h4> : heading}
                </header>
            )}
            {subheading && (
                <header className="settings__section__subheader">
                    {subheading}
                </header>
            )}
            <main>{children}</main>
        </section>
    );
};
