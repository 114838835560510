import React, { useCallback, useEffect, useMemo, useRef } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { BalanceHistory } from "../../common/dto/balance/balance-chart-response.dto";
import "./BalanceChart.scss";
import { addDays, format, toDate } from "date-fns";
import { currencyFormatter } from "../../common/helpers/currency";
import {
    DashboardChartProps,
    getDashboardChartBasis,
} from "../../lib/dashboardChart";
import { styleDefaultChartTooltip } from "../../lib/charts";
import { dropRightWhile, dropWhile, isNull } from "lodash";

interface Props extends DashboardChartProps {
    balanceHistory: BalanceHistory[];
}

interface ChartDataItem {
    date: Date;
    balance: number | null;
    difference: number | null;
}

interface CreateBulletParams {
    series: am4charts.LineSeries;
    color: am4core.Color;
    size: number;
    borderWidth: number;
}

function itemIsEmpty(item: ChartDataItem) {
    return isNull(item.balance) || !isFinite(item.balance);
}

const CHART_LINE_COLOR = "#4EA2E0";
const CHART_LINE_WIDTH = 1.5;

export const BalanceChart: React.FC<Props> = ({
    balanceHistory,
    startDate,
    endDate,
}) => {
    const chartRef = useRef<am4charts.XYChart | undefined>(undefined);

    const data = useMemo<ChartDataItem[]>(() => {
        const dataItems: ChartDataItem[] = [];

        const balanceByDay = balanceHistory.reduce(
            ($, b) => Object.assign($, { [b.date.getTime()]: b }),
            {} as Record<number, ChartDataItem>,
        );

        for (
            let currentDate = toDate(startDate);
            currentDate.getTime() <= endDate.getTime();
            currentDate = addDays(currentDate, 1)
        ) {
            dataItems.push({
                date: currentDate,
                balance: balanceByDay[currentDate.getTime()]?.balance,
                difference: balanceByDay[currentDate.getTime()]?.difference,
            });
        }

        return dropRightWhile(dropWhile(dataItems, itemIsEmpty), itemIsEmpty);
    }, [balanceHistory, startDate, endDate]);

    const createBullet = useCallback(
        ({ series, color, size, borderWidth }: CreateBulletParams) => {
            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.fill = color;
            bullet.circle.radius = size / 2;
            bullet.circle.stroke = am4core.color(CHART_LINE_COLOR);
            bullet.circle.strokeWidth = borderWidth;
            bullet.fillOpacity = 0;
            bullet.strokeOpacity = 0;

            const bulletState = bullet.states.create("hover");
            bulletState.properties.fillOpacity = 1;
            bulletState.properties.strokeOpacity = 1;
        },
        [],
    );

    useEffect(() => {
        const { chart } = getDashboardChartBasis("dashboardchart");

        const balanceSeries = new am4charts.LineSeries();
        balanceSeries.dataFields.valueY = "balance";
        balanceSeries.dataFields.dateX = "date";
        balanceSeries.stroke = am4core.color(CHART_LINE_COLOR);
        balanceSeries.strokeWidth = CHART_LINE_WIDTH;

        balanceSeries.fill = am4core.color(CHART_LINE_COLOR);
        balanceSeries.fillOpacity = 1;

        const fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [0.3, 0];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        balanceSeries.segments.template.fillModifier = fillModifier;

        balanceSeries.tensionX = 0.9;

        balanceSeries.adapter.add(
            "tooltipHTML",
            (_, target: am4charts.LineSeries) => {
                const dataItem = target.tooltipDataItem.dataContext as
                    | ChartDataItem
                    | undefined;

                if (!dataItem?.date) {
                    return;
                }

                return `<div class="dashboard-balance-chart-tooltip">
                        <header>
                            ${currencyFormatter.format(dataItem.balance ?? 0)} 
                            <small> (${
                                dataItem.difference && dataItem.difference > 0
                                    ? "+"
                                    : ""
                            }${currencyFormatter.format(
                                dataItem.difference ?? 0,
                            )})</small>
                        </header>
                        <span>${format(dataItem.date, "MMM d, yyyy")}</span>
                    </div>`;
            },
        );

        styleDefaultChartTooltip(balanceSeries);

        createBullet({
            series: balanceSeries,
            color: am4core.color("#fff"),
            size: 12,
            borderWidth: CHART_LINE_WIDTH,
        });

        chart.series.push(balanceSeries);

        chart.cursor.lineX.strokeDasharray = "";
        chart.cursor.lineX.strokeWidth = CHART_LINE_WIDTH;
        chart.cursor.lineX.stroke = am4core.color(CHART_LINE_COLOR);
        chart.cursor.lineX.strokeOpacity = 1;

        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    }, [createBullet]);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.data = data;
        }
    }, [data]);

    return (
        <div className="dashboard-chart">
            <div
                id="dashboardchart"
                style={{ width: "100%", height: "250px" }}
            />
        </div>
    );
};
