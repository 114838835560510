import React, { useEffect, useMemo } from "react";
import { TransactionsFilters } from "../../../transactions/filters/lib";
import { MatchingViewFilters } from "./MatchingViewFilters";
import styles from "./MatchingView.module.scss";
import { Sort } from "../../../transactions/useSort";
import { SortOrder } from "../../../../common/constants";
import { useInfiniteQuery } from "react-query";
import { transactionMatchingQueryKeys } from "../../lib";
import { convertFiltersToDto } from "../../../transactions/useTransactionsQuery";
import { getTransactions } from "../../../../lib/transactions";
import { useInView } from "react-intersection-observer";
import { MatchingViewTransaction } from "./MatchingViewTransaction";
import { NoMatchingTransactions } from "../../../transactions/NoMatchingTransactions";
import { Loader } from "../../../general/Loader";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import { useCreateLinkFinancialDocumentToFinancialTransactionMutation } from "../../../../mutations/financialDocument";
import { useFinancialDocumentDetailsView } from "../useFinancialDocumentDetailsView";
import { useEntities } from "../../../../hooks/useEntities";

export interface Props {
    financialDocument: FinancialDocument;
    filters: TransactionsFilters;
    onFiltersChange: (filters: TransactionsFilters) => void;
    clearFilters: () => void;
}

const FIXED_SORT: Sort = {
    field: "date",
    order: SortOrder.DESC,
};

export const MatchingView: React.FC<Props> = ({
    financialDocument,
    filters,
    onFiltersChange,
    clearFilters,
}) => {
    const { go: goBackToDefaultView } = useFinancialDocumentDetailsView();
    const match = useCreateLinkFinancialDocumentToFinancialTransactionMutation(
        financialDocument,
        goBackToDefaultView,
    );
    const entities = useEntities();

    const filtersDto = useMemo(
        () => convertFiltersToDto(filters, entities),
        [filters, entities],
    );
    const { data, fetchNextPage, hasNextPage, remove } = useInfiniteQuery({
        queryKey: transactionMatchingQueryKeys.list(filtersDto),
        queryFn: ({ pageParam = 0 }) => {
            return getTransactions({
                filters: filtersDto,
                sort: FIXED_SORT,
                limit: 20,
                page: pageParam,
            });
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.pageCount > allPages.length) {
                return allPages.length;
            }
        },
    });

    const { inView, ref } = useInView({
        initialInView: true,
    });

    useEffect(() => {
        remove(); // remove previously fetched data when entering matching
    }, [remove]);

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, inView]);

    let content;

    if (!data) {
        content = (
            <tr>
                <td colSpan={99}>
                    <Loader />
                </td>
            </tr>
        );
    } else if (!data.pages[0].total) {
        content = (
            <tr>
                <td colSpan={99}>
                    <NoMatchingTransactions onResetFilters={clearFilters} />
                </td>
            </tr>
        );
    } else {
        content = data.pages
            .flatMap((page) => [...page.data])
            .map((transaction) => (
                <MatchingViewTransaction
                    financialDocument={financialDocument}
                    transaction={transaction}
                    key={transaction.id}
                    onMatch={() => match.mutateAsync(transaction.id)}
                />
            ));
    }

    return (
        <main className={styles.container}>
            <MatchingViewFilters
                onFiltersChange={onFiltersChange}
                filters={filters}
            />

            <section className={styles.transactions}>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th className="text-right">Account</th>
                            <th className="text-right">Amount</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>{content}</tbody>

                    <footer
                        className={styles.observerFooter}
                        ref={ref}
                    ></footer>
                </table>
            </section>
        </main>
    );
};
