import React from "react";
import { PlaidConnection } from "../../common/types/plaidConnection";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { timeAgo } from "../../helpers/date";
import { FixPlaidConnection } from "./FixPlaidConnection";
import { AlertIcon } from "../../icons";
import { useConnectionOwnership } from "../../hooks/useConnectionOwnership";
import { useLatestTransactionsSyncTime } from "../../hooks/useLatestSyncTime";
import { IntegrationAccount } from "../../common/types/integrationAccount";

interface Props {
    connection: PlaidConnection;
    churnedAccount?: IntegrationAccount;
}

export const BrokenPlaidConnectionLink: React.FC<Props> = ({
    connection,
    churnedAccount,
}) => {
    const isOwner = useConnectionOwnership(connection);
    const connectionAccounts = connection.accounts ?? [];
    const lastSynced = useLatestTransactionsSyncTime(
        churnedAccount ? [churnedAccount] : connectionAccounts,
    );

    return (
        <OverlayTrigger
            overlay={
                <Tooltip id={`fix-connection-${connection.id}`}>
                    <div className="mb-2">
                        <p className="mb-0">This account is disconnected.</p>
                        {lastSynced && (
                            <p className="mb-0">
                                Last sync {timeAgo(lastSynced)}.
                            </p>
                        )}
                        {!isOwner && (
                            <p className="mb-0">
                                Connected by {connection.user?.name}.
                            </p>
                        )}
                    </div>
                    Click to fix it.
                </Tooltip>
            }
        >
            <FixPlaidConnection
                connection={connection}
                accountChurned={Boolean(churnedAccount)}
                variant="link"
                className="broken-connection-link danger"
                size="sm"
            >
                <AlertIcon className="icon-color-red-500 mr-2" />
                <span className="broken-connection-link__content">
                    Fix connection
                </span>
            </FixPlaidConnection>
        </OverlayTrigger>
    );
};
