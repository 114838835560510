import classNames from "classnames";
import React, { MouseEventHandler, useCallback } from "react";
import { Button } from "react-bootstrap";
import {
    CashFlowColumn,
    CashFlowTableRow,
} from "../../../common/dto/reports/cash-flow-tab-report-response.dto";
import {
    CASH_FLOW_PERCENT_DIFF_SUFFIX,
    CASH_FLOW_VALUE_DIFF_SUFFIX,
} from "../../../common/dto/reports/reports-common.dto";
import { noop } from "../../../helpers/general";
import "./CashFlowReportCell.scss";

interface RenderCellProps {
    column: CashFlowColumn;
    row: CashFlowTableRow;
    isExpanded?: boolean;
    isTitle?: boolean;
    setIsExpanded?: (value: boolean) => void;
    toggleRow?: (id: string) => void;
    rowVisibility?: Record<string, boolean>;
    stickyRight?: number;
}

export const CashFlowReportCell: React.FC<RenderCellProps> = ({
    row,
    column,
    isTitle = false,
    isExpanded = false,
    setIsExpanded,
    toggleRow = noop,
    rowVisibility = {},
    stickyRight,
}) => {
    const expandToggle = useCallback<
        MouseEventHandler<HTMLTableCellElement>
    >(() => {
        setIsExpanded?.(!isExpanded);
    }, [isExpanded, setIsExpanded]);

    const canExpand = row.children?.length && column.isTitle;

    const classes = classNames("data-table__cell", {
        "data-table__cell--title": isTitle,
    });

    const handlePercentClick = useCallback<
        MouseEventHandler<HTMLButtonElement>
    >(
        (event) => {
            event.stopPropagation();
            toggleRow(`${row.id}${CASH_FLOW_PERCENT_DIFF_SUFFIX}`);
        },
        [row.id, toggleRow],
    );

    const handleDiffClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.stopPropagation();
            toggleRow(`${row.id}${CASH_FLOW_VALUE_DIFF_SUFFIX}`);
        },
        [row.id, toggleRow],
    );

    if (isTitle) {
        return (
            <td
                className={classNames(classes, "cash-flow-cell-title")}
                onClick={canExpand ? expandToggle : noop}
            >
                <span
                    className={classNames({
                        "data-table__cell--expandable": canExpand,
                        "data-table__cell--expanded": isExpanded,
                    })}
                >
                    {row.data?.[column.id] || <>&nbsp;</>}
                </span>

                <span className="cash-flow-cell-addon">
                    <Button
                        variant="plain"
                        className="cash-flow-cell-button"
                        onClick={handlePercentClick}
                        active={
                            rowVisibility[
                                `${row.id}${CASH_FLOW_PERCENT_DIFF_SUFFIX}`
                            ]
                        }
                        hidden={
                            !(
                                `${row.id}${CASH_FLOW_PERCENT_DIFF_SUFFIX}` in
                                rowVisibility
                            )
                        }
                    >
                        <span className="fas fa-percent" />
                    </Button>
                    <Button
                        variant="plain"
                        className="cash-flow-cell-button"
                        onClick={handleDiffClick}
                        active={
                            rowVisibility[
                                `${row.id}${CASH_FLOW_VALUE_DIFF_SUFFIX}`
                            ]
                        }
                        hidden={
                            !(
                                `${row.id}${CASH_FLOW_VALUE_DIFF_SUFFIX}` in
                                rowVisibility
                            )
                        }
                    >
                        <span className="fas fa-dollar-sign" />
                    </Button>
                </span>
            </td>
        );
    }

    const cellValue = row.data?.[column.id];
    const sentiment = row.options?.[column.id]?.sentiment;
    return (
        <td
            className={classNames(classes, {
                "cash-flow-cell-positive": sentiment === "positive",
                "cash-flow-cell-negative": sentiment === "negative",
            })}
            style={{ ...(stickyRight && { right: stickyRight }) }}
        >
            {(!isExpanded && cellValue) || <>&nbsp;</>}
        </td>
    );
};
