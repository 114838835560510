import React from "react";
import { useFinancialDocumentUpload } from "./useFinancialDocumentUpload";
import { FinancialDocumentUploadContextValue } from "./financialDocumentUploadContext";

interface Props {
    render: (
        open: FinancialDocumentUploadContextValue["open"],
    ) => React.ReactNode;
}

export const FinancialDocumentsUpload: React.FC<Props> = ({ render }) => {
    const { open, inputProps } = useFinancialDocumentUpload();

    return (
        <>
            {render(open)}
            <input
                {...inputProps}
                className="d-none"
                data-testid="financial-documents-upload-input"
                /* bug with react-dropzone, it doesn't set ref correctly in some cases, also they have wrong types */
                ref={(el) => ((inputProps as any).ref.current = el)}
            />
        </>
    );
};
