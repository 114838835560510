import { ReactNode } from "react";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../types";
import {
    clearAuthorization,
    initializeApp,
    InitializeAppPayload,
} from "./_commonActions";
import {
    PlaidConnection,
    PlaidConnectionWithAccounts,
} from "../common/types/plaidConnection";
import { User } from "../common/types/user";
import { DuplicatePlaidAccount } from "../common/dto/plaid/plaid-common";
import { PricingTier } from "../common/billing";
import {
    FinancialConnection,
    FinancialConnectionWithAccounts,
} from "../common/types/financialConnection";
import { queryClient } from "../queryClient";
import { entitiesAccountsQueryKey } from "../queries/entitiesAccounts";
import { FinancialAccount } from "../common/types/financialAccount";

export interface Notification {
    id: number;
    message: ReactNode;
    additionalMessage?: ReactNode;
    type: "danger" | "success";
    confetti?: boolean;
}

interface TConnectAccountState {
    connection: PlaidConnection | FinancialConnection | null;
    isConnecting: boolean;
    duplicates: DuplicatePlaidAccount[] | null;
}

interface NotConnectingAccount extends TConnectAccountState {
    connection: null;
    isConnecting: false;
    duplicates: null;
}

interface SavingConnection extends TConnectAccountState {
    connection: null;
    isConnecting: true;
    duplicates: null;
}

interface ConnectionSaved extends TConnectAccountState {
    connection: PlaidConnectionWithAccounts | FinancialConnectionWithAccounts;
    isConnecting: false;
    duplicates: DuplicatePlaidAccount[];
}

export type ConnectAccountState =
    | NotConnectingAccount
    | SavingConnection
    | ConnectionSaved;

export interface AppState {
    initialized: boolean;
    user?: User;
    isDemoUser: boolean;
    notifications: Notification[];
    connectAccount: ConnectAccountState;
    newVersionAvailable: boolean;
    tiers: PricingTier[];
}

const initialState: AppState = {
    initialized: false,
    isDemoUser: false,
    notifications: [],
    connectAccount: {
        connection: null,
        isConnecting: false,
        duplicates: null,
    },
    newVersionAvailable: false,
    tiers: [],
};

export const updateUser = createAction<User>("UPDATE_USER");

export const updateConnectAccountState = createAction<ConnectAccountState>(
    "UPDATE_CONNECT_ACCOUNT_STATE",
);
export const updateFinancialAccount = createAction<FinancialAccount>(
    "UPDATE_FINANCIAL_ACCOUNT",
);

export const hideNotification = createAction<number>("HIDE_NOTIFICATION");
const showNotification = createAction<Notification>("SHOW_NOTIFICATION");

export const notifyNewVersionAvailable = createAction<void>(
    "NEW_VERSION_AVAILABLE",
);

export const ACTIVE_USER_ID_KEY = "active-user-id";

export const addNotification =
    (
        payload: Pick<
            Notification,
            "message" | "additionalMessage" | "type" | "confetti"
        >,
    ) =>
    (dispatch: Function) => {
        const notification = { id: Date.now(), ...payload };

        dispatch(showNotification(notification));

        setTimeout(() => dispatch(hideNotification(notification.id)), 5000);
    };

export const appState = createReducer<AppState>(initialState, {
    [initializeApp.toString()]: (
        state,
        { payload }: ActionWithPayload<InitializeAppPayload>,
    ) => {
        state.initialized = true;
        state.user = payload.user ?? undefined;
        state.isDemoUser = payload.isDemoUser;
        payload.user
            ? localStorage.setItem(
                  ACTIVE_USER_ID_KEY,
                  payload.user.id.toString(),
              )
            : localStorage.removeItem(ACTIVE_USER_ID_KEY);
    },
    [updateUser.toString()]: (state, { payload }: ActionWithPayload<User>) => {
        const subUpdated = payload.isSubscribed !== state.user?.isSubscribed;
        state.user = payload;
        if (subUpdated) {
            queryClient.invalidateQueries(entitiesAccountsQueryKey);
        }
        localStorage.setItem(ACTIVE_USER_ID_KEY, payload.id.toString());
    },
    [updateFinancialAccount.toString()]: (
        state,
        { payload }: ActionWithPayload<FinancialAccount>,
    ) => {
        if (state.connectAccount.connection) {
            state.connectAccount.connection.accounts =
                state.connectAccount.connection.accounts?.map((ia) => ({
                    ...ia,
                    financialAccount:
                        ia.financialAccountId === payload.id
                            ? payload
                            : ia.financialAccount,
                }));
        }
    },
    [clearAuthorization.toString()]: (state) => {
        state.user = undefined;
        localStorage.removeItem(ACTIVE_USER_ID_KEY);
    },
    [showNotification.toString()]: (state, { payload }) => {
        state.notifications.push(payload);
    },
    [hideNotification.toString()]: (state, { payload }) => {
        state.notifications = state.notifications.filter(
            (n) => n.id !== payload,
        );
    },
    [updateConnectAccountState.toString()]: (
        state,
        { payload }: ActionWithPayload<ConnectAccountState>,
    ) => {
        state.connectAccount = payload;
    },
    [notifyNewVersionAvailable.toString()]: (state) => {
        state.newVersionAvailable = true;
    },
});
