import { Category } from "../common/types/category";
import { Taxonomy } from "../common/categories";
import { createReducer } from "@reduxjs/toolkit";
import { initializeApp, InitializeAppPayload } from "./_commonActions";
import { ActionWithPayload } from "../types";
import { keyBy } from "lodash";

export interface AppConfig {
    categories: Category[];
    categoryMap: Record<Taxonomy, Category>;
}

const initialState: AppConfig = {
    categories: [],
    categoryMap: {} as Record<Taxonomy, Category>,
};

export const appConfig = createReducer<AppConfig>(initialState, {
    [initializeApp.toString()]: (
        state,
        { payload }: ActionWithPayload<InitializeAppPayload>,
    ) => {
        state.categories = payload.categories;
        state.categoryMap = keyBy(
            payload.categories.map((c) => [c, ...c.subcategories]).flat(),
            (c) => c.id,
        ) as Record<Taxonomy, Category>;
    },
});
