import React, { useCallback } from "react";
import "./Onboarding.scss";
import { useEntities } from "../../hooks/useEntities";
import { Entity } from "../../common/types/entity";
import { OnboardingEntity } from "./OnboardingEntity";
import { completeOnboarding } from "../../lib/user";
import { OnboardingUser } from "./OnboardingUser";
import { useUser } from "../../hooks/useUser";
import { OnboardingRegister } from "./OnboardingRegister";
import { usePartnerStackCookies } from "./Register/usePartnerStackCookies";

interface Props {
    showSidebar: boolean;
}

export const OnboardingContainer: React.FC<Props> = ({ showSidebar }) => {
    const user = useUser();
    const businessEntity = useEntities({
        onlyBusiness: true,
        excludeEmptyMockEntities: false,
    })[0] as Entity | undefined;

    usePartnerStackCookies();

    const handleFinishOnboarding = useCallback(async () => {
        await completeOnboarding();

        window.location.replace("/");
    }, []);

    const sidebarSize = showSidebar ? undefined : 10;

    if (!user) {
        return (
            <OnboardingRegister
                showSidebar={showSidebar}
                sidebarSize={sidebarSize}
            />
        );
    }

    if (businessEntity && !businessEntity.onboardingComplete) {
        return (
            <OnboardingEntity
                businessEntity={businessEntity}
                showSidebar={showSidebar}
                onFinish={handleFinishOnboarding}
                sidebarSize={sidebarSize}
            />
        );
    }

    return (
        <OnboardingUser
            onFinish={handleFinishOnboarding}
            sidebarSize={sidebarSize}
        />
    );
};
