import { InvoiceStatus } from "../../billing";
import { Invoice } from "../../types/billing";

export class InvoiceDto {
    static deserialize(dto: InvoiceDto): Invoice {
        return {
            ...dto,
            date: new Date(dto.date),
        };
    }

    date: string;
    status: InvoiceStatus;
    url: string | null;
    totalInCents: number;

    constructor(invoice: Invoice) {
        this.date = invoice.date.toISOString();
        this.status = invoice.status;
        this.url = invoice.url;
        this.totalInCents = invoice.totalInCents;
    }
}
