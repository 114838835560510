import { LedgerBasedProfitLossResponseDto } from "../common/dto/reports/ledger-based-profit-loss-response.dto";
import { GetAccountingReportDto } from "../common/dto/reports/get-accounting-report.dto";
import { backendClient, unwrapResponse } from "./backendClient";
import { GetBalanceSheetResponseDto } from "../common/dto/reports/get-balance-sheet-response.dto";
import { GetAccountTransactionsReportDto } from "../common/dto/reports/get-account-transactions-report.dto";
import { GetAccountTransactionsReportResponseDto } from "../common/dto/reports/get-account-transactions-report-response.dto";
import { ReportFormat } from "../common/helpers/reports";
import { FileResponse } from "../types";

export async function getAccountingProfitAndLoss(
    query: GetAccountingReportDto,
): Promise<LedgerBasedProfitLossResponseDto> {
    return unwrapResponse(
        await backendClient.get("/accounting-reports/profit-loss", {
            params: query,
        }),
    );
}

export async function getBalanceSheet(
    query: GetAccountingReportDto,
): Promise<GetBalanceSheetResponseDto> {
    return unwrapResponse(
        await backendClient.get("/accounting-reports/balance-sheet", {
            params: query,
        }),
    );
}

export function getAccountTransactions(
    query: GetAccountTransactionsReportDto<ReportFormat.JSON>,
): Promise<GetAccountTransactionsReportResponseDto>;
export function getAccountTransactions(
    query: GetAccountTransactionsReportDto<ReportFormat.XLSX>,
): Promise<FileResponse>;
export async function getAccountTransactions(
    query: GetAccountTransactionsReportDto,
): Promise<GetAccountTransactionsReportResponseDto | FileResponse> {
    const response = await backendClient.get(
        "/accounting-reports/account-transactions",
        {
            params: query,
            responseType:
                query.format === ReportFormat.JSON ? "json" : "arraybuffer",
        },
    );

    if (query.format === ReportFormat.XLSX) {
        return {
            data: response.data,
            headers: response.headers,
        };
    }

    return unwrapResponse(response);
}
