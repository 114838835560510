import { createContext, useContext } from "react";
import { noop } from "lodash";
import { Taxonomy } from "../../common/categories";
export interface TransactionsDrillDownModalParams {
    label?: string;
    category?: Taxonomy;
}

export interface TransactionsDrillDownContextValue {
    params: TransactionsDrillDownModalParams;
    open: (label: string, category: Taxonomy) => void;
    close: () => void;
}
export const transactionsDrillDownModalDefaultParams = {};

export const TransactionsDrillDownContext =
    createContext<TransactionsDrillDownContextValue>({
        params: transactionsDrillDownModalDefaultParams,
        open: noop,
        close: noop,
    });

export function useTransactionsDrillDownContext(): TransactionsDrillDownContextValue {
    const ctx = useContext(TransactionsDrillDownContext);
    if (!ctx) {
        throw new Error(
            "Can only be used inside a TransactionsDrillDownContextProvider",
        );
    }

    return ctx;
}
