// reflects Stripes subscription status 1:1 for now
import { endOfMonth, endOfYear, startOfYear, subYears } from "date-fns";
import { AppliedDiscount } from "./types/billing";
import { User } from "./types/user";

export const CUSTOM_PRICING_CALL_URL =
    "https://calendly.com/d/35r-tvz-5fm/kick-custom-pricing-call";

export const ENTERPRISE_TRIAL_CALL_URL =
    "https://calendly.com/d/4gt-jdz-gn7/kick-intro";

export enum SubscriptionStatus {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    INCOMPLETE = "INCOMPLETE",
    INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
    PAST_DUE = "PAST_DUE",
    PAUSED = "PAUSED",
    TRIALING = "TRIALING",
    UNPAID = "UNPAID",
}

export const ACTIVE_SUBSCRIPTION_STATUSES = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.TRIALING,
];

export enum InvoiceStatus {
    DRAFT = "DRAFT",
    OPEN = "OPEN",
    PAID = "PAID",
    VOID = "VOID",
    UNCOLLECTIBLE = "UNCOLLECTIBLE",
}

export const DEFAULT_FREE_SAVINGS_CREDITS = 10_000;
export const DEFAULT_FREE_USAGE_LIMIT = 15_000;

export const USAGE_GROUPING_DATE_COLUMN_ALIAS = "month" as const;

export interface UsageByMonthRawRecord {
    [USAGE_GROUPING_DATE_COLUMN_ALIAS]: Date;
    value: string;
}

export interface UsageByMonth {
    month: Date;
    value: number;
}

export interface PricingAndUsage {
    currentYearPricing: Pricing;
    lastYearPricing: Pricing;
    lastYearTotalUsage: number;
    currentYearTotalUsage: number;
    totalBeforeDiscount: number;
    total: number;
    discount: AppliedDiscount | null;
    invalidDiscountCode: boolean;
}

// all values are in dollars
export interface PricingTier {
    start: number;
    end: number;
    price: number;
}

export interface TierBase {
    price: number;
    usage: number;
}

export interface Pricing {
    priceByMonth: number[];
    totalPrice: number;
    predictedNextPayment: number;
}

export const PAID_TIERS_BASE: TierBase[] = [
    {
        price: 49,
        usage: 5_000,
    },
    {
        price: 99,
        usage: 15_000,
    },
    {
        price: 198,
        usage: 25_000,
    },
];

export function getCustomPriceTiersForUser(user: User): PricingTier[] {
    const tiers: PricingTier[] = [
        {
            start: 0,
            end: user.savingsCreditLimit,
            price: 0,
        },
    ];

    for (const base of PAID_TIERS_BASE) {
        const previous = tiers[tiers.length - 1];

        tiers.push({
            start: previous.end + 1,
            end: previous.end + base.usage,
            price: base.price,
        });
    }

    return tiers;
}

export function getTierForUsage<T extends PricingTier = PricingTier>(
    tiers: T[],
    usage: number,
): T {
    return tiers.find(({ end }) => usage <= end) ?? tiers[tiers.length - 1];
}

export enum IntentType {
    PAYMENT = "PAYMENT",
    SETUP = "SETUP",
}

export type DiscountType = "flat" | "percent";
export interface BillingDateFilters {
    start: Date;
    end: Date;
}

export function currentYearFilters() {
    return {
        start: startOfYear(new Date()),
        end: endOfMonth(new Date()),
    };
}

export function previousYearFilters() {
    return {
        start: subYears(startOfYear(new Date()), 1),
        end: subYears(endOfYear(new Date()), 1),
    };
}
