import { Address } from "../../types/embeddable/address";
import { Entity, EntityIndustry, EntityProfile } from "../../types/entity";
import { TaxLocation } from "../../types/taxLocation";
import { valueOrNull } from "../dto-helpers";
import { UserDto } from "../user/user.dto";

export class EntityDto {
    static deserialize(dto: EntityDto): Entity {
        return {
            ...dto,
            user: valueOrNull(dto.user, UserDto.deserialize),
            bookkeepingStart: new Date(dto.bookkeepingStart),
        };
    }

    id: number;
    name: string | null;
    address: Address | null;
    bookkeepingYears: number[];
    bookkeepingStart: string;
    taxLocations: TaxLocation[];
    onboardingComplete: boolean;
    industry: EntityIndustry;
    iconColor: string;
    isBusiness: boolean;
    isPersonal: boolean;
    user: UserDto | null;
    userId: number | null;
    profile: EntityProfile | null;
    isMockEntity: boolean;
    workspaceId: string | null;
    openingBalanceSubmitted: boolean;
    reportsReady: boolean;

    constructor(entity: Entity) {
        this.id = entity.id;
        this.name = entity.name;
        this.address = entity.address;
        this.bookkeepingYears = entity.bookkeepingYears;
        this.bookkeepingStart = entity.bookkeepingStart.toISOString();
        this.taxLocations = entity.taxLocations;
        this.onboardingComplete = entity.onboardingComplete;
        this.industry = entity.industry;
        this.iconColor = entity.iconColor;
        this.isBusiness = entity.isBusiness;
        this.isPersonal = entity.isPersonal;
        this.user = entity.user ? new UserDto(entity.user) : null;
        this.userId = entity.userId;
        this.profile = entity.profile;
        this.isMockEntity = entity.isMockEntity;
        this.workspaceId = entity.workspaceId;
        this.openingBalanceSubmitted = entity.openingBalanceSubmitted;
        this.reportsReady = entity.reportsReady;
    }
}
