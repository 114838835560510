import { currencyFormatterFinancialStatements } from "../../../common/helpers/currency";
import {
    ProfitLossChartDataSeriesCalculatedIdentifier,
    ProfitLossChartReportSeriesData,
    ProfitLossChartReportSeriesIdentifier,
    ProfitLossTopTransactionsDto,
} from "../../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { Taxonomy } from "../../../common/categories";
import { difference, intersection, keys } from "lodash";
import { XYSeries } from "@amcharts/amcharts4/.internal/charts/series/XYSeries";
import { CategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { styleDefaultChartTooltip } from "../../../lib/charts";
import { ReportFilters } from "../../../common/types/filters/reports";
import {
    getCycleEndForDate,
    getLabelFormatForCycle,
    printLabel,
} from "../../../common/helpers/reports";
import { ColumnSeries, XYChart } from "@amcharts/amcharts4/charts";
import { getProfitLossTabTopTransactions } from "../../../lib/ownerReports";
import { format, parse } from "date-fns";
import { REPORTS_CONTROLLER_DATE_FORMAT } from "../../../common/constants";
import { Color } from "@amcharts/amcharts4/core";

interface DataRowsRenderParams {
    data: { label: string } & Record<
        Taxonomy | ProfitLossChartDataSeriesCalculatedIdentifier,
        number
    >;
    labelRenderer: (id: Taxonomy) => string;
    seriesColorMap: Record<string, string>;
    items?: string[];
    flipValuesSign?: boolean;
}
interface ReportCombinedTooltipParams {
    seriesArray: XYSeries[];
    seriesToHookInto: XYSeries;
    getCategoryLabel: CategoryLabelGetter;
    filters: ReportFilters;
    sectionsRenderMethod: (
        sectionsRenderParams: ReportTooltipSectionsRenderParams,
    ) => string[];
}

interface ReportTooltipParams {
    chart: XYChart;
    seriesToHookInto: ColumnSeries;
    filters: ReportFilters;
}

export interface ReportTooltipSectionsRenderParams {
    item: XYSeries;
    getCategoryLabel: CategoryLabelGetter;
    seriesColorMap: Record<string, string>;
    filters: ReportFilters;
}
interface ReportTooltipRowRenderParams {
    displaySign?: boolean;
    color?: string;
}

const PL_TOOLTIP_WIDTH = 280;
export function renderTooltipRow(
    label: string,
    value: number,
    { displaySign = false, color }: ReportTooltipRowRenderParams = {},
) {
    const bullet = color
        ? `<div class="reporting-tab-chart-tooltip__item__bullet" style="--bullet-color: ${color}"></div>`
        : "";
    return `<div class="reporting-tab-chart-tooltip__item">
                <span class="reporting-tab-chart-tooltip__item__label">
                    ${bullet}
                    ${label}
                </span>
                <span class="reporting-tab-chart-tooltip__item__value">
                    ${currencyFormatterFinancialStatements.format(value, {
                        addCurrency: true,
                        displaySign: displaySign,
                    })}
                </span>
            </div>`;
}

export function renderDataRows({
    data,
    labelRenderer,
    seriesColorMap,
    items,
    flipValuesSign = false,
}: DataRowsRenderParams) {
    const tooltipForbiddenItems = ["label"];
    let itemsToRender = difference(keys(data), tooltipForbiddenItems);
    if (items && items.length > 0) {
        itemsToRender = intersection(itemsToRender, items);
    }
    return itemsToRender.reduce((acc, index) => {
        const value = flipValuesSign
            ? -data[index as ProfitLossChartDataSeriesCalculatedIdentifier]
            : data[index as ProfitLossChartDataSeriesCalculatedIdentifier];

        const label = labelRenderer(index as Taxonomy);
        const curr =
            value !== 0
                ? renderTooltipRow(label, value, {
                      displaySign: false,
                      color: seriesColorMap[label],
                  })
                : "";
        return acc + curr;
    }, "");
}

function renderTransactionRow(label: string, value: number, isTotal?: boolean) {
    const mainClass = isTotal
        ? "pl-chart-tooltip__item pl-chart-tooltip__item--total"
        : "pl-chart-tooltip__item";
    return `<div class="${mainClass}">
                    <div class="pl-chart-tooltip__item__label">${label}</div>
                    <div class="pl-chart-tooltip__item__value">${currencyFormatterFinancialStatements.format(
                        value,
                        {
                            addCurrency: true,
                            displaySign: false,
                        },
                    )}</div>
                </div>`;
}

interface RenderTooltipHTMLParams {
    seriesToHookInto: ColumnSeries;
    data: ProfitLossChartReportSeriesData;
    filters: ReportFilters;
    topTransactions?: ProfitLossTopTransactionsDto;
}

function renderTooltipHTML({
    seriesToHookInto,
    data,
    filters,
    topTransactions,
}: RenderTooltipHTMLParams) {
    const tooltipSections = [];
    tooltipSections.push(
        `<div class="pl-chart-tooltip__header">
                <div class="pl-chart-tooltip__header__bullet" style="--bullet-color: ${
                    (seriesToHookInto.fill as Color).hex
                }"></div>
                <span class="pl-chart-tooltip__header__label">${
                    seriesToHookInto.name
                }</span>
            </div>`,
    );
    tooltipSections.push(
        `<div class="pl-chart-tooltip__item pl-chart-tooltip__item--date-range">${printLabel(
            data.label,
            filters,
            true,
        )}</div>`,
    );

    let topTransactionsSection = "";
    if (topTransactions && topTransactions.top?.length > 0) {
        for (const transaction of topTransactions.top) {
            topTransactionsSection += renderTransactionRow(
                transaction.description,
                transaction.value,
            );
        }
        if (topTransactions.other !== 0) {
            topTransactionsSection += renderTransactionRow(
                "Other",
                topTransactions.other,
            );
        }
    } else {
        topTransactionsSection = `<div class="pl-chart-tooltip__item">Loading transactions data...</div>`;
    }
    tooltipSections.push(topTransactionsSection);
    const total =
        data[
            seriesToHookInto.dataFields
                .valueY as ProfitLossChartDataSeriesCalculatedIdentifier
        ] ?? 0;
    tooltipSections.push(
        `<div class="pl-chart-tooltip__item pl-chart-tooltip__item--spacer"></div>`,
    );
    tooltipSections.push(renderTransactionRow("Total:", total, true));

    const tooltipHTML = tooltipSections.reduce((acc, html) => acc + html, "");

    return `<div class="pl-chart-tooltip" style="--tooltip-width: ${PL_TOOLTIP_WIDTH}px">
                ${tooltipHTML}
            </div>`;
}

interface GetTopTransactionsDtoParams {
    filters: ReportFilters;
    data: ProfitLossChartReportSeriesData;
    seriesToHookInto: ColumnSeries;
    cacheBuster?: number;
}

function getTopTransactionsDto({
    filters,
    data,
    seriesToHookInto,
    cacheBuster,
}: GetTopTransactionsDtoParams) {
    const start = parse(
        data.label,
        getLabelFormatForCycle(filters.cycle),
        new Date(),
    );

    return {
        startDate: format(start, REPORTS_CONTROLLER_DATE_FORMAT),
        endDate: format(
            getCycleEndForDate(start, filters.cycle),
            REPORTS_CONTROLLER_DATE_FORMAT,
        ),
        entitiesAccounts: filters.entitiesAccounts,
        categoryIdentifier: seriesToHookInto.dataFields
            .valueY as ProfitLossChartReportSeriesIdentifier,
        itemsShown: difference(keys(data), [
            "label",
        ]) as ProfitLossChartReportSeriesIdentifier[],
        cacheBuster,
    };
}

export function generateSingleCategoryTooltip(
    { chart, seriesToHookInto, filters }: ReportTooltipParams,
    reportCreatedAt?: number,
) {
    seriesToHookInto.columns.template.adapter.add(
        "tooltipHTML",
        function (_, item) {
            const data = item.tooltipDataItem
                .dataContext as ProfitLossChartReportSeriesData;
            getProfitLossTabTopTransactions(
                getTopTransactionsDto({
                    filters: filters,
                    data: data,
                    seriesToHookInto: seriesToHookInto,
                    cacheBuster: reportCreatedAt,
                }),
            ).then((res) => {
                if (seriesToHookInto.tooltip) {
                    seriesToHookInto.tooltip.html = renderTooltipHTML({
                        seriesToHookInto: seriesToHookInto,
                        data: data,
                        filters: filters,
                        topTransactions: res,
                    });
                }
            });

            return renderTooltipHTML({
                seriesToHookInto: seriesToHookInto,
                data: data,
                filters: filters,
            });
        },
    );
    seriesToHookInto.columns.template.adapter.add(
        "tooltipX",
        function (tooltipX, target) {
            const barX = target.x as number;
            const barWidth = target.width as number;

            if (barX + barWidth / 2 < chart.pixelWidth / 2) {
                return barWidth + 8 + PL_TOOLTIP_WIDTH;
            } else {
                return -8;
            }
        },
    );

    styleDefaultChartTooltip(seriesToHookInto);

    if (seriesToHookInto.tooltip) {
        seriesToHookInto.tooltip.dy = 0;
        seriesToHookInto.tooltip.label.padding(0, 0, 0, 0);
        seriesToHookInto.tooltip.background.opacity = 0.95;
        seriesToHookInto.tooltip.pointerOrientation = "right";
    }
}

export function generateReportsCombinedTooltip({
    seriesArray,
    seriesToHookInto,
    getCategoryLabel,
    filters,
    sectionsRenderMethod,
}: ReportCombinedTooltipParams) {
    const seriesColorMap = seriesArray.reduce(
        (acc, s) => {
            acc[s.name] = (s.fill! as any).hex;
            return acc;
        },
        {} as Record<string, string>,
    );

    seriesToHookInto.adapter.add("tooltipHTML", function (_, item) {
        const tooltipSections = sectionsRenderMethod({
            item,
            getCategoryLabel,
            seriesColorMap,
            filters,
        });
        const tooltipHTML = tooltipSections.reduce(
            (acc, html) =>
                acc +
                `
                <div class="reporting-tab-chart-tooltip__section">
                    ${html}
                </div>
                `,
            "",
        );

        return `<div class="reporting-tab-chart-tooltip">
                ${tooltipHTML}
            </div>`;
    });

    styleDefaultChartTooltip(seriesToHookInto);

    if (seriesToHookInto.tooltip) {
        seriesToHookInto.tooltip.label.padding(0, 0, 0, 0);
        seriesToHookInto.tooltip.background.opacity = 0.95;
    }
}
