import React, { useMemo, useState } from "react";
import {
    useFinancialAccounts,
    useTotalBalance,
} from "../../hooks/useFinancialAccounts";
import { EntityIcon } from "../entity/EntityIcon";
import { DashboardAccountsItem } from "./DashboardAccountsItem";
import "./DashboardAccounts.scss";
import { currencyFormatter } from "../../common/helpers/currency";
import { useEntity } from "../entity/useEntity";
import { displayAccountBalance } from "../../helpers/account";
import classNames from "classnames";
import { ArrowDownIcon, ArrowUpIcon } from "../../icons";
import { useInteraction } from "../../hooks/useInteraction";

export const DashboardEntityAccounts: React.FC = () => {
    const entity = useEntity();
    const allAccounts = useFinancialAccounts({ entityId: entity.id });
    const accounts = useMemo(() => {
        if (!allAccounts) return;

        // nosonar @todo: replace with .toSorted once typescript is updated
        const sortedAccounts = [...allAccounts];
        sortedAccounts.sort((a, b) => a.order - b.order);

        return sortedAccounts;
    }, [allAccounts]);
    const totalBalance = useTotalBalance(entity);
    const [opened, setOpened] = useState(false);
    const open = useInteraction(() => setOpened(!opened));

    return (
        <section
            className={classNames("dashboard-accounts__entity", {
                "dashboard-accounts__entity--opened": opened,
            })}
            {...open}
        >
            <DashboardAccountsItem
                name={entity.name!}
                balance={currencyFormatter.format(totalBalance)}
                className="dashboard-accounts__header"
                icon={<EntityIcon entity={entity} />}
                addon={
                    <span className="dashboard-accounts__toggler">
                        {opened ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </span>
                }
            />
            <div className="dashboard-accounts__content">
                {accounts?.map((account) => (
                    <DashboardAccountsItem
                        key={account.id}
                        name={account.name ?? ""}
                        balance={displayAccountBalance(account)}
                        className="dashboard-accounts__account"
                    />
                ))}
                {accounts?.length === 0 && (
                    <p className="small mb-0">No accounts connected</p>
                )}
            </div>
        </section>
    );
};
