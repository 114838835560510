import React from "react";
import { ModalComponentWithResultProps } from "../../helpers/modal";
import { Entity } from "../../common/types/entity";
import { Button, Modal } from "react-bootstrap";
import { getAccountNameWithLast4 } from "../../common/helpers/financialAccount";
import { FinancialAccount } from "../../common/types/financialAccount";

interface Props extends ModalComponentWithResultProps<boolean> {
    account: FinancialAccount;
    entity: Entity;
}

export const ConfirmAccountTypeChangeModal: React.FC<Props> = ({
    account,
    entity,
    close,
}) => {
    return (
        <>
            <Modal.Header closeButton>
                <h3>Please change your account type</h3>
            </Modal.Header>
            <Modal.Body>
                <p className="text-prominent mb-5">
                    <strong>{getAccountNameWithLast4(account)}</strong> is a{" "}
                    {account.isBusiness ? "business" : "personal"} account. To
                    move it to your{" "}
                    {entity.isPersonal ? (
                        "personal accounts"
                    ) : (
                        <>
                            business <strong>{entity.name}</strong>
                        </>
                    )}
                    , you'd also need to change the account type to{" "}
                    {entity.isPersonal ? "Personal" : "Business"}.
                </p>

                <footer className="d-flex justify-content-between">
                    <Button
                        variant="secondary"
                        size="lg"
                        onClick={() => close(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => close(true)}
                    >
                        Change to {entity.isPersonal ? "Personal" : "Business"}{" "}
                        and move
                    </Button>
                </footer>
            </Modal.Body>
        </>
    );
};
