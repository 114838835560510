import { useQuery } from "react-query";
import { CustomCategory } from "../common/types/customCategory";
import { customCategoriesRest } from "../lib/restClient";
import { CUSTOM_CATEGORIES_QUERY_KEY } from "../queryClient";
import { useWorkspaceContext } from "../state/workspaceContext";
import { useTypedFlags } from "./useTypedFlags";

export function useCustomCategories(): CustomCategory[] {
    const { customCategories: canUseCustomCategories } = useTypedFlags();
    const { activeWorkspace } = useWorkspaceContext();
    const { data: customCategories = [] } = useQuery(
        CUSTOM_CATEGORIES_QUERY_KEY,
        async () => {
            if (!canUseCustomCategories || !activeWorkspace) {
                return [];
            }
            return await customCategoriesRest.queryAsAdminUser(
                activeWorkspace.primaryAdminId,
            );
        },
    );
    return customCategories;
}
