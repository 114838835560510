import { useMutation } from "react-query";
import { downloadDocuments } from "../../../lib/financialDocument";
import { LAST_DOCUMENTS_DOWNLOAD_KEY } from "./lib";

export function useDownloadDocuments(workspaceId: string) {
    return useMutation(() => downloadDocuments(workspaceId), {
        onSuccess() {
            localStorage.setItem(
                LAST_DOCUMENTS_DOWNLOAD_KEY,
                Date.now().toString(),
            );
        },
    });
}
