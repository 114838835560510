import { useEntities } from "../../../hooks/useEntities";
import { useAccountIdsByEntity } from "./useAccountIdsByEntity";
import { useCallback, useMemo } from "react";
import { TransactionsEntityAccountsFilter } from "../../../common/types/filters/transactions";

interface UseEntityAccountsFilterOptions {
    value?: TransactionsEntityAccountsFilter[];
    onChange(value: TransactionsEntityAccountsFilter[] | undefined): void;
    onlyBusiness?: boolean;
    excludeAllMockEntities?: boolean;
    excludeEmptyMockEntities?: boolean;
    includeCreditCards?: boolean;
    allowDeselectingLastItem?: boolean;
    defaultSelected?: boolean;
}

export function useEntityAccountsFilter({
    value,
    excludeAllMockEntities,
    excludeEmptyMockEntities,
    onChange,
    onlyBusiness,
    allowDeselectingLastItem,
    defaultSelected = true,
}: UseEntityAccountsFilterOptions) {
    const entities = useEntities({
        onlyBusiness,
        excludeAllMockEntities,
        excludeEmptyMockEntities,
    });
    const accountKeysByEntity = useAccountIdsByEntity();
    const prepareValue = useCallback(() => {
        if (defaultSelected) {
            return entities.map((entity) => ({
                entityId: entity.id,
                excludedAccounts: [],
            }));
        } else {
            return [];
        }
    }, [defaultSelected, entities]);

    const isEntitySelected = useCallback(
        (entityId: number) => {
            if (!value) {
                return defaultSelected;
            }

            return value.some((v) => v.entityId === entityId);
        },
        [defaultSelected, value],
    );

    const isAccountSelected = useCallback(
        (accountId: number, entityId: number) => {
            if (!value) {
                return defaultSelected;
            }

            const entityFilter = value.find((v) => v.entityId === entityId);
            return Boolean(
                entityFilter &&
                    !entityFilter.excludedAccounts?.includes(accountId),
            );
        },
        [defaultSelected, value],
    );

    const triggerChange = useCallback(
        (newValue: TransactionsEntityAccountsFilter[]) => {
            if (!defaultSelected) {
                onChange(newValue);
            } else if (
                newValue.length === 0 ||
                (newValue.length === entities.length &&
                    newValue.every((v) => !v.excludedAccounts?.length))
            ) {
                // if everything is selected or nothing is, reset the filter instead
                onChange(undefined);
            } else {
                onChange(newValue);
            }
        },
        [defaultSelected, entities.length, onChange],
    );

    const toggleEntity = useCallback(
        (entityId: number) => {
            const processedValue = !value ? prepareValue() : value;
            const hasMultipleSelectedEntities = processedValue.length > 1;
            const entityIsCurrentlySelected = processedValue.some(
                (v) => v.entityId === entityId,
            );

            if (entityIsCurrentlySelected) {
                if (hasMultipleSelectedEntities || allowDeselectingLastItem) {
                    triggerChange(
                        processedValue.filter((v) => v.entityId !== entityId),
                    );
                }
            } else {
                triggerChange([
                    ...processedValue,
                    { entityId, excludedAccounts: [] },
                ]);
            }
        },
        [value, prepareValue, allowDeselectingLastItem, triggerChange],
    );

    const toggleAccount = useCallback(
        (accountId: number, entityId: number) => {
            const processedValue = value ?? prepareValue();
            const entityFilter = processedValue.find(
                (v) => v.entityId === entityId,
            );

            if (entityFilter) {
                if (entityFilter.excludedAccounts?.includes(accountId)) {
                    triggerChange(
                        processedValue.map((v) =>
                            v.entityId === entityId
                                ? {
                                      ...v,
                                      excludedAccounts:
                                          v.excludedAccounts?.filter(
                                              (ea) => ea !== accountId,
                                          ),
                                  }
                                : v,
                        ),
                    );
                } else {
                    triggerChange(
                        processedValue.map((v) =>
                            v.entityId === entityId
                                ? {
                                      ...v,
                                      excludedAccounts: [
                                          ...(v.excludedAccounts ?? []),
                                          accountId,
                                      ],
                                  }
                                : v,
                        ),
                    );
                }
            } else {
                triggerChange([
                    ...processedValue,
                    {
                        entityId,
                        excludedAccounts: accountKeysByEntity[entityId].filter(
                            (v) => v !== accountId,
                        ),
                    },
                ]);
            }
        },
        [value, prepareValue, triggerChange, accountKeysByEntity],
    );

    const everythingSelected = useMemo(
        () =>
            !value ||
            (value.length === entities.length &&
                value.every((v) => !v.excludedAccounts?.length)),
        [value, entities],
    );

    return {
        entities,
        everythingSelected,
        isEntitySelected,
        isAccountSelected,
        toggleEntity,
        toggleAccount,
    };
}
