import { UserSettings } from "../../types/user";

export class UserSettingsDto {
    static deserialize(dto: UserSettingsDto): UserSettings {
        return {
            ...dto,
        };
    }

    weeklyInsightsEnabledAt: string | null;
    monthlyInsightsEnabledAt: string | null;

    constructor(settings: UserSettings) {
        this.weeklyInsightsEnabledAt = settings.weeklyInsightsEnabledAt;
        this.monthlyInsightsEnabledAt = settings.monthlyInsightsEnabledAt;
    }
}
