import { Form, Formik, FormikConfig } from "formik";
import React from "react";
import { object, string } from "yup";
import { FormRow } from "../../../forms/FormRow";
import { UpdateProfileFormProps } from "./types";

interface Value {
    email: string;
}

export const UpdateEmailForm: React.FC<UpdateProfileFormProps<Value>> = ({
    email,
    onSubmit,
    children,
}) => {
    const form: FormikConfig<Value> = {
        initialValues: {
            email,
        },
        validationSchema: object().shape({
            email: string()
                .required("Email is required")
                .email("Please provide a valid email address"),
        }),
        onSubmit,
    };

    return (
        <Formik {...form}>
            {({ isValid }) => (
                <Form>
                    <FormRow
                        fieldName="email"
                        fieldType="email"
                        label="Email"
                    />
                    {children(isValid)}
                </Form>
            )}
        </Formik>
    );
};
