import { Transaction } from "../common/types/transaction";
import { backendClient, unwrapResponse } from "./backendClient";
import { TransactionCategorizationRule } from "../common/types/transactionCategorizationRule";
import { CreateCategorizationRuleDto } from "../common/dto/categorizationRule/create-categorization-rule.dto";
import { TransactionCategorizationRuleDto } from "../common/dto/categorizationRule/transaction-categorization-rule.dto";
import { UpdateCategorizationRuleDto } from "../common/dto/categorizationRule/update-categorization-rule.dto";

export async function disableCategorizationRule(
    transaction: Transaction,
): Promise<void> {
    await backendClient.post(
        `/legacy-categorization-rules/${transaction.id}/disable`,
    );
}

export async function getCategorizationRules(
    workspaceId: string,
): Promise<TransactionCategorizationRule[]> {
    const response = unwrapResponse(
        await backendClient.get<TransactionCategorizationRuleDto[]>(
            `/categorization-rules/${workspaceId}`,
        ),
    );

    return response.map((dto) =>
        TransactionCategorizationRuleDto.deserialize(dto),
    );
}

export async function createCategorizationRule(
    data: CreateCategorizationRuleDto,
): Promise<TransactionCategorizationRule> {
    return TransactionCategorizationRuleDto.deserialize(
        unwrapResponse(
            await backendClient.post<TransactionCategorizationRuleDto>(
                `/categorization-rules`,
                data,
            ),
        ),
    );
}

export async function removeCategorizationRule(
    rule: TransactionCategorizationRule,
): Promise<void> {
    await backendClient.delete(
        `/categorization-rules/${rule.workspaceId}/${rule.id}`,
    );
}

export async function updateCategorizationRule(
    rule: TransactionCategorizationRule,
    data: UpdateCategorizationRuleDto,
): Promise<TransactionCategorizationRule> {
    return TransactionCategorizationRuleDto.deserialize(
        unwrapResponse(
            await backendClient.patch<TransactionCategorizationRuleDto>(
                `/categorization-rules/${rule.workspaceId}/${rule.id}`,
                data,
            ),
        ),
    );
}
