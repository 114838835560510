import { CashFlowReportDto } from "../common/dto/reports/cash-flow-tab-report-response.dto";
import { GetReportingTabReportDto } from "../common/dto/reports/get-reporting-tab-report.dto";
import {
    ProfitLossReportDto,
    ProfitLossTopTransactionsDto,
    SpendingReportDto,
} from "../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { backendClient, unwrapResponse } from "./backendClient";

import { GetProfitLossTopTransactionsDto } from "../common/dto/reports/get-profit-loss-top-transactions.dto";

export async function getReportingTabProfitLoss(
    requestDto: GetReportingTabReportDto,
): Promise<ProfitLossReportDto> {
    return unwrapResponse(
        await backendClient.get("/owner-reports/profit-loss", {
            params: requestDto,
            paramsSerializer: { indexes: true },
        }),
    );
}

export async function getCashFlowTabReport(
    requestDto: GetReportingTabReportDto,
): Promise<CashFlowReportDto> {
    return unwrapResponse(
        await backendClient.get("/owner-reports/cash-flow", {
            params: requestDto,
            paramsSerializer: { indexes: true },
        }),
    );
}

export async function getReportingTabSpending(
    requestDto: GetReportingTabReportDto,
): Promise<SpendingReportDto> {
    return unwrapResponse(
        await backendClient.get("/owner-reports/spending", {
            params: requestDto,
            paramsSerializer: { indexes: true },
        }),
    );
}

export async function getProfitLossTabTopTransactions(
    requestDto: GetProfitLossTopTransactionsDto,
): Promise<ProfitLossTopTransactionsDto> {
    return unwrapResponse(
        await backendClient.get("/owner-reports/profit-loss/top-transactions", {
            params: requestDto,
            paramsSerializer: { indexes: true },
        }),
    );
}
