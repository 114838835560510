import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { AccountCard } from "../../general/AccountCard/AccountCard";
import { useFinancialAccountEntitySelection } from "../../../hooks/useFinancialAccountEntitySelection";
import { EMPTY_STATE } from "../../../common/constants";
import { UpdateFinancialAccountEntity } from "../UpdateFinancialAccountEntity";
import { useAccountsConnectionContext } from "./AccountsConnectionContext";
import { isValidAccount } from "./helpers";
import { FinancialAccountTypeSelect } from "../FinancialAccountTypeSelect";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { useChangeFinancialAccountEntityMutation } from "../../../mutations/financialAccount";
import { IntegrationAccount } from "../../../common/types/integrationAccount";
import { Entity } from "../../../common/types/entity";

export interface AccountConnectionRowProps {
    account: IntegrationAccount;
    connection?: PlaidConnection;
    onChangeAccountType?(isBusiness: boolean): Promise<void>;
    defaultEntity?: Entity;
}

export const AccountConnectionRow: React.FC<AccountConnectionRowProps> = ({
    account,
    connection,
    onChangeAccountType,
    defaultEntity,
}) => {
    connection = connection ?? (account.connection as PlaidConnection);
    const changeEntity = useChangeFinancialAccountEntityMutation(
        account.financialAccount,
    );
    const {
        showBusinessEntitySelection,
        showPersonalEntitySelection,
        businessEntitiesToAdminister,
        personalEntitiesToAdminister,
        nonMockBusinessEntities,
    } = useFinancialAccountEntitySelection();
    const entitySelection =
        (showBusinessEntitySelection && !defaultEntity?.isBusiness) ||
        showPersonalEntitySelection;
    const { showAccountTypeWarning } = useAccountsConnectionContext();
    const [temporaryIsBusiness, setTemporaryIsBusiness] = useState<
        boolean | null
    >(null);

    const isBusinessSelection =
        temporaryIsBusiness ?? account.financialAccount.isBusiness;
    const showWarning = showAccountTypeWarning && !isValidAccount(account);

    const onClassificationChanged = useCallback(
        (isBusiness: boolean) => {
            const entitiesToAdminister = isBusiness
                ? nonMockBusinessEntities
                : personalEntitiesToAdminister;

            if (entitiesToAdminister.length === 1) {
                changeEntity.mutate(entitiesToAdminister[0]);
                setTemporaryIsBusiness(null);
            } else {
                setTemporaryIsBusiness(isBusiness);
            }

            onChangeAccountType?.(isBusiness);
        },
        [
            onChangeAccountType,
            changeEntity,
            nonMockBusinessEntities,
            personalEntitiesToAdminister,
        ],
    );

    const selectedEntity = useMemo(() => {
        if (isBusinessSelection !== account.financialAccount.isBusiness) {
            return undefined;
        } else {
            return account.financialAccount.entity;
        }
    }, [isBusinessSelection, account]);

    let content: React.ReactNode;
    const entitiesToAdminister = isBusinessSelection
        ? businessEntitiesToAdminister
        : personalEntitiesToAdminister;

    if (isBusinessSelection === null || entitiesToAdminister.length === 1) {
        content = <div>{EMPTY_STATE}</div>;
    } else if (entitiesToAdminister.length === 0) {
        content = <div>No entities to administer</div>;
    } else {
        content = (
            <UpdateFinancialAccountEntity
                account={account.financialAccount}
                connection={connection}
                dropdownKey={`accountEntitySelection_${account.id}`}
                selectedEntity={selectedEntity ?? undefined}
                onUpdated={() => setTemporaryIsBusiness(null)}
                allowMockEntity
                isBusiness={isBusinessSelection ?? undefined}
                size="sm"
            />
        );
    }

    return (
        <tr
            className={classNames("accounts-connection-widget__row", {
                "accounts-connection-widget__row--warning": showWarning,
            })}
            key={account.id}
        >
            <td className="accounts-connection-widget__account">
                <AccountCard
                    account={account.financialAccount}
                    connection={connection}
                    showNumber
                />
            </td>

            <td className="accounts-connection-widget__classification">
                <div className="accounts-connection-widget__classification__content">
                    {showWarning && (
                        <span className="mr-3 text-warning">
                            Select{" "}
                            {isBusinessSelection !== null ? "Entity" : "Type"}
                        </span>
                    )}
                    <FinancialAccountTypeSelect
                        value={isBusinessSelection}
                        onChange={onClassificationChanged}
                        loading={changeEntity.isLoading}
                    />
                </div>
            </td>

            {entitySelection && (
                <td className="accounts-connection-widget__entity">
                    {content}
                </td>
            )}
        </tr>
    );
};
