import { FlatRatePlan } from "../../../common/flatRateBilling";
import { YearCard, YearCardProps } from "./YearCard";
import { previousYear } from "../../settings/Billing/Pricing/constants";
import { Tag } from "../../general/Tag/Tag";
import React from "react";
import { currencyFormatterNoFractions } from "../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../common/helpers/monetary";

interface Props extends Pick<YearCardProps, "selected" | "onChange"> {
    selectedPlan: FlatRatePlan;
    isTrial: boolean;
    freePreviousYearBookkeepingOffer: boolean;
}

export const PreviousYearPriceCard: React.FC<Props> = ({
    selected,
    freePreviousYearBookkeepingOffer,
    isTrial,
    selectedPlan,
    onChange,
}) => {
    let previousYearPriceFormatted: React.ReactNode;

    if (freePreviousYearBookkeepingOffer) {
        previousYearPriceFormatted = (
            <>
                <del className="text-gray-500 mr-1">
                    {currencyFormatterNoFractions.format(
                        fromIntToMonetary(selectedPlan.intervalAmountCents),
                    )}
                </del>{" "}
                {currencyFormatterNoFractions.format(0)}
            </>
        );
    } else if (isTrial) {
        previousYearPriceFormatted = currencyFormatterNoFractions.format(0);
    } else {
        previousYearPriceFormatted = currencyFormatterNoFractions.format(
            fromIntToMonetary(selectedPlan.intervalAmountCents),
        );
    }

    let description: React.ReactNode;

    if (!isTrial) {
        description = (
            <>Includes all 12 months of the {previousYear} tax year.</>
        );
    } else if (freePreviousYearBookkeepingOffer) {
        description = (
            <>
                Available after your trial. Includes all 12 months of the{" "}
                {previousYear} tax year.
            </>
        );
    } else {
        description = (
            <>
                Available after your trial. Includes all 12 months of the{" "}
                {previousYear} tax year. You will be charged{" "}
                {currencyFormatterNoFractions.format(
                    fromIntToMonetary(selectedPlan.intervalAmountCents),
                )}{" "}
                at the end of your trial.
            </>
        );
    }

    return (
        <YearCard
            year={previousYear}
            priceFormatted={previousYearPriceFormatted}
            title={
                <>
                    {previousYear} Bookkeeping{" "}
                    {freePreviousYearBookkeepingOffer && (
                        <Tag
                            className="ml-1"
                            size="sm"
                            variant="primary"
                            outline
                        >
                            One-time offer
                        </Tag>
                    )}
                </>
            }
            description={description}
            selected={selected}
            onChange={onChange}
        />
    );
};
