import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import actionBarStyles from "../../general/ActionBar/ActionBar.module.scss";
import { EntityMenuItem } from "../../general/EntityMenuItem/EntityMenuItem";
import { useEntities } from "../../../hooks/useEntities";

interface EntitiesDropdownProps {
    onSelected(entityId: number): void;
    disabled?: boolean;
}

export const EntitiesDropdown: React.FC<EntitiesDropdownProps> = ({
    onSelected,
    disabled,
    children,
}) => {
    const entities = useEntities();
    return (
        <Dropdown className="d-flex">
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="action-bar-entity">Assign to entity</Tooltip>
                }
            >
                <Dropdown.Toggle
                    as="button"
                    disabled={disabled}
                    className={actionBarStyles.button}
                    data-testid="bulk-entities-dropdown-button"
                >
                    {children}
                </Dropdown.Toggle>
            </OverlayTrigger>

            <Dropdown.Menu>
                {entities.map((menuEntity) => (
                    <EntityMenuItem
                        key={menuEntity.id}
                        entity={menuEntity}
                        onSelect={() => onSelected(menuEntity.id)}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
