import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { EntitySelect } from "./EntitySelect";
import { useEntities } from "../../hooks/useEntities";
import { Entity } from "../../common/types/entity";

interface Props {
    prompt?: string;
    onCancel: () => void;
    onSelected: (entity: Entity) => void;
    selectPlaceholder?: string;
}

export const SelectBusinessEntityModal: React.FC<Props> = ({
    prompt = "Select business entity",
    onCancel,
    onSelected,
    selectPlaceholder,
}) => {
    const businessEntities = useEntities({ onlyBusiness: true });
    const [selected, setSelected] = useState<Entity>();

    return (
        <>
            <Modal.Header closeButton />
            <Modal.Body>
                <p className="text-prominent">{prompt}</p>
                <EntitySelect
                    entities={businessEntities}
                    selectedEntity={selected}
                    onChange={setSelected}
                    dropdownKey="selectBusinessEntityModal"
                    placeholder={selectPlaceholder}
                />

                <footer className="mt-5 d-flex justify-content-between">
                    <Button variant="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!selected}
                        onClick={() => selected && onSelected(selected)}
                    >
                        Confirm
                    </Button>
                </footer>
            </Modal.Body>
        </>
    );
};
