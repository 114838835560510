import React, { useMemo } from "react";
import { Transaction } from "../../common/types/transaction";
import styles from "./AutopilotCardTransaction.module.scss";
import { currencyFormatter } from "../../common/helpers/currency";
import { AccountCard } from "../general/AccountCard/AccountCard";
import { ObjectIcon } from "../general/ObjectIcon/ObjectIcon";
import { DEFAULT_ICON_COLOR } from "../../constants";
import { formatDateForTransaction } from "../../helpers/date";

interface Props {
    transaction: Transaction;
}

export const AutopilotCardTransaction: React.FC<Props> = ({ transaction }) => {
    const formattedTransactionDate = useMemo(
        () => formatDateForTransaction(transaction.date),
        [transaction.date],
    );

    return (
        <div className={styles.transaction}>
            <div>{formattedTransactionDate}</div>
            <div>{transaction.description}</div>
            <div>{currencyFormatter.format(Math.abs(transaction.amount))}</div>
            <AccountCard
                account={transaction.financialAccount}
                connection={
                    transaction.financialAccount.integrationAccounts?.[0]
                        .connection ?? undefined
                }
                onlyNumber
                iconSize="sm"
            />
            <ObjectIcon
                name="Personal"
                size="sm"
                defaultColor={DEFAULT_ICON_COLOR}
                square
            />
        </div>
    );
};
