import { useQuery } from "react-query";
import { getAccounts } from "../lib/accounting";
import { useMemo } from "react";
import { keyBy } from "lodash";
import { AccountType } from "../common/types/domains/accounting/accounts";
import { Account } from "../common/types/domains/accounting/account";

export interface AccountTypeWithAccounts {
    type: AccountType;
    accounts: Account[];
}

export function useAccounts(entityId?: number) {
    const accounts = useQuery(
        ["accounting_accounts", entityId],
        () => (entityId ? getAccounts({ entityId }) : []),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
        },
    );
    const accountsByCode = useMemo(
        () => (accounts.data ? keyBy(accounts.data, (a) => a.code) : {}),
        [accounts.data],
    );
    const accountTypesWithAccounts = useMemo(() => {
        const accountsPerType = accounts.data
            ? accounts.data
                  .sort((a, b) => (a.code < b.code ? -1 : 1))
                  .reduce((acc, curValue) => {
                      if (!acc.has(curValue.type)) {
                          acc.set(curValue.type, []);
                      }
                      acc.get(curValue.type)?.push(curValue);
                      return acc;
                  }, new Map<AccountType, Account[]>())
            : new Map<AccountType, Account[]>();

        const arrayToReturn: AccountTypeWithAccounts[] = [];
        for (const accountType of accountsPerType.keys()) {
            arrayToReturn.push({
                type: accountType,
                accounts: accountsPerType.get(accountType)!.map((a) => a),
            });
        }
        return arrayToReturn;
    }, [accounts.data]);

    return {
        isLoading: accounts.isLoading,
        isFetching: accounts.isFetching,
        accounts: accounts.data ?? [],
        accountsByCode,
        accountTypesWithAccounts,
    };
}
