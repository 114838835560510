import React, { ForwardedRef, forwardRef } from "react";
import editableTableStyles from "./EditableTable.module.scss";
import classNames from "classnames";
import { accountingNumberFormatter } from "../../../common/helpers/number";

const titleClass = "title small " + editableTableStyles.editableTableTitle;

interface Props {
    totals: TableFooterTotals;
    translateY?: number;
    opacity?: number;
    hide?: boolean;
    className?: string;
}

export interface TableFooterTotals {
    debitAmount: number;
    creditAmount: number;
}

export const EditableTableFooter = forwardRef(
    (
        { totals, translateY = 0, opacity = 1, hide = false, className }: Props,
        ref: ForwardedRef<any>,
    ) => {
        return (
            <div
                className={classNames(
                    editableTableStyles.editableTableTotal,
                    className,
                )}
                style={{
                    transform: `translateY(${translateY}px)`,
                    opacity: opacity,
                    display: hide ? "none" : "grid",
                }}
                ref={ref}
            >
                <div
                    className={classNames(
                        editableTableStyles.editableTableHeaderCell,
                        editableTableStyles.editableTableHeaderNoLeftBorder,
                    )}
                />
                <div
                    className={classNames(
                        editableTableStyles.editableTableHeaderCell,
                        editableTableStyles.editableTableHeaderNoLeftBorder,
                        editableTableStyles.editableTableCellAlignRight,
                    )}
                >
                    <p
                        className={classNames(
                            titleClass,
                            editableTableStyles.totalTitle,
                        )}
                    >
                        Total
                    </p>
                </div>
                <div
                    className={classNames(
                        editableTableStyles.editableTableHeaderCell,
                        editableTableStyles.editableTableCellAlignRight,
                    )}
                >
                    <p
                        className={classNames(
                            titleClass,
                            editableTableStyles.totalTitle,
                        )}
                    >
                        {totals.debitAmount === 0
                            ? 0
                            : accountingNumberFormatter(totals.debitAmount)}
                    </p>
                </div>
                <div
                    className={classNames(
                        editableTableStyles.editableTableHeaderCell,
                        editableTableStyles.editableTableCellAlignRight,
                    )}
                >
                    <p
                        className={classNames(
                            titleClass,
                            editableTableStyles.totalTitle,
                        )}
                    >
                        {totals.creditAmount === 0
                            ? 0
                            : accountingNumberFormatter(totals.creditAmount)}
                    </p>
                </div>
                <div
                    className={editableTableStyles.editableTableHeaderCell}
                ></div>
            </div>
        );
    },
);
