import { useMemo } from "react";
import { FiltersChangeFn, TransactionsFilters } from "../../lib";
import { ActiveFilter } from "./lib";
import { useResetFilters } from "../../../../general/ActiveFilters/useResetFilters";

type PredicateFn = (filters: TransactionsFilters) => boolean;

const isActive: Record<ActiveFilter, PredicateFn> = {
    category: ({ category }) => !!category && category.length > 0,
    direction: ({ direction }) => !!direction,
    amount: ({ minAmount, maxAmount }) =>
        minAmount !== undefined || maxAmount !== undefined,
    date: ({ start, end }) => start !== undefined || end !== undefined,
} as const;

const activeFiltersOrder: ActiveFilter[] = [
    "date",
    "amount",
    "category",
    "direction",
];
export function useActiveFilters(filters: TransactionsFilters): ActiveFilter[] {
    return useMemo(() => {
        return activeFiltersOrder.filter((filterId) =>
            isActive[filterId](filters),
        );
    }, [filters]);
}

const resetPayload: Record<ActiveFilter, Partial<TransactionsFilters>> = {
    category: { category: undefined },
    direction: { direction: undefined },
    date: { start: undefined, end: undefined },
    amount: { minAmount: undefined, maxAmount: undefined },
};

export function useResetFilter(
    onChange: FiltersChangeFn,
    filterIds: ActiveFilter | ActiveFilter[],
) {
    return useResetFilters(resetPayload, onChange, filterIds);
}
