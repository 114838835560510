import React from "react";
import {
    ConnectPlaid,
    ConnectPlaidAccountProps,
} from "../../plaid/ConnectPlaid/ConnectPlaid";
import "./AccountsConnectionWidget.scss";
import { AccountConnectionRow } from "./AccountConnectionRow";
import classNames from "classnames";
import { useFinancialAccountEntitySelection } from "../../../hooks/useFinancialAccountEntitySelection";
import { useAccountsConnectionContext } from "./AccountsConnectionContext";
import { isValidAccount } from "./helpers";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { IntegrationAccount } from "../../../common/types/integrationAccount";

export interface AccountConnectionWidgetProps
    extends Pick<
        ConnectPlaidAccountProps,
        "onConnected" | "onSavingConnection" | "defaultEntity" | "beforeConnect"
    > {
    heading: React.ReactNode;
    emptyConnectPrompt?: React.ReactNode;
    connectPrompt?: React.ReactNode;
    accounts: IntegrationAccount[];
    connection?: PlaidConnection;
    onChangeAccountType?(isBusiness: boolean): Promise<void>;
    allowConnect?: boolean;
    isConnecting?: boolean;
}

export const AccountsConnectionWidget: React.FC<
    AccountConnectionWidgetProps
> = ({
    heading,
    accounts,
    connection,
    emptyConnectPrompt,
    connectPrompt,
    allowConnect,
    onChangeAccountType,
    isConnecting,
    defaultEntity,
    children,
    ...connectAccountProps
}) => {
    const { showBusinessEntitySelection, showPersonalEntitySelection } =
        useFinancialAccountEntitySelection();
    const entitySelection =
        (showBusinessEntitySelection && !defaultEntity?.isBusiness) ||
        showPersonalEntitySelection;
    const cols = entitySelection ? 3 : 2;
    const { showAccountTypeWarning } = useAccountsConnectionContext();
    const hasError =
        showAccountTypeWarning && accounts.some((a) => !isValidAccount(a));

    return (
        <table
            className={classNames("table accounts-connection-widget", {
                "accounts-connection-widget--error": hasError,
            })}
        >
            <thead>
                <tr>
                    <th colSpan={cols} className="p-0">
                        <div className="accounts-connection-widget__header">
                            <header>{heading}</header>
                        </div>
                    </th>
                </tr>
                {entitySelection && accounts.length > 0 && (
                    <tr className="small text-grey accounts-connection-widget__header-labels">
                        <th>Account</th>
                        <th className="accounts-connection-widget__classification">
                            Type
                        </th>
                        <th>Entity / Business</th>
                    </tr>
                )}
            </thead>

            <tbody>
                {accounts.length > 0 ? (
                    <>
                        {accounts.map((account) => (
                            <AccountConnectionRow
                                key={account.id}
                                account={account}
                                connection={connection}
                                onChangeAccountType={onChangeAccountType}
                                defaultEntity={defaultEntity}
                            />
                        ))}

                        {allowConnect && (
                            <tr>
                                <td colSpan={cols}>
                                    <ConnectPlaid
                                        {...connectAccountProps}
                                        defaultEntity={defaultEntity}
                                        btnText={connectPrompt}
                                        btnVariant="secondary"
                                        btnSize="sm"
                                        disabled={isConnecting}
                                    />
                                </td>
                            </tr>
                        )}
                    </>
                ) : (
                    allowConnect && (
                        <tr>
                            <td colSpan={cols} className="p-0">
                                <div className="accounts-connection-widget__empty">
                                    <ConnectPlaid
                                        {...connectAccountProps}
                                        defaultEntity={defaultEntity}
                                        btnText={emptyConnectPrompt}
                                        btnVariant="secondary"
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                )}
            </tbody>
        </table>
    );
};
