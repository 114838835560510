import React, { useState } from "react";
import { Card } from "../../general/Card/Card";
import { Button } from "react-bootstrap";
import { currencyFormatterFractionsIfNeeded } from "../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../common/helpers/monetary";
import styles from "./Summary.module.scss";
import { Field, Form, Formik, FormikConfig } from "formik";
import { FormRowRaw } from "../../forms/FormRowRaw";

interface Props {
    discountCode: string | null;
    onDiscountCodeChange: (code: string | null) => void;
    couponDiscount: number;
    total: number;
    isTrial: boolean;
    codeInvalid?: boolean;
}

export const Summary: React.FC<Props> = ({
    discountCode,
    total,
    couponDiscount,
    onDiscountCodeChange,
    codeInvalid,
    isTrial,
}) => {
    const [addingCoupon, setAddingCoupon] = useState(!!discountCode);

    const footer = (
        <footer className="ml-auto">
            <div className={styles.total}>
                Today's total:{" "}
                <span className={styles.price} data-testid="total-today">
                    {currencyFormatterFractionsIfNeeded.format(
                        isTrial ? 0 : fromIntToMonetary(total),
                    )}
                </span>
            </div>

            {isTrial ? (
                <div className={styles.total}>
                    Total after trial:{" "}
                    <span
                        className={styles.price}
                        data-testid="total-after-trial"
                    >
                        {currencyFormatterFractionsIfNeeded.format(
                            fromIntToMonetary(total),
                        )}
                    </span>
                </div>
            ) : null}
        </footer>
    );

    const couponValue =
        discountCode && couponDiscount > 0 ? -couponDiscount : 0;

    const form: FormikConfig<{ code: string }> = {
        initialValues: { code: discountCode ?? "" },
        onSubmit: ({ code }) => {
            if (code) {
                onDiscountCodeChange(code);
            }
        },
        onReset() {
            onDiscountCodeChange(null);
        },
    };

    let content: React.ReactNode;

    if (addingCoupon) {
        content = (
            <>
                <section>
                    <Formik {...form}>
                        {({ values }) => (
                            <Form className={styles.form}>
                                <FormRowRaw
                                    value={values.code}
                                    field={
                                        <Field
                                            name="code"
                                            className="form-control form-control-sm"
                                            readOnly={!!discountCode}
                                            data-testid="coupon-input"
                                        />
                                    }
                                    error={
                                        codeInvalid
                                            ? "Invalid or expired code"
                                            : undefined
                                    }
                                    showErrorMessage={codeInvalid}
                                />

                                {discountCode ? (
                                    <Button
                                        type="reset"
                                        variant="secondary"
                                        size="sm"
                                        data-testid="remove-coupon-button"
                                    >
                                        Remove
                                    </Button>
                                ) : (
                                    <Button
                                        variant="secondary"
                                        type="submit"
                                        size="sm"
                                        data-testid="submit-coupon-button"
                                    >
                                        Add
                                    </Button>
                                )}
                            </Form>
                        )}
                    </Formik>

                    <aside className={styles.withPrice}>
                        Coupon:{" "}
                        <span
                            className={styles.price}
                            data-testid="coupon-discount"
                        >
                            {currencyFormatterFractionsIfNeeded.format(
                                fromIntToMonetary(couponValue),
                            )}
                        </span>
                    </aside>
                </section>

                <section>{footer}</section>
            </>
        );
    } else {
        content = (
            <section className="justify-content-between align-items-center">
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => setAddingCoupon(true)}
                    data-testid="add-coupon-button"
                >
                    Add coupon
                </Button>

                {footer}
            </section>
        );
    }

    return <Card className={styles.summaryCard}>{content}</Card>;
};
