import React, { MouseEvent } from "react";
import classNames from "classnames";
import "./Switch.scss";

export type SwitchSize = "sm" | "md" | "lg";
export type Direction = "ltr" | "rtl";

export interface SwitchProps {
    active: boolean;
    label?: React.ReactNode;
    icon?: (active: boolean) => React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
    onChanged: (isActive: boolean, event: MouseEvent) => any;
    size?: SwitchSize;
    danger?: boolean;
    direction?: Direction;
    className?: string;
}

export const Switch: React.FC<SwitchProps> = ({
    active,
    label,
    icon = () => null,
    loading,
    disabled,
    onChanged,
    danger,
    size = "lg",
    direction = "ltr",
    className,
}) => {
    const renderedIcon = icon(active);

    return (
        <button
            type="button"
            className={classNames(
                "switch",
                `switch--${size}`,
                `switch--direction-${direction}`,
                {
                    "switch--active": active,
                    "switch--danger": danger,
                    "switch--disabled": disabled,
                    "switch--icon": !!loading || !!renderedIcon,
                    "switch--with-label": !!label,
                },
                className,
            )}
            disabled={disabled}
            onClick={(e) => !disabled && onChanged(!active, e)}
        >
            <span className="switch__track">
                {loading ? (
                    <span className="switch__loading" />
                ) : (
                    renderedIcon && (
                        <span className="switch__icon">{renderedIcon}</span>
                    )
                )}
                <span className="switch__toggle" />
            </span>
            {label ? <label>{label}</label> : null}
        </button>
    );
};
