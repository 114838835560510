import { queryFinancialDocuments } from "../../lib/financialDocument";
import { useQuery } from "react-query";
import { FetchFinancialDocumentsDto } from "../../common/dto/financialDocument/fetch-financial-documents.dto";
import { financialDocumentQueryKeys } from "./lib";

export const FINANCIAL_DOCUMENTS_PAGE_SIZE = 20;
export const REFETCH_FOR_MATCHING_RESULTS_INTERVAL_MS = 10_000;
export function useFinancialDocumentsQuery(
    queryParams: FetchFinancialDocumentsDto,
) {
    return useQuery(
        financialDocumentQueryKeys.list(queryParams),
        () => queryFinancialDocuments(queryParams),
        {
            refetchInterval(response) {
                const hasDocumentsWithoutMatchAttempt = response?.data?.some(
                    (financialDocument) =>
                        !financialDocument.transactionMatches.length,
                );

                return hasDocumentsWithoutMatchAttempt
                    ? REFETCH_FOR_MATCHING_RESULTS_INTERVAL_MS
                    : false;
            },
        },
    );
}
