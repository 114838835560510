import { useCallback, useState } from "react";
import { PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID } from "./LedgerBasedProfitLossDownloadTable";
import { utils, writeFileXLSX } from "@sheet/core";
import {
    disableGridLines,
    getColumn,
    getRow,
} from "../../../../common/helpers/sheet";
import { LedgerBasedProfitLossResponseDto } from "../../../../common/dto/reports/ledger-based-profit-loss-response.dto";

export function useProfitLossDownload(
    report?: LedgerBasedProfitLossResponseDto,
) {
    const [downloading, setDownloading] = useState(false);

    const download = useCallback(() => {
        setDownloading(true);
        const tbl = document.getElementById(
            PROFIT_LOSS_DOWNLOAD_REPORT_TABLE_ID,
        );
        const ws = utils.table_to_sheet(tbl, {
            borders: true,
            cellStyles: true,
            rawDates: true,
        });
        disableGridLines(ws);

        getColumn(ws, 0).wpx = 20;
        getColumn(ws, 1).wpx = 300;

        if (report) {
            report.columnLabels.forEach((_, idx) => {
                const valuesColumn = getColumn(ws, idx + 2);
                valuesColumn.wpx = 80;
                valuesColumn.level = 1;
            });
            const totalsColumn = getColumn(ws, report.columnLabels.length + 2);
            totalsColumn.wpx = 80;
        }

        const dataRowsStartingAt = 6;
        for (let rowIdx = dataRowsStartingAt; rowIdx < 200; rowIdx++) {
            getRow(ws, rowIdx).hpx = 20;
        }

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Profit & Loss");
        writeFileXLSX(wb, "ProfitLossDownload.xlsx", { cellStyles: true });
        setDownloading(false);
    }, [report]);

    return {
        download,
        downloading,
    };
}
