import React from "react";
import { currencyFormatterNoFractions } from "../../../common/helpers/currency";
import { ProfitLossReportSummaryCard } from "./ProfitLossReportSummaryCard";
import { ProfitLossReportSummary } from "./types";

export const ProfitLossReportRevenueSummaryCard: React.FC<
    ProfitLossReportSummary
> = ({ startDate, endDate, income, otherIncome, revenue, monthCount }) => {
    return (
        <ProfitLossReportSummaryCard
            label="Revenue"
            value={currencyFormatterNoFractions.format(income + otherIncome)}
            startDate={startDate}
            endDate={endDate}
        >
            <div className="pl-summary-card-tooltip__value">
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Income</span>
                    <span>{currencyFormatterNoFractions.format(income)}</span>
                </div>
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Other Income</span>
                    <span>
                        {currencyFormatterNoFractions.format(otherIncome)}
                    </span>
                </div>
                <div className="pl-summary-card-tooltip__value__line--spacer" />
                <div className="pl-summary-card-tooltip__value__line--total">
                    <span>Total</span>
                    <span>{currencyFormatterNoFractions.format(revenue)}</span>
                </div>
                <div className="pl-summary-card-tooltip__value__line">
                    <span>Per Month</span>
                    <span>
                        {currencyFormatterNoFractions.format(revenue)} ÷{" "}
                        {monthCount} ={" "}
                        {currencyFormatterNoFractions.format(
                            revenue / monthCount,
                        )}
                    </span>
                </div>
            </div>
        </ProfitLossReportSummaryCard>
    );
};
