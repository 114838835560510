import React, { useCallback, useContext, useMemo, useState } from "react";
import { Transaction } from "../../../common/types/transaction";
import { TransactionsContext } from "./transactions.context";
import { getTransactionDetails } from "../../../lib/transactions";
import { keyBy, omit } from "lodash";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { Modal } from "react-bootstrap";
import { LoosingAccessToTransactionModal } from "../LoosingAccessToTransactionModal";
import { useIgnoreLoosingAccessToTransaction } from "../../../hooks/useIgnoreLoosingAccessToTransaction";
import { ToastMessagesContext } from "../../general/ToastMessages/ToastMessages.context";
import { ToastMessageType } from "../../general/ToastMessages/lib/common";
import { invalidateTransactionDetailsQueries } from "../TransactionDetails/useTransactionDetailsQuery";
import { useUpdateTransactionMutation } from "../../../mutations/transaction";
import { useEntities } from "../../../hooks/useEntities";

interface LoosingAccessContext {
    ownerName: string;
    transaction: Transaction;
    payload: UpdateTransactionDto;
}

export const TransactionsContextProvider: React.FC = ({ children }) => {
    const [transactions, setTransactions] = useState<Transaction[]>();
    const [loosingAccessContext, setLoosingAccessContext] =
        useState<LoosingAccessContext>();
    const { ignoreLoosingAccess } = useIgnoreLoosingAccessToTransaction();
    const [shownTransactionId, setShownTransactionId] = useState<number>();
    const availableEntities = useEntities();
    const availableEntityIds = availableEntities.map((entity) => entity.id);

    const { registerToast } = useContext(ToastMessagesContext);
    const updateTransaction = useUpdateTransactionMutation();

    const upsertTransaction = useCallback((transaction: Transaction) => {
        setTransactions((prev) => {
            if (prev) {
                return prev.map((t) =>
                    t.id === transaction.id ? transaction : t,
                );
            } else {
                return [transaction];
            }
        });
    }, []);

    const hideTransaction = useCallback((transaction: Transaction) => {
        setTransactions((prev) => {
            if (prev) {
                return prev.filter((t) => t.id !== transaction.id);
            } else {
                return [];
            }
        });
    }, []);

    const updateMany = useCallback((transactionsToUpdate: Transaction[]) => {
        setTransactions((prev) => {
            if (!prev) {
                return;
            }

            const dict = keyBy(transactionsToUpdate, "id");
            return prev.map((t) => (dict[t.id] ? { ...t, ...dict[t.id] } : t));
        });
    }, []);

    const saveTransaction = useCallback(
        async (transaction: Transaction, payload: UpdateTransactionDto) => {
            try {
                const updateForList = omit(payload, "taxQuestionAnswers");
                if (!payload.entityId) {
                    upsertTransaction({
                        ...transaction,
                        ...updateForList,
                    });
                }

                const response = await updateTransaction.mutateAsync({
                    transaction,
                    update: {
                        loosingAccessConfirmed: ignoreLoosingAccess,
                        ...payload,
                    },
                });

                if ("transaction" in response) {
                    const updated = response.transaction;

                    if (
                        (payload.category || payload.customCategoryId) &&
                        response.categorizationRuleSaved
                    ) {
                        registerToast(`categorization-${transaction.id}`, {
                            type: ToastMessageType.CATEGORIZATION,
                            startTimer: true,
                            data: {
                                transaction: updated,
                                newCategory: (payload.category ??
                                    payload.customCategoryId)!,
                            },
                        });
                    }

                    if (!availableEntityIds.includes(updated.entity.id)) {
                        // user no longer has access to the transaction
                        hideTransaction(updated);
                        setShownTransactionId(undefined);

                        return updated;
                    } else {
                        // refresh transaction if user still has access to it
                        await invalidateTransactionDetailsQueries();

                        const updatedTransaction = await getTransactionDetails(
                            transaction.id,
                        );

                        upsertTransaction(updatedTransaction.transaction);

                        return updatedTransaction.transaction;
                    }
                } else {
                    setLoosingAccessContext({
                        ownerName: response.loosingAccessTo,
                        transaction,
                        payload,
                    });
                }
            } catch (e) {
                upsertTransaction(transaction);
                throw e;
            }
        },
        [
            ignoreLoosingAccess,
            updateTransaction,
            upsertTransaction,
            availableEntityIds,
            hideTransaction,
            registerToast,
        ],
    );

    const onLoosingAccessModalClosed = useCallback(
        (confirmLoosingAccess?: boolean) => {
            if (confirmLoosingAccess && loosingAccessContext) {
                saveTransaction(loosingAccessContext.transaction, {
                    ...loosingAccessContext.payload,
                    loosingAccessConfirmed: true,
                }).catch((e) => {
                    throw e;
                });
            }

            setLoosingAccessContext(undefined);
        },
        [loosingAccessContext, saveTransaction],
    );

    const transactionsContextValue = useMemo(
        () => ({
            transactions,
            setTransactions,
            upsertTransaction,
            saveTransaction,
            updateMany,
            shownTransactionId,
            setShownTransactionId,
        }),
        [
            saveTransaction,
            shownTransactionId,
            transactions,
            updateMany,
            upsertTransaction,
        ],
    );

    return (
        <>
            <TransactionsContext.Provider value={transactionsContextValue}>
                {children}
            </TransactionsContext.Provider>
            <Modal
                show={!!loosingAccessContext}
                onHide={onLoosingAccessModalClosed}
            >
                {loosingAccessContext && (
                    <LoosingAccessToTransactionModal
                        ownerName={loosingAccessContext.ownerName}
                        close={onLoosingAccessModalClosed}
                    />
                )}
            </Modal>
        </>
    );
};
