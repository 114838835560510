import { SubscriptionDetails, SubscriptionType } from "../../types/billing";
import { isoStringOrNull, unpackDateOrNull } from "../dto-helpers";

import { FLAT_RATE_PLANS } from "../../flatRateBilling";
import { SubscriptionStatus } from "../../billing";

export class SubscriptionDetailsDto {
    static deserialize(dto: SubscriptionDetailsDto): SubscriptionDetails {
        return {
            ...dto,
            renewsOn: unpackDateOrNull(dto.renewsOn),
            cancelsAt: unpackDateOrNull(dto.cancelsAt),
            periodEndsAt: unpackDateOrNull(dto.periodEndsAt),
        };
    }

    type: SubscriptionType;
    status: SubscriptionStatus;
    price: number;
    renewsOn: string | null;
    plan: FLAT_RATE_PLANS;
    cancelsAt: string | null;
    cancelOnTrialEnd: boolean;
    redeemedFreePreviousYearOffer: boolean;
    includePreviousYear: boolean;
    periodEndsAt: string | null;
    nextPeriodPlan: FLAT_RATE_PLANS;

    constructor(details: SubscriptionDetails) {
        this.type = details.type;
        this.status = details.status;
        this.price = details.price;
        this.renewsOn = isoStringOrNull(details.renewsOn);
        this.plan = details.plan;
        this.cancelsAt = isoStringOrNull(details.cancelsAt);
        this.cancelOnTrialEnd = details.cancelOnTrialEnd;
        this.redeemedFreePreviousYearOffer =
            details.redeemedFreePreviousYearOffer;
        this.includePreviousYear = details.includePreviousYear;

        this.periodEndsAt = isoStringOrNull(details.periodEndsAt);
        this.nextPeriodPlan = details.nextPeriodPlan;
    }
}
