import React from "react";
import classNames from "classnames";

interface Props {
    title: React.ReactNode;
    description?: React.ReactNode;
    icon?: React.ReactNode;
    iconVariant?: string;
    className?: string;
    narrow?: boolean;
    contentNarrow?: boolean;
    wide?: boolean;
}

export const OnboardingStep: React.FC<Props> = ({
    title,
    description,
    children,
    icon,
    iconVariant,
    className,
    narrow,
    contentNarrow,
    wide,
}) => {
    return (
        <section
            className={classNames("onboarding__step", className, {
                "onboarding__step--narrow": narrow,
                "onboarding__step--content-narrow": contentNarrow,
                "onboarding__step--wide": wide,
            })}
        >
            {icon ? (
                <div
                    className={
                        "onboarding__icon" +
                        (iconVariant ? ` onboarding__icon--${iconVariant}` : "")
                    }
                >
                    {icon}
                </div>
            ) : null}
            <h1 className="onboarding__title">{title}</h1>
            {description ? (
                <p className="onboarding__headline">{description}</p>
            ) : null}
            <div className="onboarding__content">{children}</div>
        </section>
    );
};
