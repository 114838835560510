import {
    Entity,
    EntityProfileIndustry,
    EntityProfileType,
} from "../../common/types/entity";
import { OnboardingFlowType } from "./types";

export const WAITLIST_INDUSTRY = [
    EntityProfileIndustry.ECOMMERCE,
    EntityProfileIndustry.HEALTHCARE,
    EntityProfileIndustry.MANUFACTURING,
    EntityProfileIndustry.MARKETPLACE,
    EntityProfileIndustry.REAL_ESTATE,
    EntityProfileIndustry.RETAIL,
    EntityProfileIndustry.TECHNOLOGY,
];

export const ACCOUNTANT_INDUSTRY = [
    EntityProfileIndustry.ACCOUNTANT,
    EntityProfileIndustry.BOOKKEEPER,
];

export function getOnboardingFlow(entity?: Entity): OnboardingFlowType {
    if (!entity?.profile?.industry || !entity?.profile.type) {
        return OnboardingFlowType.SELF_SERVE;
    }

    if (
        WAITLIST_INDUSTRY.includes(entity.profile.industry) ||
        entity.profile.type === EntityProfileType.NO_LEGAL_ENTITY
    ) {
        return OnboardingFlowType.WAITLIST;
    }

    if (ACCOUNTANT_INDUSTRY.includes(entity.profile.industry)) {
        return OnboardingFlowType.ACCOUNTANT;
    }

    return OnboardingFlowType.SELF_SERVE;
}
