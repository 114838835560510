import {
    ActiveFinancialDocumentFilters,
    FinancialDocumentFilters,
} from "./lib";
import { useResetFilters } from "../../general/ActiveFilters/useResetFilters";

const resetPayload: Record<
    ActiveFinancialDocumentFilters,
    Partial<FinancialDocumentFilters>
> = {
    date: { start: undefined, end: undefined },
    status: { status: undefined },
};

export function useResetFinancialDocumentsFilter(
    onChange: (filters: Partial<FinancialDocumentFilters>) => void,
    filterIds:
        | ActiveFinancialDocumentFilters
        | ActiveFinancialDocumentFilters[],
) {
    return useResetFilters(resetPayload, onChange, filterIds);
}
