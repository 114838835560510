import React, { useCallback } from "react";
import { AggregatedFiltersProvider } from "../../../general/AggregatedFilters/AggregatedFiltersProvider";
import {
    DEFAULT_TAB,
    TransactionsFilters,
} from "../../../transactions/filters/lib";
import { Card } from "../../../general/Card/Card";
import { SearchFilter } from "../../../general/SearchFilter/SearchFilter";
import { AggregatedTransactionFiltersOverlay } from "../../../transactions/filters/AggregatedFilters/AggregatedTransactionFiltersOverlay";
import { AggregatedFilters } from "../../../general/AggregatedFilters/AggregatedFilters";
import { useFiltersActive } from "../../../transactions/useFiltersActive";
import {
    useActiveFilters,
    useResetFilter,
} from "../../../transactions/filters/AggregatedFilters/ActiveFilters/ActivityFilter.hooks";
import { ActiveFilters } from "../../../general/ActiveFilters/ActiveFilters";
import { TransactionsActiveFilterButton } from "../../../transactions/filters/AggregatedFilters/ActiveFilters/TransactionsActiveFilterButton";
import css from "../../../general/AggregatedFilters/AggregatedFilters.module.scss";

interface Props {
    filters: TransactionsFilters;
    onFiltersChange: (filters: TransactionsFilters) => void;
}

export const MatchingViewFilters: React.FC<Props> = ({
    filters,
    onFiltersChange,
}) => {
    const filtersActive = useFiltersActive(filters);
    const activeFilters = useActiveFilters(filters);
    const resetActiveFilters = useResetFilter(onFiltersChange, activeFilters);

    const updateDescriptionFilter = useCallback(
        (description?: string) => {
            onFiltersChange({
                description,
            });
        },
        [onFiltersChange],
    );

    return (
        <AggregatedFiltersProvider defaultTab={DEFAULT_TAB}>
            <Card className={css.filters}>
                <section className={css.filters}>
                    <SearchFilter
                        filter={filters.description}
                        onChange={updateDescriptionFilter}
                        placeholder="Description..."
                    />

                    <AggregatedFilters>
                        <AggregatedTransactionFiltersOverlay
                            filters={filters}
                            onChange={onFiltersChange}
                        />
                    </AggregatedFilters>
                </section>

                {filtersActive && (
                    <ActiveFilters
                        activeFilters={activeFilters}
                        renderVisibleFilters={(visibleFilters) =>
                            visibleFilters.map((filterId) => (
                                <TransactionsActiveFilterButton
                                    key={filterId}
                                    filterId={filterId}
                                    filters={filters}
                                    onChange={onFiltersChange}
                                />
                            ))
                        }
                        clearFilters={resetActiveFilters}
                    />
                )}
            </Card>
        </AggregatedFiltersProvider>
    );
};
