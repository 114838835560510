import React from "react";
import styles from "./UsageSection.module.scss";
import { PricingTierWithUsage, TierVariant } from "./PricingTierWithUsage";
import { TierTag } from "./TierTag";
import { getTierForUsage } from "../../../../../common/billing";
import { currencyFormatterShort } from "../../../../../common/helpers/currency";
import { useCurrentPricingTier } from "../../../../../hooks/useCurrentPricingTier";
import { useBillingStatus } from "../../../../../hooks/useBillingStatus";
import { CustomPricingCallButton } from "../../CustomPricingCallButton";
import { useAnnualBillingContext } from "../AnnualBilling.context";

export const CurrentYearPricingAndUsage: React.FC = () => {
    const { pricingAndUsage } = useAnnualBillingContext();
    const { pricingTiers } = useBillingStatus();

    const totalUsage = pricingAndUsage?.currentYearTotalUsage ?? 0;

    const currentTier = useCurrentPricingTier(totalUsage, pricingTiers);

    const currentTierIndex = pricingTiers.indexOf(currentTier);
    const requiredTierIndex = pricingTiers.indexOf(
        getTierForUsage(pricingTiers, totalUsage),
    );

    const [first, ...paidTiers] = pricingTiers;
    const lastTier = pricingTiers[pricingTiers.length - 1];

    return (
        <>
            <section className={styles.tiers}>
                <PricingTierWithUsage
                    usage={totalUsage}
                    tier={first}
                    tag={<TierTag isCurrent={currentTierIndex === 0} />}
                    variant={getTierVariant(
                        0,
                        currentTierIndex,
                        requiredTierIndex,
                    )}
                >
                    {currencyFormatterShort.format(totalUsage).toLowerCase()}{" "}
                    business expenses
                </PricingTierWithUsage>

                {paidTiers.map((tier, idx) => (
                    <PricingTierWithUsage
                        key={tier.start}
                        usage={totalUsage}
                        tier={tier}
                        tag={
                            <TierTag
                                isCurrent={currentTierIndex === idx + 1}
                                isNext={requiredTierIndex === idx + 1}
                            />
                        }
                        variant={getTierVariant(
                            idx + 1,
                            currentTierIndex,
                            requiredTierIndex,
                        )}
                        outOfCredits={idx + 1 > currentTierIndex}
                    />
                ))}
            </section>

            <footer className={styles.footer}>
                <CustomPricingCallButton
                    className="btn btn-xs btn-link text-gray-700"
                    text={`Expect ${currencyFormatterShort
                        .format(lastTier.end)
                        .toLowerCase()}+ business expenses?`}
                />
            </footer>
        </>
    );
};

function getTierVariant(
    tierIndex: number,
    currentTierIndex: number,
    requiredTierIndex: number,
): TierVariant {
    if (tierIndex === currentTierIndex) {
        return TierVariant.PRIMARY;
    }

    if (tierIndex === requiredTierIndex) {
        return TierVariant.WARNING;
    }

    return TierVariant.NEUTRAL;
}
