import React, { useCallback, useMemo, useState } from "react";
import {
    planManagementContext,
    PlanManagementContextValue,
} from "./PlanManagementContext";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";
import { StandardModal } from "../../general/Modal/Modal";
import styles from "./PlanManagement.module.scss";
import { useLoadStripe } from "../../settings/Billing/useLoadStripe";
import { prepareCheckout } from "../../../lib/flatRateBilling";
import { useQuery } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { DowngradeModalContent } from "./DowngradeModal/DowngradeModalContent";
import { UpgradeSubscriptionModal } from "./UpgradeSubscriptionModal";
import { useCanManageBilling } from "../../../hooks/useCanManageBilling";
import { trackEvent } from "../../../lib/analytics";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { useLocation } from "react-router-dom";

interface UpgradeModalOptions {
    plan: FLAT_RATE_PLANS;
    isTrial: boolean;
}

interface PlanManagementProviderProps {
    onBeforeUpgrade?(plan: FLAT_RATE_PLANS, trial: boolean): void;
    onBeforeDowngrade?(plan: FLAT_RATE_PLANS): void;
}

export const PlanManagementProvider: React.FC<PlanManagementProviderProps> = ({
    children,
    onBeforeDowngrade,
    onBeforeUpgrade,
}) => {
    const stripePromise = useLoadStripe();
    const canManageBilling = useCanManageBilling();
    const { isSubscribed } = useBillingStatus();
    const location = useLocation();

    const { data: checkoutData } = useQuery(
        "prepareCheckout",
        prepareCheckout,
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: canManageBilling,
        },
    );

    const beforeUpgrade: PlanManagementProviderProps["onBeforeUpgrade"] =
        useMemo(() => {
            return (
                onBeforeUpgrade ??
                ((plan) => {
                    void trackEvent(
                        isSubscribed
                            ? "plan_upgrade_initiated"
                            : "trial_start_initiated",
                        {
                            product: plan,
                            path: location.pathname,
                        },
                    );
                })
            );
        }, [isSubscribed, location.pathname, onBeforeUpgrade]);

    const beforeDowngrade: PlanManagementProviderProps["onBeforeDowngrade"] =
        useMemo(() => {
            return (
                onBeforeDowngrade ??
                ((plan) => {
                    void trackEvent("plan_downgrade_initiated", {
                        product: plan,
                        path: location.pathname,
                    });
                })
            );
        }, [location.pathname, onBeforeDowngrade]);

    const [downgradeModal, setDowngradeModal] =
        useState<FLAT_RATE_PLANS | null>(null);

    const [upgradeModal, setUpgradeModal] =
        useState<UpgradeModalOptions | null>(null);

    const openUpgradeModal = useCallback(
        (plan: FLAT_RATE_PLANS, isTrial: boolean) => {
            beforeUpgrade(plan, isTrial);
            setUpgradeModal({ plan, isTrial });
        },
        [beforeUpgrade],
    );

    const openDowngradeModal = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            beforeDowngrade(plan);
            setDowngradeModal(plan);
        },
        [beforeDowngrade],
    );

    const value: PlanManagementContextValue = useMemo(
        () => ({
            downgrade: openDowngradeModal,
            upgrade: openUpgradeModal,
        }),
        [openDowngradeModal, openUpgradeModal],
    );

    const stripeOptions: StripeElementsOptions = useMemo(
        () => ({
            clientSecret: checkoutData?.clientSecret,
            appearance: {
                variables: {
                    spacingUnit: "4px",
                    spacingGridRow: "24px",
                    spacingGridColumn: "32px",
                    fontFamily: `"Inter", sans-serif`,
                    colorText: "#515D71", // gray-700,
                    colorTextSecondary: "#515D71", // gray-700,
                    colorDangerText: "#ec535a", // red-500,
                },
                rules: {
                    ".Input": {
                        color: "#0F1826", // gray-900,
                    },
                },
            },
            loader: "always",
        }),
        [checkoutData?.clientSecret],
    );

    const handleUpgradeModalHide = useCallback(() => {
        if (upgradeModal) {
            void trackEvent("onboarding_payment_canceled", {
                plan: upgradeModal.plan,
            });
        }
        setUpgradeModal(null);
    }, [upgradeModal]);

    return (
        <planManagementContext.Provider value={value}>
            {children}

            <StandardModal
                size="xl"
                show={!!upgradeModal}
                onHide={handleUpgradeModalHide}
                on
                className={styles.planManagementModal}
            >
                {upgradeModal && (
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <UpgradeSubscriptionModal
                            {...upgradeModal}
                            intentId={checkoutData?.intentId}
                        />
                    </Elements>
                )}
            </StandardModal>

            <StandardModal
                size="lg"
                show={!!downgradeModal}
                onHide={() => setDowngradeModal(null)}
                className={styles.planChangeModal}
            >
                {downgradeModal && (
                    <DowngradeModalContent
                        downgradingToPlan={downgradeModal}
                        onHide={() => setDowngradeModal(null)}
                    />
                )}
            </StandardModal>
        </planManagementContext.Provider>
    );
};
