import { MutableRefObject, useCallback, useLayoutEffect, useRef } from "react";

export function useClickOutside<T extends HTMLElement>(
    onOutsideClick: () => void,
): MutableRefObject<T | null> {
    const elementRef = useRef<T | null>(null);
    const handleClick = useCallback(
        (event: MouseEvent) => {
            if (!elementRef.current) {
                return;
            }

            const isInside = elementRef.current?.contains(
                event.target as unknown as Node,
            );

            if (!isInside) {
                onOutsideClick();
            }
        },
        [onOutsideClick],
    );

    useLayoutEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [handleClick]);

    return elementRef;
}
