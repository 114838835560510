import React from "react";
import { Button } from "react-bootstrap";
import { LeftArrowIcon, RightArrowIcon } from "../../../icons";
import styles from "./Pagination.module.scss";
import classNames from "classnames";

export interface PaginationProps {
    onPageChange(nextPage: number): void;
    currentPage: number;
    pageSize: number;
    pageCount: number;
    totalCount: number;
    className?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
    onPageChange,
    currentPage,
    pageCount,
    totalCount,
    pageSize,
    className,
}) => {
    const start = (currentPage - 1) * pageSize + 1;
    const end = Math.min(currentPage * pageSize, totalCount || 0);

    return (
        <div className={classNames(styles.pagination, className)}>
            <span className="mr-2">
                {start} - {end} of {totalCount}
            </span>

            {pageCount > 1 && (
                <>
                    <Button
                        variant="secondary-icon"
                        className="mr-1"
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage <= 1}
                    >
                        <LeftArrowIcon />
                    </Button>
                    <Button
                        variant="secondary-icon"
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage >= pageCount}
                    >
                        <RightArrowIcon />
                    </Button>
                </>
            )}
        </div>
    );
};
