import React, { useCallback, useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { Entity } from "../../common/types/entity";
import { ReportFilters } from "../../common/types/filters/reports";
import { REPORTS_LABELS, ReportType } from "../../helpers/reports";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { EntityIcon } from "../entity/EntityIcon";
import { Card } from "../general/Card/Card";
import { PageHeading } from "../general/PageHeading/PageHeading";
import { ReportsContextProvider } from "./ReportsContextProvider";
import "./ReportsPage.scss";
import { ReportsPageContent } from "./ReportsPageContent";
import { ReportsPageNav } from "./ReportsPageNav";
import {
    ReportsPageFilters,
    ReportsPageFiltersProps,
} from "./filters/ReportsPageFilters";
import { useDefaultReportsFilters } from "./useDefaultReportsFilters";

interface Props {
    entity?: Entity;
    report?: ReportType;
}

function getPageHeader(
    report: ReportType | undefined,
    accountingTabEnabled: boolean,
) {
    if (accountingTabEnabled) {
        return report === ReportType.PROFIT_LOSS ? "Cash Activity" : "Insights";
    }

    return report ? REPORTS_LABELS[report] : "Reports";
}

export const ReportsPage: React.FC<Props> = ({ entity, report }) => {
    const defaultFilters = useDefaultReportsFilters();
    const [filters, setFilters] = useState<ReportFilters>(() => defaultFilters);

    const [activeTab, setActiveTab] = useState<ReportType>(
        report ?? ReportType.PROFIT_LOSS,
    );

    useEffect(() => {
        if (entity?.id) {
            setFilters((currentFilters) => ({
                ...currentFilters,
                entitiesAccounts: [{ entityId: entity.id }],
            }));
        }
    }, [entity?.id]);

    const onFiltersChange = useCallback<ReportsPageFiltersProps["onChange"]>(
        (newFilters) =>
            setFilters((oldFilters) => ({ ...oldFilters, ...newFilters })),
        [],
    );

    const { accountingTab } = useTypedFlags();

    return (
        <main className="reports">
            <PageHeading className="reports__title">
                {entity && <EntityIcon entity={entity} />}
                {getPageHeader(report, accountingTab)}
            </PageHeading>
            <ReportsContextProvider filters={filters}>
                <Tab.Container activeKey={activeTab}>
                    <Card className="reports__header" withTabs>
                        {!report && (
                            <ReportsPageNav
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        )}

                        <ReportsPageFilters
                            filters={filters}
                            onChange={onFiltersChange}
                            hardcodedEntity={entity}
                        />
                    </Card>

                    <ReportsPageContent
                        resetFilters={() => setFilters(defaultFilters)}
                    />
                </Tab.Container>
            </ReportsContextProvider>
        </main>
    );
};
