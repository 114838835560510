import React, { useMemo } from "react";
import { FinancialDocument } from "../../../common/types/financialDocument";
import { useFinancialDocumentFileQuery } from "./useFinancialDocumentFileQuery";
import { Loader } from "../../general/Loader";
import styles from "./FinancialDocumentPreview.module.scss";
import { FinancialDocumentExtensionIcon } from "../FinancialDocumentExtensionIcon";

interface Props {
    financialDocument: FinancialDocument;
}

export const FinancialDocumentPreview: React.FC<Props> = ({
    financialDocument,
}) => {
    const { data: response, isFetching } =
        useFinancialDocumentFileQuery(financialDocument);

    const imagePreview = response && response.contentType.includes("image");
    const pdfPreview = response && response.contentType === "application/pdf";

    const canShowPreview = imagePreview || pdfPreview;

    const previewUrl = useMemo(() => {
        if (!canShowPreview) {
            return null;
        }

        const blob = new Blob([response.data], {
            type: response.contentType,
        });
        return URL.createObjectURL(blob);
    }, [canShowPreview, response]);

    if (isFetching) {
        return (
            <div className={styles.container}>
                <Loader />
            </div>
        );
    }

    if (previewUrl) {
        return (
            <div className={styles.container}>
                {imagePreview ? (
                    <img
                        className={styles.preview}
                        src={previewUrl}
                        alt={financialDocument.fileName}
                    />
                ) : (
                    <div className={styles.preview}>
                        <iframe
                            src={previewUrl}
                            title={financialDocument.fileName}
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <FinancialDocumentExtensionIcon
                filename={financialDocument.fileName}
                className={styles.icon}
            />
            <p className="m-0">Preview not available</p>
        </div>
    );
};
