import React, { useCallback, useContext, useEffect } from "react";
import {
    ToastMessage as ToastMessageDef,
    ToastMessagesContext,
} from "./ToastMessages.context";
import { useTimer } from "../../../hooks/useTimer";
import { TransactionsUpdateToast } from "../../transactions/TransactionsBulkActions/TransactionsUpdateStatus/TransactionsUpdateToast";
import { ToastMessageType } from "./lib/common";
import { TransactionCategorizationToast } from "../../transactions/TransactionCategorizationToast/TransactionCategorizationToast";
import { never } from "../../../common/helpers/typescript";
import { CustomToastMessage } from "./CustomToastMessage";

const HIDE_AFTER_MS = 10_000;

interface Props {
    itemKey: string;
    message: ToastMessageDef;
}

export const ToastMessage: React.FC<Props> = ({ itemKey, message }) => {
    const { hideToast } = useContext(ToastMessagesContext);
    const hide = useCallback(() => hideToast(itemKey), [hideToast, itemKey]);
    const { start, pause, resume, clear } = useTimer(hide, HIDE_AFTER_MS);

    useEffect(() => {
        if (message.startTimer) {
            start();
        } else {
            clear();
        }
    }, [message.startTimer, start, clear]);

    let toast: React.ReactElement;

    switch (message.type) {
        case ToastMessageType.FINANCIAL_TRANSACTIONS_BULK_UPDATE:
            toast = <TransactionsUpdateToast hide={hide} {...message.data} />;
            break;
        case ToastMessageType.CATEGORIZATION:
            toast = (
                <TransactionCategorizationToast hide={hide} {...message.data} />
            );
            break;
        case ToastMessageType.CUSTOM:
            toast = <CustomToastMessage hide={hide} {...message.data} />;
            break;
        default:
            never(message);
    }

    return (
        <div
            className="toast-message"
            onMouseEnter={pause}
            onMouseLeave={resume}
        >
            {toast}
        </div>
    );
};
