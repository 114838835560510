import React from "react";
import { Entity } from "../../common/types/entity";
import { UserAvatar } from "../user/UserAvatar";
import { IconSize, ObjectIcon } from "../general/ObjectIcon/ObjectIcon";
import colors from "@styles/colors.module.scss";

interface Props {
    size?: IconSize;
    entity: Entity;
    withBorders?: boolean;
}

export const EntityIcon: React.FC<Props> = ({
    size = "md",
    entity,
    withBorders,
}) => {
    const borderColor =
        entity.iconColor === colors.white ? colors.miscAlpha100 : colors.white;
    const border = withBorders ? `1px solid ${borderColor}` : undefined;
    return entity.user ? (
        <UserAvatar user={entity.user} size={size} square border={border} />
    ) : (
        <ObjectIcon
            name={entity.name ?? ""}
            defaultColor={entity.iconColor}
            size={size}
            square
            border={border}
        />
    );
};
