import { useContext } from "react";
import { financialDocumentBulkSelectionContext } from "./financialDocumentBulkSelectionContext";

export function useFinancialDocumentBulkSelection() {
    const ctx = useContext(financialDocumentBulkSelectionContext);

    if (!ctx) {
        throw new Error(
            "useFinancialDocumentBulkSelection must be used within a FinancialDocumentBulkSelectionContextProvider",
        );
    }

    return ctx;
}
