import React from "react";
import { UsageSection } from "./UsageSection/UsageSection";
import { AnnualBillingProvider } from "./AnnualBilling.context";
import { QuoteSection } from "./QuoteSection/QuoteSection";
import { FAQSection } from "./FAQSection/FAQSection";
import { FeaturesSection } from "./FeaturesSection/FeaturesSection";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import styles from "./AnnualBilling.module.scss";

export const NotSubscribedView: React.FC = () => {
    const { creditsUsed, pricingTiers, isFetched } = useBillingStatus();
    const lastTier = pricingTiers[pricingTiers.length - 1];

    if (!isFetched) {
        return null;
    }

    const needsCustomPricing = creditsUsed >= lastTier.end;

    return (
        <AnnualBillingProvider>
            <div className={styles.annualBilling}>
                <UsageSection />
                {!needsCustomPricing && <QuoteSection />}
                <FeaturesSection />
                <FAQSection />
            </div>
        </AnnualBillingProvider>
    );
};
